import { render, staticRenderFns } from "./ReviewerAttachmentsSummary.vue?vue&type=template&id=01f9b764&scoped=true&"
import script from "./ReviewerAttachmentsSummary.vue?vue&type=script&lang=js&"
export * from "./ReviewerAttachmentsSummary.vue?vue&type=script&lang=js&"
import style0 from "./ReviewerAttachmentsSummary.vue?vue&type=style&index=0&id=01f9b764&prod&lang=css&"
import style1 from "./ReviewerAttachmentsSummary.vue?vue&type=style&index=1&id=01f9b764&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01f9b764",
  null
  
)

export default component.exports