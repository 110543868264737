
import Vue from "vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";
import DateHelperMixinVue from "@/mixins/dateHelperMixin.vue";
import { APPL_STATUS } from "@/constants/CommonConstants";
export default Vue.extend({
  mixins: [UIFeatureMixinVue, DateHelperMixinVue, ApplicationMixin],

  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      headers: [
        {
          align: "start",
          sortable: false,
          value: "name"
        },
        { text: "Date", value: "lastModifiedTs" },
        { text: "Event", value: "wfEventCode" },
        { text: "Action", value: "wfActionCode" },
        { text: "Status", value: "wfStatusCode" },
        { text: "User", value: "lastModifiedBy" }
      ],
      APPL_STATUS: APPL_STATUS
    };
  },
  props: {
    showDialog: { type: Boolean, default: true },
    eventLogs: { type: Array, default: null }
  },

  methods: {
    closeDialog() {
      this.$emit("close-event-history-dialog");
    }
  }
});
