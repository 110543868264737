
import Vue from "vue";
import { DASHBOARD_ENDPOINT } from "@/constants/EndPoints";
import { HTTP_STATUS } from "@/constants/HttpStatusCode";
import { USER_GROUPS } from "@/constants/CommonConstants";
import axios from "axios";
import store from "@/store";
import { ITESUser } from "@/types";
import routerMixin from "@/mixins/RouterMixin.vue";

export default Vue.extend({
  name: "user-info",
  mixins: [routerMixin],
  data() {
    return {
      userList: [] as ITESUser[],
      loadingDialog: true,
      headers: [
        { text: "user id", value: "userId" },
        { text: "user name", value: "userName" }
      ]
    };
  },
  created() {
    let url = DASHBOARD_ENDPOINT.GET_ALL_USERS;
    axios
      .get(url, { params: { userId: "7" } })
      .then(response => {
        if (response.status === HTTP_STATUS.OK) {
          this.userList = response.data;
        }
      })
      .catch(error => {
        store.dispatch.alertModule.showAlertMessageById(error.response.data.id);
      })
      .finally(() => {
        this.loadingDialog = false;
      });
  },

  methods: {
    storeUserInfo(userSelected: ITESUser) {
      let OrgUsers = [1, 2, 3, 4];
      let agencyUsers = [5, 6];
      let asgnedUsers = [7];
      let sprvsr = [8];
      if (OrgUsers.includes(userSelected.userId)) {
        userSelected.userGroups = USER_GROUPS.PD_TES_APPLICANT;
        userSelected.userFirstName = "TEST FN ORG";
        userSelected.userLastName = "TEST LN ORG";
        userSelected.transitAgencyCode = "CPTA";
        userSelected.fein = "efa06d5b7fe01ee06d0672853aaeabc1";
        userSelected.organizationName = "rabbit Transit";
      } else if (agencyUsers.includes(userSelected.userId)) {
        userSelected.userGroups = USER_GROUPS.PD_TES_AGREVIEWER;
        userSelected.userFirstName = "TEST FN AGENCY";
        userSelected.userLastName = "TEST LN AGENCY";
        userSelected.transitAgencyCode = "CPTA";
        userSelected.userFullName = "Full Name";
        userSelected.fein = "efa06d5b7fe01ee06d0672853aaeabc1";
        userSelected.organizationName = "rabbit Transit";
      } else if (asgnedUsers.includes(userSelected.userId)) {
        userSelected.userGroups = USER_GROUPS.PD_FMR_AGREVIEWERASSIGNED;
        userSelected.userFirstName = "TEST FN AGENCY";
        userSelected.userLastName = "TEST LN AGENCY";
        userSelected.transitAgencyCode = "CPTA";
        userSelected.userFullName = "Full Name";
        userSelected.fein = "efa06d5b7fe01ee06d0672853aaeabc1";
        userSelected.organizationName = "rabbit Transit";
      } else if (sprvsr.includes(userSelected.userId)) {
        userSelected.userGroups = USER_GROUPS.PD_FMR_AGSUPERVISOR;
        userSelected.userFirstName = "TEST FN AGENCY";
        userSelected.userLastName = "TEST LN AGENCY";
        userSelected.transitAgencyCode = "CPTA";
        userSelected.userFullName = "Full Name";
        userSelected.fein = "efa06d5b7fe01ee06d0672853aaeabc1";
        userSelected.organizationName = "rabbit Transit";
      } else {
        userSelected.userFirstName = "TEST FN CTZ";
        userSelected.userLastName = "TEST LN CTZ";
        userSelected.userGroups = USER_GROUPS.PD_TES_CITIZEN;
      }

      //organizationNameList
      //@ts-ignore
      store.commit.tesUserModule.SET_TES_USER(userSelected);

      //@ts-ignore
      this.forwardToDashboard();
    }
  }
});
