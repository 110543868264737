
import Vue from "vue";
import DateInput from "@/components/common/DateInput.vue";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import store from "@/store";
import tesControlTemplate from "@/components/common/TESControlTemplate.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";

import { IScreeningResponse, ITESUser } from "@/types";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";
import ExpansionPanelTemplate from "@/components/common/ExpansionPanelTemplate.vue";
import { expansionPanelIconObj } from "@/constants/CommonConstants";
import CloseButton from "@/components/common/CloseButton.vue";
import AccessibilityMixin from "@/mixins/AccessibilityMixin.vue";
import { GPC } from "@/constants/GPCCode";
import helper from "@/composables/helper";

export default Vue.extend({
  name: "personal-info",
  mixins: [
    fieldEntryMixin,
    UIFeatureMixinVue,
    ApplicationMixin,
    AccessibilityMixin
  ],
  components: {
    DateInput,
    tesControlTemplate,
    ExpansionPanelTemplate,
    CloseButton
  },
  data: function() {
    return {
      maskedPrimaryPhone: "" as string,
      maskedSecondaryPhone: "" as string,
      viewDOBDailog: false,
      expPanelIconObj: expansionPanelIconObj
    };
  },
  computed: {
    draftProgramInfo(): any {
      //@ts-ignore
      return store.getters.draftProgramInfoModule.getDraftProgramInfo;
    },
    applicantProfile(): any {
      //@ts-ignore
      return store.getters.applicantProfileModule.getApplicantProfile;
    },
    applicantDetails(): any {
      //@ts-ignore
      return store.getters.applicantDetailsModule.getApplicantDetails;
    },
    screeningResponse: {
      get(): IScreeningResponse {
        //@ts-ignore
        return store.getters.screeningResponseModule.getScreeningResponse;
      }
    },
    userInfo: {
      get(): ITESUser {
        //@ts-ignore
        return store.getters.tesUserModule.getTesUser;
      }
    },
    personalInfoMoreInfo: {
      get(): string {
        let moreInfotext: string = "";
        //@ts-ignore
        if (this.isOrganizationUser || this.isAgencyUser) {
          //@ts-ignore
          moreInfotext = this.buildDynamicText(
            //@ts-ignore
            this.uiFeature[GPC.PRSNL_INFO].largeText,
            "the applicant is",
            "them",
            "their"
          );
          //@ts-ignore
        } else if (this.isCitizenUser) {
          if (this.screeningResponse.onBehalfOfInd === "Y") {
            //@ts-ignore
            moreInfotext = this.buildDynamicText(
              //@ts-ignore
              this.uiFeature[GPC.PRSNL_INFO].largeText,
              "the applicant is",
              "them",
              "their"
            );
          } else {
            //@ts-ignore
            moreInfotext = this.buildDynamicText(
              //@ts-ignore
              this.uiFeature[GPC.PRSNL_INFO].largeText,
              "you are",
              "you",
              "your"
            );
          }
        }
        return moreInfotext;
      }
    }
  },
  methods: {
    saveToStore() {
      //@ts-ignore
      this.applicantProfile.applicantPrimaryPhone = this.unmaskedPhone(
        this.maskedPrimaryPhone
      );
      //@ts-ignore
      this.applicantProfile.applicantSecondaryPhone = this.unmaskedPhone(
        this.maskedSecondaryPhone
      );
      //@ts-ignore
      store.commit.applicantProfileModule.SET_APPLICANT_PROFILE(
        this.applicantProfile
      );
    },
    emailRules() {
      return ["rules.required", "rules.email"];
    },
    displayDOBDailog() {
      this.viewDOBDailog = true;
    },
    prePopulateEsecInfo() {
      if (
        !this.screeningResponse.onBehalfOfInd ||
        this.screeningResponse.onBehalfOfInd === "N"
      ) {
        if (
          (!this.applicantProfile.applicantEmailAddress ||
            this.applicantProfile.applicantEmailAddress === "") &&
          this.userInfo.emailAddress
        ) {
          this.applicantProfile.applicantEmailAddress = this.userInfo.emailAddress;
        }
        if (
          (!this.applicantProfile.applicantPrimaryPhone ||
            this.applicantProfile.applicantPrimaryPhone === "") &&
          this.userInfo.phoneNumber
        ) {
          this.maskedPrimaryPhone = this.userInfo.phoneNumber;
        }
      }
    }
  },
  created() {
    this.maskedPrimaryPhone = this.applicantProfile.applicantPrimaryPhone;
    this.maskedSecondaryPhone = this.applicantProfile.applicantSecondaryPhone;

    if (
      this.userInfo.fein !== null &&
      this.userInfo.fein !== undefined &&
      this.userInfo.fein.length > 0
    ) {
      if (
        this.applicantDetails.applicantFirstName &&
        this.applicantDetails.applicantFirstName !== ""
      ) {
        this.applicantProfile.applicantFirstName = this.applicantDetails.applicantFirstName;
        this.applicantProfile.applicantLastName = this.applicantDetails.applicantLastName;
        if (!this.applicantProfile.applicantDob) {
          this.applicantProfile.applicantDob = this.screeningResponse.applicantDob;
        }
      } else {
        if (
          !this.applicantProfile.applicantFirstName ||
          this.applicantProfile.applicantFirstName === ""
        ) {
          this.applicantProfile.applicantFirstName = this.screeningResponse.applicantFirstName;
          this.applicantProfile.applicantLastName = this.screeningResponse.applicantLastName;
          if (!this.applicantProfile.applicantDob) {
            this.applicantProfile.applicantDob = this.screeningResponse.applicantDob;
          }
        }
      }
    } else {
      if (
        !this.applicantProfile.applicantFirstName ||
        this.applicantProfile.applicantFirstName === ""
      ) {
        this.applicantProfile.applicantFirstName = this.screeningResponse.applicantFirstName;
        this.applicantProfile.applicantLastName = this.screeningResponse.applicantLastName;
        if (!this.applicantProfile.applicantDob) {
          this.applicantProfile.applicantDob = this.screeningResponse.applicantDob;
        }
      }
      this.prePopulateEsecInfo();
    }
  },
  mounted() {
    //@ts-ignore
    this.setAriaLabelledByForSelect(
      this.$refs.personal_info_select_gender_ref,
      "personal_info_gender_id"
    );
  },
  setup() {
    const { getAriaLabel } = helper();
    return { getAriaLabel };
  }
});
