
import Vue from "vue";
import store from "@/store";
import UIFeatureMixin from "@/mixins/UIFeatureMixin.vue";

export default Vue.extend({
  name: "enterprise-footer",
  mixins: [UIFeatureMixin],
  created() {
    let uiFeatureArray = "GOV_NAME,LTGOV_NAME";
    store.commit.uiFeatureModule.SET_DATA_LDNG_STS(true);
    store.dispatch.uiFeatureModule.getUIFeatures(uiFeatureArray);
  }
});
