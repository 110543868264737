
import router from "@/router";
import store from "@/store";
import { IScreeningResponse } from "@/types";
import Vue from "vue";

export default Vue.extend({
  methods: {
    forwardToDashboard() {
      router.push({
        name: "dashboard",
        query: {
          scrnRespID: String(this.screeningResponse.screeningResponseId)
        }
      });
    }
  },
  computed: {
    screeningResponse: {
      get(): IScreeningResponse {
        return store.getters.screeningResponseModule.getScreeningResponse;
      }
    }
  }
});
