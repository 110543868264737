
import { IAlert } from "@/types";
import store from "@/store";

export default {
  name: "alert-message",
  computed: {
    alert(): IAlert {
      //@ts-ignore
      return store.getters.alertModule.getAlert;
    }
  },
  methods: {
    resetAlertMessage() {
      //@ts-ignore
      store.dispatch.alertModule.resetAlertMessage();
    }
  }
};
