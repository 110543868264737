
import Vue from "vue";
import PromiseInterfaceCont from "../components/promise/PromiseInterfaceCont.vue";

export default Vue.extend({
  name: "promise-interface",
  components: {
    PromiseInterfaceCont
  },
  data() {
    return {
      showCriteria: true as boolean,
      showResults: false as boolean
    };
  }
});
