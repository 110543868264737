
import Vue from "vue";
import AdvancedSearchCriteria from "@/components/search/AdvancedSearchCriteria.vue";
import store from "@/store";

export default Vue.extend({
  name: "advanced-search",
  components: {
    AdvancedSearchCriteria
  },
  data() {
    return {
      showCriteria: true as boolean,
      showResults: false as boolean
    };
  },
  created() {
    let uiFeatureArray =
      "ABOUT_FUN,STR_APPL,ASGN_APP,APP_ASGN,FNLZ,APPL_SBMT,SAVE,APPL_CRT,APPL_RSBT,APPL_SBTD,APPL_DQFD,IN_PROCESS,NOT_START,IN_PRGS,RDY_SBMT,NOT_QLFYD,SBMTD,APPROVED,NOT_APPL,IN_PROCESS,ELIGIBLE,ADSRCH_STS,PEND_CONF,WITHDRAWN,INELIGIBLE,RE_COR,UN_RVW,WTHDRW,EDT_APP,APPRV,CMPLT_RVW,CNT_RVW,STRT_RW,SAVE_CNTN,APP_WTDRWN,APP_RSBMTD,APP_EDTD,CNFG_CMPLT,RVW_CMPLT,RWR_CHNGD,APRW_STRT,APP_NTAPCL,IN_PRGS_C,RDY_SBMT_C,CREATED_BY,CHGLGN_TXT,QUICK_SRCH,ER_INLINE,FMR,ENVRONMNT,TMP_APRVD,TMP_APRV,APEL_INPRG,UPD_ELGBTY,APP_APPL";

    //@ts-ignore
    store.commit.uiFeatureModule.SET_DATA_LDNG_STS(true);
    //@ts-ignore
    store.dispatch.uiFeatureModule.getUIFeatures(uiFeatureArray);
  },
  beforeRouteUpdate(to: any, from: any, next: any) {
    this.resetScreen();
    next();
  },
  methods: {
    resetScreen() {
      this.showCriteria = true;
      this.showResults = false;
    }
  }
});
