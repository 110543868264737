
import Vue from "vue";
import store from "@/store";
import { IDraftProgramInfo } from "@/types";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import ProgramInfoSummaryMixin from "@/mixins/ProgramInfoSummaryMixin.vue";
import readonlyField from "@/components/common/ReadOnlyField.vue";
import { GPC } from "@/constants/GPCCode";
import DynamicTextMixin from "@/mixins/DynamicTextMixin.vue";
import { CNST_TEXT } from "@/constants/CommonConstants";

export default Vue.extend({
  name: "ADA-Voter-Registration-Review",
  mixins: [
    AppPropsMixin,
    UIFeatureMixinVue,
    ProgramInfoSummaryMixin,
    DynamicTextMixin
  ],
  components: { readonlyField },
  computed: {
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        //@ts-ignore
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      }
    },
    adaVoterQues(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrgAgyCtznonBehalfOfUser()
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.VOT_REG_Q1].largeText,
          CNST_TEXT.THE_APPLICANT_IS,
          CNST_TEXT.THEY,
          CNST_TEXT.THEY
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.VOT_REG_Q1].largeText,
          CNST_TEXT.YOU_ARE,
          CNST_TEXT.YOU,
          CNST_TEXT.YOU
        );
      }
      return text;
    },
    voterRegisterOption(): any {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrgAgyCtznonBehalfOfUser()
      ) {
        text = this.buildTxtforVoteAlready(
          CNST_TEXT.THE_APPLICANT_IS,
          CNST_TEXT.THEY
        );
      } else {
        text = this.buildTxtforVoteAlready(CNST_TEXT.I_AM, CNST_TEXT.I);
      }
      return text;
    }
  },
  methods: {
    buildTxtforVoteAlready(param1: string, param2: string) {
      let text: string = "";
      if (this.draftProgramInfo.voterRegistrationSelectCode === "VOTE_ALRDY") {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["VOTE_ALRDY"].largeText,
          param1,
          param2
        );
      } else {
        text =
          //@ts-ignore
          this.uiFeature[this.draftProgramInfo.voterRegistrationSelectCode].largeText;
      }
      return text;
    }
  }
});
