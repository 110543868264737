var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('v-row',[_c('v-col',{staticClass:"px-0 py-0 px-md-16 py-md-1"},[_c('h6',[_vm._v(_vm._s(_vm.programPage)+" Detail Information")]),_c('v-card',{staticClass:"borderRadius pa-6",attrs:{"width":"100%"}},[_c('v-row',[(
              this.applicantProgramInfoObj.byPassInd !== 'Y' &&
                _vm.applicantProfile.homeAddress.paCountyCode !== '02'
            )?_c('v-col',[_c('readonlyField',{attrs:{"label":_vm.verifyAgeDsplyNameQues,"value":_vm.driverLicense,"previous":_vm.driverLicensePrevious,"showPrevious":_vm.showPreviousValues}})],1):_vm._e(),(
              this.applicantProgramInfoObj.byPassInd === 'Y' &&
                _vm.applicantProfile.homeAddress.paCountyCode !== '02'
            )?_c('v-col',[_c('p',[_vm._v(_vm._s(_vm.byPassTxtHeader))]),_c('p',[_vm._v(_vm._s(_vm.byPassTxtBdy))])]):_vm._e()],1),(_vm.applicantProfile.homeAddress.paCountyCode === '02')?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pt-2 pb-2 pr-2 pl-md-2",attrs:{"flat":""}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.uiFeature.SSR_DE_ALG.largeText)}})])],1)],1)],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }