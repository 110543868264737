
import Vue from "vue";
import { IApplicantAddress } from "@/types";
import { ADDRESS_TYPE, CNST_TEXT } from "@/constants/CommonConstants";
import ApplicantProfileMixin from "@/mixins/ApplicantProfileMixin.vue";
import store from "@/store";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import readonlyField from "@/components/common/ReadOnlyField.vue";
import { GPC } from "@/constants/GPCCode";
import DynamicTextMixin from "@/mixins/DynamicTextMixin.vue";
import InfoSection from "@/components/common/InfoSection.vue";

export default Vue.extend({
  name: "review-home-address-info",
  components: { readonlyField, InfoSection },
  mixins: [
    ApplicantProfileMixin,
    fieldEntryMixin,
    UIFeatureMixinVue,
    DynamicTextMixin
  ],
  data() {
    return {
      countyName: "" as string,
      ADDRESS_TYPE: ADDRESS_TYPE
    };
  },
  props: {
    sameMailingInd: {
      type: String
    },
    applicantAddress: {
      type: Object as () => IApplicantAddress
    },
    applicantAddressPrevious: {
      type: Object as () => IApplicantAddress
    }
  },
  computed: {
    headerLabel(): string {
      let addresTypeHeader =
        this.applicantAddress.addressTypeCode === ADDRESS_TYPE.HOME
          ? "Home"
          : "Mailing";
      return `${addresTypeHeader} Address`;
    },
    zipMasked(): any {
      //@ts-ignore
      return this.zipMask(this.applicantAddress.zip);
    },
    zipMaskedPrevious(): string {
      //@ts-ignore
      return this.zipMask(this.applicantAddressPrevious.zip);
    },
    counties(): [] {
      return store.getters.dataModule.getCounties;
    },
    stateCollegeQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithOnePlaceHolder(
        GPC.ST_COLLG_Q,
        CNST_TEXT.DOES_THE_APPLICANT,
        CNST_TEXT.DO_YOU
      );
      return text;
    },
    isMailAddressSameQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithTwoPlaceHolder(
        GPC.IS_MAIL_AD,
        CNST_TEXT.IS_THE_APPLICANTS,
        CNST_TEXT.THEIR,
        CNST_TEXT.IS_YOUR,
        CNST_TEXT.YOUR
      );
      return text;
    },
    printMatFlatString: function() {
      return (printMatFormatsList: string[]) => {
        let flatString: string = "";

        printMatFormatsList.forEach((n: string) => {
          if (!flatString && n !== "ACCBL_OTHR") {
            //@ts-ignore
            flatString = this.uiFeature[n].displayName;
          } else if (n !== "ACCBL_OTHR") {
            //@ts-ignore
            flatString = flatString + ", " + this.uiFeature[n].displayName;
          }
        });
        return flatString;
      };
    }
  },
  methods: {
    getCountyName(paCountyCode: string) {
      let countyName = this.counties.find(
        obj =>
          //@ts-ignore
          obj.id === paCountyCode
      );
      //@ts-ignore
      return countyName ? countyName.name : "";
    }
  }
});
