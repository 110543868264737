
import Vue from "vue";
import router from "@/router";
import store from "@/store";
import ApplicantDashboard from "@/components/dashboard/DashboardApplications.vue";
import { DASHBOARD_TYPE } from "@/constants/CommonConstants";
import { IDashboardContent, IApplicationNavigation } from "@/types";
import ClearStoreValuesMixin from "@/mixins/ClearStoreValuesMixin.vue";
import DateHelperMixinVue from "@/mixins/dateHelperMixin.vue";
import StepperHeader from "@/components/common/StepperHeader.vue";

export default Vue.extend({
  name: "dashboard-profile",
  mixins: [ClearStoreValuesMixin, DateHelperMixinVue],
  components: { ApplicantDashboard, StepperHeader },
  data() {
    return {
      showContinueDashboard: false
    };
  },
  computed: {
    dashboardContent: {
      get(): IDashboardContent {
        //@ts-ignore
        return store.getters.dashboardModule.getDashboardContent;
      }
    },
    isProfileDashboard(): boolean {
      return this.dashboardContent.dashboardType === DASHBOARD_TYPE.PROFILE;
    },
    isApplicantDashboard(): boolean {
      return this.dashboardContent.dashboardType === DASHBOARD_TYPE.APPLICATION;
    },
    isContinueDashboard(): boolean {
      return this.dashboardContent.dashboardType === DASHBOARD_TYPE.CONTINUE;
    },
    applicationNavigation: {
      get(): IApplicationNavigation {
        //@ts-ignore
        return store.getters.applicationNavigationModule
          .getApplicationNavigation;
      }
    },
    updatedDate(): string {
      //@ts-ignore
      return this.formatDateMixin(
        this.dashboardContent.applicantProfile.updatedTs
      );
    }
  },
  methods: {
    getCreateApplication() {
      this.applicationNavigation.applProfileEdit = false;
      //@ts-ignore
      store.commit.applicationNavigationModule.SET_APPLLICATION_NAVIGATION(
        this.applicationNavigation
      );
      router.push({ name: "createapp" });
    },
    getReviewApplication() {
      this.applicationNavigation.applProfileEdit = false;
      //@ts-ignore
      store.commit.applicationNavigationModule.SET_APPLLICATION_NAVIGATION(
        this.applicationNavigation
      );
      //@ts-ignore
      this.clearApplicantProgramInfoFromStore();
      router.push({
        name: "reviewProfileInfo",
        query: {
          profileID: String(
            this.dashboardContent.applicantProfile.applicantProfileId
          ),
          scrnRespID: String(
            this.dashboardContent.screeningResponse.screeningResponseId
          )
        }
      });
    },
    editProfile() {
      router.push({
        name: "editapp",
        query: {
          //@ts-ignore
          profileID: String(
            this.dashboardContent.applicantProfile.applicantProfileId
          ),
          scrnRespID: String(
            this.dashboardContent.screeningResponse.screeningResponseId
          )
        }
      });
    }
  }
});
