
import Vue from "vue";
import { TES_APP_ENDPOINT } from "@/constants/EndPoints";
import {
  PROGRAM_CODEVALUE,
  PROGRAMS_CONSTANTS,
  ACSPROGRAM_CODEVALUE
} from "@/constants/Programs";
import {
  IScrAnswers,
  IScreeningQuestions,
  IScreeningResponse,
  IPrograms,
  IFixedRouteScreeningResults,
  ITESUser
} from "@/types";
import axios from "axios";
import store from "@/store";
import ScreeningResponseMixin from "@/mixins/ScreeningResponseMixin.vue";
import router from "@/router";
import ProgressBar from "@/components/common/ProgressBar.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import routerMixin from "@/mixins/RouterMixin.vue";
import { ESEC_REDIRECT_STRINGS, CNST_TEXT } from "@/constants/CommonConstants";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";
import { GPC } from "@/constants/GPCCode";
import AccessibilityMixin from "@/mixins/AccessibilityMixin.vue";

export default Vue.extend({
  name: "ScrPrograms",
  mixins: [
    ScreeningResponseMixin,
    UIFeatureMixinVue,
    routerMixin,
    AppPropsMixin,
    ApplicationMixin,
    AccessibilityMixin
  ],
  components: { ProgressBar },
  data() {
    return {
      eligibleItems: [] as string[],
      multiple: true,
      programs: PROGRAM_CODEVALUE,
      acssPrgms: ACSPROGRAM_CODEVALUE,
      programCodes: PROGRAMS_CONSTANTS.PROGRAM_CODES,
      scrResponses: {} as IScreeningResponse,
      fixedRouteScreeningResults: {} as IFixedRouteScreeningResults,
      showProgressBar: true,
      scrDeleteDialog: false,
      startApplDialog: false,
      showPassword: false,
      userName: "" as string,
      password: "" as string,
      loginError: false,
      loginErrorMsg: "",
      eligPrgmsLoaded: false
    };
  },
  created() {
    if (
      this.screeningResponse.ageGroup &&
      //@ts-ignore
      this.screeningResponse.ageGroup != this.uiFeature.BTWN_1864.displayName
    ) {
      this.acssPrgms.splice(1, 1);
    } else if (this.acssPrgms.length == 1) {
      this.acssPrgms.push({ name: "PWD" });
    }
    axios
      .get(TES_APP_ENDPOINT.GET_PROGRAMS_BY_COUNTY, {
        params: {
          countyCode: this.screeningResponse.paCountyCode,
          stateCollegeInd: this.screeningResponse.stateCollegeInd
        }
      })
      .then(response => {
        this.programCodes = response.data;
      })
      .catch(error => {
        store.dispatch.alertModule.showAlertMessageById(error.response.data.id);
      });

    let scrResponseMixRef: any = this;
    if (this.userModel && this.userModel.userId) {
      let url = TES_APP_ENDPOINT.GET_ELIGIBLE_PRGMS_W_SCRN_RESP_ID;

      axios
        .get(url, {
          params: {
            screeningRespID: this.screeningResponse.screeningResponseId
          }
        })
        .then(response => {
          this.eligibleItems = [...new Set<string>(response.data)];
          this.eligPrgmsLoaded = true;
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    } else {
      let url = TES_APP_ENDPOINT.GET_ELIGIBLE_PROGRAMS;
      this.scrResponses = scrResponseMixRef.buildScreeningResponseEntity(
        this.questions,
        this.scrAnswers.answers
      );
      axios
        .post(url, this.scrResponses)
        .then(response => {
          this.eligibleItems = [...new Set<string>(response.data)];
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    }
  },
  methods: {
    startApplication() {
      if (this.userModel.userId) {
        router.push({ name: "createapp" });
      } else {
        //Before user login
        this.showProgressBar = true;
        this.scrResponses.selectedPrograms = this.eligibleItems;

        let url = TES_APP_ENDPOINT.START_APPLICATION;

        if (!this.scrResponses.userId) {
          //@ts-ignore
          this.scrResponses.userId = null;
        }

        axios
          .post(url, this.scrResponses)
          .then(response => {
            this.scrResponses = response.data;

            //@ts-ignore
            store.commit.screeningResponseModule.SET_SCREENING_RESPONSE(
              response.data
            );
            // need to redirect different page
          })
          .catch(error => {
            store.dispatch.alertModule.showAlertMessageById(
              error.response.data.id
            );
          })
          .finally(() => {
            this.showProgressBar = false;
          });
      }
    },
    deleteScrnRsp() {
      this.scrDeleteDialog = false;
      let url = TES_APP_ENDPOINT.DELETE_SCRNG_RSP;
      axios
        .post(url, this.screeningResponse)
        .then(response => {
          //redirect to dashboard

          //@ts-ignore
          this.forwardToDashboard();
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    },
    urlSearch(param: string) {
      //@ts-ignore
      let results = new RegExp("[?&]" + param + "=([^&#]*)").exec(
        window.location.href
      );
      if (results == null) {
        return null;
      } else {
        return decodeURIComponent(results[1]) || 0;
      }
    },
    postToESEC() {
      //@ts-ignore
      let decodedTARGET = this.urlSearch("TARGET");
      //@ts-ignore
      let SMAUTHREASON = this.urlSearch("SMAUTHREASON");

      let esecURL;
      if (decodedTARGET == null) {
        let parser = document.createElement("a");
        parser.href = window.location.href;
        decodedTARGET = parser.origin + "/tes-web/dashboard";
        //@ts-ignore
        esecURL = parser.origin + "/" + this.uiFeature.ESEC_URL.displayName;
        SMAUTHREASON = "0";
      } else {
        esecURL =
          //@ts-ignore
          decodedTARGET.substring(4, decodedTARGET.length) +
          //@ts-ignore
          this.uiFeature.ESEC_URL.displayName;
      }
      let headers = {
        accept: "text/html,application/xhtml+xml,application/xml"
      };

      let formData = new URLSearchParams();
      //@ts-ignore
      formData.append("target", decodedTARGET);
      //@ts-ignore
      formData.append("smauthreason", SMAUTHREASON);
      //@ts-ignore
      formData.append("SMENC", this.uiFeature.SMENC.displayName);
      //@ts-ignore
      formData.append("SMLOCALE", this.uiFeature.SMLOCALE.displayName);
      //@ts-ignore
      formData.append("USER", this.userName);
      //@ts-ignore
      formData.append("PASSWORD", this.password);

      if (this.screeningResponse.esecScreeningId) {
        //@ts-ignore
        formData.append(
          "TESScreeningID",
          this.screeningResponse.esecScreeningId
        );
      }

      axios
        .post(esecURL, formData, { headers: headers })
        .then(response => {
          let responseURL = response.request.responseURL;
          this.determineRedirect(responseURL);
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        });
    },
    determineRedirect(responseURL: string) {
      if (
        responseURL &&
        (responseURL.indexOf(ESEC_REDIRECT_STRINGS.ACCESS_DENIED) !== -1 ||
          responseURL.indexOf(ESEC_REDIRECT_STRINGS.IDSERVICES) !== -1)
      ) {
        //@ts-ignore
        let redirectURL = this.uiFeature.CTZURL_QA.largeText;
        if (this.userName.toLowerCase().startsWith("b-")) {
          //@ts-ignore
          redirectURL = this.uiFeature.BPURL_QA.largeText;
        }
        if (responseURL.indexOf(ESEC_REDIRECT_STRINGS.CTZACSURL) !== -1) {
          //@ts-ignore
          redirectURL = this.uiFeature.CTZURL_ROL.largeText;
        }
        if (responseURL.indexOf(ESEC_REDIRECT_STRINGS.PUBLICURL) !== -1) {
          redirectURL = responseURL;
        }
        window.location.href = redirectURL;
      } else if (
        responseURL &&
        (responseURL.indexOf(ESEC_REDIRECT_STRINGS.LOCKEDAUTHRSN7) !== -1 ||
          responseURL.indexOf(ESEC_REDIRECT_STRINGS.LOCKEDAUTHRSN24) !== -1 ||
          responseURL.indexOf(ESEC_REDIRECT_STRINGS.LOCKEDAUTHRSN51) !== -1)
      ) {
        this.loginError = true;
        //@ts-ignore
        this.loginErrorMsg = this.uiFeature.ACC_LOCKED.displayName;
      } else {
        this.validateAuthentication();
      }
    },
    validateAuthentication() {
      let url = TES_APP_ENDPOINT.USER_LOGIN_VERIFY;
      axios
        .get(url)
        .then(response => {
          let respVal = response.data;
          if (respVal === "SUCCESS") {
            this.loginError = false;
            router.push({ name: "dashboard" });
          } else if (respVal === "LOCKED") {
            this.loginError = true;
            //@ts-ignore
            this.loginErrorMsg = this.uiFeature.ACC_LOCKED.displayName;
          } else if (respVal === "FAILED") {
            this.loginError = true;
            //@ts-ignore
            this.loginErrorMsg = this.uiFeature.LOGIN_FAIL.displayName;
          }
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        });
    },
    displayAlertMessage() {
      //@ts-ignore
      this.showAlertMessage(
        //@ts-ignore
        this.uiFeature.ER_INLINE.largeText,
        "error"
      );
    },
    loadADADynamicGPC() {
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.screeningResponse.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        this.uiFeature["ADA_Y"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["ADA_Y"].largeText,
          CNST_TEXT.THE_APPLICANTS,
          CNST_TEXT.THE_APPLICANTS
        );
        //@ts-ignore
        this.uiFeature["WHY_ADA"].children[0].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["WHY_ADA"].children[0].largeText,
          CNST_TEXT.THE_APPLICANT_LIVES,
          CNST_TEXT.THEY
        );
        //@ts-ignore
        this.uiFeature[
          "WNOT_ADA"
          //@ts-ignore
        ].children[0].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["WNOT_ADA"].children[0].largeText,
          CNST_TEXT.TTHE_APPLICANT,
          CNST_TEXT.THEY,
          CNST_TEXT.THEY
        );
        //@ts-ignore
      } else {
        //@ts-ignore
        this.uiFeature["ADA_Y"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["ADA_Y"].largeText,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOUR
        );
        //@ts-ignore
        this.uiFeature[
          "WHY_ADA"
          //@ts-ignore
        ].children[0].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["WHY_ADA"].children[0].largeText,
          CNST_TEXT.YOU_LIVE,
          CNST_TEXT.YOU
        );
        //@ts-ignore
        this.uiFeature[
          "WNOT_ADA"
          //@ts-ignore
        ].children[0].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["WNOT_ADA"].children[0].largeText,
          CNST_TEXT.YYOU,
          CNST_TEXT.YOU,
          CNST_TEXT.YOU
        );
      }
    },
    loadFTPDynamicGPC() {
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.screeningResponse.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        this.uiFeature["FTP_Y"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["FTP_Y"].largeText,
          CNST_TEXT.THE_APPLICANTS,
          CNST_TEXT.THE_APPLICANTS
        );
        //@ts-ignore
        this.uiFeature["WHY_FTP"].children[0].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["WHY_FTP"].children[0].largeText,
          CNST_TEXT.THE_APPLICANT_LIVES,
          CNST_TEXT.MEETS
        );
        //@ts-ignore
        this.uiFeature[
          "WNOT_FTP"
          //@ts-ignore
        ].children[0].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["WNOT_FTP"].children[0].largeText,
          CNST_TEXT.TTHE_APPLICANT,
          CNST_TEXT.THEY,
          CNST_TEXT.THEY
        );
        //@ts-ignore
      } else {
        //@ts-ignore
        this.uiFeature["FTP_Y"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["FTP_Y"].largeText,
          "",
          CNST_TEXT.YOUR
        );
        //@ts-ignore
        this.uiFeature[
          "WHY_FTP"
          //@ts-ignore
        ].children[0].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["WHY_FTP"].children[0].largeText,
          CNST_TEXT.YOU_LIVE,
          CNST_TEXT.YOU_MEET
        );
        //@ts-ignore
        this.uiFeature[
          "WNOT_FTP"
          //@ts-ignore
        ].children[0].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["WNOT_FTP"].children[0].largeText,
          CNST_TEXT.YYOU,
          CNST_TEXT.YOU,
          CNST_TEXT.YOU
        );
      }
    },
    loadMATPDynamicGPC() {
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.screeningResponse.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        this.uiFeature["MATP_Y"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["MATP_Y"].largeText,
          CNST_TEXT.THE_APPLICANTS,
          CNST_TEXT.THE_APPLICANTS
        );
        //@ts-ignore
        this.uiFeature[
          "WHY_MATP"
          //@ts-ignore
        ].children[0].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["WHY_MATP"].children[0].largeText,
          CNST_TEXT.THE_APPLICANT_HAS,
          CNST_TEXT.NEEDS
        );
        //@ts-ignore
        this.uiFeature[
          "WNOT_MATP"
          //@ts-ignore
        ].children[0].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["WNOT_MATP"].children[0].largeText,
          CNST_TEXT.TTHE_APPLICANT,
          CNST_TEXT.THEY,
          CNST_TEXT.THEY,
          CNST_TEXT.THE_APPLICANT_HAS,
          CNST_TEXT.THEIR,
          CNST_TEXT.THEIR
        );
        //@ts-ignore
      } else {
        //@ts-ignore
        this.uiFeature["MATP_Y"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["MATP_Y"].largeText,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOUR
        );
        //@ts-ignore
        this.uiFeature[
          "WHY_MATP"
          //@ts-ignore
        ].children[0].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["WHY_MATP"].children[0].largeText,
          CNST_TEXT.YOU_HAVE,
          CNST_TEXT.NEED
        );
        //@ts-ignore
        this.uiFeature[
          "WNOT_MATP"
          //@ts-ignore
        ].children[0].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["WNOT_MATP"].children[0].largeText,
          CNST_TEXT.YYOU,
          CNST_TEXT.YOU,
          CNST_TEXT.YOU,
          CNST_TEXT.YOU_HAVE,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOUR
        );
      }
    },
    loadPWDDynamicGPC() {
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.screeningResponse.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        this.uiFeature["PWD_Y"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["PWD_Y"].largeText,
          CNST_TEXT.THE_APPLICANTS,
          CNST_TEXT.THE_APPLICANTS
        );
        //@ts-ignore
        this.uiFeature[
          "WHY_PWD"
          //@ts-ignore
        ].children[0].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["WHY_PWD"].children[0].largeText,
          CNST_TEXT.THE_APPLICANT_HAS
        );
        //@ts-ignore
        this.uiFeature[
          "WNOT_PWD"
          //@ts-ignore
        ].children[0].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["WNOT_PWD"].children[0].largeText,
          CNST_TEXT.TTHE_APPLICANT,
          CNST_TEXT.THE_APPLICANT_DOES,
          CNST_TEXT.DOES
        );
        //@ts-ignore
      } else {
        //@ts-ignore
        this.uiFeature["PWD_Y"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["PWD_Y"].largeText,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOUR
        );
        //@ts-ignore
        this.uiFeature[
          "WHY_PWD"
          //@ts-ignore
        ].children[0].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["WHY_PWD"].children[0].largeText,
          CNST_TEXT.YOU_HAVE
        );
        //@ts-ignore
        this.uiFeature[
          "WNOT_PWD"
          //@ts-ignore
        ].children[0].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["WNOT_PWD"].children[0].largeText,
          CNST_TEXT.YYOU,
          CNST_TEXT.LYOU_DO,
          CNST_TEXT.DO
        );
      }
    },
    loadRFTPDynamicGPC() {
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.screeningResponse.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        this.uiFeature["RFTP_Y"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["RFTP_Y"].largeText,
          CNST_TEXT.THE_APPLICANT,
          CNST_TEXT.THEIR,
          CNST_TEXT.THE_APPLICANTS,
          CNST_TEXT.THE_APPLICANTS
        );
        //@ts-ignore
        this.uiFeature[
          "WHY_RFTP"
          //@ts-ignore
        ].children[0].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["WHY_RFTP"].children[0].largeText,
          CNST_TEXT.THE_APPLICANT_LIVES,
          ""
        );
        //@ts-ignore
        this.uiFeature[
          "WNOT_RFTP"
          //@ts-ignore
        ].children[0].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["WNOT_RFTP"].children[0].largeText,
          CNST_TEXT.TTHE_APPLICANT,
          CNST_TEXT.THEY,
          CNST_TEXT.DOES
        );
        //@ts-ignore
      } else {
        //@ts-ignore
        this.uiFeature["RFTP_Y"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["RFTP_Y"].largeText,
          CNST_TEXT.YOU,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOUR
        );
        //@ts-ignore
        this.uiFeature[
          "WHY_RFTP"
          //@ts-ignore
        ].children[0].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["WHY_RFTP"].children[0].largeText,
          CNST_TEXT.YOU_LIVE,
          CNST_TEXT.YOU
        );
        //@ts-ignore
        this.uiFeature[
          "WNOT_RFTP"
          //@ts-ignore
        ].children[0].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["WNOT_RFTP"].children[0].largeText,
          CNST_TEXT.YYOU,
          CNST_TEXT.YOU,
          CNST_TEXT.DO
        );
      }
    },
    loadAGINGDynamicGPC() {
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.screeningResponse.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        this.uiFeature["AGING_Y"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["AGING_Y"].largeText,
          CNST_TEXT.THE_APPLICANT,
          CNST_TEXT.THE_APPLICANTS
        );
        //@ts-ignore
        this.uiFeature[
          "WHY_AGING"
          //@ts-ignore
        ].children[0].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["WHY_AGING"].children[0].largeText,
          CNST_TEXT.THE_APPLICANT_IS
        );
        //@ts-ignore
        this.uiFeature[
          "WNOT_AGING"
          //@ts-ignore
        ].children[0].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["WNOT_AGING"].children[0].largeText,
          CNST_TEXT.THE_APPLICANT_DOES_NOT,
          CNST_TEXT.THEY
        );
        //@ts-ignore
      } else {
        //@ts-ignore
        this.uiFeature["AGING_Y"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["AGING_Y"].largeText,
          CNST_TEXT.YOU,
          CNST_TEXT.YOUR
        );
        //@ts-ignore
        this.uiFeature[
          "WHY_AGING"
          //@ts-ignore
        ].children[0].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["WHY_AGING"].children[0].largeText,
          CNST_TEXT.YOU_ARE
        );
        //@ts-ignore
        this.uiFeature[
          "WNOT_AGING"
          //@ts-ignore
        ].children[0].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["WNOT_AGING"].children[0].largeText,
          CNST_TEXT.YOU_DONT,
          CNST_TEXT.YOU
        );
      }
    },
    loadSSRDynamicGPC() {
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.screeningResponse.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        this.uiFeature["SSR_Y"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["SSR_Y"].largeText,
          CNST_TEXT.THE_APPLICANT,
          CNST_TEXT.THE_APPLICANTS
        );
        //@ts-ignore
        this.uiFeature[
          "WHY_SSR"
          //@ts-ignore
        ].children[0].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["WHY_SSR"].children[0].largeText,
          CNST_TEXT.THE_APPLICANT_MEETS
        );
        //@ts-ignore
        this.uiFeature[
          "WNOT_SSR"
          //@ts-ignore
        ].children[0].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["WNOT_SSR"].children[0].largeText,
          CNST_TEXT.TTHE_APPLICANT,
          CNST_TEXT.THEY
        );
        //@ts-ignore
      } else {
        //@ts-ignore
        this.uiFeature["SSR_Y"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["SSR_Y"].largeText,
          CNST_TEXT.YOU,
          CNST_TEXT.YOUR
        );
        //@ts-ignore
        this.uiFeature[
          "WHY_SSR"
          //@ts-ignore
        ].children[0].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["WHY_SSR"].children[0].largeText,
          CNST_TEXT.YOU_MEET
        );
        //@ts-ignore
        this.uiFeature[
          "WNOT_SSR"
          //@ts-ignore
        ].children[0].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["WNOT_SSR"].children[0].largeText,
          CNST_TEXT.YYOU,
          CNST_TEXT.YOU
        );
      }
    },
    loadVTPDynamicGPC() {
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.screeningResponse.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        this.uiFeature["VTP_Y"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["VTP_Y"].largeText,
          CNST_TEXT.THE_APPLICANTS,
          CNST_TEXT.THE_APPLICANTS
        );
        //@ts-ignore
        this.uiFeature[
          "WHY_VTP"
          //@ts-ignore
        ].children[0].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["WHY_VTP"].children[0].largeText,
          CNST_TEXT.THE_APPLICANT_IS
        );
        //@ts-ignore
        this.uiFeature[
          "WNOT_VTP"
          //@ts-ignore
        ].children[0].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["WNOT_VTP"].children[0].largeText,
          CNST_TEXT.THE_APPLICANT_IS
        );
        //@ts-ignore
      } else {
        //@ts-ignore
        this.uiFeature["VTP_Y"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["VTP_Y"].largeText,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOUR
        );
        //@ts-ignore
        this.uiFeature[
          "WHY_VTP"
          //@ts-ignore
        ].children[0].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["WHY_VTP"].children[0].largeText,
          CNST_TEXT.YOU_ARE
        );
        //@ts-ignore
        this.uiFeature[
          "WNOT_VTP"
          //@ts-ignore
        ].children[0].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["WNOT_VTP"].children[0].largeText,
          CNST_TEXT.YOU_ARE
        );
      }
    }
  },
  computed: {
    uiFeatureByName: function() {
      return (first: string, second: string) => {
        //@ts-ignore
        let temp = this.uiFeature[`${first}${second}`];
        return temp ? temp["children"] : [];
      };
    },
    resultItems(): IPrograms[] {
      let resultItems: IPrograms[] = [];
      if (this.eligPrgmsLoaded) {
        if (this.eligibleItems.length == 0) {
          resultItems = this.programs;
        } else {
          for (let program of this.programs) {
            if (this.eligibleItems.includes(program.name)) {
              resultItems.push(program);
            }
          }
        }
      }
      return resultItems;
    },
    scrAnswers: {
      get(): IScrAnswers {
        //@ts-ignore
        return store.getters.screenQuesModule.getScrAnswers;
      }
    },
    questions: {
      get(): IScreeningQuestions[] {
        //@ts-ignore
        return store.getters.screenQuesModule.getQuestions;
      }
    },
    userModel: {
      get(): ITESUser {
        //@ts-ignore
        return store.getters.tesUserModule.getTesUser;
      }
    },

    applicantDetails(): any {
      //@ts-ignore
      return store.getters.applicantDetailsModule.getApplicantDetails;
    },

    screeningResponse: {
      get(): IScreeningResponse {
        //@ts-ignore
        return store.getters.screeningResponseModule.getScreeningResponse;
      }
    },
    ineligibleText(): string {
      let text: string = "";
      //@ts-ignore
      if (this.isOrganizationUser || this.isAgencyUser) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.NO_ELGPGM].largeText,
          CNST_TEXT.THE_APPLICANT_DOES,
          CNST_TEXT.THEIR,
          CNST_TEXT.THE_APPLICANT,
          CNST_TEXT.THE_APPLICANTS
        );
        //@ts-ignore
      } else if (this.isCitizenUser) {
        if (this.screeningResponse.onBehalfOfInd === "Y") {
          //@ts-ignore
          text = this.buildDynamicText(
            //@ts-ignore
            this.uiFeature[GPC.NO_ELGPGM].largeText,
            CNST_TEXT.THE_APPLICANT_DOES,
            CNST_TEXT.THEIR,
            CNST_TEXT.THE_APPLICANT,
            CNST_TEXT.THE_APPLICANTS
          );
        } else {
          //@ts-ignore
          text = this.buildDynamicText(
            //@ts-ignore
            this.uiFeature[GPC.NO_ELGPGM].largeText,
            CNST_TEXT.LYOU_DO,
            CNST_TEXT.YOUR,
            CNST_TEXT.YOU,
            CNST_TEXT.YOUR
          );
        }
      }
      return text;
    },
    nEligibleItems(): IPrograms[] {
      let nEligibleItems: IPrograms[] = [];
      for (let program of this.programs) {
        if (
          !this.eligibleItems.includes(program.name) &&
          this.programCodes.includes(program.name)
        ) {
          nEligibleItems.push(program);
        }
      }
      return nEligibleItems;
    },
    eligItms(): IPrograms[] {
      let eligItms: IPrograms[] = [];
      for (let program of this.resultItems) {
        if (this.programCodes.includes(program.name)) {
          eligItms.push(program);
        }
      }
      return eligItms;
    }
  },
  watch: {
    uiFeature: {
      deep: true,
      handler(obj: Object) {
        //@ts-ignore
        if (obj["ADA_Y"]) {
          this.loadADADynamicGPC();
        }
        //@ts-ignore
        if (obj["FTP_Y"]) {
          this.loadFTPDynamicGPC();
        }
        //@ts-ignore
        if (obj["MATP_Y"]) {
          this.loadMATPDynamicGPC();
        }
        //@ts-ignore
        if (obj["PWD_Y"]) {
          this.loadPWDDynamicGPC();
        }
        //@ts-ignore
        if (obj["RFTP_Y"]) {
          this.loadRFTPDynamicGPC();
        }
        //@ts-ignore
        if (obj["AGING_Y"]) {
          this.loadAGINGDynamicGPC();
        }
        //@ts-ignore
        if (obj["SSR_Y"]) {
          this.loadSSRDynamicGPC();
        }
        //@ts-ignore
        if (obj["VTP_Y"]) {
          this.loadVTPDynamicGPC();
        }
      }
    },
    scrDeleteDialog: {
      immediate: true,
      handler: function(newValue) {
        if (newValue) {
          this.$nextTick(() => {
            //@ts-ignore
            this.setAriaAttribute(this.$refs.deleteDialog);
          });
        }
      }
    }
  }
});
