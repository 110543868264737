
import Vue from "vue";

import ProgressBar from "@/components/common/ProgressBar.vue";

import DateInput from "@/components/common/DateInput.vue";
import store from "@/store";
import { APPL_STATUS } from "@/constants/CommonConstants";
import tesControlTemplate from "@/components/common/TESControlTemplate.vue";
import axios from "axios";
import { clone } from "@/util/clone";
import { uiFeatureDefaultModel } from "@/data/AppData";
import { IAdvacnedSearch, ITESUser, ICountyList } from "@/types";
import { TES_APP_ENDPOINT } from "@/constants/EndPoints";

import AdvancedSearchResults from "@/components/search/AdvancedSearchResults.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";

export default Vue.extend({
  name: "advanced-search",
  mixins: [UIFeatureMixinVue, fieldEntryMixin, AppPropsMixin, ApplicationMixin],
  components: {
    ProgressBar,
    DateInput,
    AdvancedSearchResults,
    tesControlTemplate
  },

  data() {
    return {
      showProgressBar: false,
      agencyProviders: [] as Object[],
      showResults: false,
      countyListObj: {} as ICountyList
    };
  },
  created() {
    //@ts-ignore
    store.dispatch.dataModule.getAllCounties(); //to commit in store, calls the action
    if (this.filteredCounties.length === 0) {
      store.dispatch.dataModule.getFilteredCounties();
    }

    store.dispatch.dataModule.getAgencyCounties(
      this.userInfo.transitAgencyCode
    );
  },
  beforeDestroy() {
    this.reset();
  },
  computed: {
    userInfo: {
      get(): ITESUser {
        return store.getters.tesUserModule.getTesUser;
      }
    },
    counties(): [] {
      //@ts-ignore
      return store.getters.dataModule.getCounties; //to get from store
    },
    showSubmittedDates() {
      let returnVal = false;
      //@ts-ignore
      if (
        this.advancedSearch.advancedSearchCriteria.statusList != null &&
        this.advancedSearch.advancedSearchCriteria.statusList.length != 0 &&
        (this.advancedSearch.advancedSearchCriteria.statusList.includes(
          APPL_STATUS.SUBMITTED
        ) ||
          this.advancedSearch.advancedSearchCriteria.statusList.includes(
            APPL_STATUS.UNDER_REVIEW
          ) ||
          this.advancedSearch.advancedSearchCriteria.statusList.includes(
            APPL_STATUS.RETURN_FOR_CORRECTION
          ) ||
          this.advancedSearch.advancedSearchCriteria.statusList.includes(
            APPL_STATUS.INELIGIBLE
          ) ||
          this.advancedSearch.advancedSearchCriteria.statusList.includes(
            APPL_STATUS.INCOMPLETE
          ) ||
          this.advancedSearch.advancedSearchCriteria.statusList.includes(
            APPL_STATUS.ELIGIBLE
          ) ||
          this.advancedSearch.advancedSearchCriteria.statusList.includes(
            APPL_STATUS.PENDING_CONFIGURATION
          ) ||
          this.advancedSearch.advancedSearchCriteria.statusList.includes(
            APPL_STATUS.APPROVED
          ) ||
          this.advancedSearch.advancedSearchCriteria.statusList.includes(
            APPL_STATUS.TMP_APRVD
          ) ||
          this.advancedSearch.advancedSearchCriteria.statusList.includes(
            APPL_STATUS.PENDING
          ) ||
          this.advancedSearch.advancedSearchCriteria.statusList.includes(
            APPL_STATUS.WITHDRAWN
          ) ||
          this.advancedSearch.advancedSearchCriteria.statusList.includes(
            APPL_STATUS.APEL_INPRG
          ))
      ) {
        returnVal = true;
      }
      return returnVal;
    },
    advancedSearch: {
      get(): IAdvacnedSearch {
        //@ts-ignore
        return store.getters.advancedSearchModule.getAdvancedSearch;
      },
      set(newValue: IAdvacnedSearch) {
        //@ts-ignore
        store.commit.advancedSearchModule.SET_ADVANCED_SEARCH(newValue);
      }
    },
    //need to apply security here
    statusesAvailable(): any {
      let uiFeatureChildrenArr = [] as Object[];
      //@ts-ignore
      let statusArr: string[] = this.uiFeature.ADSRCH_STS.largeText.split(",");

      //@ts-ignore
      if (this.isOrganizationUser) {
        statusArr = statusArr.filter(
          status =>
            status !== APPL_STATUS.SUBMITTED &&
            status !== APPL_STATUS.PENDING_CONFIGURATION &&
            status !== APPL_STATUS.WITHDRAWN
        );
      }

      for (let statusArrObj of statusArr) {
        let uiFeture = clone(uiFeatureDefaultModel);
        //@ts-ignore
        if (this.uiFeature && this.uiFeature[statusArrObj]) {
          //@ts-ignore
          uiFeture = this.uiFeature[statusArrObj];
        }

        if (statusArrObj === APPL_STATUS.SUBMITTED) {
          uiFeture.largeText = "Submitted";
          uiFeatureChildrenArr.push(uiFeture);
        } else if (statusArrObj === APPL_STATUS.PENDING_CONFIGURATION) {
          uiFeture.largeText = "Pending Config.";
          uiFeatureChildrenArr.push(uiFeture);
        } else if (statusArrObj === APPL_STATUS.APEL_INPRG) {
          uiFeture.largeText = "Appeal In Progress";
          uiFeatureChildrenArr.push(uiFeture);
        } else {
          //@ts-ignore
          uiFeatureChildrenArr.push(uiFeture);
        }

        if (
          //@ts-ignore
          this.isAgencyUser &&
          statusArrObj === APPL_STATUS.RETURN_FOR_CORRECTION
        ) {
          uiFeture.largeText = uiFeture.displayName;
        }
      }
      return uiFeatureChildrenArr;
    },
    programsAvailable(): any {
      //@ts-ignore
      return this.uiFeature.ABOUT_FUN.children;
    },
    filteredCounties(): [] {
      return store.getters.dataModule.getFilteredCountiesInStore;
    },
    agencyCounties(): [] {
      return store.getters.dataModule.getAgencyCountiesInStore;
    }
  },
  methods: {
    search() {
      this.showProgressBar = true;
      let url = TES_APP_ENDPOINT.PERFORM_SEARCH;
      if (this.userInfo.transitAgencyCode) {
        this.advancedSearch.userAgency = this.userInfo.transitAgencyCode;
      }
      //@ts-ignore
      if (this.isOrganizationUser) {
        this.advancedSearch.orgUser = true;
        this.advancedSearch.feinHash = this.userInfo.fein;
      }
      //@ts-ignore
      if (this.isAgencyUser) {
        this.advancedSearch.feinHash = this.userInfo.fein;
      }
      axios
        .post(url, this.advancedSearch)
        .then(response => {
          //@ts-ignore
          this.advancedSearch = response.data;
          this.showResults = true;
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    },

    reset() {
      this.showResults = false;
      //@ts-ignore
      this.$refs.advancedSearchForm.reset();
      this.advancedSearch.advancedSearchCriteria.fromDate = "";
      this.advancedSearch.advancedSearchCriteria.toDate = "";
      this.advancedSearch.advancedSearchCriteria.dob = "";
    },
    getAgencyList() {
      this.showProgressBar = true;
      let url = TES_APP_ENDPOINT.GET_TRANSIT_AGENCY_PROVIDERS_IN_COUNTIES;
      this.countyListObj.countyList = this.advancedSearch.advancedSearchCriteria.countyList;
      this.countyListObj.userAgency = this.userInfo.transitAgencyCode;
      axios
        .post(url, this.countyListObj)
        .then(response => {
          if (response.data) {
            this.agencyProviders = response.data;
          } else {
            this.agencyProviders = [] as Object[];
            this.advancedSearch.advancedSearchCriteria.agencyList = [] as string[];
          }
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    }
  }
});
