
import Vue from "vue";
import router from "@/router";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";

export default Vue.extend({
  mixins: [UIFeatureMixinVue],
  methods: {
    getAbout() {
      router.push({ name: "about" });
    }
  }
});
