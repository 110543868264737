
import Vue from "vue";
import store from "@/store";
import { ITESUser } from "@/types";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import ClearStoreValuesMixinVue from "@/mixins/ClearStoreValuesMixin.vue";
import axios from "axios";
import { TES_APP_ENDPOINT } from "@/constants/EndPoints";
import router from "@/router";
import moment from "moment";

export default Vue.extend({
  name: "Idle-Logout",
  mixins: [AppPropsMixin, ClearStoreValuesMixinVue],
  data() {
    return {
      showIdleLogoutWarning: false,
      minsAfterWarningUntilIdleLogout: 5 as number,
      secondsUntilIdleLogout: 0 as number,
      idleLogoutInterval: 0 as number,
      timeStamp: null,
      liveRegionEnable: "polite"
    };
  },
  computed: {
    userInfo: {
      get(): ITESUser {
        return store.getters.tesUserModule.getTesUser;
      }
    },
    timeRemaining() {
      return moment.utc(this.secondsUntilIdleLogout * 1000).format("HH:mm:ss");
    }
  },
  watch: {
    isAppIdle() {
      //@ts-ignore
      if (this.isAppIdle && this.userInfo.userId) {
        this.showIdleLogoutWarning = true;
        //@ts-ignore
        this.timeStamp = this.$moment()
          .add(this.minsAfterWarningUntilIdleLogout, "minutes")
          .format("h:mm A");
        this.startIdleLogoutTimer();
      }
      //@ts-ignore
      if (!this.isAppIdle) {
        this.showIdleLogoutWarning = false;
        clearInterval(this.idleLogoutInterval);
      }
    }
  },
  methods: {
    startIdleLogoutTimer() {
      this.secondsUntilIdleLogout = this.minsAfterWarningUntilIdleLogout * 60;

      this.idleLogoutInterval = setInterval(() => {
        this.secondsUntilIdleLogout = this.secondsUntilIdleLogout - 1;

        // Enable/disable live region to choose when the screen reader reads off time remaining.
        // Enabled at intervals of 1 minute, then every 10 seconds for the last 30 seconds
        // before logout.
        if (
          (this.secondsUntilIdleLogout <= 30 &&
            this.secondsUntilIdleLogout % 10 === 0) ||
          this.secondsUntilIdleLogout % 60 === 0
        ) {
          this.liveRegionEnable = "polite";
        } else {
          this.liveRegionEnable = "off";
        }

        if (this.secondsUntilIdleLogout <= 0) {
          this.userLogout();
        }
      }, 1000);
    },
    userLogout() {
      clearInterval(this.idleLogoutInterval);
      if (this.userInfo.userId) {
        let logoutUrl = TES_APP_ENDPOINT.USER_LOGOUT;
        axios
          .post(logoutUrl, this.userInfo)
          .then(response => {
            //@ts-ignore
            this.clearAllStoreValues();
            //@ts-ignore
            this.userInfo.isLoggedOut = true;
          })
          .catch(error => {
            store.dispatch.alertModule.showAlertMessageById(
              error.response.data.id
            );
          })
          .finally(() => {
            this.showIdleLogoutWarning = false;
            router.push({ name: "home", params: { idleLogout: "true" } });
          });
      } else {
        this.showIdleLogoutWarning = false;
        router.push({ name: "home", params: { idleLogout: "true" } });
      }
    }
  },
  destroyed() {
    // Clear the interval if the component is destroyed
    clearInterval(this.idleLogoutInterval);
  }
});
