
import Vue from "vue";
import PWDProgramInfoReview from "@/components/application/program/pwd/review/PWDProgramInfoReview.vue";
import PWDGeneralInfoReview from "@/components/application/program/pwd/review/PWDGeneralInfoReview.vue";
import AttachmentsReview from "@/components/application/program/AttachmentsReview.vue";
import ApplicationReviewControls from "@/components/application/program/ApplicationReviewControls.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import { IDraftProgramInfo, IProgramsList } from "@/types";
import StepperNavigationMixinVue from "@/mixins/StepperNavigationMixin.vue";
import AgencyReviewMixin from "@/mixins/AgencyReviewMixin.vue";
import UpcomingApptReview from "@/components/application/program/UpcomingApptReview.vue";

export default Vue.extend({
  name: "Program-Review-PWD",
  mixins: [UIFeatureMixinVue, StepperNavigationMixinVue, AgencyReviewMixin],
  components: {
    PWDProgramInfoReview,
    PWDGeneralInfoReview,
    AttachmentsReview,
    ApplicationReviewControls,
    UpcomingApptReview
  },
  props: {
    applicantProgramInfoObj: {
      type: Object as () => IProgramsList
    },
    draftProgramInfoObj: {
      type: Object as () => IDraftProgramInfo
    },
    showApplRevCtrls: { type: Boolean, default: false }
  },
  data: function() {
    return {
      singleFlow: false,
      documentTypeCodes: [] as string[]
    };
  },
  created() {
    if (
      //@ts-ignore
      this.isSingleFlow()
    ) {
      this.singleFlow = true;
      //@ts-ignore
    } else if (!this.isLANTA) {
      //@ts-ignore
      this.documentTypeCodes = this.getPWDDocumentTypes();
    }
  }
});
