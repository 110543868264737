var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{class:_vm.$vuetify.breakpoint.smAndDown ? '' : 'borderRadius',attrs:{"flat":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"sm":"12","md":"8","lg":"8","cols":"12"}},[_c('v-card',{staticClass:"pl-3 pr-5 py-0 py-md-3",attrs:{"flat":""}},[(!_vm.$vuetify.breakpoint.smAndDown)?_c('span',{staticClass:"requiredLegend"},[_vm._v("Required *")]):_vm._e(),_c('v-expansion-panels',{staticStyle:{"z-index":"auto"},attrs:{"multiple":""}},[(_vm.$vuetify.breakpoint.smAndDown)?_c('ExpansionPanelTemplate',{attrs:{"iconTextLabel":_vm.expPanelIconObj,"addpadding":'px-0 py-0'}},[_c('span',{attrs:{"slot":"header"},slot:"header"},[_c('span',{staticClass:"error--text font-weight-bold"},[_vm._v("Required *")])]),_c('v-card',{staticClass:"bgcolor pa-5",attrs:{"tile":"","height":"100%"}},[_c('div',{staticClass:"divWithH6Styles",domProps:{"innerHTML":_vm._s(_vm.uiFeature.MORE_INFO.displayName)}}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.textForAddress)}})])],1):_vm._e()],1),_c('v-row',[_c('v-col',[_c('tes-control-template',{attrs:{"required":"","label":"Address Line 1 (Street Number & Street Name)"}},[_c('v-text-field',{attrs:{"maxlength":"50","outlined":"","required":"","persistent-hint":"","hint":_vm.uiFeature.ADDR_TEXT.largeText,"rules":[_vm.rules.required, _vm.rules.streetNumberNameValidation],"aria-label":_vm.getAriaLabel(
                    _vm.addressLocal.addressLine1,
                    [_vm.rules.required, _vm.rules.streetNumberNameValidation],
                    'Address Line 1 Street Number and Street Name',
                    'Address Line 1 Street Number and Street Name has'
                  )},model:{value:(_vm.addressLocal.addressLine1),callback:function ($$v) {_vm.$set(_vm.addressLocal, "addressLine1", $$v)},expression:"addressLocal.addressLine1"}})],1)],1)],1),_c('v-row',[_c('v-col',[_c('tes-control-template',{attrs:{"label":"Address Line 2 (Apartment Number/Building Number)"}},[_c('v-text-field',{attrs:{"maxlength":"20","persistent-hint":"","hint":_vm.uiFeature.ADDR_APT.largeText,"rules":[_vm.rules.commaNotAllowed],"outlined":"","aria-label":_vm.getAriaLabel(
                    _vm.addressLocal.addressLine2,
                    [_vm.rules.commaNotAllowed],
                    'Address Line 2 Apartment Number or Building Number',
                    'Address Line 2 Apartment Number or Building Number has'
                  )},model:{value:(_vm.addressLocal.addressLine2),callback:function ($$v) {_vm.$set(_vm.addressLocal, "addressLine2", $$v)},expression:"addressLocal.addressLine2"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('tes-control-template',{attrs:{"required":"","label":"City"}},[_c('v-text-field',{attrs:{"maxlength":"30","required":"","rules":[_vm.rules.required, _vm.rules.nameValidation],"outlined":"","aria-label":_vm.getAriaLabel(
                    _vm.addressLocal.city,
                    [_vm.rules.required, _vm.rules.nameValidation],
                    'City',
                    'City has'
                  )},on:{"change":function($event){_vm.addressLocal.city = _vm.addressLocal.city.trim()}},model:{value:(_vm.addressLocal.city),callback:function ($$v) {_vm.$set(_vm.addressLocal, "city", $$v)},expression:"addressLocal.city"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('tes-control-template',{attrs:{"required":"","label":"State"}},[_c('v-select',{attrs:{"items":_vm.uiFeature.STATE.children,"item-text":"displayName","item-value":"resourceCode","outlined":"","required":"","rules":[_vm.rules.required],"aria-label":"State"},model:{value:(_vm.addressLocal.state),callback:function ($$v) {_vm.$set(_vm.addressLocal, "state", $$v)},expression:"addressLocal.state"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('tes-control-template',{attrs:{"required":"","label":"Zip"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.masks.zipMask),expression:"masks.zipMask"}],attrs:{"outlined":"","required":"","rules":[_vm.rules.required, _vm.rules.ZipCodeUS],"aria-label":_vm.getAriaLabel(
                    _vm.zipCode,
                    [_vm.rules.required, _vm.rules.ZipCodeUS],
                    'Zip Code',
                    'Zip format should be like'
                  )},model:{value:(_vm.zipCode),callback:function ($$v) {_vm.zipCode=$$v},expression:"zipCode"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('tes-control-template',{attrs:{"required":"","label":"County"}},[_c('v-select',{attrs:{"items":_vm.isHomeAddress ? _vm.homeCounties : _vm.counties,"item-text":"name","item-value":"id","outlined":"","required":"","rules":[_vm.rules.required],"aria-label":"Select a County"},model:{value:(_vm.addressLocal.paCountyCode),callback:function ($$v) {_vm.$set(_vm.addressLocal, "paCountyCode", $$v)},expression:"addressLocal.paCountyCode"}})],1)],1),(_vm.isHomeAddress && _vm.addressLocal.paCountyCode === '14')?_c('v-col',{staticClass:"pl-8",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('TESRadioControl',{attrs:{"questionText":_vm.stateCollegeQues,"requiredQuestion":"","rules":[_vm.rules.required],"radioGroupId":"radio_stateCollegeInd"},model:{value:(_vm.addressLocal.stateCollegeInd),callback:function ($$v) {_vm.$set(_vm.addressLocal, "stateCollegeInd", $$v)},expression:"addressLocal.stateCollegeInd"}})],1):_vm._e()],1),(_vm.isHomeAddress)?_c('v-row',[_c('v-col',[_c('TESRadioControl',{attrs:{"questionText":_vm.isMailAddressSameQues,"requiredQuestion":"","rules":[_vm.rules.required],"options":_vm.radioOptions,"radioGroupId":"radio_sameMailingLocal"},model:{value:(_vm.sameMailingLocal),callback:function ($$v) {_vm.sameMailingLocal=$$v},expression:"sameMailingLocal"}})],1)],1):_vm._e(),(_vm.isHomeAddress && _vm.addressLocal.paCountyCode)?_c('v-row',[_c('v-col',[_c('TESRadioControl',{attrs:{"questionText":_vm.uiFeature.ACBL_PRT_Q.largeText,"requiredQuestion":"","rules":[_vm.rules.required],"options":_vm.radioOptions,"radioGroupId":"radio_accessiblePrintMatIndLcl"},model:{value:(_vm.accessiblePrintMatIndLcl),callback:function ($$v) {_vm.accessiblePrintMatIndLcl=$$v},expression:"accessiblePrintMatIndLcl"}})],1)],1):_vm._e(),(
            !_vm.$vuetify.breakpoint.smAndDown &&
              (_vm.addressLocal.paCountyCode === '51' ||
                _vm.addressLocal.paCountyCode === '02') &&
              _vm.accessiblePrintMatIndLcl === 'Y'
          )?_c('div',{staticClass:"py-3 px-3 no-gutters"},[_c('tes-control-template',{attrs:{"required":"","slotRowClass":"d-block","label":_vm.uiFeature.ACCBL_FRMT.displayName}},[_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.excludeOtherOptn()),function(m,index){return _c('v-col',{key:index},[_c('v-checkbox',{key:index,attrs:{"value":m.resourceCode,"label":m.displayName,"rules":[_vm.rules.specialNeedsRule],"aria-label":`${_vm.uiFeature.ACCBL_FRMT.displayName} ${m.displayName}`},model:{value:(_vm.printMatFormatsListLcl),callback:function ($$v) {_vm.printMatFormatsListLcl=$$v},expression:"printMatFormatsListLcl"}})],1)}),1),_c('v-row',[_c('v-col',[_c('v-checkbox',{attrs:{"value":_vm.uiFeature.ACCBL_FRMT.children[3].resourceCode,"label":_vm.uiFeature.ACCBL_FRMT.children[3].displayName,"rules":[_vm.rules.specialNeedsRule],"aria-label":`${_vm.uiFeature.ACCBL_FRMT.displayName} ${_vm.uiFeature.ACCBL_FRMT.children[3].displayName}`},model:{value:(_vm.printMatFormatsListLcl),callback:function ($$v) {_vm.printMatFormatsListLcl=$$v},expression:"printMatFormatsListLcl"}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(
                  _vm.uiFeature.ACCBL_FRMT.children[3].resourceCode ===
                    'ACCBL_OTHR' &&
                    _vm.printMatFormatsListLcl.includes(
                      _vm.uiFeature.ACCBL_FRMT.children[3].resourceCode
                    )
                ),expression:"\n                  uiFeature.ACCBL_FRMT.children[3].resourceCode ===\n                    'ACCBL_OTHR' &&\n                    printMatFormatsListLcl.includes(\n                      uiFeature.ACCBL_FRMT.children[3].resourceCode\n                    )\n                "}]},[_c('v-textarea',{attrs:{"rows":"1","auto-grow":"","cols":"100","solo":"","maxlength":"200","aria-label":"Other Text","placeholder":"Enter Other Here"},model:{value:(_vm.otherPrintMatFormatLcl),callback:function ($$v) {_vm.otherPrintMatFormatLcl=$$v},expression:"otherPrintMatFormatLcl"}})],1)],1)],1)],1):_vm._e(),(
            _vm.$vuetify.breakpoint.smAndDown &&
              (_vm.addressLocal.paCountyCode === '51' ||
                _vm.addressLocal.paCountyCode === '02') &&
              _vm.accessiblePrintMatIndLcl === 'Y'
          )?_c('div',{staticClass:"py-3 px-3 no-gutters"},[_c('tes-control-template',{attrs:{"required":"","slotRowClass":"d-block","label":_vm.uiFeature.ACCBL_FRMT.displayName}},[_vm._l((_vm.excludeOtherOptn()),function(m,index){return _c('v-row',{key:index,attrs:{"no-gutters":""}},[_c('v-col',[_c('v-checkbox',{key:index,attrs:{"value":m.resourceCode,"label":m.displayName,"rules":[_vm.rules.specialNeedsRule]},model:{value:(_vm.printMatFormatsListLcl),callback:function ($$v) {_vm.printMatFormatsListLcl=$$v},expression:"printMatFormatsListLcl"}})],1)],1)}),_c('v-row',[_c('v-col',[_c('v-checkbox',{attrs:{"value":_vm.uiFeature.ACCBL_FRMT.children[3].resourceCode,"label":_vm.uiFeature.ACCBL_FRMT.children[3].displayName,"rules":[_vm.rules.specialNeedsRule]},model:{value:(_vm.printMatFormatsListLcl),callback:function ($$v) {_vm.printMatFormatsListLcl=$$v},expression:"printMatFormatsListLcl"}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(
                  _vm.uiFeature.ACCBL_FRMT.children[3].resourceCode ===
                    'ACCBL_OTHR' &&
                    _vm.printMatFormatsListLcl.includes(
                      _vm.uiFeature.ACCBL_FRMT.children[3].resourceCode
                    )
                ),expression:"\n                  uiFeature.ACCBL_FRMT.children[3].resourceCode ===\n                    'ACCBL_OTHR' &&\n                    printMatFormatsListLcl.includes(\n                      uiFeature.ACCBL_FRMT.children[3].resourceCode\n                    )\n                "}]},[_c('v-textarea',{attrs:{"rows":"1","auto-grow":"","cols":"100","solo":"","maxlength":"200","aria-label":"Other Text","placeholder":"Enter Other Here"},model:{value:(_vm.otherPrintMatFormatLcl),callback:function ($$v) {_vm.otherPrintMatFormatLcl=$$v},expression:"otherPrintMatFormatLcl"}})],1)],1)],2)],1):_vm._e(),(
            _vm.addressLocal.paCountyCode &&
              _vm.addressLocal.paCountyCode !== '51' &&
              _vm.addressLocal.paCountyCode !== '02' &&
              _vm.accessiblePrintMatIndLcl === 'Y'
          )?_c('v-row',[_c('v-col',[_c('InfoSection',{attrs:{"header":_vm.uiFeature.ACBL_INFO.largeText}})],1)],1):_vm._e()],1)],1),(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-col',{attrs:{"sm":"12","md":"4","lg":"4","cols":"12"}},[_c('v-card',{staticClass:"bgcolor pa-5",attrs:{"aria-live":"assertive","tile":"","height":"100%"}},[_c('div',{staticClass:"divWithH6Styles",domProps:{"innerHTML":_vm._s(_vm.uiFeature.MORE_INFO.displayName)}}),_c('v-expansion-panels',{staticStyle:{"z-index":"auto"},attrs:{"multiple":""}},[_c('ExpansionPanelTemplate',{attrs:{"iconTextLabel":_vm.expPanelIconObj,"addpadding":'px-0 py-0',"addColor":"#BDE5FF"}},[_c('span',{attrs:{"slot":"header"},slot:"header"},[_c('span',{staticClass:"font-weight-bold",domProps:{"innerHTML":_vm._s(_vm.uiFeature.WHY_TXT.displayName)}})]),_c('div',{staticClass:"bgcolor pa-1",attrs:{"tile":"","height":"100%"}},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.textForAddress)}})])])],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }