
import Vue from "vue";
import store from "@/store";
import ReadOnlyField from "@/components/common/ReadOnlyField.vue";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import { GPC } from "@/constants/GPCCode";

export default Vue.extend({
  components: { ReadOnlyField },
  name: "PhysicianInfoReview",
  mixins: [fieldEntryMixin, UIFeatureMixinVue],
  data() {
    return {
      GPC
    };
  },
  computed: {
    draftPhysicianInfos: () =>
      store.getters.draftProgramInfoModule.getDraftProgramInfo
        .draftPhysicianInfos,
    multiplePhysicianInd: () =>
      store.getters.draftProgramInfoModule.getDraftProgramInfo
        .multiplePhysicianInd
  },
  methods: {
    fullZipCode(zip: string, zipExt: string | undefined) {
      if (zipExt && zipExt.trim.length > 0) {
        return zip + "-" + zipExt;
      } else {
        return zip;
      }
    }
  }
});
