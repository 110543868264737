
import Vue from "vue";
import {
  IApplicantAddress,
  IApplicationNavigation,
  IProgramsList,
  IScreeningResponse,
  ITESUser,
  IDraftProgramInfo,
  IApplicantProfile
} from "@/types";
import dateHelperMixin from "@/mixins/dateHelperMixin.vue";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import ApplicantProfileDashboard from "@/components/dashboard/applicant/ApplicantProfileDashboard.vue";
import PersonalInfoReview from "@/components/application/profilereview/PersonalInfoReview.vue";
import HomeAddressReview from "@/components/application/profilereview/HomeAddressReview.vue";
import DriverLicenseReview from "@/components/application/profilereview/DriverLicenseReview.vue";
import EmergencyContactReview from "@/components/application/profilereview/EmergencyContactReview.vue";
import DemographicInfoReview from "@/components/application/profilereview/DemographicInfoReview.vue";
import TransportationInfoReview from "@/components/application/profilereview/TransportationInfoReview.vue";
import MobilityInfoReview from "@/components/application/profilereview/MobilityInfoReview.vue";
import PreparerInfoReview from "@/components/application/profilereview/PreparerInfoReview.vue";
import editProfileProgramChangesMsg from "@/components/application/EditProfileProgramChangesMsg.vue";
import ProgressBar from "@/components/common/ProgressBar.vue";
import store from "@/store";
import router from "@/router";
import routerMixin from "@/mixins/RouterMixin.vue";
import StepperNavigationMixinVue from "@/mixins/StepperNavigationMixin.vue";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";
import ApplicantProfileMixin from "@/mixins/ApplicantProfileMixin.vue";
import { clone } from "@/util/clone";
import { applicantAddress } from "@/data/AppData";

export default Vue.extend({
  name: "review-profile-info",
  mixins: [
    dateHelperMixin,
    fieldEntryMixin,
    routerMixin,
    StepperNavigationMixinVue,
    ApplicationMixin,
    ApplicantProfileMixin
  ],
  components: {
    ApplicantProfileDashboard,
    PersonalInfoReview,
    HomeAddressReview,
    EmergencyContactReview,
    DemographicInfoReview,
    PreparerInfoReview,
    ProgressBar,
    editProfileProgramChangesMsg,
    TransportationInfoReview,
    MobilityInfoReview,
    DriverLicenseReview
  },
  data() {
    return {
      showDashboard: false as boolean,
      singleFlow: false
    };
  },
  props: {
    applicationReview: { type: Boolean, default: true }
  },
  computed: {
    homeAddressPrevious(): IApplicantAddress {
      //@ts-ignore
      let hAddress: IApplicantAddress = this.applicantProfileInfoPrevious[
        "homeAddress"
      ];

      if (!hAddress) {
        hAddress = clone(applicantAddress);
      }
      return hAddress;
    },
    mailingAddressPrevious(): IApplicantAddress {
      //@ts-ignore
      let mAddress: IApplicantAddress = this.applicantProfileInfoPrevious[
        "mailingAddress"
      ];

      if (!mAddress) {
        mAddress = clone(applicantAddress);
      }

      return mAddress;
    },
    applicationNavigation: {
      get(): IApplicationNavigation {
        return store.getters.applicationNavigationModule
          .getApplicationNavigation;
      }
    },
    isDataLoading: {
      get(): boolean {
        return store.getters.uiFeatureModule.getLoadingStatus;
      }
    },
    applicantProgramInfo: {
      get(): IProgramsList {
        return store.getters.applicantProgramModule.getApplicantProgram;
      }
    },
    screeningResponse: {
      get(): IScreeningResponse {
        return store.getters.screeningResponseModule.getScreeningResponse;
      }
    },
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      }
    },
    userInfo(): ITESUser {
      return store.getters.tesUserModule.getTesUser;
    },
    applicantProfile(): IApplicantProfile {
      return store.getters.applicantProfileModule.getApplicantProfile;
    },
    showApplRevCtrls(): boolean {
      let showButtons = false;
      if (
        //@ts-ignore
        this.applicantProfile.applicantUserId === this.userInfo.userId
      ) {
        showButtons = true;
      }
      return showButtons;
    },
    isAlleghenyCounty(): boolean {
      if (
        //@ts-ignore
        this.applicantProfile.homeAddress.paCountyCode === "02"
      ) {
        return true;
      } else return false;
    }
  },
  created() {
    store.dispatch.dataModule.getAllCounties();

    if (
      //@ts-ignore
      this.isSingleFlow()
    ) {
      this.singleFlow = true;
    }
  },
  mounted() {
    //@ts-ignore
    let cycleNumber = this.applicantProfile.cycleNumber;

    if (cycleNumber && cycleNumber > 0) {
      store.dispatch.applicantProfileModule.populateProfilePrevious();
      store.dispatch.draftProgramInfoModule.loadDraftProgramInfoPrevious();
    }
  },
  methods: {
    displayDashBoard() {
      this.showDashboard = true;
    },
    editProfile() {
      if (!this.applicationReview) {
        this.applicationNavigation.applProfileEdit = true;
      } else {
        this.applicationNavigation.applProfileEdit = false;
      }
      store.commit.applicationNavigationModule.SET_APPLLICATION_NAVIGATION(
        this.applicationNavigation
      );
      //@ts-ignore
      this.applicantProfile.profileEditMsg = "";
      //@ts-ignore
      this.applicantProfile.profileSuccessMsg = "";
      router.push({
        name: "editapp",
        query: {
          //@ts-ignore
          profileID: String(this.applicantProfile.applicantProfileId),
          scrnRespID: String(this.screeningResponse.screeningResponseId)
        }
      });
    },
    GotoDashboard() {
      //@ts-ignore
      this.forwardToDashboard();
    }
  }
});
