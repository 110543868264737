
import Vue from "vue";
import store from "@/store";
import {
  IScreeningQuestions,
  IScreeningResponse,
  IFixedRouteScreeningResults,
  ITESUser,
  IApplicantDetails
} from "@/types";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";
import { CNST_TEXT } from "@/constants/CommonConstants";

export default Vue.extend({
  mixins: [UIFeatureMixinVue, ApplicationMixin],
  computed: {
    userInfo: {
      get(): ITESUser {
        //@ts-ignore
        return store.getters.tesUserModule.getTesUser;
      }
    },

    applicantDetails: {
      get(): IApplicantDetails {
        //@ts-ignore
        return store.getters.applicantDetailsModule.getApplicantDetails;
      }
    },
    questions: {
      get(): IScreeningQuestions[] {
        return store.getters.screenQuesModule.getQuestions;
      },
      set(newValue: IScreeningQuestions[]) {
        store.commit.screenQuesModule.SET_QUESTIONS(newValue);
      }
    },
    screeningResponse: {
      get(): IScreeningResponse {
        return store.getters.screeningResponseModule.getScreeningResponse;
      }
    }
  },
  methods: {
    // Method to build Screening_Response entity
    buildScreeningResponseEntity: function(
      quests: IScreeningQuestions[],
      ansrs: Array<string>
    ) {
      let scrResponses = {} as IScreeningResponse;
      let questions = quests;
      let answers = ansrs;
      let fixedRouteScreeningResults = {} as IFixedRouteScreeningResults;
      let quesIndex = 1;

      this.buildScreeningResp(
        questions,
        answers,
        scrResponses,
        quesIndex,
        fixedRouteScreeningResults
      );
      //@ts-ignore
      scrResponses.userId = this.userInfo.userId;

      if (this.applicantDetails && this.applicantDetails.applicantFirstName) {
        scrResponses.applicantFirstName = this.applicantDetails.applicantFirstName;

        scrResponses.applicantLastName = this.applicantDetails.applicantLastName;
        if (this.applicantDetails.isSurrogate) {
          scrResponses.onBehalfOfInd = "Y";
        }
      }

      return scrResponses;
    },
    getCodeForValue(
      codeName: string,
      quest: IScreeningQuestions[],
      quesIndex: number
    ): any {
      let codeValueNames = quest[quesIndex - 1]["values"];
      let code: any;
      for (let i in codeValueNames) {
        //@ts-ignore
        if (codeValueNames[i]["name"] === codeName) {
          //@ts-ignore
          code = codeValueNames[i]["id"];
          break;
        }
      }
      return code;
    },
    buildScreeningResp(
      questions: IScreeningQuestions[],
      answers: Array<string>,
      scrResponses: IScreeningResponse,
      quesIndex: number,
      fixedRouteScreeningResults: IFixedRouteScreeningResults
    ): any {
      let answer: string;

      for (let question of questions) {
        answer = answers[question.qid];

        switch (question.lineItemid) {
          case "SQ1":
            scrResponses.paCountyCode = this.getCodeForValue(
              answer,
              questions,
              quesIndex
            );
            break;
          case "SQ2":
            scrResponses.stateCollegeInd = answer;
            break;
          case "SQ3":
            scrResponses.applicantDob = answer;
            scrResponses.ageGroup = this.getAgeGroup(answer);
            break;
          case "SQ4":
            scrResponses.medicaidInd = answer;
            break;
          case "SQ5":
            scrResponses.transportationDisabilityInd = answer;
            break;
          case "SQ7":
            scrResponses.veteranInd = answer;
            break;
          case "SQ8":
            scrResponses.medicareCardInd = answer;
            break;
          default:
            scrResponses.selectedPrograms = [];
        }
      }
    },
    getAgeGroup(date: string): string {
      //@ts-ignore
      let age: number = this.$moment().diff(date, "years", true);
      if (age < 18) {
        if (age >= 17) {
          //@ts-ignore
          return this.uiFeature.ABOVE_17.displayName;
        } else {
          //@ts-ignore
          return this.uiFeature.UNDER_18.displayName;
        }
      } else if (age >= 18 && age < 65) {
        if (age < 60) {
          //@ts-ignore
          return this.uiFeature.BTWN_1864.displayName;
        } else {
          //@ts-ignore
          return this.uiFeature.BTWN_6064.displayName;
        }
      } else {
        //@ts-ignore
        return this.uiFeature.ABOVE_65.displayName;
      }
    },
    questionsByContext() {
      this.loadSQ1DynamixText();
      this.loadSQ2DynamixText();
      this.loadSQ3DynamixText();
      this.loadSQ4DynamixText();
      this.loadSQ5DynamixText();
      this.loadSQ7DynamixText();
      this.loadSQ8DynamixText();
    },
    loadSQ1DynamixText() {
      let index = this.questions.findIndex(
        (o: { lineItemid: string }) => o.lineItemid === "SQ1"
      );
      if (index != -1) {
        if (
          //@ts-ignore
          this.isOrganizationUser ||
          //@ts-ignore
          this.isAgencyUser ||
          //@ts-ignore
          (this.isCitizenUser &&
            (this.screeningResponse.onBehalfOfInd === "Y" ||
              this.applicantDetails.isSurrogate))
        ) {
          //@ts-ignore
          this.questions[index].qtext = this.buildDynamicText(
            this.questions[index].qtext,
            CNST_TEXT.THE_APPLICANTS
          );
          //@ts-ignore
          this.questions[index].whyQuesText = this.buildDynamicText(
            this.questions[index].whyQuesText,
            CNST_TEXT.APPLICANTS_THE,
            CNST_TEXT.THEIR,
            CNST_TEXT.THEIR
          );
        } else {
          //@ts-ignore
          this.questions[index].qtext = this.buildDynamicText(
            this.questions[index].qtext,
            CNST_TEXT.YOUR
          );
          //@ts-ignore
          this.questions[index].whyQuesText = this.buildDynamicText(
            this.questions[index].whyQuesText,
            "Your",
            CNST_TEXT.YOUR,
            CNST_TEXT.YOUR
          );
        }
      }
    },
    loadSQ2DynamixText() {
      let index = this.questions.findIndex(
        (o: { lineItemid: string }) => o.lineItemid === "SQ2"
      );
      if (index != -1) {
        if (
          //@ts-ignore
          this.isOrganizationUser ||
          //@ts-ignore
          this.isAgencyUser ||
          //@ts-ignore
          (this.isCitizenUser &&
            (this.screeningResponse.onBehalfOfInd === "Y" ||
              this.applicantDetails.isSurrogate))
        ) {
          //@ts-ignore
          this.questions[index].qtext = this.buildDynamicText(
            this.questions[index].qtext,
            CNST_TEXT.THE_APPLICANT
          );
          //@ts-ignore
          this.questions[index].whyQuesText = this.buildDynamicText(
            this.questions[index].whyQuesText,
            CNST_TEXT.THE_APPLICANT_LIVES,
            CNST_TEXT.THEY
          );
        } else {
          //@ts-ignore
          this.questions[index].qtext = this.buildDynamicText(
            this.questions[index].qtext,
            CNST_TEXT.YOU
          );
          //@ts-ignore
          this.questions[index].whyQuesText = this.buildDynamicText(
            this.questions[index].whyQuesText,
            "you live",
            CNST_TEXT.YOU
          );
        }
      }
    },
    loadSQ3DynamixText() {
      let index = this.questions.findIndex(
        (o: { lineItemid: string }) => o.lineItemid === "SQ3"
      );
      if (index != -1) {
        if (
          //@ts-ignore
          this.isOrganizationUser ||
          //@ts-ignore
          this.isAgencyUser ||
          //@ts-ignore
          (this.isCitizenUser &&
            (this.screeningResponse.onBehalfOfInd === "Y" ||
              this.applicantDetails.isSurrogate))
        ) {
          //@ts-ignore
          this.questions[index].qtext = this.buildDynamicText(
            this.questions[index].qtext,
            CNST_TEXT.THE_APPLICANTS
          );
          //@ts-ignore
          this.questions[index].whyQuesText = this.buildDynamicText(
            this.questions[index].whyQuesText,
            CNST_TEXT.THE_APPLICANT,
            CNST_TEXT.THEIR
          );
        } else {
          //@ts-ignore
          this.questions[index].qtext = this.buildDynamicText(
            this.questions[index].qtext,
            CNST_TEXT.YOUR
          );
          //@ts-ignore
          this.questions[index].whyQuesText = this.buildDynamicText(
            this.questions[index].whyQuesText,
            CNST_TEXT.YOU,
            CNST_TEXT.YOUR
          );
        }
      }
    },
    loadSQ4DynamixText() {
      let index = this.questions.findIndex(
        (o: { lineItemid: string }) => o.lineItemid === "SQ4"
      );
      if (index != -1) {
        if (
          //@ts-ignore
          this.isOrganizationUser ||
          //@ts-ignore
          this.isAgencyUser ||
          //@ts-ignore
          (this.isCitizenUser &&
            (this.screeningResponse.onBehalfOfInd === "Y" ||
              this.applicantDetails.isSurrogate))
        ) {
          //@ts-ignore
          this.questions[index].qtext = this.buildDynamicText(
            this.questions[index].qtext,
            CNST_TEXT.DOES_THE_APPLICANT
          );
          //@ts-ignore
          this.questions[index].whyQuesText = this.buildDynamicText(
            this.questions[index].whyQuesText,
            CNST_TEXT.THE_APPLICANT_HAS,
            CNST_TEXT.THEY
          );
        } else {
          //@ts-ignore
          this.questions[index].qtext = this.buildDynamicText(
            this.questions[index].qtext,
            CNST_TEXT.DO_YOU
          );
          //@ts-ignore
          this.questions[index].whyQuesText = this.buildDynamicText(
            this.questions[index].whyQuesText,
            CNST_TEXT.YOU_HAVE,
            CNST_TEXT.YOU
          );
        }
      }
    },
    loadSQ5DynamixText() {
      let index = this.questions.findIndex(
        (o: { lineItemid: string }) => o.lineItemid === "SQ5"
      );
      if (index != -1) {
        if (
          //@ts-ignore
          this.isOrganizationUser ||
          //@ts-ignore
          this.isAgencyUser ||
          //@ts-ignore
          (this.isCitizenUser &&
            (this.screeningResponse.onBehalfOfInd === "Y" ||
              this.applicantDetails.isSurrogate))
        ) {
          //@ts-ignore
          this.questions[index].qtext = this.buildDynamicText(
            this.questions[index].qtext,
            CNST_TEXT.DOES_THE_APPLICANT
          );
          //@ts-ignore
          this.questions[index].whyQuesText = this.buildDynamicText(
            this.questions[index].whyQuesText,
            "the applicant has"
          );
        } else {
          //@ts-ignore
          this.questions[index].qtext = this.buildDynamicText(
            this.questions[index].qtext,
            CNST_TEXT.DO_YOU
          );
          //@ts-ignore
          this.questions[index].whyQuesText = this.buildDynamicText(
            this.questions[index].whyQuesText,
            CNST_TEXT.YOU_HAVE
          );
        }
      }
    },
    loadSQ7DynamixText() {
      let index = this.questions.findIndex(
        (o: { lineItemid: string }) => o.lineItemid === "SQ7"
      );
      if (index != -1) {
        if (
          //@ts-ignore
          this.isOrganizationUser ||
          //@ts-ignore
          this.isAgencyUser ||
          //@ts-ignore
          (this.isCitizenUser &&
            (this.screeningResponse.onBehalfOfInd === "Y" ||
              this.applicantDetails.isSurrogate))
        ) {
          //@ts-ignore
          this.questions[index].qtext = this.buildDynamicText(
            this.questions[index].qtext,
            CNST_TEXT.IS_THE_APPLICANT
          );
          //@ts-ignore
          this.questions[index].whyQuesText = this.buildDynamicText(
            this.questions[index].whyQuesText,
            CNST_TEXT.THE_APPLICANTS
          );
        } else {
          //@ts-ignore
          this.questions[index].qtext = this.buildDynamicText(
            this.questions[index].qtext,
            CNST_TEXT.ARE_YOU
          );
          //@ts-ignore
          this.questions[index].whyQuesText = this.buildDynamicText(
            this.questions[index].whyQuesText,
            CNST_TEXT.YOUR
          );
        }
      }
    },
    loadSQ8DynamixText() {
      let index = this.questions.findIndex(
        (o: { lineItemid: string }) => o.lineItemid === "SQ8"
      );
      if (index != -1) {
        if (
          //@ts-ignore
          this.isOrganizationUser ||
          //@ts-ignore
          this.isAgencyUser ||
          //@ts-ignore
          (this.isCitizenUser &&
            (this.screeningResponse.onBehalfOfInd === "Y" ||
              this.applicantDetails.isSurrogate))
        ) {
          //@ts-ignore
          this.questions[index].qtext = this.buildDynamicText(
            this.questions[index].qtext,
            CNST_TEXT.DOES_THE_APPLICANT
          );
          //@ts-ignore
          this.questions[index].whyQuesText = this.buildDynamicText(
            this.questions[index].whyQuesText,
            CNST_TEXT.THE_APPLICANT_HAS,
            CNST_TEXT.THEY
          );
        } else {
          //@ts-ignore
          this.questions[index].qtext = this.buildDynamicText(
            this.questions[index].qtext,
            CNST_TEXT.DO_YOU
          );
          //@ts-ignore
          this.questions[index].whyQuesText = this.buildDynamicText(
            this.questions[index].whyQuesText,
            CNST_TEXT.YOU_HAVE,
            CNST_TEXT.YOU
          );
        }
      }
    }
  }
});
