<script>
//@ts-ignore
import maskit from "@/util/maskit";
//@ts-ignore
import tokens from "@/util/tokens";
import UIFeatureMixinVue from "./UIFeatureMixin.vue";
export default {
  mixins: [UIFeatureMixinVue],
  data() {
    return {
      acceptedFileExtensions: "image/*,.doc,.docx,.pdf",
      masks: {
        phoneMask: "(###) ###-####",
        sapMask: "##########",
        zipMask: "#####-####",
        dateMask: "##/##/####",
        phoneExtMask: "#####",
        ssnMask: "###-##-####"
      },
      rules: {
        required: value => value === 0 || !!value || "Required.",
        nameValidation: value => {
          const pattern = /^[A-Za-z0-9_ ]*(((.)|(s)|(')|(-)|(&)){0,1}([A-Za-z0-9]{1,100}))*$/i;
          return !value || pattern.test(value) || "Invalid character(s)";
        },
        numberValidation: value => {
          const pattern = /^\d*$/i;
          return !value || pattern.test(value) || "Invalid number(s)";
        },
        zipCode: (countryCode, zipCode) => {
          let pattern = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
          if (countryCode === "US") {
            return pattern.test(zipCode) || "##### OR #####-####";
          } else {
            pattern = /(^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$)/;
            return pattern.test(zipCode) || "XXX XXX";
          }
        },
        ZipCodeUS: value => {
          if (value) {
            return value.length === 10 || value.length === 5 || "55555-5555";
          } else return true;
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return !value || pattern.test(value) || "Invalid e-mail.";
        },
        phoneNumber: value => {
          if (value) {
            return value.length === 14 || "(555) 555-5555";
          } else return true;
        },
        startWithoutZero: value => {
          const pattern = /^((?!(0))[0-9]*)$/;
          let phNumber;
          if (value) {
            phNumber = value
              .replace(/ /g, "") //replace space
              .replace(/[{()}]/g, "") // replace brackets
              .replace(/-/g, ""); // replace hypen
          }

          if (phNumber) {
            //@ts-ignore
            return (
              pattern.test(phNumber) || this.uiFeature.PH_NOTZERO.largeText
            );
          } else return true;
        },
        drivingLicenseNumberLength: val => {
          const pattern = /(^[0-9]{8})$/;
          return !val || pattern.test(val) || "Must have 8 numbers";
        },
        ssnNumberLength: val => {
          const pattern = /^\d{3}-?\d{2}-?\d{4}$/;
          if (val) {
            return !val || pattern.test(val) || "Must have 9 numbers";
          } else return true;
        },
        matpIdLen: val => val.length == 10 || `Must have 10 numbers`,
        dateFormat: val => {
          const pattern = /^(?:(?:(?:0?[13578]|1[02]|(?:Jan|Mar|May|Jul|Aug|Oct|Dec))(\/|-|\.)31)\1|(?:(?:0?[1,3-9]|1[0-2]|(?:Jan|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))(\/|-|\.)(?:29|30)\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:(?:0?2|(?:Feb))(\/|-|\.)(?:29)\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:(?:0?[1-9]|(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep))|(?:1[0-2]|(?:Oct|Nov|Dec)))(\/|-|\.)(?:0?[1-9]|1\d|2[0-8])\4(?:(?:1[6-9]|[2-9]\d)?\d{4})$/;
          return (
            !val ||
            pattern.test(val) ||
            "Date must be in valid format MM/DD/YYYY"
          );
        },
        streetNumberNameValidation: value => {
          const pattern = /^[a-zA-Z0-9]+\s[a-zA-Z0-9]+[\sa-zA-Z0-9.]+/;
          return !value || pattern.test(value) || "Invalid Address";
        },
        fileRules: value => value.length > 0 || "Attachments are required.",
        mobilityDeviceRule(mobilityDevInd) {
          return value =>
            "N" === mobilityDevInd ||
            value.length > 0 ||
            "At least one mobility device is required";
        },
        commaNotAllowed: val => {
          const pattern = /^[^,]+$/;
          return !val || pattern.test(val) || "Comma(,) is not allowed";
        },
        specialNeedsRule: value =>
          value.length > 0 ||
          "At least one special Needs/Nature of Condition is required",
        requiredArray: value => value.length > 0 || "Required.",
        isDatePastOrCurrent: value => {
          return (
            !value ||
            this.$moment(value) <= this.$moment().startOf("day") ||
            "Date must be past or current date."
          );
        },
        isDateCurrentOrFuture: value => {
          return (
            !value ||
            this.$moment(value) >= this.$moment().startOf("day") ||
            "Date cannot be a Past date"
          );
        }
      }
    };
  },
  methods: {
    formatPrice(amount, isNegative) {
      let val = amount.toFixed(2);
      let returnVal = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      if (isNegative) {
        returnVal = "$ - " + returnVal;
      } else {
        returnVal = "$ " + returnVal;
      }
      return returnVal;
    },
    keydownAllowNumberOnly(event) {
      if (this.isLeftUpRightDownBackspaceDeleteTab(event.keyCode)) {
        return;
      }

      let regex = /[0-9]/;

      if (!regex.test(event.key)) {
        event.preventDefault();
        return false;
      }
    },
    allowAlphanumericOnly(event) {
      var blockSpecialCharacterRegex = /^[a-z0-9\s]+$/i;
      var key = String.fromCharCode(
        !event.charCode ? event.which : event.charCode
      );
      if (!blockSpecialCharacterRegex.test(key)) {
        event.preventDefault();
        return false;
      }
    },
    isReturnKey(event) {
      return event.keyCode === 13;
    },
    isLeftUpRightDownBackspaceDeleteTab(keyCode) {
      return (
        keyCode === 37 ||
        keyCode === 38 ||
        keyCode === 39 ||
        keyCode === 40 ||
        keyCode === 8 ||
        keyCode === 46 ||
        keyCode === 9
      );
    },
    appendHyphenAtSecondIndex(value) {
      return value.replace(/(\d{2})(\d{7})/, "$1-$2");
    },
    appendHyphenAtFifthIndex(value) {
      return value.replace(/(\d{5})(\d{4})/, "$1-$2");
    },
    numberToStringWithComma(value, decimals) {
      let newValue = value;

      if (value) {
        value = this.removeCommaSeparator(value);
        newValue = Number(value)
          .toFixed(decimals)
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      }
      return newValue;
    },
    removeCommaSeparator(value) {
      return value ? value.toString().replace(/,/g, "") : value;
    },
    padRightThreeZeros(value) {
      let returnValue;

      if (!value) {
        returnValue = value;
      } else {
        let valueTemp = this.removeCommaSeparator(value);

        if (valueTemp.length < 4) {
          returnValue = valueTemp + "000";
        } else {
          returnValue = valueTemp;
        }
      }

      return returnValue;
    },
    convertToNumber(value) {
      let returnValue = value;

      if (value && !isNaN(value)) {
        returnValue = Number(value);
      }

      return returnValue;
    },
    isEven(n) {
      return n % 2 === 0;
    },
    isChromeBrowser() {
      return (
        /Chrome/.test(navigator.userAgent) &&
        /Google Inc/.test(navigator.vendor)
      );
    },
    getAutoCompleteOff() {
      let autoCompleteOff = "off";
      if (this.isChromeBrowser()) {
        autoCompleteOff = "autocompleteOff";
      }
      return autoCompleteOff;
    },
    mustBeginAndWithBrackets(value) {
      let returnedValue = null;
      let cleanValue = null;

      if (value) {
        cleanValue = value.replace("[", "").replace("]", "");
      }

      if (cleanValue && cleanValue.length > 0) {
        returnedValue = "[" + cleanValue.trim() + "]";
      } else if (value !== null && value !== undefined && value.length === 0) {
        // Catch empty string
        returnedValue = value;
      }

      return returnedValue;
    },
    unmaskedPhone(maskedPhone) {
      if (maskedPhone) {
        return maskedPhone.replace(/\D+/g, "");
      } else {
        return null;
      }
    },
    phoneMask(phoneNumber) {
      //@ts-ignore
      return maskit(
        phoneNumber,
        //@ts-ignore
        this.masks.phoneMask,
        true,
        tokens
      );
    },
    unmaskedZip(maskedZip) {
      let returnedValue = null;
      if (maskedZip) {
        returnedValue = maskedZip.replace(/\D+/g, "");
      }
      return returnedValue;
    },
    zipMask(zip) {
      //@ts-ignore
      return maskit(
        zip,
        //@ts-ignore
        this.masks.zipMask,
        true,
        tokens
      );
    },
    ssnMask(ssn) {
      //@ts-ignore
      return maskit(
        ssn,
        //@ts-ignore
        this.masks.ssnMask,
        true,
        tokens
      );
    },
    ssnUnmask(maskedSsn) {
      return maskedSsn.replace(/\D/g, "");
    },
    validateDate(val) {
      const pattern = /^(?:(?:(?:0?[13578]|1[02]|(?:Jan|Mar|May|Jul|Aug|Oct|Dec))(\/|-|\.)31)\1|(?:(?:0?[1,3-9]|1[0-2]|(?:Jan|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))(\/|-|\.)(?:29|30)\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:(?:0?2|(?:Feb))(\/|-|\.)(?:29)\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:(?:0?[1-9]|(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep))|(?:1[0-2]|(?:Oct|Nov|Dec)))(\/|-|\.)(?:0?[1-9]|1\d|2[0-8])\4(?:(?:1[6-9]|[2-9]\d)?\d{4})$/;
      return !val || pattern.test(val);
    },
    isDateKeyPressed(event) {
      if (
        !/^^[0-9\-/]+$/.test(event.key) ||
        (/^^[0-9\-/]+$/.test(event.key) && event.keyCode !== 9)
      ) {
        return true;
      }
      return event.keyCode === 9;
    },
    reviewRecmndCommentRule(selectedRecommendation, comment) {
      if (
        selectedRecommendation &&
        (selectedRecommendation === "IN_ELIGB" ||
          selectedRecommendation === "INCMPLT" ||
          selectedRecommendation === "RETFORCORR") &&
        !comment
      )
        return "Comments required.";
    }
  }
};
</script>
