<template>
  <div>
    <v-checkbox
      :id="`${documentTypeCode}_checkbox`"
      v-model="selectedAtchmtChkBox"
      v-if="showCheckBox"
      label="I need help uploading a document."
      @change="saveCheckBoxSelection()"
      :disabled="lockCheckBox || isCheckBoxDisabled"
    >
    </v-checkbox>
    <InfoSection v-if="selectedAtchmtChkBox" :body="docUploadHelpText" />
    <v-progress-linear v-if="isCheckBoxSaving" indeterminate />
    <v-dialog v-model="helpCheckBoxDialog" persistent max-width="500px">
      <v-card class="px-2 px-md-0">
        <v-card-title>
          <h6>Upload Success</h6>
          <close-button
            class="closeButtonLocation"
            @click="helpCheckBoxDialog = false"
          />
        </v-card-title>

        <v-card-text class="px-8 py-6">
          <span>
            {{ uiFeature.ATH_NO_INF.largeText }}
          </span>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn tile class="primaryButton" @click="helpCheckBoxDialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import store from "@/store";
import { ECS_ENDPOINT } from "@/constants/EndPoints";
import { clone } from "@/util/clone";
import { attachmentCheckBoxSelectModel } from "@/data/AppData";
import StepperNavigationMixinVue from "@/mixins/StepperNavigationMixin.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import CloseButton from "@/components/common/CloseButton.vue";
import InfoSection from "@/components/common/InfoSection.vue";
import { CNST_TEXT } from "@/constants/CommonConstants";
import { GPC } from "@/constants/GPCCode";
import DynamicTextMixin from "@/mixins/DynamicTextMixin.vue";

export default Vue.extend({
  name: "DocCheckBoxSave",
  data() {
    return {
      lockCheckBox: false,
      isCheckBoxSaving: false,
      docAttachmentModel: clone(attachmentCheckBoxSelectModel),
      copyGetAtchmtCheckboxSltdList: [],
      helpCheckBoxDialog: false
    };
  },
  components: {
    CloseButton,
    InfoSection
  },
  mixins: [StepperNavigationMixinVue, UIFeatureMixinVue, DynamicTextMixin],
  props: {
    documentTypeCode: { type: String, default: null }
  },
  computed: {
    userInfo() {
      return store.getters.tesUserModule.getTesUser;
    },
    applicantProfile() {
      return store.getters.applicantProfileModule.getApplicantProfile;
    },
    applicantProgramInfo() {
      return store.getters.applicantProgramModule.getApplicantProgram;
    },
    getAtchmtCheckboxSltdList() {
      return store.getters.attachmentsModule.getAtchmtCheckboxSltdList;
    },
    docs() {
      return store.getters.attachmentsModule.getProfileDocs;
    },
    selectedAtchmtChkBox: {
      get() {
        let selectedCheckBoxObj = this.getChkBxSltdObj();
        return selectedCheckBoxObj && selectedCheckBoxObj.noUploadInd === "Y";
      },
      set(newValue) {
        //@ts-ignore
        store.commit.attachmentsModule.SET_CHECK_BOX_VALUE(newValue);
      }
    },
    isCheckBoxDisabled() {
      let tempObj = this.docs.find(
        o => o.documentTypeCode === this.documentTypeCode
      );
      return tempObj ? true : false;
    },
    showCheckBox() {
      let temp = this.docs.find(
        o => o.documentTypeCode === this.documentTypeCode
      );
      if (temp) {
        let tempObjCheckBoxObj = this.getChkBxSltdObj();
        if (tempObjCheckBoxObj && tempObjCheckBoxObj.noUploadInd === "Y") {
          this.saveCheckBoxSelection("showDialog");
        }
      }
      return temp ? false : true;
    },
    docUploadHelpText() {
      let text = "";
      if (
        //@ts-ignore
        this.isOrgAgyCtznonBehalfOfUser()
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          this.uiFeature[GPC.AMT_CH_HLP].largeText,
          CNST_TEXT.THE,
          CNST_TEXT.THE
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          this.uiFeature[GPC.AMT_CH_HLP].largeText,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOUR
        );
      }
      return text;
    }
  },
  methods: {
    getChkBxSltdObj() {
      return this.getAtchmtCheckboxSltdList.find(
        o => o.documentTypeCode === this.documentTypeCode
      );
    },
    saveCheckBoxSelection(dialogDisplay) {
      this.lockCheckBox = true;
      this.isCheckBoxSaving = true;
      let url = ECS_ENDPOINT.SAVE_NO_DOC_OPTION;
      this.populateCheckBoxSelection(dialogDisplay);

      axios
        .post(url, this.docAttachmentModel)
        .then(response => {
          if (response) {
            //@ts-ignore
            store.dispatch.attachmentsModule.getAtchmtChkBoxSltdByProfileId(
              this.applicantProfile.applicantProfileId
            );
            if (!this.isSingleFlow()) {
              let payload = {
                //@ts-ignore
                applicantProfileId: this.applicantProfile.applicantProfileId,
                //@ts-ignore
                docTypeCodes: this.documentTypeCode
              };
              store.dispatch.attachmentsModule.getAtchmtChkBoxSltdByProfileIdDocTypeCode(
                payload
              );
            }
            this.compareAllDocsAttached();
          }
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.lockCheckBox = false;
          this.isCheckBoxSaving = false;
        });
    },
    populateCheckBoxSelection(dialogDisplay) {
      let selectedCheckBoxObj = this.getChkBxSltdObj();
      if (
        dialogDisplay &&
        selectedCheckBoxObj &&
        selectedCheckBoxObj.noUploadInd === "Y"
      ) {
        this.helpCheckBoxDialog = true;
      }
      let attachmentId = null;
      if (selectedCheckBoxObj) {
        attachmentId = selectedCheckBoxObj.attachmentId;
      }

      let noUploadInd = "N";
      if (!selectedCheckBoxObj || selectedCheckBoxObj.noUploadInd === "N") {
        noUploadInd = "Y";
      }

      this.docAttachmentModel = {
        userId: this.userInfo.userId,
        userName:
          this.userInfo.userFirstName + " " + this.userInfo.userLastName,
        applicantProfileId: this.applicantProfile.applicantProfileId,
        filename: " ",
        version: 1,
        documentTypeCode: this.documentTypeCode,
        parentAttachmentId: null,
        applicationId: this.applicantProgramInfo.applicationId,
        cycleNumber: this.applicantProgramInfo.cycleNumber,
        noUploadInd: noUploadInd,
        attachmentId: attachmentId
      };
    }
  }
});
</script>
