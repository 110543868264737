import { render, staticRenderFns } from "./HomeAddressReview.vue?vue&type=template&id=b3ff5fa8&scoped=true&"
import script from "./HomeAddressReview.vue?vue&type=script&lang=ts&"
export * from "./HomeAddressReview.vue?vue&type=script&lang=ts&"
import style0 from "./HomeAddressReview.vue?vue&type=style&index=0&id=b3ff5fa8&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b3ff5fa8",
  null
  
)

export default component.exports