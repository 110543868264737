
import Vue from "vue";
import PWDApplication from "@/components/application/program/pwd/ProgramPWD.vue";
import ADAApplication from "@/components/application/program/ada/ProgramADA.vue";
import FTPApplication from "@/components/application/program/ftp/ProgramFTP.vue";
import SSRApplication from "@/components/application/program/ssr/ProgramSSR.vue";
import AGINGApplication from "@/components/application/program/aging/ProgramAGING.vue";
import MATPApplication from "@/components/application/program/matp/ProgramMATP.vue";
import ProgramVTP from "@/components/application/program/vtp/ProgramVTP.vue";
import ProgramsReview from "@/components/application/program/ProgramsReview.vue";
import { PROGRAMS_NAME } from "@/constants/Programs";
import store from "@/store";
import ClearStoreValuesMixinVue from "@/mixins/ClearStoreValuesMixin.vue";
import SingleFlowAttachmentsTemplate from "@/components/application/program/SingleFlowAttachmentsTemplate.vue";
import {
  IApplicantProfile,
  IDraftProgramInfo,
  IStepperHeaderList,
  ITESUser
} from "@/types";
import StepperNavigationMixinVue from "@/mixins/StepperNavigationMixin.vue";
import ProgressBar from "@/components/common/ProgressBar.vue";
import RFTPApplication from "@/components/application/program/rftp/ProgramRFTP.vue";

export default Vue.extend({
  name: "program-container",
  mixins: [ClearStoreValuesMixinVue, StepperNavigationMixinVue],
  components: {
    FTPApplication,
    MATPApplication,
    PWDApplication,
    SSRApplication,
    ADAApplication,
    ProgramsReview,
    AGINGApplication,
    SingleFlowAttachmentsTemplate,
    ProgressBar,
    RFTPApplication,
    ProgramVTP
  },
  data() {
    return {
      showPWD: false as boolean,
      showSSR: false as boolean,
      showADA: false as boolean,
      showADAA: false as boolean,
      showFTP: false as boolean,
      showMATP: false as boolean,
      showDocs: false as boolean,
      showReview: false as boolean,
      showAging: false as boolean,
      showComponent: false,
      showProgressBar: false,
      showRFTP: false as boolean,
      showVTP: false as boolean
    };
  },
  props: {
    program: { type: String, default: "" }
  },
  async created() {
    if (this.applicantProfile.applicantProfileId === 0) {
      this.showProgressBar = true;
      if (
        this.userInfo.userId === null ||
        this.userInfo.userId === undefined ||
        this.userInfo.userId === 0
      ) {
        await store.dispatch.tesUserModule.getUserDetails();
      }
      await this.populatePrgmsProfileSteppers();

      this.showComponent = true;
      this.showProgressBar = false;
    } else {
      this.showComponent = true;
    }
    let uiFeatureArray =
      "NOT_START,IN_PRGS,RDY_SBMT,NOT_QLFYD,NOT_APPL,SBMTD,APPROVED,DIS_TYPES,SPL_NEEDS,INCM_TYPES,AGE_PROOF,PRF_DSBLTY,PRF_DSBY_2,PRF_DSBY_3,RADIO_YN,CARE_ATTND,CERT_DBLTY,DL_VALID,DL_INVALID,APPL_CRT,APPL_RSBT,APPL_SBTD,APPL_DQFD,STR_APPL,ASGN_APP,APP_ASGN,FNLZ,APPL_SBMT,SAVE,MEDICA_INS,DL_INSTRUC,DL_TMT_TOP,DL_TMT_BTM,MATP_SEC1,MATP_AUTH,MATP_SC1_2,MATP_SEC2,PWD_SEC1,PWD_SEC2,ADA_SEC1,ADA_SEC2,SSR_SEC1,SSR_SEC2,FTP_SEC1,FTP_SEC2,FTP_INFO,AGE_VRFYST,VOTER_REG,VOTE_YES,VOTE_NO,VOTE_ALRDY,PRF_PRCARE,NT_QLFY,DSB_ACT,ADA_COMP,FXD_RT,RE_COR,IN_PRGS_C,RDY_SBMT_C,WITHDRAWN,PEND_CONF,POD_AGNCYS,COD_DOCTXT,PCA_DOCTXT,FRDMCODTXT,PARTCODTXT,AMTRAN_COD,C_COD_AGNY,APP_NTAPCL,APRW_STRT,RWR_CHNGD,RVW_CMPLT,CNFG_CMPLT,APP_EDTD,APP_RSBMTD,APP_WTDRWN,SAVE_CNTN,STRT_RW,CNT_RVW,CMPLT_RVW,APPRV,EDT_APP,WTHDRW,UN_RVW,DOCS_ADA,DOCS_PWD,DOCS_FTP,DOCS_SSR,DOCS_MATP,CHGLGN_TXT,QUICK_SRCH,ER_INLINE,FMR,ENVRONMNT,PRG_INFO,DIS_CNFM,ADA_INFO,DS_PRV_FRS,VRFY_AGE_Q,AN_FRQ_PRO,MATP_INFO,MEDAST_ELG,MEDAST_RGS,MAID_SSN,NMTCH_SSN,INELG_MATP,NMTCH_MAID,HSHD_MATP,ACCS_VEH,VALD_DL,TNSPT_APT,BS_TRN_STP,MED_COG_RS,DIF_TNSPTS,RDE_WT_OTH,CPTA_COD,CLTS_COD,LT_COD,VOT_REG_Q1,TMP_APRVD,TMP_APRV,C_POD_PWD,C_COD_ADA,AG_ATT_TXT,C_COD_PWD,APEL_INPRG,UPD_ELGBTY,APP_APPL,AGE_VRFY_D,NO_SRVAREA,RFTP_INFO,RFTP_APPLY,MEDCAR_IND,RFTP_APY_T,RFTP_APY_B,RFTP_MED_T,RFTP_MED_B,VTP_SEC1,VTP_INFO,YNT_VTP1,VTP_Q,HAS_SSN,EN_SSN,VTP_NO_MSG,PWD_NO_MSG,ATH_NO_INF,VTP_PROOF,VTP_INFO_1,DOCS_VTP,MCI_PROOF,DOCS_RFTP,AMT_CH_HLP,DIG10_RN,BY_PAS_PRG,BY_PAS_TXT,BY_PAS_HDR,BY_PAS_CHK,BY_PAS_SUM,WHY_TXT,MORE_INFO,DS_AGY_FRS,TVL_ARA_Q1,TVL_ARA_Q2,FIND_DL,PWD_SEC1_1,VOTR_REG_2,DIS_CNDTNS,SSR_DE_ALG,SSR_DET_1,SSR_DET_2,SSR_DET_3,SEPTA_COD,FUNV_DOC_T,FV_FORM,VIS_DOC_T,VIS_FORM,PCA_ADA_DC,FTP_SEPT_Q,AGE_PROF1,AUTH_DECLN";

    //@ts-ignore
    store.dispatch.uiFeatureModule.getUIFeatures(uiFeatureArray);
  },
  updated() {
    this.initProgram(this.program);
  },
  beforeMount() {
    this.initProgram(this.program);
  },
  beforeRouteUpdate(to: any, from: any, next: any) {
    this.resetScreen();
    next();
  },
  methods: {
    resetScreen() {
      this.showPWD = false;
      this.showSSR = false;
      this.showADA = false;
      this.showADAA = false;
      this.showFTP = false;
      this.showMATP = false;
      this.showDocs = false;
      this.showReview = false;
      this.showRFTP = false;
      this.showAging = false;
      this.showVTP = false;
    },
    initProgram(program: string) {
      switch (this.program) {
        case PROGRAMS_NAME.PWD:
          this.showPWD = true;
          break;
        case PROGRAMS_NAME.SSR:
          this.showSSR = true;
          break;
        case PROGRAMS_NAME.ADA:
          this.showADA = true;
          break;
        case PROGRAMS_NAME.ADA1:
          this.showADAA = true;
          break;
        case PROGRAMS_NAME.FTP:
          this.showFTP = true;
          break;
        case PROGRAMS_NAME.MATP:
          this.showMATP = true;
          break;
        case PROGRAMS_NAME.AGING:
          this.showAging = true;
          break;
        case "DOCS":
          this.showDocs = true;
          break;
        case "REVIEW":
          this.showReview = true;
          break;
        case PROGRAMS_NAME.RFTP:
          this.showRFTP = true;
          break;
        case PROGRAMS_NAME.VTP:
          this.showVTP = true;
          break;
      }
    },
    async populatePrgmsProfileSteppers() {
      let profileID = this.$route.query.profileID;
      let prgmType = this.$route.query.prgmType;
      let agencyCode = this.$route.query.agencyType;
      let that: any = this;
      await store.dispatch.programEligModule.populateProgramElig(profileID);
      that.getStepperheadersBrowserRefresh(profileID);
      if (prgmType === "DOCS" || prgmType === "REVIEW") {
        await store.dispatch.applicantProfileModule.getProfileInfo(profileID);
        await store.dispatch.draftProgramInfoModule.loadDraftProgramInfo(
          profileID
        );
        var headers = this.stepperHeaderMod.stepperHeaders;
        for (let header of headers) {
          if (
            header["programType"] === "DOCS" ||
            header["programType"] === "REVIEW"
          ) {
            store.commit.applicantProgramModule.SET_APPLICANT_PROGRAM(
              this.programEligModel.preEligiblePrograms[0]
            );
          }
        }
      } else {
        let prgmObj = {
          applicantProfileId: profileID,
          programTypeCode: prgmType,
          transitAgencyCode: agencyCode
        };
        await store.dispatch.draftProgramInfoModule.getApplicatProfileAndProgramInfo(
          prgmObj
        );
      }
      await store.dispatch.screeningResponseModule.populateScrnRespByApplProfileID(
        profileID
      );
    }
  },
  computed: {
    applicantProfile: {
      get(): IApplicantProfile {
        //@ts-ignore
        return store.getters.applicantProfileModule.getApplicantProfile;
      }
    },
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        //@ts-ignore
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      }
    },
    stepperHeaderMod: {
      get(): IStepperHeaderList {
        //@ts-ignore
        return store.getters.stepperHeaderModule.getStepperHeaderModel;
      }
    },
    programEligModel: {
      get(): any {
        //@ts-ignore
        return store.getters.programEligModule.getProgramElig;
      }
    },
    userInfo: {
      get(): ITESUser {
        //@ts-ignore
        return store.getters.tesUserModule.getTesUser;
      }
    }
  }
});
