
import Vue from "vue";
import MainHeader from "@/components/header/TheMainHeader.vue";
import OfficialHeader from "@/components/header/TheOfficialHeader.vue";
import EnterpriseFooter from "@/components/footer/TheEnterpriseFooter.vue";
import ApplicationFooter from "@/components/footer/TheApplicationFooter.vue";
import ShowAlertMessage from "@/components/common/ShowAlertMessage.vue";
import IdleLogout from "@/components/common/IdleLogout.vue";
import store from "@/store";
import { GPC_DEFAULT } from "@/constants/GPCCode";
import UIFeatureMixin from "@/mixins/UIFeatureMixin.vue";

export default Vue.extend({
  name: "App",
  components: {
    MainHeader,
    OfficialHeader,
    EnterpriseFooter,
    ApplicationFooter,
    ShowAlertMessage,
    IdleLogout
  },
  mixins: [UIFeatureMixin],
  data() {
    return {
      isLoggedIn: false,
      hash: true
    };
  },
  beforeCreate() {
    store.dispatch.uiFeatureModule.populateUIFeatureMapDefaults();
  },
  created() {
    let uiFeatureArray = GPC_DEFAULT;
    store.dispatch.uiFeatureModule.getUIFeatures(uiFeatureArray.toString());
  },
  computed: {
    showMainHeader(): boolean {
      return store.getters.appModule.getShowMainHeader;
    }
  },
  watch: {
    $route(to) {
      if (!to.hash) {
        this.hash = true;
      } else {
        this.hash = false;
      }
      // @ts-ignore
      this.$refs.skipLink.click();
      store.dispatch.alertModule.resetAlertMessage();
    }
  }
});
