
import store from "@/store";

export default {
  methods: {
    clearUserFromStore() {
      //@ts-ignore
      store.dispatch.tesUserModule.resetData();
    },
    clearScreenQuesContentFromStore() {
      //@ts-ignore
      store.dispatch.screenQuesModule.resetData();
    },
    clearScreeningResponseContentFromStore() {
      //@ts-ignore
      store.dispatch.screeningResponseModule.resetData();
    },
    clearDashboardContentFromStore() {
      //@ts-ignore
      store.dispatch.dashboardModule.resetData();
    },
    clearDrivingLicenseInfoFromStore() {
      //@ts-ignore
      store.dispatch.drivingLicenseInfoModule.resetData();
    },
    clearProgramEligFromStore() {
      //@ts-ignore
      store.dispatch.programEligModule.resetData();
    },
    clearApplicantProfileFromStore() {
      //@ts-ignore
      store.dispatch.applicantProfileModule.resetData();
    },
    clearApplicantProgramInfoFromStore() {
      //@ts-ignore
      store.dispatch.applicantProgramModule.resetData();
    },
    clearDraftProgramInfoFromStore() {
      //@ts-ignore
      store.dispatch.draftProgramInfoModule.resetData();
    },
    clearApplicationNavigationModuleFromStore() {
      //@ts-ignore
      store.dispatch.applicationNavigationModule.resetData();
    },
    clearUIFeatureDataObjectFromStore() {
      //@ts-ignore
      store.dispatch.uiFeatureModule.resetData();
    },
    clearProgramEligDataFromStore() {
      //@ts-ignore
      store.dispatch.programEligModule.resetData();
    },
    clearStepperHeaderDataFromStore() {
      //@ts-ignore
      store.dispatch.stepperHeaderModule.resetData();
    },
    clearAllStoreValues() {
      this.clearUserFromStore();
      this.clearScreenQuesContentFromStore();
      this.clearScreeningResponseContentFromStore();
      this.clearDashboardContentFromStore();
      this.clearDrivingLicenseInfoFromStore();
      this.clearProgramEligFromStore();
      this.clearApplicantProfileFromStore();
      this.clearDraftProgramInfoFromStore();
      this.clearStepperHeaderDataFromStore();
    },
    clearAllStoreValuesExceptUser() {
      this.clearScreenQuesContentFromStore();
      this.clearScreeningResponseContentFromStore();
      this.clearDashboardContentFromStore();
      this.clearDrivingLicenseInfoFromStore();
      this.clearProgramEligFromStore();
      this.clearApplicantProfileFromStore();
      this.clearDraftProgramInfoFromStore();
      this.clearStepperHeaderDataFromStore();
    }
  }
};
