import { render, staticRenderFns } from "./ProgramSSR.vue?vue&type=template&id=16e8262f&"
import script from "./ProgramSSR.vue?vue&type=script&lang=ts&"
export * from "./ProgramSSR.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports