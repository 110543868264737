import { IScrAnswers, IScreeningQuestions } from "@/types";
import { screeningAnswersDefault, applicantAddress } from "@/data/AppData";
import { clone } from "@/util/clone";
import { moduleActionContext } from "..";
import { defineModule } from "direct-vuex";
import axios from "axios";
import { TES_APP_ENDPOINT } from "@/constants/EndPoints";
import { HTTP_STATUS } from "@/constants/HttpStatusCode";

export interface ScrQuestionsState {
  scrAnswers: IScrAnswers;
  questions: IScreeningQuestions[];
  stepInd: number;
  prgmsList: [];
}

const screenQuesModule = defineModule({
  namespaced: true,
  state: {
    scrAnswers: {
      answers: [],
      selCounty: [],
      selDisability: "",
      ageGroup: "",
      showVTPQues: false,
      showRFTPQues: false,
      homeAddress: clone(applicantAddress),
      applySEPTACounty: false
    },
    questions: [],
    stepInd: 0,
    prgmsList: []
  } as ScrQuestionsState,
  getters: {
    getScrAnswers: (state: ScrQuestionsState) => state.scrAnswers,
    getQuestions: (state: ScrQuestionsState) => state.questions,
    getStepInd: (state: ScrQuestionsState) => state.stepInd,
    getPrgmsList: (state: ScrQuestionsState) => state.prgmsList
  },
  mutations: {
    SET_SCRANSWERS(state: ScrQuestionsState, newScrAnswers: IScrAnswers) {
      state.scrAnswers = newScrAnswers;
    },
    SET_QUESTIONS(
      state: ScrQuestionsState,
      newQuestions: IScreeningQuestions[]
    ) {
      state.questions = newQuestions;
    },
    SET_STEPIND(state: ScrQuestionsState, newStepInd: number) {
      state.stepInd = newStepInd;
    },
    SET_PRGMS_LIST(state: ScrQuestionsState, prgmsList: []) {
      state.prgmsList = prgmsList;
    }
  },
  actions: {
    resetData(context: any) {
      let { commit } = screenQuesModuleContext(context);
      //clearing the questions, answers and stepInd data in store.
      let screeningAnswers = clone(screeningAnswersDefault);
      let questions = [] as IScreeningQuestions[];
      //@ts-ignore
      commit.SET_QUESTIONS(questions);
      //@ts-ignore
      commit.SET_STEPIND(0);
      //@ts-ignore
      commit.SET_SCRANSWERS(screeningAnswers);
    },
    async populateScreeningResults(context: any, scrnRespID: any) {
      let { commit, rootDispatch } = screenQuesModuleContext(context);
      await axios
        .get(TES_APP_ENDPOINT.GET_SCREENING_QUES_BY_SCRN_RESP_ID, {
          params: {
            screeningRespID: scrnRespID
          }
        })
        .then(response => {
          if (response.status === HTTP_STATUS.OK) {
            commit.SET_QUESTIONS(response.data.tesScrnQuestionsResponseModel);
            commit.SET_SCRANSWERS(response.data.scrAnswers);
          }
        })
        .catch(() => {
          rootDispatch.alertModule.showAlertMessageByGPC("ER_INLINE");
        });
    }
  }
});

export default screenQuesModule;
const screenQuesModuleContext = (context: any) =>
  moduleActionContext(context, screenQuesModule);
