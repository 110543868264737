var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-col',[_c('v-dialog',{attrs:{"hide-overlay":"","transition":"dialog-bottom-transition","max-width":"850px","scrollable":""},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('v-card-title',[_c('h6',{staticClass:"primary--text text-wrap"},[_vm._v("Application History")]),_c('v-spacer',{staticClass:"pa-4"}),_c('v-icon',{attrs:{"color":"primary","aria-label":"close pop up"},on:{"click":_vm.closeDialog}},[_vm._v("fas fa-times")])],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.eventLogs,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.lastModifiedTs",fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(_vm.formatLocalTimeStamp(item.lastModifiedTs))+" ")])]}},{key:"item.wfStatusCode",fn:function({ item }){return [(
                item.wfStatusCode === _vm.APPL_STATUS.SUBMITTED ||
                  (_vm.isAgencyUser &&
                    (item.wfStatusCode ===
                      _vm.APPL_STATUS.RETURN_FOR_CORRECTION ||
                      item.wfStatusCode === _vm.APPL_STATUS.APEL_INPRG))
              )?_c('span',[_vm._v(" "+_vm._s(_vm.uiFeature[item.wfStatusCode].displayName)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.uiFeature[item.wfStatusCode].largeText)+" ")])]}},{key:"item.wfActionCode",fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(_vm.displayName(item.wfActionCode))+" ")])]}},{key:"item.wfEventCode",fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(_vm.displayName(item.wfEventCode))+" ")])]}},{key:"item.lastModifiedBy",fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(item.createdBy)+" ")])]}}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }