
import Vue from "vue";
import axios from "axios";
import store from "@/store";
import ProgressBar from "@/components/common/ProgressBar.vue";
import { TES_APP_ENDPOINT } from "@/constants/EndPoints";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import {
  IApplicantProfile,
  IDraftProgramInfo,
  IProgramsList,
  IpromiseModel,
  IPromiseSearchModel
} from "@/types";
import tesContainer from "@/components/common/TESContainer.vue";
import draftProgramMixin from "@/mixins/DraftProgramMixin.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import routerMixin from "@/mixins/RouterMixin.vue";
import StepperNavigationMixinVue from "@/mixins/StepperNavigationMixin.vue";
import { BUTTON_NAMES, CNST_TEXT } from "@/constants/CommonConstants";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import InfoSection from "@/components/common/InfoSection.vue";
import ScrollMixin from "@/mixins/ScrollMixin.vue";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";
import ApplicantProfileMixin from "@/mixins/ApplicantProfileMixin.vue";
import { GPC } from "@/constants/GPCCode";
import dateHelperMixin from "@/mixins/dateHelperMixin.vue";

export default Vue.extend({
  name: "matp-medical-assistance",
  components: {
    tesContainer,
    ProgressBar,
    InfoSection
  },
  mixins: [
    fieldEntryMixin,
    draftProgramMixin,
    UIFeatureMixinVue,
    routerMixin,
    StepperNavigationMixinVue,
    AppPropsMixin,
    ScrollMixin,
    ApplicationMixin,
    ApplicantProfileMixin,
    dateHelperMixin
  ],
  data() {
    return {
      medicaidNumberdialog: false,
      showProgressBar: false,
      showDefaultButtons: true,
      steps: 3,
      promiseDec: "",
      promiseModel: {} as IpromiseModel,
      promiseSrchModel: {} as IPromiseSearchModel
    };
  },
  computed: {
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      },
      set(newValue: IDraftProgramInfo) {
        store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(newValue);
      }
    },
    applicantProfile: {
      get(): IApplicantProfile {
        return store.getters.applicantProfileModule.getApplicantProfile;
      },
      set(newValue: IApplicantProfile) {
        store.commit.applicantProfileModule.SET_APPLICANT_PROFILE(newValue);
      }
    },
    applicantDob(): string {
      return this.getFormattedDOB(this.applicantProfile.applicantDob);
    },
    applicantProgramInfo(): IProgramsList {
      return store.getters.applicantProgramModule.getApplicantProgram;
    },
    matpInfoOne(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.MATP_SEC1].largeText,
          CNST_TEXT.THE_APPLICANT_QUALIFIES,
          CNST_TEXT.THEIR
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.MATP_SEC1].largeText,
          CNST_TEXT.YOU_QUALIFY,
          CNST_TEXT.YOUR
        );
      }
      return text;
    },
    matpInfoTwo(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.MATP_SC1_2].largeText,
          CNST_TEXT.THE_APPLICANTS,
          CNST_TEXT.THEIR,
          CNST_TEXT.THE_APPLICANT_HAS,
          CNST_TEXT.THEIR,
          CNST_TEXT.THEY_CAN,
          CNST_TEXT.THEIR
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.MATP_SC1_2].largeText,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOU_HAVE,
          CNST_TEXT.YOUR,
          CNST_TEXT.PLEASE,
          CNST_TEXT.YOUR
        );
      }
      return text;
    },
    maIDOpt: {
      get() {
        let text: string = "";
        if (this.draftProgramInfo.medicaidCardInd === "Y") {
          text = "M";
        }
        if (this.draftProgramInfo.hasSSN === "Y") {
          text = "S";
        }
        return text;
      },
      set(value: string) {
        if (value === "M") {
          this.draftProgramInfo.medicaidCardInd = "Y";
          this.draftProgramInfo.hasSSN = "";
        } else if (value === "S") {
          this.draftProgramInfo.medicaidCardInd = "";
          this.draftProgramInfo.hasSSN = "Y";
        }
      }
    },
    ssnValue: {
      get(): string {
        //@ts-ignore
        return this.ssnMask(this.draftProgramInfo.enSSN);
      },
      set(value: string) {
        //@ts-ignore
        this.draftProgramInfo.enSSN = this.ssnUnmask(value);
      }
    }
  },
  methods: {
    maIdSSNChanged() {
      if (this.maIDOpt === "M") {
        this.draftProgramInfo.enSSN = "";
      }
      if (this.maIDOpt === "S") {
        this.draftProgramInfo.medicaidCardRecipientNum = "";
      }
    },
    getFormattedDOB(dob: string) {
      //@ts-ignore
      return this.formatDateMixin(dob);
    },
    getFormattedDOBForPrms(dob: string) {
      //@ts-ignore
      return this.formatDateMixinForPromise(dob);
    },
    nomatp() {
      this.saveDraftProgramInfo("nomatp");
    },
    populateRequestObject() {
      this.promiseSrchModel.profileID = this.applicantProfile.applicantProfileId;
      this.promiseSrchModel.firstName = this.applicantProfile.applicantFirstName;
      this.promiseSrchModel.lastName = this.applicantProfile.applicantLastName;
      this.promiseSrchModel.maID = this.draftProgramInfo.medicaidCardRecipientNum;
      if (this.draftProgramInfo.enSSN != null) {
        this.promiseSrchModel.ssn = this.draftProgramInfo.enSSN.replace(
          /\D/g,
          ""
        );
      }
      this.promiseSrchModel.dob = this.getFormattedDOBForPrms(
        this.applicantProfile.applicantDob
      );
      this.promiseModel.promiseSearchModel = this.promiseSrchModel;
    },
    savePromise() {
      //@ts-ignore
      if (this.$refs.formMedicalAssistance.validate()) {
        if (this.applicantProgramInfo.statusCode === "IN_PRGS_C") {
          //@ts-ignore
          this.draftProgramInfo.programStatus = this.uiFeature.IN_PRGS_C.displayName;
        } else {
          //@ts-ignore
          this.draftProgramInfo.programStatus = this.uiFeature.IN_PRGS.displayName;
        }
        this.promiseModel.draftProgramInfoModel = this.draftProgramInfo;
        this.populateRequestObject();
        axios
          .post(TES_APP_ENDPOINT.CHECK_ELIG_PROMISE, this.promiseModel)
          .then(response => {
            //@ts-ignore
            this.promiseDec = response.data.promiseDesc;
            this.draftProgramInfo = response.data.draftProgramInfoModel;
            //@ts-ignore
            store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(
              this.draftProgramInfo
            );
            //@ts-ignore
            store.commit.applicantProgramModule.SET_APPLICANT_PROGRAM(
              this.draftProgramInfo.programInfo
            );
            if (this.promiseDec === "Good") {
              this.$emit("save-completed", BUTTON_NAMES.SAVE);
            }
          })
          .catch(error => {
            store.dispatch.alertModule.showAlertMessageById(
              error.response.data.id
            );
          })
          .finally(() => {
            this.showProgressBar = false;
          });
      }
    },
    save(saveOrContinue: string) {
      //@ts-ignore
      if (this.$refs.formMedicalAssistance.validate()) {
        this.saveDraftProgramInfo(saveOrContinue);
      } else {
        //@ts-ignore
        this.scrollToErrorField(this.$refs.formMedicalAssistance);
      }
    },
    cancel() {
      //@ts-ignore
      if (this.$refs.formMedicalAssistance.validate()) {
        this.saveDraftProgramInfo("CANCEL");
      } else {
        //@ts-ignore
        this.scrollToErrorField(this.$refs.formMedicalAssistance);
      }
    },
    saveDraftProgramInfo(buttonName: string) {
      this.showProgressBar = true;

      this.draftProgramInfo.applicantProfileId = this.applicantProfile.applicantProfileId;
      this.draftProgramInfo.programInfo = this.applicantProgramInfo;

      if ("CONTINUE" === buttonName) {
        //@ts-ignore
        this.draftProgramInfo.programStatus = this.uiFeature.NOT_QLFYD.displayName;
      }

      if ("nomatp" === buttonName) {
        //@ts-ignore
        this.draftProgramInfo.programStatus = this.uiFeature.NOT_QLFYD.displayName;
        this.draftProgramInfo.dhsAuthInd = "N";
        //@ts-ignore
        this.draftProgramInfo.dhsAuthDate = new Date();
        this.draftProgramInfo.medAssistanceEligibilityInd = "";
        this.draftProgramInfo.medicaidCardInd = "";
        this.draftProgramInfo.medicaidCardRecipientNum = "";

        this.showDefaultButtons = false;
        this.steps = 2;
        //@ts-ignore
        this.draftProgramInfo.userInfo = this.userInfo;

        this.$emit("step-count", this.steps);
      } else {
        this.changeValues();
      }

      //PROMISE CALL

      axios
        .post(TES_APP_ENDPOINT.SAVE_DRAFT_PRGM_INFO, this.draftProgramInfo)
        .then(response => {
          //@ts-ignore
          this.draftProgramInfo = response.data;
          //@ts-ignore
          store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(
            this.draftProgramInfo
          );
          //@ts-ignore
          store.commit.applicantProgramModule.SET_APPLICANT_PROGRAM(
            this.draftProgramInfo.programInfo
          );

          if (
            //@ts-ignore
            this.isSingleFlow() ||
            buttonName === BUTTON_NAMES.SAVE ||
            buttonName === BUTTON_NAMES.CANCEL
          ) {
            this.$emit("save-completed", buttonName);
          } else {
            //@ts-ignore
            this.forwardToDashboard();
          }
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    },
    changeValues() {
      this.showDefaultButtons = true;
      if (
        //@ts-ignore
        !this.isSingleFlow()
      ) {
        if (
          (this.applicantProfile.travelAttendantInd === "Y" &&
            (this.applicantProgramInfo.transitAgencyCode === "CPTA" ||
              this.applicantProgramInfo.transitAgencyCode === "CAT")) ||
          (this.applicantProgramInfo.transitAgencyCode === "CTDC" &&
            this.draftProgramInfo.medAssistanceEligibilityInd === "Y")
        ) {
          this.steps = 4;
        }
      } else {
        this.steps = 3;
      }
      if (this.draftProgramInfo.medAssistanceEligibilityInd === "N") {
        this.draftProgramInfo.medicaidCardInd = "";
      }
      if (this.draftProgramInfo.medAssistanceEligibilityInd === "N") {
        this.showDefaultButtons = false;
        this.steps = 2;
      }
      if (this.draftProgramInfo.medicaidCardInd == "N") {
        this.draftProgramInfo.medicaidCardRecipientNum = "";
      } else {
        this.draftProgramInfo.enSSN = "";
      }
      this.$emit("step-count", this.steps);
    }
  },
  watch: {
    uiFeature: {
      deep: true,
      handler(obj: Object) {
        //@ts-ignore
        if (obj["MEDAST_RGS"]) {
          //@ts-ignore
          this.loadMATPDynamicGPCText();
          //@ts-ignore
          this.loadTranspDynamicGPCText();
        }
      }
    }
  }
});
