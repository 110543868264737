
import Vue from "vue";

export default Vue.extend({
  name: "tes-control-template",
  props: {
    label: { type: String, default: "" },
    required: { type: Boolean, default: false },
    toolTipText: { type: String, default: "" },
    labelId: { type: String, default: "" },
    slotRowClass: { type: String, default: "" }
  }
});
