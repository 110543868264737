
import Vue from "vue";
import { IDraftProgramInfo } from "@/types";
import dateHelperMixin from "@/mixins/dateHelperMixin.vue";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import store from "@/store";

export default Vue.extend({
  mixins: [dateHelperMixin, fieldEntryMixin, UIFeatureMixinVue],
  computed: {
    programInfoPrevious(): IDraftProgramInfo {
      return store.getters.draftProgramInfoModule.getProgramInfoPrevious;
    },
    showPreviousValues(): boolean {
      return store.getters.draftProgramInfoModule.getShowPreviousValues;
    }
  }
});
