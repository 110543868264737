
import Vue from "vue";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import FundingPrograms from "@/components/home/FundingPrograms.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import ProgressBar from "@/components/common/ProgressBar.vue";
import store from "@/store";
import ClearStoreValuesMixinVue from "@/mixins/ClearStoreValuesMixin.vue";

export default Vue.extend({
  name: "about",
  mixins: [AppPropsMixin, UIFeatureMixinVue, ClearStoreValuesMixinVue],
  components: {
    FundingPrograms,
    ProgressBar
  },
  created() {
    const uiFeatureArray =
      "ABOUT,ABOUT_ADV,ABOUT_FEA,ABOUT_FUN,ABOUT_HEL,ADA,FTP,MATP,PWD,SSR,QUICK_SRCH," +
      "ER_INLINE,CHGLGN_TXT,CRSL_INTVL,FMR,ENVRONMNT,ABOUT_PRCS,FLOW_HORZ,FLOW_VERT," +
      "ADA_Y,ADA_N,FTP_Y,FTP_N,MATP_Y,MATP_N,PWD_Y,PWD_N,RFTP_Y,RFTP_N,SSR_Y,SSR_N," +
      "AGING_Y,AGING_N,VTP_Y,VTP_N,PWDAC_Y,ADAAC_Y,PROC_OVRVW";

    store.commit.uiFeatureModule.SET_DATA_LDNG_STS(true);
    store.dispatch.uiFeatureModule.getUIFeatures(uiFeatureArray);
  },
  computed: {
    isDataLoading(): boolean {
      return store.getters.uiFeatureModule.getLoadingStatus;
    }
  },
  methods: {
    getFMRProcessFlow() {
      let agencylogo = "";
      if (this.$vuetify.breakpoint.smAndDown) {
        //@ts-ignore
        agencylogo = this.uiFeature.FLOW_VERT.largeText;
      } else {
        //@ts-ignore
        agencylogo = this.uiFeature.FLOW_HORZ.largeText;
      }
      return agencylogo;
    }
  }
});
