
import Vue from "vue";
export default Vue.extend({
  name: "date-input",
  props: {
    id: { type: String, default: "" },
    label: { type: String, default: "" },
    value: { type: String, default: "" },
    dateRules: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
    ariaLabel: { type: String, default: "" }
  },
  data() {
    return {
      valueLocal: this.value
    };
  },
  watch: {
    valueLocal(newVal) {
      this.$emit("input", newVal);
    },
    value(newVal) {
      this.valueLocal = newVal;
    }
  }
});
