
import Vue from "vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import DynamicTextMixin from "@/mixins/DynamicTextMixin.vue";
export default Vue.extend({
  name: "NotQualified-Dialog",
  mixins: [UIFeatureMixinVue, DynamicTextMixin],
  data: function() {
    return {};
  },
  props: {
    notQualifiedDialog: { type: Boolean, default: false },
    pwdDialog: { type: Boolean, default: false },
    program: { type: String }
  },
  computed: {
    showDialog() {
      return this.notQualifiedDialog;
    },
    ftpDoNotQualifyText(): string {
      //@ts-ignore
      return this.ftpDoNotQualifyDynamicText(
        //@ts-ignore
        this.uiFeature[GPC.YNT_FTP1].largeText
      );
    }
  },
  methods: {
    close() {
      this.$emit("close");
    }
  }
});
