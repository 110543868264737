
import Vue from "vue";
import { TES_APP_ENDPOINT } from "@/constants/EndPoints";
import axios from "axios";
import store from "@/store";
import { IDashboardContent, IProgramsList, IApplicantProfile } from "@/types";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import DashboardCard from "@/components/dashboard/DashboardCard.vue";

export default Vue.extend({
  name: "ProfileReview-Dashboard",
  mixins: [UIFeatureMixinVue],
  components: {
    DashboardCard
  },
  data: function() {
    return {
      eligiblePrgmList: [] as IProgramsList[],
      preEligiblePrgmList: [] as IProgramsList[],
      notEligiblePrgmList: [] as IProgramsList[],
      submittedPrgmList: [] as IProgramsList[],
      approvedPrgmList: [] as IProgramsList[],
      inEligiblePrgmList: [] as IProgramsList[],
      accessOutsidePrgmList: [] as IProgramsList[]
    };
  },
  computed: {
    displayNoPrgmsMsg(): boolean {
      return (
        this.eligiblePrgmList.length === 0 &&
        this.submittedPrgmList.length === 0 &&
        this.approvedPrgmList.length === 0 &&
        this.preEligiblePrgmList.length === 0 &&
        this.inEligiblePrgmList.length === 0
      );
    },
    applicantProfileInfo(): IApplicantProfile {
      return store.getters.applicantProfileModule.getApplicantProfile;
    },
    dashboardContent: {
      get(): IDashboardContent {
        return store.getters.dashboardModule.getDashboardContent;
      }
    }
  },

  created() {
    store.dispatch.allTransitAgencyDataModule.getAllTransitAgencyData();
    this.eligiblePrgmList = this.dashboardContent.programEligModel.eligiblePrograms;
    this.preEligiblePrgmList = this.dashboardContent.programEligModel.preEligiblePrograms;
    this.notEligiblePrgmList = this.dashboardContent.programEligModel.notEligiblePrograms;
    this.submittedPrgmList = this.dashboardContent.programEligModel.submittedPrograms;
    this.approvedPrgmList = this.dashboardContent.programEligModel.approvedPrograms;
    this.inEligiblePrgmList = this.dashboardContent.programEligModel.inEligiblePrograms;
    this.accessOutsidePrgmList = this.dashboardContent.programEligModel.accessOutsidePrograms;

    // skipping the if check for now due to store issues.
    let url = TES_APP_ENDPOINT.GET_DRAFT_PROGRAM_INFO;
    axios
      .get(url, {
        params: {
          applicantProfileId: this.applicantProfileInfo.applicantProfileId
        }
      })
      .then(response => {
        let draftProgramInfo = response.data;
        if (draftProgramInfo) {
          //@ts-ignore
          store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(
            response.data
          );
        }
      })
      .catch(error => {
        store.dispatch.alertModule.showAlertMessageById(error.response.data.id);
      })
      //@ts-ignore
      .finally(() => {
        let elementTofocus;
        elementTofocus = document.getElementById("morethan4Tab");
        if (elementTofocus) elementTofocus.focus();
      });
  }
});
