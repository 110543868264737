
import Vue from "vue";
import store from "@/store";
import { IApplicantProfile, IProgramsList } from "@/types";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import StepperNavigationMixinVue from "@/mixins/StepperNavigationMixin.vue";
import Attachments from "@/components/application/program/Attachments.vue";
import { CNST_TEXT } from "@/constants/CommonConstants";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";
import { GPC } from "@/constants/GPCCode";

export default Vue.extend({
  name: "ssrIndividualflow-attachments",
  mixins: [UIFeatureMixinVue, StepperNavigationMixinVue, ApplicationMixin],
  components: {
    Attachments
  },
  data: function() {
    return {
      showPCADoc: false
    };
  },
  props: {
    documentTypeCodes: {
      type: Array,
      default: null
    }
  },
  computed: {
    ageProofCodes(): any {
      //@ts-ignore
      return this.uiFeature.AGE_PROOF.children;
    },
    prfPrsnCareAttendantCodes(): any {
      //@ts-ignore
      return this.uiFeature.PRF_PRCARE.children;
    },
    applicantProfile: {
      get(): IApplicantProfile {
        return store.getters.applicantProfileModule.getApplicantProfile;
      }
    },
    applicantProgramInfo: {
      get(): IProgramsList {
        return store.getters.applicantProgramModule.getApplicantProgram;
      }
    },
    prfDsbltyInfoHeaderTwo(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_2].displayName,
          CNST_TEXT.THE
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_2].displayName,
          CNST_TEXT.MY
        );
      }
      return text;
    },
    prfDsbltyInfoTwo(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_2].largeText,
          CNST_TEXT.THE
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_2].largeText,
          CNST_TEXT.YOUR
        );
      }
      return text;
    },
    prfDsbltyInfoThree(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_3].largeText,
          CNST_TEXT.THE,
          CNST_TEXT.THE
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_3].largeText,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOUR
        );
      }
      return text;
    },
    pcaDocInfoOne(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_PRCARE].largeText,
          CNST_TEXT.THE_APPLICANT_NEEDS,
          CNST_TEXT.THE_APPLICANT,
          CNST_TEXT.THEIR,
          CNST_TEXT.THE
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_PRCARE].largeText,
          CNST_TEXT.YOU_NEED,
          CNST_TEXT.YOU,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOUR
        );
      }
      return text;
    },
    ageVerificationInfo(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.AGE_PROOF].largeText,
          CNST_TEXT.THE_APPLICANTS
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.AGE_PROOF].largeText,
          CNST_TEXT.YOUR
        );
      }
      return text;
    },
    isAlleghenyCounty(): boolean {
      if (this.applicantProfile.homeAddress.paCountyCode === "02") {
        return true;
      } else return false;
    },
    replaceText(): string {
      let abc;
      if (this.isAlleghenyCounty) {
        //@ts-ignore
        abc = this.uiFeature.SSR_DE_ALG.largeText;
        return abc.replace("font-weight-bold", "");
      } else {
        //@ts-ignore
        return this.uiFeature.AGE_PROF1.largeText;
      }
    }
  },
  created() {
    if (
      this.applicantProfile.travelAttendantInd === "Y" &&
      (this.applicantProgramInfo.transitAgencyCode === "CPTA" ||
        this.applicantProgramInfo.transitAgencyCode === "CAT")
    ) {
      this.showPCADoc = true;
    }
    //@ts-ignore
    this.compareAllDocsAttached();
  }
});
