
import Vue from "vue";
import store from "@/store";
import ADAProgramInfoReview from "@/components/application/program/ada/review/ADAProgramInfoReview.vue";
import ADAVoterRegistrationReview from "@/components/application/program/ada/review/ADAVoterRegistrationReview.vue";
import PWDGeneralInfoReview from "@/components/application/program/pwd/review/PWDGeneralInfoReview.vue";
import AttachmentsReview from "@/components/application/program/AttachmentsReview.vue";
import ApplicationReviewControls from "@/components/application/program/ApplicationReviewControls.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import AttachmentsMixinVue from "@/mixins/AttachmentsMixin.vue";
import {
  IDraftFixedRouteUsage,
  IDraftProgramInfo,
  IProgramsList,
  IApplicantProfile
} from "@/types";
import StepperNavigationMixinVue from "@/mixins/StepperNavigationMixin.vue";
import AgencyReviewMixin from "@/mixins/AgencyReviewMixin.vue";
import UpcomingApptReview from "@/components/application/program/UpcomingApptReview.vue";
import PhysicianInfoReview from "@/components/application/program/PhysicianInfoReview.vue";

export default Vue.extend({
  name: "Program-Review-ADA",
  mixins: [
    UIFeatureMixinVue,
    StepperNavigationMixinVue,
    AttachmentsMixinVue,
    AgencyReviewMixin
  ],
  components: {
    ADAProgramInfoReview,
    PWDGeneralInfoReview,
    AttachmentsReview,
    ApplicationReviewControls,
    ADAVoterRegistrationReview,
    UpcomingApptReview,
    PhysicianInfoReview
  },
  data() {
    return {
      singleFlow: false,
      draftFixedRouteUsage: {} as IDraftFixedRouteUsage,
      draftFixedRouteUsageList: {} as IDraftFixedRouteUsage[],
      documentTypeCodes: [] as string[],
      showAddlSection: false as boolean
    };
  },
  props: {
    applicantProgramInfoObj: {
      type: Object as () => IProgramsList
    },
    draftProgramInfoObj: {
      type: Object as () => IDraftProgramInfo
    },
    showApplRevCtrls: { type: Boolean, default: false }
  },
  computed: {
    applicantProfile(): IApplicantProfile {
      return store.getters.applicantProfileModule.getApplicantProfile;
    }
  },
  created() {
    if (
      //@ts-ignore
      this.isSingleFlow()
    ) {
      this.singleFlow = true;
      //@ts-ignore
    } else if (!this.isLANTA) {
      //@ts-ignore
      this.documentTypeCodes = this.getADADocumentTypes();
    }
    this.draftFixedRouteUsageList = this.draftProgramInfoObj.draftFixedRouteUsageList;
    if (this.draftFixedRouteUsageList !== null) {
      for (let draftFixedRouteUsage of this.draftFixedRouteUsageList) {
        if (
          this.applicantProgramInfoObj.transitAgencyCode ===
          draftFixedRouteUsage["transitAgencyCode"]
        ) {
          this.draftFixedRouteUsage.providerAreaTravelInd =
            draftFixedRouteUsage["providerAreaTravelInd"];
          this.draftFixedRouteUsage.fixedRouteDisabilityInd =
            draftFixedRouteUsage["fixedRouteDisabilityInd"];
          this.draftFixedRouteUsage.fixedRouteDisabilityDesc =
            draftFixedRouteUsage["fixedRouteDisabilityDesc"];
        }
      }
    }

    if (
      (this.draftProgramInfoObj.disabilityInd === "Y" &&
        this.applicantProfile.gisCallInd !== "Y" &&
        this.draftFixedRouteUsage.providerAreaTravelInd === "Y" &&
        this.draftFixedRouteUsage.fixedRouteDisabilityInd === "Y") ||
      (this.draftProgramInfoObj.disabilityInd === "Y" &&
        this.applicantProfile.gisCallInd === "Y" &&
        this.draftFixedRouteUsage.fixedRouteDisabilityInd === "Y")
    ) {
      this.showAddlSection = true;
    }
  }
});
