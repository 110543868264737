
import axios from "axios";
import Vue from "vue";
import { TES_APP_ENDPOINT } from "@/constants/EndPoints";
import router from "@/router";
import store from "@/store";
import { IScreeningResponse, ITESUser, ITESApplicationModel } from "@/types";
import ClearStoreValuesMixin from "@/mixins/ClearStoreValuesMixin.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import { ESEC_REDIRECT_STRINGS } from "@/constants/CommonConstants";

export default Vue.extend({
  name: "login",
  mixins: [ClearStoreValuesMixin, UIFeatureMixinVue, fieldEntryMixin],
  props: {
    idleLogOut: { type: Boolean, default: false },
    showLoginSimulationBtn: { type: Boolean, default: false }
  },
  data() {
    return {
      showPassword: false,
      userName: "" as string,
      password: "" as string,
      troubleSigningDialog: false,
      loginError: false,
      loginErrorMsg: "",
      availableCountiesDialog: false,
      isLoginLoading: false,
      tesApplicationModel: {} as ITESApplicationModel
    };
  },
  computed: {
    tesUserInfo(): ITESUser {
      return store.getters.tesUserModule.getTesUser;
    },
    screeningResponse(): IScreeningResponse {
      return store.getters.screeningResponseModule.getScreeningResponse;
    }
  },
  methods: {
    userInfo() {
      //@ts-ignore
      this.clearAllStoreValues();
      router.push({ name: "userInfo" });
    },
    urlSearch(param: string) {
      let results = new RegExp("[?&]" + param + "=([^&#]*)").exec(
        window.location.href
      );
      if (results == null) {
        return null;
      } else {
        return decodeURIComponent(results[1]) || 0;
      }
    },
    emptyUserCredentials() {
      this.loginError = true;

      if (this.userName === "" && this.password === "") {
        this.loginErrorMsg = "Please enter the Username and Password";
      } else if (this.userName === "") {
        this.loginErrorMsg = "Please enter the Username";
      } else {
        this.loginErrorMsg = "Please enter the Password";
      }
    },
    postToESEC() {
      if (this.userName === "" || this.password === "") {
        this.emptyUserCredentials();
      } else {
        this.postToESECNonEmpty();
      }
    },
    postToESECNonEmpty() {
      this.isLoginLoading = true;
      this.loginError = false;
      this.loginErrorMsg = "";
      //@ts-ignore
      let decodedTARGET = this.urlSearch("TARGET");
      //@ts-ignore
      let SMAUTHREASON = this.urlSearch("SMAUTHREASON");

      let esecURL;
      if (decodedTARGET == null) {
        let parser = document.createElement("a");
        parser.href = window.location.href;
        decodedTARGET = parser.origin + "/tes-web/dashboard";
        //@ts-ignore
        esecURL = parser.origin + "/" + this.uiFeature.ESEC_URL.displayName;
        SMAUTHREASON = "0";
      } else {
        esecURL =
          //@ts-ignore
          decodedTARGET.substring(4, decodedTARGET.indexOf(".gov") + 4) +
          "/" +
          //@ts-ignore
          this.uiFeature.ESEC_URL.displayName;
      }

      let formData = new URLSearchParams();
      //@ts-ignore
      formData.append("target", decodedTARGET);
      //@ts-ignore
      formData.append("smauthreason", SMAUTHREASON);
      //@ts-ignore
      formData.append("SMENC", this.uiFeature.SMENC.displayName);
      //@ts-ignore
      formData.append("SMLOCALE", this.uiFeature.SMLOCALE.displayName);
      //@ts-ignore
      formData.append("USER", this.userName);
      //@ts-ignore
      formData.append("PASSWORD", this.password);
      if (this.screeningResponse.esecScreeningId) {
        //@ts-ignore
        formData.append(
          "TESScreeningID",
          this.screeningResponse.esecScreeningId
        );
      }

      axios
        .post(esecURL, formData, {
          transformRequest: [
            (data, headerValues: any) => {
              delete headerValues.common["AUTH_API_KEY"];
              return data;
            }
          ]
        })
        .then(response => {
          let responseURL = response.request.responseURL;
          this.determineRedirect(responseURL);
        })
        .catch(() => {
          this.isLoginLoading = false;
        });
    },
    determineRedirect(responseURL: string) {
      if (
        responseURL &&
        (responseURL.indexOf(ESEC_REDIRECT_STRINGS.ACCESS_DENIED) !== -1 ||
          responseURL.indexOf(ESEC_REDIRECT_STRINGS.IDSERVICES) !== -1)
      ) {
        //@ts-ignore
        let redirectURL = this.uiFeature.CTZURL_QA.largeText;
        if (this.userName.toLowerCase().startsWith("b-")) {
          //@ts-ignore
          redirectURL = this.uiFeature.BPURL_QA.largeText;
        }
        if (responseURL.indexOf(ESEC_REDIRECT_STRINGS.CTZACSURL) !== -1) {
          //@ts-ignore
          redirectURL = this.uiFeature.CTZURL_ROL.largeText;
        }
        if (
          responseURL.indexOf(ESEC_REDIRECT_STRINGS.PUBLICURL) !== -1 ||
          responseURL.indexOf(ESEC_REDIRECT_STRINGS.ACCESS_DENIED) !== -1
        ) {
          redirectURL = responseURL;
        }
        window.location.href = redirectURL;
      } else if (
        responseURL &&
        (responseURL.indexOf(ESEC_REDIRECT_STRINGS.LOCKEDAUTHRSN7) !== -1 ||
          responseURL.indexOf(ESEC_REDIRECT_STRINGS.LOCKEDAUTHRSN24) !== -1 ||
          responseURL.indexOf(ESEC_REDIRECT_STRINGS.LOCKEDAUTHRSN51) !== -1)
      ) {
        this.isLoginLoading = false;
        this.loginError = true;
        //@ts-ignore
        this.loginErrorMsg = this.uiFeature.ACC_LOCKED.displayName;
      } else {
        this.validateAuthentication();
      }
    },
    validateAuthentication() {
      axios
        .get(TES_APP_ENDPOINT.USER_LOGIN_VERIFY)
        .then(response => {
          let respVal = response.data;
          if (respVal === "SUCCESS") {
            this.loginError = false;
            //@ts-ignore
            let target: string = this.urlSearch("TARGET");
            if (target && target.indexOf("applID") !== -1) {
              this.redirectToApplicationReview(target);
            } else {
              router.push({ name: "dashboard" });
            }
          } else if (respVal === "LOCKED") {
            this.loginError = true;
            //@ts-ignore
            this.loginErrorMsg = this.uiFeature.ACC_LOCKED.displayName;
          } else if (respVal === "FAILED") {
            this.loginError = true;
            //@ts-ignore
            this.loginErrorMsg = this.uiFeature.LOGIN_FAIL.displayName;
          }
        })
        .finally(() => {
          this.isLoginLoading = false;
        });
    },
    async redirectToApplicationReview(target: string) {
      await store.dispatch.tesUserModule.getUserDetails();
      let fein = null;
      let applicationId = target.slice(target.lastIndexOf("=") + 1);
      let url = TES_APP_ENDPOINT.GET_PROFILE_DETAILS_BY_APP_ID_AGENCY;
      axios
        .get(url, {
          params: {
            tesApplicationId: applicationId,
            userAgency: null,
            fein
          }
        })
        .then(response => {
          this.tesApplicationModel = response.data;
          if (response && response.data) {
            store.commit.appModule.SET_DOB_MATCH(response.data.nameDOBMatchInd);
          }
          //@ts-ignore
          store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(
            this.tesApplicationModel.draftProgramInfoModel
          );

          //@ts-ignore
          store.commit.applicantProgramModule.SET_APPLICANT_PROGRAM(
            this.tesApplicationModel.programEligibilityModel
          );

          //@ts-ignore
          store.commit.applicantProfileModule.SET_APPLICANT_PROFILE(
            this.tesApplicationModel.applicantProfileModel
          );
          //Get the Driver license details
          store.dispatch.applicantProfileModule.getDriverLicenseDetails(
            //@ts-ignore
            store.getters.draftProgramInfoModule.getDraftProgramInfo
          );
          store.dispatch.applicationReviewModule.loadApplicationReviewModel({
            tesApplicationId: this.tesApplicationModel.applicantionId,
            cycleNumber: this.tesApplicationModel.cycleNumber
          });

          store.dispatch.screeningResponseModule.populateScrnRespByApplProfileID(
            this.tesApplicationModel.applicantProfileId
          );
          //redirect to target url
          target = target.replace("$SM$", "");
          window.location.href = target;
        });
    }
  }
});
