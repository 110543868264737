
import Vue from "vue";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import AuthorizationDisc from "@/components/application/program/matp/AuthorizationDisc.vue";
import MedicalAssistance from "@/components/application/program/matp/MedicalAssistance.vue";
import Transportation from "@/components/application/program/matp/Transportation.vue";
import tesProgramTemplate from "@/components/common/TESProgramTemplate.vue";
import { IProgramsList, IDraftProgramInfo, IApplicantProfile } from "@/types";
import store from "@/store";
import router from "@/router";
import StepperNavigationMixinVue from "@/mixins/StepperNavigationMixin.vue";
import AttachmentsMixinVue from "@/mixins/AttachmentsMixin.vue";
import { BUTTON_NAMES } from "@/constants/CommonConstants";
import routerMixin from "@/mixins/RouterMixin.vue";
import MATPAttachments from "@/components/application/program/attachments/MATPIndividualFlowAttachments.vue";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";

export default Vue.extend({
  name: "program-matp",
  mixins: [
    fieldEntryMixin,
    StepperNavigationMixinVue,
    routerMixin,
    AttachmentsMixinVue,
    ApplicationMixin
  ],
  components: {
    tesProgramTemplate,
    AuthorizationDisc,
    MedicalAssistance,
    Transportation,
    MATPAttachments
  },
  data: function() {
    return {
      stepCount: 4 as number
    };
  },
  computed: {
    applicantProgramInfo: {
      get(): IProgramsList {
        return store.getters.applicantProgramModule.getApplicantProgram;
      }
    },
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      }
    },
    applicantProfile: {
      get(): IApplicantProfile {
        return store.getters.applicantProfileModule.getApplicantProfile;
      },
      set(newValue: IApplicantProfile) {
        store.commit.applicantProfileModule.SET_APPLICANT_PROFILE(newValue);
      }
    },
    prfPrsnCareAttendantCodes(): any {
      //@ts-ignore
      return this.uiFeature.PRF_PRCARE.children;
    }
  },
  methods: {
    moveFocusToNextStep() {
      let elementTofocus;
      //@ts-ignore
      if (this.$refs.programMATPTemplate.stepperIndex === 2) {
        elementTofocus = document.getElementById("matpStep2Id");
        //@ts-ignore
      } else if (this.$refs.programMATPTemplate.stepperIndex === 3) {
        elementTofocus = document.getElementById("matpStep3Id");
        //@ts-ignore
      } else if (this.$refs.programMATPTemplate.stepperIndex === 4) {
        elementTofocus = document.getElementById("matpStep4Id");
      }
      if (elementTofocus) {
        elementTofocus.focus();
      }
    },
    saveCompleted(buttonName: string) {
      if (buttonName === "CANCEL") {
        this.continueToDashboard();
      }
      //@ts-ignore
      else if (this.$refs.programMATPTemplate.stepperIndex !== this.stepCount) {
        //@ts-ignore
        this.$refs.programMATPTemplate.nextStep();
        this.moveFocusToNextStep();
        //@ts-ignore
        this.scrollToSectionHeader(this.$refs.programMATPTemplate);
      } else if (
        //@ts-ignore
        this.isSingleFlow()
      ) {
        if (
          buttonName === BUTTON_NAMES.SAVECONTINUE ||
          buttonName === BUTTON_NAMES.CONTINUE ||
          buttonName === "nomatp"
        ) {
          let nextProgram = "";
          //@ts-ignore
          nextProgram = this.nextProgram(
            "MATP",
            this.applicantProgramInfo.transitAgencyCode
          );
          if (nextProgram === "DOCS" || nextProgram === "REVIEW") {
            router.push({
              path: "/application-program/" + nextProgram,
              query: {
                profileID: String(this.applicantProgramInfo.applicantProfileId),
                prgmType: nextProgram
              }
            });
          } else {
            router.push({
              path: "/application-program/" + nextProgram,
              query: {
                profileID: String(this.applicantProgramInfo.applicantProfileId),
                prgmType: nextProgram,
                agencyType: this.applicantProgramInfo.transitAgencyCode
              }
            });
          }
        }
      } else {
        router.push({
          path:
            "/application-review/" + this.applicantProgramInfo.programTypeCode,
          query: {
            profileID: String(this.applicantProgramInfo.applicantProfileId),
            prgmType: this.applicantProgramInfo.programTypeCode,
            agencyType: this.applicantProgramInfo.transitAgencyCode
          }
        });
      }
    },
    changeStepCount(steps: number) {
      this.stepCount = steps;
    },
    continueToDashboard() {
      //@ts-ignore
      this.forwardToDashboard();
    }
  },
  created() {
    if (this.draftProgramInfo.dhsAuthInd === "N") {
      this.stepCount = 1;
    } else if (this.draftProgramInfo.medAssistanceEligibilityInd === "N") {
      this.stepCount = 2;
    } else if (
      //@ts-ignore
      this.isSingleFlow()
    ) {
      this.stepCount = 3;
    } else {
      if (
        (this.applicantProfile.travelAttendantInd === "Y" &&
          (this.applicantProgramInfo.transitAgencyCode === "CPTA" ||
            this.applicantProgramInfo.transitAgencyCode === "CAT")) ||
        (this.applicantProgramInfo.transitAgencyCode === "FREEDOM" &&
          (this.draftProgramInfo.massTransitInabilityInd === "Y" ||
            this.draftProgramInfo.transportationDisabilityInd === "Y" ||
            this.draftProgramInfo.rideAloneInd === "Y")) ||
        (this.applicantProgramInfo.transitAgencyCode === "CTDC" &&
          this.draftProgramInfo.medAssistanceEligibilityInd === "Y")
      ) {
        this.stepCount = 4;
      } else {
        this.stepCount = 3;
      }
    }
  }
});
