
import Vue from "vue";
import ExpansionPanelTemplate from "@/components/common/ExpansionPanelTemplate.vue";
import { expansionPanelIconObj } from "@/constants/CommonConstants";
import UIFeatureMixin from "@/mixins/UIFeatureMixin.vue";

export default Vue.extend({
  name: "tes-container-quehint-template",
  components: {
    ExpansionPanelTemplate
  },
  mixins: [UIFeatureMixin],
  data() {
    return {
      expPanelIconObj: expansionPanelIconObj
    };
  },
  props: {
    questionHintHeader: { type: String, default: "" },
    questionHint: { type: String, default: "" },
    secondSubsectionHeader: { type: String, default: "" },
    secondSubsection: { type: String, default: "" },
    thirdSubsectionHeader: { type: String, default: "" },
    thirdSubsection: { type: String, default: "" },
    fourthSubsectionHeader: { type: String, default: "" },
    fourthSubsection: { type: String, default: "" },
    showMATPLink: {
      type: Boolean,
      default: false
    },
    noBGColor: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    applyClassStyle() {
      let baseClass;
      if (!this.noBGColor) {
        baseClass = "bgcolor";
      }
      return baseClass;
    }
  }
});
