
import Vue from "vue";
import pwdImage from "@/assets/PWDPWD.png";
import adaImage from "@/assets/ADA.png";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import ftpImage from "@/assets/FTP.png";
import ssrImage from "@/assets/SSR.png";
import matpImage from "@/assets/MATP.png";

export default Vue.extend({
  mixins: [UIFeatureMixinVue],
  data() {
    return {
      items: [
        {
          src: pwdImage,
          title: "PWD",
          description: "PWD"
        },
        {
          src: adaImage,
          title: "ADA",
          description: "ADA"
        },
        {
          src: ftpImage,
          title: "FTP",
          description: "FTP"
        },
        {
          src: matpImage,
          title: "MATP",
          description: "MATP"
        },
        {
          src: ssrImage,
          title: "SSR",
          description: "SSR"
        }
      ],
      carouselIndex: 0,
      ariaLiveZone: false,
      cycle: true,
      play: true
    };
  },
  computed: {
    interval(): string {
      //@ts-ignore
      if (this.uiFeature.CRSL_INTVL.displayName) {
        //@ts-ignore
        return this.uiFeature.CRSL_INTVL.displayName;
      }
      return "6000";
    }
  },
  methods: {
    activateAriaLive() {
      this.ariaLiveZone = true;
    }
  }
});
