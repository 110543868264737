
import Vue from "vue";
import store from "@/store";
import { USER_GROUPS, CNST_TEXT } from "@/constants/CommonConstants";
import UIFeatureMixinVue from "./UIFeatureMixin.vue";
import { IApplicantProfile } from "@/types";
import { GPC } from "@/constants/GPCCode";

export default Vue.extend({
  mixins: [UIFeatureMixinVue],
  methods: {
    buildTxtWithOnePlaceHolder(
      gpc: string,
      param1: string,
      param2: string
    ): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[gpc].largeText,
          param1
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[gpc].largeText,
          param2
        );
      }
      return text;
    },
    buildTxtWithOnePlaceHolderVal(
      gpc: string,
      param1: string,
      param2: string
    ): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[gpc].displayName,
          param1
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[gpc].displayName,
          param2
        );
      }
      return text;
    },
    buildTxtWithTwoPlaceHolder(
      gpc: string,
      param1: string,
      param2: string,
      param3: string,
      param4: string
    ): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[gpc].largeText,
          param1,
          param2
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[gpc].largeText,
          param3,
          param4
        );
      }
      return text;
    },
    isOrgAgyCtznonBehalfOfUser() {
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        return true;
      } else {
        return false;
      }
    },
    FtpRftpProviderQues(): string {
      let text: string = "";
      text = this.buildTxtWithOnePlaceHolderVal(
        GPC.AN_FRQ_PRO,
        CNST_TEXT.DOES_THE_APPLICANT_D,
        CNST_TEXT.DO_YOU
      );
      return text;
    },
    ftpDoNotQualifyDynamicText(largeText: string): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrgAgyCtznonBehalfOfUser()
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          largeText,
          CNST_TEXT.TTHE_APPLICANT,
          CNST_TEXT.THEY,
          CNST_TEXT.THEY
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          largeText,
          CNST_TEXT.YYOU,
          CNST_TEXT.YOU,
          CNST_TEXT.YOU
        );
      }
      return text;
    },
    matpDoNotQualifyDynamicText(largeText: string): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrgAgyCtznonBehalfOfUser()
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          largeText,
          CNST_TEXT.TTHE_APPLICANT,
          CNST_TEXT.THEY,
          CNST_TEXT.THEY,
          CNST_TEXT.THE_APPLICANT_HAS,
          CNST_TEXT.THEIR,
          CNST_TEXT.THEIR
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          largeText,
          CNST_TEXT.YYOU,
          CNST_TEXT.YOU,
          CNST_TEXT.YOU,
          CNST_TEXT.YOU_HAVE,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOUR
        );
      }
      return text;
    },
    vtpDoNotQualifyDynamicText(largeText: string): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrgAgyCtznonBehalfOfUser()
      ) {
        //@ts-ignore
        text = this.buildDynamicText(largeText, CNST_TEXT.THE_APPLICANT_IS);
      } else {
        //@ts-ignore
        text = this.buildDynamicText(largeText, CNST_TEXT.YOU_ARE);
      }
      return text;
    },
    rftpDoNotQualifyDynamicText(largeText: string): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrgAgyCtznonBehalfOfUser()
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          largeText,
          CNST_TEXT.TTHE_APPLICANT,
          CNST_TEXT.THEY,
          CNST_TEXT.DOES
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          largeText,
          CNST_TEXT.YYOU,
          CNST_TEXT.YOU,
          CNST_TEXT.DO
        );
      }
      return text;
    }
  },
  computed: {
    isOrganizationUser() {
      return (
        store.getters.tesUserModule.getTesUser.userGroups ===
        USER_GROUPS.PD_TES_APPLICANT
      );
    },
    isAgencyUser() {
      return [
        USER_GROUPS.PD_TES_AGREVIEWER,
        USER_GROUPS.PD_FMR_AGREVIEWERASSIGNED,
        USER_GROUPS.PD_FMR_AGSUPERVISOR
      ].includes(store.getters.tesUserModule.getTesUser.userGroups);
    },
    isCOReadOnlyUser() {
      return (
        store.getters.tesUserModule.getTesUser.userGroups ===
        USER_GROUPS.PD_TES_COREADONLY
      );
    },
    isCitizenUser() {
      return (
        store.getters.tesUserModule.getTesUser.userGroups ===
        USER_GROUPS.PD_TES_CITIZEN
      );
    },
    applicantProfile: {
      get(): IApplicantProfile {
        return store.getters.applicantProfileModule.getApplicantProfile;
      }
    }
  }
});
