export default function helper() {
  const getAriaLabel = (
    value: string,
    rules: any[],
    field: string,
    appendField?: string
  ): string => {
    let label: string = field;
    switch (rules.length) {
      case 1:
        if (rules[0](value) === "Required.")
          label = `${field} is ${rules[0](value)}`;
        else if (typeof rules[0](value) === "string")
          label = getLabel(rules[0](value), appendField);
        break;
      case 2:
        if (rules[0](value) === "Required.")
          label = `${field} is ${rules[0](value)}`;
        else if (typeof rules[0](value) === "string")
          label = getLabel(rules[0](value), appendField);
        else if (typeof rules[1](value) === "string")
          label = getLabel(rules[1](value), appendField);
        break;
      case 3:
        if (rules[0](value) === "Required.")
          label = `${field} is ${rules[0](value)}`;
        else if (typeof rules[0](value) === "string")
          label = getLabel(rules[0](value), appendField);
        else if (typeof rules[1](value) === "string")
          label = getLabel(rules[1](value), appendField);
        else if (typeof rules[2](value) === "string")
          label = getLabel(rules[2](value), appendField);
        break;
    }
    return label;
  };
  const getLabel = (value: string, appendField?: string): string => {
    return appendField ? `${appendField} ${value}` : `${value}`;
  };
  return { getAriaLabel };
}
