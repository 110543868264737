
import Vue from "vue";
import {
  IApplicationReviewModel,
  IDraftProgramInfo,
  IProgramsList
} from "@/types";

import store from "@/store";
import router from "@/router";
import routerMixin from "@/mixins/RouterMixin.vue";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";
import StepperNavigationMixinVue from "@/mixins/StepperNavigationMixin.vue";
import { APPL_STATUS } from "@/constants/CommonConstants";

export default Vue.extend({
  name: "application-review-controls",
  mixins: [routerMixin, StepperNavigationMixinVue, ApplicationMixin],
  components: {},
  data() {
    return {
      singleFlow: false,
      editCndtn: false,
      statusCode: ""
    };
  },
  props: {
    applicantProgramInfoObj: {
      type: Object as () => IProgramsList
    },
    draftProgramInfoObj: {
      type: Object as () => IDraftProgramInfo
    }
  },
  computed: {
    applicationReviewModel(): IApplicationReviewModel {
      return store.getters.applicationReviewModule.getApplicationReviewModel;
    },
    showRetBtn(): boolean {
      let tempstatusCode = this.applicantProgramInfoObj.statusCode;
      if (
        tempstatusCode === APPL_STATUS.UNDER_REVIEW ||
        tempstatusCode === APPL_STATUS.PENDING_CONFIGURATION ||
        tempstatusCode === APPL_STATUS.RETURN_FOR_CORRECTION
      ) {
        return false;
      }
      return true;
    },
    renderReviewSummaryWEcolane(): boolean {
      let showReviewSummaryWEcolane = false;
      if (
        this.applicationReviewModel &&
        this.applicationReviewModel.applicationReviewId > 0 &&
        //@ts-ignore
        !this.isCitizenUser &&
        (this.applicantProgramInfoObj.statusCode === "RE_COR" ||
          this.applicantProgramInfoObj.statusCode === "INELIGIBLE" ||
          this.applicantProgramInfoObj.statusCode === APPL_STATUS.INCOMPLETE ||
          this.applicantProgramInfoObj.statusCode === "PEND_CONF" ||
          this.applicantProgramInfoObj.statusCode === "APPROVED" ||
          this.applicantProgramInfoObj.statusCode === APPL_STATUS.TMP_APRVD ||
          this.applicantProgramInfoObj.statusCode === APPL_STATUS.PENDING)
      ) {
        showReviewSummaryWEcolane = true;
      }
      return showReviewSummaryWEcolane;
    }
  },
  created() {
    this.statusCode = this.applicantProgramInfoObj.statusCode;
    if (
      this.statusCode === APPL_STATUS.READY_TO_SUMBMIT ||
      this.statusCode === APPL_STATUS.IN_PROGRESS ||
      this.statusCode === APPL_STATUS.READY_TO_SUMBMIT_C ||
      this.statusCode === APPL_STATUS.IN_PROGRESS_C
    ) {
      this.editCndtn = true;
    }
    if (
      //@ts-ignore
      this.isSingleFlow()
    ) {
      this.singleFlow = true;
    }
  },
  methods: {
    GotoDashboard() {
      store.dispatch.applicantProgramModule.resetData();
      //@ts-ignore
      this.forwardToDashboard();
    },
    editApplication() {
      //@ts-ignore
      store.commit.applicantProgramModule.SET_APPLICANT_PROGRAM(
        this.applicantProgramInfoObj
      );
      //@ts-ignore
      store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(
        this.draftProgramInfoObj
      );
      router.push({
        path:
          "/application-program/" + this.applicantProgramInfoObj.programTypeCode
      });
    }
  }
});
