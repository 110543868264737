<script>
import { LOGGER } from "@/util/logger";
import { DATE_FORMAT } from "@/constants/DateFormats.ts";

export default {
  data() {
    return {};
  },
  methods: {
    stringToDate(dateString) {
      let newDate = null;

      if (dateString !== null) {
        const splitDate = dateString.split("-");
        newDate = new Date(splitDate[0], splitDate[1] - 1, splitDate[2]);
      }

      return newDate;
    },
    formatDateMixin(val) {
      if (val) {
        return this.$moment(val).format(DATE_FORMAT.LOCAL_DATE_FORMAT);
      } else {
        LOGGER.logException(
          "Invalid Date passed to formatDateMixin method: " + val
        );
        return null;
      }
    },
    formatDateMixinForPromise(val) {
      if (val) {
        return this.$moment(val).format(DATE_FORMAT.ISO860_SHORT_DATE_FORMAT);
      } else {
        LOGGER.logException(
          "Invalid Date passed to formatDateMixin method: " + val
        );
        return null;
      }
    },
    formatTimeMixin(val) {
      if (val) {
        if (this.$moment(val, "HH:mm", true).isValid()) {
          return this.$moment(val, "HH:mm").format("hh:mm A");
        } else if (this.$moment(val, "HH:mm:ss", true).isValid()) {
          return this.$moment(val, "HH:mm:ss").format("hh:mm:ss A");
        } else {
          LOGGER.logException(
            "Invalid Time passed to formatTimeMixin method: " + val
          );
          return null;
        }
      } else {
        LOGGER.logException(
          "Invalid Time passed to formatTimeMixin method: " + val
        );
        return null;
      }
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    formatLocalTimeStamp(isoDateTime) {
      return this.$moment(isoDateTime).isValid()
        ? this.$moment(isoDateTime).format(DATE_FORMAT.LOCAL_DATE_TIME_FORMAT)
        : null;
    },

    /**
     * Checks whether a date string is in valid ISO 860 date format
     * formatted string.
     * @param String date - Date in ISO 860 date (YYYY-MM-DD) format
     * @return Boolean
     */
    isValidISO860Date(value) {
      if (value.length != 10) return false;
      return this.$moment(value, DATE_FORMAT.ISO860_DATE_FORMAT).isValid();
    },

    /**
     * Parse a string in local short date format and return an ISO 860
     * formatted string.
     * @param String date - Date in local short date (MMDDYYYY) format
     * @return String - Date in ISO 860 date (YYYY-MM-DD) format
     */
    convertLocalShortToISO860(date) {
      if (!date || date.length != 8) return null;
      return this.$moment(
        date,
        DATE_FORMAT.LOCAL_SHORT_DATE_FORMAT,
        true
      ).format(DATE_FORMAT.ISO860_DATE_FORMAT);
    },

    /**
     * Parse a string in local short date format and return local date
     * formatted string.
     * @param String date - Date in local short date (MMDDYYYY) format
     * @return String - Date in local date (MM/DD/YYYY) format
     */
    convertLocalShortToLocalDate(date) {
      if (!date || date.length !== 8) return null;
      return this.$moment(
        date,
        DATE_FORMAT.LOCAL_SHORT_DATE_FORMAT,
        true
      ).format(DATE_FORMAT.LOCAL_DATE_FORMAT);
    },

    /**
     * Parse a string in ISO 860 date format and return a local short date
     * formatted string.
     * @param String date - Date in ISO 860 date (YYYY-MM-DD) format
     * @return String - Date in local short date (MMDDYYYY) format
     */
    convertISO860ToLocalShort(date) {
      if (!date || date.length != 10) return null;
      return this.$moment(date, DATE_FORMAT.ISO860_DATE_FORMAT, true).format(
        DATE_FORMAT.LOCAL_SHORT_DATE_FORMAT
      );
    },

    /**
     * Parse a string in local date format and return an ISO 860
     * formatted string.
     * @param String date - Date in local date (MM/DD/YYYY) format
     * @return String - Date in ISO 860 date (YYYY-MM-DD) format
     */
    convertLocalToISO860(date) {
      if (!date || date.length != 10) return null;
      return this.$moment(date, DATE_FORMAT.LOCAL_DATE_FORMAT, true).format(
        DATE_FORMAT.ISO860_DATE_FORMAT
      );
    },

    /**
     * Parse a string in ISO 860 format and return local date
     * formatted string.
     * @param String - Date in ISO 860 date (YYYY-MM-DD) format
     * @return String - Date in local date (MM/DD/YYYY) format
     */
    convertISO860ToLocalDate(date) {
      if (!date || date.length != 10) return null;
      return this.$moment(date, DATE_FORMAT.ISO860_DATE_FORMAT, true).format(
        DATE_FORMAT.LOCAL_DATE_FORMAT
      );
    },

    /**
     * Checks if date is in local short date format and is valid.
     * @param String date - Date in local short date (MMDDYYYY) format
     * @return Boolean
     */
    isValidLocalShortDate(date) {
      if (!date || date.length != 8) return false;
      return this.$moment(
        date,
        DATE_FORMAT.LOCAL_SHORT_DATE_FORMAT,
        true
      ).isValid();
    },

    /**
     * Checks if date is in local date format and is valid.
     * @param String date - Date in local date (MM/DD/YYYY) format
     * @return Boolean
     */
    isValidLocalDate(date) {
      if (!date || date.length != 10) return false;
      return this.$moment(date, DATE_FORMAT.LOCAL_DATE_FORMAT, true).isValid();
    },

    /**
     * Checks if date is valid against format passed in.  If no format
     * is passed in, default to the local date format
     * @param String date - Date string
     * @param String format - Format used to parse date string
     * @return Boolean
     */
    isValidDate(date, format) {
      if (!date) return false;
      if (!format) {
        return this.$moment(
          date,
          DATE_FORMAT.LOCAL_DATE_FORMAT,
          true
        ).isValid();
      } else {
        return this.$moment(date, format, true).isValid();
      }
    },
    /**
     * Checks if the end date is after the start date passed in.
     * @param String startDate - Start Date string
     * @param String endDate - End Date string
     * @return Boolean
     */
    isEndDateAfterStartDate(startDate, endDate) {
      return Date.parse(startDate) <= Date.parse(endDate);
    },
    parseTESDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${month.padStart(2, "0")}/${day.padStart(2, "0")}/${year}`;
    },
    isFutureDate(date) {
      var inputDate = new Date(date);
      var todaysDate = new Date();
      return inputDate.setHours(0, 0, 0, 0) > todaysDate.setHours(0, 0, 0, 0);
    },
    isPastDate(date) {
      var inputDate = new Date(date);
      var todaysDate = new Date();
      return inputDate.setHours(0, 0, 0, 0) < todaysDate.setHours(0, 0, 0, 0);
    },
    skipValueOnReset(value) {
      if (
        value &&
        //@ts-ignore
        this.validateDate(value)
      ) {
        //@ts-ignore
        value = this.parseTESDate(value);
        if (
          //@ts-ignore
          !this.futureDate &&
          //@ts-ignore
          this.isFutureDate(value)
        ) {
          //@ts-ignore
          this.dateError = true;
          //@ts-ignore
          this.dateErrorMessages = "Date cannot be a Future date";
        } else if (
          //@ts-ignore
          !this.pastDate &&
          //@ts-ignore
          this.isPastDate(value)
        ) {
          //@ts-ignore
          this.dateError = true;
          //@ts-ignore
          this.dateErrorMessages = "Date cannot be a Past date";
        } else if (
          //@ts-ignore
          this.isValidLocalDate(value)
        ) {
          //@ts-ignore
          this.$emit("input", this.convertLocalToISO860(value));
          //@ts-ignore
          this.$emit("input", this.parseDate(value));

          //@ts-ignore
          this.errorMsg();
          //@ts-ignore
          this.dataRules = "";
        } else if (!value) {
          //@ts-ignore
          this.$emit("input", value);
          //@ts-ignore
          this.errorMsg();
        } else {
          //@ts-ignore
          this.displayErrMsg();
        }
      } else {
        //@ts-ignore
        this.displayErrMsg();
      }
    }
  }
};
</script>
