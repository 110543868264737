
import Vue from "vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import SigninRegister from "@/components/home/SigninRegister.vue";

export default Vue.extend({
  name: "home-main",
  mixins: [UIFeatureMixinVue],
  components: {
    SigninRegister
  },
  data() {
    return {
      isMobileLoginOpen: false as boolean
    };
  },
  props: {
    idleLogOut: { type: Boolean, default: false },
    showLoginSimulationBtn: { type: Boolean, default: false }
  },
  computed: {
    welcomeImgSrc(): string {
      //@ts-ignore
      if (this.largeText("HOME_IMG")) {
        //@ts-ignore
        return require("@/" + this.largeText("HOME_IMG"));
      }
      return "";
    },
    showLogin(): boolean {
      let showLogin: boolean = false;

      if (this.$vuetify.breakpoint.xsOnly && this.isMobileLoginOpen) {
        showLogin = true;
      }

      if (!this.$vuetify.breakpoint.xsOnly) {
        showLogin = true;
      }

      return showLogin;
    },
    compuParallaxClass(): string {
      return this.isMobileLoginOpen && this.$vuetify.breakpoint.xsOnly
        ? "mainsectionTall"
        : "mainsectionShort";
    }
  }
});
