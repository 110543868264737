
import Vue from "vue";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import { TES_APP_ENDPOINT } from "@/constants/EndPoints";
import axios from "axios";
import store from "@/store";
import { IProgramsList, IApplicantProfile } from "@/types";
import { HTTP_STATUS } from "@/constants/HttpStatusCode";
import router from "@/router";
import ApplicantProfileMixin from "@/mixins/ApplicantProfileMixin.vue";
import ProgressBar from "@/components/common/ProgressBar.vue";
import NotQualifiedDialog from "@/components/dashboard/applicant/common/NotQualifiedDialog.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";

export default Vue.extend({
  name: "ProfileReview-Dashboard",
  components: { ProgressBar, NotQualifiedDialog },
  mixins: [
    fieldEntryMixin,
    AppPropsMixin,
    ApplicantProfileMixin,
    UIFeatureMixinVue
  ],
  data: function() {
    return {
      eligiblePrgmList: {} as IProgramsList,
      preEligiblePrgmList: {} as IProgramsList,
      notEligiblePrgmList: {} as IProgramsList,
      applicantAge: 0,
      notQualifiedDialog: false,
      pwdDialog: false,
      showProgressBar: true
    };
  },
  computed: {
    applicantProgramInfo: {
      get(): IProgramsList {
        //@ts-ignore
        return store.getters.applicantProgramModule.getApplicantProgram;
      },
      set(newValue: IProgramsList) {
        //@ts-ignore
        store.commit.applicantProgramModule.SET_APPLICANT_PROGRAM(newValue);
      }
    },
    applicantProfileInfo(): IApplicantProfile {
      return store.getters.applicantProfileModule.getApplicantProfile;
    }
  },
  created() {
    let url = TES_APP_ENDPOINT.GET_APPL_PRGM_ELIGIBILITIES;
    this.populateRequestObject(this.eligiblePrgmList);
    axios
      .post(url, this.eligiblePrgmList)
      .then(response => {
        if (response.status === HTTP_STATUS.OK) {
          this.eligiblePrgmList = response.data.eligiblePrograms;
          this.preEligiblePrgmList = response.data.preEligiblePrograms;
          this.notEligiblePrgmList = response.data.notEligiblePrograms;
          this.applicantAge = response.data.applicantAge;
        }
      })
      .catch(error => {
        store.dispatch.alertModule.showAlertMessageById(error.response.data.id);
      })
      .finally(() => {
        this.showProgressBar = false;
      });
  },

  methods: {
    populateRequestObject(programRequest: IProgramsList) {
      programRequest.applicantProfileId = this.applicantProfileInfo.applicantProfileId;
      programRequest.applicantDob = this.applicantProfileInfo.applicantDob;
    },
    displayDialog(pwdflag: boolean) {
      this.notQualifiedDialog = true;
      this.pwdDialog = pwdflag;
    },
    checkCondition(notEligProgram: any) {
      if (
        (this.applicantAge < 60 &&
          (notEligProgram.programTypeCode === "FTP" ||
            notEligProgram.programTypeCode === "SSR")) ||
        (this.applicantAge > 65 && notEligProgram.programTypeCode === "PWD")
      ) {
        return true;
      }
    },
    checkPWDCondition(notEligProgram: any) {
      if (this.applicantAge > 65 && notEligProgram.programTypeCode === "PWD") {
        return true;
      } else {
        return false;
      }
    },
    navigateToProgram(program: any) {
      //@ts-ignore
      store.commit.applicantProgramModule.SET_APPLICANT_PROGRAM(program);
      router.push({
        path: "/application-program/" + program.programTypeCode
      });
    }
  }
});
