
import Vue from "vue";

import { HTTP_STATUS } from "@/constants/HttpStatusCode";
import { IApplicantProfile, IDraftProgramInfo, IProgramsList } from "@/types";
import store from "@/store";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import EventHistoryMixinVue from "@/mixins/EventHistoryMixin.vue";
import EventHistory from "@/components/common/EventHistory.vue";
import StepperHeader from "@/components/common/StepperHeader.vue";
import CloseButton from "@/components/common/CloseButton.vue";
import editProfileProgramChangesMsg from "@/components/application/EditProfileProgramChangesMsg.vue";
import ApplicationResult from "@/components/application/program/ApplicationResult.vue";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import StepperNavigationMixinVue from "@/mixins/StepperNavigationMixin.vue";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";
import InfoSection from "@/components/common/InfoSection.vue";
import AccessibilityMixin from "@/mixins/AccessibilityMixin.vue";

export default Vue.extend({
  name: "tes-program-template",
  mixins: [
    UIFeatureMixinVue,
    EventHistoryMixinVue,
    fieldEntryMixin,
    StepperNavigationMixinVue,
    AppPropsMixin,
    ApplicationMixin,
    AccessibilityMixin
  ],
  components: {
    EventHistory,
    StepperHeader,
    CloseButton,
    editProfileProgramChangesMsg,
    ApplicationResult,
    InfoSection
  },
  data() {
    return {
      stepperIndex: 1 as number,
      showDialog: false,
      eventLogs: [],
      selectedTransitAgencyData: {} as Object,
      providerLinkDialog: false,
      byPassChkBox: false,
      byPassHeaderTxt: { type: String, default: "" },
      byPassHeaderBdyTxt: { type: String, default: "" },
      byPassChkBoxTxt: { type: String, default: "" },
      el: {} as HTMLElement
    };
  },
  props: {
    mainHeader: { type: String, default: "" },
    subHeader: { type: String, default: "" },
    step1Header: { type: String, default: "" },
    step2Header: { type: String, default: "" },
    step3Header: { type: String, default: "" },
    step4Header: { type: String, default: "" },
    step5Header: { type: String, default: "" },
    stepCount: { type: Number, default: 1 },
    vertical: { type: Boolean, default: false },
    isSummaryScrn: { type: Boolean, default: false },
    showProviderContact: { type: Boolean, default: false },
    step2Id: { type: String, default: "" },
    step3Id: { type: String, default: "" },
    step4Id: { type: String, default: "" }
  },

  computed: {
    applicantProgramInfo: {
      get(): IProgramsList {
        //@ts-ignore
        return store.getters.applicantProgramModule.getApplicantProgram;
      }
    },
    applicantProfile: {
      get(): IApplicantProfile {
        //@ts-ignore
        return store.getters.applicantProfileModule.getApplicantProfile;
      },
      set(newValue: IApplicantProfile) {
        //@ts-ignore
        store.commit.applicantProfileModule.SET_APPLICANT_PROFILE(newValue);
      }
    },
    allTransitAgencyData: {
      get(): Object {
        return store.getters.allTransitAgencyDataModule.getAllTransitAgencyData;
      }
    },
    allTransitAgencyDataLoaded: {
      get(): boolean {
        return store.getters.allTransitAgencyDataModule.getDataLoaded;
      }
    },
    showEventHistoryLink(): boolean {
      //@ts-ignore
      return this.isCOReadOnlyUser || this.isAgencyUser;
    },
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      },
      set(newValue: IDraftProgramInfo) {
        store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(newValue);
      }
    },
    showByPassPrgm(): boolean {
      return (
        //@ts-ignore
        this.isSingleFlow() && (this.isOrganizationUser || this.isAgencyUser)
      );
    }
  },
  created() {
    const uiFeatureArray =
      "DIS_TYPES,SPL_NEEDS,INCM_TYPES,RADIO_YN,ENG,ARA,ZHO,FRE,DEU," +
      "GUJ,HAT,ITA,KOR,MKH,NEP,POL,POR,RUS,SPA,VIE,Y,N,M,F,NOT_START," +
      "IN_PRGS,RDY_SBMT,NOT_QLFYD,NOT_APPL,SBMTD,APPROVED,PERM,TEMP," +
      "DISABL_1,DISABL_2,DISABL_3,DISABL_4,DISABL_5,DISABL_6,DISABL_7," +
      "DISABL_OTH,INCM_1,INCM_2,INCM_3,INCM_4,INCM_5,INCM_6,INCM_7," +
      "INCM_8,AGE_PROOF,PRF_DSBLTY,PRF_DSBY_2,PRF_DSBY_3,CERT_DBLTY," +
      "MAN_WHLCHR,MOT_WHLCHR,MOTO_SCO,OXYGN,WALKER,CANE,CAN_CRUC," +
      "SRVC_ANML,MOB_OTHER,PA,DL_INVALID,DL_VALID,APPL_CRT,APPL_RSBT," +
      "APPL_SBTD,APPL_DQFD,STR_APPL,ASGN_APP,APP_ASGN,FNLZ,APPL_SBMT,SAVE,MEDICA_INS," +
      "DL_INSTRUC,DL_TMT_TOP,DL_TMT_BTM,MATP_SEC1,MATP_AUTH,MATP_SC1_2,MATP_SEC2," +
      "PWD_SEC1,PWD_SEC2,ADA_SEC1,ADA_SEC2,SSR_SEC1,SSR_SEC2,FTP_SEC1," +
      "FTP_SEC2,FTP_INFO,AGE_VRFYST,VOTER_REG,VOTE_YES,VOTE_NO,VOTE_ALRDY," +
      "RE_COR,IN_PRGS_C,RDY_SBMT_C,WITHDRAWN,PEND_CONF,NT_QLFY,DSB_ACT," +
      "ADA_COMP,FXD_RT,DOC_PROOFS,PRF_PRCARE,RVW_RCMNDS,ELIGIB,IN_ELIGB," +
      "INCMPLT,PNDNG,RETFORCORR,DOCUPL_ERR,ECL_CFG_RD,CMP_RV_SLF,CMP_RV_OTH," +
      "NO_ECLN_AG,NO_ECLN_PG,YNT_MATP1,YNT_FTP1,POD_AGNCYS,UN_RVW,APRW_STRT," +
      "STRT_RW,SAVE_CNTN,APP_NTAPCL,CMPLT_RVW,RVW_CMPLT,EDT_APP,APP_EDTD,WTHDRW," +
      "APP_WTDRWN,CNT_RVW,RWR_CHNGD,APPRV,CNFG_CMPLT,APPL_SBMT,APP_RSBMTD," +
      "CARE_ATTND,APPROVE,INPRN_ASMT,OTHER_DOC,COD_DOCTXT,PCA_DOCTXT,FRDMCODTXT," +
      "PARTCODTXT,AMTRAN_COD,C_COD_AGNY,MMVTA_E,RVT_E,AMTRAN_E,VLD_DOCEXT," +
      "INELIGIBLE,INCOMPLETE,CHGLGN_TXT,FILE_EXT,INVALD_DOC,DOCS_ADA,DOCS_PWD," +
      "DOCS_FTP,DOCS_SSR,DOCS_MATP,QUICK_SRCH,ER_INLINE,CARBONC_D,LNTA_D,WHITE," +
      "BLACK,ASIAN,HISPANIC,NATV_AMRCN,NATV_HAWAN,RACE_OTH,NOT_RSPND,NATV_ALSKN," +
      "PACF_ISLN,UNKNWN,TRANS_ASST,FMR,ENVRONMNT,PRG_INFO,DIS_CNFM,BTA_E,SCTA_D," +
      "BARTA_D,RRA_D,ADA_INFO,DS_PRV_FRS,VRFY_AGE_Q,AN_FRQ_PRO,MATP_INFO," +
      "MEDAST_ELG,MEDAST_RGS,MAID_SSN,NMTCH_SSN,HSHD_MATP,ACCS_VEH,VALD_DL,TNSPT_APT,BS_TRN_STP,MED_COG_RS," +
      "DIF_TNSPTS,RDE_WT_OTH,CPTA_COD,CLTS_COD,INELG_MATP,NMTCH_MAID,LT_COD,VOT_REG_Q1,CUR_MN_TRP," +
      "PSNL_C_ADT,PRMRY_LNG,LNG_INTRPR,RCE_ETNCTY,NRG_HME,DCRG_NG_HM,PSNL_CR_HM," +
      "PNL_CHM_TP,LFT_EN_VEH,ANY_MOB_DV,AL_MBD_LBL,CR_MN_VAN,WCHR_MET_Q,TMP_APRVD," +
      "PENDING,TMP_APRV,TM_APR_PCF,C_POD_PWD,C_COD_ADA,AG_ATT_TXT,APEL_INPRG," +
      "UPD_ELGBTY,APP_APPL,AGE_VRFY_D,C_COD_PWD,BRTH_CERT,BAPT_CERT,DL,US_SSA," +
      "PASSPT,PACE_ID,ARMD_SPAPR,PENNDOT_ID,RES_ALN,ST_COLLG_Q,NO_SRVAREA," +
      "RFTP_SEC1,RFTP_INFO,RFTP_APPLY,MEDCAR_IND,RFTP_APY_T,RFTP_APY_B,RFTP_MED_T," +
      "RFTP_MED_B,VTP_SEC1,VTP_INFO,YNT_VTP1,VTP_Q,HAS_SSN,EN_SSN,VTP_PROOF," +
      "VTP_INFO_1,DOCS_VTP,MCI_PROOF,DOCS_RFTP,DIG10_RN,BY_PAS_HDR,BY_PAS_CHK," +
      "DS_AGY_FRS,TVL_ARA_Q1,TVL_ARA_Q2,FIND_DL,PFILE_QUES,IS_MAIL_AD,PWD_SEC1_1," +
      "VOTR_REG_2,DIS_CNDTNS,C_COD_VIS,PCA_ADA_DC,CTDC_MATP,MATP_FORM,ADD_PHY_QU," +
      "PHY_HLPTXT,AGE_PROF1,DOCS_FLTR,MATP_DISC1";

    //@ts-ignore
    store.dispatch.uiFeatureModule.getUIFeatures(uiFeatureArray);
    this.byPassChkBox = this.applicantProgramInfo.byPassInd === "Y";
    //@ts-ignore
    this.byPassHeaderTxt = this.uiFeature.BY_PAS_HDR.displayName.replace(
      "{prgmName}",
      this.applicantProgramInfo.programName
    );
    //@ts-ignore
    this.byPassHeaderBdyTxt = this.uiFeature.BY_PAS_HDR.largeText.replace(
      "{prgmName}",
      this.applicantProgramInfo.programName
    );
    //@ts-ignore
    this.byPassChkBoxTxt = this.uiFeature.BY_PAS_CHK.displayName.replace(
      "{prgmName}",
      this.applicantProgramInfo.programName
    );
  },
  mounted() {
    this.$emit("changeStep", this.stepperIndex);
    //@ts-ignore
    if (!this.allTransitAgencyDataLoaded) {
      store.dispatch.allTransitAgencyDataModule
        .getAllTransitAgencyData()
        .then(() => {
          this.setSelectedTransitAgencyData();
        });
    } else {
      this.setSelectedTransitAgencyData();
    }
    this.$nextTick().then(() => {
      if (this.$refs.PageTitleH1) {
        (this.$refs.PageTitleH1 as HTMLDivElement).focus();
      }
    });
  },
  methods: {
    nextStep() {
      this.stepperIndex++;
      this.$emit("changeStep", this.stepperIndex);
    },
    stepCountAlign() {
      this.$emit("changeStep", this.stepperIndex);
    },
    closeEventHistoryDialog() {
      this.showDialog = false;
    },
    getEventHistoryDialog() {
      //@ts-ignore
      this.getEventHistory()
        //@ts-ignore
        .then(response => {
          if (response.status === HTTP_STATUS.OK) {
            this.eventLogs = response.data;
            this.showDialog = true;
          }
        })
        //@ts-ignore
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          //@ts-ignore
        });
    },
    openProviderCnctDialog(e: Event) {
      this.el = e.target as HTMLElement;
      this.providerLinkDialog = true;
    },

    setSelectedTransitAgencyData() {
      //@ts-ignore
      this.selectedTransitAgencyData = this.allTransitAgencyData[
        this.applicantProgramInfo.transitAgencyCode
      ];
    },
    isByPassPrgm(): boolean {
      let isByPassPrgm = false;
      //@ts-ignore
      let isByPassPrgmArray = this.uiFeature.BY_PAS_PRG.largeText.split(",");
      if (
        isByPassPrgmArray.includes(this.applicantProgramInfo.programTypeCode)
      ) {
        isByPassPrgm = true;
      }
      //@ts-ignore
      this.byPassHeaderTxt = this.uiFeature.BY_PAS_HDR.displayName.replace(
        "{prgmName}",
        this.applicantProgramInfo.programName
      );
      //@ts-ignore
      this.byPassHeaderBdyTxt = this.uiFeature.BY_PAS_HDR.largeText.replace(
        "{prgmName}",
        this.applicantProgramInfo.programName
      );
      //@ts-ignore
      this.byPassChkBoxTxt = this.uiFeature.BY_PAS_CHK.displayName.replace(
        "{prgmName}",
        this.applicantProgramInfo.programName
      );
      return isByPassPrgm;
    },
    saveByPass() {
      if (this.byPassChkBox) {
        this.applicantProgramInfo.byPassInd = "Y";
      } else {
        this.applicantProgramInfo.byPassInd = "N";
      }
      this.$emit("bypass-prgm", this.byPassChkBox);
    }
  },
  beforeDestroy() {
    this.applicantProfile.profileEditMsg = "";
    this.applicantProfile.profileSuccessMsg = "";
    //@ts-ignore
    store.commit.applicantProfileModule.SET_APPLICANT_PROFILE(
      this.applicantProfile
    );
  },
  watch: {
    providerLinkDialog: {
      immediate: true,
      handler: function(newValue) {
        if (newValue) {
          this.$nextTick(() => {
            //@ts-ignore
            this.setAriaAttribute(this.$refs.providerLinkDialog);
          });
        }
      }
    }
  }
});
