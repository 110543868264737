var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',[_c('v-card-title',[_vm._v("Basic information and contact information")]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',[_c('tes-control-template',{attrs:{"label":"First Name","required":""}},[_c('v-text-field',{attrs:{"outlined":"","maxlength":"30","rules":[_vm.rules.required, _vm.rules.nameValidation],"aria-label":_vm.getAriaLabel(
                  _vm.physicianInfo.physicianFirstName,
                  [_vm.rules.required, _vm.rules.nameValidation],
                  `Physician's First Name`,
                  `Physician's First Name has`
                )},on:{"keyup":function($event){return _vm.$emit('update:physicianInfo', _vm.physicianInfo)},"change":function($event){_vm.physicianInfo.physicianFirstName = _vm.physicianInfo.physicianFirstName.trim()}},model:{value:(_vm.physicianInfo.physicianFirstName),callback:function ($$v) {_vm.$set(_vm.physicianInfo, "physicianFirstName", $$v)},expression:"physicianInfo.physicianFirstName"}})],1)],1),_c('v-col',[_c('tes-control-template',{attrs:{"label":"Last Name","required":""}},[_c('v-text-field',{attrs:{"outlined":"","maxlength":"30","rules":[_vm.rules.required, _vm.rules.nameValidation],"aria-label":_vm.getAriaLabel(
                  _vm.physicianInfo.physicianLastName,
                  [_vm.rules.required, _vm.rules.nameValidation],
                  `Physician's Last Name`,
                  `Physician's Last Name has`
                )},on:{"keyup":function($event){return _vm.$emit('update:physicianInfo', _vm.physicianInfo)},"change":function($event){_vm.physicianInfo.physicianLastName = _vm.physicianInfo.physicianLastName.trim()}},model:{value:(_vm.physicianInfo.physicianLastName),callback:function ($$v) {_vm.$set(_vm.physicianInfo, "physicianLastName", $$v)},expression:"physicianInfo.physicianLastName"}})],1)],1)],1),_c('v-row',[_c('v-col',[_c('tes-control-template',{attrs:{"label":"Type of Professional","required":""}},[_c('v-text-field',{attrs:{"outlined":"","aria-label":_vm.getAriaLabel(
                  _vm.physicianInfo.professionalType,
                  [_vm.rules.required],
                  'Type of Professional'
                ),"maxlength":"30","rules":[_vm.rules.required]},on:{"keyup":function($event){return _vm.$emit('update:physicianInfo', _vm.physicianInfo)}},model:{value:(_vm.physicianInfo.professionalType),callback:function ($$v) {_vm.$set(_vm.physicianInfo, "professionalType", $$v)},expression:"physicianInfo.professionalType"}})],1)],1),_c('v-col',[_c('tes-control-template',{attrs:{"label":"Email Address"}},[_c('v-text-field',{attrs:{"outlined":"","aria-label":_vm.getAriaLabel(
                  _vm.physicianInfo.physicianEmailAddress,
                  [_vm.rules.email],
                  'Email Address'
                ),"maxlength":"30","rules":[_vm.rules.email]},on:{"keyup":function($event){return _vm.$emit('update:physicianInfo', _vm.physicianInfo)}},model:{value:(_vm.physicianInfo.physicianEmailAddress),callback:function ($$v) {_vm.$set(_vm.physicianInfo, "physicianEmailAddress", $$v)},expression:"physicianInfo.physicianEmailAddress"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('tes-control-template',{attrs:{"label":"Street Address","required":""}},[_c('v-text-field',{attrs:{"outlined":"","aria-label":_vm.getAriaLabel(
                  _vm.physicianInfo.addressLine1,
                  [_vm.rules.required, _vm.rules.streetNumberNameValidation],
                  `Street Address of the Physician's Office`,
                  `Street Address of the Physician's Office has`
                ),"maxlength":"50","rules":[_vm.rules.streetNumberNameValidation, _vm.rules.required]},on:{"keyup":function($event){return _vm.$emit('update:physicianInfo', _vm.physicianInfo)}},model:{value:(_vm.physicianInfo.addressLine1),callback:function ($$v) {_vm.$set(_vm.physicianInfo, "addressLine1", $$v)},expression:"physicianInfo.addressLine1"}})],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('tes-control-template',{attrs:{"label":"Apt./Suite"}},[_c('v-text-field',{attrs:{"outlined":"","aria-label":"Appartment or Suite Number of the Physician's Office","maxlength":"10"},on:{"keyup":function($event){return _vm.$emit('update:physicianInfo', _vm.physicianInfo)}},model:{value:(_vm.physicianInfo.addressLine2),callback:function ($$v) {_vm.$set(_vm.physicianInfo, "addressLine2", $$v)},expression:"physicianInfo.addressLine2"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('tes-control-template',{attrs:{"label":"City","required":""}},[_c('v-text-field',{attrs:{"outlined":"","maxlength":"30","rules":[_vm.rules.nameValidation, _vm.rules.required],"aria-label":_vm.getAriaLabel(
                  _vm.physicianInfo.city,
                  [_vm.rules.required, _vm.rules.nameValidation],
                  'City',
                  'City has'
                )},on:{"keyup":function($event){return _vm.$emit('update:physicianInfo', _vm.physicianInfo)},"change":function($event){_vm.physicianInfo.city = _vm.physicianInfo.city.trim()}},model:{value:(_vm.physicianInfo.city),callback:function ($$v) {_vm.$set(_vm.physicianInfo, "city", $$v)},expression:"physicianInfo.city"}})],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('tes-control-template',{attrs:{"label":"State","required":""}},[_c('v-select',{attrs:{"outlined":"","aria-label":"State where Physician's office is located","items":_vm.statesList,"item-text":"displayName","item-value":"resourceCode","role":"listbox"},on:{"keyup":function($event){return _vm.$emit('update:physicianInfo', _vm.physicianInfo)}},model:{value:(_vm.physicianInfo.state),callback:function ($$v) {_vm.$set(_vm.physicianInfo, "state", $$v)},expression:"physicianInfo.state"}})],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('tes-control-template',{attrs:{"label":"Zip","required":""}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.masks.zipMask),expression:"masks.zipMask"}],attrs:{"outlined":"","aria-label":_vm.getAriaLabel(
                  _vm.zipCode,
                  [_vm.rules.required, _vm.rules.ZipCodeUS],
                  `Zip Code of Physician's office`,
                  `Zip Code format of Physician's office should be like`
                ),"maxlength":"10","rules":[_vm.rules.ZipCodeUS, _vm.rules.required]},on:{"keyup":function($event){return _vm.$emit('update:physicianInfo', _vm.physicianInfo)}},model:{value:(_vm.zipCode),callback:function ($$v) {_vm.zipCode=$$v},expression:"zipCode"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('tes-control-template',{attrs:{"label":"Office Phone","required":""}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.masks.phoneMask),expression:"masks.phoneMask"}],attrs:{"outlined":"","aria-label":_vm.getAriaLabel(
                  _vm.phoneNum,
                  [_vm.rules.required],
                  `Physician's Office Phone number`
                ),"maxlength":"14","rules":[_vm.rules.required]},on:{"keyup":function($event){return _vm.$emit('update:physicianInfo', _vm.physicianInfo)}},model:{value:(_vm.phoneNum),callback:function ($$v) {_vm.phoneNum=$$v},expression:"phoneNum"}})],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('tes-control-template',{attrs:{"label":"Ext"}},[_c('v-text-field',{attrs:{"outlined":"","aria-label":"Physician's office extension","maxlength":"5","rules":[_vm.rules.numberValidation]},on:{"keyup":function($event){return _vm.$emit('update:physicianInfo', _vm.physicianInfo)}},model:{value:(_vm.physicianInfo.physicianPhoneExt),callback:function ($$v) {_vm.$set(_vm.physicianInfo, "physicianPhoneExt", $$v)},expression:"physicianInfo.physicianPhoneExt"}})],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('tes-control-template',{attrs:{"label":"Office Fax"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.masks.phoneMask),expression:"masks.phoneMask"}],attrs:{"outlined":"","aria-label":"Physician's office fax number","maxlength":"14","rules":[_vm.rules.phoneNumber]},on:{"keyup":function($event){return _vm.$emit('update:physicianInfo', _vm.physicianInfo)}},model:{value:(_vm.faxNum),callback:function ($$v) {_vm.faxNum=$$v},expression:"faxNum"}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }