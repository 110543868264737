import "babel-polyfill";
import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import vuetify from "@/plugins/vuetify";
import VueTheMask from "vue-the-mask";
import VueMoment from "vue-moment";
import BrowserDetect from "vue-browser-detect-plugin";
import money from "v-money";
// https://matteo-gabriele.gitbook.io/vue-gtag/
import VueGtag from "vue-gtag";
import "url-search-params-polyfill";
import IdleVue from "idle-vue";
import axios from "axios";

Vue.config.productionTip = false;

axios.defaults.headers.common["AUTH_API_KEY"] =
  "92e2d711-5be8-5a9m-q52s-ppdf8v432234";

Vue.use(VueTheMask);
Vue.use(VueMoment);
Vue.use(BrowserDetect);
Vue.use(money);
Vue.use(VueGtag, { config: { id: "UA-171261286-1" } }, router);

Vue.use(IdleVue, {
  idleTime: 25 * 60 * 1000,
  startAtIdle: false,
  store: store.original
});

new Vue({
  router,
  store: store.original,
  vuetify,
  render: h => h(App)
}).$mount("#app");
