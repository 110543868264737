
import axios from "axios";
import Vue from "vue";
import { TES_APP_ENDPOINT } from "@/constants/EndPoints";
import store from "@/store";
import ProgressBar from "@/components/common/ProgressBar.vue";
import InfoSection from "@/components/common/InfoSection.vue";
import routerMixin from "@/mixins/RouterMixin.vue";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";
import { APPL_STATUS } from "@/constants/CommonConstants";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";

import {
  IProgramsList,
  ITESUser,
  IApplicationReviewModel,
  IISAMUserAttributes
} from "@/types";
export default Vue.extend({
  name: "start-review",
  mixins: [routerMixin, ApplicationMixin, UIFeatureMixinVue, AppPropsMixin],
  components: {
    ProgressBar,
    InfoSection
  },
  data() {
    return {
      showProgressBar: false,
      reviewModel: {} as IApplicationReviewModel,
      iisamUserAttributes: {} as IISAMUserAttributes
    };
  },
  created() {
    let uiFeatureArray = "RVW_LCKOUT";
    store.commit.uiFeatureModule.SET_DATA_LDNG_STS(true);
    store.dispatch.uiFeatureModule.getUIFeatures(uiFeatureArray);
    this.getReviewModel();
    window.addEventListener("beforeunload", this.endReview);
  },
  beforeDestroy() {
    this.endReview();
  },
  computed: {
    userInfo: {
      get(): ITESUser {
        //@ts-ignore
        return store.getters.tesUserModule.getTesUser;
      }
    },
    applicantProgramInfo: {
      get(): IProgramsList {
        //@ts-ignore
        return store.getters.applicantProgramModule.getApplicantProgram;
      }
    },
    applicationReviewModel: {
      get(): IApplicationReviewModel {
        //@ts-ignore
        return store.getters.applicationReviewModule.getApplicationReviewModel;
      }
    },
    showReviewCard(): boolean {
      return (
        //@ts-ignore
        this.isAgencyUser &&
        (!this.applicationReviewModel ||
          (this.applicationReviewModel.reviewerUserId ===
            this.userInfo.userId &&
            this.applicantProgramInfo.statusCode === APPL_STATUS.SUBMITTED) ||
          this.applicationReviewModel.reviewerUserId !== this.userInfo.userId ||
          this.applicantProgramInfo.statusCode === APPL_STATUS.TMP_APRVD ||
          this.applicantProgramInfo.statusCode === APPL_STATUS.PENDING)
      );
    },
    numAttachmentsAssistanceReq: () =>
      store.getters.attachmentsModule.getDocsSubmittedAssistanceRequiredCount
  },
  methods: {
    startReview() {
      this.reviewModel.reviewerUserId = this.userInfo.userId;
      this.reviewModel.tesApplicationId = this.applicantProgramInfo.applicationId;
      this.reviewModel.cycleNumber = this.applicantProgramInfo.cycleNumber;
      if (this.applicantProgramInfo.statusCode === APPL_STATUS.SUBMITTED) {
        this.reviewModel.applicationReviewId = this.applicationReviewModel.applicationReviewId;
      }
      let startReviewUrl = TES_APP_ENDPOINT.START_REVIEW;
      this.showProgressBar = true;
      axios
        .post(startReviewUrl, this.reviewModel)
        .then(response => {
          //@ts-ignore
          store.commit.applicationReviewModule.SET_APPLICATION_REVIEW_MODEL(
            response.data
          );
          this.applicantProgramInfo.statusCode = APPL_STATUS.UNDER_REVIEW;
          // route to AgencyReview screen
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          store.dispatch.applicationReviewModule.loadApplicationPreviousReviewModel(
            { tesApplicationId: this.applicantProgramInfo.applicationId }
          );
          this.showProgressBar = false;
        });
    },
    getReviewModel() {
      if (this.applicationReviewModel.reviewerUserId) {
        this.getReviewUserAttributes();
      }
      if (this.applicationReviewModel.reviewerUserId === this.userInfo.userId) {
        this.changeReviewFlag("Y");
      }
    },
    endReview() {
      this.changeReviewFlag("N");
    },
    getReviewUserAttributes() {
      let url = TES_APP_ENDPOINT.ISAM_GET_USERATTRIBUTES_BYID;
      let userId = this.applicationReviewModel.reviewerUserId;
      let name = "";
      let value = "";
      axios
        .get(url, {
          params: {
            userId: userId
          }
        })
        .then(response => {
          this.iisamUserAttributes = response.data;
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        });
    },
    continueReview() {
      this.applicationReviewModel.reviewerUserId = this.userInfo.userId;
      this.applicationReviewModel.actReviewFlag = "Y";
      let updateReviewUrl = TES_APP_ENDPOINT.UPDATE_REVIEW;
      this.showProgressBar = true;
      axios
        .post(updateReviewUrl, this.applicationReviewModel)
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    },
    changeReviewFlag(flag: string) {
      if (
        this.applicationReviewModel &&
        (flag === "Y" ||
          this.applicationReviewModel.reviewerUserId === this.userInfo.userId ||
          this.userInfo.userId === 0)
      ) {
        if (this.userInfo.userId !== 0) {
          this.applicationReviewModel.reviewerUserId = this.userInfo.userId;
        }
        this.applicationReviewModel.actReviewFlag = flag;
        let updateReviewFlagUrl = TES_APP_ENDPOINT.UPDATE_REVIEW_FLAG;
        this.showProgressBar = true;
        axios
          .post(updateReviewFlagUrl, this.applicationReviewModel)
          .catch(error => {
            store.dispatch.alertModule.showAlertMessageById(
              error.response.data.id
            );
          })
          .finally(() => {
            this.showProgressBar = false;
          });
      }
    },
    GotoDashboard() {
      //@ts-ignore
      this.forwardToDashboard();
    },
    reviewUpdatedRecently(lastUpdatedTs: string): boolean {
      let lockoutPeriod = new Date();
      lockoutPeriod.setMinutes(
        //@ts-ignore
        lockoutPeriod.getMinutes() - this.uiFeature.RVW_LCKOUT.displayName
      );
      return new Date(lastUpdatedTs) > lockoutPeriod;
    }
  }
});
