
import Vue from "vue";
import TesAddressTemplate from "@/components/common/TesAddressTemplate.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import AgencyReviewMixinVue from "@/mixins/AgencyReviewMixin.vue";
import { clone } from "@/util/clone";
import { ecolaneDataUpdateDefault } from "@/data/AppData";
import {
  IApplicantProfile,
  IStgEcolaneCustProfileModel,
  IEcolaneDataUpdate,
  IApplicantAddress
} from "@/types";
import store from "@/store";

export default Vue.extend({
  name: "EcolaneDiff",
  props: {
    updateColumns: { type: Boolean, default: false },
    summaryColumns: { type: Boolean, default: false }
  },
  mixins: [UIFeatureMixinVue, AgencyReviewMixinVue],
  components: { TesAddressTemplate },
  data() {
    return {
      firstNameUpd: false,
      middleInitUpd: false,
      emerContNameUpd: false,
      emerContPhNoUpd: false,
      ethnicityUpd: false,
      languageUpd: false,
      genderUpd: false,
      socialSecurityNumUpd: false,
      medicaidNumUpd: false,
      countyUpd: false,
      emaiAddrUpd: false,
      primaryPhUpd: false,
      secondaryPhUpd: false,
      disMentalUpd: false,
      disPhysicalUpd: false,
      disVisualUpd: false,
      homeAddrUpd: false,
      mailAddrUpd: false,
      ecolaneDataUpdateDefault: clone(ecolaneDataUpdateDefault)
    };
  },
  created() {
    this.loadCheckBoxOptions();
  },
  computed: {
    applicantProfileInfo(): IApplicantProfile {
      return store.getters.applicantProfileModule.getApplicantProfile;
    },
    stgEcolaneCustProfileModel(): IStgEcolaneCustProfileModel {
      return store.getters.applicantEcolaneProfileModule
        .getStgEcolaneCustProfile;
    },
    emergencyContact(): string {
      return (
        this.applicantProfileInfo.emergencyFirstName +
        " " +
        this.applicantProfileInfo.emergencyLastName +
        ", " +
        this.applicantProfileInfo.emergencyRelationship
      );
    },
    emergencyContactNameDiff(): boolean {
      let temp = false;
      let tempContact;
      if (
        this.applicantProfileInfo.emergencyFirstName &&
        this.applicantProfileInfo.emergencyLastName &&
        this.applicantProfileInfo.emergencyRelationship
      ) {
        tempContact =
          this.applicantProfileInfo.emergencyFirstName +
          " " +
          this.applicantProfileInfo.emergencyLastName +
          ", " +
          this.applicantProfileInfo.emergencyRelationship;
      }
      if (this.stgEcolaneCustProfileModel.emergencyContactName != tempContact) {
        temp = true;
      }
      return temp;
    },
    mailingAddress(): IApplicantAddress {
      if (this.applicantProfileInfo.sameMailingInd !== "Y") {
        return this.applicantProfileInfo.mailingAddress;
      } else {
        return this.applicantProfileInfo.homeAddress;
      }
    }
  },
  methods: {
    getCheckBoxValue(abc: string): boolean {
      //@ts-ignore
      return this.getEcolaneDataUpdateList.find(
        (o: { ecolaneDataType: string; ecolaneUpdateInd: string }) =>
          o.ecolaneDataType === abc && o.ecolaneUpdateInd === "Y"
      )
        ? true
        : false;
    },
    loadCheckBoxOptions() {
      this.firstNameUpd = this.getCheckBoxValue("FIRST_NAME");
      this.middleInitUpd = this.getCheckBoxValue("MIDDLE_INIT");
      this.emerContNameUpd = this.getCheckBoxValue("EMERG_CONT_NAME");
      this.emerContPhNoUpd = this.getCheckBoxValue("EMERG_CONT_PHNE");
      this.ethnicityUpd = this.getCheckBoxValue("ETHNICITY");
      this.languageUpd = this.getCheckBoxValue("LANGUAGE");
      this.genderUpd = this.getCheckBoxValue("GENDER");
      this.socialSecurityNumUpd = this.getCheckBoxValue(
        "SOCIAL_SECURITY_NUMBER"
      );
      this.medicaidNumUpd = this.getCheckBoxValue("MEDICAID_NUMBER");
      this.countyUpd = this.getCheckBoxValue("COUNTY");
      this.emaiAddrUpd = this.getCheckBoxValue("EMAIL_ADDR");
      this.primaryPhUpd = this.getCheckBoxValue("PRIM_PHNE");
      this.secondaryPhUpd = this.getCheckBoxValue("SEC_PHNE");
      this.disMentalUpd = this.getCheckBoxValue("DIS_MENTAL");
      this.disPhysicalUpd = this.getCheckBoxValue("DIS_PHYSCL");
      this.disVisualUpd = this.getCheckBoxValue("DIS_VISUAL");
      this.homeAddrUpd = this.getCheckBoxValue("HOME_ADDRL1");
      this.mailAddrUpd = this.getCheckBoxValue("MAIL_ADDRL1");
    }
  },
  watch: {
    getEcolaneDataUpdateList: {
      deep: true,
      handler(newArr: IEcolaneDataUpdate[]) {
        if (newArr.length > 0) {
          this.loadCheckBoxOptions();
        }
      }
    }
  }
});
