var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-form',{ref:"ftpProgramInfoTemplate",attrs:{"lazy-validation":"","autocomplete":"off"}},[_c('tes-container',{attrs:{"questionHint":_vm.ftpInfo,"showDefaultButtons":_vm.showDefaultButtons,"isFinalStep":_vm.finalStep,"isSingleFlowFinalStep":_vm.finalStep,"showRequiredLegend":""},on:{"save-clicked":function($event){return _vm.save('SAVE')},"save-continue-clicked":function($event){return _vm.saveContinue()},"continue-clicked":function($event){return _vm.save('CONTINUE')},"cancel-clicked":function($event){return _vm.cancel('CANCEL')}}},[(_vm.applicantAge >= 62 && _vm.applicantAge < 65)?_c('v-row',{staticClass:"py-3 no-gutters"},[_c('v-col',{attrs:{"cols":"12"}},[_c('InfoSection',{attrs:{"header":_vm.infoMessageHeaderAge,"body":"Please note that you must be 65 or older to qualify for the Free\n            Transit Program. You may continue and complete this application to\n            pre-qualify for this service which will be available when you turn\n            65."}})],1)],1):_vm._e(),_c('v-row',{staticClass:"pl-md-2 py-3 no-gutters"},[_c('v-col',[_c('TESRadioControl',{attrs:{"questionText":_vm.ftpApplyIndQues,"requiredQuestion":"","questionClass":"no-gutters","rules":[_vm.rules.required],"radioGroupId":"radio_ftpApplicationInd","disabled":_vm.isFTPReadOnly},on:{"input":function($event){return _vm.changeValues()}},model:{value:(_vm.draftProgramInfo.ftpApplicationInd),callback:function ($$v) {_vm.$set(_vm.draftProgramInfo, "ftpApplicationInd", $$v)},expression:"draftProgramInfo.ftpApplicationInd"}})],1)],1),(
        _vm.agencyProviders.length > 1 &&
          _vm.draftProgramInfo.ftpApplicationInd === 'Y'
      )?_c('v-row',{staticClass:"py-3 no-gutters"},[_c('v-col',{attrs:{"cols":"12"}},[_c('tes-control-template',{attrs:{"required":"","label":_vm.rftpProviderQues}},[_c('v-select',{attrs:{"items":_vm.agencyProviders,"label":"- Select -","item-text":"agencyAndArea","item-value":"transitAgencyCode","outlined":"","large":"","fab":"","dense":"","required":"","rules":[_vm.rules.required],"disabled":_vm.isFTPReadOnly},on:{"change":_vm.selectedAgencyProvider},model:{value:(_vm.draftProgramInfo.ftpAgencyCode),callback:function ($$v) {_vm.$set(_vm.draftProgramInfo, "ftpAgencyCode", $$v)},expression:"draftProgramInfo.ftpAgencyCode"}})],1)],1)],1):_vm._e(),(
        _vm.showSeptaQues &&
          _vm.draftProgramInfo.ftpApplicationInd === 'Y' &&
          _vm.draftProgramInfo.ftpAgencyCode != 'PAAC'
      )?_c('v-row',{staticClass:"pl-md-2 py-3 no-gutters"},[_c('v-col',{attrs:{"cols":"12"}},[_c('TESRadioControl',{attrs:{"questionText":_vm.septaFixedRouteQues,"questionClass":"no-gutters","requiredQuestion":"","rules":[_vm.rules.required],"radioGroupId":"radio_septaInd","disabled":_vm.isFTPReadOnly},model:{value:(_vm.draftProgramInfo.transitIdCardApplyInd),callback:function ($$v) {_vm.$set(_vm.draftProgramInfo, "transitIdCardApplyInd", $$v)},expression:"draftProgramInfo.transitIdCardApplyInd"}})],1)],1):_vm._e(),(_vm.showDLUploadMessage)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pt-2 pb-2 pr-2 pl-md-2",attrs:{"flat":""}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.uiFeature.SSR_DE_ALG.largeText)}})])],1)],1)],1):_vm._e(),(_vm.draftProgramInfo.ftpApplicationInd === 'N')?_c('v-row',{staticClass:"pl-2 py-3 no-gutters"},[_c('v-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('InfoSection',{attrs:{"header":"You do not qualify for this program","body":_vm.ftpDoNotQualifyText}})],1)],1):_vm._e()],1),_c('ProgressBar',{attrs:{"dialog":_vm.showProgressBar}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }