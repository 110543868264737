
import Vue from "vue";
import axios from "axios";
import store from "@/store";
import ProgressBar from "@/components/common/ProgressBar.vue";
import { TES_APP_ENDPOINT } from "@/constants/EndPoints";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import {
  IApplicationNavigation,
  IDraftProgramInfo,
  IProgramsList,
  IApplicantProfile
} from "@/types";
import TESRadioControl from "@/components/common/TESRadioControl.vue";
import tesContainer from "@/components/common/TESContainer.vue";
import draftProgramMixin from "@/mixins/DraftProgramMixin.vue";
import StepperNavigationMixinVue from "@/mixins/StepperNavigationMixin.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import ScrollMixin from "@/mixins/ScrollMixin.vue";
import { CNST_TEXT } from "@/constants/CommonConstants";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";
import { GPC } from "@/constants/GPCCode";

export default Vue.extend({
  name: "matp-transportation",
  components: { tesContainer, TESRadioControl, ProgressBar },
  mixins: [
    fieldEntryMixin,
    draftProgramMixin,
    StepperNavigationMixinVue,
    UIFeatureMixinVue,
    AppPropsMixin,
    ScrollMixin,
    ApplicationMixin
  ],
  data() {
    return {
      showProgressBar: false,
      singleFlowFinalStep: false,
      steps: 3
    };
  },
  props: {
    stepCount: { type: Number, default: 0 }
  },
  computed: {
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      },
      set(newValue: IDraftProgramInfo) {
        store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(newValue);
      }
    },
    applicantProgramInfo(): IProgramsList {
      return store.getters.applicantProgramModule.getApplicantProgram;
    },
    applicationNavigation(): IApplicationNavigation {
      return store.getters.applicationNavigationModule.getApplicationNavigation;
    },
    eligibleAndhaveMedicaidCardOrSSN(): boolean {
      return this.draftProgramInfo.medAssistanceEligibilityInd === "Y";
    },
    showMassTransitInability(): boolean {
      return (
        this.draftProgramInfo.workingVehicleInd === "N" &&
        this.draftProgramInfo.liveNearBusInd === "Y"
      );
    },
    showQuestion(): boolean {
      return (
        this.draftProgramInfo.workingVehicleInd === "N" &&
        this.draftProgramInfo.liveNearBusInd !== ""
      );
    },
    applicantProfile: {
      get(): IApplicantProfile {
        return store.getters.applicantProfileModule.getApplicantProfile;
      }
    },
    transInfo(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.MATP_SEC2].largeText,
          CNST_TEXT.THE_APPLICANT,
          CNST_TEXT.THEIR
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.MATP_SEC2].largeText,
          CNST_TEXT.YOU,
          CNST_TEXT.YOUR
        );
      }
      return text;
    }
  },
  created() {
    if (
      //@ts-ignore
      this.isSingleFlow()
    ) {
      this.singleFlowFinalStep = true;
    }
  },
  methods: {
    save() {
      //@ts-ignore
      if (this.$refs.formTransportation.validate()) {
        this.saveDraftProgramInfo("SAVE");
      } else {
        //@ts-ignore
        this.scrollToErrorField(this.$refs.formTransportation);
      }
    },
    saveContinue() {
      //@ts-ignore
      if (this.$refs.formTransportation.validate()) {
        this.saveDraftProgramInfo("SAVECONTINUE");
      } else {
        //@ts-ignore
        this.scrollToErrorField(this.$refs.formTransportation);
      }
    },
    cancel() {
      //@ts-ignore
      if (this.$refs.formTransportation.validate()) {
        this.saveDraftProgramInfo("CANCEL");
      } else {
        //@ts-ignore
        this.scrollToErrorField(this.$refs.formTransportation);
      }
    },
    saveDraftProgramInfo(buttonName: string) {
      this.showProgressBar = true;
      this.draftProgramInfo.programInfo = this.applicantProgramInfo;

      if (
        //@ts-ignore
        this.isApplicantCorrectionsStatus()
      ) {
        if (
          //@ts-ignore
          !this.isDocsRequired()
        ) {
          //@ts-ignore
          this.draftProgramInfo.programStatus = this.uiFeature.RDY_SBMT_C.displayName;
        } else {
          //@ts-ignore
          this.draftProgramInfo.programStatus = this.uiFeature.IN_PRGS_C.displayName;
        }
      } else if (this.stepCount > 3) {
        //@ts-ignore
        this.draftProgramInfo.programStatus = this.uiFeature.IN_PRGS.displayName;
      } else {
        //@ts-ignore
        this.draftProgramInfo.programStatus = this.uiFeature.RDY_SBMT.displayName;
      }
      //@ts-ignore
      this.draftProgramInfo.userInfo = this.userInfo;

      axios
        .post(TES_APP_ENDPOINT.SAVE_DRAFT_PRGM_INFO, this.draftProgramInfo)
        .then(response => {
          //@ts-ignore
          this.draftProgramInfo = response.data;

          store.commit.applicantProgramModule.SET_APPLICANT_PROGRAM(
            this.draftProgramInfo.programInfo
          );
          this.applicationNavigation.applProfileEdit = true;

          store.commit.applicationNavigationModule.SET_APPLLICATION_NAVIGATION(
            this.applicationNavigation
          );
          this.$emit("save-completed", buttonName);
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    },
    resetAllValues() {
      this.draftProgramInfo.transportAvailabilityInd = "";
      this.draftProgramInfo.liveNearBusInd = "";
      this.draftProgramInfo.massTransitInabilityInd = "";
      this.draftProgramInfo.transportationDisabilityInd = "";
      this.draftProgramInfo.rideAloneInd = "";
    },
    resetMassTransit() {
      this.draftProgramInfo.massTransitInabilityInd = "";
    },
    changeValues() {
      this.populateSteps();
      this.$emit("step-count", this.steps);
    },
    populateSteps() {
      if (
        //@ts-ignore
        !this.isSingleFlow()
      ) {
        if (
          (this.applicantProfile.travelAttendantInd === "Y" &&
            (this.applicantProgramInfo.transitAgencyCode === "CPTA" ||
              this.applicantProgramInfo.transitAgencyCode === "CAT")) ||
          (this.applicantProgramInfo.transitAgencyCode === "FREEDOM" &&
            (this.draftProgramInfo.massTransitInabilityInd === "Y" ||
              this.draftProgramInfo.transportationDisabilityInd === "Y" ||
              this.draftProgramInfo.rideAloneInd === "Y")) ||
          (this.applicantProgramInfo.transitAgencyCode === "CTDC" &&
            this.draftProgramInfo.medAssistanceEligibilityInd === "Y")
        ) {
          this.steps = 4;
        } else {
          this.steps = 3;
        }
      }
    }
  }
});
