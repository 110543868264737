
import Vue from "vue";
import EcolaneLink from "@/components/application/reviewagencyinformation/EcolaneLink.vue";
import { expansionPanelIconObj } from "@/constants/CommonConstants";
export default Vue.extend({
  name: "configEcolane",
  components: { EcolaneLink },
  data: function() {
    return {
      seen: true as boolean,
      hover: false as boolean,
      showhideText: "More Details" as string,
      showHideIcon: "fa-expand-alt" as string
    };
  },
  methods: {
    showHide() {
      if (!this.seen) {
        this.seen = true;
        this.showhideText = expansionPanelIconObj.hideText;
        this.showHideIcon = "fa-compress-alt";
      } else {
        this.seen = false;
        this.showhideText = expansionPanelIconObj.showText;
        this.showHideIcon = "fa-expand-alt";
      }
    }
  }
});
