
import Vue from "vue";
import screeningquestions from "@/components/questions/ScreeningQuestions.vue";
import ProgressBar from "@/components/common/ProgressBar.vue";
import store from "@/store";

export default Vue.extend({
  name: "screening-questions",
  components: {
    screeningquestions,
    ProgressBar
  },
  created() {
    let uiFeatureArray =
      "CNTY_MAP,NO_COUNTY2,NO_THIS,ER_INLINE,CHGLGN_TXT,FMR,ENVRONMNT,FLTR_MATP,UNDER_18,ABOVE_65,ABOVE_17,STATE,MORE_INFO,MATP_SCR_Q,DISB_SCR_Q,GIS_CNFM_1,GIS_CNFM_2";
    //@ts-ignore
    store.commit.uiFeatureModule.SET_DATA_LDNG_STS(true);
    //@ts-ignore
    store.dispatch.uiFeatureModule.getUIFeatures(uiFeatureArray);
  },
  mounted() {
    //@ts-ignore
    this.$vuetify.goTo(0, {
      duration: 500,
      offset: 0,
      easing: "easeInOutCubic"
    });
  },
  computed: {
    isDataLoading: {
      get(): boolean {
        //@ts-ignore
        return store.getters.uiFeatureModule.getLoadingStatus;
      }
    }
  }
});
