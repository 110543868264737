export const GPC = {
  DIS_TYPES: "DIS_TYPES",
  DIS_CNDTNS: "DIS_CNDTNS",
  SPL_NEEDS: "SPL_NEEDS",
  INCM_TYPES: "INCM_TYPES",
  RADIO_YN: "RADIO_YN",
  ENG: "ENG",
  ARA: "ARA",
  ZHO: "ZHO",
  FRE: "FRE",
  DEU: "DEU",
  GUJ: "GUJ",
  HAT: "HAT",
  ITA: "ITA",
  KOR: "KOR",
  MKH: "MKH",
  NEP: "NEP",
  POL: "POL",
  POR: "POR",
  RUS: "RUS",
  SPA: "SPA",
  VIE: "VIE",
  Y: "Y",
  N: "N",
  M: "M",
  F: "F",
  NOT_START: "NOT_START",
  IN_PRGS: "IN_PRGS",
  RDY_SBMT: "RDY_SBMT",
  NOT_QLFYD: "NOT_QLFYD",
  NOT_APPL: "NOT_APPL",
  SBMTD: "SBMTD",
  APPROVED: "APPROVED",
  PERM: "PERM",
  TEMP: "TEMP",
  STABLE: "STABLE",
  PROGRESSIV: "PROGRESSIV",
  DISABL_1: "DISABL_1",
  DISABL_2: "DISABL_2",
  DISABL_3: "DISABL_3",
  DISABL_4: "DISABL_4",
  DISABL_5: "DISABL_5",
  DISABL_6: "DISABL_6",
  DISABL_7: "DISABL_7",
  DISABL_OTH: "DISABL_OTH",
  INCM_1: "INCM_1",
  INCM_2: "INCM_2",
  INCM_3: "INCM_3",
  INCM_4: "INCM_4",
  INCM_5: "INCM_5",
  INCM_6: "INCM_6",
  INCM_7: "INCM_7",
  INCM_8: "INCM_8",
  AGE_PROOF: "AGE_PROOF",
  AGE_VRFY_D: "AGE_VRFY_D",
  PRF_DSBLTY: "PRF_DSBLTY",
  PRF_DSBY_2: "PRF_DSBY_2",
  PRF_DSBY_3: "PRF_DSBY_3",
  MATP_FORM: "MATP_FORM",
  CERT_DBLTY: "CERT_DBLTY",
  MAN_WHLCHR: "MAN_WHLCHR",
  MOT_WHLCHR: "MOT_WHLCHR",
  MOTO_SCO: "MOTO_SCO",
  OXYGN: "OXYGN",
  WALKER: "WALKER",
  CANE: "CANE",
  CAN_CRUC: "CAN_CRUC",
  SRVC_ANML: "SRVC_ANML",
  MOB_OTHER: "MOB_OTHER",
  CH_BS: "CH_BS",
  CH_CS: "CH_CS",
  POW_WHLCHR: "POW_WHLCHR",
  PA: "PA",
  DL_INVALID: "DL_INVALID",
  DL_VALID: "DL_VALID",
  APPL_CRT: "APPL_CRT",
  APPL_RSBT: "APPL_RSBT",
  APPL_SBTD: "APPL_SBTD",
  APPL_DQFD: "APPL_DQFD",
  APPL_SBMT: "APPL_SBMT",
  STR_APPL: "STR_APPL",
  ASGN_APP: "ASGN_APP",
  APP_ASGN: "APP_ASGN",
  FNLZ: "FNLZ",
  SAVE: "SAVE",
  MEDICA_INS: "MEDICA_INS",
  DL_INSTRUC: "DL_INSTRUC",
  DL_TMT_TOP: "DL_TMT_TOP",
  DL_TMT_BTM: "DL_TMT_BTM",
  MATP_SEC1: "MATP_SEC1",
  MATP_AUTH: "MATP_AUTH",
  MATP_SC1_2: "MATP_SC1_2",
  MATP_SEC2: "MATP_SEC2",
  PWD_SEC1_1: "PWD_SEC1_1",
  PWD_SEC1: "PWD_SEC1",
  PWD_SEC2: "PWD_SEC2",
  ADA_SEC1: "ADA_SEC1",
  ADA_SEC2: "ADA_SEC2",
  SSR_SEC1: "SSR_SEC1",
  SSR_SEC2: "SSR_SEC2",
  FTP_SEC1: "FTP_SEC1",
  FTP_SEC2: "FTP_SEC2",
  FTP_INFO: "FTP_INFO",
  FTP_SEPT_Q: "FTP_SEPT_Q",
  AGE_VRFYST: "AGE_VRFYST",
  VOTER_REG: "VOTER_REG",
  VOTR_REG_2: "VOTR_REG_2",
  VOTE_YES: "VOTE_YES",
  VOTE_NO: "VOTE_NO",
  VOTE_ALRDY: "VOTE_ALRDY",
  RE_COR: "RE_COR",
  IN_PRGS_C: "IN_PRGS_C",
  RDY_SBMT_C: "RDY_SBMT_C",
  WITHDRAWN: "WITHDRAWN",
  PEND_CONF: "PEND_CONF",
  TMP_APRVD: "TMP_APRVD",
  NT_QLFY: "NT_QLFY",
  DSB_ACT: "DSB_ACT",
  ADA_COMP: "ADA_COMP",
  FXD_RT: "FXD_RT",
  DOC_PROOFS: "DOC_PROOFS",
  PRF_PRCARE: "PRF_PRCARE",
  RVW_RCMNDS: "RVW_RCMNDS",
  ELIGIB: "ELIGIB",
  IN_ELIGB: "IN_ELIGB",
  RETFORCORR: "RETFORCORR",
  DOCUPL_ERR: "DOCUPL_ERR",
  ECL_CFG_RD: "ECL_CFG_RD",
  CMP_RV_SLF: "CMP_RV_SLF",
  CMP_RV_OTH: "CMP_RV_OTH",
  NO_ECLN_AG: "NO_ECLN_AG",
  NO_ECLN_PG: "NO_ECLN_PG",
  POD_AGNCYS: "POD_AGNCYS",
  UN_RVW: "UN_RVW",
  APRW_STRT: "APRW_STRT",
  STRT_RW: "STRT_RW",
  SAVE_CNTN: "SAVE_CNTN",
  APP_NTAPCL: "APP_NTAPCL",
  CMPLT_RVW: "CMPLT_RVW",
  RVW_CMPLT: "RVW_CMPLT",
  EDT_APP: "EDT_APP",
  APP_EDTD: "APP_EDTD",
  WTHDRW: "WTHDRW",
  APP_WTDRWN: "APP_WTDRWN",
  CNT_RVW: "CNT_RVW",
  TMP_APRV: "TMP_APRV",
  RWR_CHNGD: "RWR_CHNGD",
  APPRV: "APPRV",
  CNFG_CMPLT: "CNFG_CMPLT",
  APP_RSBMTD: "APP_RSBMTD",
  CARE_ATTND: "CARE_ATTND",
  APPROVE: "APPROVE",
  INPRN_ASMT: "INPRN_ASMT",
  OTHER_DOC: "OTHER_DOC",
  COD_DOCTXT: "COD_DOCTXT",
  PCA_DOCTXT: "PCA_DOCTXT",
  FRDMCODTXT: "FRDMCODTXT",
  CTDC_COD: "CTDC_COD",
  CTDC_MATP: "CTDC_MATP",
  PARTCODTXT: "PARTCODTXT",
  AMTRAN_COD: "AMTRAN_COD",
  C_COD_AGNY: "C_COD_AGNY",
  VLD_DOCEXT: "VLD_DOCEXT",
  INELIGIBLE: "INELIGIBLE",
  CHGLGN_TXT: "CHGLGN_TXT",
  FILE_EXT: "FILE_EXT",
  INVALD_DOC: "INVALD_DOC",
  DOCS_ADA: "DOCS_ADA",
  DOCS_PWD: "DOCS_PWD",
  DOCS_FTP: "DOCS_FTP",
  DOCS_SSR: "DOCS_SSR",
  DOCS_MATP: "DOCS_MATP",
  QUICK_SRCH: "QUICK_SRCH",
  ER_INLINE: "ER_INLINE",
  WHITE: "WHITE",
  BLACK: "BLACK",
  ASIAN: "ASIAN",
  HISPANIC: "HISPANIC",
  NATV_ALSKN: "NATV_ALSKN",
  PACF_ISLN: "PACF_ISLN",
  UNKNWN: "UNKNWN",
  NATV_AMRCN: "NATV_AMRCN",
  NATV_HAWAN: "NATV_HAWAN",
  RACE_OTH: "RACE_OTH",
  NOT_RSPND: "NOT_RSPND",
  ADMN_USR: "ADMN_USR",
  MAIN: "MAIN",
  MAIN_TITLE: "MAIN_TITLE",
  LANGUAGES: "LANGUAGES",
  RACE: "RACE",
  MOBL_TYPES: "MOBL_TYPES",
  GENDER: "GENDER",
  STATE: "STATE",
  DATE_FUT: "DATE_FUT",
  DATE_FOR: "DATE_FOR",
  PH_NOTZERO: "PH_NOTZERO",
  NO_COUNTY1: "NO_COUNTY1",
  NO_COUNTY2: "NO_COUNTY2",
  CNTY_MAP: "CNTY_MAP",
  NO_THIS: "NO_THIS",
  PRSNL_INFO: "PRSNL_INFO",
  HMEAD_INFO: "HMEAD_INFO",
  MAIL_INFO: "MAIL_INFO",
  EMERG_INFO: "EMERG_INFO",
  EMERG_NOTE: "EMERG_NOTE",
  DEMOG_INFO: "DEMOG_INFO",
  TRNS_INFO1: "TRNS_INFO1",
  TRNS_INFO2: "TRNS_INFO2",
  TRANS_ASST: "TRANS_ASST",
  MOBIL_INFO: "MOBIL_INFO",
  MOBL_INFO2: "MOBL_INFO2",
  ADDR_TEXT: "ADDR_TEXT",
  ADDR_APT: "ADDR_APT",
  DL_HELPTXT: "DL_HELPTXT",
  ABOUT: "ABOUT",
  ABOUT_ADV: "ABOUT_ADV",
  ABOUT_FEA: "ABOUT_FEA",
  ABOUT_FUN: "ABOUT_FUN",
  ABOUT_HEL: "ABOUT_HEL",
  REPORTS: "REPORTS",
  EMBD_TOK: "EMBD_TOK",
  EMBD_URL: "EMBD_URL",
  TOKEN_TYPE: "TOKEN_TYPE",
  R_TYPE: "R_TYPE",
  R_PAGE_V: "R_PAGE_V",
  ADA: "ADA",
  FTP: "FTP",
  MATP: "MATP",
  PWD: "PWD",
  SSR: "SSR",
  CRSL_INTVL: "CRSL_INTVL",
  IN_PROCESS: "IN_PROCESS",
  ELIGIBLE: "ELIGIBLE",
  TM_APR_PCF: "TM_APR_PCF",
  ADSRCH_STS: "ADSRCH_STS",
  HME_THIS: "HME_THIS",
  NCOUNTY_1: "NCOUNTY_1",
  NCOUNTY_3: "NCOUNTY_3",
  CTZ_PORTAL: "CTZ_PORTAL",
  BP_PORTAL: "BP_PORTAL",
  Y_PWD_1: "Y_PWD_1",
  Y_ACPWD_1: "Y_ACPWD_1",
  WHY_PWD: "WHY_PWD",
  WHY_ACPWD: "WHY_ACPWD",
  YNT_PWD1: "YNT_PWD1",
  Y_ADA_1: "Y_ADA_1",
  Y_ACADA_1: "Y_ACADA_1",
  WHY_ADA: "WHY_ADA",
  WHY_ACADA: "WHY_ACADA",
  YNT_ADA1: "YNT_ADA1",
  Y_SSR_1: "Y_SSR_1",
  WHY_SSR: "WHY_SSR",
  YNT_SSR1: "YNT_SSR1",
  Y_FTP_1: "Y_FTP_1",
  WHY_FTP: "WHY_FTP",
  YNT_FTP1: "YNT_FTP1",
  YNT_MATP1: "YNT_MATP1",
  WHY_MATP: "WHY_MATP",
  Y_MATP_1: "Y_MATP_1",
  HELP: "HELP",
  HELP_CONT: "HELP_CONT",
  HELP_GUIDE: "HELP_GUIDE",
  AG_HELP: "AG_HELP",
  AG_CONT: "AG_CONT",
  AG_GUIDE: "AG_GUIDE",
  BP_REG: "BP_REG",
  AG_DASH: "AG_DASH",
  APP_REV_E: "APP_REV_E",
  APP_REV: "APP_REV",
  AG_SEARCH: "AG_SEARCH",
  ORG_HELP: "ORG_HELP",
  ORG_CONT: "ORG_CONT",
  ORG_GUIDE: "ORG_GUIDE",
  ORG_DASH: "ORG_DASH",
  PRVDR_ASST: "PRVDR_ASST",
  REG_LOGIN: "REG_LOGIN",
  COMP_SCR_Q: "COMP_SCR_Q",
  CREATE_APP: "CREATE_APP",
  UPD_RESBMT: "UPD_RESBMT",
  HOME_WELC: "HOME_WELC",
  HOME_STRT: "HOME_STRT",
  HOME_RIDE: "HOME_RIDE",
  HOME_INFO: "HOME_INFO",
  HOME_ELIG: "HOME_ELIG",
  HOME_DTELG: "HOME_DTELG",
  SM_QRYDATA: "SM_QRYDATA",
  SM_AUTHRSN: "SM_AUTHRSN",
  SM_AGNT_NM: "SM_AGNT_NM",
  PST_PSVDAT: "PST_PSVDAT",
  SMENC: "SMENC",
  SMLOCALE: "SMLOCALE",
  TARGET: "TARGET",
  BUTTON1: "BUTTON1",
  ESEC_URL: "ESEC_URL",
  CTZ_FGTUSR: "CTZ_FGTUSR",
  CTZ_FGTPWD: "CTZ_FGTPWD",
  BP_FGTUSR: "BP_FGTUSR",
  BP_FGTPWD: "BP_FGTPWD",
  LOGIN_FAIL: "LOGIN_FAIL",
  ACC_LOCKED: "ACC_LOCKED",
  SHW_LGNSML: "SHW_LGNSML",
  CTZURL_QA: "CTZURL_QA",
  BPURL_QA: "BPURL_QA",
  CTZURL_ROL: "CTZURL_ROL",
  SUB_MSG: "SUB_MSG",
  RGSTR_RADS: "RGSTR_RADS",
  CTZ_DESC1: "CTZ_DESC1",
  CTZ_DESC2: "CTZ_DESC2",
  ORG_DESC1: "ORG_DESC1",
  ORG_DESC2: "ORG_DESC2",
  ORG_RGLINK: "ORG_RGLINK",
  CTZ_RGLINK: "CTZ_RGLINK",
  NO_ELGPGM: "NO_ELGPGM",
  NO_ELGPGM1: "NO_ELGPGM1",
  WNOT_PWD: "WNOT_PWD",
  WNOT_ADA: "WNOT_ADA",
  WNOT_MATP: "WNOT_MATP",
  WNOT_FTP: "WNOT_FTP",
  WNOT_SSR: "WNOT_SSR",
  CREATED_BY: "CREATED_BY",
  ENVRONMNT: "ENVRONMNT",
  FMR: "FMR",
  SHOW_UNAVA: "SHOW_UNAVA",
  MATP_MSG: "MATP_MSG",
  DIS_LGN_BT: "DIS_LGN_BT",
  ALERT_COLR: "ALERT_COLR",
  HOME_IMG: "HOME_IMG",
  DEFAULTDAY: "DEFAULTDAY",
  RASGN_ACT: "RASGN_ACT",
  WTDRAW_MSG: "WTDRAW_MSG",
  LT_COD: "LT_COD",
  PRMRY_LNG: "PRMRY_LNG",
  LNG_INTRPR: "LNG_INTRPR",
  RCE_ETNCTY: "RCE_ETNCTY",
  NRG_HME: "NRG_HME",
  DCRG_NG_HM: "DCRG_NG_HM",
  PSNL_CR_HM: "PSNL_CR_HM",
  PSNL_C_ADT: "PSNL_C_ADT",
  CUR_MN_TRP: "CUR_MN_TRP",
  PNL_CHM_TP: "PNL_CHM_TP",
  LFT_EN_VEH: "LFT_EN_VEH",
  ANY_MOB_DV: "ANY_MOB_DV",
  AL_MBD_LBL: "AL_MBD_LBL",
  CR_MN_VAN: "CR_MN_VAN",
  WCHR_MET_Q: "WCHR_MET_Q",
  VRFY_AGE_Q: "VRFY_AGE_Q",
  DIS_CNFM: "DIS_CNFM",
  VOT_REG_Q1: "VOT_REG_Q1",
  DS_PRV_FRS: "DS_PRV_FRS",
  AN_FRQ_PRO: "AN_FRQ_PRO",
  MEDAST_ELG: "MEDAST_ELG",
  MEDAST_RGS: "MEDAST_RGS",
  DIG10_RN: "DIG10_RN",
  HAS_SSN: "HAS_SSN",
  EN_SSN: "EN_SSN",
  HSHD_MATP: "HSHD_MATP",
  ACCS_VEH: "ACCS_VEH",
  VALD_DL: "VALD_DL",
  TNSPT_APT: "TNSPT_APT",
  BS_TRN_STP: "BS_TRN_STP",
  MED_COG_RS: "MED_COG_RS",
  DIF_TNSPTS: "DIF_TNSPTS",
  RDE_WT_OTH: "RDE_WT_OTH",
  DMGPH_QNS: "DMGPH_QNS",
  TSPN_INF_Q: "TSPN_INF_Q",
  MOB_INF_QS: "MOB_INF_QS",
  PRG_INFO: "PRG_INFO",
  ADA_INFO: "ADA_INFO",
  MATP_INFO: "MATP_INFO",
  ABOVE_65: "ABOVE_65",
  CPTA_COD: "CPTA_COD",
  CLTS_COD: "CLTS_COD",
  FLTR_MATP: "FLTR_MATP",
  BTWN_1864: "BTWN_1864",
  UNDER_18: "UNDER_18",
  C_POD_PWD: "C_POD_PWD",
  C_COD_ADA: "C_COD_ADA",
  AG_ATT_TXT: "AG_ATT_TXT",
  NO_R_COR: "NO_R_COR",

  APEL_INPRG: "APEL_INPRG",
  UPD_ELGBTY: "UPD_ELGBTY",
  APP_APPL: "APP_APPL",
  APLD_INF: "APLD_INF",
  C_COD_PWD: "C_COD_PWD",
  MOVED: "MOVED",
  ABOUT_PRCS: "ABOUT_PRCS",
  FLOW_HORZ: "FLOW_HORZ",
  FLOW_VERT: "FLOW_VERT",

  ST_COLLG_Q: "ST_COLLG_Q",
  NO_SRVAREA: "NO_SRVAREA",

  REV_FDBC: "REV_FDBC",

  BTWN_6064: "BTWN_6064",
  WHY_AGING: "WHY_AGING",
  Y_AGING1: "Y_AGING_1",
  WNOT_AGING: "WNOT_AGING",
  YNT_AGING1: "YNT_AGING1",
  WHY_VTP: "WHY_VTP",
  Y_VTP_1: "Y_VTP_1",
  WNOT_VTP: "WNOT_VTP",
  YNT_VTP1: "YNT_VTP1",
  WHY_RFTP: "WHY_RFTP",
  Y_RFTP_1: "Y_RFTP_1",
  WNOT_RFTP: "WNOT_RFTP",
  YNT_RFTP1: "YNT_RFTP1",
  AGING: "AGING",
  RFTP: "RFTP",
  VTP: "VTP",
  RFTP_SEC1: "RFTP_SEC1",
  RFTP_INFO: "RFTP_INFO",
  MATP_DISC1: "MATP_DISC1",
  RFTP_APPLY: "RFTP_APPLY",
  MEDCAR_IND: "MEDCAR_IND",
  RFTP_APY_T: "RFTP_APY_T",
  RFTP_APY_B: "RFTP_APY_B",
  RFTP_MED_T: "RFTP_MED_T",
  RFTP_MED_B: "RFTP_MED_B",
  VTP_SEC1: "VTP_SEC1",
  VTP_INFO: "VTP_INFO",
  VTP_Q: "VTP_Q",
  VTP_NO_MSG: "VTP_NO_MSG",
  PWD_NO_MSG: "PWD_NO_MSG",
  ATH_NO_INF: "ATH_NO_INF",
  STRT_HELP: "STRT_HELP",
  DOCS_VTP: "DOCS_VTP",
  VTP_PROOF: "VTP_PROOF",
  VTP_INFO_1: "VTP_INFO_1",
  DOCS_RFTP: "DOCS_RFTP",
  MCI_PROOF: "MCI_PROOF",
  ABOVE_17: "ABOVE_17",
  CST_DSBLTY: "CST_DSBLTY",
  AMT_CH_HLP: "AMT_CH_HLP",
  EDU_URL: "EDU_URL",
  BY_PAS_PRG: "BY_PAS_PRG",
  BY_PAS_TXT: "BY_PAS_TXT",
  BY_PAS_HDR: "BY_PAS_HDR",
  BY_PAS_CHK: "BY_PAS_CHK",
  BY_PAS_SUM: "BY_PAS_SUM",
  BRWSR_ERR: "BRWSR_ERR",
  ECLN_DIFF: "ECLN_DIFF",
  ECLN_D_COL: "ECLN_D_COL",
  ECLN_TXT: "ECLN_TXT",
  MORE_INFO: "MORE_INFO",
  WHY_TXT: "WHY_TXT",
  UPCMGAPTS: "UPCMGAPTS",
  MATP_SCR_Q: "MATP_SCR_Q",
  DISB_SCR_Q: "DISB_SCR_Q",
  SUB_NOTE: "SUB_NOTE",
  DS_AGY_FRS: "DS_AGY_FRS",
  TVL_ARA_Q1: "TVL_ARA_Q1",
  TVL_ARA_Q2: "TVL_ARA_Q2",
  VTP_DOCTXT: "VTP_DOCTXT",
  FIND_DL: "FIND_DL",
  ADA_Y: "ADA_Y",
  ADAAC_Y: "ADAAC_Y",
  ADA_N: "ADA_N",
  FTP_Y: "FTP_Y",
  FTP_N: "FTP_N",
  MATP_Y: "MATP_Y",
  MATP_N: "MATP_N",
  PWD_Y: "PWD_Y",
  PWDAC_Y: "PWDAC_Y",
  PWD_N: "PWD_N",
  RFTP_Y: "RFTP_Y",
  RFTP_N: "RFTP_N",
  SSR_Y: "SSR_Y",
  SSR_N: "SSR_N",
  AGING_Y: "AGING_Y",
  AGING_N: "AGING_N",
  VTP_Y: "VTP_Y",
  VTP_N: "VTP_N",
  PFILE_QUES: "PFILE_QUES",
  IS_MAIL_AD: "IS_MAIL_AD",
  AGE_PROF1: "AGE_PROF1",
  GIS_CNFM_1: "GIS_CNFM_1",
  GIS_CNFM_2: "GIS_CNFM_2",
  ADR_CH_HDR: "ADR_CH_HDR",
  CHNGE_MSG1: "CHNGE_MSG1",
  CHNGE_MSG2: "CHNGE_MSG2",
  MAX_PHYS: "MAX_PHYS",
  ORDINALS: "ORDINALS",
  ADD_PHY_QU: "ADD_PHY_QU",
  PHY_HLPTXT: "PHY_HLPTXT",
  REL_TO_APL: "REL_TO_APL",
  ACCBL_FRMT: "ACCBL_FRMT",
  ADIO_CASS: "ADIO_CASS",
  BRAILLE: "BRAILLE",
  LRGE_PRNT: "LRGE_PRNT",
  ACCBL_OTHR: "ACCBL_OTHR",
  ACBL_PRT_Q: "ACBL_PRT_Q",
  ACBL_INFO: "ACBL_INFO",
  INCMPLT: "INCMPLT",
  PNDNG: "PENDING",
  PENDING: "PENDING",
  INCOMPLETE: "INCOMPLETE",
  C_COD_VIS: "C_COD_VIS",
  SSR_DE_ALG: "SSR_DE_ALG",
  SSR_DET_1: "SSR_DET_1",
  SSR_DET_2: "SSR_DET_2",
  SSR_DET_3: "SSR_DET_3",
  FUNV_DOC_T: "FUNV_DOC_T",
  SEPTA_COD: "SEPTA_COD",
  VIS_FORM: "VIS_FORM",
  FV_FORM: "FV_FORM",
  VIS_DOC_T: "VIS_DOC_T",
  PCA_ADA_DC: "PCA_ADA_DC",
  ACCS_LNK: "ACCS_LNK",
  GOV_NAME: "GOV_NAME",
  LTGOV_NAME: "LTGOV_NAME",
  RVW_LCKOUT: "RVW_LCKOUT",
  TRNSP_WAYS: "TRNSP_WAYS",
  BUS_TRLY: "BUS_TRLY",
  SUB_TRAIN: "SUB_TRAIN",
  AMBLANC: "AMBLANC",
  AUTOMOBL: "AUTOMOBL",
  WALKING: "WALKING",
  TAXI_SERVC: "TAXI_SERVC",
  TRNSP_OTHR: "TRNSP_OTHR",
  DUP_APPL_M: "DUP_APPL_M",
  DOCS_FLTR: "DOCS_FLTR",
  CREATE_PHL: "CREATE_PHL",
  CREATE_ALG: "CREATE_ALG",
  MH_ABT_LNK: "MH_ABT_LNK",
  MH_HLP_LNK: "MH_HLP_LNK",
  MH_CON_LNK: "MH_CON_LNK",
  MH_REG_LNK: "MH_REG_LNK",
  MAID_SSN: "MAID_SSN",
  NMTCH_SSN: "NMTCH_SSN",
  NMTCH_MAID: "NMTCH_MAID",
  INELG_MATP: "INELG_MATP",
  AUTH_DECLN: "AUTH_DECLN"
};

export const GPC_HEADER: string[] = [
  GPC.PWD_SEC2,
  GPC.ADA_SEC1,
  GPC.ADA_SEC2,
  GPC.SSR_SEC1,
  GPC.SSR_SEC2,
  GPC.FTP_SEC1,
  GPC.FTP_SEC2,
  GPC.FTP_INFO,
  GPC.FTP_SEPT_Q,
  GPC.AGE_VRFYST,
  GPC.VOTER_REG,
  GPC.VOTR_REG_2,
  GPC.VOTE_YES,
  GPC.VOTE_NO,
  GPC.VOTE_ALRDY,
  GPC.RE_COR,
  GPC.IN_PRGS_C,
  GPC.RDY_SBMT_C,
  GPC.WITHDRAWN,
  GPC.PEND_CONF,
  GPC.TMP_APRVD,
  GPC.NT_QLFY,
  GPC.DSB_ACT,
  GPC.ADA_COMP,
  GPC.FXD_RT,
  GPC.DOC_PROOFS,
  GPC.PRF_PRCARE,
  GPC.RVW_RCMNDS,
  GPC.ELIGIB,
  GPC.TM_APR_PCF,
  GPC.IN_ELIGB,
  GPC.RETFORCORR,
  GPC.DOCUPL_ERR,
  GPC.ECL_CFG_RD,
  GPC.CMP_RV_SLF,
  GPC.CMP_RV_OTH,
  GPC.NO_ECLN_AG,
  GPC.NO_ECLN_PG,
  GPC.YNT_MATP1,
  GPC.YNT_FTP1,
  GPC.POD_AGNCYS,
  GPC.UN_RVW,
  GPC.APRW_STRT,
  GPC.STRT_RW,
  GPC.SAVE_CNTN,
  GPC.APP_NTAPCL,
  GPC.CMPLT_RVW,
  GPC.RVW_CMPLT,
  GPC.EDT_APP,
  GPC.APP_EDTD,
  GPC.WTHDRW,
  GPC.APP_WTDRWN,
  GPC.CNT_RVW,
  GPC.TMP_APRV,
  GPC.RWR_CHNGD,
  GPC.APPRV,
  GPC.CNFG_CMPLT,
  GPC.APP_RSBMTD,
  GPC.CARE_ATTND,
  GPC.APPROVE,
  GPC.INPRN_ASMT,
  GPC.OTHER_DOC,
  GPC.COD_DOCTXT,
  GPC.PCA_DOCTXT,
  GPC.FRDMCODTXT,
  GPC.CTDC_MATP,
  GPC.CTDC_COD,
  GPC.PARTCODTXT,
  GPC.AMTRAN_COD,
  GPC.C_COD_AGNY,
  GPC.VLD_DOCEXT,
  GPC.INELIGIBLE,
  GPC.FILE_EXT,
  GPC.INVALD_DOC,
  GPC.DOCS_ADA,
  GPC.DOCS_PWD,
  GPC.DOCS_FTP,
  GPC.DOCS_SSR,
  GPC.DOCS_MATP,
  GPC.WHITE,
  GPC.BLACK,
  GPC.ASIAN,
  GPC.HISPANIC,
  GPC.NATV_ALSKN,
  GPC.PACF_ISLN,
  GPC.UNKNWN,
  GPC.NATV_AMRCN,
  GPC.NATV_HAWAN,
  GPC.RACE_OTH,
  GPC.NOT_RSPND,
  GPC.LANGUAGES,
  GPC.RACE,
  GPC.MOBL_TYPES,
  GPC.GENDER,
  GPC.STATE,
  GPC.DATE_FUT,
  GPC.DATE_FOR,
  GPC.PH_NOTZERO,
  GPC.NO_COUNTY1,
  GPC.NO_COUNTY2,
  GPC.CNTY_MAP,
  GPC.NO_THIS,
  GPC.PRSNL_INFO,
  GPC.HMEAD_INFO,
  GPC.MAIL_INFO,
  GPC.EMERG_INFO,
  GPC.EMERG_NOTE,
  GPC.DEMOG_INFO,
  GPC.TRNS_INFO1,
  GPC.TRNS_INFO2,
  GPC.TRANS_ASST,
  GPC.MOBIL_INFO,
  GPC.MOBL_INFO2,
  GPC.ADDR_TEXT,
  GPC.ADDR_APT,
  GPC.DL_HELPTXT,
  GPC.ABOUT,
  GPC.ABOUT_ADV,
  GPC.ABOUT_FEA,
  GPC.ABOUT_HEL,
  GPC.REPORTS,
  GPC.EMBD_TOK,
  GPC.EMBD_URL,
  GPC.TOKEN_TYPE,
  GPC.R_TYPE,
  GPC.R_PAGE_V,
  GPC.DIS_TYPES,
  GPC.DIS_CNDTNS,
  GPC.SPL_NEEDS,
  GPC.INCM_TYPES,
  GPC.RADIO_YN,
  GPC.ENG,
  GPC.ARA,
  GPC.ZHO,
  GPC.FRE,
  GPC.DEU,
  GPC.GUJ,
  GPC.HAT,
  GPC.ITA,
  GPC.KOR,
  GPC.MKH,
  GPC.NEP,
  GPC.POL,
  GPC.POR,
  GPC.RUS,
  GPC.SPA,
  GPC.VIE,
  GPC.Y,
  GPC.N,
  GPC.M,
  GPC.F,
  GPC.NOT_START,
  GPC.IN_PRGS,
  GPC.RDY_SBMT,
  GPC.NOT_QLFYD,
  GPC.NOT_APPL,
  GPC.SBMTD,
  GPC.APPROVED,
  GPC.PERM,
  GPC.TEMP,
  GPC.STABLE,
  GPC.PROGRESSIV,
  GPC.DISABL_1,
  GPC.DISABL_2,
  GPC.DISABL_3,
  GPC.DISABL_4,
  GPC.DISABL_5,
  GPC.DISABL_6,
  GPC.DISABL_7,
  GPC.DISABL_OTH,
  GPC.INCM_1,
  GPC.INCM_2,
  GPC.INCM_3,
  GPC.INCM_4,
  GPC.INCM_5,
  GPC.INCM_6,
  GPC.INCM_7,
  GPC.INCM_8,
  GPC.AGE_PROOF,
  GPC.AGE_VRFY_D,
  GPC.PRF_DSBLTY,
  GPC.PRF_DSBY_2,
  GPC.PRF_DSBY_3,
  GPC.MATP_FORM,
  GPC.CERT_DBLTY,
  GPC.MAN_WHLCHR,
  GPC.MOT_WHLCHR,
  GPC.MOTO_SCO,
  GPC.OXYGN,
  GPC.WALKER,
  GPC.CANE,
  GPC.CAN_CRUC,
  GPC.SRVC_ANML,
  GPC.MOB_OTHER,
  GPC.CH_BS,
  GPC.CH_CS,
  GPC.POW_WHLCHR,
  GPC.PA,
  GPC.DL_INVALID,
  GPC.DL_VALID,
  GPC.APPL_CRT,
  GPC.APPL_RSBT,
  GPC.APPL_SBTD,
  GPC.APPL_DQFD,
  GPC.APPL_SBMT,
  GPC.STR_APPL,
  GPC.FNLZ,
  GPC.SAVE,
  GPC.MEDICA_INS,
  GPC.DL_INSTRUC,
  GPC.DL_TMT_TOP,
  GPC.DL_TMT_BTM,
  GPC.MATP_SEC1,
  GPC.MATP_AUTH,
  GPC.MATP_SC1_2,
  GPC.MATP_SEC2,
  GPC.PWD_SEC1_1,
  GPC.PWD_SEC1,
  GPC.IN_PROCESS,
  GPC.ELIGIBLE,
  GPC.ADSRCH_STS,
  GPC.CTZ_PORTAL,
  GPC.BP_PORTAL,
  GPC.Y_PWD_1,
  GPC.WHY_PWD,
  GPC.WHY_ACPWD,
  GPC.YNT_PWD1,
  GPC.Y_ADA_1,
  GPC.WHY_ADA,
  GPC.WHY_ACADA,
  GPC.YNT_ADA1,
  GPC.WHY_SSR,
  GPC.Y_SSR_1,
  GPC.YNT_SSR1,
  GPC.WNOT_SSR,
  GPC.Y_FTP_1,
  GPC.Y_MATP_1,
  GPC.HELP,
  GPC.HELP_CONT,
  GPC.HELP_GUIDE,
  GPC.AG_HELP,
  GPC.AG_CONT,
  GPC.AG_GUIDE,
  GPC.BP_REG,
  GPC.AG_DASH,
  GPC.APP_REV_E,
  GPC.APP_REV,
  GPC.AG_SEARCH,
  GPC.ORG_HELP,
  GPC.ORG_CONT,
  GPC.ORG_GUIDE,
  GPC.ORG_DASH,
  GPC.PRVDR_ASST,
  GPC.REG_LOGIN,
  GPC.COMP_SCR_Q,
  GPC.CREATE_APP,
  GPC.UPD_RESBMT,
  GPC.SUB_MSG,
  GPC.HOME_WELC,
  GPC.HOME_STRT,
  GPC.HOME_RIDE,
  GPC.ABOUT_FUN,
  GPC.ADA,
  GPC.FTP,
  GPC.MATP,
  GPC.PWD,
  GPC.SSR,
  GPC.HOME_INFO,
  GPC.HOME_ELIG,
  GPC.SM_QRYDATA,
  GPC.SM_AUTHRSN,
  GPC.SM_AGNT_NM,
  GPC.PST_PSVDAT,
  GPC.SMENC,
  GPC.SMLOCALE,
  GPC.TARGET,
  GPC.BUTTON1,
  GPC.ESEC_URL,
  GPC.HME_THIS,
  GPC.NCOUNTY_1,
  GPC.NCOUNTY_3,
  GPC.CTZ_FGTUSR,
  GPC.CTZ_FGTPWD,
  GPC.BP_FGTUSR,
  GPC.BP_FGTPWD,
  GPC.HOME_DTELG,
  GPC.LOGIN_FAIL,
  GPC.ACC_LOCKED,
  GPC.QUICK_SRCH,
  GPC.ER_INLINE,
  GPC.SHW_LGNSML,
  GPC.CTZURL_QA,
  GPC.BPURL_QA,
  GPC.CTZURL_ROL,
  GPC.CHGLGN_TXT,
  GPC.CRSL_INTVL,
  GPC.ADMN_USR,
  GPC.MAIN,
  GPC.MAIN_TITLE,
  GPC.RGSTR_RADS,
  GPC.CTZ_DESC1,
  GPC.CTZ_DESC2,
  GPC.ORG_DESC1,
  GPC.ORG_DESC2,
  GPC.ORG_RGLINK,
  GPC.CTZ_RGLINK,
  GPC.NO_ELGPGM,
  GPC.WNOT_PWD,
  GPC.WNOT_ADA,
  GPC.WNOT_MATP,
  GPC.WNOT_FTP,
  GPC.CREATED_BY,
  GPC.ENVRONMNT,
  GPC.FMR,
  GPC.SHOW_UNAVA,
  GPC.MATP_MSG,
  GPC.DIS_LGN_BT,
  GPC.ALERT_COLR,
  GPC.HOME_IMG,
  GPC.DEFAULTDAY,
  GPC.RASGN_ACT,
  GPC.WTDRAW_MSG,
  GPC.LT_COD,
  GPC.PRMRY_LNG,
  GPC.LNG_INTRPR,
  GPC.RCE_ETNCTY,
  GPC.NRG_HME,
  GPC.DCRG_NG_HM,
  GPC.PSNL_CR_HM,
  GPC.PSNL_C_ADT,
  GPC.CUR_MN_TRP,
  GPC.PNL_CHM_TP,
  GPC.LFT_EN_VEH,
  GPC.ANY_MOB_DV,
  GPC.AL_MBD_LBL,
  GPC.CR_MN_VAN,
  GPC.WCHR_MET_Q,
  GPC.VRFY_AGE_Q,
  GPC.DIS_CNFM,
  GPC.VOT_REG_Q1,
  GPC.DS_PRV_FRS,
  GPC.AN_FRQ_PRO,
  GPC.MEDAST_ELG,
  GPC.MEDAST_RGS,
  GPC.DIG10_RN,
  GPC.HAS_SSN,
  GPC.EN_SSN,
  GPC.HSHD_MATP,
  GPC.ACCS_VEH,
  GPC.VALD_DL,
  GPC.TNSPT_APT,
  GPC.BS_TRN_STP,
  GPC.MED_COG_RS,
  GPC.DIF_TNSPTS,
  GPC.RDE_WT_OTH,
  GPC.DMGPH_QNS,
  GPC.TSPN_INF_Q,
  GPC.MOB_INF_QS,
  GPC.PRG_INFO,
  GPC.ADA_INFO,
  GPC.MATP_INFO,
  GPC.ABOVE_65,
  GPC.CPTA_COD,
  GPC.CLTS_COD,
  GPC.FLTR_MATP,
  GPC.UNDER_18,
  GPC.BTWN_1864,
  GPC.C_POD_PWD,
  GPC.C_COD_ADA,
  GPC.AG_ATT_TXT,
  GPC.NO_R_COR,

  GPC.APEL_INPRG,
  GPC.UPD_ELGBTY,
  GPC.APP_APPL,
  GPC.APLD_INF,
  GPC.C_COD_PWD,
  GPC.ABOUT_PRCS,
  GPC.FLOW_HORZ,
  GPC.FLOW_VERT,

  GPC.ST_COLLG_Q,
  GPC.NO_SRVAREA,

  GPC.REV_FDBC,

  GPC.BTWN_6064,
  GPC.WHY_AGING,
  GPC.Y_AGING1,
  GPC.YNT_AGING1,
  GPC.WNOT_AGING,
  GPC.WHY_VTP,
  GPC.Y_VTP_1,
  GPC.WNOT_VTP,
  GPC.YNT_VTP1,
  GPC.WHY_RFTP,
  GPC.Y_RFTP_1,
  GPC.WNOT_RFTP,
  GPC.YNT_RFTP1,
  GPC.Y_ACADA_1,
  GPC.Y_ACPWD_1,
  GPC.AGING,
  GPC.VTP,
  GPC.RFTP,
  GPC.RFTP_SEC1,
  GPC.RFTP_INFO,
  GPC.MATP_DISC1,
  GPC.RFTP_APPLY,
  GPC.MEDCAR_IND,
  GPC.RFTP_APY_T,
  GPC.RFTP_APY_B,
  GPC.RFTP_MED_T,
  GPC.RFTP_MED_B,
  GPC.VTP_SEC1,
  GPC.VTP_INFO,
  GPC.VTP_Q,
  GPC.VTP_NO_MSG,
  GPC.PWD_NO_MSG,
  GPC.ATH_NO_INF,
  GPC.STRT_HELP,
  GPC.DOCS_VTP,
  GPC.VTP_PROOF,
  GPC.VTP_INFO_1,
  GPC.DOCS_RFTP,
  GPC.MCI_PROOF,
  GPC.ABOVE_17,
  GPC.CST_DSBLTY,
  GPC.AMT_CH_HLP,
  GPC.EDU_URL,
  GPC.BY_PAS_PRG,
  GPC.BY_PAS_TXT,
  GPC.BY_PAS_HDR,
  GPC.BY_PAS_CHK,
  GPC.BY_PAS_SUM,
  GPC.BRWSR_ERR,
  GPC.ECLN_DIFF,
  GPC.MORE_INFO,
  GPC.WHY_TXT,
  GPC.UPCMGAPTS,
  GPC.MATP_SCR_Q,
  GPC.DISB_SCR_Q,
  GPC.DS_AGY_FRS,
  GPC.TVL_ARA_Q1,
  GPC.TVL_ARA_Q2,
  GPC.VTP_DOCTXT,
  GPC.FIND_DL,
  GPC.VTP_DOCTXT,
  GPC.ADA_Y,
  GPC.ADAAC_Y,
  GPC.PWDAC_Y,
  GPC.ADA_N,
  GPC.FTP_Y,
  GPC.FTP_N,
  GPC.MATP_Y,
  GPC.MATP_N,
  GPC.PWD_Y,
  GPC.PWD_N,
  GPC.RFTP_Y,
  GPC.RFTP_N,
  GPC.SSR_Y,
  GPC.SSR_N,
  GPC.AGING_Y,
  GPC.AGING_N,
  GPC.VTP_Y,
  GPC.VTP_N,
  GPC.PFILE_QUES,
  GPC.IS_MAIL_AD,
  GPC.AGE_PROF1,
  GPC.GIS_CNFM_1,
  GPC.GIS_CNFM_2,
  GPC.ADR_CH_HDR,
  GPC.CHNGE_MSG1,

  GPC.CHNGE_MSG2,
  GPC.REL_TO_APL,
  GPC.ACCBL_FRMT,
  GPC.ADIO_CASS,
  GPC.BRAILLE,
  GPC.LRGE_PRNT,
  GPC.ACCBL_OTHR,
  GPC.ACBL_PRT_Q,
  GPC.ACBL_INFO,
  GPC.PNDNG,
  GPC.INCMPLT,
  GPC.INCOMPLETE,
  GPC.PENDING,
  GPC.C_COD_VIS,
  GPC.SSR_DE_ALG,
  GPC.SSR_DET_1,
  GPC.SSR_DET_2,
  GPC.SSR_DET_3,
  GPC.FUNV_DOC_T,
  GPC.SEPTA_COD,
  GPC.VIS_FORM,
  GPC.FV_FORM,
  GPC.VIS_DOC_T,
  GPC.PCA_ADA_DC,
  GPC.ACCS_LNK,
  GPC.GOV_NAME,
  GPC.LTGOV_NAME,
  GPC.RVW_LCKOUT,
  GPC.TRNSP_WAYS,
  GPC.BUS_TRLY,
  GPC.SUB_TRAIN,
  GPC.AMBLANC,
  GPC.AUTOMOBL,
  GPC.WALKING,
  GPC.TAXI_SERVC,
  GPC.TRNSP_OTHR,
  GPC.DUP_APPL_M,
  GPC.DOCS_FLTR,
  GPC.CREATE_PHL,
  GPC.CREATE_ALG,
  GPC.MAID_SSN,
  GPC.NMTCH_SSN,
  GPC.NMTCH_MAID,
  GPC.INELG_MATP,
  GPC.AUTH_DECLN
];

export const GPC_DEFAULT: string[] = [
  GPC.C_COD_AGNY,
  GPC.CHGLGN_TXT,
  GPC.QUICK_SRCH,
  GPC.ER_INLINE,
  GPC.ADMN_USR,
  GPC.MAIN,
  GPC.MAIN_TITLE,
  GPC.BTWN_1864,
  GPC.MOVED,
  GPC.BTWN_6064,
  GPC.MAX_PHYS,
  GPC.ORDINALS,
  GPC.BY_PAS_TXT,
  GPC.MH_ABT_LNK,
  GPC.MH_HLP_LNK,
  GPC.MH_CON_LNK,
  GPC.MH_REG_LNK
];
