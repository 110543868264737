import axios from "axios";
import { TES_APP_ENDPOINT } from "@/constants/EndPoints";
import { moduleActionContext } from "..";
import { defineModule } from "direct-vuex";
import { IDocumentSubmission } from "@/types";
import { HTTP_STATUS } from "@/constants/HttpStatusCode";

export interface AttachmentsState {
  documentList: [];
  isDocListLoading: false;
  isDocsRequired: false;
  isAllDocsUploaded: false;
  reviewerDocs: [];
  applicantDocs: [];
  reviewerDocsByAgencyAndDocType: [];
  submittedDocumentList: [];
  atchmtCheckboxSltdList: [];
  atchmtCheckboxSltdByDocTypeList: [];
  checkBoxValue: false;
  numberOfDocTypesAttached: number;
  docsSubmittedAssistanceRequired: number;
}

const attachmentsModule = defineModule({
  namespaced: true,
  state: {
    documentList: [],
    isDocListLoading: false,
    isDocsRequired: false,
    isAllDocsUploaded: false,
    reviewerDocs: [],
    applicantDocs: [],
    reviewerDocsByAgencyAndDocType: [],
    submittedDocumentList: [],
    atchmtCheckboxSltdList: [],
    atchmtCheckboxSltdByDocTypeList: [],
    checkBoxValue: false,
    numberOfDocTypesAttached: 0,
    docsSubmittedAssistanceRequired: 0
  } as AttachmentsState,
  mutations: {
    SET_DOC_LIST(state: AttachmentsState, newDocs: []) {
      state.documentList = newDocs;
    },
    SET_DOCLIST_LDNG_STS(state: AttachmentsState, payload: any) {
      state.isDocListLoading = payload;
    },
    SET_DOCS_REQUIRED(state: AttachmentsState, payload: any) {
      state.isDocsRequired = payload;
    },
    SET_ALL_DOCS_UPLOADED(state: AttachmentsState, payload: any) {
      state.isAllDocsUploaded = payload;
    },
    SET_REVR_DOC_LIST(state: AttachmentsState, newDocs: []) {
      state.reviewerDocs = newDocs;
    },
    SET_APPLICANT_DOC_LIST(state: AttachmentsState, newDocs: []) {
      state.applicantDocs = newDocs;
    },
    SET_REVR_DOCS_BY_AGENCY_AND_DOCTYPE(state: AttachmentsState, newDocs: []) {
      state.reviewerDocsByAgencyAndDocType = newDocs;
    },
    SET_SUBMITTED_DOCS_LIST(state: AttachmentsState, newDocs: []) {
      state.submittedDocumentList = newDocs;
    },
    SET_ATTACHMENT_CHECKBOX_SLTD_LIST(
      state: AttachmentsState,
      atchmtCheckboxSltdList: []
    ) {
      state.atchmtCheckboxSltdList = atchmtCheckboxSltdList;
    },
    SET_ATTACHMENT_CHECKBOX_SLTD_BY_DOC_TYPE_LIST(
      state: AttachmentsState,
      atchmtCheckboxSltdByDocTypeList: []
    ) {
      state.atchmtCheckboxSltdByDocTypeList = atchmtCheckboxSltdByDocTypeList;
    },
    SET_CHECK_BOX_VALUE(state: AttachmentsState, payload: any) {
      state.checkBoxValue = payload;
    },
    SET_NUM_OF_DOCS_ATTCHD(state: AttachmentsState, payload: any) {
      state.numberOfDocTypesAttached = payload;
    },
    SET_DOCS_SUBMITTED_ASSISTANCE_REQUIRED_COUNT(
      state: AttachmentsState,
      payload: number
    ) {
      state.docsSubmittedAssistanceRequired = payload;
    }
  },
  actions: {
    getDocListByProfileId(context: any, payload: any) {
      let url = TES_APP_ENDPOINT.GET_DOCUMENT_LIST;
      let { commit, rootDispatch } = attachmentsModuleActionContext(context);
      commit.SET_DOCLIST_LDNG_STS(true);
      axios
        .get(url, {
          params: {
            applicantProfileId: payload.applicantProfileId,
            docTypeCodes: payload.docTypeCodes
          }
        })
        .then(function(response) {
          commit.SET_DOC_LIST(response.data);
        })
        .catch(() => {
          rootDispatch.alertModule.showAlertMessageByGPC("ER_INLINE");
        })
        .finally(() => commit.SET_DOCLIST_LDNG_STS(false));
    },
    getReviewerDocsByReviewIdAndAgency(context: any, payload: any) {
      let url = TES_APP_ENDPOINT.GET_REVIEWER_DOCS_BY_REVIEWID_AND_AGENCY;
      let { commit, rootDispatch } = attachmentsModuleActionContext(context);
      commit.SET_DOCLIST_LDNG_STS(true);
      axios
        .get(url, {
          params: {
            applicationReviewId: payload.applicationReviewId,
            transitAgencyCode: payload.transitAgencyCode
          }
        })
        .then(function(response) {
          commit.SET_REVR_DOC_LIST(response.data);
        })
        .catch(() => {
          rootDispatch.alertModule.showAlertMessageByGPC("ER_INLINE");
        })
        .finally(() => commit.SET_DOCLIST_LDNG_STS(false));
    },
    getApplicantDocsByProfileId(context: any, payload: any) {
      let url = TES_APP_ENDPOINT.GET_APPLCNT_DOCS_BY_PROFILE_ID;
      let { commit, rootDispatch } = attachmentsModuleActionContext(context);
      commit.SET_DOCLIST_LDNG_STS(true);
      axios
        .get(url, {
          params: {
            applicantProfileId: payload.applicantProfileId
          }
        })
        .then(function(response) {
          commit.SET_APPLICANT_DOC_LIST(response.data);
        })
        .catch(() => {
          rootDispatch.alertModule.showAlertMessageByGPC("ER_INLINE");
        })
        .finally(() => commit.SET_DOCLIST_LDNG_STS(false));
    },
    getReviewrDocsByReviewIdAgencyAndDocType(context: any, payload: any) {
      let url = TES_APP_ENDPOINT.GET_REVWR_DOCS_BY_REVIEWID_AGENCY_DOCTYPE;
      let { commit, rootDispatch } = attachmentsModuleActionContext(context);
      commit.SET_DOCLIST_LDNG_STS(true);
      axios
        .get(url, {
          params: {
            applicationReviewId: payload.applicationReviewId,
            transitAgencyCode: payload.transitAgencyCode,
            documentTypeCode: payload.documentTypeCode
          }
        })
        .then(function(response) {
          commit.SET_REVR_DOCS_BY_AGENCY_AND_DOCTYPE(response.data);
        })
        .catch(() => {
          rootDispatch.alertModule.showAlertMessageByGPC("ER_INLINE");
        })
        .finally(() => commit.SET_DOCLIST_LDNG_STS(false));
    },
    getUploadedDocList(context: any, payload: any) {
      const url = TES_APP_ENDPOINT.GET_APPLCNT_DOCS_BY_PROFILE_ID;
      let { commit, rootDispatch } = attachmentsModuleActionContext(context);
      commit.SET_DOCLIST_LDNG_STS(true);
      axios
        .get(url, {
          params: {
            applicantProfileId: payload.applicantProfileId
          }
        })
        .then(function(response) {
          commit.SET_DOC_LIST(response.data);
        })
        .catch(() => {
          rootDispatch.alertModule.showAlertMessageByGPC("ER_INLINE");
        })
        .finally(() => commit.SET_DOCLIST_LDNG_STS(false));
    },
    getSubmittedDocList(context: any, payload: any) {
      const url = TES_APP_ENDPOINT.GET_DOCS_IN_DOCUMENT_SUBMISSION;
      let { commit, rootDispatch } = attachmentsModuleActionContext(context);
      commit.SET_DOCLIST_LDNG_STS(true);
      axios
        .get(url, {
          params: {
            tesApplicationId: payload.tesApplicationId
          }
        })
        .then(function(response) {
          commit.SET_SUBMITTED_DOCS_LIST(response.data);
        })
        .catch(() => {
          rootDispatch.alertModule.showAlertMessageByGPC("ER_INLINE");
        })
        .finally(() => commit.SET_DOCLIST_LDNG_STS(false));
    },
    getAtchmtChkBoxSltdByProfileId(context: any, payload: any) {
      let url = TES_APP_ENDPOINT.GET_DOCS_ATCHMT_CHKBOX_SELTD_LIST;
      let { commit, rootDispatch } = attachmentsModuleActionContext(context);
      axios
        .get(url, {
          params: {
            applicantProfileId: payload
          }
        })
        .then(function(response) {
          commit.SET_ATTACHMENT_CHECKBOX_SLTD_LIST(response.data);
        })
        .catch(() => {
          rootDispatch.alertModule.showAlertMessageByGPC("ER_INLINE");
        });
    },
    getAtchmtChkBoxSltdByProfileIdDocTypeCode(context: any, payload: any) {
      let url = TES_APP_ENDPOINT.GET_NO_DOC_CHECK_BOX_BY_DOC_TYPE_CODE_LIST;
      let { commit, rootDispatch } = attachmentsModuleActionContext(context);
      axios
        .get(url, {
          params: {
            applicantProfileId: payload.applicantProfileId,
            docTypeCodes: payload.docTypeCodes
          }
        })
        .then(function(response) {
          commit.SET_ATTACHMENT_CHECKBOX_SLTD_BY_DOC_TYPE_LIST(response.data);
        })
        .catch(() => {
          rootDispatch.alertModule.showAlertMessageByGPC("ER_INLINE");
        });
    },
    async getDocumentsSubmittedAssistanceRewuiredCount(
      context,
      payload: { tesApplicationId: number; appProfileId: number }
    ) {
      const url = TES_APP_ENDPOINT.GET_DOCS_LIST_SUBMITTED_ASSISTANCE_REQUIRED;
      const { commit, rootDispatch } = attachmentsModuleActionContext(context);
      let count = 0;
      try {
        const resp = await axios.get<IDocumentSubmission[]>(url, {
          params: {
            tesApplicationId: payload.tesApplicationId,
            applicantProfileId: payload.appProfileId
          }
        });
        if (resp.status == HTTP_STATUS.OK) {
          count = resp.data.length;
        } else {
          rootDispatch.alertModule.showAlertMessageByGPC("ER_INLINE");
        }
      } catch (e) {
        rootDispatch.alertModule.showAlertMessageByGPC("ER_INLINE");
      }
      commit.SET_DOCS_SUBMITTED_ASSISTANCE_REQUIRED_COUNT(count);
    }
  },
  getters: {
    getProfileDocs: (state: AttachmentsState) => {
      return state.documentList;
    },
    getDocListLoadingStatus: (state: AttachmentsState) => {
      return state.isDocListLoading;
    },
    getDocsRequired: (state: AttachmentsState) => {
      return state.isDocsRequired;
    },
    getAllDocsUploaded: (state: AttachmentsState) => {
      return state.isAllDocsUploaded;
    },
    getReviewerDocs: (state: AttachmentsState) => {
      return state.reviewerDocs;
    },
    getReviewrDocsByReviewIdAgencyAndDocType: (state: AttachmentsState) => {
      return state.reviewerDocsByAgencyAndDocType;
    },
    getSubmittedDocsList: (state: AttachmentsState) => {
      return state.submittedDocumentList;
    },
    getAtchmtCheckboxSltdList: (state: AttachmentsState) => {
      return state.atchmtCheckboxSltdList;
    },
    getAtchmtCheckboxSltdByDocTypeList: (state: AttachmentsState) => {
      return state.atchmtCheckboxSltdByDocTypeList;
    },
    getNumOfDocsAttchd: (state: AttachmentsState) => {
      return state.numberOfDocTypesAttached;
    },
    getDocsSubmittedAssistanceRequiredCount: (state: AttachmentsState) => {
      return state.docsSubmittedAssistanceRequired;
    }
  }
});

export default attachmentsModule;
const attachmentsModuleActionContext = (context: any) =>
  moduleActionContext(context, attachmentsModule);
