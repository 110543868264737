
import Vue from "vue";
import store from "@/store";
import * as pbi from "powerbi-client";
import axios from "axios";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import { GPC } from "@/constants/GPCCode";
import { REPORTS_ENDPOINT } from "@/constants/EndPoints";
import { HTTP_STATUS } from "@/constants/HttpStatusCode";

export default Vue.extend({
  name: "reportsPBI",
  mixins: [UIFeatureMixinVue],
  data() {
    return {
      showSurrogateDialog: false,
      displaySave: false,
      embedUrl: "" as string,
      accessToken: "" as string,
      dashboardId: ""
    };
  },

  computed: {
    isDataLoading(): boolean {
      return store.getters.uiFeatureModule.getLoadingStatus;
    }
  },
  created() {
    this.initializePowerBI();
  },
  mounted() {
    this.initializePowerBI().then(() => {
      const config = {
        //@ts-ignore
        type: this.uiFeature[GPC.R_TYPE].largeText,
        //@ts-ignore
        tokenType: pbi.models.TokenType.Embed,
        accessToken: this.accessToken,
        embedUrl: this.embedUrl,
        //@ts-ignore
        pageView: this.uiFeature.R_PAGE_V.displayName,
        dashboardId: this.dashboardId
      };

      let powerbi = new pbi.service.Service(
        pbi.factories.hpmFactory,
        pbi.factories.wpmpFactory,
        pbi.factories.routerFactory
      );

      const dashboardContainer = document.getElementById(
        "reportContainer"
      ) as HTMLElement;
      const dashboard = powerbi.embed(dashboardContainer, config);

      dashboard.off("loaded");
      dashboard.off("rendered");
      dashboard.on("error", function() {
        dashboard.off("error");
      });
    });
  },
  methods: {
    async initializePowerBI() {
      //@ts-ignore
      this.embedUrl = this.uiFeature[GPC.EMBD_URL].displayName;

      await axios
        .post(REPORTS_ENDPOINT.GET_EMBD_TOKEN)
        .then(response => {
          if (response.status === HTTP_STATUS.OK) {
            this.accessToken = response.data;
          }
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(error.response.data);
        });
    }
  }
});
