
import Vue from "vue";
import CloseButton from "@/components/common/CloseButton.vue";
export default Vue.extend({
  name: "Cancel-Dialog",
  props: {
    cancelDialog: { type: Boolean, default: false }
  },
  components: {
    CloseButton
  },
  data() {
    return {
      showCancelDialog: this.cancelDialog
    };
  },
  methods: {
    save() {
      this.$emit("yes-clicked");
    },
    close() {
      this.$emit("close");
      this.showCancelDialog = false;
    },
    noSave() {
      this.$emit("no-clicked");
    }
  }
});
