
import Vue from "vue";
import store from "@/store";
import axios from "axios";
import { IDraftProgramInfo } from "@/types";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import ProgramInfoSummaryMixin from "@/mixins/ProgramInfoSummaryMixin.vue";
import readonlyField from "@/components/common/ReadOnlyField.vue";
import { TES_APP_ENDPOINT } from "@/constants/EndPoints";
import { GPC } from "@/constants/GPCCode";
import DynamicTextMixin from "@/mixins/DynamicTextMixin.vue";
import { CNST_TEXT } from "@/constants/CommonConstants";

export default Vue.extend({
  name: "RFTP-Program-Information",
  mixins: [UIFeatureMixinVue, ProgramInfoSummaryMixin, DynamicTextMixin],
  components: { readonlyField },
  data() {
    return {
      agencyProviders: [] as Object[],
      isSeptaPresent: false,
      showProgressBar: false
    };
  },
  computed: {
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      }
    },
    rftpFxdRouteIndDsplyNameQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithOnePlaceHolderVal(
        GPC.RFTP_APPLY,
        CNST_TEXT.DOES_THE_APPLICANT,
        CNST_TEXT.DO_YOU
      );
      return text;
    },
    disabilityConfirmQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithOnePlaceHolder(
        GPC.DIS_CNFM,
        CNST_TEXT.THE_APPLICANT_HAS,
        CNST_TEXT.YOU_HAVE
      );
      return text;
    },
    rftpMedicareQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithOnePlaceHolder(
        GPC.MEDCAR_IND,
        CNST_TEXT.THE_APPLICANT_HAS,
        CNST_TEXT.YOU_HAVE
      );
      return text;
    },
    rftpProviderQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.FtpRftpProviderQues();
      return text;
    }
  },
  created() {
    this.showProgressBar = true;
    let url = TES_APP_ENDPOINT.GET_TRANSIT_AGENCY_PROVIDERS;
    //@ts-ignore
    let applicantProfile =
      store.getters.applicantProfileModule.getApplicantProfile;
    let applicantCounty = applicantProfile.homeAddress.paCountyCode;
    axios
      .get(url, {
        params: { countyCode: applicantCounty, prgmCode: "RFTP" }
      })
      .then(response => {
        if (applicantProfile.gisCallInd === "Y") {
          for (let agncyPrvdr of response.data) {
            if (
              applicantProfile.gisAgenciesList.includes(
                agncyPrvdr.transitAgencyCode
              )
            ) {
              this.agencyProviders.push(agncyPrvdr);
            }
          }
        } else {
          this.agencyProviders = response.data;
        }
      })
      .catch(error => {
        store.dispatch.alertModule.showAlertMessageById(error.response.data.id);
      })
      .finally(() => {
        this.showProgressBar = false;
      });
  },
  methods: {
    getAgencyAndAres(transitAgencyCode: string) {
      let agencyArea = this.agencyProviders.find(
        obj =>
          //@ts-ignore
          obj.transitAgencyCode === transitAgencyCode
      );
      //@ts-ignore
      return agencyArea ? agencyArea.agencyAndArea : "";
    }
  }
});
