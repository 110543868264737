
import Vue from "vue";

import axios from "axios";
import { DASHBOARD_ENDPOINT, TES_APP_ENDPOINT } from "@/constants/EndPoints";
import { HTTP_STATUS } from "@/constants/HttpStatusCode";

import store from "@/store";

import {
  IApplicationStepperHeaderModel,
  IDraftProgramInfo,
  IScreeningResponse,
  IStepperHeaderList,
  IApplicantProfile
} from "@/types";
import router from "@/router";
import ProgressBar from "@/components/common/ProgressBar.vue";

import StepperNavigationMixinVue from "@/mixins/StepperNavigationMixin.vue";
import AttachmentsMixinVue from "@/mixins/AttachmentsMixin.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";

export default Vue.extend({
  name: "stepper-header",
  mixins: [
    StepperNavigationMixinVue,
    AttachmentsMixinVue,
    UIFeatureMixinVue,
    AppPropsMixin
  ],
  components: {
    ProgressBar
  },
  data() {
    return {
      selectedStep: 1 as number,
      showProgressBar: false,
      loadingIndex: 0
    };
  },
  props: {
    fromDashboard: { type: Boolean, default: false },
    reload: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.showProgressBar = true;
    if (!this.applicantProfile.applicantProfileId) {
      this.applicantProfile.applicantProfileId = Number(
        this.$route.params.applicantProfileId
      );
    }
    if (
      this.applicantProfile.applicantProfileId ||
      this.$route.query.profileID
    ) {
      this.setApplicantProfileId();
      this.setLoadingIndex();

      if (this.isSteppersNotInStore || this.reload) {
        let url = DASHBOARD_ENDPOINT.GET_PREELIG_PRGMS;

        axios
          .get(url, {
            params: {
              applicantProfileId: this.applicantProfile.applicantProfileId
            }
          })
          .then(response => {
            if (response.status === HTTP_STATUS.OK) {
              this.stepperHeaderModel.stepperHeaders = response.data;
              this.setSeptaAgencyHeader();
            }
          })
          .catch(error => {
            store.dispatch.alertModule.showAlertMessageById(
              error.response.data.id
            );
          })
          .finally(() => {
            this.initCurrentStep();
            this.loadingIndex--;

            this.checkProgressStatus();
          });
      } else {
        this.initCurrentStep();
      }

      if (this.isProgramNotInStore) {
        this.loadDraftProgramInfo();
      }
    }
  },

  computed: {
    afterSubmit(): boolean {
      //If last step is current step
      return (
        this.stepperHeaderModel.stepperHeaders[
          this.stepperHeaderModel.stepperHeaders.length - 1
        ].isCurrentStep === true
      );
    },
    stepperHeaderModel: {
      get(): IStepperHeaderList {
        //@ts-ignore
        return store.getters.stepperHeaderModule.getStepperHeaderModel;
      },
      set(newValue: IStepperHeaderList) {
        //@ts-ignore
        store.commit.stepperHeaderModule.SET_STEPPER_HEADER_MODEL(newValue);
      }
    },

    applicantProfile: {
      get(): IApplicantProfile {
        //@ts-ignore
        return store.getters.applicantProfileModule.getApplicantProfile;
      }
    },

    draftProgramInfoLocal: {
      get(): IDraftProgramInfo {
        //@ts-ignore
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      },
      set(newValue: IDraftProgramInfo) {
        //@ts-ignore
        store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(newValue);
      }
    },

    isSteppersNotInStore(): boolean {
      return (
        this.stepperHeaderModel.stepperHeaders === null ||
        this.stepperHeaderModel.stepperHeaders === undefined ||
        this.stepperHeaderModel.stepperHeaders.length === 0
      );
    },
    isProgramNotInStore(): boolean {
      return (
        this.draftProgramInfoLocal === null ||
        this.draftProgramInfoLocal === undefined ||
        this.draftProgramInfoLocal.applicantProfileId === 0
      );
    },
    programEligModel: {
      get(): any {
        //@ts-ignore
        return store.getters.programEligModule.getProgramElig;
      }
    },
    screeningResponse: {
      get(): IScreeningResponse {
        //@ts-ignore
        return store.getters.screeningResponseModule.getScreeningResponse;
      }
    }
  },

  methods: {
    setCurrentStepToFalseExceptProfile() {
      this.stepperHeaderModel.stepperHeaders.forEach((step, idx) => {
        if (idx > 0 && step.isCurrentStep) {
          step.isCurrentStep = false;
        }
      });
    },
    initCurrentStep() {
      let header: IApplicationStepperHeaderModel;
      let i = 0;
      let checkAllDocsAttached = false;
      while (i < this.stepperHeaderModel.stepperHeaders.length) {
        header = this.stepperHeaderModel.stepperHeaders[i];
        if (this.reload && header.programType === "PROFILE") {
          header.isCurrentStep = true;
          this.setCurrentStepToFalseExceptProfile();
        }
        if (header.isCurrentStep) {
          if (header.programType === "DOCS") {
            //if all are submitted...current step is review
            //@ts-ignore
            if (this.getNumberOfRequiredDocs > 0) {
              checkAllDocsAttached = true;
              store.commit.attachmentsModule.SET_DOCS_REQUIRED(
                checkAllDocsAttached
              );
            }
          }
          this.enableStepperHeader(header, i);
          break;
        }
        i++;
      }
      this.setCurrentStep(i, checkAllDocsAttached);
    },
    setSelectedStep(stepNumber: number) {
      this.selectedStep = stepNumber + 1; //Inprogress step will be highlighted
    },

    continueToApplication() {
      if (this.selectedStep <= this.stepperHeaderModel.stepperHeaders.length) {
        let currentStep = this.stepperHeaderModel.stepperHeaders[
          this.selectedStep - 1
        ];

        this.goToRoute(
          currentStep.programType,
          this.selectedStep - 1,
          currentStep.agencyCode,
          false
        );
      }
    },
    goToPreviousStep(index: number) {
      this.goToRoute(
        this.stepperHeaderModel.stepperHeaders[index].programType,
        index,
        this.stepperHeaderModel.stepperHeaders[index].agencyCode,
        true
      );
    },
    goToRoute(
      programName: string,
      index: number,
      agencyCode: string,
      changeCurrentStep: boolean
    ) {
      if (changeCurrentStep) {
        this.removeCurrentStep();

        this.stepperHeaderModel.stepperHeaders[index].isCurrentStep = true;
        if (programName !== "PROFILE") {
          this.stepperHeaderModel.stepperHeaders[0].fromPrgm = programName;
          this.stepperHeaderModel.stepperHeaders[0].fromAgency = agencyCode;
          this.stepperHeaderModel.stepperHeaders[0].fromIndex = index;
        }
      }

      if (programName === "DOCS") {
        router.push({
          path: "/application-program/" + programName,
          query: {
            profileID: String(this.applicantProfile.applicantProfileId),
            prgmType: programName
          }
        });
      } else if (programName === "PROFILE") {
        this.applicantProfile.profileEditMsg = "";
        this.applicantProfile.profileSuccessMsg = "";
        router.push({
          name: "editapp",
          query: {
            profileID: String(this.applicantProfile.applicantProfileId),
            scrnRespID: String(this.screeningResponse.screeningResponseId)
          }
        });
      } else {
        for (let preEligibleProgram of this.programEligModel
          .preEligiblePrograms) {
          if (
            preEligibleProgram["programTypeCode"] === programName &&
            preEligibleProgram["transitAgencyCode"] === agencyCode
          ) {
            //@ts-ignore
            store.commit.applicantProgramModule.SET_APPLICANT_PROGRAM(
              preEligibleProgram
            );
          }
        }
        if (
          programName === "ADA" &&
          //@ts-ignore
          this.isSecondADA()
        ) {
          programName = "ADA1";
        }
        this.$router.push({
          path: "/application-program/" + programName,
          query: {
            profileID: String(this.applicantProfile.applicantProfileId),
            prgmType: programName,
            agencyType: agencyCode
          }
        });
      }
    },
    removeCurrentStep() {
      let header: IApplicationStepperHeaderModel;
      var currentStep = null;
      for (let i = 0; i < this.stepperHeaderModel.stepperHeaders.length; i++) {
        header = this.stepperHeaderModel.stepperHeaders[i];
        if (header.isCurrentStep) {
          currentStep = i;
        }
      }
      if (currentStep) {
        this.stepperHeaderModel.stepperHeaders[
          currentStep
        ].isCurrentStep = false;
      }
    },
    loadDraftProgramInfo() {
      let url = TES_APP_ENDPOINT.GET_DRAFT_PROGRAM_INFO;
      axios
        .get(url, {
          params: {
            applicantProfileId: this.applicantProfile.applicantProfileId
          }
        })
        .then(response => {
          let draftProgramInfo = response.data;
          if (draftProgramInfo) {
            //@ts-ignore
            this.draftProgramInfoLocal = response.data;
          }
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        //@ts-ignore
        .finally(() => {
          this.loadingIndex--;
          this.checkProgressStatus();
        });
    },
    checkProgressStatus() {
      if (this.loadingIndex == 0) {
        this.showProgressBar = false;
      }
    },
    async setCurrentStep(
      currStepNumber: number,
      checkAllDocsAttached: boolean
    ) {
      if (checkAllDocsAttached) {
        //@ts-ignore
        await this.compareAllDocsAttached();
        if (store.getters.attachmentsModule.getAllDocsUploaded) {
          if (
            this.stepperHeaderModel.stepperHeaders[
              this.stepperHeaderModel.stepperHeaders.length - 2
            ].isCurrentStep === true
          ) {
            this.setSelectedStep(
              this.stepperHeaderModel.stepperHeaders.length - 2
            );
          } else {
            //all docs are attached....highlight review step
            this.setSelectedStep(
              this.stepperHeaderModel.stepperHeaders.length - 1
            );
          }
        } else {
          //not all docs attached..highlight docs step
          this.setSelectedStep(
            this.stepperHeaderModel.stepperHeaders.length - 2
          );
        }
      } else {
        this.setSelectedStep(currStepNumber);

        //@ts-ignore
        this.removeDocumentsStep();
      }
    },
    setApplicantProfileId() {
      let applicantProfileId = this.applicantProfile.applicantProfileId;
      if (!applicantProfileId) {
        //@ts-ignore
        this.applicantProfile.applicantProfileId = this.$route.query.profileID;
      }
    },
    setLoadingIndex() {
      //To load stepper headers if not present in store
      if (this.isSteppersNotInStore || this.reload) {
        this.loadingIndex++;
      }
      //to load program information if not present in store
      if (this.isProgramNotInStore) {
        this.loadingIndex++;
      }
      if (this.loadingIndex == 0) {
        this.showProgressBar = false;
      }
    },
    enableStepperHeader(header: IApplicationStepperHeaderModel, i: number) {
      if (header.programType !== "PROFILE") {
        this.stepperHeaderModel.stepperHeaders[0].fromPrgm = header.programType;
        this.stepperHeaderModel.stepperHeaders[0].fromAgency =
          header.agencyCode;
        this.stepperHeaderModel.stepperHeaders[0].fromIndex = i;
      }
    },
    setSeptaAgencyHeader() {
      let headers = this.stepperHeaderModel.stepperHeaders;
      headers.forEach(header => {
        if (header.programType === "FTP") {
          // ADD SEPTA agency to header if SEPTA question is selected as yes
          if (
            this.draftProgramInfoLocal.transitIdCardApplyInd &&
            this.draftProgramInfoLocal.transitIdCardApplyInd === "Y"
          ) {
            header.description = header.description + " and " + " SEPTA";
          }
        }
      });
    }
  }
});
