
import Vue from "vue";
import store from "@/store";

import {
  IStepperHeaderList,
  IApplicationStepperHeaderModel,
  IProgramsList,
  IDraftProgramInfo,
  IApplicantProfile
} from "@/types";
import router from "@/router";
import { HTTP_STATUS } from "@/constants/HttpStatusCode";
import axios from "axios";
import { DASHBOARD_ENDPOINT } from "@/constants/EndPoints";
import AttachmentsMixinVue from "@/mixins/AttachmentsMixin.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";

export default Vue.extend({
  mixins: [AttachmentsMixinVue, UIFeatureMixinVue, AppPropsMixin],
  data() {
    return {
      numberOfDocTypesAttached: 0
    };
  },
  methods: {
    forwardToNextStep: function() {
      var headers = this.stepperHeaderMod.stepperHeaders;

      if (this.isSingleFlow()) {
        //For single flow....when preeligible programs are present
        let header: IApplicationStepperHeaderModel;
        var nextProgram = null;
        for (let i = 0; i < headers.length; i++) {
          header = headers[i];
          if (header.isCurrentStep) {
            nextProgram = headers[i + 1].programType;
            if (nextProgram !== "REVIEW") {
              //for the REVIEW step, there is no next step
              headers[i].isCurrentStep = false;
              headers[i + 1].isCurrentStep = true;
              break;
            }
          }
        }

        if (nextProgram) {
          router.push({
            path: "/application-program/" + nextProgram
          });
        }
      } else {
        router.push({
          path:
            "/application-review/" + this.applicantProgramInfo.programTypeCode
        });
      }
    },
    isSingleFlow(): boolean {
      let preEligiblePrgmsExists = false;
      if (
        this.stepperHeaderMod.stepperHeaders &&
        this.stepperHeaderMod.stepperHeaders.length > 0
      ) {
        preEligiblePrgmsExists = true;
      }
      return preEligiblePrgmsExists;
    },
    nextProgram(programTypeCode: string, agencyCode: string) {
      let headers = this.stepperHeaderMod.stepperHeaders;
      let programType = "";
      for (let i = 0; i < headers.length; i++) {
        if (
          headers[i]["programType"] === programTypeCode &&
          (agencyCode === "" || headers[i]["agencyCode"] === agencyCode)
        ) {
          this.buildHeaderDescAndAgencyCodeForFTP(headers[i], i);
          if (i < this.programEligModel.preEligiblePrograms.length) {
            //@ts-ignore
            this.programEligModel.preEligiblePrograms[i - 1][
              "statusCode"
            ] = this.draftProgramInfo.programInfo.statusCode;
            //@ts-ignore
            this.programEligModel.preEligiblePrograms[i - 1][
              "workflowItemId"
            ] = this.draftProgramInfo.programInfo.workflowItemId;
            //@ts-ignore
            store.commit.applicantProgramModule.SET_APPLICANT_PROGRAM(
              this.programEligModel.preEligiblePrograms[i]
            );
            this.draftProgramInfo.programInfo = this.applicantProgramInfo;
            //@ts-ignore
            store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(
              this.draftProgramInfo
            );
          } else if (i === this.programEligModel.preEligiblePrograms.length) {
            //@ts-ignore
            this.programEligModel.preEligiblePrograms[i - 1][
              "statusCode"
            ] = this.draftProgramInfo.programInfo.statusCode;
            //@ts-ignore
            this.programEligModel.preEligiblePrograms[i - 1][
              "workflowItemId"
            ] = this.draftProgramInfo.programInfo.workflowItemId;
          }

          this.removeDocumentsStep();

          programType = headers[i + 1]["programType"];
          if (programTypeCode !== "REVIEW") {
            //for the REVIEW step, there is no next step
            headers[i].isCurrentStep = false;
            headers[i + 1].isCurrentStep = true;
          }
          this.stepperHeaderMod.stepperHeaders[0].fromPrgm = programType;
          this.stepperHeaderMod.stepperHeaders[0].fromAgency =
            headers[i + 1]["agencyCode"];
          this.stepperHeaderMod.stepperHeaders[0].fromIndex = i + 1;
        }
      }
      return programType;
    },
    buildHeaderDescAndAgencyCodeForFTP(
      header: IApplicationStepperHeaderModel,
      i: any
    ) {
      if (header.programType === "FTP") {
        let j = 0;
        if (
          i <=
          //@ts-ignore
          this.programEligModel.preEligiblePrograms.length
        ) {
          j = i - 1;
        } else {
          j = i;
        }
        let providerName: string;
        providerName =
          header.agencyCode !== "CPTA"
            ? //@ts-ignore
              this.programEligModel.preEligiblePrograms[j][
                "transiAgencyName"
              ].toUpperCase()
            : //@ts-ignore
              this.programEligModel.preEligiblePrograms[j]["transiAgencyName"];
        header.description =
          "APPLICATION FOR " + header.programType + " WITH " + providerName;
        header.agencyCode = this.draftProgramInfo.programInfo.transitAgencyCode;
        // ADD SEPTA agency to header if SEPTA question is selected as yes
        if (
          this.draftProgramInfo.transitIdCardApplyInd &&
          this.draftProgramInfo.transitIdCardApplyInd === "Y"
        ) {
          header.description = header.description + " and " + " SEPTA";
        }
      }
    },
    isProgramExist(programTypeCode: string) {
      let headers = this.stepperHeaderMod.stepperHeaders;
      let programExist = false;
      for (let header of headers) {
        if (header["programType"] === programTypeCode) {
          programExist = true;
        }
      }
      return programExist;
    },
    getStepperheaders() {
      if (this.applicantProgramInfo.applicantProfileId) {
        let url = DASHBOARD_ENDPOINT.GET_PREELIG_PRGMS;

        axios
          .get(url, {
            params: {
              applicantProfileId: this.applicantProgramInfo.applicantProfileId
            }
          })
          .then(response => {
            if (response.status === HTTP_STATUS.OK) {
              this.stepperHeaderMod.stepperHeaders = response.data;
              this.updateStepperHeaders();
            }
          })
          .catch(error => {
            store.dispatch.alertModule.showAlertMessageById(
              error.response.data.id
            );
          });
      }
    },
    async compareAllDocsAttached() {
      let url = DASHBOARD_ENDPOINT.GET_DOC_TYPE_NUMBER;
      await axios
        .get(url, {
          params: {
            applicantProfileId: this.applicantProfile.applicantProfileId,
            //@ts-ignore
            docTypeCodes: this.getDocTypeCodesApplied().join(",")
          }
        })
        .then(response => {
          if (response.status === HTTP_STATUS.OK) {
            this.numberOfDocTypesAttached = response.data;
            store.commit.attachmentsModule.SET_NUM_OF_DOCS_ATTCHD(
              response.data
            );
          }
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          //all docs are attached....highlight review step
          //@ts-ignore
          if (this.numberOfDocTypesAttached >= this.getNumberOfRequiredDocs) {
            store.commit.attachmentsModule.SET_ALL_DOCS_UPLOADED(true);
          } else {
            //not all docs attached..highlight docs step
            //this.setSelectedStep(this.headerSteps.length - 2);
            store.commit.attachmentsModule.SET_ALL_DOCS_UPLOADED(false);
          }

          //@ts-ignore
          this.removeDocumentsStep();
        });
    },
    removeDocumentsStep() {
      var removeStep = false;
      var headers = this.stepperHeaderMod.stepperHeaders;
      if (headers && headers.length > 0) {
        if (
          //@ts-ignore
          !this.isAttachmentsRequired &&
          headers[headers.length - 2].programType === "DOCS"
        ) {
          if (headers[headers.length - 2].isCurrentStep) {
            //If documents is current step...make review as current step before removal
            headers[headers.length - 1].isCurrentStep = true;
          }

          headers.splice(headers.length - 2, 1);
          removeStep = true;
        }
        if (
          //@ts-ignore
          this.isAttachmentsRequired &&
          headers[headers.length - 2].programType !== "DOCS"
        ) {
          let header: IApplicationStepperHeaderModel = {
            programType: "DOCS",
            index: 6,
            isCurrentStep: false,
            description: "UPLOAD ATTACHMENTS",
            agencyCode: "",
            fromPrgm: "",
            fromAgency: "",
            fromIndex: 0
          };
          headers.splice(headers.length - 1, 0, header);
        }
      }
      return removeStep;
    },
    isDocsRequired() {
      var docsRequired = false;
      var headers = this.stepperHeaderMod.stepperHeaders;
      if (headers[headers.length - 2].programType === "DOCS") {
        docsRequired = true;
      }
      return docsRequired;
    },
    isSecondADA() {
      let headers = this.stepperHeaderMod.stepperHeaders;
      let secondADA = false;
      const duplicates = headers
        .map((el, i) => {
          return headers.find((element, index) => {
            if (i !== index && element.programType === el.programType) {
              return el;
            }
          });
        })
        .filter(x => x);
      if (duplicates && duplicates.length > 1) {
        for (let i = 0; i < headers.length; i++) {
          if (
            headers[i]["isCurrentStep"] &&
            headers[i - 1]["programType"] === "ADA"
          ) {
            secondADA = true;
          }
        }
      }
      return secondADA;
    },
    isApplicantCorrectionsStatus() {
      let isApplCorrStatus = false;
      if (
        this.applicantProgramInfo.statusCode === "IN_PRGS_C" ||
        this.applicantProgramInfo.statusCode === "RDY_SBMT_C"
      ) {
        isApplCorrStatus = true;
      }
      return isApplCorrStatus;
    },
    getStepperheadersBrowserRefresh(profileID: any) {
      let url = DASHBOARD_ENDPOINT.GET_PREELIG_PRGMS;

      axios
        .get(url, {
          params: {
            applicantProfileId: profileID
          }
        })
        .then(response => {
          if (response.status === HTTP_STATUS.OK) {
            this.stepperHeaderMod.stepperHeaders = response.data;
            for (let stepHeaders of this.stepperHeaderMod.stepperHeaders) {
              if (stepHeaders["programType"] === "FTP") {
                // ADD SEPTA agency to header if SEPTA question is selected as yes
                if (
                  this.draftProgramInfo.transitIdCardApplyInd &&
                  this.draftProgramInfo.transitIdCardApplyInd === "Y"
                ) {
                  stepHeaders["description"] =
                    stepHeaders["description"] + " and " + " SEPTA";
                }
              }
            }
          }
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        });
    },
    buildHeaderForAgencyPending(programTypeCode: string) {
      let headerMsg = "";
      let headers = this.stepperHeaderMod.stepperHeaders;
      headers.forEach(header => {
        if (header.programType === programTypeCode) {
          let providerName: string;
          providerName =
            header.agencyCode !== "CPTA"
              ? this.draftProgramInfo.programInfo.transiAgencyName.toUpperCase()
              : this.draftProgramInfo.programInfo.transiAgencyName;
          header.description =
            "APPLICATION FOR " + header.programType + " WITH " + providerName;
          header.agencyCode = this.draftProgramInfo.programInfo.transitAgencyCode;

          headerMsg =
            "Application for " +
            this.applicantProgramInfo.programName +
            " with " +
            this.applicantProgramInfo.transiAgencyName;
          // ADD SEPTA agency to header if SEPTA question is selected as yes
          if (
            this.draftProgramInfo.transitIdCardApplyInd &&
            this.draftProgramInfo.transitIdCardApplyInd === "Y"
          ) {
            headerMsg = headerMsg + " and " + " SEPTA";
            header.description = header.description + " and " + " SEPTA";
          }
        }
      });
      return headerMsg;
    },
    isByPassPrgmChkd(): boolean {
      let byPassPrgmChecked = false;
      // check for byPassProgram indicator
      for (let programEligModel of this.programEligModel.preEligiblePrograms) {
        if (
          programEligModel["programTypeCode"] === "SSR" &&
          programEligModel["byPassInd"] === "Y"
        ) {
          byPassPrgmChecked = true;
        }
      }
      return byPassPrgmChecked;
    },
    updateStepperHeaders() {
      for (let i = 0; i < this.stepperHeaderMod.stepperHeaders.length; i++) {
        if (i === 1) {
          this.stepperHeaderMod.stepperHeaders[i].isCurrentStep = true;
        } else {
          this.stepperHeaderMod.stepperHeaders[i].isCurrentStep = false;
        }
        if (this.stepperHeaderMod.stepperHeaders[i].programType === "FTP") {
          // ADD SEPTA agency to header if SEPTA question is selected as yes
          if (
            this.draftProgramInfo.transitIdCardApplyInd &&
            this.draftProgramInfo.transitIdCardApplyInd === "Y"
          ) {
            this.stepperHeaderMod.stepperHeaders[i].description =
              this.stepperHeaderMod.stepperHeaders[i].description +
              " and " +
              " SEPTA";
          }
        }
      }
      router.push({
        path:
          "/application-program/" +
          this.programEligModel.preEligiblePrograms[0]["programTypeCode"],
        query: {
          profileID: String(this.applicantProgramInfo.applicantProfileId),
          prgmType: this.programEligModel.preEligiblePrograms[0][
            "programTypeCode"
          ],
          agencyType: this.programEligModel.preEligiblePrograms[0][
            "transitAgencyCode"
          ]
        }
      });
    }
  },
  computed: {
    stepperHeaderMod: {
      get(): IStepperHeaderList {
        //@ts-ignore
        return store.getters.stepperHeaderModule.getStepperHeaderModel;
      },
      set(newValue: IStepperHeaderList) {
        //@ts-ignore
        store.commit.stepperHeaderModule.SET_STEPPER_HEADER_MODEL(newValue);
      }
    },
    applicantProgramInfo: {
      get(): IProgramsList {
        //@ts-ignore
        return store.getters.applicantProgramModule.getApplicantProgram;
      }
    },
    programEligModel: {
      get(): any {
        //@ts-ignore
        return store.getters.programEligModule.getProgramElig;
      }
    },
    applicantProfile: {
      get(): IApplicantProfile {
        //@ts-ignore
        return store.getters.applicantProfileModule.getApplicantProfile;
      }
    },
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        //@ts-ignore
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      }
    }
  }
});
