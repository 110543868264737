
import Vue from "vue";
import axios from "axios";
import store from "@/store";
import { TES_APP_ENDPOINT } from "@/constants/EndPoints";
import { ICodeDescription, IDraftProgramInfo } from "@/types";
import ProgressBar from "@/components/common/ProgressBar.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import ProgramInfoSummaryMixin from "@/mixins/ProgramInfoSummaryMixin.vue";
import readonlyField from "@/components/common/ReadOnlyField.vue";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import { GPC } from "@/constants/GPCCode";
import DynamicTextMixin from "@/mixins/DynamicTextMixin.vue";
import { CNST_TEXT } from "@/constants/CommonConstants";

export default Vue.extend({
  name: "FTP-Program-Information",
  mixins: [
    UIFeatureMixinVue,
    ProgramInfoSummaryMixin,
    AppPropsMixin,
    DynamicTextMixin
  ],
  components: { ProgressBar, readonlyField },
  data() {
    return {
      agencyProviders: [] as Object[],
      isSeptaPresent: false,
      septaOptions: [] as ICodeDescription[],
      showProgressBar: false
    };
  },
  computed: {
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        //@ts-ignore
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      }
    },
    ftpApplyIndQues(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrgAgyCtznonBehalfOfUser()
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.FTP_INFO].largeText,
          CNST_TEXT.DOES_THE_APPLICANT,
          CNST_TEXT.THEIR,
          CNST_TEXT.THEY,
          CNST_TEXT.THEM
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.FTP_INFO].largeText,
          CNST_TEXT.DO_YOU,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOU,
          CNST_TEXT.YOU
        );
      }
      return text;
    },
    septaFixedRouteQues(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrgAgyCtznonBehalfOfUser()
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.FTP_SEPT_Q].largeText,
          CNST_TEXT.DOES_THE_APPLICANT
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.FTP_SEPT_Q].largeText,
          CNST_TEXT.DO_YOU
        );
      }
      return text;
    },
    rftpProviderQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.FtpRftpProviderQues();
      return text;
    }
  },
  created() {
    this.showProgressBar = true;
    let url = TES_APP_ENDPOINT.GET_TRANSIT_AGENCY_PROVIDERS;
    //@ts-ignore
    let applicantProfile =
      store.getters.applicantProfileModule.getApplicantProfile;
    let applicantCounty = applicantProfile.homeAddress.paCountyCode;
    axios
      .get(url, {
        params: { countyCode: applicantCounty, prgmCode: "FTP" }
      })
      .then(response => {
        if (applicantProfile.gisCallInd === "Y") {
          for (let agncyPrvdr of response.data) {
            if (
              applicantProfile.gisAgenciesList.includes(
                agncyPrvdr.transitAgencyCode
              )
            ) {
              this.agencyProviders.push(agncyPrvdr);
            }
          }
        } else {
          this.agencyProviders = response.data;
        }
        this.populateSeptaOptions();
      })
      .catch(error => {
        store.dispatch.alertModule.showAlertMessageById(error.response.data.id);
      })
      .finally(() => {
        this.showProgressBar = false;
      });
  },
  methods: {
    populateSeptaOptions() {
      if (this.agencyProviders && this.agencyProviders.length == 1) {
        //@ts-ignore
        this.draftProgramInfo.ftpAgencyCode = this.agencyProviders[0].transitAgencyCode;
      } else if (this.agencyProviders) {
        this.isSeptaPresent = this.agencyProviders
          // @ts-ignore
          .map(p => p.transitAgencyCode)
          .includes("SEPTA");
      }
    },
    getAgencyAndAres(transitAgencyCode: string) {
      let agencyArea = this.agencyProviders.find(
        obj =>
          //@ts-ignore
          obj.transitAgencyCode === transitAgencyCode
      );
      //@ts-ignore
      return agencyArea ? agencyArea.agencyAndArea : "";
    }
  }
});
