
import axios from "axios";
import Vue from "vue";
import { TES_APP_ENDPOINT } from "@/constants/EndPoints";
import router from "@/router";
import store from "@/store";
import ProgressBar from "@/components/common/ProgressBar.vue";
import routerMixin from "@/mixins/RouterMixin.vue";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";

import {
  IApplicationReviewModel,
  IDraftProgramInfo,
  IProgramsList,
  IStepperHeaderList,
  ITESApplicationModel,
  ITESUser,
  IApplicantProfile
} from "@/types";
export default Vue.extend({
  name: "Result-Application-Information",
  mixins: [routerMixin, ApplicationMixin, AppPropsMixin, UIFeatureMixinVue],
  components: {
    ProgressBar
  },
  data() {
    return {
      showProgressBar: false,
      tesApplicationModel: {} as ITESApplicationModel,
      viewWithdrawDailog: false
    };
  },
  computed: {
    applicationReviewModel: {
      get(): IApplicationReviewModel {
        //@ts-ignore
        return store.getters.applicationReviewModule.getApplicationReviewModel;
      }
    },
    applicantProfile: {
      get(): IApplicantProfile {
        //@ts-ignore
        return store.getters.applicantProfileModule.getApplicantProfile;
      }
    },
    applicantProgramInfo: {
      get(): IProgramsList {
        //@ts-ignore
        return store.getters.applicantProgramModule.getApplicantProgram;
      }
    },
    stepperHeaderMod: {
      get(): IStepperHeaderList {
        //@ts-ignore
        return store.getters.stepperHeaderModule.getStepperHeaderModel;
      },
      set(newValue: IStepperHeaderList) {
        //@ts-ignore
        store.commit.stepperHeaderModule.SET_STEPPER_HEADER_MODEL(newValue);
      }
    },
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        //@ts-ignore
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      }
    },
    userInfo: {
      get(): ITESUser {
        //@ts-ignore
        return store.getters.tesUserModule.getTesUser;
      }
    },
    isAppEditable(): boolean {
      let userView: boolean = false;
      if (this.applicantProgramInfo.statusCode === "RE_COR") {
        if (
          //@ts-ignore
          (this.isAgencyUser &&
            this.applicantProfile.applicantUserId === this.userInfo.userId) ||
          //@ts-ignore
          !this.isAgencyUser
        ) {
          userView = true;
        }
      }
      return userView;
    }
  },
  methods: {
    editApplication() {
      this.showProgressBar = true;
      let that: any = this;
      let url = TES_APP_ENDPOINT.EDIT_APPLICATION;

      this.draftProgramInfo.userInfo = this.userInfo;

      axios
        .post(url, this.draftProgramInfo)
        .then(response => {
          this.tesApplicationModel = response.data;
          //@ts-ignore
          store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(
            this.tesApplicationModel.draftProgramInfoModel
          );

          //@ts-ignore
          store.commit.applicantProgramModule.SET_APPLICANT_PROGRAM(
            this.tesApplicationModel.programEligibilityModel
          );

          //@ts-ignore
          store.commit.applicantProfileModule.SET_APPLICANT_PROFILE(
            this.tesApplicationModel.applicantProfileModel
          );
          //@ts-ignore
          store.commit.programEligModule.SET_PROG_ELIG(
            response.data.programEligModel
          );
          //@ts-ignore
          store.commit.stepperHeaderModule.SET_STEPPER_HEADER_MODEL(
            this.tesApplicationModel.stepperHeaders
          );
          //@ts-ignore
          this.stepperHeaderMod.stepperHeaders = this.tesApplicationModel.stepperHeaders;
          if (
            this.stepperHeaderMod.stepperHeaders &&
            this.stepperHeaderMod.stepperHeaders.length > 0
          ) {
            if (this.tesApplicationModel.statusCode === "IN_PRGS_C") {
              router.push({ name: "editapp" });
            } else {
              that.showAlertError("Please contact IT Help desk");
            }
          }
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    },
    withdrawApplicationDailog() {
      this.viewWithdrawDailog = true;
    },
    withdrawApp() {
      this.showProgressBar = true;
      this.applicantProgramInfo.statusCode = "WITHDRAWN";
      //@ts-ignore
      this.withdrawApplication(this.applicantProgramInfo)
        //@ts-ignore
        .then(response => {
          //@ts-ignore
          store.commit.applicantProgramModule.SET_APPLICANT_PROGRAM(
            response.data
          );
        })
        //@ts-ignore
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
          //@ts-ignore
          this.forwardToDashboard();
        });
    },
    closeViewWithdrawDailog() {
      this.viewWithdrawDailog = false;
    }
  }
});
