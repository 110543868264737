import { ITESUser } from "@/types";

import { tesUserDefault } from "@/data/AppData";
import { clone } from "@/util/clone";
import { moduleActionContext } from "..";
import { defineModule } from "direct-vuex";
import { DASHBOARD_ENDPOINT } from "@/constants/EndPoints";
import axios from "axios";

export interface TesUserState {
  tesUser: ITESUser;
}

const tesUserModule = defineModule({
  namespaced: true,
  state: {
    tesUser: clone(tesUserDefault)
  } as TesUserState,
  getters: {
    getTesUser: (state: TesUserState) => state.tesUser
  },
  mutations: {
    SET_TES_USER(state: TesUserState, newTesUser: ITESUser) {
      state.tesUser = newTesUser;
    }
  },
  actions: {
    resetData(context: any) {
      let { commit } = tesUserModuleContext(context);
      //clearing the tesUser data in store.
      let tesUser = clone(tesUserDefault);
      //@ts-ignore
      commit.SET_TES_USER(tesUser);
    },
    async getUserDetails(context: any) {
      let { commit, rootDispatch } = tesUserModuleContext(context);
      await axios
        .get(DASHBOARD_ENDPOINT.GET_USER_INFO)
        .then(response => {
          commit.SET_TES_USER(response.data);
        })
        .catch(() => {
          rootDispatch.alertModule.showAlertMessageByGPC("ER_INLINE");
        });
    }
  }
});

export default tesUserModule;
const tesUserModuleContext = (context: any) =>
  moduleActionContext(context, tesUserModule);
