import { applicantDetailsDefault } from "@/data/AppData";
import { clone } from "@/util/clone";

import { IApplicantDetails } from "@/types";
import { defineModule } from "direct-vuex";
import { moduleActionContext } from "..";

export interface ApplicantDetailsState {
  applicantDetails: IApplicantDetails;
}

const applicantDetailsModule = defineModule({
  namespaced: true,
  state: {
    applicantDetails: clone(applicantDetailsDefault)
  } as ApplicantDetailsState,
  getters: {
    getApplicantDetails: (state: ApplicantDetailsState) =>
      state.applicantDetails
  },
  mutations: {
    SET_APPLICANT_DETAILS(
      state: ApplicantDetailsState,
      newApplicantDetails: IApplicantDetails
    ) {
      state.applicantDetails = newApplicantDetails;
    }
  },
  actions: {
    resetData(context: any) {
      let { commit } = applicantDetailsModuleActionContext(context);
      //clearing the applicantProfile data in store.
      let applicantdetails = clone(applicantDetailsDefault);
      //@ts-ignore
      commit.SET_APPLICANT_DETAILS(applicantdetails);
    }
  }
});

export default applicantDetailsModule;
const applicantDetailsModuleActionContext = (context: any) =>
  moduleActionContext(context, applicantDetailsModule);
