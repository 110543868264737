import { moduleActionContext } from "..";
import { defineModule } from "direct-vuex";
import { UI_ENDPOINT } from "@/constants/EndPoints";
import axios from "axios";
import { GPC } from "@/constants/GPCCode";
import { clone } from "@/util/clone";
import { uiFeatureDefaultModel } from "@/data/AppData";

export interface UIFeatureState {
  uiFeatureMapObj: Object;
  isGPCDataLoading: false;
}

export interface UIObj {
  [key: string]: object;
}

const uiFeatureModule = defineModule({
  namespaced: true,
  state: {
    uiFeatureMapObj: {} as Object,
    isGPCDataLoading: false
  } as UIFeatureState,
  getters: {
    uiFeature: (state: UIFeatureState) => state.uiFeatureMapObj,
    getLoadingStatus: (state: UIFeatureState) => {
      return state.isGPCDataLoading;
    }
  },
  mutations: {
    SET_UIFEATURE(state: UIFeatureState, newUIFeature: Object) {
      state.uiFeatureMapObj = { ...state.uiFeatureMapObj, ...newUIFeature };
    },
    SET_DATA_LDNG_STS(state: UIFeatureState, payload: any) {
      state.isGPCDataLoading = payload;
    }
  },
  actions: {
    resetData(context: any) {
      let { dispatch } = uiFeatureModuleContext(context);
      dispatch.populateUIFeatureMapDefaults();
    },
    populateUIFeatureMapDefaults(context: any) {
      let { commit } = uiFeatureModuleContext(context);

      let uiFeatureTemp: UIObj = {};

      Object.values(GPC).forEach(gpcCode => {
        uiFeatureTemp[gpcCode] = clone(uiFeatureDefaultModel);
      });

      commit.SET_UIFEATURE(uiFeatureTemp);
    },
    getUIFeatures(context: any, uiFeatureArray: any) {
      let { commit, rootDispatch } = uiFeatureModuleContext(context);
      let url = UI_ENDPOINT.GET_UI_FEATURES;
      commit.SET_DATA_LDNG_STS(true);
      axios
        .get(url, {
          params: { uiFeatureCodes: uiFeatureArray }
        })
        .then(response => {
          commit.SET_UIFEATURE(response.data);
        })
        .catch(() => {
          rootDispatch.alertModule.showAlertMessageByGPC("ER_INLINE");
        })
        .finally(() => {
          commit.SET_DATA_LDNG_STS(false);
        });
    }
  }
});

export default uiFeatureModule;
const uiFeatureModuleContext = (context: any) =>
  moduleActionContext(context, uiFeatureModule);
