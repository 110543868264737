
import Vue from "vue";
import editProfileProgramChangesMsg from "@/components/application/EditProfileProgramChangesMsg.vue";
import StepperHeader from "@/components/common/StepperHeader.vue";
import EditAppWithoutSteppersNav from "@/components/application/EditAppWithoutSteppersNav.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import { IApplicantProfile, ITESUser } from "@/types";
import store from "@/store";
import ApplicationResult from "@/components/application/program/ApplicationResult.vue";
import ProgressBar from "@/components/common/ProgressBar.vue";

export default Vue.extend({
  name: "edit-app",
  mixins: [UIFeatureMixinVue],
  data() {
    return {
      showComponent: false,
      showProgressBar: false,
      reloadStepper: false,
      renderComponent: true
    };
  },
  components: {
    editProfileProgramChangesMsg,
    EditAppWithoutSteppersNav,
    StepperHeader,
    ApplicationResult,
    ProgressBar
  },
  async created() {
    if (this.applicantProfile.applicantProfileId === 0) {
      this.showProgressBar = true;
      if (
        this.userInfo.userId === null ||
        this.userInfo.userId === undefined ||
        this.userInfo.userId === 0
      ) {
        await store.dispatch.tesUserModule.getUserDetails();
      }
      let profileID = this.$route.query.profileID;
      let scrnRespID = this.$route.query.scrnRespID;
      await store.dispatch.applicantProfileModule.getProfileInfo(profileID);
      await store.dispatch.screeningResponseModule.populateScreenResponse(
        scrnRespID
      );
      this.showComponent = true;
      this.showProgressBar = false;
    } else {
      this.showComponent = true;
    }
    let uiFeatureArray =
      "LANGUAGES,RACE,MOBL_TYPES,GENDER,STATE,RADIO_YN,DATE_FUT,DATE_FOR,PH_NOTZERO,NO_COUNTY2,CNTY_MAP,NO_THIS,PRSNL_INFO,HMEAD_INFO,MAIL_INFO,EMERG_INFO,DEMOG_INFO,TRNS_INFO1,TRNS_INFO2,TRANS_ASST,MOBIL_INFO,MOBL_INFO2,POD_AGNCYS,ADDR_TEXT,ADDR_APT,C_COD_AGNY,VLD_DOCEXT,QUICK_SRCH,CHGLGN_TXT,ER_INLINE,DL_HELPTXT,FMR,ENVRONMNT,WTDRAW_MSG,,DMGPH_QNS,PRMRY_LNG,LNG_INTRPR,RCE_ETNCTY,NRG_HME,DCRG_NG_HM,PSNL_CR_HM,PNL_CHM_TP,TSPN_INF_Q,PSNL_C_ADT,CUR_MN_TRP,MOB_INF_QS,LFT_EN_VEH,ANY_MOB_DV,AL_MBD_LBL,CR_MN_VAN,WCHR_MET_Q,EMERG_NOTE,ST_COLLG_Q,MORE_INFO,WHY_TXT,PFILE_QUES,IS_MAIL_AD,GIS_CNFM_1,GIS_CNFM_2,ADR_CH_HDR,CHNGE_MSG1,CHNGE_MSG2,REL_TO_APL,ACCBL_FRMT,ADIO_CASS,BRAILLE,LRGE_PRNT,ACCBL_OTHR,ACBL_PRT_Q,ACBL_INFO,TRNSP_WAYS,BUS_TRLY,SUB_TRAIN,AMBLANC,AUTOMOBL,WALKING,TAXI_SERVC,TRNSP_OTHR";

    store.dispatch.uiFeatureModule.getUIFeatures(uiFeatureArray);
  },
  mounted() {
    let el: HTMLElement | null = null;
    el = document.getElementById("editProfileHeading");
    if (el) {
      el.focus();
    }
  },
  computed: {
    applicantProfile: {
      get(): IApplicantProfile {
        //@ts-ignore
        return store.getters.applicantProfileModule.getApplicantProfile;
      }
    },
    userInfo: {
      get(): ITESUser {
        //@ts-ignore
        return store.getters.tesUserModule.getTesUser;
      }
    }
  },
  methods: {
    saveClicked(reload: boolean) {
      this.reloadStepper = reload;
      // Remove my-component from the DOM
      this.renderComponent = false;
      this.showComponent = false;

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
        this.showComponent = true;
      });
    }
  }
});
