
import Vue from "vue";
import PhysicianInformation from "./PhysicianInformation.vue";
import {
  IDraftPhysicianInfo,
  IDraftProgramInfo,
  IUIFeatureObjectModel
} from "@/types";
import { tclone } from "@/util/clone";
import { physicianDetailsDefault } from "@/data/AppData";
import TesContainer from "@/components/common/TESContainer.vue";
import store from "@/store";
import tesControlTemplate from "@/components/common/TESControlTemplate.vue";
import tesRadioControl from "@/components/common/TESRadioControl.vue";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import { GPC } from "@/constants/GPCCode";
import { ALERT_TYPE } from "@/constants/CommonWebCodes";
import DraftProgramMixin from "@/mixins/DraftProgramMixin.vue";
import StepperNavigationMixin from "@/mixins/StepperNavigationMixin.vue";
import { HTTP_STATUS } from "@/constants/HttpStatusCode";
import { BUTTON_NAMES, CNST_TEXT } from "@/constants/CommonConstants";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";
import UIFeatureMixin from "@/mixins/UIFeatureMixin.vue";
import ScrollMixin from "@/mixins/ScrollMixin.vue";

export default Vue.extend({
  name: "physician-info-list",
  components: {
    PhysicianInformation,
    TesContainer,
    tesControlTemplate,
    tesRadioControl
  },
  mixins: [
    fieldEntryMixin,
    DraftProgramMixin,
    StepperNavigationMixin,
    ApplicationMixin,
    UIFeatureMixin,
    ScrollMixin
  ],
  props: {
    stepperIndex: { type: Number, default: 0 },
    stepCount: { type: Number, default: 0 }
  },
  data: () => {
    return {
      physicianInfos: [] as IDraftPhysicianInfo[],
      additionalPhysicianQues: [""],
      MAX_PHYSICIAN_ENTRIES: 2,
      SAVE: BUTTON_NAMES.SAVE,
      SAVE_CONTINUE: BUTTON_NAMES.SAVECONTINUE,
      CANCEL: BUTTON_NAMES.CANCEL,
      CONTINUE: BUTTON_NAMES.CONTINUE
    };
  },
  computed: {
    draftProgramInfo: () =>
      store.getters.draftProgramInfoModule.getDraftProgramInfo,
    ordinals: () =>
      (store.getters.uiFeatureModule.uiFeature as Record<
        string,
        IUIFeatureObjectModel
      >)[GPC.ORDINALS].children.map(c => c.largeText),
    additionalPhysQues: () => {
      const quesGpc = (store.getters.uiFeatureModule.uiFeature as Record<
        string,
        IUIFeatureObjectModel
      >)[GPC.ADD_PHY_QU];
      if (quesGpc) {
        return quesGpc.largeText;
      } else {
        return "";
      }
    },
    helpText(): string {
      let text: string = "";
      const helpGpc = (store.getters.uiFeatureModule.uiFeature as Record<
        string,
        IUIFeatureObjectModel
      >)[GPC.PHY_HLPTXT];
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          helpGpc.largeText,
          CNST_TEXT.THE_APPLICANTS,
          CNST_TEXT.THEIR,
          CNST_TEXT.THEIR,
          CNST_TEXT.THE_APPLICANTS,
          CNST_TEXT.THE_APPLICANTS
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          helpGpc.largeText,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOUR
        );
      }
      return text;
    }
  },
  methods: {
    addPhysicianInfo() {
      const newPhysInf = tclone(physicianDetailsDefault);
      newPhysInf.tesApplicationId = this.draftProgramInfo.applicationId;
      newPhysInf.applicantProfileId = this.draftProgramInfo.applicantProfileId;
      newPhysInf.cycleNumber = this.draftProgramInfo.cycleNumber;
      this.physicianInfos.push(newPhysInf);
    },
    handleMorePhysicianInfoQuesChange(index: number) {
      if (this.additionalPhysicianQues[index] == "Y") {
        this.addPhysicianInfo();
        this.additionalPhysicianQues.push("");
      } else {
        this.physicianInfos = this.physicianInfos.slice(0, index + 1);
        this.additionalPhysicianQues = this.additionalPhysicianQues.slice(
          0,
          index + 1
        );
      }
    },
    async savePhysicianDetails(button: string) {
      try {
        const form = (this.$refs.physInfoForm as unknown) as {
          validate: () => boolean;
        };
        if (form.validate()) {
          this.physicianInfos.forEach(p => {
            if (!p.applicantProfileId || p.applicantProfileId < 1) {
              p.applicantProfileId = this.draftProgramInfo.applicantProfileId;
            }
          });
          const dpInfo = tclone(this.draftProgramInfo);
          dpInfo.draftPhysicianInfos = this.physicianInfos;
          dpInfo.multiplePhysicianInd = this.additionalPhysicianQues[0];
          // @ts-ignore
          const response: AxiosResponse<IDraftProgramInfo> = await this.saveProgramInfo(
            dpInfo,
            this.stepperIndex,
            this.stepCount,
            button
          );
          if (response.status == HTTP_STATUS.OK) {
            store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(
              response.data
            );
            this.$emit("saved", button);
          } else {
            await store.dispatch.alertModule.showAlertMessage({
              message:
                "An error occurred while communicating with the service.",
              alertType: ALERT_TYPE.WARNING
            });
          }
        } else {
          //@ts-ignore
          this.scrollToErrorField(this.$refs.physInfoForm);
        }
      } catch (e) {
        store.dispatch.alertModule.showAlertMessage({
          alertType: ALERT_TYPE.ERROR,
          message: "An error occurred while validating:  " + e
        });
      }
    }
  },
  mounted() {
    const dpi = store.getters.draftProgramInfoModule.getDraftProgramInfo;
    if (dpi && dpi.draftPhysicianInfos) {
      this.physicianInfos = [...tclone(dpi.draftPhysicianInfos)];
    } else {
      this.physicianInfos = [tclone(physicianDetailsDefault)];
    }
    for (let i = 0; i < this.physicianInfos.length - 2; i++) {
      this.additionalPhysicianQues[i] = dpi.multiplePhysicianInd;
    }
    this.additionalPhysicianQues[this.additionalPhysicianQues.length - 1] = "N";
    const gpc = store.getters.uiFeatureModule.uiFeature as Record<
      string,
      IUIFeatureObjectModel
    >;
    if (gpc[GPC.MAX_PHYS] && gpc[GPC.MAX_PHYS].displayName) {
      this.MAX_PHYSICIAN_ENTRIES = Number.parseInt(
        gpc[GPC.MAX_PHYS].displayName
      );
    }
  }
});
