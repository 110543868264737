import { IISAMUserAttributes } from "@/types";
import { isamUserAttributesDefault } from "@/data/AppData";
import { clone } from "@/util/clone";
import { moduleActionContext } from "..";
import { defineModule } from "direct-vuex";
import { TES_APP_ENDPOINT } from "@/constants/EndPoints";
import axios from "axios";

export interface ISAMUserAttributesState {
  isamUserAttributes: IISAMUserAttributes;
}

const isamUserAttributesModule = defineModule({
  namespaced: true,
  state: {
    isamUserAttributes: clone(isamUserAttributesDefault)
  } as ISAMUserAttributesState,
  getters: {
    getISAMUserAttributes: (state: ISAMUserAttributesState) =>
      state.isamUserAttributes
  },
  mutations: {
    SET_ISAM_USERATTRIBUTES(
      state: ISAMUserAttributesState,
      isamUserAttributesObject: IISAMUserAttributes
    ) {
      state.isamUserAttributes = isamUserAttributesObject;
    }
  },
  actions: {
    resetData(context: any) {
      let { commit } = isamUserAttributesContext(context);
      //clearing the isamUserAttributes data in store.
      let isamUserAttributes = clone(isamUserAttributesDefault);
      //@ts-ignore
      commit.SET_ISAM_USERATTRIBUTES(isamUserAttributes);
    },
    getISAMUserAttributes(context: any, createdByLoginId: any) {
      let { commit, rootDispatch } = isamUserAttributesContext(context);
      let url = TES_APP_ENDPOINT.ISAM_GET_USERATTRIBUTES;
      axios
        .get(url, {
          params: {
            userLoginId: createdByLoginId
          }
        })
        .then(response => {
          let userAttributes = response.data;
          if (userAttributes) {
            commit.SET_ISAM_USERATTRIBUTES(response.data);
          }
        })
        .catch(() => {
          rootDispatch.alertModule.showAlertMessageByGPC("ER_INLINE");
        });
    },
    async getISAMUserAttributesByUserId(context: any, createdByUserId: number) {
      let { commit, rootDispatch } = isamUserAttributesContext(context);
      let url = TES_APP_ENDPOINT.ISAM_GET_USERATTRIBUTES_BYID;
      await axios
        .get(url, {
          params: {
            userId: createdByUserId
          }
        })
        .then(response => {
          commit.SET_ISAM_USERATTRIBUTES(response.data);
        })
        .catch(() => {
          rootDispatch.alertModule.showAlertMessageByGPC("ER_INLINE");
        });
    }
  }
});

export default isamUserAttributesModule;
const isamUserAttributesContext = (context: any) =>
  moduleActionContext(context, isamUserAttributesModule);
