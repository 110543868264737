
import Vue from "vue";
import store from "@/store";
import { IDraftProgramInfo, IScreeningResponse } from "@/types";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import ProgramInfoSummaryMixin from "@/mixins/ProgramInfoSummaryMixin.vue";
import readonlyField from "@/components/common/ReadOnlyField.vue";
import ApplicantProfileMixin from "@/mixins/ApplicantProfileMixin.vue";
import { CNST_TEXT } from "@/constants/CommonConstants";
import { GPC } from "@/constants/GPCCode";

export default Vue.extend({
  name: "medical-assistance-appl-review",
  components: { readonlyField },
  mixins: [
    UIFeatureMixinVue,
    fieldEntryMixin,
    ProgramInfoSummaryMixin,
    ApplicantProfileMixin
  ],
  data() {
    return {};
  },
  computed: {
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        //@ts-ignore
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      }
    },
    medicaidCardRecipientNumMasked(): string {
      return `#${this.draftProgramInfo.medicaidCardRecipientNum}`;
    },
    prevMedicaidCardRecipientNumMasked(): string {
      //@ts-ignore
      return `#${this.programInfoPrevious.medicaidCardRecipientNum}`;
    },
    screeningResponse(): IScreeningResponse {
      return store.getters.screeningResponseModule.getScreeningResponse;
    },
    authorizationDeclineMsg(): string {
      let declineText: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.screeningResponse.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        declineText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.AUTH_DECLN].largeText,
          CNST_TEXT.THE_APPLICANTS
        );
      } else {
        //@ts-ignore
        declineText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.AUTH_DECLN].largeText,
          CNST_TEXT.YOUR
        );
      }
      return declineText;
    }
  }
});
