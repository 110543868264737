
import Vue from "vue";
import ApplicantProfileMixin from "@/mixins/ApplicantProfileMixin.vue";
import store from "@/store";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import RouterMixinVue from "@/mixins/RouterMixin.vue";
import {
  IApplicationReviewModel,
  IApplicantEcolaneProfileModel,
  IProgramsList,
  ITESUser,
  IApplicantProfile,
  IISAMUserAttributes,
  IScreeningResponse
} from "@/types";
import { TES_APP_ENDPOINT } from "@/constants/EndPoints";
import axios from "axios";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import tesControlTemplate from "@/components/common/TESControlTemplate.vue";
import dateHelperMixin from "@/mixins/dateHelperMixin.vue";

export default Vue.extend({
  name: "ecolaneLink",
  components: { tesControlTemplate },
  mixins: [
    ApplicantProfileMixin,
    UIFeatureMixinVue,
    RouterMixinVue,
    AppPropsMixin,
    dateHelperMixin
  ],
  data() {
    return {
      showProgressBar: false
    };
  },
  computed: {
    applicationReviewModel: {
      get(): IApplicationReviewModel {
        //@ts-ignore
        return store.getters.applicationReviewModule.getApplicationReviewModel;
      }
    },
    userInfo: {
      get(): ITESUser {
        //@ts-ignore
        return store.getters.tesUserModule.getTesUser;
      }
    },
    applicantProgramInfo: {
      get(): IProgramsList {
        //@ts-ignore
        return store.getters.applicantProgramModule.getApplicantProgram;
      }
    },
    applicantProfileInfo(): IApplicantProfile {
      return store.getters.applicantProfileModule.getApplicantProfile;
    },
    isEcolaneCustomerLoading(): boolean {
      return store.getters.applicantEcolaneProfileModule
        .getIsEcolaneCustomerLoaded;
    },
    isEcolaneCustomerDataLoaded(): boolean {
      return store.getters.applicantEcolaneProfileModule
        .getIsEcolaneCustomerDataLoaded;
    },
    selectedEcolaneCustomer(): IApplicantEcolaneProfileModel {
      return store.getters.applicantEcolaneProfileModule
        .getApplicantEcolaneProfile;
    },
    isamUserAttributes: {
      get(): IISAMUserAttributes {
        return store.getters.isamUserAttributesModule.getISAMUserAttributes;
      }
    },
    screeningResponse: {
      get(): IScreeningResponse {
        return store.getters.screeningResponseModule.getScreeningResponse;
      }
    }
  },
  methods: {
    saveReview() {
      //need to make axios call to save application review
      let url = TES_APP_ENDPOINT.UPDATE_ECOLANE_CONFIG;
      //@ts-ignore
      this.applicationReviewModel.ecolaneConfiguredBy =
        this.userInfo.userFirstName + " " + this.userInfo.userLastName;
      this.applicationReviewModel.reviewerUserId = this.userInfo.userId;
      this.applicationReviewModel.programInfo = this.applicantProgramInfo;
      this.applicationReviewModel.applicantProfile = this.applicantProfileInfo;
      let isamUserAttributesJson = JSON.parse(
        JSON.stringify(this.isamUserAttributes)
      );
      if (
        (this.screeningResponse.onBehalfOfInd === "Y" ||
          this.applicantProfileInfo.onBehalfOfInd === "Y") &&
        isamUserAttributesJson &&
        isamUserAttributesJson.attributeDetails
      ) {
        this.applicationReviewModel.applicantProfile.applicantEmailAddress =
          isamUserAttributesJson.attributeDetails["EMAIL"];
      }
      if (this.applicationReviewModel.reviewResultCode === "TM_APR_PCF") {
        this.applicationReviewModel.reviewResultCode = "TMP_APROVE";
      } else {
        this.applicationReviewModel.reviewResultCode = "APPROVE";
      }
      axios
        .post(url, this.applicationReviewModel)
        .then(response => {
          store.commit.applicationReviewModule.SET_APPLICATION_REVIEW_MODEL(
            response.data
          );
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.returnToDashboard();
        });
    },
    returnToDashboard() {
      store.dispatch.applicationReviewModule.resetData();
      //@ts-ignore
      this.forwardToDashboard();
    }
  }
});
