
import Vue from "vue";
import ApplicantProfileMixin from "@/mixins/ApplicantProfileMixin.vue";
import readonlyField from "@/components/common/ReadOnlyField.vue";
import { GPC } from "@/constants/GPCCode";
import DynamicTextMixin from "@/mixins/DynamicTextMixin.vue";
import { CNST_TEXT } from "@/constants/CommonConstants";

export default Vue.extend({
  name: "review-mobility-info",
  components: { readonlyField },
  mixins: [ApplicantProfileMixin, DynamicTextMixin],
  computed: {
    mobilityDevicesFlat(): string {
      return this.concatStringArray(
        //@ts-ignore
        this.applicantProfileInfo.applicantMobilityDevices.mobilityDeviceCodes
      );
    },
    mobilityDevicesPreviousFlat(): string {
      return this.concatStringArray(
        //@ts-ignore
        this.applicantProfileInfoPrevious.applicantMobilityDevices
          .mobilityDeviceCodes
      );
    },
    leftEnabledVehicleQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithOnePlaceHolderVal(
        GPC.LFT_EN_VEH,
        CNST_TEXT.DOES_THE_APPLICANT,
        CNST_TEXT.DO_YOU
      );
      return text;
    },
    haveAnyMobilityDevicesQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithOnePlaceHolderVal(
        GPC.ANY_MOB_DV,
        CNST_TEXT.DOES_THE_APPLICANT,
        CNST_TEXT.DO_YOU
      );
      return text;
    },
    selectMobilityDevicesQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithTwoPlaceHolder(
        GPC.AL_MBD_LBL,
        CNST_TEXT.THE_APPLICANT,
        CNST_TEXT.USES,
        CNST_TEXT.YOU,
        CNST_TEXT.USE
      );
      return text;
    },
    withWithoutAssistQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithOnePlaceHolderVal(
        GPC.CR_MN_VAN,
        CNST_TEXT.THE_APPLICANT,
        CNST_TEXT.YOU
      );
      return text;
    },
    wheelChairWeightQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithOnePlaceHolder(
        GPC.WCHR_MET_Q,
        CNST_TEXT.THE_APPLICANTS,
        CNST_TEXT.YOUR
      );
      return text;
    }
  },
  methods: {
    concatStringArray(stringArray: string[]): string {
      let flatString: string = "";

      if (stringArray.length === 1) {
        flatString = stringArray[0];
      } else if (stringArray.length > 1) {
        flatString = stringArray.reduce((first, second) => {
          return first.concat("," + second);
        });
      }

      return flatString;
    }
  }
});
