import { ICodeDescription } from "@/types";

export const MOBILITY_CODEVALUE: ICodeDescription[] = [
  { code: "MWC", description: "Manual Wheel Chair" },
  { code: "MC", description: "Motorized Scooter" },
  { code: "WLK", description: "Walker" },
  { code: "CRT", description: "Crutches" },
  { code: "OC", description: "Oxygen Cane" },
  { code: "PWC", description: "Power Wheel Chair" },
  { code: "CNE", description: "Cane" },
  { code: "GD", description: "Guide Dog" },
  { code: "OTH", description: "Other:" }
];

export const YES_NO_RADIOS: ICodeDescription[] = [
  { code: "Y", description: "Yes" },
  { code: "N", description: "No" }
];
