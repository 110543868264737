
import Vue from "vue";
import store from "@/store";
import { IDraftProgramInfo } from "@/types";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import ProgramInfoSummaryMixin from "@/mixins/ProgramInfoSummaryMixin.vue";
import readonlyField from "@/components/common/ReadOnlyField.vue";
import DynamicTextMixin from "@/mixins/DynamicTextMixin.vue";
import { GPC } from "@/constants/GPCCode";
import { CNST_TEXT } from "@/constants/CommonConstants";

export default Vue.extend({
  name: "PWD-Program-Information",
  mixins: [UIFeatureMixinVue, ProgramInfoSummaryMixin, DynamicTextMixin],
  components: {
    readonlyField
  },
  computed: {
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      }
    },
    disabilityConfirmQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithOnePlaceHolder(
        GPC.DIS_CNFM,
        CNST_TEXT.THE_APPLICANT_HAS,
        CNST_TEXT.YOU_HAVE
      );
      return text;
    }
  }
});
