import { IAppProperties } from "@/types";
import { appPropertiesDefault } from "@/data/AppData";
import { clone } from "@/util/clone";
import { defineModule } from "direct-vuex";
import { moduleActionContext } from "..";

export interface AppState {
  appProperties: IAppProperties;
  showMainHeader: true;
  dobMatch: string;
}

const appModule = defineModule({
  namespaced: true,
  state: {
    appProperties: clone(appPropertiesDefault),
    showMainHeader: true,
    dobMatch: ""
  } as AppState,
  getters: {
    getAppProperties: (state: AppState) => state.appProperties,
    getShowMainHeader: (state: AppState) => {
      return state.showMainHeader;
    },
    getDobMatch: (state: AppState) => state.dobMatch
  },
  mutations: {
    SET_APP_PROPERTIES(state: AppState, newAppProperties: IAppProperties) {
      state.appProperties = newAppProperties;
    },
    SET_SHOW_MAIN_HEADER(state: AppState, payload: any) {
      state.showMainHeader = payload;
    },
    SET_DOB_MATCH(state: AppState, payload: string) {
      state.dobMatch = payload;
    }
  }
});

export default appModule;
const appModuleContext = (context: any) =>
  moduleActionContext(context, appModule);
