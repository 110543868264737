
import Vue from "vue";
export default Vue.extend({
  name: "progress-bar-template",
  data: function() {
    return {};
  },
  props: {
    dialog: { type: Boolean, default: true }
  }
});
