import { IProgramsList } from "@/types";
import { applicantProgramDefault } from "@/data/AppData";
import { clone } from "@/util/clone";
import { moduleActionContext } from "..";
import { defineModule } from "direct-vuex";

export interface ApplicantProgramState {
  applicantProgram: IProgramsList;
}

const applicantProgramModule = defineModule({
  namespaced: true,
  state: {
    applicantProgram: clone(applicantProgramDefault)
  } as ApplicantProgramState,
  getters: {
    getApplicantProgram: (state: ApplicantProgramState) =>
      state.applicantProgram
  },
  mutations: {
    SET_APPLICANT_PROGRAM(
      state: ApplicantProgramState,
      applicantProgramObject: IProgramsList
    ) {
      state.applicantProgram = applicantProgramObject;
    }
  },
  actions: {
    resetData(context: any) {
      let { commit } = applicantProgramContext(context);
      //clearing the applicantProgram data in store.
      let applicantProgram = clone(applicantProgramDefault);
      //@ts-ignore
      commit.SET_APPLICANT_PROGRAM(applicantProgram);
    }
  }
});

export default applicantProgramModule;
const applicantProgramContext = (context: any) =>
  moduleActionContext(context, applicantProgramModule);
