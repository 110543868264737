
import Vue from "vue";
import axios from "axios";
import store from "@/store";
import ProgressBar from "@/components/common/ProgressBar.vue";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import TESRadioControl from "@/components/common/TESRadioControl.vue";
import { TES_APP_ENDPOINT } from "@/constants/EndPoints";
import {
  IDraftProgramInfo,
  IProgramsList,
  IApplicationNavigation
} from "@/types";
import tesContainer from "@/components/common/TESContainer.vue";
import draftProgramMixin from "@/mixins/DraftProgramMixin.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";

export default Vue.extend({
  name: "matp-assisted-living",
  mixins: [
    fieldEntryMixin,
    draftProgramMixin,
    UIFeatureMixinVue,
    AppPropsMixin
  ],
  components: { tesContainer, TESRadioControl, ProgressBar },
  data() {
    return {
      nursingHomeInd: "" as string,
      dischargeInd: "" as string,
      personalHomeCareInd: "" as string,
      transportCostInd: "" as string,
      showProgressBar: false
    };
  },
  computed: {
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        //@ts-ignore
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      },
      set(newValue: IDraftProgramInfo) {
        //@ts-ignore
        store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(newValue);
      }
    },
    applicantProgramInfo: {
      get(): IProgramsList {
        //@ts-ignore
        return store.getters.applicantProgramModule.getApplicantProgram;
      }
    },
    applicationNavigation: {
      get(): IApplicationNavigation {
        //@ts-ignore
        return store.getters.applicationNavigationModule
          .getApplicationNavigation;
      }
    }
  },
  methods: {
    save() {
      //@ts-ignore
      if (this.$refs.formAssistedLiving.validate()) {
        this.saveDraftProgramInfo();
      }
    },
    saveDraftProgramInfo() {
      this.showProgressBar = true;
      let url = TES_APP_ENDPOINT.SAVE_DRAFT_PRGM_INFO;
      this.draftProgramInfo.programInfo = this.applicantProgramInfo;
      //@ts-ignore
      this.draftProgramInfo.programStatus = this.uiFeature["RDY_SBMT"][
        "displayName"
      ];
      //@ts-ignore
      this.draftProgramInfo.userInfo = this.userInfo;

      axios
        .post(url, this.draftProgramInfo)
        .then(response => {
          //@ts-ignore
          this.draftProgramInfo = response.data;
          //@ts-ignore
          store.commit.applicantProgramModule.SET_APPLICANT_PROGRAM(
            this.draftProgramInfo.programInfo
          );
          this.applicationNavigation.applProfileEdit = true;
          //@ts-ignore
          store.commit.applicationNavigationModule.SET_APPLLICATION_NAVIGATION(
            this.applicationNavigation
          );
          this.$emit("save-completed");
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    }
  }
});
