
import Vue from "vue";
import routerMixin from "@/mixins/RouterMixin.vue";
import CancelDialog from "@/components/application/CancelDialog.vue";
import TESContainerQueHint from "@/components/common/TESContainerQueHint.vue";
import ExpansionPanelTemplate from "@/components/common/ExpansionPanelTemplate.vue";
import StepperNavigationMixinVue from "@/mixins/StepperNavigationMixin.vue";

export default Vue.extend({
  name: "tes-container",
  mixins: [routerMixin, StepperNavigationMixinVue],
  components: {
    CancelDialog,
    TESContainerQueHint,
    ExpansionPanelTemplate
  },
  data() {
    return {
      showCancelDialog: false,
      iconTextLabel: {
        showText: "More Details",
        hideText: "Less Details"
      }
    };
  },
  props: {
    mainHeader: { type: String, default: "" },
    subHeader: { type: String, default: "" },
    questionHintHeader: { type: String, default: "" },
    questionHint: { type: String, default: "" },
    secondSubsectionHeader: { type: String, default: "" },
    secondSubsection: { type: String, default: "" },
    thirdSubsectionHeader: { type: String, default: "" },
    thirdSubsection: { type: String, default: "" },
    fourthSubsectionHeader: { type: String, default: "" },
    fourthSubsection: { type: String, default: "" },
    disabledSave: { type: Boolean, default: false },
    showDefaultButtons: { type: Boolean, default: true },
    hideButtons: { type: Boolean, default: false },
    matpAuthButtons: { type: Boolean, default: false },
    showRequiredLegend: { type: Boolean, default: false },
    isFinalStep: { type: Boolean, default: false },
    isSingleFlowFinalStep: { type: Boolean, default: false },
    showMATPLink: {
      type: Boolean,
      default: false
    },
    byPassPrgmCheck: { type: Boolean, default: false },
    programName: { type: String, default: "" },
    paCountyCode: { type: String, default: "" }
  },
  methods: {
    confirmCancel() {
      this.showCancelDialog = true;
    },
    saveProgramForCancel() {
      this.$emit("cancel-clicked");
      this.showCancelDialog = false;
    },
    continueToDashboard() {
      //@ts-ignore
      this.forwardToDashboard();
    }
  }
});
