<template>
  <v-container pa-0 py-1 py-md-0 pa-md-1 class="uploadSection">
    <v-alert dense outlined text type="error" v-if="showInvalidExtMsg">{{
      uiFeature.INVALD_DOC.largeText
    }}</v-alert>
    <v-alert dense outlined text type="error" v-if="showFileSizeExcMsg">{{
      uiFeature.INVALD_DOC.displayName
    }}</v-alert>
    <div :class="fileClass" class="mt-3">
      <!-- LABEL -->
      <div v-if="!$vuetify.breakpoint.smAndDown">
        <p class="dropbox-label-ie" v-if="$browserDetect.isIE">
          Click to browse file(s)
        </p>
        <p class="dropbox-label" v-else>
          Drop Files here
          <br />or
          <br />
          <v-btn tabindex="-1" aria-hidden="true" tile class="primaryButton">
            Browse
          </v-btn>
        </p>
      </div>
      <!-- FILE INPUT -->
      <form enctype="multipart/form-data" novalidate autocomplete="off">
        <v-btn
          tabindex="-1"
          aria-hidden="true"
          v-if="$vuetify.breakpoint.smAndDown"
          class="primaryButton browseButton"
          text
        >
          Browse
        </v-btn>
        <input
          type="file"
          multiple
          :name="uploadFieldName"
          @change="
            filesChange($event.target.name, $event.target.files);
            fileCount = $event.target.files.length;
          "
          :accept="acceptedFileExtensions"
          class="input-file"
          ref="fileInput"
          :aria-label="ariaLabel"
          @dragenter="dragOver = true"
          @dragleave="dragOver = false"
          @focus="isFileUploadFocused = true"
          @blur="isFileUploadFocused = false"
        />
      </form>
    </div>

    <!-- FILE TO UPLOAD LIST -->
    <v-slide-y-transition>
      <v-card class="fileupload" elevation="0" v-show="showUploadDialog">
        <v-form ref="formDocUploader" v-model="validDocUploader">
          <v-list
            style="padding: 0px !important;"
            :class="
              $vuetify.breakpoint.smAndDown
                ? 'documentList'
                : 'documentListWide'
            "
            two-line
            v-for="(doc, idx) in docsToUpload"
            :key="idx"
          >
            <DocUploadRow
              :hideDocDescription="hideDocDescription"
              :doc="doc"
              :showHeaders="idx === 0"
              :index="idx"
              style="background: white;"
              @removeDoc="removeDoc(idx)"
              :docDescCodes="docDescCodes"
            />
          </v-list>
        </v-form>
        <v-card-actions class="uploadSection pa-0">
          <v-btn
            tile
            class="primaryButton"
            @click="uploadDocuments()"
            :disabled="isUploading"
            :loading="isUploading"
            >Upload</v-btn
          >
          <v-btn tile class="mx-2 secondaryButton" @click="cancelUpload()"
            >Cancel</v-btn
          >
          <v-fade-transition>
            <div v-show="!validDocUploader && triedToUpload" class="red--text">
              Please clear all errors.
            </div>
          </v-fade-transition>
        </v-card-actions>
      </v-card>
    </v-slide-y-transition>
  </v-container>
</template>

<script>
import axios from "axios";
import store from "@/store";
import DocUploadRow from "@/components/common/DocumentUploadRow";
import { ECS_ENDPOINT } from "@/constants/EndPoints";
import fieldEntryMixinVue from "@/mixins/fieldEntryMixin.vue";
import StepperNavigationMixinVue from "@/mixins/StepperNavigationMixin.vue";

export default {
  name: "DocUploader",
  components: { DocUploadRow },
  mixins: [fieldEntryMixinVue, StepperNavigationMixinVue],
  data() {
    return {
      docsToUpload: [],
      validDocUploader: false,
      uploadFieldName: "file",
      showUploadDialog: false,
      triedToUpload: false,
      showInvalidExtMsg: false,
      showFileSizeExcMsg: false,
      dragOver: false,
      isFileUploadFocused: false
    };
  },
  props: {
    docsMaxNumber: { type: Number, default: 0 },
    isEcs: { type: Boolean, default: false },
    hideDocDescription: { type: Boolean, default: false },
    resetAttachment: { type: Boolean, default: false },
    documentTypeCode: { type: String, default: null },
    docDescCodes: {
      type: Array,
      default: null
    },
    ariaLabel: { type: String, default: "" }
  },
  watch: {
    resetAttachment(val) {
      if (val) {
        this.reset();
      }
    }
  },
  computed: {
    isUploading() {
      let status = this.docsToUpload.map(d => {
        return d.uploadStatus;
      });

      return status.indexOf("uploading") !== -1;
    },
    draftProgramInfo() {
      return store.getters.draftProgramInfoModule.getDraftProgramInfo;
    },
    userInfo() {
      return store.getters.tesUserModule.getTesUser;
    },
    applicantProfile() {
      return store.getters.applicantProfileModule.getApplicantProfile;
    },
    applicantProgramInfo() {
      return store.getters.applicantProgramModule.getApplicantProgram;
    },
    fileClass() {
      let baseClass;
      if (this.showUploadDialog && this.$vuetify.breakpoint.smAndDown) {
        baseClass = "buttonContainer dropbox1";
        return this.dragOver ? `${baseClass} dragover` : baseClass;
      } else if (this.isFileUploadFocused) {
        baseClass = "buttonContainer dropbox focusFileUploader";
        return baseClass;
      } else {
        baseClass = "buttonContainer dropbox";
        return this.dragOver ? `${baseClass} dragover` : baseClass;
      }
    }
  },
  methods: {
    removeDoc(index) {
      this.docsToUpload.splice(index, 1);
      this.showUploadDialog = this.docsToUpload.length > 0;
    },
    cancelUpload() {
      this.showUploadDialog = false;
      this.triedToUpload = false;
      this.reset();
      this.$refs.formDocUploader.resetValidation();
    },
    mounted() {
      this.reset();
    },
    reset() {
      this.docsToUpload = [];
      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = null;
      }
    },
    save(formData) {
      this.upload(formData);
    },
    uploadDocuments() {
      if (this.$refs.formDocUploader.validate()) {
        this.uploadDocs(this.docsToUpload, this.docsMaxNumber);
        this.triedToUpload = false;
      } else {
        this.showUploadDialog = true;
        this.triedToUpload = true;
      }
    },
    uploadDocs(fileUploadObject, count) {
      fileUploadObject.forEach(f => {
        if (f.uploadStatus !== "uploaded") {
          f.uploadStatus = "uploading";
          this.upload(f.formData, f.metaData, ++count)
            .then(response => {
              f.uploadStatus = "uploaded";
              this.$emit("uploaded");
              this.cancelUpload();
              this.$emit("docErrorMessage", false);
            })
            .catch(error => {
              this.cancelUpload();
              this.$store.dispatch("showAlertMessage", {
                message:
                  "Error uploading file. Be sure file name does not exceed 100 characters.",
                error: error
              });
              f.uploadStatus = "failed";
              this.$emit("docErrorMessage", true);
            })
            .finally(() => {
              this.compareAllDocsAttached();
              this.showInvalidExtMsg = false;
            });
        }
      });
    },
    async upload(formData, metaData, docNumber) {
      let url = ECS_ENDPOINT.UPLOAD_DOCUMENT;
      formData.append(
        "json",
        new Blob([JSON.stringify(metaData)], { type: "application/json" })
      );

      await axios.post(url, formData);
    },
    filesChange(fieldName, fileList) {
      this.dragOver = false;

      if (!fileList.length) return;

      let validExtensionArray = this.uiFeature.FILE_EXT.largeText.split(",");
      this.showInvalidExtMsg = false;
      this.showFileSizeExcMsg = false;

      Array.from(fileList).forEach(file => {
        let extension = file.name.split(".").pop();
        if (!validExtensionArray.includes(extension)) {
          this.showInvalidExtMsg = true;
        }
        if (file.size > 52428800) {
          this.showFileSizeExcMsg = true;
        }
      });
      if (this.showInvalidExtMsg || this.showFileSizeExcMsg) return;

      let fileUploadObject = this.createFileUploadObject(fieldName, fileList);

      this.$refs.formDocUploader.resetValidation();
      this.validDocUploader = true;

      this.docsToUpload.push(...fileUploadObject);

      this.uploadDocuments();
    },
    createFileUploadObject(fieldName, fileList) {
      let tempFileUploadObject = {
        uploadStatus: "new",
        formData: new FormData(),
        metaData: {
          userId: this.userInfo.userId,
          userName:
            this.userInfo.userFirstName + " " + this.userInfo.userLastName,
          applicantProfileId: this.applicantProfile.applicantProfileId,
          version: 1,
          documentTypeCode: this.documentTypeCode,
          description: "",
          status: "",
          filename: "",
          mimeType: "",
          parentAttachmentId: null,
          applicationId: this.applicantProgramInfo.applicationId,
          cycleNumber: this.applicantProgramInfo.cycleNumber
        }
      };

      let fileUploadObjects = [];
      fileUploadObjects = Array.from(Array(fileList.length).keys()).map(x => {
        let fileUploadObject = JSON.parse(JSON.stringify(tempFileUploadObject));

        let filename = fileList[x].name;
        fileUploadObject.metaData.filename = filename;

        let fileExtenstion = filename.substr(filename.lastIndexOf(".") + 1);

        fileUploadObject.metaData.mimeType = fileExtenstion;

        let formData = new FormData();
        formData.append(fieldName, fileList[x], filename);
        fileUploadObject.formData = formData;

        return fileUploadObject;
      });

      return fileUploadObjects;
    }
  }
};
</script>

<style lang="scss" scoped>
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  height: 160px;
  position: relative;
  cursor: pointer;
  top: -12px;
}

@media #{map-get($display-breakpoints, sm-and-down)} {
  .dropbox {
    outline: 0px !important; /* the dash box */
    height: 100% !important;
    position: relative;
    cursor: pointer;
  }
  .dropbox1 {
    outline: 0px !important; /* the dash box */
    position: relative;
    cursor: pointer;
  }
  .input-file {
    opacity: 0;
    display: inline-block;
    width: 100%;
    height: 100% !important;
    cursor: pointer;
    position: absolute;
    top: 0% !important;
    left: 0% !important;
  }
  .browseButton {
    width: 100%;
  }
}

.uploadSection {
  background: white;
  color: dimgray;
}

.dropbox-label {
  text-align: center;
  position: absolute;
  top: 23px;
  width: 100%;
}
.dropbox-label-ie {
  text-align: center;
  padding-top: 22px;
}
.input-file {
  opacity: 0;
  display: inline-block;
  width: 100%;
  height: 160px;
  cursor: pointer;
}

.dragover {
  outline: 3px dashed grey !important;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue;
}
.focusFileUploader {
  outline: 1px dotted #000 !important;
  outline: -webkit-focus-ring-color auto 5px !important;
}
.fileupload {
  background: lightcyan;
  margin-top: 5px;
  width: 100%;
}
.buttonContainer {
  min-width: 198px;
  width: 100%;
  display: inline-block;
  text-align: center;
}
</style>
