
import Vue from "vue";
import store from "@/store";
import { IDraftProgramInfo, IProgramsList } from "@/types";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import ProgramInfoSummaryMixin from "@/mixins/ProgramInfoSummaryMixin.vue";
import readonlyField from "@/components/common/ReadOnlyField.vue";
import { GPC } from "@/constants/GPCCode";
import DynamicTextMixin from "@/mixins/DynamicTextMixin.vue";
import { CNST_TEXT } from "@/constants/CommonConstants";

export default Vue.extend({
  name: "SSR-General-Information",
  mixins: [UIFeatureMixinVue, ProgramInfoSummaryMixin, DynamicTextMixin],
  components: { readonlyField },
  props: {
    programPage: { type: String, default: "" },
    applicantProgramInfoObj: {
      type: Object as () => IProgramsList
    }
  },
  data() {
    return {
      byPassTxtHeader: { type: String, default: "" },
      byPassTxtBdy: { type: String, default: "" }
    };
  },
  computed: {
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        //@ts-ignore
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      }
    },
    driverLicense(): string {
      let driverLicense = `Drivers License or PA ID #: ${this.draftProgramInfo.driverLicenseNumber}`;

      if (this.draftProgramInfo.ageVerificationMethodCode === "N") {
        //@ts-ignore
        driverLicense = this.uiFeature.DL_INVALID.largeText;
      }

      return driverLicense;
    },
    driverLicensePrevious(): string {
      //@ts-ignore
      let driverLicensePrevious = this.programInfoPrevious.driverLicenseNumber;

      //@ts-ignore
      if (this.programInfoPrevious.ageVerificationMethodCode === "N") {
        //@ts-ignore
        driverLicensePrevious = this.uiFeature.DL_INVALID.largeText;
      }

      return driverLicensePrevious;
    },
    verifyAgeDsplyNameQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithOnePlaceHolderVal(
        GPC.VRFY_AGE_Q,
        CNST_TEXT.THE_APPLICANTS,
        CNST_TEXT.YOUR
      );
      return text;
    }
  },
  created() {
    //@ts-ignore
    this.byPassTxtHeader = this.uiFeature.BY_PAS_TXT.displayName.replace(
      "{prgmName}",
      this.applicantProgramInfoObj.programName
    );
    //@ts-ignore
    this.byPassTxtBdy = this.uiFeature.BY_PAS_SUM.displayName.replace(
      "{prgmName}",
      this.applicantProgramInfoObj.programName
    );
  }
});
