
import Vue from "vue";

import ProgressBar from "@/components/common/ProgressBar.vue";
import { IAdvacnedSearch, ITESApplicationModel, ITESUser } from "@/types";
import store from "@/store";
import { TES_APP_ENDPOINT } from "@/constants/EndPoints";
import axios from "axios";
import router from "@/router";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import DateHelperMixinVue from "@/mixins/dateHelperMixin.vue";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";

export default Vue.extend({
  name: "advanced-search",
  mixins: [
    UIFeatureMixinVue,
    DateHelperMixinVue,
    AppPropsMixin,
    ApplicationMixin
  ],
  components: {
    ProgressBar
  },
  data() {
    return {
      showProgressBar: false,
      headers: [
        {
          text: "Applicant Name",
          value: "applicantFullName"
        },
        { text: "Application ID", value: "tesApplicationId" },
        { text: "DOB", value: "applicantDOB" },
        { text: "Status", value: "statusCode" },
        { text: "Status Date", value: "statusDate" },
        { text: "Program", value: "programName" },
        { text: "Agency", value: "transitAgencyName" },
        { text: "County", value: "countyName" },
        { text: "Created By", value: "createdByName" }
      ],
      eventLogs: [],
      tesApplicationModel: {} as ITESApplicationModel
    };
  },

  computed: {
    computedHeaders(): { text: string; value: string }[] {
      if (
        //@ts-ignore
        !this.isAgencyUser
      ) {
        return this.headers.filter(header => header.text !== "Created By");
      }
      return this.headers;
    },
    advancedSearch: {
      get(): IAdvacnedSearch {
        //@ts-ignore
        return store.getters.advancedSearchModule.getAdvancedSearch;
      },
      set(newValue: IAdvacnedSearch) {
        //@ts-ignore
        store.commit.advancedSearchModule.SET_ADVANCED_SEARCH(newValue);
      }
    },

    tooManyResults(): boolean {
      return this.advancedSearch.advancedSearchResults.length > 1000;
    },
    noResults(): boolean {
      return this.advancedSearch.advancedSearchResults.length == 0;
    },
    displayResults(): boolean {
      return (
        this.advancedSearch.advancedSearchResults.length <= 1000 &&
        this.advancedSearch.advancedSearchResults.length > 0
      );
    },
    userInfo(): ITESUser {
      return store.getters.tesUserModule.getTesUser;
    }
  },
  methods: {
    forwardSubmittedApplicationToSummary(applicationId: string) {
      let url = TES_APP_ENDPOINT.GET_COMPLETE_PROFILE_DETAILS_BY_APP_ID;
      axios
        .get(url, {
          params: { tesApplicationId: applicationId }
        })
        .then(response => {
          this.tesApplicationModel = response.data;
          if (response && response.data) {
            store.commit.appModule.SET_DOB_MATCH(response.data.nameDOBMatchInd);
          }
          this.forwardToApplicationSummary(this.tesApplicationModel);
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    },
    async forwardToApplicationSummary(tesAppModel: ITESApplicationModel) {
      //@ts-ignore
      store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(
        tesAppModel.draftProgramInfoModel
      );

      //@ts-ignore
      store.commit.applicantProgramModule.SET_APPLICANT_PROGRAM(
        tesAppModel.programEligibilityModel
      );

      //@ts-ignore
      store.commit.applicantProfileModule.SET_APPLICANT_PROFILE(
        tesAppModel.applicantProfileModel
      );
      //Get the Driver license details
      store.dispatch.applicantProfileModule.getDriverLicenseDetails(
        //@ts-ignore
        store.getters.draftProgramInfoModule.getDraftProgramInfo
      );

      if (tesAppModel.applicantionId) {
        await store.dispatch.applicationReviewModule.loadApplicationReviewModel(
          {
            tesApplicationId: tesAppModel.applicantionId,
            cycleNumber: tesAppModel.cycleNumber
          }
        );
      }

      // route to Application review page
      router.push({
        name: "application-review",
        //@ts-ignore
        params: { program: tesAppModel.programTypeCode }
      });
    },
    forwardBeforeSubmittedApplicationToSummary(
      applicantProfileId: string,
      programTypeCode: string,
      transitAgencyCode: string
    ) {
      let url = TES_APP_ENDPOINT.GET_COMPLETE_PROFILE_DETAILS_BY_APP_PROFILE_ID;
      axios
        .get(url, {
          params: {
            applicantProfileId: applicantProfileId,
            programTypeCode: programTypeCode,
            transitAgencyCode: transitAgencyCode
          }
        })
        .then(response => {
          this.tesApplicationModel = response.data;

          this.tesApplicationModel.programTypeCode = programTypeCode;

          this.forwardToApplicationSummary(this.tesApplicationModel);
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    },
    forwardToOrgUserDashboard(applicantProfileId: string) {
      router.push({
        name: "dashboardOrgUser",
        query: {
          applicantProfileId: applicantProfileId,
          screeningResponseId: ""
        }
      });
    },
    search(
      applicationId: string,
      applicantProfileId: string,
      programTypeCode: string,
      transitAgencyCode: string
    ) {
      this.showProgressBar = true;
      if (
        applicationId !== null &&
        applicationId !== undefined &&
        applicationId.length !== 0
      ) {
        this.forwardSubmittedApplicationToSummary(applicationId);
      } else {
        if (
          applicantProfileId !== null &&
          applicantProfileId !== undefined &&
          applicantProfileId.length !== 0
        ) {
          this.forwardBeforeSubmittedApplicationToSummary(
            applicantProfileId,
            programTypeCode,
            transitAgencyCode
          );
        }
      }
    },
    searchApplctn(
      applicationId: string,
      applicantProfileId: string,
      programTypeCode: string,
      transitAgencyCode: string,
      transitAgencyName: string,
      createdByName: string
    ) {
      if (
        applicationId === null &&
        applicantProfileId !== null &&
        applicantProfileId !== undefined &&
        applicantProfileId.length !== 0 &&
        transitAgencyName != createdByName
      ) {
        this.showProgressBar = true;
        this.forwardBeforeSubmittedApplicationToSummary(
          applicantProfileId,
          programTypeCode,
          transitAgencyCode
        );
      }
    }
  }
});
