<template>
  <v-container pa-1 class="uploadSection">
    <v-alert dense outlined text type="error" v-if="showInvalidExtMsg">{{
      uiFeature.INVALD_DOC.largeText
    }}</v-alert>
    <v-alert dense outlined text type="error" v-if="showFileSizeExcMsg">{{
      uiFeature.INVALD_DOC.displayName
    }}</v-alert>
    <div :class="fileClass">
      <!-- LABEL -->
      <p class="dropbox-label-ie" v-if="$browserDetect.isIE">
        Click to browse file(s)
      </p>
      <p class="dropbox-label" v-else>
        Drag your file(s) here to
        <br />begin or click to browse
      </p>

      <!-- FILE INPUT -->
      <form enctype="multipart/form-data" novalidate>
        <input
          type="file"
          multiple
          :name="uploadFieldName"
          @change="
            filesChange($event.target.name, $event.target.files);
            fileCount = $event.target.files.length;
          "
          :accept="acceptedFileExtensions"
          class="input-file"
          ref="fileInput"
          aria-label="Document Uploader"
          @dragenter="dragOver = true"
          @dragleave="dragOver = false"
        />
      </form>
    </div>
    <!-- FILE TO UPLOAD LIST -->
    <v-slide-y-transition>
      <v-card class="fileupload" elevation="0" v-show="showUploadDialog">
        <v-form ref="formDocUploader" v-model="validDocUploader">
          <v-list
            style="padding: 0px !important;"
            :class="
              $vuetify.breakpoint.smAndDown
                ? 'documentList'
                : 'documentListWide'
            "
            two-line
            v-for="(doc, idx) in docsToUpload"
            :key="idx"
          >
            <ReviewerDocumentUploadRow
              :hideDocDescription="hideDocDescription"
              :doc="doc"
              :showHeaders="idx === 0"
              :index="idx"
              style="background: lightcyan;"
              @removeDoc="removeDoc(idx)"
              :docDescCodes="docDescCodes"
            />
          </v-list>
        </v-form>
        <v-card-actions class="uploadSection mt-4">
          <v-spacer></v-spacer>
          <v-btn
            tile
            class="primaryButton"
            @click="uploadDocuments()"
            :disabled="isUploading"
            :loading="isUploading"
            >Upload</v-btn
          >
          <v-btn tile class="ml-2 secondaryButton" @click="cancelUpload()"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-slide-y-transition>
  </v-container>
</template>

<script>
import axios from "axios";
import store from "@/store";
import ReviewerDocumentUploadRow from "@/components/common/ReviewerDocumentUploadRow";
import { ECS_ENDPOINT } from "@/constants/EndPoints";
import fieldEntryMixinVue from "@/mixins/fieldEntryMixin.vue";

export default {
  name: "Reviewer-DocUploader",
  components: { ReviewerDocumentUploadRow },
  mixins: [fieldEntryMixinVue],
  data() {
    return {
      docsToUpload: [],
      validDocUploader: false,
      uploadFieldName: "file",
      showUploadDialog: false,
      triedToUpload: false,
      showInvalidExtMsg: false,
      showFileSizeExcMsg: false,
      dragOver: false
    };
  },
  props: {
    docsMaxNumber: { type: Number, default: 0 },
    isEcs: { type: Boolean, default: false },
    hideDocDescription: { type: Boolean, default: false },
    resetAttachment: { type: Boolean, default: false },
    documentTypeCode: { type: String, default: null },
    docDescCodes: {
      type: Array,
      default: null
    }
  },
  computed: {
    isUploading() {
      let status = this.docsToUpload.map(d => {
        return d.uploadStatus;
      });

      return status.indexOf("uploading") !== -1;
    },
    draftProgramInfo() {
      return store.getters.draftProgramInfoModule.getDraftProgramInfo;
    },
    userInfo() {
      return store.getters.tesUserModule.getTesUser;
    },
    applicantProfile() {
      return store.getters.applicantProfileStore.getApplicantProfile;
    },
    applicantProgramInfo: {
      get() {
        //@ts-ignore
        return store.getters.applicantProgramModule.getApplicantProgram;
      }
    },
    applicationReviewModel: {
      get() {
        //@ts-ignore
        return store.getters.applicationReviewModule.getApplicationReviewModel;
      }
    },
    fileClass() {
      let baseClass = "buttonContainer dropbox";

      return this.dragOver ? `${baseClass} dragover` : baseClass;
    }
  },

  methods: {
    removeDoc(index) {
      this.docsToUpload.splice(index, 1);
      this.showUploadDialog = this.docsToUpload.length > 0;
    },
    cancelUpload() {
      this.showUploadDialog = false;
      this.triedToUpload = false;
      this.reset();
      this.$refs.formDocUploader.resetValidation();
    },
    mounted() {
      this.reset();
    },
    reset() {
      this.docsToUpload = [];
      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = null;
      }
    },
    save(formData) {
      this.upload(formData);
    },
    uploadDocuments() {
      if (this.$refs.formDocUploader.validate()) {
        this.uploadDocs(this.docsToUpload, this.docsMaxNumber);
        this.triedToUpload = false;
      } else {
        this.triedToUpload = true;
      }
    },
    uploadDocs(fileUploadObject, count) {
      fileUploadObject.forEach(f => {
        if (f.uploadStatus !== "uploaded") {
          f.uploadStatus = "uploading";
          this.upload(f.formData, f.metaData, ++count)
            .then(response => {
              f.uploadStatus = "uploaded";
              this.$emit("uploaded");
              this.cancelUpload();
              this.$emit("docErrorMessage", false);
            })
            .catch(error => {
              this.$store.dispatch("showAlertMessage", {
                message:
                  "Error uploading file. Be sure file name does not exceed 100 characters.",
                error: error
              });
              f.uploadStatus = "failed";
              this.$emit("docErrorMessage", true);
            });
        }
      });
    },
    async upload(formData, metaData, docNumber) {
      let url = ECS_ENDPOINT.UPLOAD_DOCUMENT;
      formData.append(
        "json",
        new Blob([JSON.stringify(metaData)], { type: "application/json" })
      );

      await axios.post(url, formData);
    },
    filesChange(fieldName, fileList) {
      this.dragOver = false;

      if (!fileList.length) return;
      let validExtensionArray = this.uiFeature.FILE_EXT.largeText.split(",");
      this.showInvalidExtMsg = false;
      this.showFileSizeExcMsg = false;
      Array.from(fileList).forEach(file => {
        let extension = file.name.split(".").pop();
        if (!validExtensionArray.includes(extension)) {
          this.showInvalidExtMsg = true;
        }
        if (file.size > 52428800) {
          this.showFileSizeExcMsg = true;
        }
      });
      if (this.showInvalidExtMsg || this.showFileSizeExcMsg) return;

      let fileUploadObject = this.createFileUploadObject(fieldName, fileList);

      this.$refs.formDocUploader.resetValidation();
      this.validDocUploader = true;

      this.docsToUpload.push(...fileUploadObject);

      this.showUploadDialog = true;
    },
    createFileUploadObject(fieldName, fileList) {
      let tempFileUploadObject = {
        uploadStatus: "new",
        formData: new FormData(),
        metaData: {
          userId: this.userInfo.userId,
          userName:
            this.userInfo.userFirstName + " " + this.userInfo.userLastName,
          applicantProfileId: this.applicantProgramInfo.applicantProfileId,
          version: 1,
          documentTypeCode: this.documentTypeCode,
          description: "",
          status: "",
          filename: "",
          mimeType: "",
          parentAttachmentId: null,
          transitAgencyCode: this.applicantProgramInfo.transitAgencyCode,
          applicationReviewId: this.applicationReviewModel.applicationReviewId
        }
      };

      let fileUploadObjects = [];
      fileUploadObjects = Array.from(Array(fileList.length).keys()).map(x => {
        let fileUploadObject = JSON.parse(JSON.stringify(tempFileUploadObject));

        let filename = fileList[x].name;
        fileUploadObject.metaData.filename = filename;

        let fileExtenstion = filename.substr(filename.lastIndexOf(".") + 1);

        fileUploadObject.metaData.mimeType = fileExtenstion;

        let formData = new FormData();
        formData.append(fieldName, fileList[x], filename);
        fileUploadObject.formData = formData;

        return fileUploadObject;
      });

      return fileUploadObjects;
    }
  },
  watch: {
    resetAttachment(val) {
      if (val) {
        this.reset();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  height: 105px;
  position: relative;
  cursor: pointer;
  top: -12px;
  margin-bottom: -22px;
}
.uploadSection {
  background: lightcyan;
  color: dimgray;
}

.dropbox-label {
  text-align: center;
  position: absolute;
  top: 23px;
  width: 100%;
}
.dropbox-label-ie {
  text-align: center;
  padding-top: 22px;
}
.input-file {
  opacity: 0;
  display: inline-block;
  width: 100%;
  height: 108px;
  cursor: pointer;
}
.dragover {
  outline: 3px dashed grey !important;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue;
}
.fileupload {
  background: lightcyan;
  margin-top: 5px;
  width: 100%;
}
.buttonContainer {
  min-width: 198px;
  width: 100%;
  display: inline-block;
  text-align: center;
}
</style>
