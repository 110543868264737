var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('v-row',[_c('v-col',{staticClass:"pl-16 pr-16"},[_c('h6',[_vm._v(" Recommendation ")]),_c('v-card',{staticClass:"borderRadius px-6 py-2",attrs:{"width":"100%"}},[_c('v-row',[_c('v-col',[_c('readonlyField',{attrs:{"label":"Select recommendation for this application:","value":_vm.displayName(_vm.applicationReviewModel.reviewResultCode)}})],1),(_vm.applicationReviewModel.tempApprovalEndDate)?_c('v-col',[_c('readonlyField',{attrs:{"label":"Temporary Approval End Date:","value":_vm.formatDateMixin(_vm.applicationReviewModel.tempApprovalEndDate)}})],1):_vm._e(),(_vm.applicationReviewModel.followUpDate)?_c('v-col',[_c('readonlyField',{attrs:{"label":"Follow Up Date:","value":_vm.formatDateMixin(_vm.applicationReviewModel.followUpDate)}})],1):_vm._e()],1),(
            _vm.applicationReviewModelPrevious &&
              _vm.applicationReviewModelPrevious.tempApprovalEndDate
          )?_c('v-row',[_c('v-col',{attrs:{"offset-6":""}}),_c('v-col',[_c('readonlyField',{attrs:{"label":"Prior Temporary Approval End Date:","value":_vm.formatDateMixin(
                  _vm.applicationReviewModelPrevious.tempApprovalEndDate
                )}})],1)],1):_vm._e(),_c('v-row',{staticClass:"mt-2",attrs:{"no-gutters":""}},[(
              _vm.applicationReviewModel.reviewResultCode === 'IN_ELIGB' ||
                _vm.applicationReviewModel.reviewResultCode === 'INCMPLT' ||
                _vm.applicationReviewModel.reviewResultCode === 'RETFORCORR'
            )?_c('v-col',[_c('span',[_vm._v(" Reasons: ")])]):_vm._e()],1),(
            _vm.applicationReviewModel.reviewResultCode === 'IN_ELIGB' ||
              _vm.applicationReviewModel.reviewResultCode === 'INCMPLT' ||
              _vm.applicationReviewModel.reviewResultCode === 'RETFORCORR'
          )?_c('ul',{staticClass:"ml-3"},[_vm._l((_vm.applicationReviewModel.reasons),function(reason,index){return _c('div',{key:index},[(reason.resultReason != 'Other')?_c('li',[_c('span',{staticClass:"font-weight-bold word-wrap"},[_vm._v(" "+_vm._s(reason.resultReason)+" ")])]):_vm._e()])}),_vm._l((_vm.applicationReviewModel.otherReasons),function(otherReason,i){return _c('div',{key:`o${i}`},[_c('li',[_c('span',{staticClass:"font-weight-bold word-wrap"},[_vm._v(" "+_vm._s(otherReason.resultReasonOther)+" ")])])])})],2):_vm._e(),_c('v-row',{staticClass:"mt-4",attrs:{"no-gutters":""}},[_c('v-col',[_c('readonlyField',{attrs:{"label":"Comments:","value":_vm.applicationReviewModel.reviewComment}})],1)],1),(!this.isCitizenUser)?_c('v-row',{staticClass:"mt-4",attrs:{"no-gutters":""}},[_c('v-col',[_c('readonlyField',{attrs:{"label":"Internal Notes:","value":_vm.applicationReviewModel.internalReviewNote}})],1)],1):_vm._e(),(!this.isCitizenUser)?_c('v-row',{staticClass:"mt-4",attrs:{"no-gutters":""}},[_c('v-col',[_c('readonlyField',{attrs:{"label":"Reviewed By:","value":_vm.reviewedBy}})],1),(!this.isCitizenUser)?_c('v-col',[_c('readonlyField',{attrs:{"label":"Reviewed Date:","value":_vm.formatDateMixin(_vm.applicationReviewModel.reviewDate)}})],1):_vm._e()],1):_vm._e(),(_vm.showDiffSectionMethod)?_c('v-row',{staticClass:"pt-2",attrs:{"no-gutters":""}},[_c('span',[_vm._v(" "+_vm._s(_vm.uiFeature.ECLN_DIFF.displayName)+" ")]),(_vm.showDiffSectionMethod)?_c('EcolaneDiff',{attrs:{"summaryColumns":""}}):_vm._e()],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }