
import Vue from "vue";
import router from "@/router";
import store from "@/store";
import axios from "axios";
import {
  IDashboardContent,
  IScrnQuesRequest,
  IScrAnswers,
  IScreeningQuestions,
  ITESUser,
  IScreeningResponse
} from "@/types";
import { TES_APP_ENDPOINT } from "@/constants/EndPoints";
import { HTTP_STATUS } from "@/constants/HttpStatusCode";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import ScreeningResponseMixin from "@/mixins/ScreeningResponseMixin.vue";
import DateHelperMixinVue from "@/mixins/dateHelperMixin.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";

export default Vue.extend({
  name: "dashboard-result",
  mixins: [
    AppPropsMixin,
    DateHelperMixinVue,
    UIFeatureMixinVue,
    ScreeningResponseMixin
  ],
  data() {
    return {
      completedDate: "",
      scrnQuesRequest: {} as IScrnQuesRequest
    };
  },
  created() {
    //@ts-ignore
    this.completedDate = this.formatDateMixin(
      this.dashboardContent.screeningResponse.updatedTs
    );
  },
  computed: {
    scrAnswers: {
      get(): IScrAnswers {
        //@ts-ignore
        return store.getters.screenQuesModule.getScrAnswers;
      },
      set(scrAnswers: IScrAnswers) {
        //@ts-ignore
        store.commit.screenQuesModule.SET_SCRANSWERS(this.scrAnswers);
      }
    },
    questions: {
      get(): IScreeningQuestions[] {
        //@ts-ignore
        return store.getters.screenQuesModule.getQuestions;
      },
      set(newValue: IScreeningQuestions[]) {
        //@ts-ignore
        store.commit.screenQuesModule.SET_QUESTIONS(this.questions);
      }
    },
    dashboardContent: {
      get(): IDashboardContent {
        //@ts-ignore
        return store.getters.dashboardModule.getDashboardContent;
      }
    },
    userInfo: {
      get(): ITESUser {
        //@ts-ignore
        return store.getters.tesUserModule.getTesUser;
      }
    },
    screeningResponse: {
      get(): IScreeningResponse {
        //@ts-ignore
        return store.getters.screeningResponseModule.getScreeningResponse;
      }
    }
  },
  methods: {
    getScreeningResults() {
      let url = TES_APP_ENDPOINT.GET_SCREENING_QUES_BY_SCRN_RESP_ID;
      this.scrnQuesRequest.userId = this.userInfo.userId;
      axios
        .get(url, {
          params: {
            screeningRespID: this.screeningResponse.screeningResponseId
          }
        })
        .then(response => {
          if (response.status === HTTP_STATUS.OK) {
            //@ts-ignore
            store.commit.screenQuesModule.SET_QUESTIONS(
              response.data.tesScrnQuestionsResponseModel
            );
            //@ts-ignore
            store.commit.screenQuesModule.SET_SCRANSWERS(
              response.data.scrAnswers
            );
            if (this.screeningResponse.applicantDob) {
              router.push({
                name: "screeningQuestionsResults",
                query: {
                  scrnRespID: String(this.screeningResponse.screeningResponseId)
                }
              });
            } else {
              router.push({ name: "screeningQuestions" });
            }
            //@ts-ignore
            this.questionsByContext();
          }
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        });
    }
  }
});
