
import Vue from "vue";
import { IApplicantAddress } from "@/types";
import { applicantAddress } from "@/data/AppData";

export default Vue.extend({
  name: "tes-address-template",
  props: {
    addressInfo: {
      type: Object as () => IApplicantAddress,
      default: applicantAddress
    },
    isEcolaneAddress: { type: Boolean, default: false },
    boldFontStyle: { type: Boolean, default: false }
  },
  computed: {
    addressLine(): string {
      let tempAddress = "";
      if (this.isEcolaneAddress) {
        if (
          this.addressInfo.apartmentNumber &&
          "" != this.addressInfo.apartmentNumber
        ) {
          tempAddress = `${this.addressInfo.streetNumber} ${this.addressInfo.street} ${this.addressInfo.apartmentNumber}`;
        } else {
          tempAddress = this.returnTempAddress();
        }
      } else {
        if (
          this.addressInfo.addressLine2 &&
          "" != this.addressInfo.addressLine2
        ) {
          tempAddress = `${this.addressInfo.addressLine1} ${this.addressInfo.addressLine2}`;
        } else {
          tempAddress = this.addressInfo.addressLine1;
        }
      }
      return tempAddress;
    },
    zipCodeDisp(): string {
      let tempZipDisp = "";
      if (this.isEcolaneAddress) {
        tempZipDisp = this.addressInfo.postcode;
      } else {
        let length = this.addressInfo.zip.length;
        if (length > 5) {
          let zip = this.addressInfo.zip.slice(0, 5);
          tempZipDisp = `${zip}-${this.addressInfo.zipExtension}`;
        } else {
          tempZipDisp = this.addressInfo.zip;
        }
      }
      return tempZipDisp;
    },
    labelClass(): string {
      return this.boldFontStyle ? "font-weight-bold" : "";
    }
  },
  methods: {
    returnTempAddress(): string {
      let tempAddress: string = "";
      if (this.addressInfo.streetNumber && this.addressInfo.street) {
        tempAddress = `${this.addressInfo.streetNumber} ${this.addressInfo.street}`;
      } else if (this.addressInfo.streetNumber && !this.addressInfo.street) {
        tempAddress = `${this.addressInfo.streetNumber}`;
      } else if (!this.addressInfo.streetNumber && this.addressInfo.street) {
        tempAddress = `${this.addressInfo.street}`;
      }
      return tempAddress;
    }
  }
});
