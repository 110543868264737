var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mt-4"},[_c('v-card',[_c('v-card-title',{staticClass:"hoverCard"},[_c('v-row',{staticClass:"cursor",attrs:{"no-gutters":""},on:{"click":_vm.showHide}},[_c('v-col',{staticClass:"word-wrap",attrs:{"cols":"8"}},[_c('h6',{staticClass:"pl-2"},[_vm._v("Review Information")])]),_c('v-col',{staticClass:"text-md-right",attrs:{"cols":"4"}},[_c('v-icon',{staticClass:"pr-2 primary--text",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.showHideIcon))]),_c('span',{staticClass:"ml-1 agencyBlue"},[_vm._v(_vm._s(_vm.showhideText))])],1)],1)],1),(_vm.isAppealInPrgrs)?_c('div',[_c('v-container',{staticClass:"pl-12 pr-12 ma-0"},[_c('v-card',{staticClass:"borderRadius pt-0 pr-0 pb-0",attrs:{"color":"green","elevation":"1"}},[_c('v-row',{staticClass:"pl-4 pt-4",attrs:{"no-gutters":""}},[_c('v-icon',{staticClass:"pl-4 pr-4"},[_vm._v("fas fa-times")]),_c('span',{staticClass:"font-weight-bold white--text"},[_vm._v(_vm._s(_vm.uiFeature.APLD_INF.displayName))])],1)],1)],1)],1):_vm._e(),(_vm.seen)?_c('div',[(
          !_vm.isNoEcolaneAgency &&
            !_vm.isNoEcolanePrgrm &&
            !_vm.applicationReviewModelPrevious.tempApprovalEndDate
        )?_c('tes-program-template',{ref:"reviewInfoWEcolane",attrs:{"isSummaryScrn":true,"step1Header":"Review Application & Ecolane Info","step2Header":"Reviewer Attachments","step3Header":_vm.applicantProgramInfo.programTypeCode === 'MATP' &&
          _vm.draftProgramInfo.dhsAuthInd === 'Y'
            ? 'Verify MATP'
            : 'Recommendation',"step4Header":"Recommendation","vertical":"","stepCount":_vm.stepCount}},[_c('ReviewAppEcolaneInfo',{attrs:{"slot":"step1Content"},on:{"save-completed":_vm.saveCompleted,"step-count":_vm.changeStepCount},slot:"step1Content"}),(!_vm.isLANTA)?_c('ReviewerAttachmentsVue',{attrs:{"slot":"step2Content","docDescCodes":_vm.docProofsNoLANTA},on:{"continue-clicked":_vm.saveCompleted},slot:"step2Content"}):_vm._e(),(_vm.isLANTA)?_c('ReviewerAttachmentsVue',{attrs:{"slot":"step2Content","docDescCodes":_vm.docProofsWithLANTA},on:{"continue-clicked":_vm.saveCompleted},slot:"step2Content"}):_vm._e(),(
            _vm.applicantProgramInfo.programTypeCode === 'MATP' &&
              _vm.draftProgramInfo.dhsAuthInd === 'Y'
          )?_c('ReviewVerifyMATP',{attrs:{"slot":"step3Content"},on:{"save-completed":_vm.saveCompleted,"step-count":_vm.changeStepCount},slot:"step3Content"}):_vm._e(),_c('ReviewRecommendationVue',_vm._g({attrs:{"slot":_vm.applicantProgramInfo.programTypeCode === 'MATP' &&
            _vm.draftProgramInfo.dhsAuthInd === 'Y'
              ? 'step4Content'
              : 'step3Content'},slot:_vm.applicantProgramInfo.programTypeCode === 'MATP' &&
            _vm.draftProgramInfo.dhsAuthInd === 'Y'
              ? 'step4Content'
              : 'step3Content'},_vm.$listeners))],1):_vm._e(),(
          _vm.isNoEcolaneAgency ||
            _vm.isNoEcolanePrgrm ||
            _vm.applicationReviewModelPrevious.tempApprovalEndDate
        )?_c('tes-program-template',{ref:"reviewInfoWEcolane",attrs:{"isSummaryScrn":true,"step1Header":"Reviewer Attachments","step2Header":_vm.applicantProgramInfo.programTypeCode === 'MATP' &&
          _vm.draftProgramInfo.dhsAuthInd === 'Y'
            ? 'Verify MATP'
            : 'Recommendation',"step3Header":"Recommendation","vertical":"","stepCount":_vm.stepCount}},[(_vm.isAppealInPrgrs)?_c('div',[_c('v-card',{staticClass:"pa-2",attrs:{"color":"success","outlined":""}},[_c('v-icon',{staticClass:"pl-4 pr-4",attrs:{"color":"white"}},[_vm._v(" fas fa-check")]),_c('span',{staticClass:"font-weight-bold white--text"},[_vm._v(_vm._s(_vm.uiFeature.APLD_INF.displayName))])],1)],1):_vm._e(),(!_vm.isLANTA)?_c('ReviewerAttachmentsVue',{attrs:{"slot":"step1Content","docDescCodes":_vm.docProofsNoLANTA},on:{"continue-clicked":_vm.saveCompleted},slot:"step1Content"}):_vm._e(),(_vm.isLANTA)?_c('ReviewerAttachmentsVue',{attrs:{"slot":"step1Content","docDescCodes":_vm.docProofsWithLANTA},on:{"continue-clicked":_vm.saveCompleted},slot:"step1Content"}):_vm._e(),(
            _vm.applicantProgramInfo.programTypeCode === 'MATP' &&
              _vm.draftProgramInfo.dhsAuthInd === 'Y'
          )?_c('ReviewVerifyMATP',{attrs:{"slot":"step2Content"},on:{"save-completed":_vm.saveCompleted,"step-count":_vm.changeStepCount},slot:"step2Content"}):_vm._e(),_c('ReviewRecommendationVue',_vm._g({attrs:{"slot":_vm.applicantProgramInfo.programTypeCode === 'MATP' &&
            _vm.draftProgramInfo.dhsAuthInd === 'Y'
              ? 'step3Content'
              : 'step2Content'},slot:_vm.applicantProgramInfo.programTypeCode === 'MATP' &&
            _vm.draftProgramInfo.dhsAuthInd === 'Y'
              ? 'step3Content'
              : 'step2Content'},_vm.$listeners))],1):_vm._e()],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }