import { IAlert } from "@/types";
import { ALERT_TYPE } from "@/constants/CommonWebCodes";
import { clone } from "@/util/clone";
import { alertDefault } from "@/data/AppData";
import { defineModule } from "direct-vuex";
import { moduleActionContext } from "..";

export interface AlertState {
  alert: IAlert;
}

const alertModule = defineModule({
  namespaced: true,
  state: {
    alert: clone(alertDefault)
  } as AlertState,
  getters: {
    getAlert: (state: AlertState) => state.alert
  },
  mutations: {
    UPDATE_ALERT(state: AlertState, newAlert: IAlert) {
      state.alert = newAlert;
    }
  },
  actions: {
    resetAlertMessage(context: any) {
      const { commit } = alertModuleActionContext(context);
      let alert: IAlert = clone(alertDefault);

      commit.UPDATE_ALERT(alert);
    },
    showAlertError(context: any, message: string) {
      const { commit } = alertModuleActionContext(context);

      let alert: IAlert = clone(alertDefault);

      alert.type = ALERT_TYPE.ERROR;
      alert.error = message;
      alert.show = true;

      commit.UPDATE_ALERT(alert);
    },
    showAlertMessage(context: any, options: any) {
      const { commit } = alertModuleActionContext(context);

      let alert: IAlert = clone(alertDefault);

      alert.dismissible = true;
      alert.message = options.message;
      alert.type = options.alertType;
      alert.show = true;

      commit.UPDATE_ALERT(alert);
    },
    showAlertMessageByGPC(context: any, message: string) {
      const { dispatch, rootGetters } = alertModuleActionContext(context);
      let errMsg =
        //@ts-ignore
        rootGetters.uiFeatureModule.uiFeature[message].largeText;

      let options = { message: errMsg, alertType: ALERT_TYPE.ERROR };
      dispatch.showAlertMessage(options);
    },
    showAlertMessageById(context: any, errorId: string) {
      const { rootGetters, dispatch } = alertModuleActionContext(context);
      let errorGPC =
        //@ts-ignore
        rootGetters.uiFeatureModule.uiFeature.ER_INLINE.largeText;
      let errMsg = `${errorGPC} ${errorId}`;

      let options = { message: errMsg, alertType: ALERT_TYPE.ERROR };
      dispatch.showAlertMessage(options);
    }
  }
});

export default alertModule;
const alertModuleActionContext = (context: any) =>
  moduleActionContext(context, alertModule);
