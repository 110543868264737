
import Vue from "vue";
import { IDraftPhysicianInfo, IUIFeatureObjectModel } from "@/types";
import tesControlTemplate from "../common/TESControlTemplate.vue";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import store from "@/store";
import { GPC } from "@/constants/GPCCode";
import helper from "@/composables/helper";

export default Vue.extend({
  name: "physician-information",
  props: {
    physicianInfo: {
      type: Object as () => IDraftPhysicianInfo,
      required: true,
      default: {} as IDraftPhysicianInfo
    }
  },
  data: () => {
    return {
      zipCode: "",
      phoneNum: "",
      faxNum: ""
    };
  },
  computed: {
    statesList: () =>
      (store.getters.uiFeatureModule.uiFeature as Record<
        string,
        IUIFeatureObjectModel
      >)[GPC.STATE].children
  },
  watch: {
    zipCode(newVal: string | undefined, oldVal: string | undefined) {
      if (newVal && newVal !== oldVal) {
        // @ts-ignore
        const unmasked = this.unmaskedZip(newVal);
        this.physicianInfo.zip = unmasked.substring(0, 5);
        this.physicianInfo.zipExtension =
          newVal.length > 5 ? newVal.substring(6) : "";
      }
    },
    phoneNum(newVal: string | undefined, oldVal: string | undefined) {
      if (newVal && newVal !== oldVal) {
        // @ts-ignore
        this.physicianInfo.physicianPhone = this.unmaskedPhone(newVal);
      }
    },
    faxNum(newVal: string | undefined, oldVal: string | undefined) {
      if (newVal && newVal !== oldVal) {
        // @ts-ignore
        this.physicianInfo.physicianFax = this.unmaskedPhone(newVal);
      }
    }
  },
  components: { tesControlTemplate },
  mixins: [fieldEntryMixin],
  async mounted() {
    store.dispatch.uiFeatureModule.getUIFeatures(GPC.STATE);
    if (this.physicianInfo) {
      this.phoneNum = this.physicianInfo.physicianPhone;
      this.faxNum = this.physicianInfo.physicianFax;
      this.zipCode = this.physicianInfo.zip;
      if (
        this.physicianInfo.zipExtension &&
        this.physicianInfo.zipExtension.length > 0
      ) {
        this.zipCode += this.physicianInfo.zipExtension;
      }
    }
  },
  setup() {
    const { getAriaLabel } = helper();
    return { getAriaLabel };
  }
});
