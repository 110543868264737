
import Vue from "vue";
import axios from "axios";
import router from "@/router";
import store from "@/store";
import {
  IApplicantProfile,
  IDraftProgramInfo,
  IProgramsList,
  IStepperHeaderList
} from "@/types";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import RouterMixinVue from "@/mixins/RouterMixin.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import Attachments from "@/components/application/program/Attachments.vue";
import AttachmentsMixinVue from "@/mixins/AttachmentsMixin.vue";
import StepperNavigationMixinVue from "@/mixins/StepperNavigationMixin.vue";
import { TES_APP_ENDPOINT } from "@/constants/EndPoints";
import ProgressBar from "@/components/common/ProgressBar.vue";
import CancelDialog from "@/components/application/CancelDialog.vue";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import {
  CNST_TEXT,
  PROGRAM_NAMES,
  APPL_STATUS
} from "@/constants/CommonConstants";
import { GPC } from "@/constants/GPCCode";
import DynamicTextMixin from "@/mixins/DynamicTextMixin.vue";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";

export default Vue.extend({
  name: "singleflow-attachments",
  mixins: [
    fieldEntryMixin,
    UIFeatureMixinVue,
    RouterMixinVue,
    AttachmentsMixinVue,
    StepperNavigationMixinVue,
    AppPropsMixin,
    DynamicTextMixin,
    ApplicationMixin
  ],
  components: {
    Attachments,
    ProgressBar,
    CancelDialog
  },
  data: function() {
    return {
      showProgressBar: false,
      showDocUploadErrorMsg: false,
      cancelDialog: false,
      subHeaderMsg: "" as string,
      docText: "" as string,
      iconTextLabel: {
        showText: "",
        hideText: ""
      },
      iconDisplay: {
        expandIcon: "mdi-menu-right",
        compressIcon: "mdi-menu-up"
      }
    };
  },
  props: {},
  methods: {
    displayCancelDialog() {
      this.cancelDialog = true;
    },
    saveAttachmentsForCancel() {
      this.navigateToReview("CANCEL");
    },
    navigateToReview(buttonName: string) {
      if (
        //@ts-ignore
        this.isApplicantCorrectionsStatus()
      ) {
        //@ts-ignore
        this.saveDraftProgramInfo();
      }
      if (buttonName && buttonName === "CANCEL") {
        this.returnToDashboard();
      } else {
        this.stepperHeaderModel.stepperHeaders[
          this.stepperHeaderModel.stepperHeaders.length - 2
        ].isCurrentStep = false;
        this.stepperHeaderModel.stepperHeaders[
          this.stepperHeaderModel.stepperHeaders.length - 1
        ].isCurrentStep = true;
        router.push({
          path: "/application-program/" + "REVIEW",
          query: {
            profileID: String(this.applicantProgramInfo.applicantProfileId),
            prgmType: "REVIEW"
          }
        });
      }
    },
    returnToDashboard() {
      //@ts-ignore
      this.forwardToDashboard();
    },
    saveDraftProgramInfo() {
      this.showProgressBar = true;
      let url = TES_APP_ENDPOINT.SAVE_DRAFT_PRGM_INFO;
      //@ts-ignore
      this.draftProgramInfo.applicantProfileId = this.applicantProfile.applicantProfileId;
      //@ts-ignore
      this.applicantProgramInfo.statusCode = "RDY_SBMT_C";
      //@ts-ignore
      this.programEligModel.preEligiblePrograms[0].statusCode = "RDY_SBMT_C";
      //@ts-ignore
      this.draftProgramInfo.programInfo = this.applicantProgramInfo;
      //@ts-ignore
      this.draftProgramInfo.userInfo = this.userInfo;
      //@ts-ignore
      this.draftProgramInfo.programStatus = "RDY_SBMT_C";

      axios
        .post(url, this.draftProgramInfo)
        .then(response => {
          //@ts-ignore
          store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(
            response.data
          );
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    },
    populateSubHeaderAndDocText() {
      const attachDocText = "Attach Documents for Proof of Disability for";
      //@ts-ignore
      if (this.showCustomCODADA && this.showCustomCODADACencotPWD) {
        let eligibleADAProgram: IProgramsList = this.eligiblePrograms.find(
          (o: { programTypeCode: string }) =>
            o.programTypeCode === "ADA" || o.programTypeCode === "RFTP"
        );
        this.subHeaderMsg = `${attachDocText} ${eligibleADAProgram.transiAgencyName}`;
        this.populateDocTextForCustomDisability(eligibleADAProgram);
        //@ts-ignore
      } else if (this.showCustomCODADA) {
        let eligibleADAProgram: IProgramsList = this.eligiblePrograms.find(
          (o: { programTypeCode: string }) =>
            o.programTypeCode === "ADA" ||
            o.programTypeCode === "MATP" ||
            o.programTypeCode === "PWD" ||
            o.programTypeCode === "RFTP"
        );
        this.subHeaderMsg = `${attachDocText} ${eligibleADAProgram.transiAgencyName}`;
        this.populateDocTextForCustomDisability(eligibleADAProgram);
      }
    },
    populateDocTextForCustomDisability(eligibleProgram: IProgramsList) {
      if (eligibleProgram.transitAgencyCode === "FREEDOM") {
        //@ts-ignore
        this.docText = this.getCodDocDynamicText(
          //@ts-ignore
          this.uiFeature.FRDMCODTXT.largeText
        );
      } else {
        let abcObj = this.docGPCCodes.find(
          obj =>
            //@ts-ignore
            obj.resourceCode === eligibleProgram.transitAgencyCode + "_COD"
        );
        //@ts-ignore
        this.docText = this.getCodDocDynamicText(abcObj.largeText);
      }
    }
  },
  computed: {
    prfDsbltyCodes(): any {
      //@ts-ignore
      return this.uiFeature.PRF_DSBLTY.children;
    },
    ageProofCodes(): any {
      //@ts-ignore
      return this.uiFeature.AGE_PROOF.children;
    },
    prfPrsnCareAttendantCodes(): any {
      //@ts-ignore
      return this.uiFeature.PRF_PRCARE.children;
    },
    matpFormCodes(): any {
      //@ts-ignore
      return this.uiFeature.MATP_FORM.children;
    },
    applicantProfile: {
      get(): IApplicantProfile {
        //@ts-ignore
        return store.getters.applicantProfileModule.getApplicantProfile;
      }
    },
    medicareInfo(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.MCI_PROOF].largeText,
          CNST_TEXT.THE_APPLICANT_HAS,
          CNST_TEXT.THE_APPLICANT,
          CNST_TEXT.THEM
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBLTY].largeText,
          CNST_TEXT.YOU_HAVE,
          CNST_TEXT.YOU,
          CNST_TEXT.YOU
        );
      }
      return text;
    },
    prfDsbltyInfoOne(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBLTY].largeText,
          CNST_TEXT.THE_APPLICANT_HAS,
          CNST_TEXT.THE_APPLICANT,
          CNST_TEXT.THE
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBLTY].largeText,
          CNST_TEXT.YOU_HAVE,
          CNST_TEXT.YOU,
          CNST_TEXT.YOUR
        );
      }
      return text;
    },
    prfDsbltyInfoHeaderTwo(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_2].displayName,
          CNST_TEXT.THE
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_2].displayName,
          CNST_TEXT.MY
        );
      }
      return text;
    },
    prfDsbltyInfoTwo(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_2].largeText,
          CNST_TEXT.THE
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_2].largeText,
          CNST_TEXT.YOUR
        );
      }
      return text;
    },
    prfDsbltyInfoThree(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_3].largeText,
          CNST_TEXT.THE,
          CNST_TEXT.THE
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_3].largeText,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOUR
        );
      }
      return text;
    },
    pcaDocInfoOne(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_PRCARE].largeText,
          CNST_TEXT.THE_APPLICANT_NEEDS,
          CNST_TEXT.THE_APPLICANT,
          CNST_TEXT.THEIR,
          CNST_TEXT.THE
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_PRCARE].largeText,
          CNST_TEXT.YOU_NEED,
          CNST_TEXT.YOU,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOUR
        );
      }
      return text;
    },
    vtpDocInfoHeader(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.VTP_INFO_1].displayName,
          CNST_TEXT.BE_USED,
          CNST_TEXT.THAT_THE_APPLICANT_IS
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.VTP_INFO_1].displayName,
          CNST_TEXT.I_USE,
          CNST_TEXT.I_AM
        );
      }
      return text;
    },
    vtpDocInfoOne(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.VTP_INFO_1].largeText,
          CNST_TEXT.THE_APPLICANTS,
          CNST_TEXT.THE_APPLICANT
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.VTP_INFO_1].largeText,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOU
        );
      }
      return text;
    },
    codDocText(): string {
      //@ts-ignore
      return this.getCodDocDynamicText(
        //@ts-ignore
        this.uiFeature[GPC.COD_DOCTXT].largeText
      );
    },
    matpCTDCDocText(): string {
      //@ts-ignore
      return this.getCodDocDynamicText(
        //@ts-ignore
        this.uiFeature[GPC.CTDC_MATP].largeText
      );
    },
    matpFormHelpText(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.MATP_FORM].largeText,
          CNST_TEXT.THE_APPLICANT_IS,
          CNST_TEXT.THE_APPLICANT,
          CNST_TEXT.THE
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.MATP_FORM].largeText,
          CNST_TEXT.YOU_ARE,
          CNST_TEXT.YOU,
          CNST_TEXT.YOUR
        );
      }
      return text;
    },
    eligiblePrograms: {
      get(): any {
        //@ts-ignore
        return store.getters.programEligModule.getProgramElig
          .preEligiblePrograms;
      }
    },
    programEligModule: {
      get(): any {
        //@ts-ignore
        return store.getters.programEligModule.getProgramElig;
      }
    },
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        //@ts-ignore
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      },
      set(newValue: IDraftProgramInfo) {
        //@ts-ignore
        store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(newValue);
      }
    },
    programArray(): any {
      //@ts-ignore
      return this.eligiblePrograms.map(o => o.programTypeCode);
    },
    userInfo: {
      get() {
        //@ts-ignore
        return store.getters.tesUserModule.getTesUser;
      }
    },
    applicantProgramInfo: {
      get() {
        //@ts-ignore
        return store.getters.applicantProgramModule.getApplicantProgram;
      }
    },
    isDocumentsRequired: {
      get() {
        //@ts-ignore
        return store.getters.attachmentsModule.getDocsRequired;
      }
    },
    isAllDocsUploaded: {
      get() {
        //@ts-ignore
        return store.getters.attachmentsModule.getAllDocsUploaded;
      }
    },
    docGPCCodes(): [] {
      //@ts-ignore
      return this.uiFeature.AG_ATT_TXT.children;
    },
    stepperHeaderModel: {
      get(): IStepperHeaderList {
        //@ts-ignore
        return store.getters.stepperHeaderModule.getStepperHeaderModel;
      },
      set(newValue: IStepperHeaderList) {
        //@ts-ignore
        store.commit.stepperHeaderModule.SET_STEPPER_HEADER_MODEL(newValue);
      }
    },
    veteranUploadDesc(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithOnePlaceHolder(
        GPC.VTP_DOCTXT,
        CNST_TEXT.THE_APPLICANT_IS,
        CNST_TEXT.YOU_ARE
      );
      return text;
    },
    ageVerificationInfo(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.AGE_PROOF].largeText,
          CNST_TEXT.THE_APPLICANT_IS
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.AGE_PROOF].largeText,
          CNST_TEXT.YOU_ARE
        );
      }
      return text;
    },
    funcVeriInfo(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.FV_FORM].largeText,
          "the applicant qualifies",
          CNST_TEXT.THEY,
          CNST_TEXT.THE_APPLICANTS
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.FV_FORM].largeText,
          "you qualify",
          CNST_TEXT.YOU,
          CNST_TEXT.YOUR
        );
      }
      return text;
    },
    visFormInfo(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.VIS_FORM].largeText,
          CNST_TEXT.THE_APPLICANT_HAS,
          CNST_TEXT.THE
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.VIS_FORM].largeText,
          CNST_TEXT.YOU_HAVE,
          CNST_TEXT.YOUR
        );
      }
      return text;
    },
    pcaDOCText(): string {
      let text: string = "";
      const disqualifyingStatuses = [
        APPL_STATUS.NOT_QUALIFIED,
        APPL_STATUS.NOT_START
      ];

      const hasADAWithSEPTA = this.eligiblePrograms.find(
        (o: {
          programTypeCode: string;
          transitAgencyCode: string;
          statusCode: string;
        }) =>
          o.programTypeCode === PROGRAM_NAMES.ADA &&
          o.transitAgencyCode === "SEPTA" &&
          !disqualifyingStatuses.includes(o.statusCode)
      );
      if (hasADAWithSEPTA) {
        //@ts-ignore
        text = this.uiFeature[GPC.PCA_ADA_DC].largeText;
      } else {
        //@ts-ignore
        text = this.uiFeature[GPC.PCA_DOCTXT].largeText;
      }
      return text;
    },
    isAlleghenyCounty(): boolean {
      if (this.applicantProfile.homeAddress.paCountyCode === "02") {
        return true;
      } else return false;
    },
    replaceText(): string {
      let abc;
      if (this.isAlleghenyCounty) {
        //@ts-ignore
        abc = this.uiFeature.SSR_DE_ALG.largeText;
        return abc.replace("font-weight-bold", "");
      } else {
        //@ts-ignore
        return this.uiFeature.AGE_PROF1.largeText;
      }
    }
  },
  created() {
    const uiFeatureArray =
      "PRF_DSBLTY,AGE_PROOF,PRF_PRCARE,CARE_ATTND,CERT_DBLTY," +
      "DOCUPL_ERR,POD_AGNCYS,COD_DOCTXT,PCA_DOCTXT,FRDMCODTXT," +
      "PARTCODTXT,AMTRAN_COD,C_COD_AGNY,VLD_DOCEXT,CHGLGN_TXT," +
      "FILE_EXT,INVALD_DOC,QUICK_SRCH,ER_INLINE,ADMN_USR,MAIN," +
      "MAIN_TITLE,FMR,ENVRONMNT,CPTA_COD,CLTS_COD,LT_COD,C_POD_PWD," +
      "C_COD_ADA,AG_ATT_TXT,C_COD_PWD,AGE_VRFY_D,VTP_PROOF,MCI_PROOF," +
      "AMT_CH_HLP,VTP_DOCTXT,AGE_PROF1,CTDC_COD,C_COD_VIS,CTDC_MATP," +
      "MATP_FORM";
    //@ts-ignore
    store.commit.uiFeatureModule.SET_DATA_LDNG_STS(true);
    //@ts-ignore
    store.dispatch.uiFeatureModule.getUIFeatures(uiFeatureArray);
    this.populateSubHeaderAndDocText();
    store.dispatch.attachmentsModule.getAtchmtChkBoxSltdByProfileId(
      this.applicantProfile.applicantProfileId
    );
  }
});
