
import Vue from "vue";
import store from "@/store";
import DocumentSectionList from "@/components/common/DocumentSectionList.vue";
import DraftProgramMixin from "@/mixins/DraftProgramMixin.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import ProgressBar from "@/components/common/ProgressBar.vue";
import { IProgramsList } from "@/types";

export default Vue.extend({
  name: "Attachments-Review",
  components: {
    DocumentSectionList,
    ProgressBar
  },
  mixins: [DraftProgramMixin, UIFeatureMixinVue],
  data() {
    return {
      uploadedFiles: [],
      showProgressBar: false
    };
  },
  props: {
    documentTypeCode: {
      type: String,
      default: null
    },
    documentType: {
      type: String,
      default: null
    },
    documentTypeCodes: {
      type: Array,
      default: null
    }
  },
  computed: {
    docs: {
      get() {
        //@ts-ignore
        return store.getters.attachmentsModule.getProfileDocs;
      },
      set(newValue) {
        //@ts-ignore
        store.commit.attachmentsModule.SET_DOC_LIST(newValue);
      }
    },
    isDocListLoadding() {
      return store.getters.attachmentsModule.getDocListLoadingStatus;
    },
    draftProgramInfo: {
      get() {
        //@ts-ignore
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      },
      set(newValue) {
        //@ts-ignore
        store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(newValue);
      }
    },
    applicantProfileInfo: {
      get() {
        return store.getters.applicantProfileModule.getApplicantProfile;
      }
    },
    applicantProgramInfo(): IProgramsList {
      return store.getters.applicantProgramModule.getApplicantProgram;
    },
    getAtchmtCheckboxSltdByDocTypeList() {
      return store.getters.attachmentsModule.getAtchmtCheckboxSltdByDocTypeList;
    },
    showDocsListByDocType: function() {
      //@ts-ignore
      return (first: string) => {
        //@ts-ignore
        return this.docs.filter(doc => doc.documentTypeCode === first);
      };
    },
    showChkBxSection(): boolean {
      //@ts-ignore
      return this.getAtchmtCheckboxSltdByDocTypeList.find(
        //@ts-ignore
        o => o.noUploadInd === "Y"
      );
    },
    showChkBxByDocType: function() {
      //@ts-ignore
      return (first: string) => {
        //@ts-ignore
        return this.getAtchmtCheckboxSltdByDocTypeList.find(
          //@ts-ignore
          o => o.documentTypeCode === first && o.noUploadInd === "Y"
        );
      };
    }
  },
  methods: {
    loadDocsByProgram() {
      let payload = {
        //@ts-ignore
        applicantProfileId: this.applicantProgramInfo.applicantProfileId,
        //@ts-ignore
        docTypeCodes: this.documentTypeCodes.join(",")
      };
      store.dispatch.attachmentsModule.getDocListByProfileId(payload);
    }
  },
  created() {
    //@ts-ignore
    this.loadDocsByProgram();
  }
});
