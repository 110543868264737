
import Vue from "vue";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import DraftProgramMixin from "@/mixins/DraftProgramMixin.vue";
import ProgressBar from "@/components/common/ProgressBar.vue";
import store from "@/store";
import { IDraftProgramInfo, IProgramsList } from "@/types";
import TESRadioControl from "@/components/common/TESRadioControl.vue";
import tesContainer from "@/components/common/TESContainer.vue";
import routerMixin from "@/mixins/RouterMixin.vue";
import StepperNavigationMixinVue from "@/mixins/StepperNavigationMixin.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import InfoSection from "@/components/common/InfoSection.vue";
import ScrollMixin from "@/mixins/ScrollMixin.vue";
import { BUTTON_NAMES, CNST_TEXT } from "@/constants/CommonConstants";
import { GPC } from "@/constants/GPCCode";
import DynamicTextMixin from "@/mixins/DynamicTextMixin.vue";

export default Vue.extend({
  name: "vtp-program-info",
  mixins: [
    fieldEntryMixin,
    DraftProgramMixin,
    routerMixin,
    StepperNavigationMixinVue,
    UIFeatureMixinVue,
    AppPropsMixin,
    ScrollMixin,
    DynamicTextMixin
  ],
  components: {
    tesContainer,
    TESRadioControl,
    ProgressBar,
    InfoSection
  },
  props: {
    stepCount: {
      type: Number
    }
  },
  data() {
    return {
      showProgressBar: false,
      steps: this.stepCount,
      singleFlowFinalStep: false,
      showDefaultButtons: true
    };
  },
  methods: {
    saveContinue() {
      //@ts-ignore
      if (this.$refs.vtpProgramInfoTemp.validate()) {
        this.saveDraftProgramInfo("SAVECONTINUE");
      } else {
        //@ts-ignore
        this.scrollToErrorField(this.$refs.vtpProgramInfoTemp);
      }
    },
    save(buttonName: string) {
      //@ts-ignore
      if (this.$refs.vtpProgramInfoTemp.validate()) {
        if (this.draftProgramInfo.veteranInd === "N") {
          this.saveDraftProgramInfo(buttonName);
        } else {
          this.saveDraftProgramInfo("SAVE");
        }
      } else {
        //@ts-ignore
        this.scrollToErrorField(this.$refs.vtpProgramInfoTemp);
      }
    },
    cancel() {
      //@ts-ignore
      if (this.$refs.vtpProgramInfoTemp.validate()) {
        this.saveDraftProgramInfo("CANCEL");
      } else {
        //@ts-ignore
        this.scrollToErrorField(this.$refs.vtpProgramInfoTemp);
      }
    },
    saveDraftProgramInfo(buttonName: string) {
      this.showProgressBar = true;
      let stepperIndex = 1;

      //@ts-ignore
      this.saveProgramInfo(
        this.draftProgramInfo,
        stepperIndex,
        this.steps,
        buttonName
      )
        //@ts-ignore
        .then(response => {
          this.draftProgramInfo = response.data;
          //@ts-ignore
          store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(
            this.draftProgramInfo
          );

          store.commit.applicantProgramModule.SET_APPLICANT_PROGRAM(
            this.draftProgramInfo.programInfo
          );
          if (
            BUTTON_NAMES.SAVE === buttonName ||
            BUTTON_NAMES.CANCEL === buttonName
          ) {
            this.$emit("save-completed", buttonName);
          } else {
            if (
              //@ts-ignore
              this.isSingleFlow()
            ) {
              this.$emit("save-completed", buttonName);
            } else {
              //@ts-ignore
              this.forwardToDashboard();
            }
          }
        })
        //@ts-ignore
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    },
    changeStepCount(steps: number) {
      this.steps = steps;
      this.$emit("step-count", this.steps);
    },
    changeValues() {
      this.showDefaultButtons = true;
      if (
        //@ts-ignore
        this.isSingleFlow()
      ) {
        this.steps = 1;
      } else {
        this.steps = 2;
      }
      if (this.draftProgramInfo.veteranInd === "N") {
        this.showDefaultButtons = false;
        this.steps = 1;
      }
      this.$emit("step-count", this.steps);
    }
  },
  computed: {
    applicantProgramInfo: {
      get(): IProgramsList {
        //@ts-ignore
        return store.getters.applicantProgramModule.getApplicantProgram;
      }
    },
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        //@ts-ignore
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      },
      set(newValue: IDraftProgramInfo) {
        //@ts-ignore
        store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(newValue);
      }
    },
    veteranConfirmQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithOnePlaceHolder(
        GPC.VTP_INFO,
        CNST_TEXT.THE_APPLICANT_IS,
        CNST_TEXT.YOU_ARE
      );
      return text;
    },
    veteranConfirmQuesHelp(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithOnePlaceHolder(
        GPC.VTP_SEC1,
        CNST_TEXT.THE_APPLICANTS,
        CNST_TEXT.YOUR
      );
      return text;
    },
    veteranConfirmQuesNoMsg(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithTwoPlaceHolder(
        GPC.VTP_NO_MSG,
        CNST_TEXT.TTHE_APPLICANT_DOES,
        CNST_TEXT.THEY,
        CNST_TEXT.YOU_DO,
        CNST_TEXT.YOU
      );
      return text;
    },
    veteranConfirmQuesNoMsgHead(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithOnePlaceHolderVal(
        GPC.VTP_NO_MSG,
        CNST_TEXT.TTHE_APPLICANT_DOES,
        CNST_TEXT.YOU_DO
      );
      return text;
    }
  },
  created() {
    if (
      //@ts-ignore
      this.isSingleFlow()
    ) {
      this.singleFlowFinalStep = true;
    }
    if (this.draftProgramInfo.veteranInd === "N") {
      this.showDefaultButtons = false;
    }
  }
});
