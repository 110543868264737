
import Vue from "vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import store from "@/store";

export default Vue.extend({
  name: "moved",
  mixins: [UIFeatureMixinVue],
  data() {
    return {
      idleLogOut: false as boolean
    };
  },
  created() {
    let uiFeatureArray = "MOVED";
    store.dispatch.uiFeatureModule.getUIFeatures(uiFeatureArray);
    store.commit.appModule.SET_SHOW_MAIN_HEADER(false);
  }
});
