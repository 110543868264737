
import Vue from "vue";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import axios from "axios";
import store from "@/store";
import {
  IDrivingLicense,
  IApplicantProfile,
  IDraftProgramInfo,
  ITESUser,
  IProgramsList,
  IProgramEligModel,
  IDashboardContent
} from "@/types";
import { clone } from "@/util/clone";
import { DL_ENDPOINT } from "@/constants/EndPoints";
import { HTTP_STATUS } from "@/constants/HttpStatusCode";
import { DATE_FORMAT } from "@/constants/DateFormats";
import ProgressBar from "@/components/common/ProgressBar.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import StepperNavigationMixinVue from "@/mixins/StepperNavigationMixin.vue";
import InfoSection from "@/components/common/InfoSection.vue";
import CloseButton from "@/components/common/CloseButton.vue";
import { GPC } from "@/constants/GPCCode";
import DynamicTextMixin from "@/mixins/DynamicTextMixin.vue";
import { CNST_TEXT } from "@/constants/CommonConstants";

export default Vue.extend({
  name: "DL-Information",
  mixins: [
    fieldEntryMixin,
    AppPropsMixin,
    UIFeatureMixinVue,
    StepperNavigationMixinVue,
    DynamicTextMixin
  ],
  components: { ProgressBar, InfoSection, CloseButton },
  data: function() {
    return {
      dlRequest: {} as IDrivingLicense,
      origselected: "",
      dialog: false,
      wheredldialog: false,
      verified: "",
      dlerror: false,
      dlerrormessages: "",
      failedCountAttempts: 0 as number,
      showProgressBar: false
    };
  },
  props: {
    program: { type: String, default: "" }
  },
  created() {
    if (null !== this.draftProgramInfo) {
      this.origselected = this.draftProgramInfo.ageVerificationMethodCode;
    }
  },
  computed: {
    applicantProfile: {
      get(): IApplicantProfile {
        //@ts-ignore
        return store.getters.applicantProfileModule.getApplicantProfile;
      }
    },
    drivingLicenseInfo: {
      get(): IDrivingLicense {
        //@ts-ignore
        return store.getters.drivingLicenseInfoModule.getDrivingLicenseInfo;
      },
      set(newValue: IDrivingLicense) {
        //@ts-ignore
        store.commit.drivingLicenseInfoModule.SET_DRIVING_LICENSE_INFO(
          this.drivingLicenseInfo
        );
      }
    },
    isVerified(): boolean | null {
      let isverified: boolean | null = null;
      const dlVerifyCode = this.draftProgramInfo.driverLicenseVerifyCode;
      if (dlVerifyCode === "TMT" || dlVerifyCode === "DNM") {
        isverified = false;
      } else if (dlVerifyCode === "VRFD") {
        isverified = true;
      }
      return isverified;
    },
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        //@ts-ignore
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      },
      set(newValue: IDraftProgramInfo) {
        //@ts-ignore
        store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(newValue);
      }
    },
    userInfo: {
      get(): ITESUser {
        //@ts-ignore
        return store.getters.tesUserModule.getTesUser;
      }
    },
    verifyAgeDsplyNameQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithOnePlaceHolderVal(
        GPC.VRFY_AGE_Q,
        CNST_TEXT.THE_APPLICANTS,
        CNST_TEXT.YOUR
      );
      return text;
    },
    whereToFindDLQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithOnePlaceHolderVal(
        GPC.FIND_DL,
        CNST_TEXT.THE_APPLICANTS,
        CNST_TEXT.MY
      );
      return text;
    },
    DriverLicenseInfo(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithTwoPlaceHolder(
        GPC.DL_INSTRUC,
        CNST_TEXT.APPLICANTS_THE,
        CNST_TEXT.THEIR,
        CNST_TEXT.YOUR_Y,
        CNST_TEXT.YOUR
      );
      return text;
    },
    DLOptionValueQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithOnePlaceHolder(
        GPC.DL_VALID,
        CNST_TEXT.THE_APPLICANTS,
        CNST_TEXT.YOUR
      );
      return text;
    },
    applicantProgramInfo(): IProgramsList {
      return store.getters.applicantProgramModule.getApplicantProgram;
    },
    programEligModel(): IProgramEligModel {
      return store.getters.programEligModule.getProgramElig;
    },
    dashboardContent(): IDashboardContent {
      return store.getters.dashboardModule.getDashboardContent;
    },
    isFTPReadOnly(): boolean {
      if (
        this.isMultiFTPAndSepta &&
        (this.applicantProgramInfo.statusCode === "RE_COR" ||
          this.applicantProgramInfo.statusCode === "IN_PRGS_C")
      ) {
        return true;
      } else return false;
    },
    isMultiFTPAndSepta(): boolean {
      let count: number = 0;
      let isSepta: boolean = false;
      let submittedPrograms;
      if (
        this.programEligModel.submittedPrograms !== null &&
        this.programEligModel.submittedPrograms.length > 0
      ) {
        submittedPrograms = this.programEligModel.submittedPrograms;
      } else {
        submittedPrograms = this.dashboardContent.programEligModel
          .submittedPrograms;
      }

      if (submittedPrograms && submittedPrograms.length > 1) {
        submittedPrograms.forEach((item: any) => {
          if (item.programTypeCode === "FTP") {
            count++;
          }
          if (item.transitAgencyCode === "SEPTA") {
            isSepta = true;
          }
        });
      }
      return count > 1 && isSepta;
    }
  },
  methods: {
    change() {
      if (
        this.origselected &&
        this.draftProgramInfo.ageVerificationMethodCode !== this.origselected
      ) {
        this.dialog = true;
      }
      this.$emit("change-disable-save");
      //@ts-ignore
      store.commit.drivingLicenseInfoModule.SET_COMMON_QUES_HAS_DL(
        this.draftProgramInfo.ageVerificationMethodCode
      );
    },
    displayWhereDrivingLicense() {
      if (this.draftProgramInfo.ageVerificationMethodCode === "Y") {
        this.wheredldialog = true;
      }
    },
    validate() {
      const pattern = /^\d*$/i;
      if (this.draftProgramInfo.driverLicenseNumber.length <= 7) {
        this.dlerror = true;
        this.dlerrormessages = "Must have 8 numbers";
      } else if (!pattern.test(this.draftProgramInfo.driverLicenseNumber)) {
        this.dlerror = true;
        this.dlerrormessages = "Invalid number(s)";
      } else {
        this.dlerror = false;
        this.dlerrormessages = "";
        this.validateDrivingLicense();
      }
    },
    validateDrivingLicense() {
      this.dlRequest = this.drivingLicenseInfo;
      let url = DL_ENDPOINT.GET_BY_DL_NUM;
      this.populateRequestObject(this.dlRequest);
      this.showProgressBar = true;
      axios
        .post(url, this.dlRequest)
        .then(response => {
          if (response.status === HTTP_STATUS.OK) {
            //@ts-ignore
            store.commit.drivingLicenseInfoModule.SET_DRIVING_LICENSE_INFO(
              response.data
            );

            this.failedCountAttempts = 0;
            this.draftProgramInfo.driverLicenseVerifyCode = "VRFD"; //VRFD = Verified
            //@ts-ignore
            store.commit.drivingLicenseInfoModule.SET_IS_VERIFIED("Y");
          }
        })
        .catch(error => {
          if (error.response.status === HTTP_STATUS.SC_NOT_FOUND) {
            this.failedCountAttempts = this.failedCountAttempts + 1;
            this.draftProgramInfo.driverLicenseVerifyCode = "DNM"; //DNM = Does not match
            //@ts-ignore
            store.commit.drivingLicenseInfoModule.SET_IS_VERIFIED("N");
            if (this.failedCountAttempts > 3) {
              this.draftProgramInfo.driverLicenseVerifyCode = "TMT"; // TMT = Too Many Tries
              //@ts-ignore
              store.commit.drivingLicenseInfoModule.SET_COMMON_QUES_HAS_DL("N");
            }
          } else {
            store.dispatch.alertModule.showAlertMessageById(
              error.response.data.id
            );
          }
          let steps = 3;
          if (
            //@ts-ignore
            this.isSingleFlow()
          ) {
            if (this.program === "FTP") {
              steps = 2;
            } else {
              steps = 1;
            }
          } else {
            if (this.program === "SSR") {
              steps = 2;
            }
          }
          this.$emit("step-count", steps);
        })
        .finally(() => {
          this.showProgressBar = false;
          this.$emit("change-disable-save");
          let elementToFocus;
          if (this.failedCountAttempts === 0)
            elementToFocus = document.getElementById("license-matched");
          else if (this.failedCountAttempts > 0)
            elementToFocus = document.getElementById("license-not-matched");
          if (elementToFocus) elementToFocus.focus();
        });
    },
    populateRequestObject(dlRequest: IDrivingLicense) {
      dlRequest.applicantProfileId = this.applicantProfile.applicantProfileId;
      dlRequest.dlNumber = clone(this.draftProgramInfo.driverLicenseNumber);
      dlRequest.lastName = this.applicantProfile.applicantLastName;
      dlRequest.birthDate = this.formatDate(
        this.applicantProfile.applicantDob,
        DATE_FORMAT.ISO860_SHORT_DATE_FORMAT
      );
      dlRequest.createdBy = this.userInfo.createdBy;
      dlRequest.updatedBy = this.userInfo.createdBy;
    },
    formatDate(date: string, format: string): string {
      //@ts-ignore
      return this.$moment(date).format(format);
    },
    drivingLicenseNumber() {
      return this.draftProgramInfo.driverLicenseNumber;
    },
    populateFailedCountAttempts() {
      if (this.draftProgramInfo.ageVerificationMethodCode === "N") {
        this.failedCountAttempts = 0;
      }
    },
    populateSteps(steps: any) {
      if (
        this.draftProgramInfo.ageVerificationMethodCode === "Y" &&
        this.draftProgramInfo.driverLicenseVerifyCode === "VRFD"
      ) {
        steps = this.ageVerifiedCodeOrNotverified(steps);
      } else {
        steps = this.ageNotVerifiedCodeOrVerified(steps);
      }
      this.$emit("step-count", steps);
    },
    ageVerifiedCodeOrNotverified(steps: any): any {
      if (
        //@ts-ignore
        this.isSingleFlow()
      ) {
        if (
          //@ts-ignore
          this.isByPassPrgmChkd()
        ) {
          steps = 2;
        } else {
          steps = 1;
        }
      } else {
        if (this.program === "FTP") {
          if (this.draftProgramInfo.ftpApplicationInd === "N") {
            steps = 1;
          } else if (this.ageVerified()) {
            steps = 2;
          } else {
            steps = 3;
          }
        } else {
          if (
            (this.draftProgramInfo.ageVerificationMethodCode === "Y" &&
              this.draftProgramInfo.driverLicenseVerifyCode === "VRFD") ||
            this.draftProgramInfo.ageVerificationMethodCode === "N"
          ) {
            steps = 1;
          } else {
            steps = 2;
          }
        }
      }
      return steps;
    },
    ageNotVerifiedCodeOrVerified(steps: any): any {
      if (
        //@ts-ignore
        this.isSingleFlow()
      ) {
        if (this.program === "FTP") {
          if (
            //@ts-ignore
            this.isByPassPrgmChkd()
          ) {
            if (this.draftProgramInfo.ftpApplicationInd === "N") {
              steps = 1;
            } else {
              steps = 2;
            }
          } else {
            steps = 1;
          }
        } else {
          steps = 1;
        }
      } else {
        steps = this.chkFTPApplInd(steps);
      }
      return steps;
    },
    ageVerified() {
      return (
        this.draftProgramInfo.ageVerificationMethodCode === "Y" &&
        this.draftProgramInfo.driverLicenseVerifyCode === "VRFD"
      );
    },
    chkFTPApplInd(steps: any) {
      if (this.program === "FTP") {
        if (this.draftProgramInfo.ftpApplicationInd === "N") {
          steps = 1;
        } else if (this.ageVerified()) {
          steps = 2;
        } else {
          steps = 3;
        }
      } else {
        steps = 2;
      }
      return steps;
    },
    isOnBehalfOfuser() {
      //@ts-ignore
      return this.isOrgAgyCtznonBehalfOfUser();
    }
  },
  watch: {
    draftProgramInfo: {
      // This will let Vue know to look inside the draftProgramInfo
      deep: true,
      handler() {
        let steps;
        if (this.draftProgramInfo.ageVerificationMethodCode) {
          //@ts-ignore
          store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(
            this.draftProgramInfo
          );
        } else {
          this.draftProgramInfo.ageVerificationMethodCode = "";
        }

        this.populateFailedCountAttempts();
        this.populateSteps(steps);
      }
    }
  }
});
