
import Vue from "vue";
import { version } from "@/../package.json";

export default Vue.extend({
  name: "application-footer",
  data() {
    return {
      version: version
    };
  },
  computed: {
    findMyRideClass(): string {
      return this.$vuetify.breakpoint.mdAndUp ? "centerALink" : "";
    },
    versionShort(): string {
      return version.substring(0, version.lastIndexOf("."));
    }
  }
});
