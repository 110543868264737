import { moduleActionContext } from "..";
import { applicationNavigationDefault } from "@/data/AppData";
import { IApplicationNavigation } from "@/types";
import { clone } from "@/util/clone";
import { defineModule } from "direct-vuex";

export interface ApplicationNavigationState {
  applicationNavigation: IApplicationNavigation;
}

const applicationNavigationModule = defineModule({
  namespaced: true,
  state: {
    applicationNavigation: clone(applicationNavigationDefault)
  } as ApplicationNavigationState,
  getters: {
    getApplicationNavigation: (state: ApplicationNavigationState) =>
      state.applicationNavigation
  },
  mutations: {
    SET_APPLLICATION_NAVIGATION(
      state: ApplicationNavigationState,
      applicationNavigation: IApplicationNavigation
    ) {
      state.applicationNavigation = applicationNavigation;
    }
  },
  actions: {
    resetData(context: any) {
      let { commit } = applicantNavigationContext(context);
      //clearing the applicantProgram data in store.
      let applicationNavigation = clone(applicationNavigationDefault);
      //@ts-ignore
      commit.SET_APPLLICATION_NAVIGATION(applicationNavigation);
    }
  }
});

export default applicationNavigationModule;
const applicantNavigationContext = (context: any) =>
  moduleActionContext(context, applicationNavigationModule);
