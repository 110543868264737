import axios from "axios";
import {
  IApplicantEcolaneProfileModel,
  IStgEcolaneCustProfileModel,
  IEcolaneDataUpdate
} from "@/types";
import {
  applicantEcolaneProfileModelDefault,
  stgEcolaneCustProfileDefault,
  ecolaneDataUpdateDefault
} from "@/data/AppData";
import { clone } from "@/util/clone";
import { moduleActionContext } from "..";
import { defineModule } from "direct-vuex";
import { ECOLANE_ENDPOINT } from "@/constants/EndPoints";

export interface IApplicantEcolaneProfileState {
  applicantEcolaneProfile: IApplicantEcolaneProfileModel;
  isEcolaneCustomerLoading: boolean;
  isEcolaneCustomerDataLoaded: boolean;
  stgEcolaneCustProfile: IStgEcolaneCustProfileModel;
  ecolaneDataUpdateList: IEcolaneDataUpdate[];
  ecolaneDataPrevUpdateList: IEcolaneDataUpdate[];
}

const applicantEcolaneProfileModule = defineModule({
  namespaced: true,
  state: {
    applicantEcolaneProfile: clone(applicantEcolaneProfileModelDefault),
    isEcolaneCustomerLoading: false,
    isEcolaneCustomerDataLoaded: false,
    stgEcolaneCustProfile: clone(stgEcolaneCustProfileDefault),
    ecolaneDataUpdateList: [clone(ecolaneDataUpdateDefault)],
    ecolaneDataPrevUpdateList: [clone(ecolaneDataUpdateDefault)]
  } as IApplicantEcolaneProfileState,
  getters: {
    getApplicantEcolaneProfile: (state: IApplicantEcolaneProfileState) =>
      state.applicantEcolaneProfile,
    getIsEcolaneCustomerLoaded: (state: IApplicantEcolaneProfileState) =>
      state.isEcolaneCustomerLoading,
    getIsEcolaneCustomerDataLoaded: (state: IApplicantEcolaneProfileState) =>
      state.isEcolaneCustomerDataLoaded,
    getStgEcolaneCustProfile: (state: IApplicantEcolaneProfileState) =>
      state.stgEcolaneCustProfile,
    getEcolaneDataUpdateList: (state: IApplicantEcolaneProfileState) =>
      state.ecolaneDataUpdateList,
    getEcolaneDataPrevUpdateList: (state: IApplicantEcolaneProfileState) =>
      state.ecolaneDataPrevUpdateList
  },
  mutations: {
    SET_APPLICANT_ECOLANE_PROFILE(
      state: IApplicantEcolaneProfileState,
      applicantEcolaneProfileObject: IApplicantEcolaneProfileModel
    ) {
      state.applicantEcolaneProfile = applicantEcolaneProfileObject;
    },
    SET_ECOLANE_CUST_LOADING(
      state: IApplicantEcolaneProfileState,
      flag: boolean
    ) {
      state.isEcolaneCustomerLoading = flag;
    },
    SET_ECOLANE_CUST_DATA_LOADED(
      state: IApplicantEcolaneProfileState,
      flag: boolean
    ) {
      state.isEcolaneCustomerDataLoaded = flag;
    },
    SET_STG_ECOLANE_CUST_PROFILE(
      state: IApplicantEcolaneProfileState,
      stgEcolaneCustProfileObj: IStgEcolaneCustProfileModel
    ) {
      state.stgEcolaneCustProfile = stgEcolaneCustProfileObj;
    },
    SET_ECOLANE_DATA_UPDATE_LIST(
      state: IApplicantEcolaneProfileState,
      ecolaneDataUpdateList: IEcolaneDataUpdate[]
    ) {
      state.ecolaneDataUpdateList = ecolaneDataUpdateList;
    },
    SET_ECOLANE_DATA_PREV_UPDATE_LIST(
      state: IApplicantEcolaneProfileState,
      ecolaneDataUpdateList: IEcolaneDataUpdate[]
    ) {
      state.ecolaneDataPrevUpdateList = ecolaneDataUpdateList;
    }
  },
  actions: {
    async loadApplicantEcolaneProfile(context: any, params: any) {
      const { commit, rootDispatch } = applicantEcolaneProfileContext(context);
      commit.SET_ECOLANE_CUST_LOADING(true);
      let url = ECOLANE_ENDPOINT.GET_ECOLANE_CUSTOMER_INFO_FROM_TES;
      try {
        let response = await axios.get(url, {
          params: {
            applicantProfileId: params.applicantProfileId,
            transitAgencyCode: params.transitAgencyCode
          }
        });
        commit.SET_APPLICANT_ECOLANE_PROFILE(response.data);
        commit.SET_ECOLANE_CUST_DATA_LOADED(true);
        return response.data;
      } catch {
        rootDispatch.alertModule.showAlertMessageByGPC("ER_INLINE");
      } finally {
        commit.SET_ECOLANE_CUST_LOADING(false);
      }
    },
    async getStgEcolaneCustProfileInfo(context: any, params: any) {
      const { commit, rootDispatch } = applicantEcolaneProfileContext(context);
      commit.SET_ECOLANE_CUST_LOADING(true);
      let url = ECOLANE_ENDPOINT.GET_STG_ECOLANE_INFO;
      try {
        let response = await axios.get(url, {
          params: {
            applicationReviewId: params.applicationReviewId
          }
        });
        if (response.data) {
          commit.SET_STG_ECOLANE_CUST_PROFILE(response.data);
        }
        commit.SET_ECOLANE_CUST_DATA_LOADED(true);
        return response.data;
      } catch {
        rootDispatch.alertModule.showAlertMessageByGPC("ER_INLINE");
      } finally {
        commit.SET_ECOLANE_CUST_LOADING(false);
      }
    },
    getEcolaneDataUpdateListByReviewId(context: any, params: any) {
      const { commit, rootDispatch } = applicantEcolaneProfileContext(context);
      commit.SET_ECOLANE_CUST_LOADING(true);
      let url = ECOLANE_ENDPOINT.GET_ECOLANE_DATA_UPDATE_LIST;
      axios
        .get(url, {
          params: {
            applicationReviewId: params.applicationReviewId
          }
        })
        .then(response => {
          if (response.data) {
            commit.SET_ECOLANE_DATA_UPDATE_LIST(response.data);
            commit.SET_ECOLANE_DATA_PREV_UPDATE_LIST(response.data);
          }
          commit.SET_ECOLANE_CUST_DATA_LOADED(true);
        })
        .catch(() => {
          rootDispatch.alertModule.showAlertMessageByGPC("ER_INLINE");
        })
        .finally(() => {
          commit.SET_ECOLANE_CUST_LOADING(false);
        });
    },
    getEcolaneDataUpdateListByEcolaneUpdInd(context: any, params: any) {
      const { commit, rootDispatch } = applicantEcolaneProfileContext(context);
      commit.SET_ECOLANE_CUST_LOADING(true);
      let url =
        ECOLANE_ENDPOINT.GET_ECOLANE_DATA_UPDATE_LIST_BY_ECOLANE_UPD_IND;
      axios
        .get(url, {
          params: {
            applicationReviewId: params.applicationReviewId,
            ecolaneUpdateInd: params.ecolaneUpdateInd
          }
        })
        .then(response => {
          commit.SET_ECOLANE_DATA_UPDATE_LIST(response.data);
          commit.SET_ECOLANE_CUST_DATA_LOADED(true);
        })
        .catch(() => {
          rootDispatch.alertModule.showAlertMessageByGPC("ER_INLINE");
        })
        .finally(() => {
          commit.SET_ECOLANE_CUST_LOADING(false);
        });
    },
    resetData(context: any) {
      let { commit } = applicantEcolaneProfileContext(context);
      //clearing the applicationReview data in store.
      commit.SET_APPLICANT_ECOLANE_PROFILE(
        clone(applicantEcolaneProfileModelDefault)
      );
      commit.SET_ECOLANE_CUST_DATA_LOADED(false);
      commit.SET_STG_ECOLANE_CUST_PROFILE(clone(stgEcolaneCustProfileDefault));
      commit.SET_ECOLANE_DATA_UPDATE_LIST(clone(ecolaneDataUpdateDefault));
      commit.SET_ECOLANE_DATA_PREV_UPDATE_LIST(clone(ecolaneDataUpdateDefault));
    }
  }
});

export default applicantEcolaneProfileModule;
const applicantEcolaneProfileContext = (context: any) =>
  moduleActionContext(context, applicantEcolaneProfileModule);
