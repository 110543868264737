import axios from "axios";
import { TES_APP_ENDPOINT } from "@/constants/EndPoints";
import { HTTP_STATUS } from "@/constants/HttpStatusCode";
import { moduleActionContext } from "..";
import { defineModule } from "direct-vuex";

export interface DataState {
  counties: [];
  fixedRouteCounties: [];
  filteredCounties: [];
  agencyCounties: [];
}

const dataModule = defineModule({
  namespaced: true,
  state: {
    counties: [],
    fixedRouteCounties: [],
    filteredCounties: [],
    agencyCounties: []
  } as DataState,
  getters: {
    getCounties: (state: DataState) => state.counties,
    getfixedRouteCounties: (state: DataState) => state.fixedRouteCounties,
    getFilteredCountiesInStore: (state: DataState) => state.filteredCounties,
    getAgencyCountiesInStore: (state: DataState) => state.agencyCounties
  },
  mutations: {
    SET_COUNTIES(state: DataState, newCounties: []) {
      state.counties = newCounties;
    },
    SET_FIXED_ROUTE_COUNTIES(state: DataState, newFixedRouteCounties: []) {
      state.fixedRouteCounties = newFixedRouteCounties;
    },
    SET_FILTERED_COUNTIES(state: DataState, newFilteredCounties: []) {
      state.filteredCounties = newFilteredCounties;
    },
    SET_AGENCY_COUNTIES(state: DataState, newAgencyCounties: []) {
      state.agencyCounties = newAgencyCounties;
    }
  },
  actions: {
    getAllCounties(context: any) {
      const { commit, rootDispatch } = dataModuleActionContext(context);
      let url = TES_APP_ENDPOINT.GET_COUNTIES;

      axios
        .get(url)
        .then(response => {
          if (response.status === HTTP_STATUS.OK) {
            commit.SET_COUNTIES(response.data);
          }
        })
        .catch(() => {
          rootDispatch.alertModule.showAlertMessageByGPC("ER_INLINE");
        });
    },
    getFixedRouteCounties(context: any) {
      const { commit, rootDispatch } = dataModuleActionContext(context);
      let url = TES_APP_ENDPOINT.GET_FIXED_ROUTE_COUNTIES;

      axios
        .get(url)
        .then(response => {
          if (response.status === HTTP_STATUS.OK) {
            commit.SET_FIXED_ROUTE_COUNTIES(response.data);
          }
        })
        .catch(() => {
          rootDispatch.alertModule.showAlertMessageByGPC("ER_INLINE");
        });
    },
    getFilteredCounties(context: any) {
      const { commit, rootDispatch } = dataModuleActionContext(context);
      let url = TES_APP_ENDPOINT.GET_FILTERED_COUNTIES;

      axios
        .get(url)
        .then(response => {
          if (response.status === HTTP_STATUS.OK) {
            commit.SET_FILTERED_COUNTIES(response.data);
          }
        })
        .catch(() => {
          rootDispatch.alertModule.showAlertMessageByGPC("ER_INLINE");
        });
    },
    getAgencyCounties(context: any, agency: any) {
      const { commit, rootDispatch } = dataModuleActionContext(context);
      let url = TES_APP_ENDPOINT.GET_AGENCY_COUNTIES;
      axios
        .get(url, {
          params: {
            agency: agency
          }
        })
        .then(response => {
          if (response.status === HTTP_STATUS.OK) {
            commit.SET_AGENCY_COUNTIES(response.data);
          }
        })
        .catch(() => {
          rootDispatch.alertModule.showAlertMessageByGPC("ER_INLINE");
        });
    }
  }
});

export default dataModule;
const dataModuleActionContext = (context: any) =>
  moduleActionContext(context, dataModule);
