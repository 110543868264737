
import Vue from "vue";
import axios from "axios";
import store from "@/store";
import ProgressBar from "@/components/common/ProgressBar.vue";
import { TES_APP_ENDPOINT } from "@/constants/EndPoints";
import {
  IApplicantProfile,
  IDraftProgramInfo,
  IProgramsList,
  ITESUser
} from "@/types";
import tesContainer from "@/components/common/TESContainer.vue";
import routerMixin from "@/mixins/RouterMixin.vue";
import StepperNavigationMixinVue from "@/mixins/StepperNavigationMixin.vue";
import { BUTTON_NAMES, CNST_TEXT } from "@/constants/CommonConstants";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import ScrollMixin from "@/mixins/ScrollMixin.vue";
import { GPC } from "@/constants/GPCCode";
import dateHelperMixin from "@/mixins/dateHelperMixin.vue";

export default Vue.extend({
  name: "authorization-disclosure",
  components: {
    tesContainer,
    ProgressBar
  },
  mixins: [
    routerMixin,
    StepperNavigationMixinVue,
    AppPropsMixin,
    ScrollMixin,
    dateHelperMixin,
    ApplicationMixin
  ],
  data() {
    return {
      showProgressBar: false,
      showDefaultButtons: true,
      steps: 3,
      selected: ["Other"],
      reason: "At the request of the individual.",
      asProvided: "As provided",
      a1Text:
        "Medical Assistance Eligibility information on an ongoing basis for as long as MA-funded transportation services are desired.  Medical Assistance provider identity and location information.  Personal demographic information to allow transportation services to be provided.",
      a2Text:
        "The Commonwealth of Pennsylvania Department of Transportation, its agents and subcontractors.",
      otherText: "Until revoked by the individual in writing"
    };
  },
  computed: {
    disclosureInfo(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.MATP_DISC1].largeText,
          CNST_TEXT.THE_APPLICANTS,
          CNST_TEXT.THE_APPLICANTS,
          CNST_TEXT.THE_APPLICANTS,
          CNST_TEXT.THE_APPLICANTS
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.MATP_DISC1].largeText,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOUR
        );
      }
      return text;
    },
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      },
      set(newValue: IDraftProgramInfo) {
        store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(newValue);
      }
    },
    userInfo(): ITESUser {
      return store.getters.tesUserModule.getTesUser;
    },
    matpAuthInfo(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.MATP_AUTH].largeText,
          CNST_TEXT.THE_APPLICANT_IS
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.MATP_AUTH].largeText,
          CNST_TEXT.YOU_ARE
        );
      }
      return text;
    },
    applicantProfile: {
      get(): IApplicantProfile {
        return store.getters.applicantProfileModule.getApplicantProfile;
      },
      set(newValue: IApplicantProfile) {
        store.commit.applicantProfileModule.SET_APPLICANT_PROFILE(newValue);
      }
    },
    applicantProgramInfo(): IProgramsList {
      return store.getters.applicantProgramModule.getApplicantProgram;
    },
    applicantName(): string {
      let name: string = this.applicantProfile.applicantFirstName;
      if (this.applicantProfile.applicantMiddleInitial) {
        name = name
          .concat(" ")
          .concat(this.applicantProfile.applicantMiddleInitial);
      }
      if (this.applicantProfile.applicantLastName) {
        name = name.concat(" ").concat(this.applicantProfile.applicantLastName);
      }
      return name;
    },
    signatureName(): string {
      let name: string = this.applicantName;
      if (this.applicantProfile.onBehalfOfInd === "Y") {
        name = this.userInfo.userFirstName;
        if (this.userInfo.userLastName) {
          name = name.concat(" ").concat(this.userInfo.userLastName);
        }
      }
      return name;
    },
    relationship(): string {
      return this.isOrganizationUser || this.isAgencyUser
        ? "Organization staff member on behalf of the applicant"
        : this.applicantProfile.relationshipToApplicant;
    },
    applicantDob(): string {
      return this.getFormattedDOB(this.applicantProfile.applicantDob);
    },
    currentDate() {
      //@ts-ignore
      return this.getFormattedDOB(new Date());
    },
    applicantPhone(): any {
      let phone: string = this.applicantProfile.applicantPrimaryPhone;
      if (this.applicantProfile.applicantPrimaryPhoneExt) {
        phone = phone
          .concat("-")
          .concat(this.applicantProfile.applicantPrimaryPhoneExt);
      }
      return phone;
    },
    applicantAddress(): any {
      let address: string = this.applicantProfile.homeAddress.addressLine1;
      if (this.applicantProfile.homeAddress.addressLine2) {
        address = address
          .concat(", ")
          .concat(this.applicantProfile.homeAddress.addressLine2);
      }
      if (this.applicantProfile.homeAddress.city) {
        address = address
          .concat(", ")
          .concat(this.applicantProfile.homeAddress.city);
      }
      if (this.applicantProfile.homeAddress.state) {
        address = address
          .concat(", ")
          .concat(this.applicantProfile.homeAddress.state);
      }
      if (this.applicantProfile.homeAddress.zip) {
        address = address
          .concat(" ")
          .concat(this.applicantProfile.homeAddress.zip);
      }
      return address;
    }
  },
  created() {
    if (
      this.draftProgramInfo.programStatus ===
      //@ts-ignore
      this.uiFeature.NOT_QLFYD.displayName
    ) {
      this.showDefaultButtons = false;
    }
  },
  methods: {
    getFormattedDOB(dob: string) {
      //@ts-ignore
      return this.formatDateMixin(dob);
    },
    agree() {
      //@ts-ignore
      if (this.$refs.formAuthorization.validate()) {
        this.saveDraftProgramInfo("Agree");
      } else {
        //@ts-ignore
        this.scrollToErrorField(this.$refs.formAuthorization);
      }
    },
    nomatp() {
      //@ts-ignore
      if (this.$refs.formAuthorization.validate()) {
        this.saveDraftProgramInfo("nomatp");
      } else {
        //@ts-ignore
        this.scrollToErrorField(this.$refs.formAuthorization);
      }
    },
    changeValues() {
      this.showDefaultButtons = true;
      if (
        //@ts-ignore
        !this.isSingleFlow()
      ) {
        if (
          (this.applicantProfile.travelAttendantInd === "Y" &&
            (this.applicantProgramInfo.transitAgencyCode === "CPTA" ||
              this.applicantProgramInfo.transitAgencyCode === "CAT")) ||
          (this.applicantProgramInfo.transitAgencyCode === "CTDC" &&
            this.draftProgramInfo.medAssistanceEligibilityInd === "Y")
        ) {
          this.steps = 4;
        }
      } else if (this.draftProgramInfo.medAssistanceEligibilityInd !== "N") {
        this.steps = 3;
      } else {
        this.steps = 2;
      }
      if (this.draftProgramInfo.dhsAuthInd === "N") {
        this.steps = 1;
      }
      this.$emit("step-count", this.steps);
    },
    saveDraftProgramInfo(buttonName: string) {
      this.showProgressBar = true;

      this.draftProgramInfo.applicantProfileId = this.applicantProfile.applicantProfileId;
      this.draftProgramInfo.programInfo = this.applicantProgramInfo;

      if (this.applicantProgramInfo.statusCode === "IN_PRGS_C") {
        //@ts-ignore
        this.draftProgramInfo.programStatus = this.uiFeature.IN_PRGS_C.displayName;
      } else {
        //@ts-ignore
        this.draftProgramInfo.programStatus = this.uiFeature.IN_PRGS.displayName;
      }
      if ("Agree" === buttonName) {
        this.draftProgramInfo.dhsAuthInd = "Y";
        this.draftProgramInfo.medAssistanceEligibilityInd = "Y";
        //@ts-ignore
        this.draftProgramInfo.dhsAuthDate = new Date();
      }
      if ("nomatp" === buttonName) {
        //@ts-ignore
        this.draftProgramInfo.programStatus = this.uiFeature.NOT_QLFYD.displayName;
        this.draftProgramInfo.dhsAuthInd = "N";
        //@ts-ignore
        this.draftProgramInfo.dhsAuthDate = new Date();
        this.draftProgramInfo.medAssistanceEligibilityInd = "";
        this.draftProgramInfo.medicaidCardInd = "";
        this.draftProgramInfo.medicaidCardRecipientNum = "";
      }
      this.changeValues();
      //@ts-ignore
      this.draftProgramInfo.userInfo = this.userInfo;

      axios
        .post(TES_APP_ENDPOINT.SAVE_DRAFT_PRGM_INFO, this.draftProgramInfo)
        .then(response => {
          //@ts-ignore
          this.draftProgramInfo = response.data;
          //@ts-ignore
          store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(
            this.draftProgramInfo
          );
          //@ts-ignore
          store.commit.applicantProgramModule.SET_APPLICANT_PROGRAM(
            this.draftProgramInfo.programInfo
          );

          if (
            //@ts-ignore
            this.isSingleFlow() ||
            buttonName === "Agree" ||
            buttonName === BUTTON_NAMES.CANCEL
          ) {
            this.$emit("save-completed", buttonName);
          } else {
            //@ts-ignore
            this.forwardToDashboard();
          }
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    }
  }
});
