
import Vue from "vue";
import reviewProfileInfo from "@/components/application/profilereview/ReviewProfileInfo.vue";
import store from "@/store";
import { IApplicantProfile, ITESUser } from "@/types";
import ProgressBar from "@/components/common/ProgressBar.vue";

export default Vue.extend({
  name: "Review-Profile-Information",
  components: {
    reviewProfileInfo,
    ProgressBar
  },
  data() {
    return { showComponent: false, showProgressBar: false };
  },
  async created() {
    if (this.applicantProfile.applicantProfileId === 0) {
      this.showProgressBar = true;
      if (
        this.userInfo.userId === null ||
        this.userInfo.userId === undefined ||
        this.userInfo.userId === 0
      ) {
        await store.dispatch.tesUserModule.getUserDetails();
      }
      let profileID = this.$route.query.profileID;
      let scrnRespID = this.$route.query.scrnRespID;
      await store.dispatch.applicantProfileModule.getProfileInfo(profileID);
      await store.dispatch.screeningResponseModule.populateScreenResponse(
        scrnRespID
      );
      this.showComponent = true;
      this.showProgressBar = false;
    } else {
      this.showComponent = true;
    }
    let uiFeatureArray =
      "ENG,ARA,ZHO,FRE,DEU,GUJ,HAT,ITA,KOR,MKH,NEP,POL,POR,RUS,SPA,VIE,WHITE,BLACK,ASIAN,HISPANIC,NATV_AMRCN,NATV_HAWAN,RACE_OTH,NOT_RSPND,NATV_ALSKN,PACF_ISLN,UNKNWN,Y,N,M,F,O,P,NOT_START,IN_PRGS,RDY_SBMT,NOT_QLFYD,NOT_APPL,SBMTD,APPROVED,MAN_WHLCHR,MOT_WHLCHR,MOTO_SCO,OXYGN,WALKER,CANE,CAN_CRUC,SRVC_ANML,MOB_OTHER,PA,RE_COR,IN_PRGS_C,RDY_SBMT_C,WITHDRAWN,PEND_CONF,INELIGIBLE,INCOMPLETE,QUICK_SRCH,ER_INLINE,CHGLGN_TXT,TRANS_ASST,ADMN_USR,FMR,ENVRONMNT,TMP_APRVD,DMGPH_QNS,PRMRY_LNG,LNG_INTRPR,RCE_ETNCTY,NRG_HME,DCRG_NG_HM,PSNL_CR_HM,PNL_CHM_TP,TSPN_INF_Q,PSNL_C_ADT,CUR_MN_TRP,MOB_INF_QS,LFT_EN_VEH,ANY_MOB_DV,AL_MBD_LBL,CR_MN_VAN,WCHR_MET_Q,APEL_INPRG,ST_COLLG_Q,PFILE_QUES,IS_MAIL_AD,,ACCBL_FRMT,ADIO_CASS,BRAILLE,LRGE_PRNT,ACCBL_OTHR,ACBL_PRT_Q,ACBL_INFO,TRNSP_WAYS,BUS_TRLY,SUB_TRAIN,AMBLANC,AUTOMOBL,WALKING,TAXI_SERVC,TRNSP_OTHR,REL_TO_APL";

    //@ts-ignore
    store.commit.uiFeatureModule.SET_DATA_LDNG_STS(true);
    //@ts-ignore
    store.dispatch.uiFeatureModule.getUIFeatures(uiFeatureArray);
  },
  computed: {
    applicantProfile: {
      get(): IApplicantProfile {
        //@ts-ignore
        return store.getters.applicantProfileModule.getApplicantProfile;
      }
    },
    userInfo: {
      get(): ITESUser {
        //@ts-ignore
        return store.getters.tesUserModule.getTesUser;
      }
    }
  }
});
