
import Vue from "vue";
import store from "@/store";
import * as pbi from "powerbi-client";
import axios from "axios";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import { GPC } from "@/constants/GPCCode";
import { REPORTS_ENDPOINT } from "@/constants/EndPoints";
import { HTTP_STATUS } from "@/constants/HttpStatusCode";
import { ITESUser } from "@/types";

export default Vue.extend({
  name: "reports",
  mixins: [UIFeatureMixinVue],
  data() {
    return {
      embedUrl: "" as string,
      accessToken: "" as string,
      userId: 0 as number,
      agencyCode: "" as string,
      filter: "" as string,
      dashboardId: "",
      agencyCodes: "" as string
    };
  },

  computed: {
    isDataLoading(): boolean {
      return store.getters.uiFeatureModule.getLoadingStatus;
    },
    userInfo: {
      get(): ITESUser {
        //@ts-ignore
        return store.getters.tesUserModule.getTesUser;
      }
    }
  },
  mounted() {
    this.initializePowerBI().then(() => {
      //const permissions = pbi.models.Permissions.All;

      const config = {
        //@ts-ignore
        type: this.uiFeature[GPC.R_TYPE].largeText,
        //@ts-ignore
        tokenType: pbi.models.TokenType.Embed,
        accessToken: this.accessToken,
        embedUrl: this.embedUrl,
        //@ts-ignore
        pageView: this.uiFeature.R_PAGE_V.displayName,
        dashboardId: this.dashboardId,

        settings: {
          filterPaneEnabled: false, //this.params.IsFilterPane,
          navContentPaneEnabled: true,
          layoutType: pbi.models.LayoutType.Master
        }
      };

      let powerbi = new pbi.service.Service(
        pbi.factories.hpmFactory,
        pbi.factories.wpmpFactory,
        pbi.factories.routerFactory
      );

      const dashboardContainer = document.getElementById(
        "reportContainer"
      ) as HTMLElement;
      const dashboard = powerbi.embed(dashboardContainer, config);

      dashboard.off("loaded");
      dashboard.off("rendered");
      dashboard.on("error", function() {
        dashboard.off("error");
      });
    });
  },
  methods: {
    async initializePowerBI() {
      //@ts-ignore

      this.embedUrl = this.uiFeature[GPC.EMBD_URL].largeText;
      if (this.userInfo.transitAgencyCode) {
        this.agencyCode = this.userInfo.transitAgencyCode;

        await axios
          .get(REPORTS_ENDPOINT.GET_AGENCIES_REVIEW, {
            params: {
              reviewAgencyCode: this.agencyCode
            }
          })
          .then(response => {
            if (response.status === HTTP_STATUS.OK) {
              this.agencyCodes = response.data;
            }
          })
          .catch(error => {
            store.dispatch.alertModule.showAlertMessageById(
              error.response.data
            );
          });
      }

      this.filter =
        "&$filter=ProfileProgramExportRpt/TransitAgencyCode in (" +
        this.agencyCodes +
        ")";
      this.embedUrl = this.embedUrl + this.filter;

      await axios
        .post(REPORTS_ENDPOINT.GET_EMBD_TOKEN)
        .then(response => {
          if (response.status === HTTP_STATUS.OK) {
            this.accessToken = response.data;
          }
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(error.response.data);
        });
    }
  }
});
