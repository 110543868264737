
import Vue from "vue";
import ReviewRecommendationSummary from "@/components/application/reviewagencyinformation/ReviewRecommendationSummary.vue";
import AppealInProgrs from "@/components/application/program/AppealInProgrs.vue";
import ReviewEcolaneInfoSummary from "@/components/application/reviewagencyinformation/ReviewEcolaneInfoSummary.vue";
import ReviewerAttachmentsSummary from "@/components/application/reviewagencyinformation/ReviewerAttachmentsSummary.vue";
import AgencyReviewMixin from "@/mixins/AgencyReviewMixin.vue";
import StepperNavigationMixinVue from "@/mixins/StepperNavigationMixin.vue";
import ApplicationReviewControls from "@/components/application/program/ApplicationReviewControls.vue";
import { IDraftProgramInfo, IProgramsList } from "@/types";
import { expansionPanelIconObj } from "@/constants/CommonConstants";
import store from "@/store";

export default Vue.extend({
  name: "review-summary-w-ecolane",
  mixins: [AgencyReviewMixin, StepperNavigationMixinVue],
  components: {
    ReviewRecommendationSummary,
    ReviewEcolaneInfoSummary,
    ReviewerAttachmentsSummary,
    ApplicationReviewControls,
    AppealInProgrs
  },
  props: {
    applicantProgramInfoObj: {
      type: Object as () => IProgramsList
    },
    draftProgramInfoObj: {
      type: Object as () => IDraftProgramInfo
    },
    showApplRevCtrls: { type: Boolean, default: false }
  },
  data: function() {
    return {
      seen: true as boolean,
      showhideText: expansionPanelIconObj.hideText,
      showHideIcon: "fa-compress-alt" as string,
      singleFlow: false
    };
  },
  methods: {
    showHide() {
      if (!this.seen) {
        this.seen = true;
        this.showhideText = expansionPanelIconObj.hideText;
        this.showHideIcon = "fa-compress-alt";
      } else {
        this.seen = false;
        this.showhideText = expansionPanelIconObj.showText;
        this.showHideIcon = "fa-expand-alt";
      }
    }
  },
  created() {
    if (
      //@ts-ignore
      this.isSingleFlow()
    ) {
      this.singleFlow = true;
    }

    let payload = {
      //@ts-ignore
      applicationReviewId: this.applicationReviewModel.applicationReviewId,
      //@ts-ignore
      ecolaneUpdateInd: "Y"
    };
    store.dispatch.applicantEcolaneProfileModule.getEcolaneDataUpdateListByEcolaneUpdInd(
      payload
    );

    store.dispatch.applicantEcolaneProfileModule.getStgEcolaneCustProfileInfo({
      //@ts-ignore
      applicationReviewId: this.applicationReviewModel.applicationReviewId
    });
  }
});
