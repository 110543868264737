
import Vue from "vue";
import store from "@/store";
import ProgressBar from "@/components/common/ProgressBar.vue";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import TESRadioControl from "@/components/common/TESRadioControl.vue";

import { IApplicantProfile, IDraftProgramInfo } from "@/types";

import tesContainer from "@/components/common/TESContainer.vue";
import draftProgramMixin from "@/mixins/DraftProgramMixin.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import StepperNavigationMixinVue from "@/mixins/StepperNavigationMixin.vue";
import { BUTTON_NAMES, CNST_TEXT } from "@/constants/CommonConstants";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import InfoSection from "@/components/common/InfoSection.vue";
import ScrollMixin from "@/mixins/ScrollMixin.vue";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";
import { GPC } from "@/constants/GPCCode";
import DynamicTextMixin from "@/mixins/DynamicTextMixin.vue";

export default Vue.extend({
  name: "ADA-Voter-Registration",
  mixins: [
    fieldEntryMixin,
    draftProgramMixin,
    UIFeatureMixinVue,
    StepperNavigationMixinVue,
    AppPropsMixin,
    ScrollMixin,
    ApplicationMixin,
    DynamicTextMixin
  ],
  components: { tesContainer, TESRadioControl, ProgressBar, InfoSection },
  data() {
    return {
      showProgressBar: false
    };
  },
  props: {
    stepCount: {
      type: Number
    },
    stepperIndex: { type: Number, default: 0 }
  },
  computed: {
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      },
      set(newValue: IDraftProgramInfo) {
        store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(newValue);
      }
    },
    voterRegisterOptions(): any {
      return this.voterRegisterOption();
    },
    applicantProfile(): IApplicantProfile {
      return store.getters.applicantProfileModule.getApplicantProfile;
    },
    voterRegInfoHeaderOne(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrgAgyCtznonBehalfOfUser()
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.VOTER_REG].displayName,
          CNST_TEXT.THE_APPLICANT
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.VOTER_REG].displayName,
          CNST_TEXT.I
        );
      }
      return text;
    },
    voterRegInfoOne(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrgAgyCtznonBehalfOfUser()
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.VOTER_REG].largeText,
          CNST_TEXT.THE_APPLICANT,
          CNST_TEXT.THEY,
          CNST_TEXT.THE_APPLICANT
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.VOTER_REG].largeText,
          CNST_TEXT.YOU,
          CNST_TEXT.YOU,
          CNST_TEXT.YOU
        );
      }
      return text;
    },
    voterRegInfoHeaderTwo(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrgAgyCtznonBehalfOfUser()
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.VOTR_REG_2].displayName,
          CNST_TEXT.THE_APPLICANT
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.VOTR_REG_2].displayName,
          CNST_TEXT.I
        );
      }
      return text;
    },
    voterRegInfoTwo(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrgAgyCtznonBehalfOfUser()
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.VOTR_REG_2].largeText,
          CNST_TEXT.THE_APPLICANTS,
          CNST_TEXT.THE_APPLICANT.charAt(0).toUpperCase() +
            CNST_TEXT.THE_APPLICANT.slice(1),
          CNST_TEXT.THEIR,
          CNST_TEXT.THEY,
          CNST_TEXT.THEM,
          CNST_TEXT.THEIR,
          CNST_TEXT.THEY
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.VOTR_REG_2].largeText,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOU.charAt(0).toUpperCase() + CNST_TEXT.YOU.slice(1),
          CNST_TEXT.YOUR,
          CNST_TEXT.YOU,
          CNST_TEXT.YOU,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOU
        );
      }
      return text;
    },
    adaVoterQues(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrgAgyCtznonBehalfOfUser()
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.VOT_REG_Q1].largeText,
          CNST_TEXT.THE_APPLICANT_IS,
          CNST_TEXT.THEY,
          CNST_TEXT.THEY
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.VOT_REG_Q1].largeText,
          CNST_TEXT.YOU_ARE,
          CNST_TEXT.YOU,
          CNST_TEXT.YOU
        );
      }
      return text;
    },
    isSingleFlowFinalStep(): boolean {
      return this.stepperIndex === this.stepCount;
    }
  },
  methods: {
    voterRegisterOption(): any {
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        this.uiFeature[
          "VOTER_REG"
          //@ts-ignore
        ].children[2].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["VOTER_REG"].children[2].largeText,
          CNST_TEXT.THE_APPLICANT_IS,
          CNST_TEXT.THEY
        );
      } else {
        //@ts-ignore
        this.uiFeature[
          "VOTER_REG"
          //@ts-ignore
        ].children[2].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["VOTER_REG"].children[2].largeText,
          CNST_TEXT.I_AM,
          CNST_TEXT.I
        );
      }
      //@ts-ignore
      return this.uiFeature.VOTER_REG.children;
    },
    saveContinue() {
      //@ts-ignore
      if (this.$refs.adaVoterRegForm.validate()) {
        this.saveADAProgramInfo("SAVECONTINUE");
      } else {
        //@ts-ignore
        this.scrollToErrorField(this.$refs.adaVoterRegForm);
      }
    },
    save(save: string) {
      //@ts-ignore
      if (this.$refs.adaVoterRegForm.validate()) {
        this.saveADAProgramInfo(save);
      } else {
        //@ts-ignore
        this.scrollToErrorField(this.$refs.adaVoterRegForm);
      }
    },
    cancel() {
      //@ts-ignore
      if (this.$refs.adaVoterRegForm.validate()) {
        this.saveADAProgramInfo("CANCEL");
      } else {
        //@ts-ignore
        this.scrollToErrorField(this.$refs.adaVoterRegForm);
      }
    },
    saveADAProgramInfo(buttonName: string) {
      this.showProgressBar = true;
      let stepperIndex = 3;
      if (
        //@ts-ignore
        this.isSingleFlow()
      ) {
        stepperIndex = this.stepCount;
      }
      //@ts-ignore
      this.saveProgramInfo(
        this.draftProgramInfo,
        stepperIndex,
        this.stepCount,
        buttonName
      )
        //@ts-ignore
        .then(response => {
          this.draftProgramInfo = response.data;

          store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(
            this.draftProgramInfo
          );

          store.commit.applicantProgramModule.SET_APPLICANT_PROGRAM(
            this.draftProgramInfo.programInfo
          );

          if (
            //@ts-ignore
            this.isSingleFlow() ||
            buttonName === BUTTON_NAMES.SAVE ||
            buttonName === BUTTON_NAMES.CANCEL
          ) {
            this.$emit("save-completed", buttonName);
          } else {
            //@ts-ignore
            this.forwardToDashboard();
          }
        })
        //@ts-ignore
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    }
  }
});
