
import Vue from "vue";
import store from "@/store";
import { IAppProperties } from "@/types";

export default Vue.extend({
  computed: {
    appProps: {
      get(): IAppProperties {
        return store.getters.appModule.getAppProperties;
      },
      set(appProd: IAppProperties) {
        store.commit.appModule.SET_APP_PROPERTIES(appProd);
      }
    }
  },
  methods: {
    showAlertError(message: string) {
      //@ts-ignore
      store.dispatch.alertModule.showAlertError(message);
    },
    showAlertMessage(message: string, alertType: string) {
      let options = { message: message, alertType: alertType };
      //@ts-ignore
      store.dispatch.alertModule.showAlertMessage(options);
    }
  }
});
