
import Vue from "vue";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import tesProgramTemplate from "@/components/common/TESProgramTemplate.vue";
import { IDraftProgramInfo, IProgramsList } from "@/types";
import SSRDetailInfo from "@/components/application/program/ssr/SSRDetailInfo.vue";
import store from "@/store";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import router from "@/router";
import StepperNavigationMixinVue from "@/mixins/StepperNavigationMixin.vue";
import { BUTTON_NAMES } from "@/constants/CommonConstants";
import routerMixin from "@/mixins/RouterMixin.vue";
import AttachmentsMixinVue from "@/mixins/AttachmentsMixin.vue";
import SSRAttachments from "@/components/application/program/attachments/SSRIndividualFlowAttachments.vue";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";

export default Vue.extend({
  name: "program-ssr",
  mixins: [
    fieldEntryMixin,
    UIFeatureMixinVue,
    StepperNavigationMixinVue,
    routerMixin,
    AttachmentsMixinVue,
    ApplicationMixin
  ],
  components: {
    tesProgramTemplate,
    SSRDetailInfo,
    SSRAttachments
  },
  data: function() {
    return {
      stepCount: 2 as number,
      documentTypeCodes: [] as string[],
      byPassPrgmChecked: false
    };
  },
  methods: {
    saveCompleted(buttonName: string) {
      if (buttonName === "CANCEL") {
        this.continueToDashboard();
      }
      //@ts-ignore
      else if (this.$refs.programSSRTemplate.stepperIndex !== this.stepCount) {
        //@ts-ignore
        this.$refs.programSSRTemplate.nextStep();
        //@ts-ignore
        this.scrollToSectionHeader(this.$refs.programSSRTemplate);
      } else if (
        //@ts-ignore
        this.isSingleFlow()
      ) {
        if (
          buttonName === BUTTON_NAMES.SAVECONTINUE ||
          buttonName === BUTTON_NAMES.CONTINUE ||
          buttonName === BUTTON_NAMES.SAVECONTBYPASS
        ) {
          let nextProgram = "";
          //@ts-ignore
          nextProgram = this.nextProgram(
            "SSR",
            this.applicantProgramInfo.transitAgencyCode
          );
          if (nextProgram === "DOCS" || nextProgram === "REVIEW") {
            router.push({
              path: "/application-program/" + nextProgram,
              query: {
                profileID: String(this.applicantProgramInfo.applicantProfileId),
                prgmType: nextProgram
              }
            });
          } else {
            router.push({
              path: "/application-program/" + nextProgram,
              query: {
                profileID: String(this.applicantProgramInfo.applicantProfileId),
                prgmType: nextProgram,
                agencyType: this.applicantProgramInfo.transitAgencyCode
              }
            });
          }
        }
      } else {
        router.push({
          path:
            "/application-review/" + this.applicantProgramInfo.programTypeCode
        });
      }
    },
    changeStepCount(steps: number) {
      this.stepCount = steps;
    },
    continueToDashboard() {
      //@ts-ignore
      this.forwardToDashboard();
    },
    byPassProgram(byPassCheck: boolean) {
      this.byPassPrgmChecked = byPassCheck;
    }
  },
  computed: {
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        //@ts-ignore
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      },
      set(newValue: IDraftProgramInfo) {
        //@ts-ignore
        store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(newValue);
      }
    },
    applicantProgramInfo: {
      get(): IProgramsList {
        //@ts-ignore
        return store.getters.applicantProgramModule.getApplicantProgram;
      }
    },
    applicantAge: {
      get(): number {
        let applicantDOB =
          //@ts-ignore
          store.getters.applicantProfileModule.getApplicantProfile.applicantDob;
        let currentDate = new Date();
        let birthDate = new Date(applicantDOB);
        //@ts-ignore
        let difference: any = currentDate - birthDate;
        return Math.floor(difference / 31557600000);
      }
    },
    docDescCodes(): any {
      //@ts-ignore
      return this.uiFeature.AGE_PROOF.children;
    }
  },
  created() {
    if (
      (null !== this.draftProgramInfo &&
        this.draftProgramInfo.ageVerificationMethodCode === "Y" &&
        this.draftProgramInfo.driverLicenseVerifyCode === "VRFD") ||
      //@ts-ignore
      this.isSingleFlow()
    ) {
      this.stepCount = 1;
    } else {
      this.stepCount = 2;
    }
    //@ts-ignore
    if (!this.isSingleFlow()) {
      //@ts-ignore
      this.documentTypeCodes = this.getSSRDocumentTypes();
    } else {
      if (this.applicantProgramInfo.byPassInd === "Y") {
        this.byPassPrgmChecked = true;
      }
    }
  }
});
