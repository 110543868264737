<template>
  <div>
    <v-row v-if="index === 0" class="font-weight-bold" no-gutters>
      <v-col class="pt-5 font-weight-bold">
        <span> Uploaded Documents:</span>
      </v-col>
    </v-row>
    <v-row
      v-if="index === 0"
      class="font-weight-bold"
      style="background: #464646;"
      no-gutters
    >
      <v-col cols="10">
        <v-row>
          <v-col class="pt-5 pl-6" cols="4">
            <span style="color: white"> Document</span>
          </v-col>
          <v-col class="pt-5 pl-6" cols="3">
            <span style="color: white"> Type</span>
          </v-col>
          <v-col class="pt-5 pl-1" cols="3">
            <span style="color: white"> Description</span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-divider v-if="index === 0" aria-hidden="true"></v-divider>
    <v-divider
      v-if="index > 0"
      :key="'d' + index"
      aria-hidden="true"
    ></v-divider>

    <v-list-item
      :key="isEcs ? doc.sequenceNumber : doc.externalKey"
      @click.stop
      :ripple="false"
    >
      <v-list-item-content>
        <v-container fluid>
          <!-- Application Document -->
          <v-row v-if="!hideDocDescription">
            <v-col cols="10">
              <v-row class="pa-0" wrap>
                <v-col cols="4">
                  <v-progress-circular
                    indeterminate
                    v-if="isDownloading"
                    color="primary"
                    class="downloadingIcon"
                  />
                  <a @click="downloadDocument()" class="downloadLink">
                    {{ documentName }}
                  </a>
                </v-col>
                <v-col cols="3">
                  <span>{{ docType }}</span>
                </v-col>
                <v-col cols="3">
                  <span>{{ docDescription }}</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col v-if="!renderReviewSummaryWEcolane">
              <v-dialog eager v-model="dialog" persistent width="400">
                <template v-slot:activator="{ on }">
                  <v-btn
                    text
                    v-on="on"
                    :disabled="false"
                    aria-label="Delete Uploaded Document"
                  >
                    <v-icon class="mr-2">fa-times</v-icon>
                    Delete
                  </v-btn>
                </template>
                <v-card>
                  <v-card-text class="pa-4" flat>
                    <span class="font-weight-bold">
                      Are you sure you want to delete this document?
                    </span>
                  </v-card-text>
                  <v-divider aria-hidden="true"></v-divider>
                  <v-card-actions class="justify-center">
                    <v-btn tile @click="dialog = false" class="secondaryButton"
                      >No</v-btn
                    >
                    <v-btn tile @click="deleteDocument()" class="primaryButton"
                      >Yes</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </v-container>
      </v-list-item-content>
    </v-list-item>
    <v-progress-linear
      pa-0
      indeterminate
      v-if="isUpdating"
      class="docUpdateProgessbar"
    />
  </div>
</template>

<script>
import FieldEnrtyMixin from "@/mixins/fieldEntryMixin.vue";
import axios from "axios";
import { LOGGER } from "@/util/logger";
import { ECS_ENDPOINT } from "@/constants/EndPoints";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import AgencyReviewMixin from "@/mixins/AgencyReviewMixin.vue";

export default {
  name: "Reviewer-DocumentRow",
  components: {},
  mixins: [FieldEnrtyMixin, UIFeatureMixinVue, AgencyReviewMixin],
  data() {
    return {
      dialog: false,
      localDoc: JSON.parse(JSON.stringify(this.doc)),
      isUpdating: false,
      thumbnail: "",
      isLoadingThumbnail: true,
      isImage: false,
      isDownloading: false
    };
  },
  props: {
    doc: { type: Object, default: null },
    showHeaders: { type: Boolean, default: false },
    index: { type: Number, default: 0 },
    isEcs: { type: Boolean, default: false },
    hideDocDescription: { type: Boolean, default: false },
    docDescCodes: {
      type: Array,
      default: null
    },
    documentTypeCode: {
      type: String,
      default: null
    }
  },
  computed: {
    docType: {
      get() {
        return this.uiFeature[this.localDoc.documentTypeCode].displayName;
      },
      set(newVal) {
        this.localDoc.documentTypeCode = newVal;
      }
    },
    ecsDocType: {
      get() {
        return this.localDoc.typeCode;
      },
      set(newVal) {
        this.localDoc.typeCode = newVal;
      }
    },
    docDescription: {
      get() {
        //@ts-ignore
        return this.localDoc.description;
      },
      set(newVal) {
        this.localDoc.description = newVal;
      }
    },
    documentName: {
      get() {
        return this.localDoc.filename.trim();
      },
      set(newVal) {
        this.localDoc.filename = newVal;
      }
    },
    showHeader() {
      return this.showHeaders || this.$vuetify.breakpoint.smAndDown;
    }
  },
  mounted() {
    if (!this.isEcs) {
      if (this.doc.mimeType.trim() !== "pdf") {
        this.downloadThumbnail();
      }
    }
  },
  methods: {
    getDescText(array, value) {
      let desc;
      array.find(function(obj) {
        if (obj.value === value) {
          desc = obj.text;
        }
      });
      return desc;
    },
    getDocNumberFromKey(key) {
      return parseInt(key.charAt(key.length - 1));
    },
    downloadDocument() {
      this.isDownloading = true;

      axios
        .get(ECS_ENDPOINT.RETRIEVE_DOCUMENT, {
          params: {
            attachmentId: this.localDoc.attachmentId
          },
          responseType: "arraybuffer"
        })
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", this.localDoc.filename);
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch(error => {
          LOGGER.logException(error);
        })
        .finally(() => {
          this.isDownloading = false;
        });
    },
    deleteDocument() {
      let url = ECS_ENDPOINT.DELETE_DOCUMENT;

      axios
        .get(url, {
          params: {
            attachmentId: this.localDoc.attachmentId,
            applicantProfileId: this.localDoc.applicantProfileId
          }
        })
        .then(() => {
          this.$emit("removeDoc");
        })
        .catch(error => {
          LOGGER.logException(error);
        });

      this.dialog = false;
    },
    updateDocument() {
      this.isUpdating = true;
      let documentNumber = this.getDocNumberFromKey(this.doc.externalKey);
      // eslint-disable-next-line
      const url = `${DOCUMENT_ENDPOINT.DB2}${this.permitApplicationId}/${documentNumber}/${this.doc.version}`;

      let formData = new FormData();

      formData.append(
        "json",
        new Blob([JSON.stringify(this.localDoc)], {
          type: "application/json"
        })
      );

      axios
        .put(url, formData)
        .then(() => {
          this.doc.subType = this.docType;
          this.doc.description = this.docDescription;
        })
        .catch(error => {
          LOGGER.logException(error);
        })
        .finally(() => {
          this.isUpdating = false;
        });
    }
  },
  watch: {
    permitApplicationId(val) {
      if (val && !this.isEcs && this.doc.mimeType.trim() !== "pdf") {
        this.downloadThumbnail();
      }
    }
  }
};
</script>

<style scoped>
.documentTypeSelector {
  overflow: hidden;
}
.docUpdateProgessbar {
  margin-top: 0;
  padding: 0px;
  top: -23px;
  margin-bottom: -7px;
}
.imgThumbnail {
  top: 20px;
  left: 10px;
  position: absolute;
}

.downloadingIcon {
  position: absolute;
  top: 40px;
  width: 15px !important;
}
.downloadLink {
  position: absolute;
  left: 50px;
}
.downloadLink:hover {
  text-decoration: underline;
}
</style>
