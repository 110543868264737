
import Vue from "vue";
import axios from "axios";
import store from "@/store";
import {
  IDraftProgramInfo,
  ITESUser,
  IProgramEligModel,
  IsubmitReqModel,
  IScreeningResponse,
  IPromiseSearchModel,
  IApplicantProfile
} from "@/types";
import ProgressBar from "@/components/common/ProgressBar.vue";
import { TES_APP_ENDPOINT } from "@/constants/EndPoints";
import ApplicantProfileMixin from "@/mixins/ApplicantProfileMixin.vue";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import routerMixin from "@/mixins/RouterMixin.vue";
import StepperNavigationMixinVue from "@/mixins/StepperNavigationMixin.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";

export default Vue.extend({
  name: "NotQualify-Application",
  mixins: [
    ApplicantProfileMixin,
    AppPropsMixin,
    routerMixin,
    StepperNavigationMixinVue,
    UIFeatureMixinVue
  ],
  components: {
    ProgressBar
  },
  data() {
    return {
      showProgressBar: false,
      submitReqModel: {} as IsubmitReqModel,
      promiseSrchModel: {} as IPromiseSearchModel
    };
  },
  computed: {
    userInfo(): ITESUser {
      return store.getters.tesUserModule.getTesUser;
    },
    applicantProfile(): IApplicantProfile {
      return store.getters.applicantProfileModule.getApplicantProfile;
    },
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      },
      set(newValue: IDraftProgramInfo) {
        store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(newValue);
      }
    },
    screeningResponse: {
      get(): IScreeningResponse {
        //@ts-ignore
        return store.getters.screeningResponseModule.getScreeningResponse;
      }
    },
    programEligModel(): IProgramEligModel {
      return store.getters.programEligModule.getProgramElig;
    }
  },
  methods: {
    GotoDashboard() {
      //@ts-ignore
      this.forwardToDashboard();
    },
    getFormattedDOBForPrms(dob: string) {
      //@ts-ignore
      return this.formatDateMixinForPromise(dob);
    },
    populateRequestObject() {
      this.promiseSrchModel.profileID = this.applicantProfile.applicantProfileId;
      this.promiseSrchModel.firstName = this.applicantProfile.applicantFirstName;
      this.promiseSrchModel.lastName = this.applicantProfile.applicantLastName;
      this.promiseSrchModel.dob = this.getFormattedDOBForPrms(
        this.applicantProfile.applicantDob
      );
    },
    submitApplication() {
      this.showProgressBar = true;

      this.draftProgramInfo.programInfoList = this.programEligModel.preEligiblePrograms;
      this.draftProgramInfo.userInfo = this.userInfo;

      this.submitReqModel.draftProgramInfoModel = this.draftProgramInfo;
      if (
        this.screeningResponse.medicaidInd &&
        this.screeningResponse.medicaidInd === "No"
      ) {
        this.populateRequestObject();
        this.submitReqModel.promiseSearchModel = this.promiseSrchModel;
      }

      axios
        .post(TES_APP_ENDPOINT.SUBMIT_APPLICATION, this.submitReqModel)
        .then(response => {
          store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(
            response.data
          );
          this.GotoDashboard();
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    }
  }
});
