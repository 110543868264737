import { IScreeningResponse } from "@/types";
import { clone } from "@/util/clone";
import { screeningResponseDefault } from "@/data/AppData";
import { moduleActionContext } from "..";
import { defineModule } from "direct-vuex";
import { DASHBOARD_ENDPOINT, TES_APP_ENDPOINT } from "@/constants/EndPoints";
import axios from "axios";

export interface ScreeningResponseState {
  screeningResponseModel: IScreeningResponse;
}

const screeningResponseModule = defineModule({
  namespaced: true,
  state: {
    screeningResponseModel: clone(screeningResponseDefault)
  } as ScreeningResponseState,
  getters: {
    getScreeningResponse: (state: ScreeningResponseState) =>
      state.screeningResponseModel
  },
  mutations: {
    SET_SCREENING_RESPONSE(
      state: ScreeningResponseState,
      newScreeningResponse: IScreeningResponse
    ) {
      state.screeningResponseModel = newScreeningResponse;
    }
  },
  actions: {
    resetData(context: any) {
      let { commit } = screeningResponseModuleContext(context);
      //clearing the screeningResponseModel data in store.
      let screeningResponseModel = clone(screeningResponseDefault);
      //@ts-ignore
      commit.SET_SCREENING_RESPONSE(screeningResponseModel);
    },
    async populateScreenResponse(context: any, scrnRespID: any) {
      let { commit, rootDispatch } = screeningResponseModuleContext(context);
      await axios
        .get(DASHBOARD_ENDPOINT.GET_SCREENING_RESPONSE_BY_ID, {
          params: {
            screeningResponseId: scrnRespID
          }
        })
        .then(response => {
          commit.SET_SCREENING_RESPONSE(response.data);
        })
        .catch(() => {
          rootDispatch.alertModule.showAlertMessageByGPC("ER_INLINE");
        });
    },
    async populateScrnRespByApplProfileID(context: any, applProfileID: any) {
      let { commit, rootDispatch } = screeningResponseModuleContext(context);
      await axios
        .get(TES_APP_ENDPOINT.GET_SCRNG_RSP, {
          params: {
            applicantProfileID: applProfileID
          }
        })
        .then(response => {
          commit.SET_SCREENING_RESPONSE(response.data);
        })
        .catch(() => {
          rootDispatch.alertModule.showAlertMessageByGPC("ER_INLINE");
        });
    }
  }
});

export default screeningResponseModule;
const screeningResponseModuleContext = (context: any) =>
  moduleActionContext(context, screeningResponseModule);
