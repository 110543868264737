
import Vue from "vue";
import axios from "axios";
import ProgressBar from "@/components/common/ProgressBar.vue";
import TesAddressTemplate from "@/components/common/TesAddressTemplate.vue";
import store from "@/store";
import {
  IApplicationReviewModel,
  IApplicantEcolaneProfileModel,
  IApplicantProfile,
  IDraftProgramInfo,
  IProgramsList,
  ITESEcolaneModel,
  IStgEcolaneCustProfileModel,
  IApplicantAddress,
  IEcolaneDataUpdate,
  IPostingStgModel
} from "@/types";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import { GPC } from "@/constants/GPCCode";
import { clone } from "@/util/clone";
import dateHelperMixinVue from "@/mixins/dateHelperMixin.vue";
import fieldEntryMixinVue from "@/mixins/fieldEntryMixin.vue";
import { TES_APP_ENDPOINT, ECOLANE_ENDPOINT } from "@/constants/EndPoints";
import AppPropsMixinVue from "@/mixins/AppPropsMixin.vue";
import RouterMixinVue from "@/mixins/RouterMixin.vue";
import {
  tesEcolaneRequestWrapperDefault,
  stgEcolaneCustProfileDefault,
  applicantAddress,
  ecolaneDataUpdateDefault,
  postingStgModelDefault
} from "@/data/AppData";
import InfoSection from "@/components/common/InfoSection.vue";
import EcolaneDiff from "@/components/common/EcolaneDiff.vue";
import AgencyReviewMixinVue from "@/mixins/AgencyReviewMixin.vue";

export default Vue.extend({
  mixins: [
    UIFeatureMixinVue,
    dateHelperMixinVue,
    fieldEntryMixinVue,
    AppPropsMixinVue,
    RouterMixinVue,
    AgencyReviewMixinVue
  ],
  name: "review-app-ecolane",
  components: {
    ProgressBar,
    TesAddressTemplate,
    InfoSection,
    EcolaneDiff
  },
  data() {
    return {
      showProgressBar: false,
      ecolaneSearchDialog: false,
      headers: [
        {
          text: "",
          sortable: false
        },
        { text: "Customer Number", value: "customerNumber" },
        { text: "First Name", value: "firstName" },
        { text: "Last Name", value: "lastName" },
        { text: "DOB", value: "dateOfBirth" },
        { text: "Address", value: "homeAddress" },
        { text: "Phone", value: "phoneLandline" },
        { text: "Email", value: "email" }
      ],
      customers: [],
      ecolaneRequestModel: {} as ITESEcolaneModel,
      showNewRecordMsg: false,
      tesEcolaneRequestWrapper: clone(tesEcolaneRequestWrapperDefault),
      showDiffSection: false
    };
  },
  async created() {
    this.showProgressBar = true;
    let params = {
      applicantProfileId: this.applicantProgramInfo.applicantProfileId,
      transitAgencyCode: this.applicantProgramInfo.transitAgencyCode
    };
    let response: IApplicantEcolaneProfileModel = await store.dispatch.applicantEcolaneProfileModule.loadApplicantEcolaneProfile(
      params
    );
    if (this.applicationReviewModel.ecolaneMatchInd === "Y") {
      this.showNewRecordMsg = true;
    }

    await store.dispatch.applicantEcolaneProfileModule.getStgEcolaneCustProfileInfo(
      {
        applicationReviewId: this.applicationReviewModel.applicationReviewId
      }
    );

    if (response) {
      this.ecolaneRequestModel.customerNumber = response.ecolaneCustomerId;
      this.retreieveEcolaneCustomers("autoLoad");
    }
    this.showProgressBar = false;
  },
  methods: {
    saveEcolaneInfo() {
      if (
        this.selectedEcolaneCustomer.ecolaneCustomerId ||
        this.showNewRecordMsg
      ) {
        if (this.selectedEcolaneCustomer.ecolaneCustomerId) {
          this.saveSelectedEcolaneCustomer();
          this.saveStgEcolaneInfo();
          this.saveEcolaneDataUpdate();
          this.applicationReviewModel.ecolaneMatchInd = "";
        } else {
          this.applicationReviewModel.ecolaneMatchInd = "Y";
        }
        this.updateEcolaneNoMatch();
      }
    },
    saveSelectedEcolaneCustomer() {
      this.showProgressBar = true;
      let url = ECOLANE_ENDPOINT.SAVE_ECOLANE_INFO;

      this.selectedEcolaneCustomer.applicantProfileId = this.applicantProgramInfo.applicantProfileId;
      this.selectedEcolaneCustomer.transitAgencyCode = this.applicantProgramInfo.transitAgencyCode;

      axios
        .post(url, this.selectedEcolaneCustomer)
        .then(response => {
          this.selectedEcolaneCustomer = response.data;
          this.$emit("save-completed");
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    },
    updateEcolaneNoMatch() {
      this.showProgressBar = true;
      let url = TES_APP_ENDPOINT.UPDATE_ECOLANE_NOMATCH_IND;
      axios
        .post(url, this.applicationReviewModel)
        .then(response => {
          this.$emit("save-completed");
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    },
    setSelectEcolaneCustomer(ecolaneCustomer: any) {
      if (
        !this.selectedEcolaneCustomer ||
        !this.selectedEcolaneCustomer.ecolaneCustomerId
      ) {
        this.selectedEcolaneCustomer = {} as IApplicantEcolaneProfileModel;
      }
      this.selectedEcolaneCustomer.ecolaneCustomerId =
        ecolaneCustomer.customerNumber;
      this.selectedEcolaneCustomer.directLink = ecolaneCustomer.directLink;

      this.ecolaneSearchDialog = false;
      this.showNewRecordMsg = false;
      this.applicationReviewModel.ecolaneMatchInd = "";
      this.populateStgEcolaneCustProfileModel(ecolaneCustomer);
      this.populateEcolaneUpdateDataList();
      //@ts-ignore
      this.showDiffSection = this.showDiffSectionMethod;
    },
    retreieveEcolaneCustomers(autoRefresh: string) {
      this.showProgressBar = true;
      let url = ECOLANE_ENDPOINT.RETRIEVE_ECOLANE_FROM_ORIGINAL_ECOLANE_SERVICE;
      let applicantDOB = this.applicantProfileInfo.applicantDob.split("-");
      this.ecolaneRequestModel.lastName = this.applicantProfileInfo.applicantLastName;

      this.ecolaneRequestModel.yearOfBirth = parseInt(applicantDOB[0]);
      this.ecolaneRequestModel.monthOfBirth = parseInt(applicantDOB[1]);
      this.ecolaneRequestModel.dayOfBirth = parseInt(applicantDOB[2]);
      if (autoRefresh !== "autoLoad") {
        this.ecolaneRequestModel.customerNumber = "";
      }

      this.tesEcolaneRequestWrapper.tesEcolaneRequest = this.ecolaneRequestModel;
      this.tesEcolaneRequestWrapper.agencyCode = this.applicantProgramInfo.transitAgencyCode;
      this.tesEcolaneRequestWrapper.countyCode = this.applicantProfileInfo.homeAddress.paCountyCode;
      axios
        .post(url, this.tesEcolaneRequestWrapper)
        .then(response => {
          this.customers = response.data;
          if (autoRefresh == "autoLoad") {
            this.populateStgEcolaneCustProfileModel(this.customers[0]);
            this.populateEcolaneUpdateDataList();
          } else {
            this.ecolaneSearchDialog = true;
          }
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    },
    noMatchSelected() {
      if (
        typeof this.selectedEcolaneCustomer.ecolaneCustomerId !== "undefined" &&
        this.selectedEcolaneCustomer.ecolaneCustomerId !== ""
      ) {
        this.showProgressBar = true;
        let url = ECOLANE_ENDPOINT.DELETE_ECOLANE_CUSTOMER_INFO_FROM_TES;
        axios
          .post(url, this.selectedEcolaneCustomer)
          .catch(error => {
            store.dispatch.alertModule.showAlertMessageById(
              error.response.data.id
            );
          })
          .finally(() => {
            this.showProgressBar = false;
          });
      }
      this.showNewRecordMsg = true;
      this.ecolaneSearchDialog = false;
      this.selectedEcolaneCustomer = {} as IApplicantEcolaneProfileModel;

      this.deleteStgEcolaneCustProfile();
      this.deleteEcolaneUpdateDataList();
    },
    saveStgEcolaneInfo() {
      let url = ECOLANE_ENDPOINT.SAVE_STG_ECOLANE_INFO;
      axios
        .post(url, this.stgEcolaneCustProfile)
        .then(response => {
          this.stgEcolaneCustProfile = response.data;
          this.stgEcolaneCustProfile.homeAddress = this.populateHomeAddressObj(
            this.stgEcolaneCustProfile
          );
          this.stgEcolaneCustProfile.mailAddress = this.populateMailAddressObj(
            this.stgEcolaneCustProfile
          );
          store.commit.applicantEcolaneProfileModule.SET_STG_ECOLANE_CUST_PROFILE(
            this.stgEcolaneCustProfile
          );
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        });
    },
    populateStgEcolaneCustProfileModel(ecolaneCustomer: any) {
      if (!this.stgEcolaneCustProfile) {
        this.stgEcolaneCustProfile = clone(stgEcolaneCustProfileDefault);
      }
      this.stgEcolaneCustProfile.applicationReviewId = this.applicationReviewModel.applicationReviewId;
      this.stgEcolaneCustProfile.county = ecolaneCustomer.county;
      this.stgEcolaneCustProfile.customerDOB = ecolaneCustomer.dateOfBirth;
      this.stgEcolaneCustProfile.customerFirstName = ecolaneCustomer.firstName;
      this.stgEcolaneCustProfile.customerLastName = ecolaneCustomer.lastName;
      this.stgEcolaneCustProfile.customerMiddleInitial =
        ecolaneCustomer.middleInitial;
      this.stgEcolaneCustProfile.customerNumber =
        ecolaneCustomer.customerNumber;
      this.stgEcolaneCustProfile.defaultDevice = ecolaneCustomer.defaultDevice;
      this.stgEcolaneCustProfile.directLink = ecolaneCustomer.directLink;
      this.stgEcolaneCustProfile.directLinkLegacy =
        ecolaneCustomer.directLinkLegacy;
      if (ecolaneCustomer.disabilityMental) {
        this.stgEcolaneCustProfile.disabilityMentalInd = "Y";
      } else {
        this.stgEcolaneCustProfile.disabilityMentalInd = "N";
      }
      if (ecolaneCustomer.disabilityPhysical) {
        this.stgEcolaneCustProfile.disabilityPhysicalInd = "Y";
      } else {
        this.stgEcolaneCustProfile.disabilityPhysicalInd = "N";
      }
      if (ecolaneCustomer.disabilityVisual) {
        this.stgEcolaneCustProfile.disabilityVisualInd = "Y";
      } else {
        this.stgEcolaneCustProfile.disabilityVisualInd = "N";
      }
      this.stgEcolaneCustProfile.emailAddress = ecolaneCustomer.email;
      this.stgEcolaneCustProfile.emergencyContactAddress =
        ecolaneCustomer.emergencyContactAddress;
      this.stgEcolaneCustProfile.emergencyContactName =
        ecolaneCustomer.emergencyContactName;
      this.stgEcolaneCustProfile.emergencyContactPhoneDay =
        ecolaneCustomer.emergencyContactPhoneDay;
      this.stgEcolaneCustProfile.emergencyContactPhoneNight =
        ecolaneCustomer.emergencyContactPhoneNight;
      this.stgEcolaneCustProfile.ethnicity = ecolaneCustomer.ethnicity;
      this.stgEcolaneCustProfile.language = this.getDisplayTextForEcolaneLanguageCode(
        ecolaneCustomer.languageCode
      );
      this.stgEcolaneCustProfile.gender = ecolaneCustomer.gender;
      this.stgEcolaneCustProfile.socialSecurityNumber =
        ecolaneCustomer.socialSecurityNumber;
      this.stgEcolaneCustProfile.medicaidNumber =
        ecolaneCustomer.medicaidNumber;
      if (ecolaneCustomer.homeAddress) {
        this.stgEcolaneCustProfile.homeAddress = ecolaneCustomer.homeAddress;
        this.stgEcolaneCustProfile.homeAddressAptNum =
          ecolaneCustomer.homeAddress.apartmentNumber;
        this.stgEcolaneCustProfile.homeAddressCity =
          ecolaneCustomer.homeAddress.city;
        this.stgEcolaneCustProfile.homeAddressState =
          ecolaneCustomer.homeAddress.state;
        this.stgEcolaneCustProfile.homeAddressStreet =
          ecolaneCustomer.homeAddress.street;
        this.stgEcolaneCustProfile.homeAddressStreetNum =
          ecolaneCustomer.homeAddress.streetNumber;
        this.stgEcolaneCustProfile.homeAddressZip =
          ecolaneCustomer.homeAddress.postcode;
        this.stgEcolaneCustProfile.homeAddressCounty =
          ecolaneCustomer.homeAddress.county;
      } else {
        this.stgEcolaneCustProfile.homeAddress = applicantAddress;
        this.stgEcolaneCustProfile.homeAddressAptNum = "";
        this.stgEcolaneCustProfile.homeAddressCity = "";
        this.stgEcolaneCustProfile.homeAddressState = "";
        this.stgEcolaneCustProfile.homeAddressStreet = "";
        this.stgEcolaneCustProfile.homeAddressStreetNum = "";
        this.stgEcolaneCustProfile.homeAddressZip = "";
        this.stgEcolaneCustProfile.homeAddressCounty = "";
      }
      this.stgEcolaneCustProfile.landlinePhone = ecolaneCustomer.phoneLandline;
      this.stgEcolaneCustProfile.mobilePhone = ecolaneCustomer.phoneMobile;
      if (ecolaneCustomer.mailingAddress) {
        this.stgEcolaneCustProfile.mailAddress = ecolaneCustomer.mailingAddress;
        this.stgEcolaneCustProfile.mailAddressAptNum =
          ecolaneCustomer.mailingAddress.apartmentNumber;
        this.stgEcolaneCustProfile.mailAddressCity =
          ecolaneCustomer.mailingAddress.city;
        this.stgEcolaneCustProfile.mailAddressState =
          ecolaneCustomer.mailingAddress.state;
        this.stgEcolaneCustProfile.mailAddressStreet =
          ecolaneCustomer.mailingAddress.street;
        this.stgEcolaneCustProfile.mailAddressStreetNum =
          ecolaneCustomer.mailingAddress.streetNumber;
        this.stgEcolaneCustProfile.mailAddressZip =
          ecolaneCustomer.mailingAddress.postcode;
        this.stgEcolaneCustProfile.mailAddressCounty =
          ecolaneCustomer.mailingAddress.county;
      } else {
        this.stgEcolaneCustProfile.mailAddress = applicantAddress;
        this.stgEcolaneCustProfile.mailAddressAptNum = "";
        this.stgEcolaneCustProfile.mailAddressCity = "";
        this.stgEcolaneCustProfile.mailAddressState = "";
        this.stgEcolaneCustProfile.mailAddressStreet = "";
        this.stgEcolaneCustProfile.mailAddressStreetNum = "";
        this.stgEcolaneCustProfile.mailAddressZip = "";
        this.stgEcolaneCustProfile.mailAddressCounty = "";
      }
      store.commit.applicantEcolaneProfileModule.SET_STG_ECOLANE_CUST_PROFILE(
        this.stgEcolaneCustProfile
      );
    },
    saveEcolaneDataUpdate() {
      let url = ECOLANE_ENDPOINT.SAVE_ECOLANE_DATA_UPDATE;
      let abc: IPostingStgModel = clone(postingStgModelDefault);
      //@ts-ignore
      abc.ecolaneDataUpdateModels = this.getEcolaneDataUpdateList;
      //@ts-ignore
      abc.prevEcolaneDataUpdateModels = this.getEcolaneDataPrevUpdateList;
      axios
        .post(url, abc)
        .then(response => {
          //@ts-ignore
          store.commit.applicantEcolaneProfileModule.SET_ECOLANE_DATA_UPDATE_LIST(
            response.data
          );
          //@ts-ignore
          store.commit.applicantEcolaneProfileModule.SET_ECOLANE_DATA_PREV_UPDATE_LIST(
            response.data
          );
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        });
    },
    populateEcolaneUpdateDataList() {
      let updateList: IEcolaneDataUpdate[];
      updateList = [];
      let fmrDataValues: IEcolaneDataUpdate[];
      fmrDataValues = this.populateFMRDataValues();
      fmrDataValues.forEach(element => {
        if (
          element != ecolaneDataUpdateDefault &&
          element.fmrDataValue != null &&
          element.fmrDataValue != ""
        ) {
          updateList.push(element);
        }
      });
      //@ts-ignore
      if (this.showHomeAddrDiff) {
        let temp = this.populateHomeAddrLine1();
        updateList.push(temp);
        temp = this.populateHomeAddrLine2();
        updateList.push(temp);
        temp = this.populateHomeCity();
        updateList.push(temp);
        temp = this.populateHomeState();
        updateList.push(temp);
        temp = this.populateHomeZip();
        updateList.push(temp);
      }
      //@ts-ignore
      if (this.showMailAddrDiff) {
        let temp = this.populateMailAddrLine1();
        updateList.push(temp);
        temp = this.populateMailAddrLine2();
        updateList.push(temp);
        temp = this.populateMailCity();
        updateList.push(temp);
        temp = this.populateMailState();
        updateList.push(temp);
        temp = this.populateMailZip();
        updateList.push(temp);
      }
      if (updateList.length > 0) {
        this.showDiffSection = true;
      }
      store.commit.applicantEcolaneProfileModule.SET_ECOLANE_DATA_UPDATE_LIST(
        updateList
      );
    },
    populateFMRDataValues(): IEcolaneDataUpdate[] {
      let fmrDataValues: IEcolaneDataUpdate[];
      fmrDataValues = [];
      fmrDataValues.push(this.populateFirstNameUpdate());
      fmrDataValues.push(this.populateMiddleInitUpdate());
      fmrDataValues.push(this.populateEmerContNameUpdate());
      fmrDataValues.push(this.populateEmerContPhNoUpdate());
      fmrDataValues.push(this.populateEthnicityUpdate());
      fmrDataValues.push(this.populateLanguageUpdate());
      fmrDataValues.push(this.populateGenderUpdate());
      fmrDataValues.push(this.populateSocialSecurityNumUpdate());
      fmrDataValues.push(this.populateMedicaidNumUpdate());
      fmrDataValues.push(this.populateCountyUpdate());
      fmrDataValues.push(this.populateEmaiAddrUpdate());
      fmrDataValues.push(this.populatePrimaryPhUpdate());
      fmrDataValues.push(this.populateSecondaryPhUpdate());
      fmrDataValues.push(this.populateDisMentalUpdate());
      fmrDataValues.push(this.populateDisPhysicalUpdate());
      fmrDataValues.push(this.populateDisVisualUpdate());
      return fmrDataValues;
    },
    populateFirstNameUpdate(): IEcolaneDataUpdate {
      let ecolaneDataObj: IEcolaneDataUpdate;
      if (
        this.stgEcolaneCustProfile.customerFirstName !=
        this.applicantProfileInfo.applicantFirstName
      ) {
        //@ts-ignore
        ecolaneDataObj = this.firstNameObj;
        if (!ecolaneDataObj || !ecolaneDataObj.ecolaneDataUpdateId) {
          ecolaneDataObj = clone(ecolaneDataUpdateDefault);
        }
        ecolaneDataObj.applicationReviewId = this.applicationReviewModel.applicationReviewId;
        ecolaneDataObj.ecolaneDataType = "FIRST_NAME";
        ecolaneDataObj.ecolaneDataValue = this.stgEcolaneCustProfile.customerFirstName;
        ecolaneDataObj.fmrDataValue = this.applicantProfileInfo.applicantFirstName;
        return ecolaneDataObj;
      } else {
        return ecolaneDataUpdateDefault;
      }
    },
    populateMiddleInitUpdate(): IEcolaneDataUpdate {
      let ecolaneDataObj: IEcolaneDataUpdate;
      if (
        this.stgEcolaneCustProfile.customerMiddleInitial !=
        this.applicantProfileInfo.applicantMiddleInitial
      ) {
        //@ts-ignore
        ecolaneDataObj = this.middleInitObj;
        if (!ecolaneDataObj || !ecolaneDataObj.ecolaneDataUpdateId) {
          ecolaneDataObj = clone(ecolaneDataUpdateDefault);
        }
        ecolaneDataObj.applicationReviewId = this.applicationReviewModel.applicationReviewId;
        ecolaneDataObj.ecolaneDataType = "MIDDLE_INIT";
        ecolaneDataObj.ecolaneDataValue = this.stgEcolaneCustProfile.customerMiddleInitial;
        ecolaneDataObj.fmrDataValue = this.applicantProfileInfo.applicantMiddleInitial;
        return ecolaneDataObj;
      } else {
        return ecolaneDataUpdateDefault;
      }
    },
    populateEmerContNameUpdate(): IEcolaneDataUpdate {
      let ecolaneDataObj: IEcolaneDataUpdate;
      if (
        this.stgEcolaneCustProfile.emergencyContactName !=
        this.applicantProfileInfo.emergencyFirstName +
          " " +
          this.applicantProfileInfo.emergencyLastName +
          ", " +
          this.applicantProfileInfo.emergencyRelationship
      ) {
        //@ts-ignore
        ecolaneDataObj = this.emerContNameObj;
        if (!ecolaneDataObj || !ecolaneDataObj.ecolaneDataUpdateId) {
          ecolaneDataObj = clone(ecolaneDataUpdateDefault);
        }
        ecolaneDataObj.applicationReviewId = this.applicationReviewModel.applicationReviewId;
        ecolaneDataObj.ecolaneDataType = "EMERG_CONT_NAME";
        if (this.stgEcolaneCustProfile.emergencyContactName) {
          ecolaneDataObj.ecolaneDataValue = this.stgEcolaneCustProfile.emergencyContactName;
        }
        if (
          this.applicantProfileInfo.emergencyFirstName &&
          this.applicantProfileInfo.emergencyLastName &&
          this.applicantProfileInfo.emergencyRelationship
        ) {
          ecolaneDataObj.fmrDataValue =
            this.applicantProfileInfo.emergencyFirstName +
            " " +
            this.applicantProfileInfo.emergencyLastName +
            ", " +
            this.applicantProfileInfo.emergencyRelationship;
        }

        if (ecolaneDataObj.ecolaneDataValue === ecolaneDataObj.fmrDataValue) {
          return ecolaneDataUpdateDefault;
        } else {
          return ecolaneDataObj;
        }
      } else {
        return ecolaneDataUpdateDefault;
      }
    },
    populateEmerContPhNoUpdate(): IEcolaneDataUpdate {
      let ecolaneDataObj: IEcolaneDataUpdate;
      if (
        this.stgEcolaneCustProfile.emergencyContactPhoneDay !=
        this.applicantProfileInfo.emergencyPrimaryPhone
      ) {
        //@ts-ignore
        ecolaneDataObj = this.emerContPhNoObj;
        if (!ecolaneDataObj || !ecolaneDataObj.ecolaneDataUpdateId) {
          ecolaneDataObj = clone(ecolaneDataUpdateDefault);
        }
        ecolaneDataObj.applicationReviewId = this.applicationReviewModel.applicationReviewId;
        ecolaneDataObj.ecolaneDataType = "EMERG_CONT_PHNE";
        ecolaneDataObj.ecolaneDataValue = this.stgEcolaneCustProfile.emergencyContactPhoneDay;
        ecolaneDataObj.fmrDataValue = this.applicantProfileInfo.emergencyPrimaryPhone;
        return ecolaneDataObj;
      } else {
        return ecolaneDataUpdateDefault;
      }
    },
    populateEthnicityUpdate(): IEcolaneDataUpdate {
      let ecolaneDataObj: IEcolaneDataUpdate;
      if (
        this.stgEcolaneCustProfile.ethnicity !=
        //@ts-ignore
        this.displayName(this.applicantProfileInfo.ethnicityCode)
      ) {
        //@ts-ignore
        ecolaneDataObj = this.ethnicityObj;
        if (!ecolaneDataObj || !ecolaneDataObj.ecolaneDataUpdateId) {
          ecolaneDataObj = clone(ecolaneDataUpdateDefault);
        }
        ecolaneDataObj.applicationReviewId = this.applicationReviewModel.applicationReviewId;
        ecolaneDataObj.ecolaneDataType = "ETHNICITY";
        ecolaneDataObj.ecolaneDataValue = this.stgEcolaneCustProfile.ethnicity;
        //@ts-ignore
        ecolaneDataObj.fmrDataValue = this.displayName(
          this.applicantProfileInfo.ethnicityCode
        );
        return ecolaneDataObj;
      } else {
        return ecolaneDataUpdateDefault;
      }
    },
    populateLanguageUpdate(): IEcolaneDataUpdate {
      let ecolaneDataObj: IEcolaneDataUpdate;
      if (
        this.stgEcolaneCustProfile.language !=
        //@ts-ignore
        this.displayName(this.applicantProfileInfo.primaryLanguageCode)
      ) {
        //@ts-ignore
        ecolaneDataObj = this.languageObj;
        if (!ecolaneDataObj || !ecolaneDataObj.ecolaneDataUpdateId) {
          ecolaneDataObj = clone(ecolaneDataUpdateDefault);
        }
        ecolaneDataObj.applicationReviewId = this.applicationReviewModel.applicationReviewId;
        ecolaneDataObj.ecolaneDataType = "LANGUAGE";
        ecolaneDataObj.ecolaneDataValue = this.stgEcolaneCustProfile.language;
        //@ts-ignore
        ecolaneDataObj.fmrDataValue = this.displayName(
          this.applicantProfileInfo.primaryLanguageCode
        );
        return ecolaneDataObj;
      } else {
        return ecolaneDataUpdateDefault;
      }
    },
    populateGenderUpdate(): IEcolaneDataUpdate {
      let ecolaneDataObj: IEcolaneDataUpdate;
      let ecolaneValue: string = this.stgEcolaneCustProfile.gender;
      let fmrValue: string = this.applicantProfileInfo.applicantGender;
      if (this.isEcolaneGenderUpdateNeeded(fmrValue, ecolaneValue)) {
        //@ts-ignore
        ecolaneDataObj = this.genderObj;
        if (!ecolaneDataObj || !ecolaneDataObj.ecolaneDataUpdateId) {
          ecolaneDataObj = clone(ecolaneDataUpdateDefault);
        }
        ecolaneDataObj.applicationReviewId = this.applicationReviewModel.applicationReviewId;
        ecolaneDataObj.ecolaneDataType = "GENDER";
        ecolaneDataObj.ecolaneDataValue = ecolaneValue;
        //@ts-ignore
        ecolaneDataObj.fmrDataValue = fmrValue;

        return ecolaneDataObj;
      } else {
        return ecolaneDataUpdateDefault;
      }
    },
    populateSocialSecurityNumUpdate(): IEcolaneDataUpdate {
      let ecolaneDataObj: IEcolaneDataUpdate;
      if (
        this.stgEcolaneCustProfile.socialSecurityNumber !=
        this.draftProgramInfo.enSSN
      ) {
        //@ts-ignore
        ecolaneDataObj = this.socialSecurityObj;
        if (!ecolaneDataObj || !ecolaneDataObj.ecolaneDataUpdateId) {
          ecolaneDataObj = clone(ecolaneDataUpdateDefault);
        }
        ecolaneDataObj.applicationReviewId = this.applicationReviewModel.applicationReviewId;
        ecolaneDataObj.ecolaneDataType = "SOCIAL_SECURITY_NUMBER";
        ecolaneDataObj.ecolaneDataValue = this.stgEcolaneCustProfile.socialSecurityNumber;
        //@ts-ignore
        ecolaneDataObj.fmrDataValue = this.draftProgramInfo.enSSN;

        return ecolaneDataObj;
      } else {
        return ecolaneDataUpdateDefault;
      }
    },
    populateMedicaidNumUpdate(): IEcolaneDataUpdate {
      let ecolaneDataObj: IEcolaneDataUpdate;
      if (
        this.stgEcolaneCustProfile.medicaidNumber !=
        this.draftProgramInfo.medicaidCardRecipientNum
      ) {
        //@ts-ignore
        ecolaneDataObj = this.medicaidObj;
        if (!ecolaneDataObj || !ecolaneDataObj.ecolaneDataUpdateId) {
          ecolaneDataObj = clone(ecolaneDataUpdateDefault);
        }
        ecolaneDataObj.applicationReviewId = this.applicationReviewModel.applicationReviewId;
        ecolaneDataObj.ecolaneDataType = "MEDICAID_NUMBER";
        ecolaneDataObj.ecolaneDataValue = this.stgEcolaneCustProfile.medicaidNumber;
        //@ts-ignore
        ecolaneDataObj.fmrDataValue = this.draftProgramInfo.medicaidCardRecipientNum;

        return ecolaneDataObj;
      } else {
        return ecolaneDataUpdateDefault;
      }
    },
    populateCountyUpdate(): IEcolaneDataUpdate {
      let ecolaneDataObj: IEcolaneDataUpdate;
      let countyVal: string = this.getCountyName(
        this.applicantProfileInfo.homeAddress.paCountyCode
      );
      if (this.stgEcolaneCustProfile.county != countyVal) {
        //@ts-ignore
        ecolaneDataObj = this.countyObj;
        if (!ecolaneDataObj || !ecolaneDataObj.ecolaneDataUpdateId) {
          ecolaneDataObj = clone(ecolaneDataUpdateDefault);
        }
        ecolaneDataObj.applicationReviewId = this.applicationReviewModel.applicationReviewId;
        ecolaneDataObj.ecolaneDataType = "COUNTY";
        ecolaneDataObj.ecolaneDataValue = this.stgEcolaneCustProfile.county;
        ecolaneDataObj.fmrDataValue = countyVal;
        return ecolaneDataObj;
      } else {
        return ecolaneDataUpdateDefault;
      }
    },
    populateEmaiAddrUpdate(): IEcolaneDataUpdate {
      let ecolaneDataObj: IEcolaneDataUpdate;
      if (
        this.stgEcolaneCustProfile.emailAddress !=
        this.applicantProfileInfo.applicantEmailAddress
      ) {
        //@ts-ignore
        ecolaneDataObj = this.emaiAddrObj;
        if (!ecolaneDataObj || !ecolaneDataObj.ecolaneDataUpdateId) {
          ecolaneDataObj = clone(ecolaneDataUpdateDefault);
        }
        ecolaneDataObj.applicationReviewId = this.applicationReviewModel.applicationReviewId;
        ecolaneDataObj.ecolaneDataType = "EMAIL_ADDR";
        ecolaneDataObj.ecolaneDataValue = this.stgEcolaneCustProfile.emailAddress;
        ecolaneDataObj.fmrDataValue = this.applicantProfileInfo.applicantEmailAddress;
        return ecolaneDataObj;
      } else {
        return ecolaneDataUpdateDefault;
      }
    },
    populatePrimaryPhUpdate(): IEcolaneDataUpdate {
      let ecolaneDataObj: IEcolaneDataUpdate;
      if (
        this.stgEcolaneCustProfile.landlinePhone !=
        this.applicantProfileInfo.applicantPrimaryPhone
      ) {
        //@ts-ignore
        ecolaneDataObj = this.primaryPhObj;
        if (!ecolaneDataObj || !ecolaneDataObj.ecolaneDataUpdateId) {
          ecolaneDataObj = clone(ecolaneDataUpdateDefault);
        }
        ecolaneDataObj.applicationReviewId = this.applicationReviewModel.applicationReviewId;
        ecolaneDataObj.ecolaneDataType = "PRIM_PHNE";
        ecolaneDataObj.ecolaneDataValue = this.stgEcolaneCustProfile.landlinePhone;
        ecolaneDataObj.fmrDataValue = this.applicantProfileInfo.applicantPrimaryPhone;
        return ecolaneDataObj;
      } else {
        return ecolaneDataUpdateDefault;
      }
    },
    populateSecondaryPhUpdate(): IEcolaneDataUpdate {
      let ecolaneDataObj: IEcolaneDataUpdate;
      if (
        this.stgEcolaneCustProfile.mobilePhone !=
        this.applicantProfileInfo.applicantSecondaryPhone
      ) {
        //@ts-ignore
        ecolaneDataObj = this.secondaryPhObj;
        if (!ecolaneDataObj || !ecolaneDataObj.ecolaneDataUpdateId) {
          ecolaneDataObj = clone(ecolaneDataUpdateDefault);
        }
        ecolaneDataObj.applicationReviewId = this.applicationReviewModel.applicationReviewId;
        ecolaneDataObj.ecolaneDataType = "SEC_PHNE";
        ecolaneDataObj.ecolaneDataValue = this.stgEcolaneCustProfile.mobilePhone;
        ecolaneDataObj.fmrDataValue = this.applicantProfileInfo.applicantSecondaryPhone;
        return ecolaneDataObj;
      } else {
        return ecolaneDataUpdateDefault;
      }
    },
    populateDisMentalUpdate(): IEcolaneDataUpdate {
      let ecolaneDataObj: IEcolaneDataUpdate;
      if (
        this.stgEcolaneCustProfile.disabilityMentalInd !=
        this.draftProgramInfo.mentalDisabilityInd
      ) {
        //@ts-ignore
        ecolaneDataObj = this.disMentalObj;
        if (!ecolaneDataObj || !ecolaneDataObj.ecolaneDataUpdateId) {
          ecolaneDataObj = clone(ecolaneDataUpdateDefault);
        }
        ecolaneDataObj.applicationReviewId = this.applicationReviewModel.applicationReviewId;
        ecolaneDataObj.ecolaneDataType = "DIS_MENTAL";
        ecolaneDataObj.ecolaneDataValue = this.stgEcolaneCustProfile.disabilityMentalInd;
        ecolaneDataObj.fmrDataValue = this.draftProgramInfo.mentalDisabilityInd;
        return ecolaneDataObj;
      } else {
        return ecolaneDataUpdateDefault;
      }
    },
    populateDisPhysicalUpdate(): IEcolaneDataUpdate {
      let ecolaneDataObj: IEcolaneDataUpdate;
      if (
        this.stgEcolaneCustProfile.disabilityPhysicalInd !=
        this.draftProgramInfo.mobilityDisabilityInd
      ) {
        //@ts-ignore
        ecolaneDataObj = this.disPhysicalObj;
        if (!ecolaneDataObj || !ecolaneDataObj.ecolaneDataUpdateId) {
          ecolaneDataObj = clone(ecolaneDataUpdateDefault);
        }
        ecolaneDataObj.applicationReviewId = this.applicationReviewModel.applicationReviewId;
        ecolaneDataObj.ecolaneDataType = "DIS_PHYSCL";
        ecolaneDataObj.ecolaneDataValue = this.stgEcolaneCustProfile.disabilityPhysicalInd;
        ecolaneDataObj.fmrDataValue = this.draftProgramInfo.mobilityDisabilityInd;
        return ecolaneDataObj;
      } else {
        return ecolaneDataUpdateDefault;
      }
    },
    populateDisVisualUpdate(): IEcolaneDataUpdate {
      let ecolaneDataObj: IEcolaneDataUpdate;
      if (
        this.stgEcolaneCustProfile.disabilityVisualInd !=
        this.draftProgramInfo.visualDisabilityInd
      ) {
        //@ts-ignore
        ecolaneDataObj = this.disVisualObj;
        if (!ecolaneDataObj || !ecolaneDataObj.ecolaneDataUpdateId) {
          ecolaneDataObj = clone(ecolaneDataUpdateDefault);
        }
        ecolaneDataObj.applicationReviewId = this.applicationReviewModel.applicationReviewId;
        ecolaneDataObj.ecolaneDataType = "DIS_VISUAL";
        ecolaneDataObj.ecolaneDataValue = this.stgEcolaneCustProfile.disabilityVisualInd;
        ecolaneDataObj.fmrDataValue = this.draftProgramInfo.visualDisabilityInd;
        return ecolaneDataObj;
      } else {
        return ecolaneDataUpdateDefault;
      }
    },
    populateHomeAddrLine1(): IEcolaneDataUpdate {
      let ecolaneDataObj: IEcolaneDataUpdate;
      //@ts-ignore
      ecolaneDataObj = this.homeAddrLine1Obj;
      if (!ecolaneDataObj || !ecolaneDataObj.ecolaneDataUpdateId) {
        ecolaneDataObj = clone(ecolaneDataUpdateDefault);
      }
      ecolaneDataObj.applicationReviewId = this.applicationReviewModel.applicationReviewId;
      ecolaneDataObj.ecolaneDataType = "HOME_ADDRL1";
      if (
        this.stgEcolaneCustProfile.homeAddressStreetNum &&
        this.stgEcolaneCustProfile.homeAddressStreet
      ) {
        ecolaneDataObj.ecolaneDataValue = `${this.stgEcolaneCustProfile.homeAddressStreetNum} ${this.stgEcolaneCustProfile.homeAddressStreet}`;
      } else if (
        !this.stgEcolaneCustProfile.homeAddressStreetNum &&
        this.stgEcolaneCustProfile.homeAddressStreet
      ) {
        ecolaneDataObj.ecolaneDataValue = `${this.stgEcolaneCustProfile.homeAddressStreet}`;
      } else if (
        this.stgEcolaneCustProfile.homeAddressStreetNum &&
        !this.stgEcolaneCustProfile.homeAddressStreet
      ) {
        ecolaneDataObj.ecolaneDataValue = `${this.stgEcolaneCustProfile.homeAddressStreetNum}`;
      }
      ecolaneDataObj.fmrDataValue = this.applicantProfileInfo.homeAddress.addressLine1;
      return ecolaneDataObj;
    },
    populateHomeAddrLine2(): IEcolaneDataUpdate {
      let ecolaneDataObj: IEcolaneDataUpdate;
      //@ts-ignore
      ecolaneDataObj = this.homeAddrLine2Obj;
      if (!ecolaneDataObj || !ecolaneDataObj.ecolaneDataUpdateId) {
        ecolaneDataObj = clone(ecolaneDataUpdateDefault);
      }
      ecolaneDataObj.applicationReviewId = this.applicationReviewModel.applicationReviewId;
      ecolaneDataObj.ecolaneDataType = "HOME_ADDRL2";
      ecolaneDataObj.ecolaneDataValue = this.stgEcolaneCustProfile.homeAddressAptNum;
      ecolaneDataObj.fmrDataValue = this.applicantProfileInfo.homeAddress.addressLine2;
      return ecolaneDataObj;
    },
    populateHomeCity(): IEcolaneDataUpdate {
      let ecolaneDataObj: IEcolaneDataUpdate;
      //@ts-ignore
      ecolaneDataObj = this.homeCityObj;
      if (!ecolaneDataObj || !ecolaneDataObj.ecolaneDataUpdateId) {
        ecolaneDataObj = clone(ecolaneDataUpdateDefault);
      }
      ecolaneDataObj.applicationReviewId = this.applicationReviewModel.applicationReviewId;
      ecolaneDataObj.ecolaneDataType = "HOME_CITY";
      ecolaneDataObj.ecolaneDataValue = this.stgEcolaneCustProfile.homeAddressCity;
      ecolaneDataObj.fmrDataValue = this.applicantProfileInfo.homeAddress.city;
      return ecolaneDataObj;
    },
    populateHomeState(): IEcolaneDataUpdate {
      let ecolaneDataObj: IEcolaneDataUpdate;
      //@ts-ignore
      ecolaneDataObj = this.homeStateObj;
      if (!ecolaneDataObj || !ecolaneDataObj.ecolaneDataUpdateId) {
        ecolaneDataObj = clone(ecolaneDataUpdateDefault);
      }
      ecolaneDataObj.applicationReviewId = this.applicationReviewModel.applicationReviewId;
      ecolaneDataObj.ecolaneDataType = "HOME_STATE";
      ecolaneDataObj.ecolaneDataValue = this.stgEcolaneCustProfile.homeAddressState;
      ecolaneDataObj.fmrDataValue = this.applicantProfileInfo.homeAddress.state;
      return ecolaneDataObj;
    },
    populateHomeZip(): IEcolaneDataUpdate {
      let ecolaneDataObj: IEcolaneDataUpdate;
      //@ts-ignore
      ecolaneDataObj = this.homeZipObj;
      if (!ecolaneDataObj || !ecolaneDataObj.ecolaneDataUpdateId) {
        ecolaneDataObj = clone(ecolaneDataUpdateDefault);
      }
      ecolaneDataObj.applicationReviewId = this.applicationReviewModel.applicationReviewId;
      ecolaneDataObj.ecolaneDataType = "HOME_ZIP";
      ecolaneDataObj.ecolaneDataValue = this.stgEcolaneCustProfile.homeAddressZip;
      ecolaneDataObj.fmrDataValue = this.applicantProfileInfo.homeAddress.zip;
      return ecolaneDataObj;
    },
    populateMailAddrLine1(): IEcolaneDataUpdate {
      let ecolaneDataObj: IEcolaneDataUpdate;
      let addrLine1: string;
      if (this.applicantProfileInfo.sameMailingInd === "Y") {
        addrLine1 = this.applicantProfileInfo.homeAddress.addressLine1;
      } else {
        addrLine1 = this.applicantProfileInfo.mailingAddress.addressLine1;
      }
      //@ts-ignore
      ecolaneDataObj = this.mailAddrLine1Obj;
      if (!ecolaneDataObj || !ecolaneDataObj.ecolaneDataUpdateId) {
        ecolaneDataObj = clone(ecolaneDataUpdateDefault);
      }
      ecolaneDataObj.applicationReviewId = this.applicationReviewModel.applicationReviewId;
      ecolaneDataObj.ecolaneDataType = "MAIL_ADDRL1";
      if (
        this.stgEcolaneCustProfile.mailAddressStreetNum &&
        this.stgEcolaneCustProfile.mailAddressStreet
      ) {
        ecolaneDataObj.ecolaneDataValue = `${this.stgEcolaneCustProfile.mailAddressStreetNum} ${this.stgEcolaneCustProfile.mailAddressStreet}`;
      } else if (
        !this.stgEcolaneCustProfile.mailAddressStreetNum &&
        this.stgEcolaneCustProfile.mailAddressStreet
      ) {
        ecolaneDataObj.ecolaneDataValue = `${this.stgEcolaneCustProfile.mailAddressStreet}`;
      } else if (
        this.stgEcolaneCustProfile.mailAddressStreetNum &&
        !this.stgEcolaneCustProfile.mailAddressStreet
      ) {
        ecolaneDataObj.ecolaneDataValue = `${this.stgEcolaneCustProfile.mailAddressStreetNum}`;
      }
      ecolaneDataObj.fmrDataValue = addrLine1;
      return ecolaneDataObj;
    },
    populateMailAddrLine2(): IEcolaneDataUpdate {
      let ecolaneDataObj: IEcolaneDataUpdate;
      let addrLine2: string;
      if (this.applicantProfileInfo.sameMailingInd === "Y") {
        addrLine2 = this.applicantProfileInfo.homeAddress.addressLine2;
      } else {
        addrLine2 = this.applicantProfileInfo.mailingAddress.addressLine2;
      }
      //@ts-ignore
      ecolaneDataObj = this.mailAddrLine2Obj;
      if (!ecolaneDataObj || !ecolaneDataObj.ecolaneDataUpdateId) {
        ecolaneDataObj = clone(ecolaneDataUpdateDefault);
      }
      ecolaneDataObj.applicationReviewId = this.applicationReviewModel.applicationReviewId;
      ecolaneDataObj.ecolaneDataType = "MAIL_ADDRL2";
      ecolaneDataObj.ecolaneDataValue = this.stgEcolaneCustProfile.mailAddressAptNum;
      ecolaneDataObj.fmrDataValue = addrLine2;
      return ecolaneDataObj;
    },
    populateMailCity(): IEcolaneDataUpdate {
      let ecolaneDataObj: IEcolaneDataUpdate;
      let city: string;
      if (this.applicantProfileInfo.sameMailingInd === "Y") {
        city = this.applicantProfileInfo.homeAddress.city;
      } else {
        city = this.applicantProfileInfo.mailingAddress.city;
      }
      //@ts-ignore
      ecolaneDataObj = this.mailCityObj;
      if (!ecolaneDataObj || !ecolaneDataObj.ecolaneDataUpdateId) {
        ecolaneDataObj = clone(ecolaneDataUpdateDefault);
      }
      ecolaneDataObj.applicationReviewId = this.applicationReviewModel.applicationReviewId;
      ecolaneDataObj.ecolaneDataType = "MAIL_CITY";
      ecolaneDataObj.ecolaneDataValue = this.stgEcolaneCustProfile.mailAddressCity;
      ecolaneDataObj.fmrDataValue = city;
      return ecolaneDataObj;
    },
    populateMailState(): IEcolaneDataUpdate {
      let ecolaneDataObj: IEcolaneDataUpdate;
      let state: string;
      if (this.applicantProfileInfo.sameMailingInd === "Y") {
        state = this.applicantProfileInfo.homeAddress.state;
      } else {
        state = this.applicantProfileInfo.mailingAddress.state;
      }
      //@ts-ignore
      ecolaneDataObj = this.mailStateObj;
      if (!ecolaneDataObj || !ecolaneDataObj.ecolaneDataUpdateId) {
        ecolaneDataObj = clone(ecolaneDataUpdateDefault);
      }
      ecolaneDataObj.applicationReviewId = this.applicationReviewModel.applicationReviewId;
      ecolaneDataObj.ecolaneDataType = "MAIL_STATE";
      ecolaneDataObj.ecolaneDataValue = this.stgEcolaneCustProfile.mailAddressState;
      ecolaneDataObj.fmrDataValue = state;
      return ecolaneDataObj;
    },
    populateMailZip(): IEcolaneDataUpdate {
      let ecolaneDataObj: IEcolaneDataUpdate;
      let zip: string;
      if (this.applicantProfileInfo.sameMailingInd === "Y") {
        zip = this.applicantProfileInfo.homeAddress.zip;
      } else {
        zip = this.applicantProfileInfo.mailingAddress.zip;
      }
      //@ts-ignore
      ecolaneDataObj = this.mailZipObj;
      if (!ecolaneDataObj || !ecolaneDataObj.ecolaneDataUpdateId) {
        ecolaneDataObj = clone(ecolaneDataUpdateDefault);
      }
      ecolaneDataObj.applicationReviewId = this.applicationReviewModel.applicationReviewId;
      ecolaneDataObj.ecolaneDataType = "MAIL_ZIP";
      ecolaneDataObj.ecolaneDataValue = this.stgEcolaneCustProfile.mailAddressZip;
      ecolaneDataObj.fmrDataValue = zip;
      return ecolaneDataObj;
    },
    getDisplayTextForEcolaneLanguageCode(languageCode: string): string {
      switch (languageCode) {
        case "en":
          //@ts-ignore
          return this.displayName(GPC.ENG);
        case "ar":
          //@ts-ignore
          return this.displayName(GPC.ARA);
        case "zh":
          //@ts-ignore
          return this.displayName(GPC.ZHO);
        case "fr":
          //@ts-ignore
          return this.displayName(GPC.FRE);
        case "de":
          //@ts-ignore
          return this.displayName(GPC.DEU);
        case "gu":
          //@ts-ignore
          return this.displayName(GPC.GUJ);
        case "ht":
          //@ts-ignore
          return this.displayName(GPC.HAT);
        case "it":
          //@ts-ignore
          return this.displayName(GPC.ITA);
        case "ko":
          //@ts-ignore
          return this.displayName(GPC.KOR);
        case "km":
          //@ts-ignore
          return this.displayName(GPC.MKH);
        case "ne":
          //@ts-ignore
          return this.displayName(GPC.NEP);
        case "pl":
          //@ts-ignore
          return this.displayName(GPC.POL);
        case "pt":
          //@ts-ignore
          return this.displayName(GPC.POR);
        case "ru":
          //@ts-ignore
          return this.displayName(GPC.RUS);
        case "es":
          //@ts-ignore
          return this.displayName(GPC.SPA);
        case "vi":
          //@ts-ignore
          return this.displayName(GPC.VIE);
        default:
          return "";
      }
    },
    deleteStgEcolaneCustProfile() {
      this.showDiffSection = false;
      if (this.stgEcolaneCustProfile.stgEcolaneCustProfileId) {
        this.showProgressBar = true;
        let url = ECOLANE_ENDPOINT.DELETE_STG_ECOLANE_INFO;
        axios
          .post(url, this.stgEcolaneCustProfile)
          .catch(error => {
            store.dispatch.alertModule.showAlertMessageById(
              error.response.data.id
            );
          })
          .finally(() => {
            store.commit.applicantEcolaneProfileModule.SET_STG_ECOLANE_CUST_PROFILE(
              stgEcolaneCustProfileDefault
            );
            this.showProgressBar = false;
          });
      } else {
        store.commit.applicantEcolaneProfileModule.SET_STG_ECOLANE_CUST_PROFILE(
          stgEcolaneCustProfileDefault
        );
      }
    },
    deleteEcolaneUpdateDataList() {
      //@ts-ignore
      if (this.getEcolaneDataUpdateList.length > 0) {
        //@ts-ignore
        let x: IEcolaneDataUpdate = this.getEcolaneDataUpdateList[0];
        if (x.ecolaneDataUpdateId) {
          this.showDiffSection = false;
          this.showProgressBar = true;
          let url = ECOLANE_ENDPOINT.DELETE_ECOLANE_DATA_UPDATE_BY_REV_ID;
          axios
            //@ts-ignore
            .post(url, x)
            .catch(error => {
              store.dispatch.alertModule.showAlertMessageById(
                error.response.data.id
              );
            })
            .finally(() => {
              store.commit.applicantEcolaneProfileModule.SET_ECOLANE_DATA_UPDATE_LIST(
                [clone(ecolaneDataUpdateDefault)]
              );
              store.commit.applicantEcolaneProfileModule.SET_ECOLANE_DATA_PREV_UPDATE_LIST(
                [clone(ecolaneDataUpdateDefault)]
              );
              this.showProgressBar = false;
            });
        }
      } else {
        store.commit.applicantEcolaneProfileModule.SET_ECOLANE_DATA_UPDATE_LIST(
          [clone(ecolaneDataUpdateDefault)]
        );
        store.commit.applicantEcolaneProfileModule.SET_ECOLANE_DATA_PREV_UPDATE_LIST(
          [clone(ecolaneDataUpdateDefault)]
        );
      }
    },
    populateHomeAddressObj(
      newVal: IStgEcolaneCustProfileModel
    ): IApplicantAddress {
      let varHomeAddress: IApplicantAddress = clone(applicantAddress);
      varHomeAddress.street = newVal.homeAddressStreet;
      varHomeAddress.streetNumber = newVal.homeAddressStreetNum;
      varHomeAddress.apartmentNumber = newVal.homeAddressAptNum;
      varHomeAddress.postcode = newVal.homeAddressZip;
      varHomeAddress.city = newVal.homeAddressCity;
      varHomeAddress.state = newVal.homeAddressState;
      return varHomeAddress;
    },
    populateMailAddressObj(
      newVal: IStgEcolaneCustProfileModel
    ): IApplicantAddress {
      let varMailAddress: IApplicantAddress = clone(applicantAddress);
      varMailAddress.street = newVal.mailAddressStreet;
      varMailAddress.streetNumber = newVal.mailAddressStreetNum;
      varMailAddress.apartmentNumber = newVal.mailAddressAptNum;
      varMailAddress.postcode = newVal.mailAddressZip;
      varMailAddress.city = newVal.mailAddressCity;
      varMailAddress.state = newVal.mailAddressState;
      return varMailAddress;
    },
    getCountyName(paCountyCode: string) {
      let countyName = this.counties.find(
        obj =>
          //@ts-ignore
          obj.id === paCountyCode
      );
      //@ts-ignore
      return countyName ? countyName.name : "";
    },
    isEcolaneGenderUpdateNeeded(
      fmrValue: string,
      ecolaneValue: string
    ): boolean {
      if (fmrValue === "O" || fmrValue === "P") {
        return false;
      } else {
        return ecolaneValue != fmrValue;
      }
    }
  },
  computed: {
    applicantProfileInfo(): IApplicantProfile {
      return store.getters.applicantProfileModule.getApplicantProfile;
    },
    applicantProgramInfo: {
      get(): IProgramsList {
        return store.getters.applicantProgramModule.getApplicantProgram;
      }
    },
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        //@ts-ignore
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      }
    },
    isEcolaneCustomerLoading(): boolean {
      return store.getters.applicantEcolaneProfileModule
        .getIsEcolaneCustomerLoaded;
    },
    isEcolaneCustomerDataLoaded(): boolean {
      return store.getters.applicantEcolaneProfileModule
        .getIsEcolaneCustomerDataLoaded;
    },
    selectedEcolaneCustomer: {
      get(): IApplicantEcolaneProfileModel {
        return store.getters.applicantEcolaneProfileModule
          .getApplicantEcolaneProfile;
      },
      set(newObject: IApplicantEcolaneProfileModel) {
        store.commit.applicantEcolaneProfileModule.SET_APPLICANT_ECOLANE_PROFILE(
          newObject
        );
      }
    },
    applicationReviewModel: {
      get(): IApplicationReviewModel {
        return store.getters.applicationReviewModule.getApplicationReviewModel;
      }
    },
    stgEcolaneCustProfile: {
      get(): IStgEcolaneCustProfileModel {
        return store.getters.applicantEcolaneProfileModule
          .getStgEcolaneCustProfile;
      },
      set(newObject: IStgEcolaneCustProfileModel) {
        store.commit.applicantEcolaneProfileModule.SET_STG_ECOLANE_CUST_PROFILE(
          newObject
        );
      }
    },
    counties(): [] {
      return store.getters.dataModule.getCounties;
    }
  },
  watch: {
    stgEcolaneCustProfile: {
      deep: true,
      handler(newVal: IStgEcolaneCustProfileModel) {
        if (newVal.stgEcolaneCustProfileId) {
          this.stgEcolaneCustProfile.homeAddress = this.populateHomeAddressObj(
            newVal
          );
          this.stgEcolaneCustProfile.mailAddress = this.populateMailAddressObj(
            newVal
          );
          //@ts-ignore
          this.showDiffSection = this.showDiffSectionMethod;
        }
      }
    },
    showDiffSectionMethod: {
      deep: true,
      handler(newVal: boolean) {
        this.showDiffSection = newVal;
      }
    }
  }
});
