
import Vue from "vue";
import tesProgramTemplate from "@/components/common/TESProgramTemplate.vue";
import ReviewAppEcolaneInfo from "@/components/application/reviewagencyinformation/ReviewAppEcolaneInfo.vue";
import ReviewVerifyMATP from "@/components/application/reviewagencyinformation/ReviewVerifyMATP.vue";
import store from "@/store";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import ReviewerAttachmentsVue from "./ReviewerAttachments.vue";
import ReviewRecommendationVue from "@/components/application/reviewagencyinformation/ReviewRecommendation.vue";
import AgencyReviewMixin from "@/mixins/AgencyReviewMixin.vue";
import { IApplicationReviewModel, IUIFeatureObjectModel } from "@/types";
import { expansionPanelIconObj } from "@/constants/CommonConstants";
import { clone } from "@/util/clone";

export default Vue.extend({
  name: "review-info-with-ecolane",
  mixins: [UIFeatureMixinVue, AgencyReviewMixin],
  components: {
    tesProgramTemplate,
    ReviewAppEcolaneInfo,
    ReviewerAttachmentsVue,
    ReviewRecommendationVue,
    ReviewVerifyMATP
  },
  data: function() {
    return {
      seen: true as boolean,
      hover: false as boolean,
      showhideText: expansionPanelIconObj.hideText,
      showHideIcon: "fa-compress-alt" as string,
      stepCount: 4 as number
    };
  },
  created() {
    store.dispatch.dataModule.getAllCounties();
    this.loadEcolaneRelatedInfo(
      this.applicationReviewModel.applicationReviewId
    );
    //@ts-ignore
    if (this.isNoEcolaneAgency || this.isNoEcolanePrgrm) {
      this.changeStepCount(2);
    }
    //@ts-ignore
    if (this.applicantProgramInfo.programTypeCode !== "MATP") {
      this.stepCount = 3;
    }
  },
  updated() {
    if (
      //@ts-ignore
      this.applicantProgramInfo.programTypeCode === "MATP" &&
      this.draftProgramInfo.dhsAuthInd === "Y" &&
      (this.isNoEcolaneAgency ||
        this.isNoEcolanePrgrm ||
        this.applicationReviewModelPrevious.tempApprovalEndDate)
    ) {
      this.changeStepCount(3);
    } else if (
      this.isNoEcolaneAgency ||
      this.isNoEcolanePrgrm ||
      this.applicationReviewModelPrevious.tempApprovalEndDate
    ) {
      this.changeStepCount(2);
    } else if (
      //@ts-ignore
      this.applicantProgramInfo.programTypeCode === "MATP" &&
      this.draftProgramInfo.dhsAuthInd === "Y"
    ) {
      this.changeStepCount(4);
    } else {
      this.changeStepCount(3);
    }
  },
  methods: {
    showHide() {
      if (!this.seen) {
        this.seen = true;
        this.showhideText = expansionPanelIconObj.hideText;
        this.showHideIcon = "fa-compress-alt";
      } else {
        this.seen = false;
        this.showhideText = expansionPanelIconObj.showText;
        this.showHideIcon = "fa-expand-alt";
      }
    },
    saveCompleted() {
      ///@ts-ignore
      if (this.isLANTA) {
        //@ts-ignore
        this.loadLANTADocs();
      }
      //@ts-ignore
      if (this.$refs.reviewInfoWEcolane.stepperIndex !== this.stepCount) {
        //@ts-ignore
        this.$refs.reviewInfoWEcolane.nextStep();
      }
    },
    changeStepCount(steps: number) {
      //@ts-ignore
      this.stepCount = steps;
    },
    loadEcolaneRelatedInfo(revid: number) {
      let payload = {
        applicationReviewId: revid
      };
      store.dispatch.applicantEcolaneProfileModule.getEcolaneDataUpdateListByReviewId(
        payload
      );
    }
  },
  computed: {
    docProofsWithLANTA(): any {
      let a: IUIFeatureObjectModel[] = clone(
        //@ts-ignore
        this.uiFeature.DOC_PROOFS.children
      );

      //@ts-ignore
      let x: string[] = this.uiFeature.DOCS_FLTR.largeText.split(",");

      let i = 0;
      a.forEach(a1 => {
        x.forEach(x1 => {
          if (x1 === a1.resourceCode) {
            a.splice(i, 1);
          }
        });
        i++;
      });
      return a;
    },
    docProofsNoLANTA(): any {
      let docProofsNoLANTA = this.docProofsWithLANTA.slice(0);
      return docProofsNoLANTA;
    },
    draftProgramInfo(): any {
      //@ts-ignore
      return store.getters.draftProgramInfoModule.getDraftProgramInfo;
    },
    applicationReviewModelPrevious: {
      get(): IApplicationReviewModel {
        return store.getters.applicationReviewModule
          .getApplicationPreviousReviewModel;
      }
    },
    applicationReviewModel: {
      get(): IApplicationReviewModel {
        return store.getters.applicationReviewModule.getApplicationReviewModel;
      }
    }
  }
});
