
import Vue from "vue";
import store from "@/store";
import axios from "axios";

import { TES_APP_ENDPOINT } from "@/constants/EndPoints";

import { IProgramsList } from "@/types";
import { STATUS_CODES } from "@/constants/CommonConstants";

export default Vue.extend({
  methods: {
    getEventHistory: function() {
      let url = TES_APP_ENDPOINT.GET_EVENT_HISTORY;
      return axios.get(url, {
        params: { wfItemId: this.applicantProgramInfo.workflowItemId }
      });
    }
  },
  computed: {
    applicantProgramInfo: {
      get(): IProgramsList {
        //@ts-ignore
        return store.getters.applicantProgramModule.getApplicantProgram;
      }
    },
    showEventHistoryLink(): boolean {
      return (
        this.applicantProgramInfo.statusCode === STATUS_CODES.IN_PRGS ||
        this.applicantProgramInfo.statusCode === STATUS_CODES.RDY_SBMT ||
        this.applicantProgramInfo.statusCode === STATUS_CODES.SBMTD
      );
    }
  }
});
