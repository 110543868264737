
import Vue from "vue";
import { IApplicantProfile } from "@/types";
import store from "@/store";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import StepperHeader from "@/components/common/StepperHeader.vue";
import SingleFlowAttachments from "@/components/application/program/attachments/SingleFlowAttachments.vue";

export default Vue.extend({
  name: "singleflow-attachments-template",
  mixins: [fieldEntryMixin],
  components: {
    SingleFlowAttachments,
    StepperHeader
  },
  data: function() {
    return {
      stepCount: 1 as number
    };
  },
  computed: {
    applicantProfile: {
      get(): IApplicantProfile {
        //@ts-ignore
        return store.getters.applicantProfileModule.getApplicantProfile;
      },
      set(newValue: IApplicantProfile) {
        //@ts-ignore
        store.commit.applicantProfileModule.SET_APPLICANT_PROFILE(newValue);
      }
    }
  }
});
