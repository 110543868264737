import { IStateProvince } from "@/types";

export const STATE_PROVINCE: IStateProvince[] = [
  { name: "Alabama", countryCode: "US", code: "AL" },
  { name: "Alaska", countryCode: "US", code: "AK" },
  { name: "Arizona", countryCode: "US", code: "AZ" },
  { name: "Arkansas", countryCode: "US", code: "AR" },
  { name: "Armed Forces America", countryCode: "US", code: "AA" },
  { name: "Armed Forces Europe", countryCode: "US", code: "AE" },
  { name: "Armed Forces Pacific", countryCode: "US", code: "AP" },
  { name: "California", countryCode: "US", code: "CA" },
  { name: "Colorado", countryCode: "US", code: "CO" },
  { name: "Connecticut", countryCode: "US", code: "CT" },
  { name: "Delaware", countryCode: "US", code: "DE" },
  { name: "District of Columbia", countryCode: "US", code: "DC" },
  { name: "Florida", countryCode: "US", code: "FL" },
  { name: "Georgia", countryCode: "US", code: "GA" },
  { name: "Hawaii", countryCode: "US", code: "HI" },
  { name: "Idaho", countryCode: "US", code: "ID " },
  { name: "Illinois", countryCode: "US", code: "IL" },
  { name: "Indiana", countryCode: "US", code: "IN" },
  { name: "Iowa", countryCode: "US", code: "IA" },
  { name: "Kansas", countryCode: "US", code: "KS" },
  { name: "Kentucky", countryCode: "US", code: "KY" },
  { name: "Louisiana", countryCode: "US", code: "LA" },
  { name: "Maine", countryCode: "US", code: "ME" },
  { name: "Maryland", countryCode: "US", code: "MD" },
  { name: "Massachusetts", countryCode: "US", code: "MA" },
  { name: "Michigan", countryCode: "US", code: "MI" },
  { name: "Minnesota", countryCode: "US", code: "MN" },
  { name: "Mississippi", countryCode: "US", code: "MS" },
  { name: "Missouri", countryCode: "US", code: "MO" },
  { name: "Montana", countryCode: "US", code: "MT" },
  { name: "Nebraska", countryCode: "US", code: "NE" },
  { name: "Nevada", countryCode: "US", code: "NV" },
  { name: "New Hampshire", countryCode: "US", code: "NH" },
  { name: "New Jersey", countryCode: "US", code: "NJ" },
  { name: "New Mexico", countryCode: "US", code: "NM" },
  { name: "New York", countryCode: "US", code: "NY" },
  { name: "North Carolina", countryCode: "US", code: "NC" },
  { name: "North Dakota", countryCode: "US", code: "ND" },
  { name: "Ohio", countryCode: "US", code: "OH" },
  { name: "Oklahoma", countryCode: "US", code: "OK" },
  { name: "Oregon", countryCode: "US", code: "OR" },
  { name: "Pennsylvania", countryCode: "US", code: "PA" },
  { name: "Rhode Island", countryCode: "US", code: "RI" },
  { name: "South Carolina", countryCode: "US", code: "SC" },
  { name: "South Dakota", countryCode: "US", code: "SD" },
  { name: "Tennessee", countryCode: "US", code: "TN" },
  { name: "Texas", countryCode: "US", code: "TX" },
  { name: "Utah", countryCode: "US", code: "UT" },
  { name: "Vermont", countryCode: "US", code: "VT" },
  { name: "Virginia", countryCode: "US", code: "VA" },
  { name: "Washington", countryCode: "US", code: "WA" },
  { name: "West Virginia", countryCode: "US", code: "WV" },
  { name: "Wisconsin", countryCode: "US", code: "WI" },
  { name: "Wyoming", countryCode: "US", code: "WY" },
  { name: "Alberta", countryCode: "CA", code: "AB" },
  { name: "British Columbia", countryCode: "CA", code: "BC" },
  { name: "Manitoba", countryCode: "CA", code: "MB" },
  { name: "New Brunswick", countryCode: "CA", code: "NB" },
  { name: "Newfoundland and Labrador", countryCode: "CA", code: "NL" },
  { name: "Northwest Territories", countryCode: "CA", code: "NT" },
  { name: "Nova Scotia", countryCode: "CA", code: "NS" },
  { name: "Nunavut", countryCode: "CA", code: "NU" },
  { name: "Ontario", countryCode: "CA", code: "ON" },
  { name: "Prince Edward Island", countryCode: "CA", code: "PE" },
  { name: "Quebec", countryCode: "CA", code: "QC" },
  { name: "Saskatchewan", countryCode: "CA", code: "SK" },
  { name: "Yukon", countryCode: "CA", code: "YT" }
];
