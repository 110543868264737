
import Vue from "vue";
import {
  IApplicantAddress,
  IScreeningResponse,
  IUIFeatureObjectModel
} from "@/types";
import store from "@/store";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import tesControlTemplate from "@/components/common/TESControlTemplate.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";
import ExpansionPanelTemplate from "@/components/common/ExpansionPanelTemplate.vue";
import InfoSection from "@/components/common/InfoSection.vue";
import { expansionPanelIconObj, CNST_TEXT } from "@/constants/CommonConstants";
import TESRadioControl from "@/components/common/TESRadioControl.vue";
import { GPC } from "@/constants/GPCCode";
import DynamicTextMixin from "@/mixins/DynamicTextMixin.vue";
import { clone } from "@/util/clone";
import helper from "@/composables/helper";

export default Vue.extend({
  name: "tes-address",
  mixins: [
    fieldEntryMixin,
    UIFeatureMixinVue,
    ApplicationMixin,
    DynamicTextMixin
  ],
  components: {
    tesControlTemplate,
    ExpansionPanelTemplate,
    InfoSection,
    TESRadioControl
  },
  data() {
    return {
      addressLocal: this.value,
      stateCollegeInd: "" as string,
      sameMailingLocal: this.sameMailingInd,
      zipCode: this.value.zip,
      expPanelIconObj: expansionPanelIconObj,
      accessiblePrintMatIndLcl: this.accessiblePrintMatInd,
      printMatFormatsListLcl: this.printMatFormatsList,
      otherPrintMatFormatLcl: this.otherPrintMatFormat
    };
  },
  props: {
    value: {
      type: Object as () => IApplicantAddress
    },
    sameMailingInd: { type: String, default: "" },
    accessiblePrintMatInd: { type: String, default: "" },
    printMatFormatsList: { type: Array, default: null },
    otherPrintMatFormat: { type: String, default: "" }
  },
  setup() {
    const { getAriaLabel } = helper();
    return { getAriaLabel };
  },
  watch: {
    addressLocal: {
      deep: true,
      handler(newVal: IApplicantAddress) {
        this.$emit("input", newVal);
      }
    },
    value: {
      deep: true,
      handler(newVal: IApplicantAddress) {
        this.addressLocal = newVal;
      }
    },
    sameMailingLocal(newVal: string) {
      this.$emit("mailingIndUpated", newVal);
    },
    sameMailingInd(newVal: string) {
      this.sameMailingLocal = newVal;
    },
    zipCode(newVal: string) {
      //@ts-ignore
      this.addressLocal.zip = this.unmaskedPhone(newVal);
    },
    accessiblePrintMatIndLcl(newVal: string) {
      this.$emit("accessiblePrintMatIndUpd", newVal);
    },
    accessiblePrintMatInd(newVal: string) {
      this.accessiblePrintMatIndLcl = newVal;
    },
    printMatFormatsListLcl(newVal: []) {
      this.$emit("printMatFormatsListUpd", newVal);
    },
    printMatFormatsList(newVal: []) {
      this.printMatFormatsListLcl = newVal;
    },
    otherPrintMatFormatLcl(newVal: string) {
      this.$emit("otherPrintMatFormatUpd", newVal);
    },
    otherPrintMatFormat(newVal: string) {
      this.otherPrintMatFormatLcl = newVal;
    }
  },
  computed: {
    isHomeAddress(): boolean {
      return this.addressLocal.addressTypeCode === "HOME";
    },
    radioOptions(): any {
      //@ts-ignore
      return this.uiFeature.RADIO_YN.children;
    },
    counties(): [] {
      return store.getters.dataModule.getCounties;
    },
    homeCounties(): [] {
      return store.getters.dataModule.getFilteredCountiesInStore;
    },
    screeningResponse: {
      get(): IScreeningResponse {
        //@ts-ignore
        return store.getters.screeningResponseModule.getScreeningResponse;
      }
    },
    textForAddress(): string {
      let addressText: string = "";
      let gpcCode = "MAIL_INFO";
      if (this.isHomeAddress) {
        gpcCode = "HMEAD_INFO";
        addressText = this.getHomeAddressText(gpcCode);
      } else {
        //@ts-ignore
        if (this.isOrganizationUser || this.isAgencyUser) {
          //@ts-ignore
          addressText = this.buildDynamicText(
            //@ts-ignore
            this.uiFeature[gpcCode].largeText,
            "the applicant's",
            "them"
          );
          //@ts-ignore
        } else if (this.isCitizenUser) {
          if (this.screeningResponse.onBehalfOfInd === "Y") {
            //@ts-ignore
            addressText = this.buildDynamicText(
              //@ts-ignore
              this.uiFeature[gpcCode].largeText,
              "the applicant's",
              "them"
            );
          } else {
            //@ts-ignore
            addressText = this.buildDynamicText(
              //@ts-ignore
              this.uiFeature[gpcCode].largeText,
              "your",
              "you"
            );
          }
        }
      }
      return addressText;
    },
    stateCollegeQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithOnePlaceHolder(
        GPC.ST_COLLG_Q,
        CNST_TEXT.DOES_THE_APPLICANT,
        CNST_TEXT.DO_YOU
      );
      return text;
    },
    isMailAddressSameQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithTwoPlaceHolder(
        GPC.IS_MAIL_AD,
        CNST_TEXT.IS_THE_APPLICANTS,
        CNST_TEXT.THEIR,
        CNST_TEXT.IS_YOUR,
        CNST_TEXT.YOUR
      );
      return text;
    }
  },
  methods: {
    getHomeAddressText(gpc: string): string {
      let result: string = "";
      //@ts-ignore
      if (this.isOrganizationUser || this.isAgencyUser) {
        //@ts-ignore
        result = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[gpc].largeText,
          "the applicant's",
          "the applicant is",
          "their",
          "them",
          "The applicant's",
          "their",
          "their"
        );
        //@ts-ignore
      } else if (this.isCitizenUser) {
        if (this.screeningResponse.onBehalfOfInd === "Y") {
          //@ts-ignore
          result = this.buildDynamicText(
            //@ts-ignore
            this.uiFeature[gpc].largeText,
            "the applicant's",
            "the applicant is",
            "their",
            "them",
            "The applicant's",
            "their",
            "their"
          );
        } else {
          //@ts-ignore
          result = this.buildDynamicText(
            //@ts-ignore
            this.uiFeature[gpc].largeText,
            "your",
            "you are",
            "your",
            "you",
            "Your",
            "your",
            "your"
          );
        }
      }
      return result;
    },
    excludeOtherOptn(): IUIFeatureObjectModel[] {
      let x: IUIFeatureObjectModel[];
      //@ts-ignore
      x = clone(this.uiFeature.ACCBL_FRMT.children);
      x.pop();
      return x;
    }
  }
});
