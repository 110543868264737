var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('v-row',[_c('v-col',{staticClass:"px-16 py-1 colAlignment"},[_c('h6',[_vm._v(" Demographic Information ")]),_c('v-card',{staticClass:"borderRadius pa-6",attrs:{"width":"100%"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('readonlyField',{attrs:{"label":_vm.primaryLanguageQues,"value":_vm.uiFeature[_vm.applicantProfileInfo.primaryLanguageCode]
                  .displayName,"previous":_vm.displayName(_vm.applicantProfileInfoPrevious.primaryLanguageCode),"showPrevious":_vm.showPreviousValues}})],1),(_vm.applicantProfileInfo.primaryLanguageCode != 'ENG')?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('readonlyField',{attrs:{"label":_vm.interpreterQues,"value":_vm.uiFeature[_vm.applicantProfileInfo.interpreterInd].largeText,"previous":_vm.largeText(_vm.applicantProfileInfoPrevious.interpreterInd),"showPrevious":_vm.showPreviousValues}})],1):_vm._e()],1),_c('v-row',[_c('v-col',[_c('readonlyField',{attrs:{"label":_vm.raceEthnicityQues,"value":_vm.displayName(_vm.applicantProfileInfo.ethnicityCode),"previous":_vm.displayName(_vm.applicantProfileInfoPrevious.ethnicityCode),"showPrevious":_vm.showPreviousValues}})],1)],1),_c('v-row',[_c('v-col',[_c('readonlyField',{attrs:{"label":_vm.nursingHomeQues,"value":_vm.largeText(_vm.applicantProfileInfo.nursingHomeInd),"previous":_vm.largeText(_vm.applicantProfileInfoPrevious.nursingHomeInd),"showPrevious":_vm.showPreviousValues}})],1)],1),(_vm.applicantProfileInfo.nursingHomeInd === 'Y')?_c('v-row',[_c('v-col',[_c('readonlyField',{attrs:{"label":_vm.nursingHomeDischargeQues,"value":_vm.uiFeature[_vm.applicantProfileInfo.nursingHomeDischargeInd]
                  .largeText,"previous":_vm.largeText(
                  _vm.applicantProfileInfoPrevious.nursingHomeDischargeInd
                ),"showPrevious":_vm.showPreviousValues}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('readonlyField',{attrs:{"label":_vm.perCareAssistQues,"value":_vm.largeText(_vm.applicantProfileInfo.assistedLivingInd),"previous":_vm.largeText(_vm.applicantProfileInfoPrevious.assistedLivingInd),"showPrevious":_vm.showPreviousValues}})],1)],1),(_vm.applicantProfileInfo.assistedLivingInd === 'Y')?_c('v-row',[_c('v-col',[_c('readonlyField',{attrs:{"label":_vm.perCareAsstTransQues,"value":_vm.uiFeature[_vm.applicantProfileInfo.assistedLivingTransportInd]
                  .largeText,"previous":_vm.largeText(
                  _vm.applicantProfileInfoPrevious.assistedLivingTransportInd
                ),"showPrevious":_vm.showPreviousValues}})],1)],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }