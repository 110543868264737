
import Vue from "vue";
import router from "@/router";
import SurrogateDialog from "@/components/dashboard/SurrogateDialog.vue";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";

export default Vue.extend({
  name: "dashboard-initial",
  components: {
    SurrogateDialog
  },
  mixins: [ApplicationMixin],
  data() {
    return {
      showSurrogateDialog: false,
      displaySave: false
    };
  },
  computed: {},
  methods: {
    getScreeningQuestions() {
      this.showSurrogateDialog = true;
    },
    getAbout() {
      router.push({ name: "about" });
    },
    closeSurrogateDialog() {
      this.showSurrogateDialog = false;
    }
  }
});
