
import Vue from "vue";
import {
  IApplicantProfile,
  IISAMUserAttributes,
  IScreeningResponse,
  ITESUser
} from "@/types";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import { TES_APP_ENDPOINT } from "@/constants/EndPoints";
import store from "@/store";
import axios from "axios";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import ApplicantProfileMixin from "@/mixins/ApplicantProfileMixin.vue";
import readonlyField from "@/components/common/ReadOnlyField.vue";

export default Vue.extend({
  name: "review-preparer-info",
  components: { readonlyField },
  mixins: [
    fieldEntryMixin,
    UIFeatureMixinVue,
    AppPropsMixin,
    ApplicantProfileMixin
  ],
  data() {
    return {
      prepIIsamUserAttributes: {} as IISAMUserAttributes
    };
  },
  created() {
    if (this.applicantProfileInfo.createdByUserId) {
      this.getReviewUserAttributes();
    }
  },
  computed: {
    applicantProfileInfo: {
      get(): IApplicantProfile {
        //@ts-ignore
        return store.getters.applicantProfileModule.getApplicantProfile;
      },
      set(newValue: IApplicantProfile) {
        //@ts-ignore
        store.commit.applicantProfileModule.SET_APPLICANT_PROFILE(newValue);
      }
    },
    userInfo: {
      get(): ITESUser {
        //@ts-ignore
        return store.getters.tesUserModule.getTesUser;
      }
    },
    phoneMasked(): any {
      let that: any = this;
      return that.phoneMask(this.userInfo.phoneNumber);
    },
    isamUserAttributes: {
      get(): IISAMUserAttributes {
        //@ts-ignore
        return store.getters.isamUserAttributesModule.getISAMUserAttributes;
      }
    },
    screeningResponse: {
      get(): IScreeningResponse {
        return store.getters.screeningResponseModule.getScreeningResponse;
      }
    }
  },
  methods: {
    getReviewUserAttributes() {
      let url = TES_APP_ENDPOINT.ISAM_GET_USERATTRIBUTES_BYID;
      let userId = this.applicantProfileInfo.createdByUserId;
      axios
        .get(url, {
          params: {
            userId: userId
          }
        })
        .then(response => {
          this.prepIIsamUserAttributes = response.data;
          store.commit.isamUserAttributesModule.SET_ISAM_USERATTRIBUTES(
            response.data
          );
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        });
    }
  }
});
