export const LOGGER = {
  EventCat: {
    Button: "Button",
    Drag_N_Drop: "Drag_N_Drop",
    Red: "Red"
  },
  EventAction: {
    Navigate: "Navigate",
    Validate: "Validate",
    Submit: "Submit",
    Save: "Save",
    ExpandCollapse: "Expand/Collapse",
    RouteSwap: "Route Swap",
    RouteReorder: "Route Reorder",
    VehicleReorder: "Vehicle Reorder",
    RouteAnalyzeBridge: "RouteAnalyzeBridge"
  },
  logEvent: function logEvent() {
    // will implement the logic in next release
  },
  logException: function logException(error) {
    // will implement the logic in next release
  }
};
