
import Vue from "vue";

export default Vue.extend({
  name: "read-only-field",
  props: {
    label: { type: String, default: "" },
    value: { type: String, default: "" },
    previous: { type: String, default: "" },
    showPrevious: { type: Boolean, default: false }
  },
  computed: {
    isPreviousSame(): boolean {
      let previousSame = true;

      if (this.showPrevious) {
        let valueTemp: string = this.value
          ? this.value.toLowerCase()
          : this.value;

        let previousTemp: string = this.previous
          ? this.previous.toLowerCase()
          : this.previous;

        previousSame = valueTemp === previousTemp;
      }

      return previousSame;
    },
    dontMatchClass(): string {
      let fieldClasses = "font-weight-bold word-wrap d-inline-block";
      return this.isPreviousSame ? fieldClasses : fieldClasses + " dontMatch";
    }
  }
});
