/**
 * Clones an object into a new instance
 * @param obj The object to be cloned
 * @returns A cloned version of the object
 */
export function clone(obj: any): any {
  let clonedObj = null;

  if (obj != null) {
    const stringified = JSON.stringify(obj);
    clonedObj = JSON.parse(stringified);
  }

  return clonedObj;
}

/**
 * An improved clone which maintains the type of the original
 * @param obj The object to be cloned
 * @returns a fully typed clone of the object
 */
export function tclone<T>(obj: T): T {
  let clonedObj: T = {} as T;
  if (obj) {
    const str = JSON.stringify(obj);
    clonedObj = JSON.parse(str);
  }
  return clonedObj;
}
