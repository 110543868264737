
import Vue from "vue";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import DraftProgramMixin from "@/mixins/DraftProgramMixin.vue";
import ProgressBar from "@/components/common/ProgressBar.vue";
import store from "@/store";
import { IApplicantProfile, IDraftProgramInfo, IProgramsList } from "@/types";
import TESRadioControl from "@/components/common/TESRadioControl.vue";
import tesContainer from "@/components/common/TESContainer.vue";
import routerMixin from "@/mixins/RouterMixin.vue";
import StepperNavigationMixinVue from "@/mixins/StepperNavigationMixin.vue";
import { BUTTON_NAMES, CNST_TEXT } from "@/constants/CommonConstants";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import InfoSection from "@/components/common/InfoSection.vue";
import ScrollMixin from "@/mixins/ScrollMixin.vue";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";
import DynamicTextMixin from "@/mixins/DynamicTextMixin.vue";
import { GPC } from "@/constants/GPCCode";

export default Vue.extend({
  name: "pwd-program-info",
  mixins: [
    fieldEntryMixin,
    DraftProgramMixin,
    routerMixin,
    StepperNavigationMixinVue,
    UIFeatureMixinVue,
    AppPropsMixin,
    ScrollMixin,
    ApplicationMixin,
    DynamicTextMixin
  ],
  components: { tesContainer, TESRadioControl, ProgressBar, InfoSection },
  props: {
    applicantAge: { type: Number, default: 0 },
    stepCount: { type: Number, default: 0 }
  },
  data() {
    return {
      showProgressBar: false,
      showDefaultButtons: true,
      steps: 3
    };
  },
  computed: {
    applicantProgramInfo(): IProgramsList {
      return store.getters.applicantProgramModule.getApplicantProgram;
    },
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      },
      set(newValue: IDraftProgramInfo) {
        store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(newValue);
      }
    },
    infoMessageHeaderAge(): string {
      return `Based on the information you provided, you are ${this.applicantAge} years old`;
    },
    applicantProfile(): IApplicantProfile {
      return store.getters.applicantProfileModule.getApplicantProfile;
    },
    pwdProgInfo(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PWD_SEC1_1].largeText,
          CNST_TEXT.THE_APPLICANTS,
          CNST_TEXT.THEY
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PWD_SEC1_1].largeText,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOU
        );
      }
      return text;
    },
    pwdProgInfoTwo(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PWD_SEC1].largeText,
          CNST_TEXT.THE_APPLICANTS,
          CNST_TEXT.THE_APPLICANTS
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PWD_SEC1].largeText,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOUR
        );
      }
      return text;
    },
    disabilityConfirmQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithOnePlaceHolder(
        GPC.DIS_CNFM,
        CNST_TEXT.THE_APPLICANT_HAS,
        CNST_TEXT.YOU_HAVE
      );
      return text;
    }
  },
  created() {
    if (this.draftProgramInfo.disabilityInd === "N") {
      this.showDefaultButtons = false;
    }
  },
  methods: {
    cancel() {
      //@ts-ignore
      if (this.$refs.pwdProgramInfoForm.validate()) {
        this.saveDraftProgramInfo("CANCEL");
      } else {
        //@ts-ignore
        this.scrollToErrorField(this.$refs.pwdProgramInfoForm);
      }
    },
    save(buttonName: string) {
      //@ts-ignore
      if (this.$refs.pwdProgramInfoForm.validate()) {
        if (this.draftProgramInfo.disabilityInd === "N") {
          this.saveDraftProgramInfo(buttonName);
        } else {
          this.saveDraftProgramInfo("SAVE");
        }
      } else {
        //@ts-ignore
        this.scrollToErrorField(this.$refs.pwdProgramInfoForm);
      }
    },
    saveDraftProgramInfo(buttonName: string) {
      this.showProgressBar = true;
      let stepperIndex = 1;
      //@ts-ignore
      this.saveProgramInfo(
        this.draftProgramInfo,
        stepperIndex,
        this.stepCount,
        buttonName
      )
        //@ts-ignore
        .then(response => {
          //@ts-ignore
          this.draftProgramInfo = response.data;

          store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(
            this.draftProgramInfo
          );

          store.commit.applicantProgramModule.SET_APPLICANT_PROGRAM(
            this.draftProgramInfo.programInfo
          );

          if (
            BUTTON_NAMES.SAVE === buttonName ||
            BUTTON_NAMES.CANCEL === buttonName
          ) {
            this.$emit("save-completed", buttonName);
          } else {
            if (
              //@ts-ignore
              this.isSingleFlow()
            ) {
              this.$emit("save-completed", buttonName);
            } else {
              //@ts-ignore
              this.forwardToDashboard();
            }
          }
        })
        //@ts-ignore
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    },
    changeValues() {
      this.showDefaultButtons = true;
      if (
        //@ts-ignore
        this.isSingleFlow()
      ) {
        this.steps = 2;
      } else {
        this.steps = 3;
      }
      if (this.draftProgramInfo.disabilityInd === "N") {
        this.showDefaultButtons = false;
        this.steps = 1;
      }
      this.$emit("step-count", this.steps);
    }
  }
});
