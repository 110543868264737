
import Vue from "vue";
import store from "@/store";
import { IDraftProgramInfo } from "@/types";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import ProgramInfoSummaryMixin from "@/mixins/ProgramInfoSummaryMixin.vue";
import readonlyField from "@/components/common/ReadOnlyField.vue";
import { GPC } from "@/constants/GPCCode";
import DynamicTextMixin from "@/mixins/DynamicTextMixin.vue";
import { CNST_TEXT } from "@/constants/CommonConstants";

export default Vue.extend({
  name: "VTP-General-Information",
  mixins: [UIFeatureMixinVue, ProgramInfoSummaryMixin, DynamicTextMixin],
  components: { readonlyField },
  props: {
    programPage: { type: String, default: "" }
  },
  computed: {
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        //@ts-ignore
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      }
    },
    veteranQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithOnePlaceHolder(
        GPC.VTP_INFO,
        CNST_TEXT.THE_APPLICANT_IS,
        CNST_TEXT.YOU_ARE
      );
      return text;
    }
  }
});
