
import Vue from "vue";
import PersonalInfo from "@/components/application/PersonalInfo.vue";
import HomeAddress from "@/components/application/HomeAddress.vue";
import EmergencyContact from "@/components/application/EmergencyContact.vue";
import DemographicInfo from "@/components/application/DemographicInfo.vue";
import TransportationInfo from "@/components/application/TransportationInfo.vue";
import MobilityInfo from "@/components/application/MobilityInfo.vue";
import axios from "axios";
import { TES_APP_ENDPOINT, GENERAl_INFO_ENDPOINT } from "@/constants/EndPoints";
import {
  IApplicantAddress,
  IApplicantProfile,
  ITESUser,
  IScreeningResponse,
  IApplicationNavigation,
  IProgramsList,
  IStepperHeaderList,
  IGISDataModel
} from "@/types";
import { applicantAddress } from "@/data/AppData";
import { clone } from "@/util/clone";
import store from "@/store";
import router from "@/router";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import dateHelperMixin from "@/mixins/dateHelperMixin.vue";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import ProgressBar from "@/components/common/ProgressBar.vue";
import ClearStoreValuesMixinVue from "@/mixins/ClearStoreValuesMixin.vue";
import routerMixin from "@/mixins/RouterMixin.vue";
import StepperNavigationMixinVue from "@/mixins/StepperNavigationMixin.vue";
import CancelDialog from "@/components/application/CancelDialog.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import CloseButton from "@/components/common/CloseButton.vue";
import ScrollMixin from "@/mixins/ScrollMixin.vue";
import DynamicTextMixin from "@/mixins/DynamicTextMixin.vue";
import { CNST_TEXT } from "@/constants/CommonConstants";
import { GPC } from "@/constants/GPCCode";
import TESRadioControl from "@/components/common/TESRadioControl.vue";
import AccessibilityMixin from "@/mixins/AccessibilityMixin.vue";
import AttachmentsMixinVue from "@/mixins/AttachmentsMixin.vue";

export default Vue.extend({
  name: "without-steppers-nav",
  mixins: [
    fieldEntryMixin,
    dateHelperMixin,
    AppPropsMixin,
    ClearStoreValuesMixinVue,
    routerMixin,
    StepperNavigationMixinVue,
    UIFeatureMixinVue,
    ScrollMixin,
    DynamicTextMixin,
    AccessibilityMixin,
    AttachmentsMixinVue
  ],
  components: {
    PersonalInfo,
    EmergencyContact,
    HomeAddress,
    DemographicInfo,
    ProgressBar,
    TransportationInfo,
    MobilityInfo,
    CancelDialog,
    CloseButton,
    TESRadioControl
  },
  data() {
    return {
      stepperIndex: 1 as number,
      vertical: true as boolean,
      editable: true as boolean,
      showMailingAddress: false as boolean,
      mailingAddress: {} as IApplicantAddress,
      homeAddress: {} as IApplicantAddress,
      selectedHomeAddress: {} as IApplicantAddress,
      showProgressBar: false,
      profileChange: false,
      selectedCounty: "",
      selectedDOB: "",
      isDOBChanged: false,
      isCountyChanged: false,
      profileChangeHeader: "",
      profileChangeMsg: "",
      singleFlow: false,
      withdraw: false,
      withdrawPrgmList: [] as IProgramsList[],
      buttonSave: "",
      displayCancelDialog: false,
      selectedStateCollgInd: "",
      isStateCollgIndChanged: false,
      gisCounties: [] as string[],
      gisDataModel: {} as IGISDataModel,
      gisCounty: false,
      confirmedCounty: ""
    };
  },
  created() {
    if (
      //@ts-ignore
      this.isSingleFlow()
    ) {
      this.singleFlow = true;
    }
    //@ts-ignore
    store.dispatch.dataModule.getFilteredCounties();
    //@ts-ignore
    store.dispatch.dataModule.getAllCounties();

    this.homeAddress = clone(applicantAddress);
    this.homeAddress.addressTypeCode = "HOME";
    this.homeAddress.paCountyCode = this.screeningResponse.paCountyCode;

    this.isStateCollege();

    if (
      this.applicantProfile.homeAddress &&
      this.applicantProfile.homeAddress.profileAddressId
    ) {
      this.homeAddress = this.applicantProfile.homeAddress;
      this.homeAddress.maskedZip = this.applicantProfile.homeAddress.zip;
      this.selectedHomeAddress = clone(this.applicantProfile.homeAddress);
    }

    this.mailingAddress = clone(applicantAddress);
    this.mailingAddress.addressTypeCode = "MAIL";

    if (
      this.applicantProfile.mailingAddress != null &&
      this.applicantProfile.mailingAddress.profileAddressId
    ) {
      this.mailingAddress = this.applicantProfile.mailingAddress;
      this.mailingAddress.maskedZip = this.applicantProfile.mailingAddress.zip;
    }

    this.selectedCounty = this.homeAddress.paCountyCode;
    this.selectedDOB = this.applicantProfile.applicantDob;
  },
  computed: {
    applicantProfile: {
      get(): IApplicantProfile {
        return store.getters.applicantProfileModule.getApplicantProfile;
      },
      set(newValue: IApplicantProfile) {
        store.commit.applicantProfileModule.SET_APPLICANT_PROFILE(newValue);
      }
    },
    userInfo(): ITESUser {
      return store.getters.tesUserModule.getTesUser;
    },
    screeningResponse(): IScreeningResponse {
      return store.getters.screeningResponseModule.getScreeningResponse;
    },
    counties(): [] {
      return store.getters.dataModule.getCounties;
    },
    applicationNavigation(): IApplicationNavigation {
      return store.getters.applicationNavigationModule.getApplicationNavigation;
    },
    applicantProgramInfo(): IProgramsList {
      return store.getters.applicantProgramModule.getApplicantProgram;
    },
    stepperHeaderMod: {
      get(): IStepperHeaderList {
        return store.getters.stepperHeaderModule.getStepperHeaderModel;
      }
    },
    gisCountyConfirmDialogTitle(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrgAgyCtznonBehalfOfUser()
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.GIS_CNFM_1].largeText,
          CNST_TEXT.THE_APPLICANTS
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.GIS_CNFM_1].largeText,
          CNST_TEXT.YOUR
        );
      }
      return text;
    },
    gisCountyConfirmDialogText(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrgAgyCtznonBehalfOfUser()
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.GIS_CNFM_2].largeText,
          CNST_TEXT.THE_APPLICANT_RESIDES
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.GIS_CNFM_2].largeText,
          CNST_TEXT.YOU_RESIDE
        );
      }
      return text;
    },
    withDrawDynamicText(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrgAgyCtznonBehalfOfUser()
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.WTDRAW_MSG].largeText,
          CNST_TEXT.THE_APPLICANTS,
          CNST_TEXT.APPLICANT
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.WTDRAW_MSG].largeText,
          CNST_TEXT.YOUR,
          CNST_TEXT.YYOU
        );
      }
      return text;
    },
    isAlleghenyCounty(): boolean {
      if (
        (this.homeAddress &&
          this.homeAddress.paCountyCode &&
          this.homeAddress.paCountyCode === "02") ||
        this.applicantProfile.homeAddress.paCountyCode === "02"
      ) {
        return true;
      } else return false;
    },
    isAllDocsUploaded: {
      get() {
        //@ts-ignore
        return store.getters.attachmentsModule.getAllDocsUploaded;
      }
    },
    isHoppingNeeded(): boolean {
      return (
        this.stepperHeaderMod.stepperHeaders[0].fromPrgm !== "REVIEW" ||
        (this.stepperHeaderMod.stepperHeaders[0].fromPrgm === "REVIEW" &&
          (!this.isAttachmentsRequired ||
            (this.isAttachmentsRequired && this.isAllDocsUploaded)))
      );
    }
  },
  methods: {
    addressChangeHeaderText(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrgAgyCtznonBehalfOfUser()
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.ADR_CH_HDR].largeText,
          CNST_TEXT.THE_APPLICANTS
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.ADR_CH_HDR].largeText,
          CNST_TEXT.YOUR
        );
      }
      return text;
    },
    profileChangeMsgOneText(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrgAgyCtznonBehalfOfUser()
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.CHNGE_MSG1].largeText,
          CNST_TEXT.THE_APPLICANTS,
          CNST_TEXT.THE_APPLICANT_WANTS,
          CNST_TEXT.OF_THEIR,
          CNST_TEXT.THEY_SHOULD,
          CNST_TEXT.THEIR
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.CHNGE_MSG1].largeText,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOU_WANT,
          CNST_TEXT.YOUR,
          CNST_TEXT.PLEASE,
          CNST_TEXT.YOUR
        );
      }
      return text;
    },
    profileChangeMsgTwoText(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrgAgyCtznonBehalfOfUser()
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.CHNGE_MSG2].largeText,
          CNST_TEXT.THE_APPLICANTS
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.CHNGE_MSG2].largeText,
          CNST_TEXT.YOUR
        );
      }
      return text;
    },
    isStateCollege() {
      if (
        this.applicantProfile.homeAddress &&
        this.applicantProfile.homeAddress.stateCollegeInd
      ) {
        this.selectedStateCollgInd = this.applicantProfile.homeAddress.stateCollegeInd;
      } else if (
        this.screeningResponse &&
        this.screeningResponse.stateCollegeInd
      ) {
        this.selectedStateCollgInd = this.screeningResponse.stateCollegeInd;
      } else {
        this.selectedStateCollgInd = "";
      }
    },
    showCancelDialog() {
      this.displayCancelDialog = true;
    },
    saveProfileForCancel() {
      this.checkCountyChanges("CANCEL");
      this.displayCancelDialog = false;
    },
    async checkCountyChanges(buttonName: string) {
      if (this.checkValidations()) {
        await this.validateAndSaveAdrs();
        if (!this.gisCounty) {
          this.buttonSave = buttonName;
          const msgChange = this.profileChangeMsgOneText();
          if (
            (this.selectedCounty != this.homeAddress.paCountyCode ||
              this.isAddressChanged()) &&
            this.selectedDOB != this.applicantProfile.applicantDob
          ) {
            this.prepMsgAdrsDob(msgChange);
          } else if (
            this.selectedCounty != this.homeAddress.paCountyCode ||
            this.isAddressChanged()
          ) {
            this.prepMsgAdrs(msgChange);
          } else if (this.selectedDOB != this.applicantProfile.applicantDob) {
            this.isDOBChanged = true;
            this.isCountyChanged = false;
            this.profileChangeHeader = this.addressChangeHeaderText().concat(
              "DOB"
            );
            this.profileChangeMsg = this.profileChangeMsgTwoText();
            this.isStateCollegeInd();
            this.applicantProfile.gisCallInd = "N";
            this.applicantProfile.gisResponseJSON = new Blob();
          } else {
            this.profileChange = false;
            this.isDOBChanged = false;
            this.isCountyChanged = false;
            this.isStateCollegeInd();
            this.saveProfileInfo(buttonName);
          }
          this.cntyChangeOrDob(buttonName);
        }
      }
    },
    cntyChangeOrDob(buttonName: string) {
      if (this.isCountyChanged || this.isDOBChanged) {
        if (buttonName === "CANCEL") {
          this.saveProfileInfo(buttonName);
        } else {
          this.showWithdrawPrograms();
        }
        this.showProgressBar = true;
      }
    },
    prepMsgAdrsDob(msgChange: string) {
      this.isCountyChanged = true;
      this.isDOBChanged = true;
      this.profileChangeHeader = this.addressChangeHeaderText()
        .concat("DOB")
        .concat(
          this.countyAndAddressChanged() ? ", home address and county" : ""
        )
        .concat(this.addressOnlyChanged() ? " and home address" : "")
        .concat(this.countyOnlyChanged() ? " and county" : "");
      this.profileChangeMsg = msgChange;
      this.isStateCollegeInd();
    },
    prepMsgAdrs(msgChange: string) {
      this.isCountyChanged = true;
      this.isDOBChanged = false;
      this.profileChangeHeader = this.addressChangeHeaderText()
        .concat(this.isAddressChanged() ? " home address " : " county")
        .concat(this.countyAndAddressChanged() ? " and county" : "");
      this.profileChangeMsg = msgChange;
    },
    countyChange(msgChange: string) {
      this.isCountyChanged = true;
      this.isDOBChanged = true;
      this.profileChangeHeader = this.addressChangeHeaderText()
        .concat("DOB")
        .concat(
          this.countyAndAddressChanged() ? ", home address and county" : ""
        )
        .concat(this.addressOnlyChanged() ? " and home address" : "")
        .concat(this.countyOnlyChanged() ? " and county" : "");
      this.profileChangeMsg = msgChange;
      this.isStateCollegeInd();
    },
    async validateAndSaveAdrs() {
      this.gisCounty = false;
      this.homeAddress.countyName = this.getCountyName(
        this.homeAddress.paCountyCode
      );
      if (
        !this.confirmedCounty ||
        this.confirmedCounty != this.homeAddress.paCountyCode
      ) {
        await this.getGISCounties();
      }
    },
    async getGISCounties() {
      let url = GENERAl_INFO_ENDPOINT.GET_GIS_COUNTIES;
      await axios
        .post(url, this.homeAddress)
        .then(response => {
          this.gisDataModel = response.data;
          this.gisCounties = this.gisDataModel.countyList;
          if (
            this.gisCounties.length > 0 &&
            !this.gisCounties.includes(this.homeAddress.countyName)
          ) {
            this.confirmedCounty = "";
            this.gisCounty = true;
          }
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        });
    },
    confirmCounty() {
      this.homeAddress.paCountyCode = this.confirmedCounty;
      this.homeAddress.countyName = this.getCountyName(
        this.homeAddress.paCountyCode
      );
      this.gisCounty = false;
    },
    checkValidations() {
      let validation = true;
      if (this.isAlleghenyCounty) {
        //@ts-ignore
        if (!this.$refs.formPersonalInfo.validate()) {
          //@ts-ignore
          this.scrollToErrorField(this.$refs.formPersonalInfo);
          validation = false;
        }
        //@ts-ignore
        else if (!this.$refs.formHomeAddress.validate()) {
          //@ts-ignore
          this.scrollToErrorField(this.$refs.formHomeAddress);
          validation = false;
        }
        //@ts-ignore
        else if (!this.$refs.formEmergencyContact.validate()) {
          //@ts-ignore
          this.scrollToErrorField(this.$refs.formEmergencyContact);
          validation = false;
        }
      } else {
        //@ts-ignore
        if (!this.$refs.formPersonalInfo.validate()) {
          //@ts-ignore
          this.scrollToErrorField(this.$refs.formPersonalInfo);
          validation = false;
        }
        //@ts-ignore
        else if (!this.$refs.formHomeAddress.validate()) {
          //@ts-ignore
          this.scrollToErrorField(this.$refs.formHomeAddress);
          validation = false;
        }
        //@ts-ignore
        else if (!this.$refs.formEmergencyContact.validate()) {
          //@ts-ignore
          this.scrollToErrorField(this.$refs.formEmergencyContact);
          validation = false;
        }
        //@ts-ignore
        else if (!this.$refs.formDemographicInfo.validate()) {
          //@ts-ignore
          this.scrollToErrorField(this.$refs.formDemographicInfo);
          validation = false;
        }
        //@ts-ignore
        else if (!this.$refs.formTransportationInfo.validate()) {
          //@ts-ignore
          this.scrollToErrorField(this.$refs.formTransportationInfo);
          validation = false;
        }
        //@ts-ignore
        else if (!this.$refs.formMobilityInfo.validate()) {
          //@ts-ignore
          this.scrollToErrorField(this.$refs.formMobilityInfo);
          validation = false;
        }
      }
      return validation;
    },
    getCountyName(paCountyCode: string) {
      let countyCode = this.counties.find(
        obj1 =>
          //@ts-ignore
          obj1.id === paCountyCode
      );
      //@ts-ignore
      return countyCode ? countyCode.name : "";
    },
    getCountyCodeForVal(codeName: string): any {
      let code: any;
      for (let i in this.counties) {
        //@ts-ignore
        if (this.counties[i]["name"] === codeName) {
          //@ts-ignore
          code = this.counties[i]["id"];
          break;
        }
      }
      return code;
    },
    getFormattedDOB(dob: string) {
      //@ts-ignore
      return this.formatDateMixin(dob);
    },
    setApplicantAddressIds() {
      // set homeAddress's profileAddressId and applicantProfileId
      this.homeAddress.applicantProfileId = this.applicantProfile.applicantProfileId;

      if (
        this.applicantProfile.homeAddress &&
        this.applicantProfile.homeAddress.profileAddressId
      ) {
        this.homeAddress.profileAddressId = this.applicantProfile.homeAddress.profileAddressId;
      }
      // set mailingAddress's profileAddressId and applicantProfileId
      this.mailingAddress.applicantProfileId = this.applicantProfile.applicantProfileId;
      if (
        this.applicantProfile.mailingAddress &&
        this.applicantProfile.mailingAddress.profileAddressId
      ) {
        this.mailingAddress.profileAddressId = this.applicantProfile.mailingAddress.profileAddressId;
      }
    },
    continueToDashboard() {
      //@ts-ignore
      this.forwardToDashboard();
    },
    setApplicantAddress() {
      if (applicantAddress.addressTypeCode === "HOME") {
        this.homeAddress = applicantAddress;
      } else {
        this.mailingAddress = applicantAddress;
      }
    },
    showWithdrawPrograms() {
      this.showProgressBar = true;
      let url = TES_APP_ENDPOINT.GET_WITHDRAW_APPLICATIONS;
      this.populateApplicantProfile();
      this.applicantProfile.isCountyChanged = this.isCountyChanged;
      this.applicantProfile.isDOBChanged = this.isDOBChanged;
      this.applicantProfile.selectedCounty = this.selectedCounty;
      this.applicantProfile.selectedDOB = this.selectedDOB;
      axios
        .post(url, this.applicantProfile)
        .then(response => {
          //@ts-ignore
          this.applicantProfile = response.data.applicantProfile;
          //@ts-ignore
          store.commit.applicantProfileModule.SET_APPLICANT_PROFILE(
            this.applicantProfile
          );
          //@ts-ignore
          store.commit.programEligModule.SET_PROG_ELIG(
            response.data.programEligModel
          );

          this.isCountyChanged = this.applicantProfile.isCountyChanged;
          this.isDOBChanged = this.applicantProfile.isDOBChanged;
          this.selectedCounty = this.applicantProfile.selectedCounty;
          this.selectedDOB = this.applicantProfile.selectedDOB;

          if (response.data.programEligModel.withdrawPrograms.length > 0) {
            this.withdraw = true;
            this.withdrawPrgmList =
              response.data.programEligModel.withdrawPrograms;
          } else {
            this.withdraw = false;
            this.withdrawPrgmList = [];
          }
          this.profileChange = true;
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    },
    saveProfileInfo(buttonName: string) {
      if (
        //@ts-ignore
        this.$refs.formPersonalInfo.validate() &&
        //@ts-ignore
        this.$refs.formHomeAddress.validate() &&
        //@ts-ignore
        this.$refs.formEmergencyContact.validate()
      ) {
        if (
          (!this.isAlleghenyCounty &&
            //@ts-ignore
            this.$refs.formDemographicInfo.validate() &&
            //@ts-ignore
            this.$refs.formTransportationInfo.validate() &&
            //@ts-ignore
            this.$refs.formMobilityInfo.validate()) ||
          this.isAlleghenyCounty
        ) {
          this.showProgressBar = true;
          this.saveEditedProfileInfo(buttonName);
        }
      } else {
        this.checkValidations();
      }
    },
    populateApplicantProfile() {
      //@ts-ignore
      this.$refs.refEmergencyContact.saveToStore();
      //@ts-ignore
      this.$refs.refPersonalInfo.saveToStore();

      this.setApplicantAddressIds();

      this.applicantProfile.homeAddress = this.homeAddress;
      this.applicantProfile.mailingAddress = this.mailingAddress;

      if (this.applicantProfile.homeAddress.paCountyCode != "14") {
        this.applicantProfile.homeAddress.stateCollegeInd = "";
      }
      if (!this.isAlleghenyCounty) {
        const demoInfo = this.$refs.demographicInfo as any;
        demoInfo.populatePersonalInfo();
        const transportationInfo = this.$refs.transportationInfo as any;
        transportationInfo.populateTransportationInfo();
        const mobilitInfo = this.$refs.mobilityInfo as any;
        mobilitInfo.populateMobilityInfo();
      }

      this.applicantProfile.addedPrograms = "";
      this.applicantProfile.removedPrograms = "";
      this.applicantProfile.noChangePrograms = "";
      this.applicantProfile.updatedPrograms = "";
      this.applicantProfile.withdrawPrograms = "";
    },
    saveEditedProfileInfo(buttonName: string) {
      let url = TES_APP_ENDPOINT.SAVE_EDIT_PROFILE_INFO;
      this.populateApplicantProfile();
      this.applicantProfile.isCountyChanged = this.isCountyChanged;
      this.applicantProfile.isDOBChanged = this.isDOBChanged;
      this.applicantProfile.selectedCounty = this.selectedCounty;
      this.applicantProfile.selectedDOB = this.selectedDOB;
      this.applicantProfile.applicationId = this.applicantProgramInfo.applicationId;
      this.applicantProfile.isStateCollgIndChanged = this.isStateCollgIndChanged;
      if (!this.gisCounties.includes(this.homeAddress.countyName)) {
        this.applicantProfile.gisCallInd = "N";
      }
      if (
        this.screeningResponse &&
        this.screeningResponse.screeningResponseId
      ) {
        this.applicantProfile.screeningResponseId = this.screeningResponse.screeningResponseId;
      }
      axios
        .post(url, this.applicantProfile)
        .then(response => {
          //@ts-ignore
          this.applicantProfile = response.data.applicantProfile;
          let ageThreshold = response.data.ageThreshold;
          //@ts-ignore
          store.commit.applicantProfileModule.SET_APPLICANT_PROFILE(
            this.applicantProfile
          );
          this.setProfileEditMessage(ageThreshold);
          if (response.data.programEligModel.preEligiblePrograms.length > 0) {
            //@ts-ignore
            store.commit.programEligModule.SET_PROG_ELIG(
              response.data.programEligModel
            );
          }

          this.setProgramEligModel(response);
          this.forwardTO(buttonName, response);
        })
        .catch(error => {
          this.profileChange = false;
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    },
    setProfileEditMessage(ageThreshold: boolean) {
      if (
        this.isCountyChanged ||
        this.isDOBChanged ||
        this.applicantProfile.isStateCollgIndChanged
      ) {
        this.applicantProfile.isCountyChanged = this.isCountyChanged;
        this.applicantProfile.isDOBChanged = this.isDOBChanged;
        if (ageThreshold) {
          this.applicantProfile.profileSuccessMsg =
            "Your changes were saved successfully.";
        } else {
          this.applicantProfile.profileEditMsg =
            "The update was completed. Some program applications have changed.";
        }
      } else {
        this.applicantProfile.isCountyChanged = this.isCountyChanged;
        this.applicantProfile.isDOBChanged = this.isDOBChanged;
        this.applicantProfile.profileSuccessMsg =
          "Your changes were saved successfully.";
      }
    },
    setProgramEligModel(response: any) {
      if (
        null !==
          response.data.tesApplicationModel.programEligModel
            .preEligiblePrograms &&
        response.data.tesApplicationModel.programEligModel.preEligiblePrograms
      ) {
        if (
          response.data.tesApplicationModel.programEligModel.preEligiblePrograms
            .length > 0
        ) {
          //@ts-ignore
          store.commit.programEligModule.SET_PROG_ELIG(
            response.data.tesApplicationModel.programEligModel
          );
        }
      }
    },
    validCountyAddressChange() {
      return (
        this.isCountyChanged || this.isDOBChanged || this.isAddressChanged()
      );
    },
    async forwardTO(buttonName: string, response: any) {
      if (!buttonName) {
        buttonName = this.buttonSave;
      }
      await this.isDocTabNeeded();
      if (buttonName === "CANCEL") {
        this.continueToDashboard();
      } else if (buttonName === "SAVE") {
        this.profileChange = false;
        this.buttonSave = "";
        this.$emit("save-clicked", this.validCountyAddressChange());
      } else {
        if (
          null !=
            response.data.tesApplicationModel.programEligModel
              .preEligiblePrograms &&
          response.data.tesApplicationModel.programEligModel.preEligiblePrograms
            .length === 1 &&
          this.applicantProgramInfo.statusCode === "IN_PRGS_C"
        ) {
          //@ts-ignore
          this.forwardToNextStep();
        } else if (
          response.data.programEligModel.preEligiblePrograms.length > 0
        ) {
          //@ts-ignore
          store.commit.applicantProgramModule.SET_APPLICANT_PROGRAM(
            response.data.programEligModel.preEligiblePrograms[0]
          );
          this.profileChange = false;
          if (
            this.applicantProfile.profileSuccessMsg &&
            "fromPrgm" in this.stepperHeaderMod.stepperHeaders[0] &&
            this.stepperHeaderMod.stepperHeaders[0].fromPrgm &&
            this.isHoppingNeeded
          ) {
            let fromPrgm = this.stepperHeaderMod.stepperHeaders[0].fromPrgm;
            let fromAgency = this.stepperHeaderMod.stepperHeaders[0].fromAgency;
            let fromIndex = this.stepperHeaderMod.stepperHeaders[0].fromIndex;
            this.stepperHeaderMod.stepperHeaders[
              fromIndex
            ].isCurrentStep = true;
            this.stepperHeaderMod.stepperHeaders[0].isCurrentStep = false;
            router.push({
              path: "/application-program/" + fromPrgm,
              query: {
                profileID: String(this.applicantProfile.applicantProfileId),
                prgmType: fromPrgm,
                agencyType: fromAgency
              }
            });
          } else {
            //@ts-ignore
            this.getStepperheaders();
          }
        } else if (this.applicationNavigation.applProfileEdit) {
          router.push({
            path:
              "/application-review/" + this.applicantProgramInfo.programTypeCode
          });
        } else {
          //@ts-ignore
          store.dispatch.stepperHeaderModule.resetData();
          store.dispatch.applicantProgramModule.resetData();
          router.push({
            name: "reviewProfileInfo",
            query: {
              profileID: String(this.applicantProfile.applicantProfileId),
              scrnRespID: String(this.screeningResponse.screeningResponseId)
            }
          });
        }
      }
    },
    async isDocTabNeeded() {
      if (
        this.stepperHeaderMod.stepperHeaders.length > 0 &&
        "fromPrgm" in this.stepperHeaderMod.stepperHeaders[0] &&
        this.stepperHeaderMod.stepperHeaders[0].fromPrgm === "REVIEW"
      ) {
        //@ts-ignore
        await this.compareAllDocsAttached();
      }
    },
    isStateCollegeInd() {
      if (
        this.applicantProfile.homeAddress.stateCollegeInd &&
        this.selectedStateCollgInd &&
        this.selectedStateCollgInd !=
          this.applicantProfile.homeAddress.stateCollegeInd
      ) {
        this.isStateCollgIndChanged = true;
      }
    },
    isAddressChanged() {
      let addressChanged = false;
      if (
        this.selectedHomeAddress.addressLine1 !=
          this.homeAddress.addressLine1 ||
        this.selectedHomeAddress.addressLine2 !=
          this.homeAddress.addressLine2 ||
        this.selectedHomeAddress.city != this.homeAddress.city ||
        this.selectedHomeAddress.zip != this.homeAddress.zip
      ) {
        addressChanged = true;
      }
      return addressChanged;
    },
    countyAndAddressChanged() {
      let bothChanged = false;
      if (
        this.selectedCounty != this.homeAddress.paCountyCode &&
        this.isAddressChanged()
      ) {
        bothChanged = true;
      }
      return bothChanged;
    },
    countyOnlyChanged() {
      let countyChanged = false;
      if (
        this.selectedCounty != this.homeAddress.paCountyCode &&
        !this.isAddressChanged()
      ) {
        countyChanged = true;
      }
      return countyChanged;
    },
    addressOnlyChanged() {
      let addressChanged = false;
      if (
        this.selectedCounty === this.homeAddress.paCountyCode &&
        this.isAddressChanged()
      ) {
        addressChanged = true;
      }
      return addressChanged;
    },
    countyChanged() {
      let countyChanged = false;
      if (this.selectedCounty != this.homeAddress.paCountyCode) {
        countyChanged = true;
      }
      return countyChanged;
    },
    addressAndDOBChanged() {
      let bothChanged = false;
      if (
        this.selectedCounty != this.homeAddress.paCountyCode &&
        this.isAddressChanged() &&
        this.selectedDOB != this.applicantProfile.applicantDob
      ) {
        bothChanged = true;
      }
      return bothChanged;
    }
  },
  mounted() {
    //@ts-ignore
    this.$vuetify.goTo(0, {
      duration: 500,
      offset: 0,
      easing: "easeInOutCubic"
    });
  },
  watch: {
    profileChange: {
      immediate: true,
      handler: function(newValue) {
        if (newValue) {
          this.$nextTick(() => {
            //@ts-ignore
            this.setAriaAttribute(this.$refs.editProfileChangeDialogRef);
          });
        }
      }
    },
    gisCounty: {
      immediate: true,
      handler: function(newValue) {
        if (newValue) {
          this.$nextTick(() => {
            //@ts-ignore
            this.setAriaAttribute(this.$refs.editGISDialogRef);
          });
        }
      }
    }
  }
});
