
import Vue from "vue";
import { IApplicantProfile, IDraftProgramInfo, IProgramsList } from "@/types";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import DraftProgramMixin from "@/mixins/DraftProgramMixin.vue";
import ProgressBar from "@/components/common/ProgressBar.vue";
import store from "@/store";
import tesContainer from "@/components/common/TESContainer.vue";
import PADrivingLicense from "@/components/application/program/PADrivingLicense.vue";
import router from "@/router";
import StepperNavigationMixinVue from "@/mixins/StepperNavigationMixin.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import ScrollMixin from "@/mixins/ScrollMixin.vue";
import { CNST_TEXT } from "@/constants/CommonConstants";
import { GPC } from "@/constants/GPCCode";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";

export default Vue.extend({
  mixins: [
    fieldEntryMixin,
    DraftProgramMixin,
    StepperNavigationMixinVue,
    UIFeatureMixinVue,
    AppPropsMixin,
    ScrollMixin,
    ApplicationMixin
  ],
  name: "ftp-detail-info",
  props: {
    stepCount: {
      type: Number
    }
  },
  components: {
    tesContainer,
    PADrivingLicense,
    ProgressBar
  },
  data() {
    return {
      showProgressBar: false,
      steps: this.stepCount,
      singleFlowFinalStep: false,
      disableSave: true
    };
  },
  methods: {
    saveContinue() {
      //@ts-ignore
      if (this.$refs.ftpDetailInfoTemp.validate()) {
        this.saveDraftProgramInfo("SAVECONTINUE");
      } else {
        //@ts-ignore
        this.scrollToErrorField(this.$refs.ftpDetailInfoTemp);
      }
    },
    save() {
      //@ts-ignore
      if (this.$refs.ftpDetailInfoTemp.validate()) {
        this.saveDraftProgramInfo("SAVE");
      } else {
        //@ts-ignore
        this.scrollToErrorField(this.$refs.ftpDetailInfoTemp);
      }
    },
    cancel() {
      //@ts-ignore
      if (this.$refs.ftpDetailInfoTemp.validate()) {
        this.saveDraftProgramInfo("CANCEL");
      } else {
        //@ts-ignore
        this.scrollToErrorField(this.$refs.ftpDetailInfoTemp);
      }
    },
    saveDraftProgramInfo(buttonName: string) {
      this.showProgressBar = true;
      let stepperIndex = 2;
      this.draftProgramInfo =
        //@ts-ignore
        store.getters.draftProgramInfoModule.getDraftProgramInfo;
      if (this.draftProgramInfo.ageVerificationMethodCode === "N") {
        this.draftProgramInfo.driverLicenseNumber = "";
        this.draftProgramInfo.driverLicenseVerifyCode = "";
      }
      //@ts-ignore
      this.saveProgramInfo(
        this.draftProgramInfo,
        stepperIndex,
        this.stepCount,
        "SAVE"
      )
        //@ts-ignore
        .then(response => {
          this.draftProgramInfo = response.data;
          //@ts-ignore
          store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(
            this.draftProgramInfo
          );
          store.commit.applicantProgramModule.SET_APPLICANT_PROGRAM(
            this.draftProgramInfo.programInfo
          );
          if (this.steps > stepperIndex) {
            this.$emit("save-completed", buttonName);
          } else if (
            //@ts-ignore
            this.isSingleFlow()
          ) {
            let nextProgram = "";
            //@ts-ignore
            nextProgram = this.nextProgram(
              "FTP",
              this.applicantProgramInfo.transitAgencyCode
            );
            if (nextProgram === "DOCS" || nextProgram === "REVIEW") {
              router.push({
                path: "/application-program/" + nextProgram,
                query: {
                  profileID: String(
                    this.applicantProgramInfo.applicantProfileId
                  ),
                  prgmType: nextProgram
                }
              });
            } else {
              router.push({
                path: "/application-program/" + nextProgram,
                query: {
                  profileID: String(
                    this.applicantProgramInfo.applicantProfileId
                  ),
                  prgmType: nextProgram,
                  agencyType: this.applicantProgramInfo.transitAgencyCode
                }
              });
            }
          } else {
            router.push({
              path:
                "/application-review/" +
                this.applicantProgramInfo.programTypeCode
            });
          }
        })
        //@ts-ignore
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    },
    changeStepCount(steps: number) {
      this.steps = steps;
      this.$emit("step-count", steps);
    },
    changeDisableSave() {
      this.disableSave = true;
      if (
        (this.draftProgramInfo.ageVerificationMethodCode === "Y" &&
          this.draftProgramInfo.driverLicenseVerifyCode === "VRFD") ||
        this.draftProgramInfo.ageVerificationMethodCode === "N"
      ) {
        this.disableSave = false;
      }
    }
  },
  computed: {
    applicantProgramInfo: {
      get(): IProgramsList {
        //@ts-ignore
        return store.getters.applicantProgramModule.getApplicantProgram;
      }
    },
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        //@ts-ignore
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      },
      set(newValue: IDraftProgramInfo) {
        //@ts-ignore
        store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(newValue);
      }
    },
    applicantProfile: {
      get(): IApplicantProfile {
        //@ts-ignore
        return store.getters.applicantProfileModule.getApplicantProfile;
      }
    },
    ftpDetailInfo(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.FTP_SEC2].largeText,
          CNST_TEXT.THE_APPLICANTS,
          CNST_TEXT.THEY,
          CNST_TEXT.THEIR,
          CNST_TEXT.THEY
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.FTP_SEC2].largeText,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOU,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOU
        );
      }
      return text;
    }
  },
  created() {
    if (
      //@ts-ignore
      this.isSingleFlow()
    ) {
      this.singleFlowFinalStep = true;
    }
    this.changeDisableSave();
  }
});
