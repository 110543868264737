<template>
  <div style="width: 100%">
    <v-divider v-if="index === 0 && showDivider" aria-hidden="true"></v-divider>
    <v-divider
      v-if="index > 0"
      :key="'d' + index"
      aria-hidden="true"
    ></v-divider>

    <v-list-item
      class="docRowListItem px-0"
      :key="isEcs ? doc.sequenceNumber : doc.externalKey"
      @click.stop
      :ripple="false"
    >
      <v-list-item-content>
        <v-container pa-0 fluid>
          <!-- Application Document -->
          <v-row no-wrap v-if="!hideDocDescription">
            <v-col cols="12" sm="12" md="9" lg="9" xl="9">
              <v-row wrap>
                <v-col class="d-flex">
                  <v-tooltip bottom color="info">
                    <template v-slot:activator="{ on, attrs }">
                      <a
                        @click="downloadDocument()"
                        class="downloadLink text-truncate"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ documentName }}
                      </a>
                    </template>
                    <span>{{ documentName }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-col>

            <v-col
              v-if="!hideDeleteOptn && !isDownloading"
              cols="12"
              sm="12"
              md="3"
              lg="3"
              xl="3"
            >
              <v-dialog eager v-model="dialog" persistent width="400">
                <template v-slot:activator="{ on }">
                  <v-btn
                    text
                    v-on="on"
                    aria-label="Delete Uploaded Document"
                    class="text-capitalize px-0"
                  >
                    <v-icon color="primary">delete fa-trash</v-icon>
                    <span style="text-transform: capitalize;" class="ml-1"
                      >Delete</span
                    >
                  </v-btn>
                </template>
                <v-card>
                  <v-card-text class="pa-4" flat>
                    <span class="font-weight-bold">
                      Are you sure you want to delete this document?
                    </span>
                  </v-card-text>
                  <v-divider aria-hidden="true"></v-divider>
                  <v-card-actions class="justify-center">
                    <v-btn tile class="secondaryButton" @click="dialog = false"
                      >No</v-btn
                    >
                    <v-btn tile class="primaryButton" @click="deleteDocument()"
                      >Yes</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col
              v-if="!hideDeleteOptn && isDownloading"
              cols="12"
              sm="12"
              md="3"
              lg="3"
              xl="3"
            >
              <v-progress-circular
                indeterminate
                v-if="isDownloading"
                color="primary"
                class="downloadingIcon"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-list-item-content>
    </v-list-item>
    <v-progress-linear
      pa-0
      indeterminate
      v-if="isUpdating"
      class="docUpdateProgessbar"
    />
  </div>
</template>

<script>
import FieldEnrtyMixin from "@/mixins/fieldEntryMixin.vue";
import axios from "axios";
import { LOGGER } from "@/util/logger";
import { ECS_ENDPOINT } from "@/constants/EndPoints";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";

export default {
  name: "DocumentRow",
  components: {},
  mixins: [FieldEnrtyMixin, UIFeatureMixinVue],
  data() {
    return {
      dialog: false,
      localDoc: JSON.parse(JSON.stringify(this.doc)),
      isUpdating: false,
      thumbnail: "",
      isLoadingThumbnail: true,
      isImage: false,
      isDownloading: false
    };
  },
  props: {
    doc: { type: Object, default: null },
    showHeaders: { type: Boolean, default: false },
    index: { type: Number, default: 0 },
    isEcs: { type: Boolean, default: false },
    hideDocDescription: { type: Boolean, default: false },
    docDescCodes: {
      type: Array,
      default: null
    },
    documentTypeCode: {
      type: String,
      default: null
    },
    hideDeleteOptn: { type: Boolean, default: false },
    showDivider: { type: Boolean, default: true }
  },
  computed: {
    docType: {
      get() {
        return this.localDoc.documentTypeCode;
      },
      set(newVal) {
        this.localDoc.documentTypeCode = newVal;
      }
    },
    ecsDocType: {
      get() {
        return this.localDoc.typeCode;
      },
      set(newVal) {
        this.localDoc.typeCode = newVal;
      }
    },
    docDescription: {
      get() {
        if (this.localDoc.description) {
          //@ts-ignore
          return this.uiFeature[this.localDoc.description].displayName;
        } else return null;
      },
      set(newVal) {
        this.localDoc.description = newVal;
      }
    },
    documentName: {
      get() {
        return this.localDoc.filename.trim();
      },
      set(newVal) {
        this.localDoc.filename = newVal;
      }
    },
    showHeader() {
      return this.showHeaders || this.$vuetify.breakpoint.smAndDown;
    }
  },
  mounted() {
    if (!this.isEcs) {
      if (this.doc.mimeType.trim() !== "pdf") {
        this.downloadThumbnail();
      }
    }
  },
  methods: {
    getDescText(array, value) {
      let desc;
      array.find(function(obj) {
        if (obj.value === value) {
          desc = obj.text;
        }
      });
      return desc;
    },
    getDocNumberFromKey(key) {
      return parseInt(key.charAt(key.length - 1));
    },
    downloadDocument() {
      this.isDownloading = true;
      axios
        .get(ECS_ENDPOINT.RETRIEVE_DOCUMENT, {
          params: {
            attachmentId: this.localDoc.attachmentId
          },
          responseType: "arraybuffer"
        })
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", this.localDoc.filename);
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch(error => {
          LOGGER.logException(error);
        })
        .finally(() => {
          this.isDownloading = false;
        });
    },
    deleteDocument() {
      let url = ECS_ENDPOINT.DELETE_DOCUMENT;

      axios
        .get(url, {
          params: {
            attachmentId: this.localDoc.attachmentId,
            applicantProfileId: this.localDoc.applicantProfileId
          }
        })
        .then(() => {
          this.$emit("removeDoc");
        })
        .catch(error => {
          LOGGER.logException(error);
        });

      this.dialog = false;
    },
    updateDocument() {
      this.isUpdating = true;
      let documentNumber = this.getDocNumberFromKey(this.doc.externalKey);
      // eslint-disable-next-line
      const url = `${DOCUMENT_ENDPOINT.DB2}${this.permitApplicationId}/${documentNumber}/${this.doc.version}`;

      let formData = new FormData();

      formData.append(
        "json",
        new Blob([JSON.stringify(this.localDoc)], {
          type: "application/json"
        })
      );

      axios
        .put(url, formData)
        .then(() => {
          this.doc.subType = this.docType;
          this.doc.description = this.docDescription;
        })
        .catch(error => {
          LOGGER.logException(error);
        })
        .finally(() => {
          this.isUpdating = false;
        });
    }
  },
  watch: {
    permitApplicationId(val) {
      if (val && !this.isEcs && this.doc.mimeType.trim() !== "pdf") {
        this.downloadThumbnail();
      }
    }
  }
};
</script>

<style scoped>
.docUpdateProgessbar {
  margin-top: 0;
  padding: 0px;
  top: -23px;
  margin-bottom: -7px;
}

.downloadingIcon {
  position: absolute;
  bottom: 26px;
  width: 15px !important;
}

.downloadLink:hover {
  text-decoration: underline;
}
</style>
