
import Vue from "vue";
import SSRGeneralInfoReview from "@/components/application/program/ssr/review/SSRGeneralInfoReview.vue";
import AttachmentsReview from "@/components/application/program/AttachmentsReview.vue";
import ApplicationReviewControls from "@/components/application/program/ApplicationReviewControls.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import { IDraftProgramInfo, IProgramsList } from "@/types";
import StepperNavigationMixinVue from "@/mixins/StepperNavigationMixin.vue";
import AttachmentsMixinVue from "@/mixins/AttachmentsMixin.vue";
import UpcomingApptReview from "@/components/application/program/UpcomingApptReview.vue";

export default Vue.extend({
  name: "Program-Review-SSR",
  mixins: [UIFeatureMixinVue, StepperNavigationMixinVue, AttachmentsMixinVue],
  components: {
    SSRGeneralInfoReview,
    AttachmentsReview,
    ApplicationReviewControls,
    UpcomingApptReview
  },
  props: {
    applicantProgramInfoObj: {
      type: Object as () => IProgramsList
    },
    draftProgramInfoObj: {
      type: Object as () => IDraftProgramInfo
    },
    showApplRevCtrls: { type: Boolean, default: false },
    program: { type: String, default: "" }
  },
  data: function() {
    return {
      singleFlow: false,
      documentTypeCodes: [] as string[]
    };
  },
  computed: {},
  created() {
    if (
      //@ts-ignore
      this.isSingleFlow()
    ) {
      this.singleFlow = true;
    } else {
      //@ts-ignore
      this.documentTypeCodes = this.getSSRDocumentTypes();
    }
  }
});
