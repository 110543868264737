<template>
  <v-container>
    <v-row>
      <v-col class=" pl-16 pr-16">
        <h6>
          Reviewer Attachments
        </h6>
        <v-card outlined class="borderRadius pa-6" width="100%">
          <v-row wrap>
            <v-col cols="12">
              <p class="mb-0">Uploaded Documents:</p>
              <div>
                <v-list
                  three-line
                  :class="
                    $vuetify.breakpoint.smAndDown
                      ? 'documentList'
                      : 'documentListWide'
                  "
                >
                  <v-list-item v-if="docs.length < 1">
                    <v-list-item-content>
                      <v-list-item-title class="text-xs-center"
                        ><span
                          >No Reviewer documents are attached to the
                          program</span
                        ></v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <template v-for="(doc, index) in docs">
                    <ReviewerDocumentRow
                      :key="doc.attachmentId"
                      :doc="doc"
                      :showHeaders="index === 0"
                      :index="index"
                      :isEcs="true"
                      @removeDoc="removeDoc(index)"
                    />
                  </template>
                </v-list>
                <v-progress-linear v-if="isDocListLoadding" indeterminate />
              </div>
            </v-col>
          </v-row>
        </v-card> </v-col
    ></v-row>
    <ProgressBar :dialog="showProgressBar" />
  </v-container>
</template>
<script>
import Vue from "vue";
import store from "@/store";
import ReviewerDocumentRow from "@/components/common/ReviewerDocumentRow";
import ProgressBar from "@/components/common/ProgressBar.vue";

export default Vue.extend({
  name: "ReviewerAttachmentsSummary",
  components: {
    ProgressBar,
    ReviewerDocumentRow
  },
  mixins: [],
  data() {
    return {
      showProgressBar: false
    };
  },
  computed: {
    docs: {
      get() {
        //@ts-ignore
        return store.getters.attachmentsModule.getReviewerDocs;
      },
      set(newValue) {
        //@ts-ignore
        store.commit.attachmentsModule.SET_REVR_DOC_LIST(newValue);
      }
    },
    isDocListLoadding() {
      return store.getters.attachmentsModule.getDocListLoadingStatus;
    },
    applicantProgramInfo: {
      get() {
        //@ts-ignore
        return store.getters.applicantProgramModule.getApplicantProgram;
      }
    },
    applicationReviewModel: {
      get() {
        //@ts-ignore
        return store.getters.applicationReviewModule.getApplicationReviewModel;
      }
    }
  },
  methods: {
    loadReviewerDocs() {
      let payload = {
        applicationReviewId: this.applicationReviewModel.applicationReviewId,
        transitAgencyCode: this.applicantProgramInfo.transitAgencyCode
      };
      store.dispatch.attachmentsModule.getReviewerDocsByReviewIdAndAgency(
        payload
      );
    }
  },
  created() {
    this.loadReviewerDocs();
  }
});
</script>

<style>
div.documentList > div > div {
  height: 135px;
}
div.documentList > div > div > a {
  height: 131px !important;
}
div.documentListWide > div > div {
  height: 75px;
}
div.documentListWide > div > div > a {
  height: 80px !important;
}
</style>
<style scoped>
.borderRadius {
  border: 2px solid;
}
</style>
