var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.showReviewCard),expression:"showReviewCard"}],staticClass:"pa-5",attrs:{"outlined":"","elevation":"2","color":"info"}},[_c('ProgressBar',{attrs:{"dialog":_vm.showProgressBar}}),(
      !_vm.applicationReviewModel ||
        _vm.applicantProgramInfo.statusCode === 'TMP_APRVD' ||
        _vm.applicantProgramInfo.statusCode === 'PENDING' ||
        (_vm.applicationReviewModel.reviewerUserId === _vm.userInfo.userId &&
          _vm.applicationReviewModel.reviewResultCode === null)
    )?_c('v-card-title',{staticClass:"pa-1"},[_vm._v(" This application is ready to be reviewed.")]):_vm._e(),_c('v-card-title',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.applicationReviewModel &&
        _vm.applicationReviewModel.reviewerUserId !== _vm.userInfo.userId &&
        this.applicantProgramInfo.statusCode !== 'TMP_APRVD' &&
        this.applicantProgramInfo.statusCode !== 'PENDING' &&
        this.applicantProgramInfo.statusCode !== 'PEND_CONF'
    ),expression:"\n      applicationReviewModel &&\n        applicationReviewModel.reviewerUserId !== userInfo.userId &&\n        this.applicantProgramInfo.statusCode !== 'TMP_APRVD' &&\n        this.applicantProgramInfo.statusCode !== 'PENDING' &&\n        this.applicantProgramInfo.statusCode !== 'PEND_CONF'\n    "}],staticClass:"pa-1"},[_vm._v(" This application is currently being reviewed by:")]),_c('v-card-title',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.applicationReviewModel &&
        _vm.applicationReviewModel.reviewerUserId !== _vm.userInfo.userId &&
        _vm.applicationReviewModel.actReviewFlag === 'N' &&
        this.applicantProgramInfo.statusCode === 'PEND_CONF'
    ),expression:"\n      applicationReviewModel &&\n        applicationReviewModel.reviewerUserId !== userInfo.userId &&\n        applicationReviewModel.actReviewFlag === 'N' &&\n        this.applicantProgramInfo.statusCode === 'PEND_CONF'\n    "}],staticClass:"pa-1"},[_vm._v(" This application is currently being configured by:")]),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.applicationReviewModel &&
        _vm.applicationReviewModel.reviewerUserId !== _vm.userInfo.userId
    ),expression:"\n      applicationReviewModel &&\n        applicationReviewModel.reviewerUserId !== userInfo.userId\n    "}]},[(_vm.iisamUserAttributes && _vm.iisamUserAttributes.attributeDetails)?_c('v-col',[_c('span',{staticClass:"font-weight-bold word-wrap"},[_vm._v(_vm._s(_vm.iisamUserAttributes.attributeDetails["FIRST_NAME"]))]),_vm._v("  "),_c('span',{staticClass:"font-weight-bold word-wrap"},[_vm._v(_vm._s(_vm.iisamUserAttributes.attributeDetails["LAST_NAME"]))])]):_vm._e()],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.numAttachmentsAssistanceReq > 0),expression:"numAttachmentsAssistanceReq > 0"}]},[_c('v-col',[_c('InfoSection',{attrs:{"header":_vm.displayName('STRT_HELP'),"body":_vm.largeText('STRT_HELP')}})],1)],1),_c('v-card-text',{staticClass:"px-2"},[_c('v-card-actions',[(
          this.applicantProgramInfo.statusCode === 'SBMTD' ||
            this.applicantProgramInfo.statusCode === 'TMP_APRVD' ||
            this.applicantProgramInfo.statusCode === 'PENDING'
        )?_c('v-btn',{staticClass:"primaryButton",attrs:{"tile":""},on:{"click":function($event){return _vm.startReview()}}},[_vm._v("START REVIEW")]):_vm._e(),(
          _vm.applicationReviewModel &&
            _vm.applicationReviewModel.reviewerUserId !== _vm.userInfo.userId &&
            (_vm.isAgencySupervisor || _vm.isReviewerSelfAsgndUser) &&
            this.applicantProgramInfo.statusCode !== 'SBMTD' &&
            this.applicantProgramInfo.statusCode !== 'TMP_APRVD' &&
            this.applicantProgramInfo.statusCode !== 'PENDING' &&
            this.applicantProgramInfo.statusCode !== 'PEND_CONF' &&
            (!_vm.reviewUpdatedRecently(_vm.applicationReviewModel.updatedTs) ||
              _vm.applicationReviewModel.actReviewFlag === 'N')
        )?_c('v-btn',{staticClass:"primaryButton",attrs:{"tile":""},on:{"click":function($event){return _vm.continueReview()}}},[_vm._v("CONTINUE REVIEW")]):_vm._e(),(
          _vm.applicationReviewModel &&
            _vm.applicationReviewModel.reviewerUserId !== _vm.userInfo.userId &&
            this.applicantProgramInfo.statusCode === 'PEND_CONF' &&
            (!_vm.reviewUpdatedRecently(_vm.applicationReviewModel.updatedTs) ||
              _vm.applicationReviewModel.actReviewFlag === 'N')
        )?_c('v-btn',{staticClass:"primaryButton",attrs:{"tile":""},on:{"click":function($event){return _vm.continueReview()}}},[_vm._v("CONTINUE CONFIGURATION")]):_vm._e(),(
          !_vm.applicationReviewModel ||
            _vm.applicationReviewModel.actReviewFlag === 'N'
        )?_c('v-btn',{staticClass:"ml-2 secondaryButton",attrs:{"tile":""},on:{"click":function($event){return _vm.GotoDashboard()}}},[_vm._v("Cancel")]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }