
import Vue from "vue";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import axios from "axios";
import { ECS_ENDPOINT } from "@/constants/EndPoints";
import { HTTP_STATUS } from "@/constants/HttpStatusCode";
export default Vue.extend({
  mixins: [fieldEntryMixin],
  data: () => ({
    valid: true,
    dialog: false,
    files: [],
    fileDesc: "" as string,
    showSnackbar: false,
    snackBarText: "INFO",
    snackBarColor: "info"
  }),
  methods: {
    resetFileUploadData() {
      this.files = [];
      this.fileDesc = "";
      this.dialog = false;
      //@ts-ignore
      this.$refs.fileUploadForm.resetValidation();
    },
    save() {
      if (!Array.isArray(this.files) || !this.files.length) {
        this.snackBarText = "Attachments are required.";
        this.showSnackbar = true;
        this.snackBarColor = "error";
      } else {
        let formData = new FormData();
        for (let file of this.files) {
          formData.append("files", file);
        }
        formData.append("fileDesc", this.fileDesc);

        axios
          .post(ECS_ENDPOINT.UPLOAD_MULTIPLE, formData)
          .then(response => {
            if (response.status === HTTP_STATUS.OK) {
              this.snackBarText = "UPLOAD SUCCESSFUL";
              this.showSnackbar = true;
              this.snackBarColor = "info";
            } else {
              this.snackBarText = response.status + ": " + response.statusText;
              this.showSnackbar = true;
              this.snackBarColor = "info";
            }
          })
          .catch(error => {
            this.snackBarText =
              error.response.status + ": " + error.response.statusText;
            this.showSnackbar = true;
            this.snackBarColor = "error";
          })
          .finally(() => {
            //@ts-ignore
            this.resetFileUploadData();
          });
      }
    }
  }
});
