
import Vue from "vue";
import store from "@/store";
import PWDDetailInfo from "@/components/application/program/pwd/PWDDetailInfo.vue";
import RFTPProgramInfo from "@/components/application/program/rftp/RFTPProgramInfo.vue";
import PhysicianList from "@/components/application/PhysicianList.vue";
import {
  IApplicantProfile,
  IDraftFixedRouteUsage,
  IDraftProgramInfo,
  IProgramsList
} from "@/types";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import tesProgramTemplate from "@/components/common/TESProgramTemplate.vue";
import Attachments from "@/components/application/program/Attachments.vue";
import router from "@/router";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import StepperNavigationMixinVue from "@/mixins/StepperNavigationMixin.vue";
import { BUTTON_NAMES, CNST_TEXT } from "@/constants/CommonConstants";
import routerMixin from "@/mixins/RouterMixin.vue";
import AttachmentsMixinVue from "@/mixins/AttachmentsMixin.vue";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";
import { GPC } from "@/constants/GPCCode";

// Constants used to build the screen display logics
const RFTP_BASE = "RFTPBASE";
const PWD_INFO = "PWD";
const PHYS_INFO = "PHYSINFO";
const ATTACHMENTS = "ATTACHMENTS";
const ATTACH_DISABILITY1 = "ATTACHDISABILITY1";
const ATTACH_DISABILITY2 = "ATTACHDISABILITY2";
const ATTACH_MCIA = "ATTACHMCIA";
const ATTACH_DISABILITY3 = "ATTACHDISABILITY3";
const ATTACH_VISF = "ATTACHVISF";

export default Vue.extend({
  name: "program-rftp",
  mixins: [
    fieldEntryMixin,
    UIFeatureMixinVue,
    StepperNavigationMixinVue,
    routerMixin,
    AttachmentsMixinVue,
    ApplicationMixin
  ],
  components: {
    tesProgramTemplate,
    RFTPProgramInfo,
    PWDDetailInfo,
    Attachments,
    PhysicianList
  },
  data: function() {
    return {
      stepCount: 4 as number,
      customDisability: false,
      docText: "" as string,
      headerMsg: "" as string,
      displayedSections: [] as string[],
      attachmentTypes: [] as string[],
      visualDocTypeCodeArray: [] as string[],
      RFTP_BASE,
      PWD_INFO,
      PHYS_INFO,
      ATTACHMENTS,
      ATTACH_DISABILITY1,
      ATTACH_DISABILITY2,
      ATTACH_DISABILITY3,
      ATTACH_MCIA,
      ATTACH_VISF,
      stepNum: 0
    };
  },
  props: {
    agencyName: { type: String, default: "" }
  },
  methods: {
    isFixedRouteAnswerNo(): boolean {
      return this.draftFixedRouteUsage.every(
        (item: IDraftFixedRouteUsage) => item.fixedRouteDisabilityInd !== "Y"
      );
    },
    moveFocusToNextStep() {
      const elemId = `step${this.stepNum}Content`;
      const elem = document.getElementById(elemId);
      if (elem) {
        elem.focus();
      }
    },
    saveCompleted(buttonName: string) {
      if (buttonName === "CANCEL") {
        this.continueToDashboard();
      }
      //@ts-ignore
      else if (this.stepNum !== this.stepCount) {
        this.buildHeader();
        //@ts-ignore
        this.$refs.programRFTPTemplate.nextStep();
        this.moveFocusToNextStep();
        //@ts-ignore
        this.scrollToSectionHeader(this.$refs.programRFTPTemplate);
      } else if (
        //@ts-ignore
        this.isSingleFlow()
      ) {
        if (
          buttonName === BUTTON_NAMES.SAVECONTINUE ||
          buttonName === BUTTON_NAMES.CONTINUE
        ) {
          //@ts-ignore
          this.headerMsg = this.buildHeaderForAgencyPending("RFTP");
          let nextProgram = "";
          //@ts-ignore
          nextProgram = this.nextProgram(
            "RFTP",
            this.applicantProgramInfo.transitAgencyCode
          );
          if (nextProgram === "DOCS" || nextProgram === "REVIEW") {
            router.push({
              path: "/application-program/" + nextProgram,
              query: {
                profileID: String(this.applicantProgramInfo.applicantProfileId),
                prgmType: nextProgram
              }
            });
          } else {
            router.push({
              path: "/application-program/" + nextProgram,
              query: {
                profileID: String(this.applicantProgramInfo.applicantProfileId),
                prgmType: nextProgram,
                agencyType: this.applicantProgramInfo.transitAgencyCode
              }
            });
          }
        } else {
          //@ts-ignore
          this.headerMsg = this.buildHeaderForAgencyPending("RFTP");
        }
      } else {
        this.buildHeader();
        router.push({
          path:
            "/application-review/" + this.applicantProgramInfo.programTypeCode
        });
      }
    },
    changeStepCount() {
      this.createDisplayMap();
      this.stepCount = this.displayedSections.length;
    },
    changeStep(step: number) {
      this.stepNum = step;
    },
    createDisplayMap() {
      this.displayedSections.splice(0, this.displayedSections.length);
      // Build a model of the sections to display -- Order matters here!
      // ******* RFTP Section Base (always shown) *******
      this.displayedSections.push(RFTP_BASE);
      if (this.draftProgramInfo.rftpApplyInd !== "Y") {
        return;
      }

      // ******* PWD/RFTP Additional Info *******
      this.displayDetailInfo();

      // ******* Physician Info (SEPTA) *******
      if (
        (this.draftProgramInfo.rftpProviderAgencyCode === "SEPTA" ||
          this.applicantProgramInfo.transitAgencyCode === "SEPTA") &&
        this.draftProgramInfo.rftpApplyInd === "Y" &&
        this.draftProgramInfo.disabilityInd === "Y" &&
        // @ts-ignore
        (!this.isSingleFlow() ||
          // @ts-ignore
          !this.isProgramExist("ADA") ||
          !this.draftProgramInfo.draftPhysicianInfos ||
          this.draftProgramInfo.draftPhysicianInfos.length < 1)
      ) {
        this.displayedSections.push(PHYS_INFO);
      }

      // ******* Document Attachments *******
      // @ts-ignore
      if (!this.isSingleFlow()) {
        if (
          !this.customDisability &&
          this.draftProgramInfo.disabilityInd === "Y" &&
          this.applicantProgramInfo.transitAgencyCode != "SEPTA"
        ) {
          this.pushAttachmentsSection();
          this.attachmentTypes.push(ATTACH_DISABILITY1);
        } else if (
          this.customDisability &&
          this.draftProgramInfo.disabilityInd === "Y" &&
          this.applicantProgramInfo.transitAgencyCode != "SEPTA"
        ) {
          this.pushAttachmentsSection();
          this.attachmentTypes.push(ATTACH_DISABILITY2);
        }
        if (this.draftProgramInfo.medicareCardInd === "Y") {
          this.pushAttachmentsSection();
          this.attachmentTypes.push(ATTACH_MCIA);
        }
        this.pushATTACHDISABILITY3();
        this.pushATTACHVISF();
      }
    },
    displayDetailInfo() {
      if (
        // @ts-ignore
        !this.isProgramExist("PWD") &&
        // @ts-ignore
        (!this.isProgramExist("ADA") || this.isFixedRouteAnswerNo()) &&
        store.getters.draftProgramInfoModule.getDraftProgramInfo
          .disabilityInd !== "N"
      ) {
        this.displayedSections.push(PWD_INFO);
      }
    },
    pushATTACHDISABILITY3() {
      if (
        // @ts-ignore
        this.showRFTPForm &&
        this.applicantProgramInfo.transitAgencyCode === "SEPTA"
      ) {
        this.pushAttachmentsSection();
        this.attachmentTypes.push(ATTACH_DISABILITY3);
      }
    },
    pushATTACHVISF() {
      if (
        // @ts-ignore
        this.showVisionForm &&
        this.applicantProgramInfo.transitAgencyCode === "SEPTA"
      ) {
        this.pushAttachmentsSection();
        this.attachmentTypes.push(ATTACH_VISF);
      }
    },
    popATTACHVISF() {
      if (
        // @ts-ignore
        !this.showVisionForm &&
        this.applicantProgramInfo.transitAgencyCode === "SEPTA"
      ) {
        let index = this.attachmentTypes.findIndex(x => x === "ATTACHVISF");
        if (index != -1) {
          this.attachmentTypes.splice(index, 1);
        }
      }
    },
    pushAttachmentsSection() {
      let hasIncluded = this.displayedSections.filter(
        section => section === "ATTACHMENTS"
      );
      if (hasIncluded.length === 0) {
        this.displayedSections.push(ATTACHMENTS);
      }
    },
    getSectionTitle(index: number): string {
      let val = "";
      if (index - 1 < this.displayedSections.length) {
        const sect = this.displayedSections[index - 1];
        if (sect && sect == PWD_INFO) {
          val = "RFTP Detail Information";
        } else if (sect && sect == PHYS_INFO) {
          val = "Physician Information";
        } else if (sect && sect == ATTACHMENTS) {
          val = "Upload Attachments";
        }
      }
      return val;
    },
    continueToDashboard() {
      //@ts-ignore
      this.forwardToDashboard();
    },
    getRFTPDocumentTypes() {
      let documentTypeCodes = [];
      if (this.draftProgramInfo.medicareCardInd === "Y") {
        //@ts-ignore
        documentTypeCodes = this.uiFeature.DOCS_RFTP.largeText.split(",");
      }
      if (this.draftProgramInfo.disabilityInd === "Y") {
        if (this.customDisability) {
          //@ts-ignore
          documentTypeCodes = this.uiFeature.DOCS_PWD.displayName.split(",");
        } else {
          //@ts-ignore
          documentTypeCodes = this.uiFeature.DOCS_PWD.largeText.split(",");
        }
      }
      if (
        // @ts-ignore
        this.showRFTPForm &&
        this.applicantProgramInfo.transitAgencyCode === "SEPTA"
      ) {
        documentTypeCodes.push("RFTP_CST_D");
      }
      return documentTypeCodes;
    },
    isCustomDisabilityReq() {
      if (
        //@ts-ignore
        this.isCustomDisabilityAgency() ||
        //@ts-ignore
        this.isCustomDisabilityAgencyPWD()
      ) {
        this.customDisability = true;
        this.populateDocTextForCustomDisability();
      }
    },
    populateDocTextForCustomDisability() {
      if (this.applicantProgramInfo.transitAgencyCode === "FREEDOM") {
        //@ts-ignore
        this.docText = this.getCodDocDynamicText(
          //@ts-ignore
          this.uiFeature.FRDMCODTXT.largeText
        );
      } else {
        let abcObj = this.docGPCCodes.find(
          obj =>
            //@ts-ignore
            obj.resourceCode ===
            this.applicantProgramInfo.transitAgencyCode + "_COD"
        );
        //@ts-ignore
        this.docText = this.getCodDocDynamicText(abcObj.largeText);
      }
    },
    buildHeader() {
      if (this.applicantProgramInfo.transitAgencyCode === "AGY_PEND") {
        this.headerMsg =
          "Application for Reduced Fare Transit Program " +
          this.applicantProgramInfo.transiAgencyName;
      } else {
        this.headerMsg =
          "Application for " +
          this.applicantProgramInfo.programName +
          " with " +
          this.applicantProgramInfo.transiAgencyName;
      }
    },
    getUploadedDocList() {
      let payload = {
        applicantProfileId: this.applicantProgramInfo.applicantProfileId
      };
      store.dispatch.attachmentsModule.getUploadedDocList(payload);
      store.dispatch.attachmentsModule.getAtchmtChkBoxSltdByProfileId(
        this.applicantProgramInfo.applicantProfileId
      );

      //@ts-ignore
      if (this.showVisionForm) {
        //@ts-ignore
        this.compareAllDocsAttached();
      }
    }
  },
  computed: {
    draftFixedRouteUsage(): IDraftFixedRouteUsage[] {
      return this.draftProgramInfo.draftFixedRouteUsageList;
    },
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        //@ts-ignore
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      },
      set(newValue: IDraftProgramInfo) {
        //@ts-ignore
        store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(newValue);
      }
    },
    applicantProgramInfo: {
      get(): IProgramsList {
        //@ts-ignore
        return store.getters.applicantProgramModule.getApplicantProgram;
      }
    },
    docDescCodes(): any {
      //@ts-ignore
      return this.uiFeature.PRF_DSBLTY.children;
    },
    docGPCCodes(): [] {
      //@ts-ignore
      return this.uiFeature.AG_ATT_TXT.children;
    },
    applicantProfile(): IApplicantProfile {
      return store.getters.applicantProfileModule.getApplicantProfile;
    },
    prfDsbltyInfoOne(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBLTY].largeText,
          CNST_TEXT.THE_APPLICANT_HAS,
          CNST_TEXT.THE_APPLICANT,
          CNST_TEXT.THE
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBLTY].largeText,
          CNST_TEXT.YOU_HAVE,
          CNST_TEXT.YOU,
          CNST_TEXT.YOUR
        );
      }
      return text;
    },
    prfDsbltyInfoHeaderTwo(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_2].displayName,
          CNST_TEXT.THE
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_2].displayName,
          CNST_TEXT.MY
        );
      }
      return text;
    },
    prfDsbltyInfoTwo(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_2].largeText,
          CNST_TEXT.THE
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_2].largeText,
          CNST_TEXT.YOUR
        );
      }
      return text;
    },
    prfDsbltyInfoThree(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_3].largeText,
          CNST_TEXT.THE,
          CNST_TEXT.THE
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_3].largeText,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOUR
        );
      }
      return text;
    },
    codDocText(): string {
      //@ts-ignore
      return this.getCodDocDynamicText(
        //@ts-ignore
        this.uiFeature[GPC.COD_DOCTXT].largeText
      );
    },
    visFormInfo(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.VIS_FORM].largeText,
          CNST_TEXT.THE_APPLICANT_HAS,
          CNST_TEXT.THE
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.VIS_FORM].largeText,
          CNST_TEXT.YOU_HAVE,
          CNST_TEXT.YOUR
        );
      }
      return text;
    }
  },
  created() {
    if (this.applicantProgramInfo.transitAgencyCode === "AGY_PEND") {
      this.headerMsg =
        "Application for Reduced Fare Transit Program " +
        this.applicantProgramInfo.transiAgencyName;
    } else {
      this.headerMsg =
        "Application for " +
        this.applicantProgramInfo.programName +
        " with " +
        this.applicantProgramInfo.transiAgencyName;
    }

    this.changeStepCount();
    this.visualDocTypeCodeArray.push("VIS_FORM");

    //@ts-ignore
    if (!this.isSingleFlow()) {
      this.isCustomDisabilityReq();
      this.getRFTPDocumentTypes();
      this.getUploadedDocList();
    }
  },
  watch: {
    draftProgramInfo: {
      deep: true,
      handler(newVal: IDraftProgramInfo) {
        if (newVal.visualDisabilityInd === "Y") {
          this.pushATTACHVISF();
          this.getUploadedDocList();
        } else if (newVal.visualDisabilityInd === "N") {
          this.popATTACHVISF();
          this.getUploadedDocList();
        }
      }
    }
  }
});
