export const INVOICE_ENDPOINT = {
  GET_BY_DOC_NUM: "/tes-app/invoice/search-by-doc-num/{REPLACE_WITH_ID}",
  GET_BY_REF_NUM: "/tes-app/invoice/search-by-ref-num",
  CREATE_SUBMIT_HTML: "/tes-app/payment/v1/createSubmitHTML"
};

// tes-ui urls go here
export const UI_ENDPOINT = {
  GET_UI_FEATURES: "/tes-web/casa/uiFeatureMap",
  UI_REFRESH_UIFEATURE: "/tes-web/casa/refresh"
};

export const APP_DL_ENDPOINT = {
  GET_DETAILS_BY_DL_NUM: "/tes-app/dlNumber/getDLDetails",
  SAVE_DL_DETAILS: "/tes-app/dlNumber/saveApplicantDLDetails"
};

export const ECS_ENDPOINT = {
  UPLOAD_DOC: "/tes-ecs/uploadDoc",
  UPLOAD_MULTIPLE: "/tes-ecs/uploadMultiple",
  ECS_REFRESH_UIFEATURE: "/tes-ecs/refreshUIFeatureCache/refresh",
  GET_APP_SUMMARY_PDF: "/tes-ecs/docCreator/appSummaryPdf",
  UPLOAD_DOCUMENT: "/tes-ecs/uploadDocument",
  DELETE_DOCUMENT: "/tes-ecs/deleteDocument",
  RETRIEVE_DOCUMENT: "/tes-ecs/retrieveDocument",
  SAVE_NO_DOC_OPTION: "/tes-ecs/saveNoDocOption"
};

export const TES_WORKFLOW_ENDPOINT = {
  WF_REFRESH_UIFEATURE: "/tes-workflow/refreshUIFeatureCache/refresh"
};

export const TES_NOTIFICATION_ENDPOINT = {
  NOTIFICATION_REFRESH_UIFEATURE: "/tes-notifi/refreshUIFeatureCache/refresh"
};

export const DL_ENDPOINT = {
  GET_BY_DL_NUM: "/tes-license/dlicense/byDLNum",
  GET_DL_DETAILS: "/tes-license/dlicense/getDLDetails",
  DL_REFRESH_UIFEATURE: "/tes-license/refreshUIFeatureCache/refresh"
};

export const ECOLANE_ENDPOINT = {
  SAVE_ECOLANE_INFO: "/tes-ecolane/ecolaneProfile/saveEcolaneInfo",
  GET_ECOLANE_CUSTOMER_INFO_FROM_TES:
    "/tes-ecolane/ecolaneProfile/getEcolaneInfo",
  DELETE_ECOLANE_CUSTOMER_INFO_FROM_TES:
    "/tes-ecolane/ecolaneProfile/deleteEcolaneInfo",
  RETRIEVE_ECOLANE_FROM_ORIGINAL_ECOLANE_SERVICE:
    "/tes-ecolane/ecolane/getCustomers",
  ECOLANE_CREATE_CUSTOMER: "/tes-ecolane/ecolane/createCustomer",
  ECOLANE_UPDATE_CUSTOMER: "/tes-ecolane/ecolane/updateCustomer",
  ECOLANE_REFRESH_UIFEATURE: "/tes-ecolane/refreshUIFeatureCache/refresh",
  SAVE_STG_ECOLANE_INFO: "/tes-ecolane/ecolaneProfile/saveStgEcolaneInfo",
  GET_STG_ECOLANE_INFO: "/tes-ecolane/ecolaneProfile/getStgEcolaneInfo",
  DELETE_STG_ECOLANE_INFO: "/tes-ecolane/ecolaneProfile/deleteStgEcolaneInfo",
  GET_ECOLANE_DATA_UPDATE_LIST:
    "/tes-ecolane/ecolaneProfile/getEcolaneDataUpdateList",
  GET_ECOLANE_DATA_UPDATE_LIST_BY_ECOLANE_UPD_IND:
    "/tes-ecolane/ecolaneProfile/getEcolaneDataByEcolaneUpdInd",
  SAVE_ECOLANE_DATA_UPDATE: "/tes-ecolane/ecolaneProfile/saveEcolaneDataUpdate",
  DELETE_ECOLANE_DATA_UPDATE_BY_REV_ID:
    "/tes-ecolane/ecolaneProfile/deleteEcolaneDataUpdateByRevId"
};

export const REPORTS_ENDPOINT = {
  GET_EMBD_TOKEN: "/fmr-reports/getPowerBIReportsToken/getEmbdToken",
  GET_AGENCIES_REVIEW: "/tes-app/reportsData/getAgencyCodes",
  REPORTS_REFRESH_UIFEATURE: "/fmr-reports/refreshUIFeatureCache/refresh"
};

export const PROMISE_ENDPOINT = {
  PROMISE_REFRESH_UIFEATURE:
    "/fmr-promiseinterface/refreshUIFeatureCache/refresh",
  PROMISE_PASSWORD_CHANGE: "/fmr-promiseinterface/admin/change",
  PROMISE_ELIG_CALL: "/fmr-promiseinterface/soapAPI/checkEligByNameDobMAID"
};

export const GENERAl_INFO_ENDPOINT = {
  GET_PRGMS_BY_USER: "/tes-app/generalInfo/getProgramsSelected",
  SAVE_APPLICANT_ADDRESS: "/tes-app/generalInfo/saveApplicantAddress",
  GET_SCREENING_RESPONSE: "/tes-app/generalInfo/getScreeningResponse",
  GET_GIS_COUNTIES: "/tes-app/generalInfo/getGISCounties"
};

// tes-app urls go here
export const TES_APP_ENDPOINT = {
  SAVE_PERSONAL_INFO: "/tes-app/appl/generalInfo/savePersonalInfo",
  SAVE_DEMOGRPHIC_INFO: "/tes-app/generalInfo/saveDemographicInfo",
  SAVE_MOBILITY_INFO: "/tes-app/generalInfo/saveMobilityInfo",
  SAVE_DRAFT_PRGM_INFO: "/tes-app/program/saveDraftProgramInfo",
  GET_DOCUMENT_LIST: "/tes-app/appl/getDocumentList",
  GET_NO_DOC_CHECK_BOX_BY_DOC_TYPE_CODE_LIST:
    "/tes-app/appl/getNoDocumentCheckBoxByDocTypeList",
  GET_DOCS_LIST_SUBMITTED_ASSISTANCE_REQUIRED:
    "/tes-app/appl/getDocsSubbmittedAssistanceRequired",
  GET_DOC_LIST_BY_PROFILEID: "/tes-app/appl/getDocListByProfileId",
  GET_EVENT_HISTORY: "/tes-app/program/getEventHistory",
  SUBMIT_APPLICATION: "/tes-app/submitAppl/submitApplication",
  GET_COMPLETE_PROFILE_DETAILS_BY_APP_ID: "/tes-app/searchAppl/getApplByApplId",
  GET_PROFILE_DETAILS_BY_APP_ID_AGENCY:
    "/tes-app/searchAppl/quickSreach/getApplByApplId",
  PERFORM_SEARCH: "/tes-app/advancedSearch/performSearch",
  GET_HOME_MAIN_TEXT: "/tes-app/ui/homeMain",
  GET_SCREENING_QUES: "/tes-app/public/scrnQues/getScreeningQues",
  GET_SCREENING_QUES_BY_SCRN_RESP_ID:
    "/tes-app/scrnQues/getScrnQuesByScrnRespID",
  GET_SCREENING_QUES_PRGMS_BY_CNTY:
    "/tes-app/public/scrnQues/getProgramsByCounty",
  GET_COUNTIES: "/tes-app/public/counties/getCounties",
  GET_FIXED_ROUTE_COUNTIES: "/tes-app/public/counties/getFixedRouteCounties",
  GET_FILTERED_COUNTIES: "/tes-app/public/counties/getFilteredCounties",
  GET_AGENCY_COUNTIES: "/tes-app/public/counties/getAgencyCounties",
  GET_ELIGIBLE_PROGRAMS: "/tes-app/public/eligiblePrograms",
  GET_ELIGIBLE_PRGMS_W_SCRN_RESP_ID: "/tes-app/eligibleProgramsByScrnRespID",
  START_APPLICATION: "/tes-app/startApplication",
  GET_APPL_PRGM_ELIGIBILITIES: "/tes-app/getApplPrgmEligibilities",
  GET_TRANSIT_AGENCY_PROVIDERS: "/tes-app/program/transitAgencyProviders",
  GET_DRAFT_PROGRAM_INFO: "/tes-app/program/draftProgramInfoByProfileId",
  GET_TRANSIT_AGENCY_SRV_NAME: "/tes-app/program/getProviderAgencyName",
  GET_TRANSIT_AGENCY_PROVIDERS_IN_COUNTIES:
    "/tes-app/program/transitAgencyProvidersInCounties",
  SAVE_EDIT_PROFILE_INFO: "/tes-app/generalInfo/saveEditedProfileInfo",
  DELETE_SCRNG_RSP: "/tes-app/deleteScreeningResponse",
  UPDATE_APPL_PROFILE_ID: "/tes-app/appl/updateApplProfileId",
  APP_REFRESH_UIFEATURE: "/tes-app/refreshUIFeatureCache/refresh",
  USER_LOGOUT: "/tes-app/public/logout",
  USER_LOGIN_VERIFY: "/tes-app/public/loginValidation",

  GET_COMPLETE_PROFILE_DETAILS_BY_APP_PROFILE_ID:
    "/tes-app/advancedSearch/getProgramSummaryBeforeSubmit",
  START_APPL_BEFORE_LOGIN: "/tes-app/public/startApplBeforeLogin",
  GENERATE_PDF: "/tes-app/createPDF/generatePDF",
  GET_USERINFO_BY_ID: "/tes-app/appl/getUserInfoById",
  ISAM_GET_USERATTRIBUTES: "/tes-app/appl/isam/getUserAttributeValues",
  ISAM_GET_USERATTRIBUTES_BYID: "/tes-app/appl/isam/getUserAttributeById",
  GET_WITHDRAW_APPLICATIONS: "/tes-app/appl/getWithdrawApplications",
  START_REVIEW: "/tes-app/appl/startReview",
  UPDATE_ELIGBLTY: "/tes-app/appl/updateEligibility",
  GET_DOCLIST_BY_APPLREVIEWID: "/tes-app/appl/getDocListByApplicationReviewId",
  GET_APPLREVIEW_MODEL_BY_APPLID:
    "/tes-app/appl/getApplicationReviewModelByApplicationId",
  GET_PRIOR_APPLREVIEW_MODEL_BY_APPLID: "/tes-app/appl/getPriorAppReviewModel",
  GET_REVIEW_REASONS_BY_RESULTCODE:
    "/tes-app/appl/getReviewResultReasonsByReviewResultCode",
  UPDATE_REVIEW: "/tes-app/appl/updateReview",
  UPDATE_REVIEW_FLAG: "/tes-app/appl/updateReviewFlag",
  UPDATE_ECOLANE_CONFIG: "/tes-app/appl/updateEcolaneConfig",
  UPDATE_ECOLANE_NOMATCH_IND: "/tes-app/appl/updateEcolaneNoMatchInd",
  EDIT_APPLICATION: "/tes-app/editAppl/editApplication",
  SAVE_REVIEW_RECOMMENDATION: "/tes-app/appl/saveReviewRecommendation",
  GET_SCRNG_RSP: "/tes-app/getScrnRespByApplProfileID",
  COMPLETE_REVIEW: "/tes-app/appl/completeReview",
  WITHDRAW_APPLICATION: "/tes-app/appl/withdrawApplication",

  GET_APPLICANT_ECOLANE_PROFILE: "/tes-app/appl/getApplicantEcolaneProfile",
  GET_REVIEWER_DOCS_BY_REVIEWID_AND_AGENCY:
    "/tes-app/appl/getReviewerDocsByReviewIdAndAgency",
  GET_APPLCNT_DOCS_BY_PROFILE_ID: "/tes-app/appl/getApplicantDocsByProfileId",
  GET_CONTACTUS_AGENCIES_DETAILS_BY_CNTY:
    "/tes-app/public/contactUs/getContactUsBySelCounty",
  GET_REVWR_DOCS_BY_REVIEWID_AGENCY_DOCTYPE:
    "/tes-app/appl/getReviewerDocsByAgencyAndDocType",
  GET_PROGRAMS_BY_COUNTY: "/tes-app/public/getAllProgramsByCounty",
  GET_DOCS_IN_DOCUMENT_SUBMISSION: "/tes-app/appl/getDocsInDocumentSubmission",
  GET_DOCS_ATCHMT_CHKBOX_SELTD_LIST:
    "/tes-app/appl/getDocsAtchmtChkBoxSltdList",
  GET_CANNED_COMMENTS_BY_TRANSIT_AGENCY:
    "/tes-app/appl/getCannedCommentsByTransitAgencyCode",
  GET_RELATED_APPS: "/tes-app/dashboard/getRelatedApplications",
  CHECK_ELIG_PROMISE: "/tes-app/program/checkEligPromiseCall",
  VERIFY_ELIG_PROMISE: "/tes-app/program/verifyEligPromiseCall"
};

export const DASHBOARD_ENDPOINT = {
  GET_TABS: "/tes-app/dashboard/getScreeningResponsesForTabs",
  GET_SCREENING_RESPONSE_BY_ID:
    "/tes-app/dashboard/getScreeningResponseByResponseId",
  GET_APPLICANT_PROFILE: "/tes-app/dashboard/getApplicantProfile",
  GET_APPLICANT_PROFILE_PROGRAM:
    "/tes-app/dashboard/getApplicantPgmEligibilityReq",
  GET_DASHBOARD_CONTENT: "/tes-app/dashboard/getDashboardContent",
  GET_ALL_USERS: "/tes-app/dashboard/getAllUsers",
  GET_ALL_SCREENING_RSPS: "/tes-app/dashboard/getAllScreeningResponses",
  UPDATE_SCREENING_RSPS: "/tes-app/dashboard/updateScreeningResponseName",
  GET_USER_INFO: "/tes-app/dashboard/getUserInfo",
  GET_ORG_DASHBOARD_CONTENT: "/tes-app/dashboard/getOrgDashboardContent",

  GET_ORG_USER_DASHBOARD_CONTENT:
    "/tes-app/dashboard/getOrgUserDashboardContent",

  GET_PREELIG_PRGMS: "/tes-app/dashboard/getPreEligiblePrograms",
  GET_DOC_TYPE_NUMBER:
    "/tes-app/dashboard/getNumberOfDocTypesByApplicantProfileId",
  GET_UNASSIGNED_AGENCY_APPS:
    "/tes-app/dashboard/getUnassignedAgencyApplications",
  GET_UNASSIGNED_AGENCY_APP_COUNT:
    "/tes-app/dashboard/getCountOfUnassignedApplications",
  GET_ALL_TRANSIT_AGENCY_DATA: "/tes-app/dashboard/getAllTransitAgencyData/",
  GET_ALL_AGING_APPS: "/tes-app/dashboard/getAllAgingApplications",
  GET_ASSIGNEE_LIST: "/tes-app/dashboard/getAsgneeList",
  ASSIGN_APP_USER: "/tes-app/dashboard/assignApp"
};

export const APP_REVIEW = {
  GET_APP_PROFILE: "/tes-app/appReview/getApplicantProfile",
  GET_APP_ADDRESS: "/tes-app/appReview/getApplicantAddress",
  GET_APP_PROFILE_MOBILITY:
    "/tes-app/appReview/getApplicantProfileMobilityDevices",
  GET_APP_PROGRAM_INFO: "/tes-app/appReview/getAppProgramInfo"
};
