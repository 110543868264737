import { IDrivingLicense } from "@/types";
import { drivingLicenseInfoDefault } from "@/data/AppData";
import { clone } from "@/util/clone";
import { moduleActionContext } from "..";
import { defineModule } from "direct-vuex";

export interface DrivingLicenseInfoState {
  drivingLicenseInfo: IDrivingLicense;
  isVerified: string;
  cntButton: boolean;
  commonQuesHasDL: string;
}

const drivingLicenseModule = defineModule({
  namespaced: true,
  state: {
    drivingLicenseInfo: clone(drivingLicenseInfoDefault),
    isVerified: "",
    cntButton: false,
    commonQuesHasDL: ""
  } as DrivingLicenseInfoState,
  getters: {
    getDrivingLicenseInfo: (state: DrivingLicenseInfoState) =>
      state.drivingLicenseInfo,
    getIsVerified: (state: DrivingLicenseInfoState) => state.isVerified,
    getCntButton: (state: DrivingLicenseInfoState) => state.cntButton,
    getCommonQuesHasDL: (state: DrivingLicenseInfoState) =>
      state.commonQuesHasDL
  },
  mutations: {
    SET_DRIVING_LICENSE_INFO(
      state: DrivingLicenseInfoState,
      drivingLicenseInfoObject: IDrivingLicense
    ) {
      state.drivingLicenseInfo = drivingLicenseInfoObject;
    },
    SET_IS_VERIFIED(state: DrivingLicenseInfoState, newValue: string) {
      state.isVerified = newValue;
    },
    SET_CNT_BUTTON(state: DrivingLicenseInfoState, newValue: boolean) {
      state.cntButton = newValue;
    },
    SET_COMMON_QUES_HAS_DL(state: DrivingLicenseInfoState, newValue: string) {
      state.commonQuesHasDL = newValue;
    }
  },
  actions: {
    resetData(context: any) {
      let { commit } = drivingLicenseModuleContext(context);
      //clearing the drivingLicenseInfo data in store.
      let drivingLicenseInfo = clone(drivingLicenseInfoDefault);
      //@ts-ignore
      commit.SET_DRIVING_LICENSE_INFO(drivingLicenseInfo);
      //@ts-ignore
      commit.SET_IS_VERIFIED("");
      //@ts-ignore
      commit.SET_CNT_BUTTON(false);
      //@ts-ignore
      commit.SET_COMMON_QUES_HAS_DL("");
    }
  }
});

export default drivingLicenseModule;
const drivingLicenseModuleContext = (context: any) =>
  moduleActionContext(context, drivingLicenseModule);
