
import Vue from "vue";
import router from "@/router";
import AccessibilityMixin from "@/mixins/AccessibilityMixin.vue";

export default Vue.extend({
  name: "quest-dialog",
  mixins: [AccessibilityMixin],

  props: {
    showQuestionnaireDialog: { type: Boolean, default: true },
    location: { type: String, default: "" }
  },
  methods: {
    startApplication() {
      router.push({ name: "createapp" });
      this.$emit("close-questDialog");
    },
    getScreeningQuestions() {
      router.push({ name: "screeningQuestions" });
      this.$emit("close-questDialog");
    }
  },
  watch: {
    showQuestionnaireDialog: {
      immediate: true,
      handler: function(newValue) {
        if (newValue) {
          this.$nextTick(() => {
            //@ts-ignore
            this.setAriaAttribute(this.$refs.orgQuestionnaireDialog);
          });
        }
      }
    }
  }
});
