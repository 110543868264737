import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from "vuetify/lib/util/colors";
import "@fortawesome/fontawesome-free/css/all.css"; // Ensure you are using css-loader

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "fa"
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: "#002469",
        secondary: "#6699C2",
        accent: colors.blue.accent1,
        error: "#D71A17",
        info: "#BDE5FF",
        success: "#029949",
        warning: "#E0AB06",
        white: "#FFFFFF",
        black: "#000000",
        alert_gold: "#E0AB06",
        alert_green: "#029949",
        brand_red: "#AD2624",
        brand_green: "007336",
        base_light_grey: "#EBEBEB",
        agency_light_grey: "#F4F4F4",
        disabled_steppers: "#949494"
      },
      dark: {
        primary: colors.blue.accent4,
        secondary: colors.deepPurple.accent1,
        accent: colors.blue.accent1,
        error: "#FF5252",
        info: "#BDE5FF",
        success: "#4CAF50",
        warning: "#FFC107",
        white: "#FFFFFF",
        black: "#000000",
        alert_gold: "#E0AB06",
        alert_green: "#029949",
        brand_red: "#AD2624",
        brand_green: "007336",
        base_light_grey: "#EBEBEB",
        agency_light_grey: "#F4F4F4",
        disabled_steppers: "#949494"
      }
    }
  }
});
