
import Vue from "vue";
import ProgressBar from "@/components/common/ProgressBar.vue";
import ContactUs from "@/components/contact/ContactUs.vue";
import store from "@/store";

export default Vue.extend({
  name: "contact",
  components: {
    ContactUs,
    ProgressBar
  },
  created() {
    let uiFeatureArray =
      "HME_THIS,NCOUNTY_3,QUICK_SRCH,ER_INLINE,CHGLGN_TXT,ADMN_USR,MAIN,MAIN_TITLE,FMR,ENVRONMNT,ADA,FTP,MATP,PWD,SSR,ADA_Y,ADAAC_Y,PWDAC_Y,ADA_N,FTP_Y,FTP_N,MATP_Y,MATP_N,PWD_Y,PWD_N,RFTP_Y,RFTP_N,SSR_Y,SSR_N,AGING_Y,AGING_N,VTP_Y,VTP_N";

    //@ts-ignore
    store.commit.uiFeatureModule.SET_DATA_LDNG_STS(true);
    //@ts-ignore
    store.dispatch.uiFeatureModule.getUIFeatures(uiFeatureArray);
  },
  computed: {
    isDataLoading: {
      get(): boolean {
        //@ts-ignore
        return store.getters.uiFeatureModule.getLoadingStatus;
      }
    }
  }
});
