
import Vue from "vue";
import { TES_APP_ENDPOINT } from "@/constants/EndPoints";
import axios from "axios";
import router from "@/router";
import {
  IApplicantMobilityDevice,
  IApplicantProfile,
  IProgramsList,
  IApplicationNavigation,
  ITESUser,
  IScreeningResponse
} from "@/types";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import store from "@/store";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import TESRadioControl from "@/components/common/TESRadioControl.vue";
import StepperNavigationMixinVue from "@/mixins/StepperNavigationMixin.vue";
import routerMixin from "@/mixins/RouterMixin.vue";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import ProgressBar from "@/components/common/ProgressBar.vue";
import ExpansionPanelTemplate from "@/components/common/ExpansionPanelTemplate.vue";
import { expansionPanelIconObj, CNST_TEXT } from "@/constants/CommonConstants";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";
import { GPC } from "@/constants/GPCCode";
import DynamicTextMixin from "@/mixins/DynamicTextMixin.vue";

export default Vue.extend({
  name: "mobility-info",
  mixins: [
    fieldEntryMixin,
    UIFeatureMixinVue,
    StepperNavigationMixinVue,
    routerMixin,
    AppPropsMixin,
    ApplicationMixin,
    DynamicTextMixin
  ],
  components: {
    TESRadioControl,
    ProgressBar,
    ExpansionPanelTemplate
  },
  data() {
    return {
      mobilityDeviceUseInd: "" as string,
      liftEnabledVehicleInd: "" as string,
      wheelChairTransferInd: "" as string,
      wheelChairSizeInd: "" as string,
      seletedMobility: [] as string[],
      otherText: "" as string,
      applicantMobilityModel: {} as IApplicantMobilityDevice,
      viewDiagramDailog: false,
      showProgressBar: false,
      expPanelIconObj: expansionPanelIconObj
    };
  },
  props: {
    cancelButton: { type: Boolean, default: false }
  },
  methods: {
    saveMobilityInfo() {
      this.showProgressBar = true;
      let url = TES_APP_ENDPOINT.SAVE_MOBILITY_INFO;
      this.populateMobilityInfo();

      axios
        .post(url, this.applicantProfile)
        .then(response => {
          //@ts-ignore
          store.commit.applicantProfileModule.SET_APPLICANT_PROFILE(
            response.data.applicantProfile
          );
          //@ts-ignore
          store.commit.programEligModule.SET_PROG_ELIG(
            response.data.programEligModel
          );

          if (this.cancelButton) {
            //@ts-ignore
            this.forwardToDashboard();
          } else if (
            response.data.programEligModel.preEligiblePrograms.length > 0
          ) {
            //@ts-ignore
            store.commit.applicantProgramModule.SET_APPLICANT_PROGRAM(
              response.data.programEligModel.preEligiblePrograms[0]
            );
            //@ts-ignore
            this.getStepperheaders();
          } else if (this.applicationNavigation.applProfileEdit) {
            router.push({
              path:
                "/application-review/" +
                this.applicantProgramInfo.programTypeCode
            });
          } else {
            store.dispatch.applicantProgramModule.resetData();
            router.push({ name: "reviewProfileInfo" });
          }
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    },
    resetValues() {
      if (this.mobilityDeviceUseInd === "N") {
        this.seletedMobility = [];
      }
    },
    populateMobilityInfo() {
      this.applicantMobilityModel.applicantProfileId = this.applicantProfile.applicantProfileId;
      this.applicantMobilityModel.mobilityDeviceCodes = this.seletedMobility;
      this.applicantMobilityModel.otherMobilityDevice = this.otherText;

      this.applicantProfile.mobilityDeviceUseInd = this.mobilityDeviceUseInd;
      this.applicantProfile.liftEnabledVehicleInd = this.liftEnabledVehicleInd;
      this.applicantProfile.wheelChairTransferInd = this.wheelChairTransferInd;
      this.applicantProfile.wheelChairSizeInd = this.wheelChairSizeInd;

      this.applicantProfile.applicantMobilityDevices = this.applicantMobilityModel;

      //@ts-ignore
      store.commit.applicantProfileModule.SET_APPLICANT_PROFILE(
        this.applicantProfile
      );
    },
    getText(radio: any): any {
      if (radio.largeText) {
        return radio.largeText;
      } else {
        return radio.displayName;
      }
    },
    displayViewDiagramDialog() {
      this.viewDiagramDailog = true;
    }
  },
  computed: {
    applicantProfile: {
      get(): IApplicantProfile {
        //@ts-ignore
        return store.getters.applicantProfileModule.getApplicantProfile;
      },
      set(newValue: IApplicantProfile) {
        //@ts-ignore
        store.commit.applicantProfileModule.SET_APPLICANT_PROFILE(newValue);
      }
    },
    applicationNavigation: {
      get(): IApplicationNavigation {
        //@ts-ignore
        return store.getters.applicationNavigationModule
          .getApplicationNavigation;
      }
    },
    applicantProgramInfo: {
      get(): IProgramsList {
        //@ts-ignore
        return store.getters.applicantProgramModule.getApplicantProgram;
      }
    },
    userInfo: {
      get(): ITESUser {
        //@ts-ignore
        return store.getters.tesUserModule.getTesUser;
      }
    },
    mobilityItems(): any {
      //@ts-ignore
      return this.uiFeature.MOBL_TYPES.children;
    },
    radioOptions(): any {
      let radioOptions: any;
      //@ts-ignore
      radioOptions = this.uiFeature.RADIO_YN.children;
      return radioOptions;
    },
    screeningResponse(): IScreeningResponse {
      return store.getters.screeningResponseModule.getScreeningResponse;
    },
    firstSubSectionContent(): string {
      let firstSubSectionText: string = "";
      //@ts-ignore
      if (this.isOrganizationUser || this.isAgencyUser) {
        //@ts-ignore
        firstSubSectionText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.MOBIL_INFO].largeText,
          "the applicant",
          "their"
        );
        //@ts-ignore
      } else if (this.isCitizenUser) {
        if (this.screeningResponse.onBehalfOfInd === "Y") {
          //@ts-ignore
          firstSubSectionText = this.buildDynamicText(
            //@ts-ignore
            this.uiFeature[GPC.MOBIL_INFO].largeText,
            "the applicant",
            "their"
          );
        } else {
          //@ts-ignore
          firstSubSectionText = this.buildDynamicText(
            //@ts-ignore
            this.uiFeature[GPC.MOBIL_INFO].largeText,
            "you",
            "your"
          );
        }
      }
      return firstSubSectionText;
    },
    leftEnabledVehicleQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithOnePlaceHolderVal(
        GPC.LFT_EN_VEH,
        CNST_TEXT.DOES_THE_APPLICANT,
        CNST_TEXT.DO_YOU
      );
      return text;
    },
    haveAnyMobilityDevicesQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithOnePlaceHolderVal(
        GPC.ANY_MOB_DV,
        CNST_TEXT.DOES_THE_APPLICANT,
        CNST_TEXT.DO_YOU
      );
      return text;
    },
    selectMobilityDevicesQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithTwoPlaceHolder(
        GPC.AL_MBD_LBL,
        CNST_TEXT.THE_APPLICANT,
        CNST_TEXT.USES,
        CNST_TEXT.YOU,
        CNST_TEXT.USE
      );
      return text;
    },
    withWithoutAssistQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithOnePlaceHolderVal(
        GPC.CR_MN_VAN,
        CNST_TEXT.THE_APPLICANT,
        CNST_TEXT.YOU
      );
      return text;
    },
    wheelChairWeightQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithOnePlaceHolder(
        GPC.WCHR_MET_Q,
        CNST_TEXT.THE_APPLICANTS,
        CNST_TEXT.YOUR
      );
      return text;
    }
  },
  created() {
    if (this.applicantProfile) {
      this.mobilityDeviceUseInd = this.applicantProfile.mobilityDeviceUseInd;
      this.liftEnabledVehicleInd = this.applicantProfile.liftEnabledVehicleInd;
      this.wheelChairTransferInd = this.applicantProfile.wheelChairTransferInd;
      this.wheelChairSizeInd = this.applicantProfile.wheelChairSizeInd;
      if (this.applicantProfile.applicantMobilityDevices) {
        this.seletedMobility = this.applicantProfile.applicantMobilityDevices.mobilityDeviceCodes;
        if (
          this.applicantProfile.applicantMobilityDevices.otherMobilityDevice
        ) {
          this.otherText = this.applicantProfile.applicantMobilityDevices.otherMobilityDevice;
        }
      }
    }
  }
});
