
import Vue from "vue";
import FTPProgramInfoReview from "@/components/application/program/ftp/review/FTPProgramInfoReview.vue";
import FTPGeneralInfoReview from "@/components/application/program/ftp/review/FTPGeneralInfoReview.vue";
import AttachmentsReview from "@/components/application/program/AttachmentsReview.vue";
import ApplicationReviewControls from "@/components/application/program/ApplicationReviewControls.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import { IDraftProgramInfo, IProgramsList } from "@/types";
import StepperNavigationMixinVue from "@/mixins/StepperNavigationMixin.vue";
import AttachmentsMixinVue from "@/mixins/AttachmentsMixin.vue";
import InfoSection from "@/components/common/InfoSection.vue";
import store from "@/store";

export default Vue.extend({
  name: "Program-Review-FTP",
  mixins: [UIFeatureMixinVue, StepperNavigationMixinVue, AttachmentsMixinVue],
  components: {
    FTPProgramInfoReview,
    FTPGeneralInfoReview,
    AttachmentsReview,
    ApplicationReviewControls,
    InfoSection
  },
  props: {
    applicantProgramInfoObj: {
      type: Object as () => IProgramsList
    },
    draftProgramInfoObj: {
      type: Object as () => IDraftProgramInfo
    },
    showApplRevCtrls: { type: Boolean, default: false }
  },
  data: function() {
    return {
      singleFlow: false,
      documentTypeCodes: [] as string[]
    };
  },
  computed: {
    applicantProfileInfo() {
      return store.getters.applicantProfileModule.getApplicantProfile;
    },
    isPRTBypass(): boolean {
      return (
        this.applicantProfileInfo.homeAddress.paCountyCode === "02" &&
        this.draftProgramInfoObj.ftpAgencyCode == "PAAC"
      );
    }
  },
  created() {
    if (
      //@ts-ignore
      this.isSingleFlow()
    ) {
      this.singleFlow = true;
    } else {
      //@ts-ignore
      this.documentTypeCodes = this.getFTPDocumentTypes();
    }
  }
});
