
import Vue from "vue";

export default Vue.extend({
  name: "info-section",
  props: {
    header: { type: String, default: "" },
    body: { type: String, default: "" },
    showHeader: { type: Boolean, default: true },
    showBody: { type: Boolean, default: true },
    headerClass: { type: String, default: "font-weight-bold mb-2" },
    infoBorder: { type: Boolean, default: false }
  }
});
