
import Vue from "vue";
import store from "@/store";
import { IProgramsList } from "@/types";
import { expansionPanelIconObj } from "@/constants/CommonConstants";
import ExpansionPanelTemplate from "@/components/common/ExpansionPanelTemplate.vue";
export default Vue.extend({
  name: "Edit-Profile-Program-Changes-Msg",
  components: {
    ExpansionPanelTemplate
  },
  data() {
    return {
      iconTextLabel: {
        showText: expansionPanelIconObj.showText,
        hideText: expansionPanelIconObj.hideText
      }
    };
  },
  computed: {
    applicantProfile(): any {
      //@ts-ignore
      return store.getters.applicantProfileModule.getApplicantProfile;
    },
    applicantProgramInfo: {
      get(): IProgramsList {
        //@ts-ignore
        return store.getters.applicantProgramModule.getApplicantProgram;
      }
    }
  },
  created() {
    window.scrollTo(0, 0);
  },
  methods: {}
});
