
import Vue from "vue";
import PersonalInfo from "@/components/application/PersonalInfo.vue";
import HomeAddress from "@/components/application/HomeAddress.vue";
import axios from "axios";
import { GENERAl_INFO_ENDPOINT, TES_APP_ENDPOINT } from "@/constants/EndPoints";
import {
  IApplicantAddress,
  IApplicantProfile,
  ITESUser,
  IScreeningResponse,
  IApplicantDetails,
  IGISDataModel
} from "@/types";
import { applicantAddress } from "@/data/AppData";
import { clone } from "@/util/clone";
import store from "@/store";
import MobilityInfo from "@/components/application/MobilityInfo.vue";
import TransportationInfo from "@/components/application/TransportationInfo.vue";
import DemographicInfo from "@/components/application/DemographicInfo.vue";
import EmergencyContact from "@/components/application/EmergencyContact.vue";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import routerMixin from "@/mixins/RouterMixin.vue";
import dateHelperMixin from "@/mixins/dateHelperMixin.vue";
import StepperHeader from "@/components/common/StepperHeader.vue";
import CancelDialog from "@/components/application/CancelDialog.vue";
import CloseButton from "@/components/common/CloseButton.vue";
import ScrollMixin from "@/mixins/ScrollMixin.vue";
import { CNST_TEXT } from "@/constants/CommonConstants";
import DynamicTextMixin from "@/mixins/DynamicTextMixin.vue";
import { GPC } from "@/constants/GPCCode";
import TESRadioControl from "@/components/common/TESRadioControl.vue";
import AccessibilityMixin from "@/mixins/AccessibilityMixin.vue";

export default Vue.extend({
  name: "steppers-nav",
  mixins: [
    fieldEntryMixin,
    AppPropsMixin,
    routerMixin,
    dateHelperMixin,
    ScrollMixin,
    DynamicTextMixin,
    AccessibilityMixin
  ],
  components: {
    PersonalInfo,
    EmergencyContact,
    HomeAddress,
    DemographicInfo,
    TransportationInfo,
    MobilityInfo,
    StepperHeader,
    CancelDialog,
    CloseButton,
    TESRadioControl
  },
  data() {
    return {
      stepperIndex: 1 as number,
      vertical: true as boolean,
      editable: true as boolean,
      showMailingAddress: false as boolean,
      mailingAddress: {} as IApplicantAddress,
      homeAddress: {} as IApplicantAddress,
      dialog: false,
      profileInfo: false,
      mobilityCancelInfo: false,
      mobilityCancelButton: false,
      displayCancelDialog: false,
      dupAppl: false,
      gisCounties: [] as string[],
      gisDataModel: {} as IGISDataModel,
      gisCounty: false,
      confirmedCounty: ""
    };
  },
  created() {
    //@ts-ignore
    store.dispatch.dataModule.getFilteredCounties();
    //@ts-ignore
    store.dispatch.dataModule.getAllCounties();
    this.getUIFeaturesForThisScreen();

    this.homeAddress = clone(applicantAddress);
    this.homeAddress.addressTypeCode = "HOME";
    this.homeAddress.paCountyCode = this.screeningResponse.paCountyCode;
    this.homeAddress.addressLine1 = this.screeningResponse.addressLine1;
    this.homeAddress.addressLine2 = this.screeningResponse.addressLine2;
    this.homeAddress.city = this.screeningResponse.city;
    this.homeAddress.state = this.screeningResponse.state;
    //@ts-ignore
    this.homeAddress.zip = this.unmaskedPhone(this.screeningResponse.zip);
    if (this.homeAddress.paCountyCode === "14") {
      if ("Yes" === this.screeningResponse.stateCollegeInd) {
        this.homeAddress.stateCollegeInd = "Y";
      } else if ("No" === this.screeningResponse.stateCollegeInd) {
        this.homeAddress.stateCollegeInd = "N";
      }
    }

    if (
      this.applicantProfile.homeAddress &&
      this.applicantProfile.homeAddress.profileAddressId
    ) {
      this.homeAddress = this.applicantProfile.homeAddress;
      this.homeAddress.maskedZip = this.applicantProfile.homeAddress.zip;
    }

    this.mailingAddress = clone(applicantAddress);
    this.mailingAddress.addressTypeCode = "MAIL";

    if (
      this.applicantProfile.mailingAddress != null &&
      this.applicantProfile.mailingAddress.profileAddressId
    ) {
      this.mailingAddress = this.applicantProfile.mailingAddress;
      this.mailingAddress.maskedZip = this.applicantProfile.mailingAddress.zip;
    }
  },
  computed: {
    applicantProfile: {
      get(): IApplicantProfile {
        return store.getters.applicantProfileModule.getApplicantProfile;
      },
      set(newValue: IApplicantProfile) {
        store.commit.applicantProfileModule.SET_APPLICANT_PROFILE(newValue);
      }
    },
    userInfo(): ITESUser {
      return store.getters.tesUserModule.getTesUser;
    },
    screeningResponse(): IScreeningResponse {
      return store.getters.screeningResponseModule.getScreeningResponse;
    },
    counties(): [] {
      //@ts-ignore
      return store.getters.dataModule.getCounties;
    },
    applicantDetails(): IApplicantDetails {
      return store.getters.applicantDetailsModule.getApplicantDetails;
    },
    gisCountyConfirmDialogTitle(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrgAgyCtznonBehalfOfUser()
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.GIS_CNFM_1].largeText,
          CNST_TEXT.THE_APPLICANTS
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.GIS_CNFM_1].largeText,
          CNST_TEXT.YOUR
        );
      }
      return text;
    },
    gisCountyConfirmDialogText(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrgAgyCtznonBehalfOfUser()
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.GIS_CNFM_2].largeText,
          CNST_TEXT.THE_APPLICANT_RESIDES
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.GIS_CNFM_2].largeText,
          CNST_TEXT.YOU_RESIDE
        );
      }
      return text;
    },
    isAlleghenyCounty(): boolean {
      if (
        (this.homeAddress &&
          this.homeAddress.paCountyCode &&
          this.homeAddress.paCountyCode === "02") ||
        (this.applicantProfile.homeAddress &&
          this.applicantProfile.homeAddress.paCountyCode === "02")
      ) {
        return true;
      } else return false;
    }
  },
  methods: {
    getUIFeaturesForThisScreen() {
      let uiFeatureArray =
        "LANGUAGES,RACE,MOBL_TYPES,GENDER,STATE,RADIO_YN,DATE_FUT,DATE_FOR,PH_NOTZERO,NO_COUNTY2,CNTY_MAP,NO_THIS,PRSNL_INFO,HMEAD_INFO,MAIL_INFO,EMERG_INFO,DEMOG_INFO,TRNS_INFO1,TRNS_INFO2,TRANS_ASST,MOBIL_INFO,MOBL_INFO2,POD_AGNCYS,ADDR_TEXT,ADDR_APT,C_COD_AGNY,QUICK_SRCH,ER_INLINE,CHGLGN_TXT,DL_HELPTXT,FMR,ENVRONMNT,,DMGPH_QNS,PRMRY_LNG,LNG_INTRPR,RCE_ETNCTY,NRG_HME,DCRG_NG_HM,PSNL_CR_HM,PNL_CHM_TP,TSPN_INF_Q,PSNL_C_ADT,CUR_MN_TRP,MOB_INF_QS,LFT_EN_VEH,ANY_MOB_DV,AL_MBD_LBL,CR_MN_VAN,WCHR_MET_Q,EMERG_NOTE,ST_COLLG_Q,DUP_APPL_M,MORE_INFO,WHY_TXT,PFILE_QUES,IS_MAIL_AD,GIS_CNFM_1,GIS_CNFM_2,REL_TO_APL,ACCBL_FRMT,ADIO_CASS,BRAILLE,LRGE_PRNT,ACCBL_OTHR,ACBL_PRT_Q,ACBL_INFO,TRNSP_WAYS,BUS_TRLY,SUB_TRAIN,AMBLANC,AUTOMOBL,WALKING,TAXI_SERVC,TRNSP_OTHR";

      store.dispatch.uiFeatureModule.getUIFeatures(uiFeatureArray);
    },
    setApplicantAddressIds() {
      // set homeAddress's profileAddressId and applicantProfileId
      this.homeAddress.applicantProfileId = this.applicantProfile.applicantProfileId;

      if (
        this.applicantProfile.homeAddress &&
        this.applicantProfile.homeAddress.profileAddressId
      ) {
        this.homeAddress.profileAddressId = this.applicantProfile.homeAddress.profileAddressId;
      }
      // set mailingAddress's profileAddressId and applicantProfileId
      this.mailingAddress.applicantProfileId = this.applicantProfile.applicantProfileId;
      if (
        this.applicantProfile.mailingAddress &&
        this.applicantProfile.mailingAddress.profileAddressId
      ) {
        this.mailingAddress.profileAddressId = this.applicantProfile.mailingAddress.profileAddressId;
      }
    },
    continueToDashboard() {
      //@ts-ignore
      this.forwardToDashboard();
    },
    showCancelDialog() {
      this.displayCancelDialog = true;
    },
    showMobilityCancelDialog() {
      this.mobilityCancelInfo = true;
      this.displayCancelDialog = true;
    },
    saveProfileForCancel() {
      if (this.isAlleghenyCounty) {
        this.saveEmergencyInfoForCancel();
      } else {
        if (this.mobilityCancelInfo) {
          this.saveMobilityInfoForCancel();
        } else {
          this.mobilityCancelInfo = false;
          this.nextStep(true);
        }
      }
      this.displayCancelDialog = false;
    },
    async nextStep(cancelPopup: boolean) {
      switch (this.stepperIndex) {
        case 1:
          //@ts-ignore
          if (this.$refs.formPersonalInfo.validate()) {
            this.dialog = true;
            this.savePersonalInfo(cancelPopup, false);
          } else {
            //@ts-ignore
            this.scrollToErrorField(this.$refs.formPersonalInfo);
          }
          break;

        case 2:
          //@ts-ignore
          if (this.$refs.formHomeAddress.validate()) {
            this.validateAndSaveAdrs(cancelPopup);
          } else {
            //@ts-ignore
            this.scrollToErrorField(this.$refs.formHomeAddress);
          }
          break;
        case 3:
          this.emgContactSaveOnNextStep(cancelPopup);
          break;
        case 4:
          //@ts-ignore
          if (this.$refs.formDemographicInfo.validate()) {
            this.dialog = true;
            this.saveDemoInfo(cancelPopup);
          } else {
            //@ts-ignore
            this.scrollToErrorField(this.$refs.formDemographicInfo);
          }
          break;
        case 5:
          //@ts-ignore
          if (this.$refs.formTransportationInfo.validate()) {
            this.dialog = true;
            this.saveTransportationInfo(cancelPopup);
          } else {
            //@ts-ignore
            this.scrollToErrorField(this.$refs.formTransportationInfo);
          }
          break;
        default:
          this.stepperIndex++;
      }
    },
    emgContactSaveOnNextStep(cancelPopup: boolean) {
      //@ts-ignore
      if (this.$refs.formEmergencyContact.validate()) {
        this.dialog = true;
        this.saveEmergencyContact(cancelPopup);
      } else {
        //@ts-ignore
        this.scrollToErrorField(this.$refs.formEmergencyContact);
      }
    },
    async validateAndSaveAdrs(cancelPopup: boolean) {
      this.getCountyValForCode();
      if (
        !this.confirmedCounty ||
        this.confirmedCounty != this.homeAddress.paCountyCode
      ) {
        await this.getGISCounties();
      }
      if (!this.gisCounty) {
        this.dialog = true;
        this.saveApplicantAddress(cancelPopup);
      }
    },
    confirmCounty() {
      this.homeAddress.paCountyCode = this.confirmedCounty;
      this.gisCounty = false;
    },
    scrollTo(ref: any, refToFocus: any) {
      setTimeout(
        () =>
          this.$vuetify.goTo(ref, {
            duration: 500,
            offset: 0,
            easing: "easeInOutCubic"
          }),
        500
      );
      refToFocus.focus();
    },
    savePersonalInfo(cancelPopup: boolean, dupAppl: boolean) {
      //@ts-ignore
      this.$refs.refPersonalInfo.saveToStore();
      this.applicantProfile.applicantUserId = this.userInfo.userId;

      this.applicantProfile.createdByUserId = this.userInfo.userId;
      this.applicantProfile.screeningResponseId = this.screeningResponse.screeningResponseId;
      if (
        this.applicantProfile.onBehalfOfInd == "Y" ||
        this.applicantDetails.isSurrogate
      ) {
        this.applicantProfile.onBehalfOfInd = "Y";
      } else {
        this.applicantProfile.onBehalfOfInd = "N";
      }

      if (this.userInfo.organizationType === "O") {
        this.applicantProfile.organizationName = this.userInfo.organizationName;
      }
      this.setApplicantProfileDupDetails(dupAppl);
      this.saveProfileInfoCont(cancelPopup);
    },
    saveProfileInfoCont(cancelPopup: boolean) {
      let url = TES_APP_ENDPOINT.SAVE_PERSONAL_INFO;
      axios
        .post(url, this.applicantProfile)
        .then(response => {
          this.applicantProfile = response.data;
          if (
            (null === this.applicantProfile.applicantProfileId ||
              this.applicantProfile.applicantProfileId === 0) &&
            this.applicantProfile.duplicateApplications
          ) {
            this.dupAppl = true;
          } else {
            if (this.screeningResponse.screeningResponseId > 0) {
              this.updateApplProfileId();
            }

            this.stepperIndex++;
            if (cancelPopup) {
              this.continueToDashboard();
            } else {
              this.dupAppl = false;
              this.scrollTo(
                this.$refs.refStepHomeAddress,
                this.$refs.refHomeAddressLabel
              );
            }
          }
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.dialog = false;
        });
    },
    saveWithDuplicateApplications() {
      this.savePersonalInfo(false, true);
    },
    updateApplProfileId() {
      let url = TES_APP_ENDPOINT.UPDATE_APPL_PROFILE_ID;
      this.applicantProfile.screeningResponseId = this.screeningResponse.screeningResponseId;
      this.dialog = true;
      axios
        .post(url, this.applicantProfile)
        .then(response => {
          //@ts-ignore
          this.applicantProfile = response.data;
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.dialog = false;
        });
    },
    saveApplicantAddress(cancelPopup: boolean) {
      let url = GENERAl_INFO_ENDPOINT.SAVE_APPLICANT_ADDRESS;
      this.setApplicantAddressIds();

      this.applicantProfile.homeAddress = this.homeAddress;
      this.applicantProfile.mailingAddress = this.mailingAddress;

      if (this.applicantProfile.homeAddress.paCountyCode != "14") {
        this.applicantProfile.homeAddress.stateCollegeInd = "";
      }

      axios
        .post(url, this.applicantProfile)
        .then(response => {
          //@ts-ignore
          this.applicantProfile = response.data;

          this.homeAddress = this.applicantProfile.homeAddress;

          if (this.applicantProfile.mailingAddress != null) {
            this.mailingAddress = this.applicantProfile.mailingAddress;
          } else {
            this.mailingAddress = clone(applicantAddress);
            this.mailingAddress.addressTypeCode = "MAIL";
          }
          this.stepperIndex++;

          if (cancelPopup) {
            this.continueToDashboard();
          } else {
            this.scrollTo(
              this.$refs.refStepEmergencyContact,
              this.$refs.refEmgContactLabel
            );
          }
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.dialog = false;
        });
    },
    saveEmergencyContact(cancelPopup: boolean) {
      let url = TES_APP_ENDPOINT.SAVE_PERSONAL_INFO;
      //@ts-ignore
      this.$refs.refEmergencyContact.saveToStore();
      axios
        .post(url, this.applicantProfile)
        .then(response => {
          //@ts-ignore
          this.applicantProfile = response.data;
          this.stepperIndex++;
          if (cancelPopup) {
            this.continueToDashboard();
          } else {
            this.scrollTo(
              this.$refs.refStepDemographicInfo,
              this.$refs.refDemographicInfoLabel
            );
          }
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.dialog = false;
        });
    },
    saveDemoInfo(cancelPopup: boolean) {
      let url = TES_APP_ENDPOINT.SAVE_DEMOGRPHIC_INFO;
      //@ts-ignore
      this.$refs.demographicInfo.populatePersonalInfo();
      axios
        .post(url, this.applicantProfile)
        .then(response => {
          //@ts-ignore
          this.applicantProfile = response.data;
          this.stepperIndex++;
          if (cancelPopup) {
            this.continueToDashboard();
          } else {
            this.scrollTo(
              this.$refs.refStepTransportationInfo,
              this.$refs.refTransportationInfoLabel
            );
          }
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.dialog = false;
        });
    },
    saveTransportationInfo(cancelPopup: boolean) {
      let url = TES_APP_ENDPOINT.SAVE_PERSONAL_INFO;
      //@ts-ignore
      this.$refs.transportationInfo.populateTransportationInfo();
      axios
        .post(url, this.applicantProfile)
        .then(response => {
          //@ts-ignore
          this.applicantProfile = response.data;
          this.stepperIndex++;
          if (cancelPopup) {
            this.continueToDashboard();
          } else {
            this.scrollTo(
              this.$refs.refStepMobilityInfo,
              this.$refs.refMobilityInfoLabel
            );
          }
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.dialog = false;
        });
    },
    displayProfileInfo() {
      //@ts-ignore
      if (!this.$refs.formPersonalInfo.validate()) {
        //@ts-ignore
        this.scrollToErrorField(this.$refs.formPersonalInfo);
        this.stepperIndex = 1;
      }
      //@ts-ignore
      else if (!this.$refs.formHomeAddress.validate()) {
        //@ts-ignore
        this.scrollToErrorField(this.$refs.formHomeAddress);
        this.stepperIndex = 2;
      }
      //@ts-ignore
      else if (!this.$refs.formEmergencyContact.validate()) {
        //@ts-ignore
        this.scrollToErrorField(this.$refs.formEmergencyContact);
        this.stepperIndex = 3;
      }
      //@ts-ignore
      else if (!this.$refs.formDemographicInfo.validate()) {
        //@ts-ignore
        this.scrollToErrorField(this.$refs.formDemographicInfo);
        this.stepperIndex = 4;
      }
      //@ts-ignore
      else if (!this.$refs.formTransportationInfo.validate()) {
        //@ts-ignore
        this.scrollToErrorField(this.$refs.formTransportationInfo);
        this.stepperIndex = 5;
      }
      //@ts-ignore
      else if (!this.$refs.formMobilityInfo.validate()) {
        //@ts-ignore
        this.scrollToErrorField(this.$refs.formMobilityInfo);
        this.stepperIndex = 6;
      } else {
        this.profileInfo = true;
      }
    },
    validateProfileInfo() {
      //@ts-ignore
      if (!this.$refs.formPersonalInfo.validate()) {
        //@ts-ignore
        this.scrollToErrorField(this.$refs.formPersonalInfo);
        this.stepperIndex = 1;
      }
      //@ts-ignore
      else if (!this.$refs.formHomeAddress.validate()) {
        //@ts-ignore
        this.scrollToErrorField(this.$refs.formHomeAddress);
        this.stepperIndex = 2;
      }
      //@ts-ignore
      else if (!this.$refs.formEmergencyContact.validate()) {
        //@ts-ignore
        this.scrollToErrorField(this.$refs.formEmergencyContact);
        this.stepperIndex = 3;
      } else {
        this.profileInfo = true;
      }
    },
    saveMobilityInfoForCancel() {
      const formMobilityInfo = this.$refs.formMobilityInfo as any;
      if (formMobilityInfo.validate()) {
        this.mobilityCancelButton = true;
        this.saveMobilityInfo();
      }
      this.mobilityCancelInfo = false;
      this.scrollTo(
        this.$refs.refStepMobilityInfo,
        this.$refs.refMobilityInfoLabel
      );
    },
    saveMobilityInfo() {
      this.profileInfo = false;

      const mobilityInfo = this.$refs.mobilityInfo as any;
      this.dialog = true;
      mobilityInfo.saveMobilityInfo();
      this.dialog = false;
    },
    saveEmergencyInfoForCancel() {
      const emergencyCnctInfo = this.$refs.refEmergencyContact as any;
      //@ts-ignore
      if (this.$refs.formEmergencyContact.validate()) {
        emergencyCnctInfo.saveEmergencyCnctInfo(true);
      }
    },
    saveEmergencyInfo() {
      this.profileInfo = false;
      const emergencyCnctInfo = this.$refs.refEmergencyContact as any;
      this.dialog = true;
      emergencyCnctInfo.saveEmergencyCnctInfo(false);
      this.dialog = false;
    },
    getFormattedDOB(dob: string) {
      //@ts-ignore
      return this.formatDateMixin(dob);
    },
    getCountyName(paCountyCode: string) {
      let countyName = this.counties.find(
        obj =>
          //@ts-ignore
          obj.id === paCountyCode
      );
      //@ts-ignore
      return countyName ? countyName.name : "";
    },
    goToPersonalInfoSection() {
      this.profileInfo = false;
      this.dupAppl = false;
      this.stepperIndex = 1;
      this.scrollTo(
        this.$refs.refStepPersonalInfo,
        this.$refs.refPersonalInfoLabel
      );
    },
    setApplicantProfileDupDetails(dupAppl: boolean) {
      this.applicantProfile.continueWithDupAppl = dupAppl;
      this.applicantProfile.organizationType = this.userInfo.organizationType;
      if (this.userInfo.organizationType === "A") {
        this.applicantProfile.transitAgencyCode = this.userInfo.transitAgencyCode;
        this.applicantProfile.transitAgencyName = this.userInfo.transitAgencyName;
      }
    },
    async getGISCounties() {
      let url = GENERAl_INFO_ENDPOINT.GET_GIS_COUNTIES;
      await axios
        .post(url, this.homeAddress)
        .then(response => {
          this.gisDataModel = response.data;
          this.gisCounties = this.gisDataModel.countyList;
          if (
            this.gisCounties.length > 0 &&
            !this.gisCounties.includes(this.homeAddress.countyName)
          ) {
            this.confirmedCounty = "";
            this.gisCounty = true;
          }
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        });
    },
    getCountyValForCode() {
      for (let i in this.counties) {
        if (this.counties[i]["id"] === this.homeAddress.paCountyCode) {
          //@ts-ignore
          this.homeAddress.countyName = this.counties[i]["name"];
          break;
        }
      }
    },
    getCountyCodeForVal(codeName: string): any {
      let code: any;
      for (let i in this.counties) {
        //@ts-ignore
        if (this.counties[i]["name"] === codeName) {
          //@ts-ignore
          code = this.counties[i]["id"];
          break;
        }
      }
      return code;
    }
  },
  watch: {
    gisCounty: {
      immediate: true,
      handler: function(newValue) {
        if (newValue) {
          this.$nextTick(() => {
            //@ts-ignore
            this.setAriaAttribute(this.$refs.createGISDialogRef);
          });
        }
      }
    }
  }
});
