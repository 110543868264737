
import Vue from "vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import axios from "axios";
import { TES_APP_ENDPOINT } from "@/constants/EndPoints";
import { IContactUsModel, IApplicantProfile } from "@/types";
import SystemInfoMessage from "@/components/common/SystemInfoMessage.vue";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import ProgressBar from "@/components/common/ProgressBar.vue";
import store from "@/store";
import AccessibilityMixin from "@/mixins/AccessibilityMixin.vue";

export default Vue.extend({
  name: "contactUs",
  mixins: [
    UIFeatureMixinVue,
    AppPropsMixin,
    fieldEntryMixin,
    AccessibilityMixin
  ],
  components: { SystemInfoMessage, ProgressBar },
  data() {
    return {
      countyCode: "",
      showProgressBar: false,
      contactUsContent: [] as IContactUsModel[]
    };
  },
  created() {
    if (this.filteredCounties.length === 0) {
      store.dispatch.dataModule.getFilteredCounties();
    }
    if (
      this.applicantProfile &&
      this.applicantProfile.homeAddress &&
      this.applicantProfile.homeAddress.paCountyCode
    ) {
      this.countyCode = this.applicantProfile.homeAddress.paCountyCode;
      this.getAgenciesByCounty(this.countyCode);
    }
  },
  mounted() {
    //@ts-ignore
    this.setAriaLabelledByForSelect(
      this.$refs.contact_us_county_select,
      "county_of_residence"
    );
  },
  computed: {
    filteredCounties(): [] {
      return store.getters.dataModule.getFilteredCountiesInStore;
    },
    applicantProfile: {
      get(): IApplicantProfile {
        return store.getters.applicantProfileModule.getApplicantProfile;
      }
    }
  },
  methods: {
    getAgenciesByCounty(resourceCode: string) {
      this.showProgressBar = true;
      axios
        .get(TES_APP_ENDPOINT.GET_CONTACTUS_AGENCIES_DETAILS_BY_CNTY, {
          params: {
            countyCode: resourceCode,
            contactType: "APPLY"
          }
        })
        .then(response => {
          this.contactUsContent = response.data;
          let el: HTMLElement | null = null;
          if (this.$vuetify.breakpoint.smAndDown) {
            el = document.getElementById("providerHeading");
          }
          if (el) {
            el.focus();
          }
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    }
  }
});
