
import Vue from "vue";
import UserAccount from "@/components/header/UserAccount.vue";
import {
  TES_APP_ENDPOINT,
  UI_ENDPOINT,
  DL_ENDPOINT,
  ECS_ENDPOINT,
  TES_NOTIFICATION_ENDPOINT,
  TES_WORKFLOW_ENDPOINT,
  ECOLANE_ENDPOINT,
  REPORTS_ENDPOINT,
  PROMISE_ENDPOINT
} from "@/constants/EndPoints";
import { ITESApplicationModel, ITESUser } from "@/types";
import ProgressBar from "@/components/common/ProgressBar.vue";
import { HTTP_STATUS } from "@/constants/HttpStatusCode";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import ClearStoreValuesMixin from "@/mixins/ClearStoreValuesMixin.vue";
import axios from "axios";
import router from "@/router";
import store from "@/store";
import routerMixin from "@/mixins/RouterMixin.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";
import CloseButton from "@/components/common/CloseButton.vue";
import { tesApplicationDefault } from "@/data/AppData";
import { clone } from "@/util/clone";

export default Vue.extend({
  name: "main-header",
  components: {
    UserAccount,
    ProgressBar,
    CloseButton
  },
  mixins: [
    fieldEntryMixin,
    AppPropsMixin,
    ClearStoreValuesMixin,
    routerMixin,
    UIFeatureMixinVue,
    ApplicationMixin
  ],
  data() {
    return {
      drawer: false,
      applicationId: "" as string,
      menuOpen: false,
      tesApplicationModel: {} as ITESApplicationModel,
      showProgressBar: false,
      searchResults: false,
      srchRsltNoAcs: false,
      exceptionCaused: false
    };
  },
  computed: {
    userInfo: {
      get(): ITESUser {
        //@ts-ignore
        return store.getters.tesUserModule.getTesUser;
      }
    },
    showSearchBox() {
      let showBox = false;
      if (this.userInfo && this.userInfo.userId) {
        showBox = true;
      }
      return showBox;
    },
    showAdminLink() {
      let adminLink = false;
      //@ts-ignore
      let admUsers = this.uiFeature.ADMN_USR.displayName.split(",");

      if (
        this.userInfo.userId &&
        admUsers.includes(this.userInfo.userId.toString())
      ) {
        adminLink = true;
      }
      return adminLink;
    },
    showLogoutLink() {
      let logoutLink = false;
      if (this.userInfo && this.userInfo.userId) {
        logoutLink = true;
      }
      return logoutLink;
    },
    showReports() {
      let show = true;
      //@ts-ignore
      if (this.isCitizenUser || this.isOrganizationUser) {
        show = false;
      }
      return show;
    },
    noSearchResults(): boolean {
      return this.searchResults;
    },
    noSrchRsltNoAcs(): boolean {
      return this.srchRsltNoAcs;
    },
    resetMenuData(): boolean {
      return !this.menuOpen && this.exceptionCaused;
    }
  },
  methods: {
    forwardToHomeOrDashboard() {
      if (this.showLogoutLink) {
        //after Login
        if (this.isSameRouteAsNow("dashboard")) {
          // to avoid redundant navigation
          return;
        } else {
          //@ts-ignore
          this.forwardToDashboard();
        }
      } else {
        //@ts-ignore
        this.clearAllStoreValues();

        if (this.isSameRouteAsNow("home")) {
          // to avoid redundant navigation
          return;
        } else {
          //@ts-ignore
          router.push({ name: "home" });
        }
      }
    },

    isSameRouteAsNow(paramStr: string) {
      // get comparable fullPaths
      let from = this.$route.fullPath;
      let to = this.$router.resolve({ name: paramStr }).route.fullPath;

      return from === to;
    },
    search() {
      this.showProgressBar = true;
      let fein = null;
      //@ts-ignore
      if (this.isOrganizationUser) {
        fein = this.userInfo.fein;
      }
      let url = TES_APP_ENDPOINT.GET_PROFILE_DETAILS_BY_APP_ID_AGENCY;
      this.menuOpen = true;
      axios
        .get(url, {
          params: {
            tesApplicationId: this.applicationId,
            userAgency: this.userInfo.transitAgencyCode,
            fein
          }
        })
        .then(response => {
          this.tesApplicationModel = response.data;
          if (response && response.data) {
            store.commit.appModule.SET_DOB_MATCH(response.data.nameDOBMatchInd);
          }
          this.applicationId = "";
          this.searchResults = false;
          this.srchRsltNoAcs = false;
          //@ts-ignore
          store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(
            this.tesApplicationModel.draftProgramInfoModel
          );

          //@ts-ignore
          store.commit.applicantProgramModule.SET_APPLICANT_PROGRAM(
            this.tesApplicationModel.programEligibilityModel
          );

          //@ts-ignore
          store.commit.applicantProfileModule.SET_APPLICANT_PROFILE(
            this.tesApplicationModel.applicantProfileModel
          );
          //Get the Driver license details
          store.dispatch.applicantProfileModule.getDriverLicenseDetails(
            //@ts-ignore
            store.getters.draftProgramInfoModule.getDraftProgramInfo
          );
          store.dispatch.applicationReviewModule.loadApplicationReviewModel({
            tesApplicationId: this.tesApplicationModel.applicantionId,
            cycleNumber: this.tesApplicationModel.cycleNumber
          });

          store.dispatch.screeningResponseModule.populateScrnRespByApplProfileID(
            this.tesApplicationModel.applicantProfileId
          );

          let params = {
            applicantProfileId: this.tesApplicationModel.applicantProfileId,
            transitAgencyCode: this.tesApplicationModel.transitAgencyCode
          };
          store.dispatch.applicantEcolaneProfileModule.loadApplicantEcolaneProfile(
            params
          );

          this.menuOpen = false;
          // route to Application review page
          router.push({
            name: "application-review",
            //@ts-ignore
            params: { program: this.tesApplicationModel.programTypeCode }
          });
        })
        .catch(error => {
          this.exceptionCaused = true;
          if (error.response.status === HTTP_STATUS.SC_NOT_FOUND) {
            this.searchResults = true;
            this.srchRsltNoAcs = false;
          }
          if (error.response.status === HTTP_STATUS.UNAUTHORIZED) {
            this.srchRsltNoAcs = true;
            this.searchResults = false;
          }
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    },
    refreshUIFeatureCache() {
      let restServices = [];
      restServices.push(UI_ENDPOINT.UI_REFRESH_UIFEATURE);
      restServices.push(TES_APP_ENDPOINT.APP_REFRESH_UIFEATURE);
      restServices.push(
        TES_NOTIFICATION_ENDPOINT.NOTIFICATION_REFRESH_UIFEATURE
      );
      restServices.push(TES_WORKFLOW_ENDPOINT.WF_REFRESH_UIFEATURE);
      restServices.push(DL_ENDPOINT.DL_REFRESH_UIFEATURE);
      restServices.push(ECS_ENDPOINT.ECS_REFRESH_UIFEATURE);
      restServices.push(ECOLANE_ENDPOINT.ECOLANE_REFRESH_UIFEATURE);
      restServices.push(REPORTS_ENDPOINT.REPORTS_REFRESH_UIFEATURE);
      restServices.push(PROMISE_ENDPOINT.PROMISE_REFRESH_UIFEATURE);

      restServices.forEach(url => {
        this.showProgressBar = true;
        //added authentication for higher environment
        axios
          .get(url)
          .then(() => {
            // there is no response data
          })
          .catch(error => {
            store.dispatch.alertModule.showAlertMessageById(
              error.response.data.id
            );
          })
          .finally(() => {
            this.showProgressBar = false;
          });
      });
    },
    changePromisePassword() {
      let changePassURL = PROMISE_ENDPOINT.PROMISE_PASSWORD_CHANGE;

      this.showProgressBar = true;
      axios
        .get(changePassURL)
        .then(() => {
          // there is no response data
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    },
    closeOnClick() {
      if (this.resetMenuData && this.exceptionCaused) {
        this.applicationId = "";
        this.tesApplicationModel = clone(tesApplicationDefault);
        this.searchResults = false;
        this.srchRsltNoAcs = false;
        this.exceptionCaused = false;
        //@ts-ignore
        this.$refs.menuDialogTemp.reset();
      }
    }
  }
});
