
import Vue from "vue";
import {
  IApplicantProfile,
  IProgramsList,
  IApplicationNavigation,
  ITESUser,
  IScreeningResponse
} from "@/types";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import store from "@/store";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import TESRadioControl from "@/components/common/TESRadioControl.vue";
import tesControlTemplate from "@/components/common/TESControlTemplate.vue";
import ExpansionPanelTemplate from "@/components/common/ExpansionPanelTemplate.vue";
import { expansionPanelIconObj, CNST_TEXT } from "@/constants/CommonConstants";
import { GPC } from "@/constants/GPCCode";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";
import DynamicTextMixin from "@/mixins/DynamicTextMixin.vue";
import { tclone } from "@/util/clone";
import helper from "@/composables/helper";

export default Vue.extend({
  name: "transportation-info",
  mixins: [
    fieldEntryMixin,
    UIFeatureMixinVue,
    ApplicationMixin,
    DynamicTextMixin
  ],
  components: {
    TESRadioControl,
    tesControlTemplate,
    ExpansionPanelTemplate
  },
  data() {
    return {
      travelAttendantInd: "" as string,
      transportationAssistanceInd: "" as string,
      expPanelIconObj: expansionPanelIconObj,
      transpWays: [] as string[]
    };
  },
  methods: {
    resetValues() {
      if (this.transportationAssistanceInd === "N") {
        this.travelAttendantInd = "";
      }
    },
    populateTransportationInfo() {
      this.applicantProfile.travelAttendantInd = this.travelAttendantInd;
      this.applicantProfile.transportationAssistanceInd = this.transportationAssistanceInd;

      //@ts-ignore
      store.commit.applicantProfileModule.SET_APPLICANT_PROFILE(
        this.applicantProfile
      );
    },
    transpWaysChanged() {
      if (!this.transpWays.includes(GPC.TRNSP_OTHR)) {
        this.applicantProfile.otherTranspWays = "";
      }
      this.applicantProfile.transpWaysList = this.transpWays.filter(
        t => !!t && t !== "NULL"
      );
    }
  },
  computed: {
    applicantProfile: {
      get(): IApplicantProfile {
        //@ts-ignore
        return store.getters.applicantProfileModule.getApplicantProfile;
      },
      set(newValue: IApplicantProfile) {
        //@ts-ignore
        store.commit.applicantProfileModule.SET_APPLICANT_PROFILE(newValue);
      }
    },
    applicationNavigation: {
      get(): IApplicationNavigation {
        //@ts-ignore
        return store.getters.applicationNavigationModule
          .getApplicationNavigation;
      }
    },
    applicantProgramInfo: {
      get(): IProgramsList {
        //@ts-ignore
        return store.getters.applicantProgramModule.getApplicantProgram;
      }
    },
    userInfo: {
      get(): ITESUser {
        //@ts-ignore
        return store.getters.tesUserModule.getTesUser;
      }
    },
    screeningResponse(): IScreeningResponse {
      return store.getters.screeningResponseModule.getScreeningResponse;
    },
    firstSubSectionInfo(): string {
      let firstSubSectionText: string = "";
      //@ts-ignore
      if (this.isOrganizationUser || this.isAgencyUser) {
        //@ts-ignore
        firstSubSectionText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.TRNS_INFO1].largeText,
          "the applicant",
          "they",
          "their",
          "The applicant",
          "The applicant's",
          "them",
          "they"
        );
        //@ts-ignore
      } else if (this.isCitizenUser) {
        if (this.screeningResponse.onBehalfOfInd === "Y") {
          //@ts-ignore
          firstSubSectionText = this.buildDynamicText(
            //@ts-ignore
            this.uiFeature[GPC.TRNS_INFO1].largeText,
            "the applicant",
            "they",
            "their",
            "The applicant",
            "The applicant's",
            "them",
            "they"
          );
        } else {
          //@ts-ignore
          firstSubSectionText = this.buildDynamicText(
            //@ts-ignore
            this.uiFeature[GPC.TRNS_INFO1].largeText,
            "you",
            "you",
            "your",
            "I",
            "My",
            "me",
            "I"
          );
        }
      }
      return firstSubSectionText;
    },
    secondSubSectionInfo(): string {
      let secondSubSectionText: string = "";
      //@ts-ignore
      if (this.isOrganizationUser || this.isAgencyUser) {
        //@ts-ignore
        secondSubSectionText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.TRNS_INFO2].largeText,
          "the applicant"
        );
        //@ts-ignore
      } else if (this.isCitizenUser) {
        if (this.screeningResponse.onBehalfOfInd === "Y") {
          //@ts-ignore
          secondSubSectionText = this.buildDynamicText(
            //@ts-ignore
            this.uiFeature[GPC.TRNS_INFO2].largeText,
            "the applicant"
          );
        } else {
          //@ts-ignore
          secondSubSectionText = this.buildDynamicText(
            //@ts-ignore
            this.uiFeature[GPC.TRNS_INFO2].largeText,
            "you"
          );
        }
      }
      return secondSubSectionText;
    },
    transAssistQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithOnePlaceHolder(
        GPC.TRANS_ASST,
        CNST_TEXT.DOES_THE_APPLICANT,
        CNST_TEXT.DO_YOU
      );
      return text;
    },
    personalCareAssistQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithOnePlaceHolderVal(
        GPC.PSNL_C_ADT,
        CNST_TEXT.DOES_THE_APPLICANT,
        CNST_TEXT.DO_YOU
      );
      return text;
    },
    currMeansTransQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithOnePlaceHolderVal(
        GPC.CUR_MN_TRP,
        CNST_TEXT.THE_APPLICANTS,
        CNST_TEXT.YOUR
      );
      return text;
    },
    transportationRule() {
      return () =>
        (this.applicantProfile.transpWaysList &&
          this.applicantProfile.transpWaysList.length > 0 &&
          this.applicantProfile.transpWaysList.filter(w => w && w !== "NULL")
            .length > 0) ||
        "At least one option must be selected";
    },
    transpOtherRule() {
      let otherText = this.applicantProfile.otherTranspWays
        ? this.applicantProfile.otherTranspWays.trim()
        : "";
      return () => !!otherText || "Required when Other is selected";
    }
  },
  created() {
    if (this.applicantProfile) {
      this.transportationAssistanceInd = this.applicantProfile.transportationAssistanceInd;
      this.travelAttendantInd = this.applicantProfile.travelAttendantInd;
      if (
        this.applicantProfile.transpWaysList &&
        this.applicantProfile.transpWaysList.length > 0
      ) {
        this.transpWays = tclone(this.applicantProfile.transpWaysList);
      }
    }
  },
  setup() {
    const { getAriaLabel } = helper();
    return { getAriaLabel };
  }
});
