var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('v-row',[_c('v-col',{staticClass:"px-0 py-0 px-md-16 py-md-1"},[_c('h6',[_vm._v("RFTP Program Information")]),_c('v-card',{staticClass:"borderRadius pa-6",attrs:{"width":"100%"}},[_c('v-row',[_c('v-col',[_c('readonlyField',{attrs:{"label":_vm.rftpFxdRouteIndDsplyNameQues,"value":_vm.largeText(_vm.draftProgramInfo.rftpApplyInd),"previous":_vm.largeText(_vm.programInfoPrevious.rftpApplyInd),"showPrevious":_vm.showPreviousValues}})],1)],1),(_vm.draftProgramInfo.rftpApplyInd === 'Y')?_c('v-row',[_c('v-col',[_c('readonlyField',{attrs:{"label":_vm.disabilityConfirmQues,"value":_vm.largeText(_vm.draftProgramInfo.disabilityInd),"previous":_vm.largeText(_vm.programInfoPrevious.disabilityInd),"showPrevious":_vm.showPreviousValues}})],1)],1):_vm._e(),(
            _vm.draftProgramInfo.disabilityInd === 'N' &&
              _vm.draftProgramInfo.rftpApplyInd === 'Y'
          )?_c('v-row',[_c('v-col',[_c('readonlyField',{attrs:{"label":_vm.rftpMedicareQues,"value":_vm.largeText(_vm.draftProgramInfo.medicareCardInd),"previous":_vm.largeText(_vm.programInfoPrevious.medicareCardInd),"showPrevious":_vm.showPreviousValues}})],1)],1):_vm._e(),(
            _vm.agencyProviders.length > 1 &&
              _vm.draftProgramInfo.disabilityInd === 'Y' &&
              _vm.draftProgramInfo.rftpApplyInd === 'Y'
          )?_c('v-row',[_c('v-col',[_c('readonlyField',{attrs:{"label":_vm.rftpProviderQues,"value":_vm.getAgencyAndAres(_vm.draftProgramInfo.rftpProviderAgencyCode),"previous":_vm.getAgencyAndAres(_vm.programInfoPrevious.rftpProviderAgencyCode),"showPrevious":_vm.showPreviousValues}})],1)],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }