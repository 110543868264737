
import Vue from "vue";
import store from "@/store";

import { IApplicantProfile, IDraftProgramInfo, IProgramsList } from "@/types";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import DynamicTextMixin from "@/mixins/DynamicTextMixin.vue";
import {
  PROGRAM_NAMES,
  APPL_STATUS,
  CNST_TEXT
} from "@/constants/CommonConstants";

export default Vue.extend({
  mixins: [UIFeatureMixinVue, DynamicTextMixin],
  computed: {
    applicantProfile: {
      get(): IApplicantProfile {
        //@ts-ignore
        return store.getters.applicantProfileModule.getApplicantProfile;
      }
    },
    eligiblePrograms: {
      get(): any {
        //@ts-ignore
        return store.getters.programEligModule.getProgramElig
          .preEligiblePrograms;
      }
    },
    eligPrograms: {
      get(): any {
        //@ts-ignore
        return store.getters.programEligModule.getProgramElig.eligiblePrograms;
      }
    },
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        //@ts-ignore
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      },
      set(newValue: IDraftProgramInfo) {
        //@ts-ignore
        store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(newValue);
      }
    },
    applicantProgramInfo: {
      get(): IProgramsList {
        return store.getters.applicantProgramModule.getApplicantProgram;
      }
    },
    agencyArray(): any {
      //@ts-ignore
      return this.eligiblePrograms.map(
        (o: { transitAgencyCode: string }) => o.transitAgencyCode
      );
    },
    isAttachmentsRequired(): boolean {
      return (
        this.showCommonPOD ||
        this.showCommonPODPWD ||
        this.showCustomCODADA ||
        this.showCustomCTDCMATPDoc ||
        this.showPCA ||
        this.showAgeProof ||
        this.showVTPPROOF ||
        this.showMCIPROOF ||
        this.showVisionForm ||
        this.showFuncVeriForm ||
        this.showRFTPForm
      );
    },

    showCommonPOD(): boolean {
      let showCommonPODSection = false;
      //@ts-ignore
      let agencyStaticArray: string[] = this.uiFeature.POD_AGNCYS.largeText.split(
        ","
      );
      let isEligibleProgramType = this.eligiblePrograms.find(
        (o: { programTypeCode: string }) =>
          o.programTypeCode === "ADA" ||
          o.programTypeCode === "PWD" ||
          o.programTypeCode === "RFTP"
      );
      let isRFTP = this.eligiblePrograms.find(
        (o: { programTypeCode: string }) => o.programTypeCode === "RFTP"
      );

      if (
        (agencyStaticArray.some(r => this.agencyArray.includes(r)) ||
          (this.agencyArray.includes("LNTA") &&
            isRFTP &&
            this.draftProgramInfo.rftpApplyInd !== "N")) &&
        isEligibleProgramType &&
        this.draftProgramInfo.disabilityInd === "Y" &&
        !this.showCustomCODADA &&
        !this.showCommonPODPWD &&
        !this.showCustomCTDCMATPDoc
      ) {
        showCommonPODSection = true;
      }
      return showCommonPODSection;
    },
    showCommonPODPWD(): boolean {
      let showCustomCodPwdSection = false;
      //@ts-ignore
      let transitAgencies: string[] = this.uiFeature.C_POD_PWD.largeText.split(
        ","
      );

      let isTransitAgencyPWD = this.eligiblePrograms.find(
        (o: { programTypeCode: string; transitAgencyCode: string }) =>
          (o.programTypeCode === PROGRAM_NAMES.PWD ||
            (o.programTypeCode === PROGRAM_NAMES.RFTP &&
              this.draftProgramInfo.disabilityInd === "Y")) &&
          transitAgencies.find(
            obj =>
              //@ts-ignore
              obj === o.transitAgencyCode
          )
      );

      //@ts-ignore
      let customDisabilityAgencies: string[] = this.uiFeature.C_COD_AGNY.largeText.split(
        ","
      );

      let isPWDNotQualified = this.eligiblePrograms.find(
        (o: { programTypeCode: string; statusCode: string }) =>
          (o.programTypeCode === PROGRAM_NAMES.PWD ||
            (o.programTypeCode === PROGRAM_NAMES.RFTP &&
              this.draftProgramInfo.disabilityInd !== "Y")) &&
          o.statusCode === APPL_STATUS.NOT_QUALIFIED
      );

      if (
        customDisabilityAgencies.some(agency =>
          this.agencyArray.includes(agency)
        ) &&
        !this.showCustomCODADA &&
        isTransitAgencyPWD &&
        !isPWDNotQualified
      ) {
        showCustomCodPwdSection = true;
      }
      return showCustomCodPwdSection;
    },
    showCustomCODADA(): boolean {
      let showCustomCodAdaSection = false;
      //@ts-ignore
      let transitAgencies: string[] = this.uiFeature.C_COD_ADA.largeText.split(
        ","
      );

      let isTransitAgencyADA = this.eligiblePrograms.find(
        (o: { programTypeCode: string; transitAgencyCode: string }) =>
          o.programTypeCode === PROGRAM_NAMES.ADA &&
          transitAgencies.find(
            obj =>
              //@ts-ignore
              obj === o.transitAgencyCode
          )
      );

      let isTransitAgencyRFTP = this.eligiblePrograms.find(
        (o: { programTypeCode: string; transitAgencyCode: string }) =>
          o.programTypeCode === PROGRAM_NAMES.RFTP &&
          transitAgencies.find(
            obj =>
              //@ts-ignore
              obj === o.transitAgencyCode
          )
      );

      let isFreedomMATP = this.checkFreedomMATP();

      //@ts-ignore
      let customDisabilityAgencies: string[] = this.uiFeature.C_COD_AGNY.largeText.split(
        ","
      );

      let isADANotQualified = this.eligiblePrograms.find(
        (o: { programTypeCode: string; statusCode: string }) =>
          o.programTypeCode === PROGRAM_NAMES.ADA &&
          o.statusCode === APPL_STATUS.NOT_QUALIFIED
      );

      let isRFTPNotQualified = this.eligiblePrograms.find(
        (o: { programTypeCode: string; statusCode: string }) =>
          o.programTypeCode === PROGRAM_NAMES.RFTP &&
          o.statusCode === APPL_STATUS.NOT_QUALIFIED
      );

      let isMATPDisability =
        this.draftProgramInfo.massTransitInabilityInd === "Y" ||
        this.draftProgramInfo.transportationDisabilityInd === "Y" ||
        this.draftProgramInfo.rideAloneInd === "Y";

      if (
        ((customDisabilityAgencies.some(agency =>
          this.agencyArray.includes(agency)
        ) &&
          ((isTransitAgencyADA && !isADANotQualified) ||
            this.checkCENCOTPWD() ||
            (isTransitAgencyRFTP && !isRFTPNotQualified))) ||
          (isFreedomMATP && isMATPDisability)) &&
        this.draftProgramInfo.disabilityInd !== "N" &&
        this.draftProgramInfo.draftFixedRouteUsageList !== null &&
        (this.draftProgramInfo.draftFixedRouteUsageList.length == 0 ||
          this.draftProgramInfo.draftFixedRouteUsageList[0]
            .providerAreaTravelInd === "Y" ||
          this.applicantProfile.gisCallInd !== "N")
      ) {
        showCustomCodAdaSection = true;
      }
      return showCustomCodAdaSection;
    },
    showCustomCTDCMATPDoc(): boolean {
      let showCustomCTDCMATPSection = false;
      if (
        this.draftProgramInfo.medAssistanceEligibilityInd === "Y" &&
        this.applicantProgramInfo.transitAgencyCode === "CTDC" &&
        this.applicantProgramInfo.programTypeCode === "MATP"
      ) {
        showCustomCTDCMATPSection = true;
      }
      return showCustomCTDCMATPSection;
    },
    showPCA(): boolean {
      let showPCASection = false;
      const disqualifyingStatuses = [
        APPL_STATUS.NOT_QUALIFIED,
        APPL_STATUS.NOT_START
      ];
      const hasADAWithRabbit = this.eligiblePrograms.find(
        (o: {
          programTypeCode: string;
          transitAgencyCode: string;
          statusCode: string;
        }) =>
          o.programTypeCode === PROGRAM_NAMES.ADA &&
          o.transitAgencyCode === "CPTA" &&
          !disqualifyingStatuses.includes(o.statusCode)
      );
      const hasPWDWithRabbit = this.eligiblePrograms.find(
        (o: {
          programTypeCode: string;
          transitAgencyCode: string;
          statusCode: string;
        }) =>
          o.programTypeCode === PROGRAM_NAMES.PWD &&
          o.transitAgencyCode === "CPTA" &&
          !disqualifyingStatuses.includes(o.statusCode)
      );

      const hasSSRorMATP = this.eligiblePrograms.find(
        (o: { programTypeCode: string; statusCode: string }) =>
          (o.programTypeCode === PROGRAM_NAMES.SSR ||
            o.programTypeCode === PROGRAM_NAMES.MATP) &&
          !disqualifyingStatuses.includes(o.statusCode)
      );

      if (
        (this.applicantProfile.travelAttendantInd === "Y" &&
          (this.agencyArray.includes("CPTA") ||
            this.agencyArray.includes("CAT")) &&
          ((!hasADAWithRabbit && !hasPWDWithRabbit) ||
            this.draftProgramInfo.disabilityInd === "N") &&
          hasSSRorMATP) ||
        this.activateADASEPTA ||
        this.activateHasAGNGorVTP
      ) {
        showPCASection = true;
      }
      return showPCASection;
    },
    activateADASEPTA(): boolean {
      const disqualifyingStatuses = [
        APPL_STATUS.NOT_QUALIFIED,
        APPL_STATUS.NOT_START
      ];
      const hasADAWithSEPTA = this.eligiblePrograms.find(
        (o: {
          programTypeCode: string;
          transitAgencyCode: string;
          statusCode: string;
        }) =>
          o.programTypeCode === PROGRAM_NAMES.ADA &&
          o.transitAgencyCode === "SEPTA" &&
          !disqualifyingStatuses.includes(o.statusCode)
      );
      return (
        (hasADAWithSEPTA ||
          (this.applicantProgramInfo.transitAgencyCode === "SEPTA" &&
            this.applicantProgramInfo.programTypeCode === PROGRAM_NAMES.ADA)) &&
        this.draftProgramInfo.disabilityInd === "Y" &&
        this.applicantProfile.travelAttendantInd === "Y"
      );
    },
    activateHasAGNGorVTP(): boolean {
      const disqualifyingStatuses = [
        APPL_STATUS.NOT_QUALIFIED,
        APPL_STATUS.NOT_START
      ];
      const hasAGNGorVTP = this.eligiblePrograms.find(
        (o: { programTypeCode: string; statusCode: string }) =>
          (o.programTypeCode === PROGRAM_NAMES.AGING ||
            o.programTypeCode === PROGRAM_NAMES.VTP) &&
          !disqualifyingStatuses.includes(o.statusCode)
      );
      return hasAGNGorVTP && this.applicantProfile.travelAttendantInd === "Y";
    },
    showAgeProof(): boolean {
      let showAgeProofSection = false;

      //@ts-ignore
      if (
        this.eligiblePrograms.find(
          (
            //@ts-ignore
            o: { programTypeCode: string }
          ) =>
            o.programTypeCode === "SSR" ||
            o.programTypeCode === "FTP" ||
            o.programTypeCode === "AGING"
          //@ts-ignore
        )
      ) {
        showAgeProofSection = this.showAgeProofExt();
      }
      return showAgeProofSection;
    },
    showVTPPROOF(): boolean {
      let showVTPPROOFSection = false;
      let hasVTP = this.eligiblePrograms.find(
        (o: { programTypeCode: string }) => o.programTypeCode === "VTP"
      );
      //@ts-ignore
      if (hasVTP && this.draftProgramInfo.veteranInd !== "N") {
        showVTPPROOFSection = true;
      }
      return showVTPPROOFSection;
    },
    showMCIPROOF(): boolean {
      let showMCIPROOFSection = false;
      let hasRFTP = this.eligiblePrograms.find(
        (o: { programTypeCode: string }) => o.programTypeCode === "RFTP"
      );

      if (
        this.draftProgramInfo.medicareCardInd === "Y" &&
        (hasRFTP ||
          (this.applicantProgramInfo.transitAgencyCode === "SEPTA" &&
            this.applicantProgramInfo.programTypeCode === PROGRAM_NAMES.RFTP &&
            this.draftProgramInfo.disabilityInd === "N" &&
            this.draftProgramInfo.visualDisabilityInd === "N"))
      ) {
        showMCIPROOFSection = true;
      }
      return showMCIPROOFSection;
    },
    showVisionForm(): boolean {
      let showVisionFormSection = false;

      //@ts-ignore
      let agencyStaticArray: string[] = this.uiFeature.C_COD_VIS.largeText.split(
        ","
      );

      const disqualifyingStatuses = [
        APPL_STATUS.NOT_QUALIFIED,
        APPL_STATUS.NOT_START
      ];

      const hasSEPTA = this.eligiblePrograms.find(
        (o: {
          programTypeCode: string;
          transitAgencyCode: string;
          statusCode: string;
        }) =>
          (o.programTypeCode === PROGRAM_NAMES.ADA ||
            o.programTypeCode === PROGRAM_NAMES.RFTP) &&
          o.transitAgencyCode === "SEPTA" &&
          !disqualifyingStatuses.includes(o.statusCode)
      );

      if (
        (hasSEPTA ||
          (this.applicantProgramInfo.transitAgencyCode === "SEPTA" &&
            (this.applicantProgramInfo.programTypeCode === PROGRAM_NAMES.ADA ||
              this.applicantProgramInfo.programTypeCode ===
                PROGRAM_NAMES.RFTP))) &&
        this.draftProgramInfo.disabilityInd === "Y" &&
        this.draftProgramInfo.visualDisabilityInd === "Y" &&
        agencyStaticArray.includes("SEPTA")
      ) {
        showVisionFormSection = true;
      }
      return showVisionFormSection;
    },
    showFuncVeriForm(): boolean {
      let showFuncVeriFormSection = false;
      const disqualifyingStatuses = [
        APPL_STATUS.NOT_QUALIFIED,
        APPL_STATUS.NOT_START
      ];

      const hasADAWithSEPTA = this.eligiblePrograms.find(
        (o: {
          programTypeCode: string;
          transitAgencyCode: string;
          statusCode: string;
        }) =>
          o.programTypeCode === PROGRAM_NAMES.ADA &&
          o.transitAgencyCode === "SEPTA" &&
          !disqualifyingStatuses.includes(o.statusCode)
      );

      if (
        (hasADAWithSEPTA ||
          (this.applicantProgramInfo.transitAgencyCode === "SEPTA" &&
            this.applicantProgramInfo.programTypeCode === PROGRAM_NAMES.ADA)) &&
        this.draftProgramInfo.disabilityInd === "Y"
      ) {
        showFuncVeriFormSection = true;
      }
      return showFuncVeriFormSection;
    },
    showRFTPForm(): boolean {
      let showRFTPFormSection = false;
      const disqualifyingStatuses = [
        APPL_STATUS.NOT_QUALIFIED,
        APPL_STATUS.NOT_START
      ];

      const hasRFTPWithSEPTA = this.eligiblePrograms.find(
        (o: {
          programTypeCode: string;
          transitAgencyCode: string;
          statusCode: string;
        }) =>
          o.programTypeCode === PROGRAM_NAMES.RFTP &&
          o.transitAgencyCode === "SEPTA" &&
          !disqualifyingStatuses.includes(o.statusCode)
      );

      if (
        (hasRFTPWithSEPTA ||
          (this.applicantProgramInfo.transitAgencyCode === "SEPTA" &&
            this.applicantProgramInfo.programTypeCode ===
              PROGRAM_NAMES.RFTP)) &&
        this.draftProgramInfo.rftpApplyInd === "Y" &&
        this.draftProgramInfo.disabilityInd === "Y"
      ) {
        showRFTPFormSection = true;
      }
      return showRFTPFormSection;
    },
    showCustomCODADACencotPWD(): boolean {
      return this.showCustomCODADA && this.checkCENCOTPWD();
    },
    getNumberOfRequiredDocs(): any {
      var numberOfDocs = 0;

      if (
        this.showCommonPOD ||
        this.showCommonPODPWD ||
        this.showCustomCODADA ||
        this.showCustomCTDCMATPDoc
      ) {
        //at a time only one of these will be true
        numberOfDocs++;
        // If custom ADA applied for Center County CENCOT, check for PWD for same county and another attachment applies.
        if (this.showCustomCODADA && this.showCustomCODADACencotPWD) {
          numberOfDocs++;
        }
      }
      if (this.showPCA) {
        numberOfDocs++;
      }
      if (this.showAgeProof) {
        numberOfDocs++;
      }
      if (this.showVTPPROOF) {
        numberOfDocs++;
      }
      if (this.showMCIPROOF) {
        numberOfDocs++;
      }
      if (this.showVisionForm) {
        numberOfDocs++;
      }
      if (this.showFuncVeriForm) {
        numberOfDocs++;
      }
      if (this.showRFTPForm) {
        numberOfDocs++;
      }
      return numberOfDocs;
    },
    showIndvFlowSEPTAPCA(): boolean {
      let showPCASection = false;
      if (
        this.applicantProgramInfo.transitAgencyCode === "SEPTA" &&
        this.applicantProgramInfo.programTypeCode === PROGRAM_NAMES.ADA &&
        this.draftProgramInfo.disabilityInd === "Y" &&
        this.applicantProfile.travelAttendantInd === "Y"
      ) {
        showPCASection = true;
      }
      return showPCASection;
    },
    getDocCountforADAIndvFlowSepta(): any {
      let numberOfDocs = 1; // ADA already comes with one default attachment.
      if (
        !this.isCustomDisabilityAgency() &&
        this.applicantProgramInfo.transitAgencyCode === "SEPTA"
      ) {
        numberOfDocs = 0;
      }
      if (this.showIndvFlowSEPTAPCA) {
        numberOfDocs++;
      }
      if (this.showFuncVeriForm) {
        numberOfDocs++;
      }
      if (this.showVisionForm) {
        numberOfDocs++;
      }
      return numberOfDocs;
    }
  },
  methods: {
    showAgeProofExt(): boolean {
      let showAgeProofSection = false;
      let hasSSR = this.eligiblePrograms.find(
        (o: { programTypeCode: string }) =>
          o.programTypeCode === "SSR" || o.programTypeCode === "AGING"
      );
      let hasFTP = this.eligiblePrograms.find(
        (o: { programTypeCode: string }) => o.programTypeCode === "FTP"
      );
      if (hasSSR && hasSSR.byPassInd === "Y") {
        if (hasFTP && this.draftProgramInfo.ftpApplicationInd !== "N") {
          if (
            !(
              this.draftProgramInfo.driverLicenseVerifyCode === "VRFD" ||
              (hasSSR && hasSSR.programTypeCode === "AGING")
            )
          ) {
            showAgeProofSection = true;
          }
        }
      } else {
        if (this.draftProgramInfo.driverLicenseVerifyCode !== "VRFD") {
          showAgeProofSection = true;
        }
      }
      return showAgeProofSection;
    },
    isCustomDisabilityAgency() {
      //@ts-ignore
      let customDisabilityAgencies: string[] = this.uiFeature.C_COD_AGNY.largeText.split(
        ","
      );
      return customDisabilityAgencies.includes(
        this.applicantProgramInfo.transitAgencyCode
      );
    },
    isCustomDisabilityAgencyPWD() {
      //@ts-ignore
      let customDisabilityAgencies: string[] = this.uiFeature.C_COD_PWD.largeText.split(
        ","
      );
      return customDisabilityAgencies.includes(
        this.applicantProgramInfo.transitAgencyCode
      );
    },
    checkFreedomMATP() {
      return this.eligiblePrograms.find(
        (o: { programTypeCode: string; transitAgencyCode: string }) =>
          o.programTypeCode === PROGRAM_NAMES.MATP &&
          o.transitAgencyCode === "FREEDOM"
      );
    },
    checkCENCOTPWD() {
      return this.eligiblePrograms.find(
        (o: { programTypeCode: string; transitAgencyCode: string }) =>
          o.programTypeCode === PROGRAM_NAMES.PWD &&
          o.transitAgencyCode === "CENCOT"
      );
    },
    getMATPDocumentTypes(): string[] {
      let docTypeCodes: string[] = [];
      if (
        this.applicantProfile.travelAttendantInd === "Y" &&
        (this.applicantProgramInfo.transitAgencyCode === "CPTA" ||
          this.applicantProgramInfo.transitAgencyCode === "CAT")
      ) {
        //@ts-ignore
        docTypeCodes = this.uiFeature.DOCS_MATP.largeText.split(",");
      } else if (
        this.applicantProgramInfo.transitAgencyCode === "FREEDOM" &&
        (this.draftProgramInfo.massTransitInabilityInd === "Y" ||
          this.draftProgramInfo.transportationDisabilityInd === "Y" ||
          this.draftProgramInfo.rideAloneInd === "Y")
      ) {
        //@ts-ignore
        docTypeCodes = this.uiFeature.DOCS_MATP.displayName.split(",");
      } else if (
        this.draftProgramInfo.medAssistanceEligibilityInd === "Y" &&
        this.applicantProgramInfo.transitAgencyCode === "CTDC"
      ) {
        //@ts-ignore
        docTypeCodes = this.uiFeature.DOCS_MATP.displayName.split(",");
      }
      return docTypeCodes;
    },
    getFTPDocumentTypes(): string[] {
      //@ts-ignore
      return this.uiFeature.DOCS_FTP.largeText.split(",");
    },
    getSSRDocumentTypes(): string[] {
      //@ts-ignore
      return this.uiFeature.DOCS_SSR.largeText.split(",");
    },
    getPWDDocumentTypes(): string[] {
      let docTypeCodes: string[] = [];
      if (
        //@ts-ignore
        this.isCustomDisabilityAgency() ||
        //@ts-ignore
        this.isCustomDisabilityAgencyPWD()
      ) {
        //@ts-ignore
        docTypeCodes = this.uiFeature.DOCS_PWD.displayName.split(",");
      } else {
        //@ts-ignore
        docTypeCodes = this.uiFeature.DOCS_PWD.largeText.split(",");
      }
      return docTypeCodes;
    },
    getADADocumentTypes(): string[] {
      let docTypeCodes: string[] = [];
      //@ts-ignore
      if (this.isCustomDisabilityAgency()) {
        //@ts-ignore
        docTypeCodes = this.uiFeature.DOCS_ADA.displayName.split(",");
      } else {
        //@ts-ignore
        docTypeCodes = this.uiFeature.DOCS_ADA.largeText.split(",");
      }

      if (this.showIndvFlowSEPTAPCA) {
        docTypeCodes.push("PRF_PRCARE");
      }

      if (this.showFuncVeriForm) {
        docTypeCodes.push("FV_FORM");
      }

      if (this.showVisionForm) {
        docTypeCodes.push("VIS_FORM");
      }
      return docTypeCodes;
    },
    getVTPDocumentTypes(): string[] {
      //@ts-ignore
      return this.uiFeature.DOCS_VTP.largeText.split(",");
    },
    getRFTPDocumentTypes(): string[] {
      let docTypeCodes: string[] = [];
      //@ts-ignore
      docTypeCodes = this.uiFeature.DOCS_RFTP.displayName.split(",");
      if (this.draftProgramInfo.medicareCardInd !== "N") {
        //@ts-ignore
        docTypeCodes.push(this.uiFeature.DOCS_RFTP.largeText.split(","));
      }
      if (this.showRFTPForm) {
        docTypeCodes.push("RFTP_CST_D");
      }

      if (this.showVisionForm) {
        docTypeCodes.push("VIS_FORM");
      }

      return docTypeCodes;
    },
    getDocTypeCodesApplied(): string[] {
      let docTypeCodes: string[] = [];
      if (this.showCommonPOD || this.showCustomCODADACencotPWD) {
        docTypeCodes.push("PRF_DSBLTY");
      }
      if (this.showCommonPODPWD || this.showCustomCODADA) {
        docTypeCodes.push("CST_DSBLTY");
      }
      if (this.showCustomCTDCMATPDoc) {
        docTypeCodes.push("MATP_FORM");
      }
      if (this.showPCA) {
        docTypeCodes.push("PRF_PRCARE");
      }
      if (this.showVTPPROOF) {
        docTypeCodes.push("VTP_PROOF");
      }
      if (this.showMCIPROOF) {
        docTypeCodes.push("MCI_PROOF");
      }
      if (this.showAgeProof) {
        docTypeCodes.push("AGE_PROOF");
      }
      if (this.showVisionForm) {
        docTypeCodes.push("VIS_FORM");
      }
      if (this.showFuncVeriForm) {
        docTypeCodes.push("FV_FORM");
      }
      if (this.showRFTPForm) {
        docTypeCodes.push("RFTP_CST_D");
      }
      if (this.showIndvFlowSEPTAPCA) {
        docTypeCodes.push("PRF_PRCARE");
      }

      docTypeCodes.push(this.getDocCodesForADACustomDsbltyIndvFlow());
      return docTypeCodes;
    },
    getDocCodesForADACustomDsbltyIndvFlow(): string {
      let abc = "";
      if (
        this.showIndvFlowSEPTAPCA ||
        this.showFuncVeriForm ||
        this.showVisionForm
      ) {
        if (this.isCustomDisabilityAgency()) {
          abc = "CST_DSBLTY";
        } else {
          abc = "PRF_DSBLTY";
        }
      }
      return abc;
    },
    getCodDocDynamicText(largeText: string): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrgAgyCtznonBehalfOfUser()
      ) {
        //@ts-ignore
        text = this.buildDynamicText(largeText, CNST_TEXT.THE_APPLICANT_IS);
      } else {
        //@ts-ignore
        text = this.buildDynamicText(largeText, CNST_TEXT.YOU_ARE);
      }
      return text;
    }
  }
});
