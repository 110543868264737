import {
  IApplicantMobilityDevice,
  IApplicantProfile,
  IApplicantAddress,
  IDrivingLicense
} from "@/types";
import { applicantProfileDefault, demographicInfo } from "@/data/AppData";
import { HTTP_STATUS } from "@/constants/HttpStatusCode";
import { ADDRESS_TYPE } from "@/constants/CommonConstants";
import { clone } from "@/util/clone";
import { moduleActionContext } from "..";
import {
  APP_REVIEW,
  DASHBOARD_ENDPOINT,
  DL_ENDPOINT
} from "@/constants/EndPoints";
import { defineModule } from "direct-vuex";
import axios from "axios";

export interface ApplicantProfileState {
  applicantProfile: IApplicantProfile;
  applicantProfilePrevious: IApplicantProfile;
  showPreviousValues: boolean;
}

const applicantProfileModule = defineModule({
  namespaced: true,
  state: {
    applicantProfile: clone(applicantProfileDefault),
    applicantProfilePrevious: clone(applicantProfileDefault),
    showPreviousValues: false
  } as ApplicantProfileState,
  getters: {
    getApplicantProfile: (state: ApplicantProfileState) =>
      state.applicantProfile,
    getApplicantProfilePrevious: (state: ApplicantProfileState) =>
      state.applicantProfilePrevious,
    getShowPreviousValues: (state: ApplicantProfileState) =>
      state.showPreviousValues
  },
  mutations: {
    SET_APPLICANT_PROFILE(
      state: ApplicantProfileState,
      applicantProfileObject: IApplicantProfile
    ) {
      state.applicantProfile = applicantProfileObject;
    },
    SET_APPLICANT_PROFILE_PREVIOUS(
      state: ApplicantProfileState,
      applicantProfileObject: IApplicantProfile
    ) {
      state.showPreviousValues = applicantProfileObject.tesApplicationId !== 0;
      state.applicantProfilePrevious = applicantProfileObject;
      state.applicantProfilePrevious.applicantMobilityDevices = clone(
        demographicInfo
      );
    },
    SET_APPLICANT_ADDRESS_HOME_PREVIOUS(
      state: ApplicantProfileState,
      address: IApplicantAddress
    ) {
      state.applicantProfilePrevious.homeAddress = address;
    },
    SET_APPLICANT_ADDRESS_MAILING_PREVIOUS(
      state: ApplicantProfileState,
      address: IApplicantAddress
    ) {
      state.applicantProfilePrevious.mailingAddress = address;
    },
    SET_APPLICANT_PROFILE_MOBILITY_PREVIOUS(
      state: ApplicantProfileState,
      applicantProfileMobilityObject: IApplicantMobilityDevice
    ) {
      state.applicantProfilePrevious.applicantMobilityDevices = applicantProfileMobilityObject;
    },
    SET_APPLICANT_PROFILE_DL(
      state: ApplicantProfileState,
      driverLicense: IDrivingLicense
    ) {
      state.applicantProfile.drivingLicenseModel = driverLicense;
    }
  },
  actions: {
    resetData(context: any) {
      let { commit } = applicantProfileContext(context);
      //clearing the applicantProfile data in store.
      commit.SET_APPLICANT_PROFILE(clone(applicantProfileDefault));
      commit.SET_APPLICANT_PROFILE_PREVIOUS(clone(applicantProfileDefault));
    },
    populateProfilePrevious(context: any) {
      let { commit, dispatch, rootDispatch } = applicantProfileContext(context);
      axios
        .get(APP_REVIEW.GET_APP_PROFILE, {
          params: {
            tesAppId: context.state.applicantProfile.applicationId,
            cycleNumber: context.state.applicantProfile.cycleNumber - 1
          }
        })
        .then(response => {
          commit.SET_APPLICANT_PROFILE_PREVIOUS(response.data);
          dispatch.populateProfileMobilityPrevious();
          dispatch.populateAddressPrevious(ADDRESS_TYPE.HOME);
          dispatch.populateAddressPrevious(ADDRESS_TYPE.MAIL);
        })
        .catch(() => {
          rootDispatch.alertModule.showAlertMessageByGPC("ER_INLINE");
        });
    },
    populateAddressPrevious(context: any, type: string) {
      let { commit, rootDispatch } = applicantProfileContext(context);

      axios
        .get(APP_REVIEW.GET_APP_ADDRESS, {
          params: {
            tesAppId: context.state.applicantProfile.applicationId,
            cycleNumber: context.state.applicantProfile.cycleNumber - 1,
            type: type
          }
        })
        .then(response => {
          if (response.status !== HTTP_STATUS.NO_CONTENT) {
            if (type === ADDRESS_TYPE.HOME) {
              commit.SET_APPLICANT_ADDRESS_HOME_PREVIOUS(response.data);
            } else {
              commit.SET_APPLICANT_ADDRESS_MAILING_PREVIOUS(response.data);
            }
          }
        })
        .catch(() => {
          rootDispatch.alertModule.showAlertMessageByGPC("ER_INLINE");
        });
    },
    populateProfileMobilityPrevious(context: any) {
      let { commit, rootDispatch } = applicantProfileContext(context);

      axios
        .get(APP_REVIEW.GET_APP_PROFILE_MOBILITY, {
          params: {
            tesAppId: context.state.applicantProfile.applicationId,
            cycleNumber: context.state.applicantProfile.cycleNumber - 1
          }
        })
        .then(response => {
          commit.SET_APPLICANT_PROFILE_MOBILITY_PREVIOUS(response.data);
        })
        .catch(() => {
          rootDispatch.alertModule.showAlertMessageByGPC("ER_INLINE");
        });
    },
    async getProfileInfo(context: any, profileID: any) {
      let { commit, rootDispatch } = applicantProfileContext(context);
      await axios
        .get(DASHBOARD_ENDPOINT.GET_APPLICANT_PROFILE, {
          params: {
            applicantProfileId: profileID
          }
        })
        .then(response => {
          commit.SET_APPLICANT_PROFILE(response.data);
        })
        .catch(() => {
          rootDispatch.alertModule.showAlertMessageByGPC("ER_INLINE");
        });
    },
    getDriverLicenseDetails(context: any, draftProgramInfo: any) {
      let { commit, rootDispatch } = applicantProfileContext(context);
      let dlVerifyCode = draftProgramInfo.driverLicenseVerifyCode;
      if (dlVerifyCode && dlVerifyCode === "VRFD") {
        let dlNumber = draftProgramInfo.driverLicenseNumber;
        axios
          .get(DL_ENDPOINT.GET_DL_DETAILS, {
            params: {
              dlNumber: dlNumber
            }
          })
          .then(response => {
            commit.SET_APPLICANT_PROFILE_DL(response.data);
          })
          .catch(() => {
            rootDispatch.alertModule.showAlertMessageByGPC("ER_INLINE");
          });
      }
    }
  }
});

export default applicantProfileModule;
const applicantProfileContext = (context: any) =>
  moduleActionContext(context, applicantProfileModule);
