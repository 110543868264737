
import Vue from "vue";
import reviewProfileInfo from "@/components/application/profilereview/ReviewProfileInfo.vue";
import ApplicationResult from "@/components/application/program/ApplicationResult.vue";
import editProfileProgramChangesMsg from "@/components/application/EditProfileProgramChangesMsg.vue";
import store from "@/store";
import {
  IDraftProgramInfo,
  IProgramsList,
  IStepperHeaderList,
  ITESUser,
  IApplicantProfile,
  IApplicantEcolaneProfileModel,
  IApplicationReviewModel
} from "@/types";
import EventHistory from "@/components/common/EventHistory.vue";
import { HTTP_STATUS } from "@/constants/HttpStatusCode";
import EventHistoryMixinVue from "@/mixins/EventHistoryMixin.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import StepperNavigationMixinVue from "@/mixins/StepperNavigationMixin.vue";
import dateHelperMixinVue from "@/mixins/dateHelperMixin.vue";
import StartReviewVue from "@/components/application/program/StartReview.vue";
import SummaryReportCreator from "@/components/common/SummaryReportCreator.vue";
import {
  USER_GROUPS,
  APPL_STATUS,
  expansionPanelIconObj
} from "@/constants/CommonConstants";
import { TES_APP_ENDPOINT } from "@/constants/EndPoints";
import axios from "axios";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import AgencyReviewMixin from "@/mixins/AgencyReviewMixin.vue";
import ExpansionPanelTemplate from "@/components/common/ExpansionPanelTemplate.vue";

export default Vue.extend({
  name: "Review-Application-Information",
  mixins: [
    EventHistoryMixinVue,
    UIFeatureMixinVue,
    StepperNavigationMixinVue,
    dateHelperMixinVue,
    ApplicationMixin,
    AppPropsMixin,
    AgencyReviewMixin
  ],
  components: {
    reviewProfileInfo,
    ApplicationResult,
    editProfileProgramChangesMsg,
    EventHistory,
    StartReviewVue,
    SummaryReportCreator,
    ExpansionPanelTemplate
  },
  data() {
    return {
      seen: false as boolean,
      showhideText: "View Details" as string,
      showHideIcon: "fa-arrow-alt-circle-right" as string,
      showDialog: false,
      eventLogs: [],
      singleFlow: false,
      createdByLoginId: "" as string,
      iconTextLabel: {
        showText: expansionPanelIconObj.showText,
        hideText: expansionPanelIconObj.hideText
      }
    };
  },
  props: {
    applicationReview: { type: Boolean, default: true }
  },
  computed: {
    mainHeaderText(): string {
      let titleType: string = "Application Summary for";

      if (
        this.userInfo.userGroups === USER_GROUPS.PD_TES_AGREVIEWER &&
        this.applicantProgramInfo.statusCode === APPL_STATUS.UNDER_REVIEW
      ) {
        titleType = "Review Application for";
      }

      return `${titleType} ${this.applicantProfile.applicantFirstName} ${this.applicantProfile.applicantLastName}`;
    },
    applicantProfile(): IApplicantProfile {
      return store.getters.applicantProfileModule.getApplicantProfile;
    },
    applicantProgramInfo(): IProgramsList {
      return store.getters.applicantProgramModule.getApplicantProgram;
    },
    draftProgramInfo(): IDraftProgramInfo {
      return store.getters.draftProgramInfoModule.getDraftProgramInfo;
    },
    counties(): [] {
      return store.getters.dataModule.getCounties;
    },
    userInfo(): ITESUser {
      return store.getters.tesUserModule.getTesUser;
    },
    stepperHeaderMod: {
      get(): IStepperHeaderList {
        return store.getters.stepperHeaderModule.getStepperHeaderModel;
      },
      set(newValue: IStepperHeaderList) {
        store.commit.stepperHeaderModule.SET_STEPPER_HEADER_MODEL(newValue);
      }
    },
    selectedEcolaneCustomer(): IApplicantEcolaneProfileModel {
      return store.getters.applicantEcolaneProfileModule
        .getApplicantEcolaneProfile;
    },
    isAppRvwModelLoaded(): boolean {
      return store.getters.applicationReviewModule.getIsAppRvwModelLoaded;
    },
    applicationReviewModel: {
      get(): IApplicationReviewModel {
        //@ts-ignore
        return store.getters.applicationReviewModule.getApplicationReviewModel;
      }
    },
    isAgencySubmitted(): boolean {
      return (
        (this.applicantProgramInfo.statusCode === "SBMTD" ||
          this.applicantProgramInfo.statusCode === "RE_COR" ||
          this.applicantProgramInfo.statusCode === "PEND_CONF" ||
          this.applicantProgramInfo.statusCode === "INCOMPLETE" ||
          this.applicantProgramInfo.statusCode === "APEL_INPRG") &&
        //@ts-ignore
        this.isAgencyUser
      );
    },
    isRevwElgbl(): boolean {
      let result = false;
      let agencyStaticArray: string[] = this.applicantProgramInfo.reviewAgency.split(
        ","
      );

      if (
        agencyStaticArray &&
        agencyStaticArray.includes(this.userInfo.transitAgencyCode)
      ) {
        result = true;
      }
      return result;
    },
    isStrtRvwElgbl(): any {
      return (
        this.applicantProgramInfo.applicationId &&
        !this.isAppRvwModelLoaded &&
        this.applicantProgramInfo.transitAgencyCode &&
        this.userInfo.transitAgencyCode &&
        this.isRevwElgbl &&
        (this.applicantProgramInfo.statusCode === APPL_STATUS.UNDER_REVIEW ||
          this.applicantProgramInfo.statusCode === APPL_STATUS.SUBMITTED ||
          this.applicantProgramInfo.statusCode === APPL_STATUS.TMP_APRVD ||
          this.applicantProgramInfo.statusCode === APPL_STATUS.PENDING ||
          this.applicantProgramInfo.statusCode === APPL_STATUS.APEL_INPRG ||
          this.applicantProgramInfo.statusCode ===
            APPL_STATUS.PENDING_CONFIGURATION) &&
        //@ts-ignore
        (this.isAgencySupervisor ||
          //@ts-ignore
          this.isReviewerSelfAsgndUser ||
          (this.applicationReviewModel &&
            this.applicationReviewModel.reviewerUserId ===
              this.userInfo.userId))
      );
    },
    showAppHistory(): boolean {
      //@ts-ignore
      return this.isCOReadOnlyUser || this.isAgencyUser;
    }
  },
  created() {
    //@ts-ignore
    if (this.isSingleFlow()) {
      this.singleFlow = true;
    }
    if (this.applicantProgramInfo.createdByUserId) {
      this.getUserInfoById();
    }

    if (
      this.applicantProgramInfo.statusCode &&
      this.applicantProgramInfo.statusCode !== APPL_STATUS.SUBMITTED &&
      this.applicantProgramInfo.statusCode !== APPL_STATUS.IN_PROGRESS &&
      this.applicantProgramInfo.statusCode !== APPL_STATUS.READY_TO_SUMBMIT &&
      this.applicantProgramInfo.statusCode !== APPL_STATUS.NOT_QUALIFIED &&
      this.applicantProgramInfo.statusCode !== APPL_STATUS.NOT_START &&
      //@ts-ignore
      !this.isSingleFlow()
    ) {
      let params = {
        applicantProfileId: this.applicantProgramInfo.applicantProfileId,
        transitAgencyCode: this.applicantProgramInfo.transitAgencyCode
      };
      store.dispatch.applicantEcolaneProfileModule.loadApplicantEcolaneProfile(
        params
      );
    }
  },
  methods: {
    showHide() {
      if (!this.seen) {
        this.seen = true;
        this.showhideText = "Hide Details";
      } else {
        this.seen = false;
        this.showhideText = "View Details";
      }
    },
    getEventHistoryDialog() {
      //@ts-ignore
      this.getEventHistory().then(response => {
        if (response.status === HTTP_STATUS.OK) {
          this.eventLogs = response.data;
          this.showDialog = true;
        }
      });
    },
    closeEventHistoryDialog() {
      this.showDialog = false;
    },
    getCountyName(paCountyCode: string) {
      let obj = this.counties.find(
        obj1 =>
          //@ts-ignore
          obj1.id === paCountyCode
      );
      //@ts-ignore
      return obj ? obj.name : "";
    },
    getUserInfoById() {
      let url = TES_APP_ENDPOINT.GET_USERINFO_BY_ID;
      axios
        .get(url, {
          params: {
            userId: this.applicantProgramInfo.createdByUserId
          }
        })
        .then(response => {
          this.createdByLoginId = response.data.loginId;
          if (this.createdByLoginId) {
            store.dispatch.isamUserAttributesModule.getISAMUserAttributes(
              this.createdByLoginId
            );
          }
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        });
    }
  }
});
