
import Vue from "vue";
import { TES_APP_ENDPOINT } from "@/constants/EndPoints";
import axios from "axios";
import router from "@/router";
import {
  IApplicantMobilityDevice,
  IApplicantProfile,
  IProgramsList,
  IApplicationNavigation,
  ITESUser,
  IScreeningResponse
} from "@/types";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import store from "@/store";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import tesControlTemplate from "@/components/common/TESControlTemplate.vue";
import TESRadioControl from "@/components/common/TESRadioControl.vue";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import ExpansionPanelTemplate from "@/components/common/ExpansionPanelTemplate.vue";
import { expansionPanelIconObj, CNST_TEXT } from "@/constants/CommonConstants";
import AccessibilityMixin from "@/mixins/AccessibilityMixin.vue";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";
import { GPC } from "@/constants/GPCCode";
import DynamicTextMixin from "@/mixins/DynamicTextMixin.vue";
import helper from "@/composables/helper";

export default Vue.extend({
  name: "demographic-info",
  mixins: [
    fieldEntryMixin,
    UIFeatureMixinVue,
    AppPropsMixin,
    AccessibilityMixin,
    ApplicationMixin,
    DynamicTextMixin
  ],
  components: {
    TESRadioControl,
    tesControlTemplate,
    ExpansionPanelTemplate
  },
  data() {
    return {
      selectedLanguage: "" as string,
      interpreterInd: "" as string,
      selectedRace: "" as string,
      nursingHomeInd: "" as string,
      nursingHomeDischargeInd: "" as string,
      assistedLivingInd: "" as string,
      assistedLivingTransportInd: "" as string,
      applicantMobilityModel: {} as IApplicantMobilityDevice,
      expPanelIconObj: expansionPanelIconObj
    };
  },
  methods: {
    saveDemographicInfo() {
      let url = TES_APP_ENDPOINT.SAVE_DEMOGRPHIC_INFO;
      this.populatePersonalInfo();

      axios
        .post(url, this.applicantProfile)
        .then(response => {
          this.applicantProfile = response.data;
          //@ts-ignore
          store.commit.applicantProfileModule.SET_APPLICANT_PROFILE(
            this.applicantProfile
          );
          if (this.applicationNavigation.applProfileEdit) {
            router.push({
              path:
                "/application-review/" +
                this.applicantProgramInfo.programTypeCode
            });
          } else {
            router.push({ name: "reviewProfileInfo" });
          }
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        });
    },
    resetValues() {
      if (this.selectedLanguage === "ENG") {
        this.interpreterInd = "";
      }
      if (this.nursingHomeInd === "N") {
        this.nursingHomeDischargeInd = "";
      }
      if (this.assistedLivingInd === "N") {
        this.assistedLivingTransportInd = "";
      }
    },
    populatePersonalInfo() {
      this.applicantProfile.primaryLanguageCode = this.selectedLanguage;
      this.applicantProfile.interpreterInd = this.interpreterInd;
      this.applicantProfile.ethnicityCode = this.selectedRace;
      this.applicantProfile.nursingHomeInd = this.nursingHomeInd;
      this.applicantProfile.nursingHomeDischargeInd = this.nursingHomeDischargeInd;
      this.applicantProfile.assistedLivingInd = this.assistedLivingInd;
      this.applicantProfile.assistedLivingTransportInd = this.assistedLivingTransportInd;

      //@ts-ignore
      store.commit.applicantProfileModule.SET_APPLICANT_PROFILE(
        this.applicantProfile
      );
    }
  },
  computed: {
    applicantProfile: {
      get(): IApplicantProfile {
        //@ts-ignore
        return store.getters.applicantProfileModule.getApplicantProfile;
      },
      set(newValue: IApplicantProfile) {
        //@ts-ignore
        store.commit.applicantProfileModule.SET_APPLICANT_PROFILE(newValue);
      }
    },
    applicationNavigation: {
      get(): IApplicationNavigation {
        //@ts-ignore
        return store.getters.applicationNavigationModule
          .getApplicationNavigation;
      }
    },
    applicantProgramInfo: {
      get(): IProgramsList {
        //@ts-ignore
        return store.getters.applicantProgramModule.getApplicantProgram;
      }
    },
    userInfo: {
      get(): ITESUser {
        //@ts-ignore
        return store.getters.tesUserModule.getTesUser;
      }
    },
    languages(): any {
      //@ts-ignore
      return this.uiFeature.LANGUAGES.children;
    },
    race(): any {
      //@ts-ignore
      return this.uiFeature.RACE.children;
    },
    screeningResponse(): IScreeningResponse {
      return store.getters.screeningResponseModule.getScreeningResponse;
    },
    demoGraphicInfoText(): string {
      let demographicText: string = "";
      //@ts-ignore
      if (this.isOrganizationUser || this.isAgencyUser) {
        //@ts-ignore
        demographicText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.DEMOG_INFO].largeText,
          CNST_TEXT.THE_APPLICANT,
          CNST_TEXT.THE_PROVIDER,
          CNST_TEXT.THEM
        );
        //@ts-ignore
      } else if (this.isCitizenUser) {
        if (this.screeningResponse.onBehalfOfInd === "Y") {
          //@ts-ignore
          demographicText = this.buildDynamicText(
            //@ts-ignore
            this.uiFeature[GPC.DEMOG_INFO].largeText,
            CNST_TEXT.THE_APPLICANT,
            CNST_TEXT.THE_PROVIDER,
            CNST_TEXT.THEM
          );
        } else {
          //@ts-ignore
          demographicText = this.buildDynamicText(
            //@ts-ignore
            this.uiFeature[GPC.DEMOG_INFO].largeText,
            CNST_TEXT.YOU,
            CNST_TEXT.THEY,
            CNST_TEXT.YOU
          );
        }
      }
      return demographicText;
    },
    primaryLanguageQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithOnePlaceHolderVal(
        GPC.PRMRY_LNG,
        CNST_TEXT.THE_APPLICANTS,
        CNST_TEXT.YOUR
      );
      return text;
    },
    interpreterQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithOnePlaceHolderVal(
        GPC.LNG_INTRPR,
        CNST_TEXT.DOES_THE_APPLICANT,
        CNST_TEXT.DO_YOU
      );
      return text;
    },
    nursingHomeQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithOnePlaceHolderVal(
        GPC.NRG_HME,
        CNST_TEXT.DOES_THE_APPLICANT,
        CNST_TEXT.DO_YOU
      );
      return text;
    },
    raceEthnicityQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithOnePlaceHolderVal(
        GPC.RCE_ETNCTY,
        CNST_TEXT.THE_APPLICANTS,
        CNST_TEXT.YOUR
      );
      return text;
    },
    nursingHomeDischargeQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithOnePlaceHolderVal(
        GPC.DCRG_NG_HM,
        CNST_TEXT.IS_THE_APPLICANT,
        CNST_TEXT.ARE_YOU
      );
      return text;
    },
    perCareAssistQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithOnePlaceHolderVal(
        GPC.PSNL_CR_HM,
        CNST_TEXT.DOES_THE_APPLICANT,
        CNST_TEXT.DO_YOU
      );
      return text;
    },
    perCareAsstTransQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithOnePlaceHolder(
        GPC.PNL_CHM_TP,
        CNST_TEXT.THE_APPLICANTS,
        CNST_TEXT.YOUR
      );
      return text;
    }
  },
  created() {
    if (this.applicantProfile) {
      this.selectedLanguage = this.applicantProfile.primaryLanguageCode;
      this.interpreterInd = this.applicantProfile.interpreterInd;
      this.selectedRace = this.applicantProfile.ethnicityCode;
      this.nursingHomeInd = this.applicantProfile.nursingHomeInd;
      this.nursingHomeDischargeInd = this.applicantProfile.nursingHomeDischargeInd;
      this.assistedLivingInd = this.applicantProfile.assistedLivingInd;
      this.assistedLivingTransportInd = this.applicantProfile.assistedLivingTransportInd;
    }
  },
  updated() {
    if (this.$refs.demographicLanguageRef) {
      //@ts-ignore
      this.setAriaAttributesOfAutoComplete(
        this.$refs.demographicLanguageRef,
        "demographic_select_language_id"
      );
    }
    if (this.$refs.demographicEthnicityRef) {
      //@ts-ignore
      this.setAriaAttributesOfAutoComplete(
        this.$refs.demographicEthnicityRef,
        "demographic_select_ethnicity_id"
      );
    }
  },
  setup() {
    const { getAriaLabel } = helper();
    return { getAriaLabel };
  }
});
