
import Vue from "vue";
import axios from "axios";
import { TES_APP_ENDPOINT } from "@/constants/EndPoints";
import store from "@/store";

import {
  IProgramsList,
  ITESUser,
  IApplicationReviewModel,
  IStgEcolaneCustProfileModel,
  IApplicantProfile,
  IDraftProgramInfo,
  IEcolaneDataUpdate,
  IApplicantAddress
} from "@/types";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import { AGENCY_REVIEW, APPL_STATUS } from "@/constants/CommonConstants";
import { clone } from "@/util/clone";
import { ecolaneDataUpdateDefault } from "@/data/AppData";

export default Vue.extend({
  mixins: [UIFeatureMixinVue],
  computed: {
    userInfo(): ITESUser {
      return store.getters.tesUserModule.getTesUser;
    },
    applicantProgramInfo: {
      get(): IProgramsList {
        //@ts-ignore
        return store.getters.applicantProgramModule.getApplicantProgram;
      },
      set(newObject: IProgramsList) {
        store.commit.applicantProgramModule.SET_APPLICANT_PROGRAM(newObject);
      }
    },
    applicationReviewModel: {
      get(): IApplicationReviewModel {
        return store.getters.applicationReviewModule.getApplicationReviewModel;
      },
      set(newObject: IApplicationReviewModel) {
        store.commit.applicationReviewModule.SET_APPLICATION_REVIEW_MODEL(
          newObject
        );
      }
    },
    stgEcolaneCustProfile: {
      get(): IStgEcolaneCustProfileModel {
        return store.getters.applicantEcolaneProfileModule
          .getStgEcolaneCustProfile;
      }
    },
    applicantProfileInfo(): IApplicantProfile {
      return store.getters.applicantProfileModule.getApplicantProfile;
    },
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      }
    },
    isNoEcolaneAgencyDashBrd(): boolean {
      let isNoEcolaneAgency = false;
      //@ts-ignore
      let noEclnAgncyStr = this.uiFeature.NO_ECLN_AG.largeText;
      let noEclnAgncyArray = noEclnAgncyStr.split(",");
      if (noEclnAgncyArray.includes(this.userInfo.transitAgencyCode)) {
        isNoEcolaneAgency = true;
      }
      return isNoEcolaneAgency;
    },
    isNoEcolaneAgency(): boolean {
      let isNoEcolaneAgency = false;
      //@ts-ignore
      let noEclnAgncyStr = this.uiFeature.NO_ECLN_AG.largeText;
      let noEclnAgncyArray = noEclnAgncyStr.split(",");
      if (
        noEclnAgncyArray.includes(this.applicantProgramInfo.transitAgencyCode)
      ) {
        isNoEcolaneAgency = true;
      }
      return isNoEcolaneAgency;
    },
    isNoEcolanePrgrm(): boolean {
      let isNoEcolanePrgrm = false;
      //@ts-ignore
      let noEclnAgncyStr = this.uiFeature.NO_ECLN_PG.largeText;
      let noEclnAgncyArray = noEclnAgncyStr.split(",");
      if (
        noEclnAgncyArray.includes(this.applicantProgramInfo.programTypeCode)
      ) {
        isNoEcolanePrgrm = true;
      }
      return isNoEcolanePrgrm;
    },
    isAgncyElgbl(): boolean {
      let isAgncyElgbl = false;
      //@ts-ignore
      let noEclnAgncyStr = this.uiFeature.NO_ECLN_PG.largeText;
      let noEclnAgncyArray = noEclnAgncyStr.split(",");
      if (
        !noEclnAgncyArray.includes(this.applicantProgramInfo.programTypeCode)
      ) {
        let agencyStaticArray: string[] = this.applicantProgramInfo.ecolaneConfigAgncy.split(
          ","
        );

        if (
          agencyStaticArray &&
          agencyStaticArray.includes(this.userInfo.transitAgencyCode)
        ) {
          isAgncyElgbl = true;
        }
      }
      return isAgncyElgbl;
    },
    isRFTP(): boolean {
      let isRFTP = false;
      if (this.applicantProgramInfo.programTypeCode === AGENCY_REVIEW.RFTP) {
        isRFTP = true;
      }
      return isRFTP;
    },
    isFTP(): boolean {
      let isFTP = false;
      if (this.applicantProgramInfo.programTypeCode === AGENCY_REVIEW.FTP) {
        isFTP = true;
      }
      return isFTP;
    },
    isADA(): boolean {
      let isADA = false;
      if (this.applicantProgramInfo.programTypeCode === AGENCY_REVIEW.ADA) {
        isADA = true;
      }
      return isADA;
    },
    isPWD(): boolean {
      let isPWD = false;
      if (this.applicantProgramInfo.programTypeCode === AGENCY_REVIEW.PWD) {
        isPWD = true;
      }
      return isPWD;
    },
    isLANTA(): boolean {
      let isLANTA = false;
      if (this.applicantProgramInfo.transitAgencyCode === AGENCY_REVIEW.LNTA) {
        isLANTA = true;
      }
      return isLANTA;
    },
    isIneleigible(): boolean {
      let isIneligible = false;
      if (this.applicantProgramInfo.statusCode === APPL_STATUS.INELIGIBLE) {
        isIneligible = true;
      }
      return isIneligible;
    },
    isIncomplete(): boolean {
      let isIncomplete = false;
      if (this.applicantProgramInfo.statusCode === APPL_STATUS.INCOMPLETE) {
        isIncomplete = true;
      }
      return isIncomplete;
    },
    isAppealInPrgrs(): boolean {
      let isAppealInPrgrs = false;
      if (this.applicantProgramInfo.statusCode === APPL_STATUS.APEL_INPRG) {
        isAppealInPrgrs = true;
      }
      return isAppealInPrgrs;
    },
    renderReviewSummaryWEcolane(): boolean {
      let showReviewSummaryWEcolane = false;
      if (
        this.applicationReviewModel &&
        this.applicationReviewModel.applicationReviewId > 0 &&
        //@ts-ignore
        !this.isCitizenUser &&
        (this.applicantProgramInfo.statusCode === "RE_COR" ||
          this.applicantProgramInfo.statusCode === "INELIGIBLE" ||
          this.applicantProgramInfo.statusCode === "INCOMPLETE" ||
          this.applicantProgramInfo.statusCode === "PEND_CONF" ||
          this.applicantProgramInfo.statusCode === "APPROVED" ||
          this.applicantProgramInfo.statusCode === APPL_STATUS.TMP_APRVD)
      ) {
        showReviewSummaryWEcolane = true;
      }
      return showReviewSummaryWEcolane;
    },
    getEcolaneDataUpdateList(): IEcolaneDataUpdate[] {
      return store.getters.applicantEcolaneProfileModule
        .getEcolaneDataUpdateList;
    },
    getEcolaneDataPrevUpdateList(): IEcolaneDataUpdate[] {
      return store.getters.applicantEcolaneProfileModule
        .getEcolaneDataPrevUpdateList;
    },
    firstNameObj(): IEcolaneDataUpdate {
      return this.getEcolaneDataUpdObj("FIRST_NAME");
    },
    middleInitObj(): IEcolaneDataUpdate {
      return this.getEcolaneDataUpdObj("MIDDLE_INIT");
    },
    emerContNameObj(): IEcolaneDataUpdate {
      return this.getEcolaneDataUpdObj("EMERG_CONT_NAME");
    },
    emerContPhNoObj(): IEcolaneDataUpdate {
      return this.getEcolaneDataUpdObj("EMERG_CONT_PHNE");
    },
    ethnicityObj(): IEcolaneDataUpdate {
      return this.getEcolaneDataUpdObj("ETHNICITY");
    },
    languageObj(): IEcolaneDataUpdate {
      return this.getEcolaneDataUpdObj("LANGUAGE");
    },
    genderObj(): IEcolaneDataUpdate {
      return this.getEcolaneDataUpdObj("GENDER");
    },
    socialSecurityObj(): IEcolaneDataUpdate {
      return this.getEcolaneDataUpdObj("SOCIAL_SECURITY_NUMBER");
    },
    medicaidObj(): IEcolaneDataUpdate {
      return this.getEcolaneDataUpdObj("MEDICAID_NUMBER");
    },
    countyObj(): IEcolaneDataUpdate {
      return this.getEcolaneDataUpdObj("COUNTY");
    },
    emaiAddrObj(): IEcolaneDataUpdate {
      return this.getEcolaneDataUpdObj("EMAIL_ADDR");
    },
    primaryPhObj(): IEcolaneDataUpdate {
      return this.getEcolaneDataUpdObj("PRIM_PHNE");
    },
    secondaryPhObj(): IEcolaneDataUpdate {
      return this.getEcolaneDataUpdObj("SEC_PHNE");
    },
    disMentalObj(): IEcolaneDataUpdate {
      return this.getEcolaneDataUpdObj("DIS_MENTAL");
    },
    disPhysicalObj(): IEcolaneDataUpdate {
      return this.getEcolaneDataUpdObj("DIS_PHYSCL");
    },
    disVisualObj(): IEcolaneDataUpdate {
      return this.getEcolaneDataUpdObj("DIS_VISUAL");
    },
    homeAddrLine1Obj(): IEcolaneDataUpdate {
      return this.getEcolaneDataUpdObj("HOME_ADDRL1");
    },
    homeAddrLine2Obj(): IEcolaneDataUpdate {
      return this.getEcolaneDataUpdObj("HOME_ADDRL2");
    },
    homeCityObj(): IEcolaneDataUpdate {
      return this.getEcolaneDataUpdObj("HOME_CITY");
    },
    homeStateObj(): IEcolaneDataUpdate {
      return this.getEcolaneDataUpdObj("HOME_STATE");
    },
    homeZipObj(): IEcolaneDataUpdate {
      return this.getEcolaneDataUpdObj("HOME_ZIP");
    },
    mailAddrLine1Obj(): IEcolaneDataUpdate {
      return this.getEcolaneDataUpdObj("MAIL_ADDRL1");
    },
    mailAddrLine2Obj(): IEcolaneDataUpdate {
      return this.getEcolaneDataUpdObj("MAIL_ADDRL2");
    },
    mailCityObj(): IEcolaneDataUpdate {
      return this.getEcolaneDataUpdObj("MAIL_CITY");
    },
    mailStateObj(): IEcolaneDataUpdate {
      return this.getEcolaneDataUpdObj("MAIL_STATE");
    },
    mailZipObj(): IEcolaneDataUpdate {
      return this.getEcolaneDataUpdObj("MAIL_ZIP");
    },
    showHomeAddrDiff(): boolean {
      let temp: boolean = false;
      if (
        this.upperValue(
          this.applicantProfileInfo.homeAddress.addressLine1.trim()
        ) !=
          this.upperValue(
            `${this.stgEcolaneCustProfile.homeAddressStreetNum} ${this.stgEcolaneCustProfile.homeAddressStreet}`
          ) ||
        this.upperValue(this.applicantProfileInfo.homeAddress.addressLine2) !=
          this.upperValue(this.stgEcolaneCustProfile.homeAddressAptNum) ||
        this.upperValue(this.applicantProfileInfo.homeAddress.city) !=
          this.upperValue(this.stgEcolaneCustProfile.homeAddressCity) ||
        this.upperValue(this.applicantProfileInfo.homeAddress.state) !=
          this.upperValue(this.stgEcolaneCustProfile.homeAddressState) ||
        this.upperValue(this.stgEcolaneCustProfile.homeAddressZip) !=
          this.upperValue(this.applicantProfileInfo.homeAddress.zip)
      ) {
        temp = true;
      }
      return temp;
    },
    showMailAddrDiff(): boolean {
      let temp: boolean = false;
      if (
        this.applicantProfileInfo.sameMailingInd === "Y" ||
        !this.applicantProfileInfo.mailingAddress
      ) {
        if (
          this.upperValue(
            this.applicantProfileInfo.homeAddress.addressLine1.trim()
          ) !=
            this.upperValue(
              `${this.stgEcolaneCustProfile.mailAddressStreetNum} ${this.stgEcolaneCustProfile.mailAddressStreet}`
            ) ||
          this.upperValue(this.applicantProfileInfo.homeAddress.addressLine2) !=
            this.upperValue(this.stgEcolaneCustProfile.mailAddressAptNum) ||
          this.upperValue(this.applicantProfileInfo.homeAddress.city) !=
            this.upperValue(this.stgEcolaneCustProfile.mailAddressCity) ||
          this.upperValue(this.applicantProfileInfo.homeAddress.state) !=
            this.upperValue(this.stgEcolaneCustProfile.mailAddressState) ||
          this.upperValue(this.applicantProfileInfo.homeAddress.zip) !=
            this.upperValue(this.stgEcolaneCustProfile.mailAddressZip)
        ) {
          temp = true;
        }
      } else {
        if (
          this.upperValue(
            this.applicantProfileInfo.mailingAddress.addressLine1.trim()
          ) !=
            this.upperValue(
              `${this.stgEcolaneCustProfile.mailAddressStreetNum} ${this.stgEcolaneCustProfile.mailAddressStreet}`
            ) ||
          this.upperValue(
            this.applicantProfileInfo.mailingAddress.addressLine2
          ) != this.upperValue(this.stgEcolaneCustProfile.mailAddressAptNum) ||
          this.upperValue(this.applicantProfileInfo.mailingAddress.city) !=
            this.upperValue(this.stgEcolaneCustProfile.mailAddressCity) ||
          this.upperValue(this.applicantProfileInfo.mailingAddress.state) !=
            this.upperValue(this.stgEcolaneCustProfile.mailAddressState) ||
          this.upperValue(this.applicantProfileInfo.mailingAddress.zip) !=
            this.upperValue(this.stgEcolaneCustProfile.mailAddressZip)
        ) {
          temp = true;
        }
      }
      return temp;
    },
    showDiffSectionMethod(): boolean {
      let showDiffSection = false;
      if (this.getEcolaneDataUpdateList.length > 0) {
        let x: IEcolaneDataUpdate = this.getEcolaneDataUpdateList[0];
        if (x.ecolaneDataType) {
          showDiffSection = true;
        }
      }
      return showDiffSection;
    }
  },
  methods: {
    loadLANTADocs(): any {
      let prgmTypeCode = this.applicantProgramInfo.programTypeCode;
      let docTypeCode = "INPRN_ASMT";
      if (prgmTypeCode === "RFTP") {
        docTypeCode = "PRF_DSBLTY";
      }
      let payload = {
        applicationReviewId: this.applicationReviewModel.applicationReviewId,
        transitAgencyCode: this.applicantProgramInfo.transitAgencyCode,
        documentTypeCode: docTypeCode
      };
      store.dispatch.attachmentsModule.getReviewrDocsByReviewIdAgencyAndDocType(
        payload
      );
    },
    getEcolaneDataUpdObj(abc: string): IEcolaneDataUpdate {
      //@ts-ignore
      let x: IEcolaneDataUpdate = this.getEcolaneDataUpdateList.find(
        o => o.ecolaneDataType === abc
      );
      if (!x) {
        x = clone(ecolaneDataUpdateDefault);
      }
      return x;
    },
    upperValue(a: string): string {
      let x: string = "";
      if (a) {
        x = a.toUpperCase();
      }
      return x;
    },
    cancelReview() {
      this.changeReviewFlag("N");
      //@ts-ignore
      this.forwardToDashboard();
    },
    changeReviewFlag(flag: string) {
      if (
        this.applicationReviewModel &&
        this.applicationReviewModel.reviewerUserId === this.userInfo.userId
      ) {
        this.applicationReviewModel.actReviewFlag = flag;
        let updateReviewFlagUrl = TES_APP_ENDPOINT.UPDATE_REVIEW_FLAG;
        axios
          .post(updateReviewFlagUrl, this.applicationReviewModel)
          .catch(error => {
            store.dispatch.alertModule.showAlertMessageById(
              error.response.data.id
            );
          });
      }
    },
    maskSSN(ssn: string): string {
      let mask: string = "";
      if (ssn != null && ssn.length > 4) {
        mask = "***-**-" + ssn.substring(ssn.length - 4);
      }
      return mask;
    }
  }
});
