
import Vue from "vue";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import tesProgramTemplate from "@/components/common/TESProgramTemplate.vue";
import { IApplicantProfile, IDraftProgramInfo, IProgramsList } from "@/types";
import VTPProgramInfo from "@/components/application/program/vtp/VTPProgramInfo.vue";
import store from "@/store";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import router from "@/router";
import Attachments from "@/components/application/program/Attachments.vue";
import AttachmentsMixinVue from "@/mixins/AttachmentsMixin.vue";
import StepperNavigationMixinVue from "@/mixins/StepperNavigationMixin.vue";
import { BUTTON_NAMES, CNST_TEXT } from "@/constants/CommonConstants";
import routerMixin from "@/mixins/RouterMixin.vue";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";
import { GPC } from "@/constants/GPCCode";

export default Vue.extend({
  name: "program-vtp",
  mixins: [
    fieldEntryMixin,
    UIFeatureMixinVue,
    AttachmentsMixinVue,
    StepperNavigationMixinVue,
    routerMixin,
    ApplicationMixin
  ],
  components: {
    tesProgramTemplate,
    VTPProgramInfo,
    Attachments
  },
  data: function() {
    return {
      stepCount: 2 as number,
      headerMsg: "" as string,
      documentTypeCodes: [] as string[]
    };
  },
  methods: {
    saveCompleted(buttonName: string) {
      if (buttonName === "CANCEL") {
        this.continueToDashboard();
      }
      //@ts-ignore
      else if (this.$refs.programVTPTemplate.stepperIndex !== this.stepCount) {
        //@ts-ignore
        this.$refs.programVTPTemplate.nextStep();
        //@ts-ignore
        this.scrollToSectionHeader(this.$refs.programVTPTemplate);
      } else if (
        //@ts-ignore
        this.isSingleFlow()
      ) {
        if (
          buttonName === BUTTON_NAMES.SAVECONTINUE ||
          buttonName === BUTTON_NAMES.CONTINUE
        ) {
          let nextProgram = "";
          //@ts-ignore
          nextProgram = this.nextProgram(
            "VTP",
            this.applicantProgramInfo.transitAgencyCode
          );
          if (nextProgram === "DOCS" || nextProgram === "REVIEW") {
            router.push({
              path: "/application-program/" + nextProgram,
              query: {
                profileID: String(this.applicantProgramInfo.applicantProfileId),
                prgmType: nextProgram
              }
            });
          } else {
            router.push({
              path: "/application-program/" + nextProgram,
              query: {
                profileID: String(this.applicantProgramInfo.applicantProfileId),
                prgmType: nextProgram,
                agencyType: this.applicantProgramInfo.transitAgencyCode
              }
            });
          }
        }
      } else {
        router.push({
          path:
            "/application-review/" + this.applicantProgramInfo.programTypeCode
        });
      }
    },
    changeStepCount(steps: number) {
      this.stepCount = steps;
    },
    continueToDashboard() {
      //@ts-ignore
      this.forwardToDashboard();
    }
  },
  computed: {
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        //@ts-ignore
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      },
      set(newValue: IDraftProgramInfo) {
        //@ts-ignore
        store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(newValue);
      }
    },
    applicantProgramInfo: {
      get(): IProgramsList {
        //@ts-ignore
        return store.getters.applicantProgramModule.getApplicantProgram;
      }
    },
    applicantProfile(): IApplicantProfile {
      return store.getters.applicantProfileModule.getApplicantProfile;
    },
    vtpDocInfoHeader(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.VTP_INFO_1].displayName,
          CNST_TEXT.BE_USED,
          CNST_TEXT.THAT_THE_APPLICANT_IS
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.VTP_INFO_1].displayName,
          CNST_TEXT.I_USE,
          CNST_TEXT.I_AM
        );
      }
      return text;
    },
    vtpDocInfoOne(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.VTP_INFO_1].largeText,
          CNST_TEXT.THE_APPLICANTS,
          CNST_TEXT.THE_APPLICANT
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.VTP_INFO_1].largeText,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOU
        );
      }
      return text;
    },
    prfDsbltyInfoHeaderTwo(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_2].displayName,
          CNST_TEXT.THE
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_2].displayName,
          CNST_TEXT.MY
        );
      }
      return text;
    },
    prfDsbltyInfoTwo(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_2].largeText,
          CNST_TEXT.THE
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_2].largeText,
          CNST_TEXT.YOUR
        );
      }
      return text;
    },
    prfDsbltyInfoThree(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_3].largeText,
          CNST_TEXT.THE,
          CNST_TEXT.THE
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_3].largeText,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOUR
        );
      }
      return text;
    }
  },
  created() {
    if (
      //@ts-ignore
      this.isSingleFlow()
    ) {
      this.stepCount = 1;
    } else {
      this.stepCount = 2;
      if (this.draftProgramInfo.veteranInd === "N") {
        this.stepCount = 1;
      }
    }
    //@ts-ignore
    if (!this.isSingleFlow()) {
      //@ts-ignore
      this.documentTypeCodes = this.getVTPDocumentTypes();
    }
  }
});
