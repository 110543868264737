var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.stepperHeaderModel.stepperHeaders.length > 0)?_c('div',[_c('progress-bar',{attrs:{"dialog":_vm.showProgressBar}}),(_vm.fromDashboard)?_c('v-card',{staticClass:"mt-4 mt-sm-0 pa-2 pa-sm-4",attrs:{"color":"info"}},[(!_vm.afterSubmit)?_c('h6',{staticClass:"ma-2 pa-2"},[_vm._v(" You are almost done with your applications. You can continue where you left off. ")]):_vm._e(),(_vm.afterSubmit)?_c('h6',{staticClass:"ma-2 pa-2"},[_vm._v(" Your applications are ready to be submitted. ")]):_vm._e(),_c('v-btn',{staticClass:"primaryButton mx-4 my-2",attrs:{"tile":""},on:{"click":function($event){return _vm.continueToApplication()}}},[_vm._v(" CONTINUE APPLICATIONS ")])],1):_vm._e(),(_vm.fromDashboard)?_c('v-stepper',{staticClass:"elevation-0",attrs:{"alt-labels":""},model:{value:(_vm.selectedStep),callback:function ($$v) {_vm.selectedStep=$$v},expression:"selectedStep"}},[_c('v-stepper-header',{staticClass:"stepperHeader",attrs:{"aria-hidden":"true"}},[_vm._l((_vm.stepperHeaderModel.stepperHeaders),function(headerStep,ind){return [_c('v-stepper-step',{key:ind + 1,attrs:{"step":ind + 1}},[_vm._v(" "+_vm._s(headerStep.description)+" ")]),(
            ind < _vm.stepperHeaderModel.stepperHeaders.length - 1 &&
              _vm.$vuetify.breakpoint.mdAndUp
          )?_c('v-divider',{key:'div' + ind,attrs:{"aria-hidden":"true"}}):_vm._e()]})],2)],1):_vm._e(),_c('v-stepper',{directives:[{name:"show",rawName:"v-show",value:(!_vm.fromDashboard && _vm.$vuetify.breakpoint.smAndDown),expression:"!fromDashboard && $vuetify.breakpoint.smAndDown"}],staticClass:"elevation-0 mobileStepper",attrs:{"alt-labels":""},model:{value:(_vm.selectedStep),callback:function ($$v) {_vm.selectedStep=$$v},expression:"selectedStep"}},[_c('v-stepper-header',[_vm._l((_vm.stepperHeaderModel.stepperHeaders),function(headerStep,ind){return [(
            headerStep.isCurrentStep && headerStep.programType !== 'PROFILE'
          )?_c('span',{key:ind + 10,staticClass:"my-auto pl-4",staticStyle:{"width":"12%"}},[_c('v-btn',{attrs:{"icon":"","aria-label":"Go to previous step"},on:{"click":function($event){return _vm.goToPreviousStep(ind - 1)}}},[_c('v-icon',{attrs:{"size":"35","color":"disabled_steppers"}},[_vm._v(" fa-chevron-left ")])],1)],1):_vm._e(),_c('span',{directives:[{name:"show",rawName:"v-show",value:(headerStep.isCurrentStep),expression:"headerStep.isCurrentStep"}],key:ind + 20,staticStyle:{"width":"88%"}},[_c('v-stepper-step',{attrs:{"step":ind + 1,"editable":"","color":"brand_green","edit-icon":"mdi mdi-pencil"}},[_vm._v(" "+_vm._s(headerStep.description)+" ")])],1)]})],2)],1),_c('v-stepper',{directives:[{name:"show",rawName:"v-show",value:(!_vm.fromDashboard && _vm.$vuetify.breakpoint.mdAndUp),expression:"!fromDashboard && $vuetify.breakpoint.mdAndUp"}],staticClass:"elevation-0 desktopStepper",attrs:{"alt-labels":""},model:{value:(_vm.selectedStep),callback:function ($$v) {_vm.selectedStep=$$v},expression:"selectedStep"}},[_c('v-stepper-header',[_vm._l((_vm.stepperHeaderModel.stepperHeaders),function(headerStep,ind){return [_c('v-stepper-step',{key:ind + 1,attrs:{"complete":_vm.selectedStep > ind + 1,"step":ind + 1,"editable":_vm.selectedStep > ind + 1,"color":"brand_green","edit-icon":"mdi mdi-pencil"},on:{"click":function($event){_vm.selectedStep > ind + 1
              ? _vm.goToRoute(
                  headerStep.programType,
                  ind,
                  headerStep.agencyCode,
                  true
                )
              : ''}}},[_vm._v(" "+_vm._s(headerStep.description)+" ")]),(ind < _vm.stepperHeaderModel.stepperHeaders.length - 1)?_c('v-divider',{key:'div' + ind,attrs:{"aria-hidden":"true"}}):_vm._e()]})],2)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }