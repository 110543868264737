
import Vue from "vue";
import store from "@/store";

export default Vue.extend({
  computed: {
    uiFeature: {
      get(): Object {
        return store.getters.uiFeatureModule.uiFeature;
      }
    },
    largeText: function() {
      return (first: string) => {
        let temp: string;
        if (first) {
          //@ts-ignore
          temp = this.uiFeature[first].largeText;
          return temp ? temp : "";
        } else {
          return "";
        }
      };
    },
    displayName: function() {
      return (first: string) => {
        let temp: string;
        if (first) {
          //@ts-ignore
          temp = this.uiFeature[first].displayName;
          return temp ? temp : "";
        } else {
          return "";
        }
      };
    },
    largeTextByParam: function() {
      return (first: string, second: string) => {
        //@ts-ignore
        let temp = this.uiFeature[`${first}${second}`];
        return temp ? temp.largeText : [];
      };
    }
  },
  methods: {
    buildDynamicText(text: string, ...args: any): string {
      let result = "";
      args.forEach((item: string, idx: number) => {
        if (result) {
          result = result.replace(`{placeholder${idx + 1}}`, item);
        } else {
          result = text.replace(`{placeholder${idx + 1}}`, item);
        }
      });
      return result;
    }
  }
});
