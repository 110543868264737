export const DASHBOARD_TYPE = {
  INITIAL: "INITIAL",
  RESULT: "RESULT",
  PROFILE: "PROFILE",
  APPLICATION: "APPLICATION",
  CONTINUE: "CONTINUE"
};

export const STATUS_CODES = {
  NOT_START: "NOT_START",
  IN_PRGS: "IN_PRGS",
  RDY_SBMT: "RDY_SBMT",
  SBMTD: "SBMTD",
  NOT_QLFYD: "NOT_QLFYD",
  RDY_SBMT_C: "RDY_SBMT_C"
};

export const ACTION_CODES = {
  STR_APPL: "STR_APPL",
  FNLZ: "FNLZ",
  APPL_SBMT: "APPL_SBMT",
  SAVE: "SAVE"
};

export const EVENT_CODES = {
  APPL_CRT: "APPL_CRT",
  APPL_RSBT: "APPL_RSBT",
  APPL_SBTD: "APPL_SBTD",
  APPL_DQFD: "APPL_DQFD"
};

export const DISABILITY_TYPE = {
  TEMP: "TEMP",
  PERM: "PERM"
};

export const BUTTON_NAMES = {
  SAVE: "SAVE",
  SAVECONTINUE: "SAVECONTINUE",
  CONTINUE: "CONTINUE",
  CANCEL: "CANCEL",
  SAVECONTBYPASS: "SAVECONTBYPASS"
};

export const PROGRAM_NAMES = {
  SSR: "SSR",
  FTP: "FTP",
  PWD: "PWD",
  ADA: "ADA",
  MATP: "MATP",
  RFTP: "RFTP",
  VTP: "VTP",
  AGING: "AGING"
};

export const USER_GROUPS = {
  PD_TES_AGREVIEWER: "PD-FMR-AGReviewer",
  PD_TES_APPLICANT: "PD-FMR-Applicant",
  PD_TES_CITIZEN: "PD-FMR-Citizen",
  PD_TES_COREADONLY: "PD-FMR-COReadOnly",
  PD_FMR_AGREVIEWERASSIGNED: "PD-FMR-AGReviewerAssigned",
  PD_FMR_AGSUPERVISOR: "PD-FMR-AGSupervisor"
};

export const APPL_STATUS = {
  NOT_START: "NOT_START",
  IN_PROGRESS: "IN_PRGS",
  READY_TO_SUMBMIT: "RDY_SBMT",
  NOT_QUALIFIED: "NOT_QLFYD",
  SUBMITTED: "SBMTD",
  APPROVED: "APPROVED",
  NOT_APPLICABLE: "NOT_APPL",
  IN_PROCESS: "IN_PROCESS",
  IN_PROGRESS_C: "IN_PRGS_C",
  READY_TO_SUMBMIT_C: "RDY_SBMT_C",
  UNDER_REVIEW: "UN_RVW",
  RETURN_FOR_CORRECTION: "RE_COR",
  INELIGIBLE: "INELIGIBLE",
  WITHDRAWN: "WITHDRAWN",
  PENDING_CONFIGURATION: "PEND_CONF",
  ELIGIBLE: "ELIGIBLE",
  TMP_APRVD: "TMP_APRVD",
  APEL_INPRG: "APEL_INPRG",
  PENDING: "PENDING",
  INCOMPLETE: "INCOMPLETE"
};

export const ESEC_REDIRECT_STRINGS = {
  ACCESS_DENIED: "AccessDenied",
  IDSERVICES: "idservices",
  LOCKEDAUTHRSN24: "SMAUTHREASON=24",
  LOCKEDAUTHRSN51: "SMAUTHREASON=51",
  LOCKEDAUTHRSN7: "SMAUTHREASON=7",
  PUBLICURL: "pub",
  CTZACSURL: "PDCitizenRequestAccessforFMR"
};

export const AGENCY_REVIEW = {
  LNTA: "LNTA",
  FTP: "FTP",
  ADA: "ADA",
  CMP_RV_OTH: "CMP_RV_OTH",
  CMP_RV_SLF: "CMP_RV_SLF",
  PWD: "PWD",
  RFTP: "RFTP"
};

export const ADDRESS_TYPE = {
  HOME: "HOME",
  MAIL: "MAIL"
};

export const expansionPanelIconObj = {
  showText: "More Details",
  hideText: "Less Details"
};

export const CNST_TEXT = {
  APPLICANTS_THE: "The applicant's",
  THE_APPLICANTS: "the applicant's",
  THE_APPLICANT: "the applicant",
  TTHE_APPLICANT: "The applicant",
  THE_APPLICANT_LIVES: "the applicant lives",
  THE_APPLICANT_MEETS: "the applicant meets",
  YOU_LIVE: "you live",
  THE_APPLICANT_HAS: "the applicant has",
  THE_APPLICANT_NEEDS: "the applicant needs",
  DOES_THE_APPLICANT: "Does the applicant",
  DOES_THE_APPLICANT_D: "does the applicant",
  IS_THE_APPLICANT: "Is the applicant",
  THE_APPLICANT_DOES_NOT: "The applicant does not",
  THE_APPLICANT_DOES: "the applicant does",
  TTHE_APPLICANT_DOES: "The applicant does",
  YOU_DONT: "You don't",
  YOU_DO: "You do",
  LYOU_DO: "you do",
  YOU_HAVE: "you have",
  YOU_MEET: "you meet",
  YOU_NEED: "you need",
  DO_YOU: "Do you",
  ARE_YOU: "Are you",
  THEIR: "their",
  YOUR: "your",
  THEY: "they",
  THEY_CAN: "they can",
  YOU: "you",
  YYOU: "You",
  MEETS: "meets",
  NEEDS: "needs",
  NEED: "need",
  DO: "do",
  DOES: "does",
  DOESNT: "doesn't",
  I: "I",
  I_DONT: "I don't",
  MY: "my",
  THEM: "them",
  YOU_QUALIFY: "you qualify",
  THE_APPLICANT_QUALIFIES: "the applicant qualifies",
  PLEASE: "please",
  THE_APPLICANT_IS: "the applicant is",
  TTHE_APPLICANT_IS: "The applicant is",
  YOU_ARE: "you are",
  THE: "the",
  YOUR_Y: "Your",
  IS_THE_APPLICANTS: "Is the applicant's",
  IS_YOUR: "Is your",
  THE_PROVIDER: "the provider",
  I_AM: "I am",
  USE: "use",
  USES: "uses",
  I_USE: "I use",
  BE_USED: "be used",
  THAT_THE_APPLICANT_IS: "that the applicant is",
  THIS: "this",
  THE_APPLICANT_RESIDES: "the applicant resides",
  YOU_RESIDE: "you reside",
  YOU_WANT: "you want",
  THE_APPLICANT_WANTS: "the applicant wants",
  OF_THEIR: "of their",
  THEY_SHOULD: "they should",
  APPLICANT: "Applicant",
  I_HAVE: "I have"
};
