
import Vue from "vue";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import tesProgramTemplate from "@/components/common/TESProgramTemplate.vue";
import {
  IApplicantProfile,
  IDraftFixedRouteUsage,
  IDraftProgramInfo,
  IProgramsList
} from "@/types";
import ADAProgramInfo from "@/components/application/program/ada/ADAProgramInfo.vue";
import ADAVoterRegistration from "@/components/application/program/ada/ADAVoterRegistration.vue";
import PWDDetailInfo from "@/components/application/program/pwd/PWDDetailInfo.vue";
import Attachments from "@/components/application/program/Attachments.vue";
import store from "@/store";
import router from "@/router";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import StepperNavigationMixinVue from "@/mixins/StepperNavigationMixin.vue";
import { BUTTON_NAMES, CNST_TEXT } from "@/constants/CommonConstants";
import routerMixin from "@/mixins/RouterMixin.vue";
import AttachmentsMixinVue from "@/mixins/AttachmentsMixin.vue";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";
import { GPC } from "@/constants/GPCCode";
import PhysicianList from "@/components/application/PhysicianList.vue";

const SECTIONS = {
  ADA_DEFAULT: { id: "ADADEFAULT", header: "ADA Program Information" },
  PWD_INFO: { id: "PWDINFO", header: "ADA Detail Information" },
  PHYSICIAN_INFO: { id: "PHYSICIANINFO", header: "Physician Information" },
  ADA_VOTER_REG: { id: "ADAVOTERREG", header: "Voter Registration" },
  ATTACHMENTS: {
    // A general holder for the individual attachment sections to lump them all into one section
    id: "ATTACHMENTS",
    header: "Attach Documents for Proof of Disability"
  }
};

export default Vue.extend({
  name: "program-ada",
  mixins: [
    fieldEntryMixin,
    UIFeatureMixinVue,
    StepperNavigationMixinVue,
    routerMixin,
    AttachmentsMixinVue,
    ApplicationMixin
  ],
  components: {
    tesProgramTemplate,
    ADAProgramInfo,
    PWDDetailInfo,
    Attachments,
    ADAVoterRegistration,
    PhysicianList
  },
  data: function() {
    return {
      draftFixedRouteUsageList: {} as IDraftFixedRouteUsage[],
      draftFixedRouteUsage: {} as IDraftFixedRouteUsage,
      disabilityInd: false,
      stepContent: "step3Content",
      displayVoter: true,
      documentTypeCodes: [] as string[],
      customDisability: false,
      docText: "" as string,
      step: 0,
      displaySections: [] as { id: string; header: string }[],
      SECTIONS
    };
  },
  computed: {
    stepCount: {
      get(): number {
        return this.displaySections.length;
      }
    },
    sectionHeaders: {
      get(): string[] {
        return this.displaySections.map(s => s.header);
      }
    },
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      },
      set(newValue: IDraftProgramInfo) {
        store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(newValue);
      }
    },
    applicantProgramInfo: {
      get(): IProgramsList {
        return store.getters.applicantProgramModule.getApplicantProgram;
      }
    },
    docDescCodes(): any {
      //@ts-ignore
      return this.uiFeature.PRF_DSBLTY.children;
    },
    docGPCCodes(): [] {
      //@ts-ignore
      return this.uiFeature.AG_ATT_TXT.children;
    },
    applicantProfile(): IApplicantProfile {
      return store.getters.applicantProfileModule.getApplicantProfile;
    },
    prfDsbltyInfoOne(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBLTY].largeText,
          CNST_TEXT.THE_APPLICANT_HAS,
          CNST_TEXT.THE_APPLICANT,
          CNST_TEXT.THE
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBLTY].largeText,
          CNST_TEXT.YOU_HAVE,
          CNST_TEXT.YOU,
          CNST_TEXT.YOUR
        );
      }
      return text;
    },
    prfDsbltyInfoHeaderTwo(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_2].displayName,
          CNST_TEXT.THE
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_2].displayName,
          CNST_TEXT.MY
        );
      }
      return text;
    },
    prfDsbltyInfoTwo(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_2].largeText,
          CNST_TEXT.THE
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_2].largeText,
          CNST_TEXT.YOUR
        );
      }
      return text;
    },
    prfDsbltyInfoThree(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_3].largeText,
          CNST_TEXT.THE,
          CNST_TEXT.THE
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_3].largeText,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOUR
        );
      }
      return text;
    },
    codDocText(): string {
      //@ts-ignore
      return this.getCodDocDynamicText(
        //@ts-ignore
        this.uiFeature[GPC.COD_DOCTXT].largeText
      );
    },
    pcaDocInfoOne(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_PRCARE].largeText,
          CNST_TEXT.THE_APPLICANT_NEEDS,
          CNST_TEXT.THE_APPLICANT,
          CNST_TEXT.THEIR,
          CNST_TEXT.THE
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_PRCARE].largeText,
          CNST_TEXT.YOU_NEED,
          CNST_TEXT.YOU,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOUR
        );
      }
      return text;
    },
    funcVeriInfo(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.FV_FORM].largeText,
          "the applicant qualifies",
          CNST_TEXT.THEY,
          CNST_TEXT.THE_APPLICANTS
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.FV_FORM].largeText,
          "you qualify",
          CNST_TEXT.YOU,
          CNST_TEXT.YOUR
        );
      }
      return text;
    },
    visFormInfo(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.VIS_FORM].largeText,
          CNST_TEXT.THE_APPLICANT_HAS,
          CNST_TEXT.THE
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.VIS_FORM].largeText,
          CNST_TEXT.YOU_HAVE,
          CNST_TEXT.YOUR
        );
      }
      return text;
    }
  },
  created() {
    this.draftFixedRouteUsageList = this.draftProgramInfo.draftFixedRouteUsageList;
    if (this.draftFixedRouteUsageList !== null) {
      this.disabilityInd =
        this.draftFixedRouteUsageList.length <= 1 &&
        // @ts-ignore
        !this.isProgramExist("PWD");
      this.displayVoter = true;
      for (const dfru of this.draftFixedRouteUsageList) {
        if (
          dfru["providerAreaTravelInd"] === "Y" &&
          dfru["fixedRouteDisabilityInd"] === "Y"
        ) {
          this.displayVoter = false;
        }
        if (
          this.applicantProgramInfo.transitAgencyCode ===
          dfru["transitAgencyCode"]
        ) {
          this.draftFixedRouteUsage.providerAreaTravelInd =
            dfru["providerAreaTravelInd"];
          this.draftFixedRouteUsage.fixedRouteDisabilityInd =
            dfru["fixedRouteDisabilityInd"];
          this.draftFixedRouteUsage.fixedRouteDisabilityDesc =
            dfru["fixedRouteDisabilityDesc"];
        }
      }
    } else {
      // @ts-ignore
      this.disabilityInd = !this.isProgramExist("PWD");
    }
    this.configureDisplayedSections();
    this.createdContd();
  },
  methods: {
    moveFocusToNextStep() {
      const elementTofocus = document.getElementById(`adaStep${this.step}Id`);
      if (elementTofocus) {
        elementTofocus.focus();
      }
    },
    changeStep(step: number) {
      this.step = step;
    },
    saveCompleted(buttonName: string) {
      if (buttonName === "CANCEL") {
        this.continueToDashboard();
      } else if (this.step !== this.stepCount) {
        //@ts-ignore
        this.$refs.programADATemplate.nextStep();
        this.moveFocusToNextStep();
        //@ts-ignore
        this.scrollToSectionHeader(this.$refs.programADATemplate);
      } else if (
        //@ts-ignore
        this.isSingleFlow()
      ) {
        if (
          buttonName === BUTTON_NAMES.SAVECONTINUE ||
          buttonName === BUTTON_NAMES.CONTINUE
        ) {
          let nextProgram = "";
          //@ts-ignore
          nextProgram = this.nextProgram(
            "ADA",
            this.applicantProgramInfo.transitAgencyCode
          );
          if (nextProgram === "ADA") {
            nextProgram = "ADA1";
          }
          if (nextProgram === "DOCS" || nextProgram === "REVIEW") {
            router.push({
              path: "/application-program/" + nextProgram,
              query: {
                profileID: String(this.applicantProgramInfo.applicantProfileId),
                prgmType: nextProgram
              }
            });
          } else {
            router.push({
              path: "/application-program/" + nextProgram,
              query: {
                profileID: String(this.applicantProgramInfo.applicantProfileId),
                prgmType: nextProgram,
                agencyType: this.applicantProgramInfo.transitAgencyCode
              }
            });
          }
        }
      } else {
        router.push({
          path:
            "/application-review/" + this.applicantProgramInfo.programTypeCode
        });
      }
    },
    continueToDashboard() {
      //@ts-ignore
      this.forwardToDashboard();
    },
    isCustomDisabilityReq() {
      //@ts-ignore
      if (this.isCustomDisabilityAgency()) {
        this.customDisability = true;
        this.populateDocTextForCustomDisability();
      }
    },
    populateDocTextForCustomDisability() {
      if (this.applicantProgramInfo.transitAgencyCode === "FREEDOM") {
        //@ts-ignore
        this.docText = this.getCodDocDynamicText(
          //@ts-ignore
          this.uiFeature.FRDMCODTXT.largeText
        );
      } else {
        let abcObj = this.docGPCCodes.find(
          obj =>
            //@ts-ignore
            obj.resourceCode ===
            this.applicantProgramInfo.transitAgencyCode + "_COD"
        );
        //@ts-ignore
        this.docText = this.getCodDocDynamicText(abcObj.largeText);
      }
    },
    configureDisplayedSections() {
      this.displaySections = [];
      this.displaySections.push(this.SECTIONS.ADA_DEFAULT); // unconditional first section

      if (
        this.draftProgramInfo.disabilityInd !== "N" &&
        this.draftFixedRouteUsage.providerAreaTravelInd !== "N" &&
        this.draftFixedRouteUsage.fixedRouteDisabilityInd !== "N"
      ) {
        // Add voter registration section if not second ADA section in single flow
        if (this.displayVoter) {
          this.displaySections.push(this.SECTIONS.ADA_VOTER_REG);
        }

        // Add PWD Info if not entered/single flow
        if (
          // @ts-ignore
          !this.isSingleFlow() ||
          !this.draftProgramInfo.disabilityType ||
          this.disabilityInd
        ) {
          this.displaySections.push(this.SECTIONS.PWD_INFO);
        }

        // Add Physician Info Section for SEPTA if disability has been indicated
        if (
          this.draftProgramInfo.disabilityInd === "Y" &&
          this.applicantProgramInfo.transitAgencyCode === "SEPTA"
        ) {
          this.displaySections.push(this.SECTIONS.PHYSICIAN_INFO);
        }

        // Add Attachments if not single flow
        // @ts-ignore
        if (!this.isSingleFlow()) {
          this.displaySections.push(this.SECTIONS.ATTACHMENTS);
        }
      }
      // Add PWD Details section conditionally
    },
    createdContd() {
      //@ts-ignore
      if (!this.isSingleFlow()) {
        this.isCustomDisabilityReq();
        //@ts-ignore
        this.documentTypeCodes = this.getADADocumentTypes();
        this.getUploadedDocList();
        this.loadAllDocsAtchdCount();
      }
    },
    loadAllDocsAtchdCount() {
      if (
        //@ts-ignore
        this.showIndvFlowSEPTAPCA ||
        //@ts-ignore
        this.showFuncVeriForm ||
        //@ts-ignore
        this.showVisionForm
      ) {
        //@ts-ignore
        this.compareAllDocsAttached();
      }
    },
    getUploadedDocList() {
      let payload = {
        applicantProfileId: this.applicantProgramInfo.applicantProfileId
      };
      store.dispatch.attachmentsModule.getUploadedDocList(payload);
      store.dispatch.attachmentsModule.getAtchmtChkBoxSltdByProfileId(
        this.applicantProgramInfo.applicantProfileId
      );
    }
  }
});
