
import Vue from "vue";
import ProgressBar from "@/components/common/ProgressBar.vue";
import store from "@/store";
import axios from "axios";
import { TES_APP_ENDPOINT } from "@/constants/EndPoints";
import {
  IApplicantProfile,
  IDraftProgramInfo,
  IProgramsList,
  IPromiseSearchModel,
  IPromiseRespModel
} from "@/types";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import dateHelperMixinVue from "@/mixins/dateHelperMixin.vue";
import fieldEntryMixinVue from "@/mixins/fieldEntryMixin.vue";
import AppPropsMixinVue from "@/mixins/AppPropsMixin.vue";
import RouterMixinVue from "@/mixins/RouterMixin.vue";
import readonlyField from "@/components/common/ReadOnlyField.vue";

export default Vue.extend({
  mixins: [
    UIFeatureMixinVue,
    dateHelperMixinVue,
    fieldEntryMixinVue,
    AppPropsMixinVue,
    RouterMixinVue
  ],
  name: "review-app-ecolane",
  components: {
    ProgressBar,
    readonlyField
  },
  data() {
    return {
      showProgressBar: false,
      searchedPromise: false,
      promiseSrchModel: {} as IPromiseSearchModel,
      promiseRespModel: {} as IPromiseRespModel
    };
  },
  async created() {
    this.showProgressBar = false;
  },
  methods: {
    searchPromise() {
      this.populateRequestObject();
      axios
        .post(TES_APP_ENDPOINT.VERIFY_ELIG_PROMISE, this.promiseSrchModel)
        .then(response => {
          //@ts-ignore
          this.promiseRespModel = response.data;
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
          this.searchedPromise = true;
        });
    },
    savePromise() {
      this.$emit("save-completed");
    },
    getFormattedDOBForPrms(dob: string) {
      //@ts-ignore
      return this.formatDateMixinForPromise(dob);
    },
    populateRequestObject() {
      this.promiseSrchModel.profileID = this.applicantProgramInfo.applicantProfileId;
      this.promiseSrchModel.firstName = this.applicantProfile.applicantFirstName;
      this.promiseSrchModel.lastName = this.applicantProfile.applicantLastName;
      this.promiseSrchModel.maID = this.draftProgramInfo.medicaidCardRecipientNum;
      if (this.draftProgramInfo.enSSN != null) {
        this.promiseSrchModel.ssn = this.draftProgramInfo.enSSN.replace(
          /\D/g,
          ""
        );
      }
      this.promiseSrchModel.dob = this.getFormattedDOBForPrms(
        this.applicantProfile.applicantDob
      );
    }
  },
  computed: {
    applicantProfile: {
      get(): IApplicantProfile {
        return store.getters.applicantProfileModule.getApplicantProfile;
      }
    },
    applicantProgramInfo: {
      get(): IProgramsList {
        return store.getters.applicantProgramModule.getApplicantProgram;
      }
    },
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        //@ts-ignore
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      }
    },
    ssnValue: {
      get(): string {
        //@ts-ignore
        return this.ssnMask(this.draftProgramInfo.enSSN);
      },
      set(value: string) {
        this.draftProgramInfo.enSSN = value;
      }
    },
    eligCodeValue(): any {
      let eligCodeValue: string = "N/A";
      if (this.promiseRespModel.eligiblityCode) {
        eligCodeValue = this.promiseRespModel.eligiblityCode;
        if (
          this.promiseRespModel.eligiblity &&
          this.promiseRespModel.eligiblity === "Y"
        ) {
          eligCodeValue = eligCodeValue.concat("(Eligible)");
        } else {
          eligCodeValue = eligCodeValue.concat("(Ineligible)");
        }
      }
      return eligCodeValue;
    }
  }
});
