import axios from "axios";
import { DASHBOARD_ENDPOINT, TES_APP_ENDPOINT } from "@/constants/EndPoints";
import { moduleActionContext } from "..";
import { defineModule } from "direct-vuex";

export interface IAllTransitAgencyData {
  allTransitAgencyData: Object;
  loaded: boolean;
  cannedCommentsList: [];
}

const allTransitAgencyDataModule = defineModule({
  namespaced: true,
  state: {
    allTransitAgencyData: Object,
    loaded: false,
    cannedCommentsList: []
  } as IAllTransitAgencyData,
  mutations: {
    SET_TRANSIT_AGENCY_DATA(
      state: IAllTransitAgencyData,
      newTransitAgencyData: Object
    ) {
      state.allTransitAgencyData = newTransitAgencyData;
      state.loaded = true;
    },
    SET_CANNED_COMMENTS_LIST(state: IAllTransitAgencyData, newDocs: []) {
      state.cannedCommentsList = newDocs;
    }
  },
  getters: {
    getAllTransitAgencyData: (state: IAllTransitAgencyData) => {
      return state.allTransitAgencyData;
    },
    getDataLoaded: (state: IAllTransitAgencyData) => {
      return state.loaded;
    },
    getCannedCommentsList: (state: IAllTransitAgencyData) => {
      return state.cannedCommentsList;
    }
  },
  actions: {
    async getAllTransitAgencyData(context: any) {
      let url = DASHBOARD_ENDPOINT.GET_ALL_TRANSIT_AGENCY_DATA;
      let { commit, rootDispatch } = allTransitAgencyDataModuleContext(context);
      return axios
        .get(url)
        .then(response => {
          commit.SET_TRANSIT_AGENCY_DATA(response.data);
        })
        .catch(() => {
          rootDispatch.alertModule.showAlertMessageByGPC("ER_INLINE");
        });
    },
    getCannedCommentsByTransitAgency(context: any, payload: any) {
      const url = TES_APP_ENDPOINT.GET_CANNED_COMMENTS_BY_TRANSIT_AGENCY;
      let { commit, rootDispatch } = allTransitAgencyDataModuleContext(context);
      axios
        .get(url, {
          params: {
            transitAgencyCode: payload.transitAgencyCode
          }
        })
        .then(function(response) {
          commit.SET_CANNED_COMMENTS_LIST(response.data);
        })
        .catch(() => {
          rootDispatch.alertModule.showAlertMessageByGPC("ER_INLINE");
        });
    }
  }
});

export default allTransitAgencyDataModule;
const allTransitAgencyDataModuleContext = (context: any) =>
  moduleActionContext(context, allTransitAgencyDataModule);
