
import Vue from "vue";
import store from "@/store";
import { IDraftProgramInfo } from "@/types";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import ProgramInfoSummaryMixin from "@/mixins/ProgramInfoSummaryMixin.vue";
import dateHelperMixin from "@/mixins/dateHelperMixin.vue";
import readonlyField from "@/components/common/ReadOnlyField.vue";
import { CNST_TEXT, DISABILITY_TYPE } from "@/constants/CommonConstants";
import { GPC } from "@/constants/GPCCode";
import DynamicTextMixin from "@/mixins/DynamicTextMixin.vue";

export default Vue.extend({
  name: "PWD-General-Information",
  mixins: [
    UIFeatureMixinVue,
    ProgramInfoSummaryMixin,
    dateHelperMixin,
    DynamicTextMixin
  ],
  components: { readonlyField },
  props: {
    programName: {
      type: String
    }
  },
  data() {
    return {
      seletedNeeds: [] as string[],
      DISABILITY_TYPE: DISABILITY_TYPE
    };
  },
  computed: {
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        //@ts-ignore
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      }
    },
    seletedNeedsFlated(): string {
      let needsFlat: string = "";

      this.seletedNeeds.forEach(n => {
        //@ts-ignore
        needsFlat = needsFlat + this.uiFeature[n].displayName;
      });

      return needsFlat;
    },
    seletedNeedsPrevious(): string[] {
      let previousNeeds: string[] = [];

      //@ts-ignore
      let programPrevious: IDraftProgramInfo = this.programInfoPrevious;

      if (programPrevious.visualDisabilityInd === "Y") {
        previousNeeds.push("DISABL_1");
      }
      if (programPrevious.hearingDisabilityInd === "Y") {
        previousNeeds.push("DISABL_2");
      }
      if (programPrevious.mentalDisabilityInd === "Y") {
        previousNeeds.push("DISABL_3");
      }
      if (programPrevious.cognitiveDisabilityInd === "Y") {
        previousNeeds.push("DISABL_4");
      }
      if (programPrevious.mobilityDisabilityInd === "Y") {
        previousNeeds.push("DISABL_5");
      }
      if (programPrevious.otherDisabilityInd === "Y") {
        previousNeeds.push("DISABL_OTH");
      }
      return previousNeeds;
    },
    seletedNeedsPreviousFlated(): string {
      let needsFlat: string = "";

      this.seletedNeedsPrevious.forEach(n => {
        //@ts-ignore
        needsFlat = needsFlat + this.uiFeature[n].displayName;
      });

      return needsFlat;
    },
    disabilityConditionQuestion(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithOnePlaceHolderVal(
        GPC.DIS_CNDTNS,
        CNST_TEXT.IS_THE_APPLICANTS,
        CNST_TEXT.IS_YOUR
      );
      return text;
    }
  },
  created() {
    if (this.draftProgramInfo.visualDisabilityInd === "Y") {
      this.seletedNeeds.push("DISABL_1");
    }
    if (this.draftProgramInfo.hearingDisabilityInd === "Y") {
      this.seletedNeeds.push("DISABL_2");
    }
    if (this.draftProgramInfo.mentalDisabilityInd === "Y") {
      this.seletedNeeds.push("DISABL_3");
    }
    if (this.draftProgramInfo.cognitiveDisabilityInd === "Y") {
      this.seletedNeeds.push("DISABL_4");
    }
    if (this.draftProgramInfo.mobilityDisabilityInd === "Y") {
      this.seletedNeeds.push("DISABL_5");
    }
    if (this.draftProgramInfo.otherDisabilityInd === "Y") {
      this.seletedNeeds.push("DISABL_OTH");
    }
  }
});
