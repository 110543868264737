
import Vue from "vue";
import ApplicantProfileMixin from "@/mixins/ApplicantProfileMixin.vue";
import readonlyField from "@/components/common/ReadOnlyField.vue";

export default Vue.extend({
  name: "review-personal-info",
  components: { readonlyField },
  mixins: [ApplicantProfileMixin]
});
