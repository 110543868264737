
import Vue from "vue";

import ProgressBar from "@/components/common/ProgressBar.vue";
import DateInput from "@/components/common/DateInput.vue";
import store from "@/store";
import tesControlTemplate from "@/components/common/TESControlTemplate.vue";
import axios from "axios";
import { IPromiseSearchModel, IPromiseRespModel } from "@/types";
import { PROMISE_ENDPOINT } from "@/constants/EndPoints";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import dateHelperMixin from "@/mixins/dateHelperMixin.vue";
import readonlyField from "@/components/common/ReadOnlyField.vue";

export default Vue.extend({
  name: "promise-interface-cont",
  mixins: [fieldEntryMixin, dateHelperMixin],
  components: {
    ProgressBar,
    DateInput,
    tesControlTemplate,
    readonlyField
  },

  data() {
    return {
      showProgressBar: false,
      promiseSrchModel: {} as IPromiseSearchModel,
      promiseRespModel: {} as IPromiseRespModel,
      showResults: false
    };
  },
  beforeDestroy() {
    this.reset();
  },
  methods: {
    getFormattedDOBForPrms(dob: string) {
      //@ts-ignore
      return this.formatDateMixinForPromise(dob);
    },
    promiseCall() {
      this.showProgressBar = true;
      let url = PROMISE_ENDPOINT.PROMISE_ELIG_CALL;
      this.promiseSrchModel.dob = this.getFormattedDOBForPrms(
        this.promiseSrchModel.dob
      );
      axios
        .post(url, this.promiseSrchModel)
        .then(response => {
          //@ts-ignore
          this.promiseRespModel = response.data;
          this.showResults = true;
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    },

    reset() {
      this.showResults = false;
      //@ts-ignore
      this.$refs.promiseSearchForm.reset();
      this.promiseSrchModel.dob = "";
      this.promiseRespModel.eligiblity = "";
      this.promiseRespModel.provider = "";
      this.promiseRespModel.eligiblityCode = "";
    }
  }
});
