
import Vue from "vue";
import HelpDetails from "@/components/help/HelpDetails.vue";
import ProgressBar from "@/components/common/ProgressBar.vue";
import store from "@/store";

export default Vue.extend({
  name: "help",
  components: {
    HelpDetails,
    ProgressBar
  },
  created() {
    let uiFeatureArray =
      "HELP,HELP_CONT,HELP_GUIDE,ER_INLINE,PRVDR_ASST,REG_LOGIN,COMP_SCR_Q,CREATE_APP,UPD_RESBMT,CHGLGN_TXT,FMR,ENVRONMNT,AG_HELP,AG_CONT,AG_GUIDE,BP_REG,AG_DASH,APP_REV_E,APP_REV,AG_SEARCH,ORG_HELP,ORG_CONT,ORG_GUIDE,ORG_DASH,CREATE_PHL,CREATE_ALG";

    //@ts-ignore
    store.dispatch.uiFeatureModule.getUIFeatures(uiFeatureArray);
  },
  computed: {
    isDataLoading: {
      get(): boolean {
        //@ts-ignore
        return store.getters.uiFeatureModule.getLoadingStatus;
      }
    }
  }
});
