
import Vue from "vue";
import axios from "axios";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import DraftProgramMixin from "@/mixins/DraftProgramMixin.vue";
import ProgressBar from "@/components/common/ProgressBar.vue";
import store from "@/store";
import { IApplicantProfile, IDraftProgramInfo, IProgramsList } from "@/types";
import TESRadioControl from "@/components/common/TESRadioControl.vue";
import tesContainer from "@/components/common/TESContainer.vue";
import routerMixin from "@/mixins/RouterMixin.vue";
import StepperNavigationMixinVue from "@/mixins/StepperNavigationMixin.vue";
import { BUTTON_NAMES, CNST_TEXT } from "@/constants/CommonConstants";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import InfoSection from "@/components/common/InfoSection.vue";
import { TES_APP_ENDPOINT } from "@/constants/EndPoints";
import tesControlTemplate from "@/components/common/TESControlTemplate.vue";
import ScrollMixin from "@/mixins/ScrollMixin.vue";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";
import { GPC } from "@/constants/GPCCode";
import DynamicTextMixin from "@/mixins/DynamicTextMixin.vue";

export default Vue.extend({
  name: "rftp-program-info",
  mixins: [
    fieldEntryMixin,
    DraftProgramMixin,
    routerMixin,
    StepperNavigationMixinVue,
    UIFeatureMixinVue,
    AppPropsMixin,
    ScrollMixin,
    ApplicationMixin,
    DynamicTextMixin
  ],
  components: {
    tesContainer,
    TESRadioControl,
    ProgressBar,
    InfoSection,
    tesControlTemplate
  },
  props: {
    stepCount: { type: Number, default: 0 }
  },
  data() {
    return {
      showProgressBar: false,
      showDefaultButtons: true,
      medicareCardInd: "" as string,
      agencyProviders: [] as Object[]
    };
  },
  computed: {
    applicantProgramInfo(): IProgramsList {
      return store.getters.applicantProgramModule.getApplicantProgram;
    },
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      },
      set(newValue: IDraftProgramInfo) {
        store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(newValue);
      }
    },
    programEligModel: {
      get(): any {
        //@ts-ignore
        return store.getters.programEligModule.getProgramElig;
      }
    },
    applicantProfile(): IApplicantProfile {
      return store.getters.applicantProfileModule.getApplicantProfile;
    },
    rftpInfoOne(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.RFTP_SEC1].largeText,
          CNST_TEXT.THE_APPLICANT_HAS,
          CNST_TEXT.THEY,
          CNST_TEXT.THEM
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.RFTP_SEC1].largeText,
          CNST_TEXT.YOU_HAVE,
          CNST_TEXT.YOU,
          CNST_TEXT.YOU
        );
      }
      return text;
    },
    rftpInfoTwo(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.ADA_SEC2].largeText,
          CNST_TEXT.THE_APPLICANTS,
          CNST_TEXT.THE_APPLICANTS
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.ADA_SEC2].largeText,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOUR
        );
      }
      return text;
    },
    rftpFxdRouteIndDsplyNameQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithOnePlaceHolderVal(
        GPC.RFTP_APPLY,
        CNST_TEXT.DOES_THE_APPLICANT,
        CNST_TEXT.DO_YOU
      );
      return text;
    },
    rftpFxdRouteIndLrgTxtQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithOnePlaceHolder(
        GPC.RFTP_APPLY,
        CNST_TEXT.DOES_THE_APPLICANT,
        CNST_TEXT.DO_YOU
      );
      return text;
    },
    disabilityConfirmQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithOnePlaceHolder(
        GPC.DIS_CNFM,
        CNST_TEXT.THE_APPLICANT_HAS,
        CNST_TEXT.YOU_HAVE
      );
      return text;
    },
    rftpMedicareQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithOnePlaceHolder(
        GPC.MEDCAR_IND,
        CNST_TEXT.THE_APPLICANT_HAS,
        CNST_TEXT.YOU_HAVE
      );
      return text;
    },
    rftpProviderQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.FtpRftpProviderQues();
      return text;
    },
    // Display logic has been fully externalized
    singleFlowFinalStep() {
      // @ts-ignore
      return this.isSingleFlow() && this.stepCount === 1;
    }
  },
  created() {
    if (this.draftProgramInfo.disabilityInd === "Y") {
      this.draftProgramInfo.medicareCardInd = "";
    }

    if (
      this.draftProgramInfo.rftpApplyInd === "N" ||
      this.draftProgramInfo.medicareCardInd === "N"
    ) {
      this.showDefaultButtons = false;
    }

    this.medicareCardInd = this.draftProgramInfo.medicareCardInd;
    let url = TES_APP_ENDPOINT.GET_TRANSIT_AGENCY_PROVIDERS;
    //@ts-ignore
    const applicantProfile =
      store.getters.applicantProfileModule.getApplicantProfile;
    let applicantCounty = applicantProfile.homeAddress.paCountyCode;
    axios
      .get(url, {
        params: { countyCode: applicantCounty, prgmCode: "RFTP" }
      })
      .then(response => {
        //@ts-ignore
        if (applicantProfile.gisCallInd === "Y" && this.isSingleFlow()) {
          for (let agncyPrvdr of response.data) {
            if (
              applicantProfile.gisAgenciesList.includes(
                agncyPrvdr.transitAgencyCode
              )
            ) {
              this.agencyProviders.push(agncyPrvdr);
            }
          }
        } else {
          this.agencyProviders = response.data;
        }
      })
      .catch(error => {
        store.dispatch.alertModule.showAlertMessageById(error.response.data.id);
      });
  },
  mounted() {
    this.changeValues();
  },
  methods: {
    cancel() {
      //@ts-ignore
      if (this.$refs.rftpProgramInfoForm.validate()) {
        this.saveRFTPProgramInfo("CANCEL");
      } else {
        //@ts-ignore
        this.scrollToErrorField(this.$refs.rftpProgramInfoForm);
      }
    },
    saveContinue() {
      //@ts-ignore
      if (this.$refs.rftpProgramInfoForm.validate()) {
        this.saveRFTPProgramInfo("SAVECONTINUE");
      } else {
        //@ts-ignore
        this.scrollToErrorField(this.$refs.rftpProgramInfoForm);
      }
    },
    save(buttonName: string) {
      //@ts-ignore
      if (this.$refs.rftpProgramInfoForm.validate()) {
        if (
          this.draftProgramInfo.rftpApplyInd === "N" ||
          this.medicareCardInd === "N"
        ) {
          this.saveRFTPProgramInfo(buttonName);
        } else {
          this.saveRFTPProgramInfo("SAVE");
        }
      } else {
        //@ts-ignore
        this.scrollToErrorField(this.$refs.rftpProgramInfoForm);
      }
    },
    saveRFTPProgramInfo(buttonName: string) {
      //@ts-ignore
      if (this.$refs.rftpProgramInfoForm.validate()) {
        this.showProgressBar = true;
        const stepperIndex = 1;
        if (this.draftProgramInfo.disabilityInd === "Y") {
          this.draftProgramInfo.medicareCardInd = "";
        }
        this.draftProgramInfo.medicareCardInd = this.medicareCardInd;
        //@ts-ignore
        this.saveProgramInfo(
          this.draftProgramInfo,
          stepperIndex,
          this.stepCount,
          buttonName
        )
          //@ts-ignore
          .then(response => {
            this.draftProgramInfo = response.data;
            //@ts-ignore
            store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(
              this.draftProgramInfo
            );

            //@ts-ignore
            store.commit.applicantProgramModule.SET_APPLICANT_PROGRAM(
              this.draftProgramInfo.programInfo
            );

            this.updateProgEligModels(); // cognitive complexity reduction

            if (
              //@ts-ignore
              this.isSingleFlow() ||
              buttonName === BUTTON_NAMES.SAVE ||
              buttonName === BUTTON_NAMES.CANCEL
            ) {
              this.$emit("save-completed", buttonName);
            } else {
              //@ts-ignore
              this.forwardToDashboard();
            }
          })
          //@ts-ignore
          .catch(error => {
            store.dispatch.alertModule.showAlertMessageById(
              error.response.data.id
            );
          })
          .finally(() => {
            this.showProgressBar = false;
          });
      }
    },
    updateProgEligModels() {
      for (let programEligModel of this.programEligModel.preEligiblePrograms) {
        if (
          programEligModel["programTypeCode"] === "RFTP" ||
          programEligModel["transitAgencyCode"] === "AGY_PEND"
        ) {
          programEligModel[
            "transitAgencyCode"
          ] = this.draftProgramInfo.programInfo.transitAgencyCode;
          programEligModel[
            "transiAgencyName"
          ] = this.draftProgramInfo.programInfo.transiAgencyName;
          programEligModel[
            "agencyEmailAddress"
          ] = this.draftProgramInfo.programInfo.agencyEmailAddress;
        }
      }
    },
    changeValues() {
      if (this.draftProgramInfo.rftpApplyInd === "N") {
        this.medicareCardInd = "";

        if (this.agencyProviders.length > 1) {
          this.draftProgramInfo.rftpProviderAgencyCode = "AGY_PEND";
        }

        if (
          //@ts-ignore
          !this.isProgramExist("PWD") &&
          //@ts-ignore
          !this.isProgramExist("ADA")
        ) {
          this.draftProgramInfo.disabilityInd = "";
        }
      } else if (this.draftProgramInfo.disabilityInd === "Y") {
        this.draftProgramInfo.medicareCardInd = "";
        this.medicareCardInd = "";
      } else {
        this.draftProgramInfo.medicareCardInd = this.medicareCardInd;
      }

      this.showDefaultButtons =
        this.draftProgramInfo.rftpApplyInd !== "N" &&
        this.medicareCardInd !== "N";

      this.$emit("step-count");
    },
    isPWDADAExists() {
      let programExist = false;
      if (
        //@ts-ignore
        this.isProgramExist("PWD") ||
        //@ts-ignore
        this.isProgramExist("ADA")
      ) {
        programExist = true;
      }
      return programExist;
    }
  }
});
