
export default {
  data() {
    return {};
  },
  methods: {
    scrollToErrorField(ref: any) {
      let element: any;
      ref.inputs.find((input: any) => {
        if (input.errorBucket.length > 0) {
          element = input;
        }
        return element;
      });
      if (element) {
        //@ts-ignore
        this.$vuetify.goTo(element, {
          duration: 500,
          offset: 75,
          easing: "easeInOutCubic"
        });
        if (element.$_modelEvent !== "change") {
          element.focus();
        }
      }
    }
  }
};
