<template>
  <v-container class="pa-2 ma-1">
    <v-card class="borderRadius pa-2">
      <div class="pl-4">
        <v-alert
          dense
          outlined
          text
          type="error"
          v-if="showDocUploadErrorMsg"
          >{{ uiFeature.DOCUPL_ERR.largeText }}</v-alert
        >
        <div>
          <h6>
            Upload required documents
          </h6>
        </div>
        <span class="pt-2">
          {{ uiFeature.VLD_DOCEXT.largeText }}
        </span>
      </div>
      <div>
        <ReviewerDocUploader
          @uploaded="loadReviewerDocs()"
          :docsMaxNumber="docsMaxNumber"
          ref="docUploader"
          :documentTypeCode="documentTypeCode"
          :docDescCodes="docDescCodes"
          @docErrorMessage="val => (showDocUploadErrorMsg = val)"
          class="mt-2"
        />

        <v-list
          three-line
          flat
          :class="
            $vuetify.breakpoint.smAndDown ? 'documentList' : 'documentListWide'
          "
          style="width:100%"
        >
          <v-list-item v-if="docs.length < 1">
            <v-list-item-content>
              <v-list-item-title class="text-xs-center"
                >No documents are attached to the application</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <template v-for="(doc, index) in docs">
            <ReviewerDocumentRow
              :key="doc.attachmentId"
              :doc="doc"
              :showHeaders="index === 0"
              :docDescCodes="docDescCodes"
              :documentTypeCode="documentTypeCode"
              :index="index"
              :isEcs="true"
              @removeDoc="removeDoc(index)"
            />
          </template>
        </v-list>
        <v-progress-linear v-if="isDocListLoadding" indeterminate />
      </div>
    </v-card>
    <!-- Overriding the 'buttons' named slot not to display action buttons, needed for single flow-->
    <template v-slot:buttons v-if="noShowButtons">
      <span></span>
      <!-- Do not remove this empty tag -->
    </template>

    <v-container class="pl-0">
      <v-btn tile class="primaryButton" @click="continueClicked()"
        >Continue</v-btn
      >
      <v-btn tile class="ml-2 secondaryButton" @click="cancelReview()"
        >Cancel</v-btn
      >
    </v-container>
  </v-container>
</template>
<script>
import Vue from "vue";
import store from "@/store";
import ReviewerDocUploader from "@/components/common/ReviewerDocUploader";
import router from "@/router";
import ReviewerDocumentRow from "@/components/common/ReviewerDocumentRow.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import RouterMixinVue from "@/mixins/RouterMixin.vue";
import AgencyReviewMixinVue from "@/mixins/AgencyReviewMixin.vue";

export default Vue.extend({
  name: "ReviewerAttachments",
  components: {
    ReviewerDocumentRow,
    ReviewerDocUploader
  },
  mixins: [UIFeatureMixinVue, RouterMixinVue, AgencyReviewMixinVue],
  data() {
    return {
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: "file",
      showDocUploadErrorMsg: false
    };
  },
  props: {
    docDescCodes: {
      type: Array,
      default: null
    },
    documentTypeCode: {
      type: String,
      default: null
    },
    isFinalStep: {
      type: Boolean,
      default: true
    },
    noShowButtons: {
      type: Boolean,
      default: false
    },
    mainHeader: {
      type: String,
      default: null
    },
    subHeader: {
      type: String,
      default: null
    },
    showDefaultButtons: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    docs: {
      get() {
        //@ts-ignore
        return store.getters.attachmentsModule.getReviewerDocs;
      },
      set(newValue) {
        //@ts-ignore
        store.commit.attachmentsModule.SET_REVR_DOC_LIST(newValue);
      }
    },
    isDocListLoadding() {
      return store.getters.attachmentsModule.getDocListLoadingStatus;
    },

    docsMaxNumber() {
      let maxNumber = 0;
      if (this.docs) {
        this.docs.forEach(d => {
          let key = d.documentTypeCode;
          let docNumber = parseInt(key.charAt(key.length - 1));
          if (maxNumber < docNumber) {
            maxNumber = docNumber;
          }
        });
      }
      return maxNumber;
    },
    applicantProgramInfo: {
      get() {
        //@ts-ignore
        return store.getters.applicantProgramModule.getApplicantProgram;
      }
    },
    applicationReviewModel: {
      get() {
        //@ts-ignore
        return store.getters.applicationReviewModule.getApplicationReviewModel;
      }
    }
  },
  methods: {
    removeDoc(index) {
      this.$delete(this.docs, index);
    },
    loadReviewerDocs() {
      let payload = {
        applicationReviewId: this.applicationReviewModel.applicationReviewId,
        transitAgencyCode: this.applicantProgramInfo.transitAgencyCode
      };
      store.dispatch.attachmentsModule.getReviewerDocsByReviewIdAndAgency(
        payload
      );
    },
    navigateToReview() {
      this.saveProgramInformation("CONTINUE");
    },
    save() {
      this.saveProgramInformation("SAVE");
    },
    saveContinue() {
      this.saveProgramInformation("SAVECONTINUE");
    },
    saveProgramInformation(buttonName) {
      this.showProgressBar = true;
      if (
        //@ts-ignore
        this.isSingleFlow()
      ) {
        if (buttonName === "CONTINUE") {
          let nextProgram = "";
          //@ts-ignore
          nextProgram = this.nextProgram("DOCS", "");
          router.push({
            path: "/application-program/" + nextProgram
          });
        }
      } else {
        router.push({
          path:
            "/application-review/" + this.applicantProgramInfo.programTypeCode
        });
      }
    },
    continueClicked() {
      this.$emit("continue-clicked");
    }
  },
  created() {
    this.loadReviewerDocs();
  }
});
</script>

<style scoped lang="scss">
div.documentList > div > div {
  height: 135px;
}
div.documentList > div > div > a {
  height: 131px !important;
}
div.documentListWide > div > div {
  height: 75px;
}
div.documentListWide > div > div > a {
  height: 80px !important;
}
.borderRadius {
  border: 1px solid #002469;
}
</style>
