
import Vue from "vue";
import axios from "axios";
import { TES_APP_ENDPOINT } from "@/constants/EndPoints";
import store from "@/store";
import {
  IApplicantProfile,
  IDraftFixedRouteUsage,
  IDraftProgramInfo,
  IProgramsList
} from "@/types";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import ProgramInfoSummaryMixin from "@/mixins/ProgramInfoSummaryMixin.vue";
import readonlyField from "@/components/common/ReadOnlyField.vue";
import { GPC } from "@/constants/GPCCode";
import DynamicTextMixin from "@/mixins/DynamicTextMixin.vue";
import { CNST_TEXT } from "@/constants/CommonConstants";

export default Vue.extend({
  name: "ADA-Program-Information",
  mixins: [
    AppPropsMixin,
    UIFeatureMixinVue,
    ProgramInfoSummaryMixin,
    DynamicTextMixin
  ],
  components: { readonlyField },
  data() {
    return {
      travelQuestionText: "",
      showFixedRouteDisabilityInd: false
    };
  },
  props: {
    applicantProgramInfo: {
      type: Object as () => IProgramsList
    },
    draftFixedRoute: {
      type: Object as () => IDraftFixedRouteUsage
    }
  },
  computed: {
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      }
    },
    disabilityQuestionText(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrgAgyCtznonBehalfOfUser()
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.DS_AGY_FRS].largeText,
          CNST_TEXT.DOES_THE_APPLICANT,
          CNST_TEXT.THEM,
          this.applicantProgramInfo.transiAgencyName
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.DS_AGY_FRS].largeText,
          CNST_TEXT.DO_YOU,
          CNST_TEXT.YOU,
          this.applicantProgramInfo.transiAgencyName
        );
      }
      return text;
    },
    applicantProfile(): IApplicantProfile {
      return store.getters.applicantProfileModule.getApplicantProfile;
    },
    disabilityConfirmQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithOnePlaceHolder(
        GPC.DIS_CNFM,
        CNST_TEXT.THE_APPLICANT_HAS,
        CNST_TEXT.YOU_HAVE
      );
      return text;
    },
    dsbltyFixedRouteSrvQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithTwoPlaceHolder(
        GPC.DS_PRV_FRS,
        CNST_TEXT.THE_APPLICANTS,
        CNST_TEXT.THEM,
        CNST_TEXT.YOUR,
        CNST_TEXT.YOU
      );
      return text;
    }
  },
  created() {
    let applicantCounty =
      store.getters.applicantProfileModule.getApplicantProfile.homeAddress
        .paCountyCode;
    let prgmTransitAgencyCode = this.applicantProgramInfo.transitAgencyCode;
    if (
      this.draftProgramInfo.disabilityInd === "Y" &&
      (this.draftFixedRoute.providerAreaTravelInd === "Y" ||
        this.applicantProfile.gisCallInd === "Y")
    ) {
      this.showFixedRouteDisabilityInd = true;
    }
    axios
      .get(TES_APP_ENDPOINT.GET_TRANSIT_AGENCY_SRV_NAME, {
        params: {
          countyCode: applicantCounty,
          transitAgencyCode: prgmTransitAgencyCode
        }
      })
      .then(response => {
        this.getTravelQuesTxt(response.data.areaName);
      })
      .catch(error => {
        store.dispatch.alertModule.showAlertMessageById(error.response.data.id);
      });
  },
  methods: {
    getTravelQuesTxt(serviceAreaName: string) {
      let applicantCounty =
        store.getters.applicantProfileModule.getApplicantProfile.homeAddress
          .paCountyCode;
      //@ts-ignore
      let noSrvArea = this.uiFeature.NO_SRVAREA.largeText;
      let noSrvAreaArray = noSrvArea.split(",");
      if (noSrvAreaArray.includes(applicantCounty)) {
        //@ts-ignore
        this.travelQuestionText = this.buildTxtWithTwoPlaceHolder(
          GPC.TVL_ARA_Q1,
          CNST_TEXT.DOES_THE_APPLICANT,
          this.applicantProgramInfo.transiAgencyName,
          CNST_TEXT.DO_YOU,
          this.applicantProgramInfo.transiAgencyName
        );
      } else {
        if (
          //@ts-ignore
          this.isOrgAgyCtznonBehalfOfUser()
        ) {
          //@ts-ignore
          this.travelQuestionText = this.buildDynamicText(
            //@ts-ignore
            this.uiFeature[GPC.TVL_ARA_Q2].largeText,
            CNST_TEXT.DOES_THE_APPLICANT,
            serviceAreaName,
            this.applicantProgramInfo.transiAgencyName
          );
        } else {
          //@ts-ignore
          this.travelQuestionText = this.buildDynamicText(
            //@ts-ignore
            this.uiFeature[GPC.TVL_ARA_Q2].largeText,
            CNST_TEXT.DO_YOU,
            serviceAreaName,
            this.applicantProgramInfo.transiAgencyName
          );
        }
      }
    }
  }
});
