<template>
  <tes-container
    :questionHint="questionHintText"
    :questionHintHeader="questionHintHeader"
    :secondSubsection="secondSubsection"
    :secondSubsectionHeader="secondSubsectionHeader"
    :thirdSubsection="thirdSubsection"
    :thirdSubsectionHeader="thirdSubsectionHeader"
    :fourthSubsection="fourthSubsection"
    :fourthSubsectionHeader="fourthSubsectionHeader"
    @save-clicked="save()"
    @save-continue-clicked="saveContinue()"
    @continue-clicked="navigateToReview()"
    :disabledSave="isSaveDisabled"
    :isFinalStep="isFinalStep"
    :mainHeader="mainHeader"
    :subHeader="subHeader"
    :showDefaultButtons="showDefaultButtons"
    showRequiredLegend
    :hideButtons="noShowButtons"
  >
    <v-row class="py-3 no-gutters">
      <v-col cols="12">
        <tesControlTemplate required label="Upload required documents">
          <span class="pt-2" v-if="docText" v-html="docText" />
          <span>
            {{ uiFeature.VLD_DOCEXT.largeText }}
          </span>
          <DocUploader
            @uploaded="
              isSingleFlow() ? getUploadedDocList() : loadDocsByProgram()
            "
            :docsMaxNumber="docsMaxNumber"
            ref="docUploader"
            :documentTypeCode="documentTypeCode"
            :docDescCodes="docDescCodes"
            @docErrorMessage="val => emitDocErrorMessage(val)"
            class="mt-2"
            :ariaLabel="uploaderAriaLabel"
          />

          <v-list
            three-line
            flat
            :class="
              $vuetify.breakpoint.smAndDown
                ? 'documentList'
                : 'documentListWide'
            "
            style="width:100%"
          >
            <v-list-item v-if="docs.length < 1">
              <v-list-item-content>
                <v-list-item-title class="text-xs-center">
                  No documents are attached to the application
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <template v-for="(doc, index) in docs">
              <DocumentRow
                v-if="doc.documentTypeCode == documentTypeCode"
                :key="doc.attachmentId"
                :doc="doc"
                :showHeaders="index === 0"
                :docDescCodes="docDescCodes"
                :documentTypeCode="documentTypeCode"
                :hideDeleteOptn="hideDeleteBtn(doc)"
                :index="index"
                :isEcs="true"
                @removeDoc="removeDoc(index)"
              />
            </template>
          </v-list>
        </tesControlTemplate>
        <DocCheckBoxSave :documentTypeCode="documentTypeCode" />
        <v-progress-linear v-if="isDocListLoadding" indeterminate />
      </v-col>
    </v-row>
  </tes-container>
</template>

<script>
import Vue from "vue";
import store from "@/store";
import DocumentRow from "@/components/common/DocumentRow.vue";
import DocUploader from "@/components/common/DocUploader.vue";
import DocCheckBoxSave from "@/components/common/DocCheckBoxSave.vue";
import tesContainer from "@/components/common/TESContainer.vue";
import { TES_APP_ENDPOINT } from "@/constants/EndPoints";
import DraftProgramMixin from "@/mixins/DraftProgramMixin.vue";
import StepperNavigationMixinVue from "@/mixins/StepperNavigationMixin.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import tesControlTemplate from "@/components/common/TESControlTemplate.vue";
import axios from "axios";
import router from "@/router";

export default Vue.extend({
  name: "Attachments",
  components: {
    DocumentRow,
    DocUploader,
    DocCheckBoxSave,
    tesContainer,
    tesControlTemplate
  },
  mixins: [
    DraftProgramMixin,
    StepperNavigationMixinVue,
    UIFeatureMixinVue,
    AppPropsMixin
  ],
  data() {
    return {
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: "file"
    };
  },
  props: {
    docDescCodes: { type: Array, default: null },
    documentTypeCode: { type: String, default: null },
    isFinalStep: { type: Boolean, default: true },
    noShowButtons: { type: Boolean, default: false },
    mainHeader: { type: String, default: null },
    subHeader: { type: String, default: null },
    docText: { type: String, default: null },
    showDefaultButtons: { type: Boolean, default: false },
    questionHintText: { type: String, default: null },
    questionHintHeader: { type: String, default: "" },
    documentTypeCodes: { type: Array, default: null },
    attachmentsProgType: { type: String, default: "" },
    secondSubsectionHeader: { type: String, default: "" },
    secondSubsection: { type: String, default: "" },
    thirdSubsectionHeader: { type: String, default: "" },
    thirdSubsection: { type: String, default: "" },
    fourthSubsectionHeader: { type: String, default: "" },
    fourthSubsection: { type: String, default: "" },
    uploaderAriaLabel: { type: String, default: "" }
  },
  computed: {
    docs: {
      get() {
        return store.getters.attachmentsModule.getProfileDocs;
      },
      set(newValue) {
        store.commit.attachmentsModule.SET_DOC_LIST(newValue);
      }
    },
    isDocListLoadding() {
      return store.getters.attachmentsModule.getDocListLoadingStatus;
    },
    docsMaxNumber() {
      let maxNumber = 0;
      if (this.docs) {
        this.docs.forEach(d => {
          let key = d.documentTypeCode;
          let docNumber = parseInt(key.charAt(key.length - 1));
          if (maxNumber < docNumber) {
            maxNumber = docNumber;
          }
        });
      }
      return maxNumber;
    },
    draftProgramInfo: {
      get() {
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      },
      set(newValue) {
        store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(newValue);
      }
    },
    applicantProgramInfo() {
      return store.getters.applicantProgramModule.getApplicantProgram;
    },
    userInfo() {
      return store.getters.tesUserModule.getTesUser;
    },
    applicantProfile() {
      return store.getters.applicantProfileModule.getApplicantProfile;
    },
    submittedDocs: {
      get() {
        return store.getters.attachmentsModule.getSubmittedDocsList;
      },
      set(newValue) {
        store.commit.attachmentsModule.SET_SUBMITTED_DOCS_LIST(newValue);
      }
    },
    getAtchmtCheckboxSltdList() {
      return store.getters.attachmentsModule.getAtchmtCheckboxSltdList;
    },
    isAtchmtCheckboxSltd() {
      return this.getAtchmtCheckboxSltdList.find(
        o =>
          o.documentTypeCode === this.documentTypeCode && o.noUploadInd === "Y"
      );
    },
    getNumOfDocsAttchd() {
      return store.getters.attachmentsModule.getNumOfDocsAttchd;
    },
    isSaveDisabled() {
      let disableSave = true;
      if (
        this.attachmentsProgType == "SSR" ||
        this.attachmentsProgType == "RFTP"
      ) {
        disableSave = this.getNumOfDocsAttchd > 1 ? false : true;
      } else if (this.attachmentsProgType === "ADA") {
        disableSave =
          this.getNumOfDocsAttchd >= this.getDocCountforADAIndvFlowSepta
            ? false
            : true;
      } else {
        disableSave =
          this.docs.length > 0 || this.isAtchmtCheckboxSltd ? false : true;
      }
      return disableSave;
    }
  },
  created() {
    if (
      //@ts-ignore
      this.isSingleFlow()
    ) {
      this.singleFlowFinalStep = true;
      this.getUploadedDocList();
    } else {
      this.showDefaultButtons = true;
      this.singleFlowFinalStep = false;
      if (this.attachmentsProgType !== "ADA") {
        this.loadDocsByProgram();
      }
    }
  },
  methods: {
    removeDoc(index) {
      this.$delete(this.docs, index);
      this.compareAllDocsAttached();
    },
    getUploadedDocList() {
      const url = TES_APP_ENDPOINT.GET_DOC_LIST_BY_PROFILEID;
      axios
        .get(url, {
          params: {
            applicantProfileId:
              store.getters.applicantProfileModule.getApplicantProfile
                .applicantProfileId
          }
        })
        .then(response => {
          this.docs = response.data;
          if (
            this.applicantProgramInfo &&
            this.applicantProgramInfo.cycleNumber > 0
          ) {
            this.getSubmittedDocs();
          }
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        });
    },
    navigateToReview() {
      this.saveProgramInformation("CONTINUE");
    },
    save() {
      this.saveProgramInformation("SAVE");
    },
    saveContinue() {
      this.saveProgramInformation("SAVECONTINUE");
    },
    saveProgramInformation(buttonName) {
      this.showProgressBar = true;
      if (
        //@ts-ignore
        this.isSingleFlow()
      ) {
        if (buttonName === "CONTINUE") {
          if (
            //@ts-ignore
            this.isApplicantCorrectionsStatus()
          ) {
            //@ts-ignore
            this.saveDraftProgramInfo();
          }
          this.showProgressBar = false;
          let nextProgram = "";
          //@ts-ignore
          nextProgram = this.nextProgram("DOCS", "");
          router.push({
            path: "/application-program/" + nextProgram
          });
        }
      } else {
        let stepperIndex = 3;
        //@ts-ignore
        this.saveProgramInfo(this.draftProgramInfo, stepperIndex, 3, "SAVE")
          .then(response => {
            let tempDraftProgramInfo = response.data;
            store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(
              tempDraftProgramInfo
            );

            store.commit.applicantProgramModule.SET_APPLICANT_PROGRAM(
              tempDraftProgramInfo.programInfo
            );
          })
          .catch(error => {
            store.dispatch.alertModule.showAlertMessageById(
              error.response.data.id
            );
          })
          .finally(() => {
            this.showProgressBar = false;
          });
        router.push({
          path:
            "/application-review/" + this.applicantProgramInfo.programTypeCode,
          query: {
            profileID: String(this.applicantProgramInfo.applicantProfileId),
            prgmType: this.applicantProgramInfo.programTypeCode,
            agencyType: this.applicantProgramInfo.transitAgencyCode
          }
        });
      }
    },
    saveDraftProgramInfo() {
      this.showProgressBar = true;
      let url = TES_APP_ENDPOINT.SAVE_DRAFT_PRGM_INFO;
      //@ts-ignore
      this.draftProgramInfo.applicantProfileId = this.applicantProfile.applicantProfileId;
      //@ts-ignore
      this.draftProgramInfo.programInfo = this.applicantProgramInfo;
      //@ts-ignore
      draftProgramInfo.userInfo = this.userInfo;
      //@ts-ignore
      this.draftProgramInfo.programStatus = this.uiFeature.RDY_SBMT_C.displayName;

      axios
        .post(url, this.draftProgramInfo)
        .then(response => {
          //@ts-ignore
          store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(
            response.data
          );
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    },
    emitDocErrorMessage(value) {
      this.$emit("docErrorMessage", value);
    },
    loadDocsByProgram() {
      let payload = {
        applicantProfileId: this.applicantProgramInfo.applicantProfileId,
        docTypeCodes:
          this.attachmentsProgType === "ADA"
            ? this.getDocTypeCodesApplied().join(",")
            : this.documentTypeCodes.join(",")
      };
      store.dispatch.attachmentsModule.getDocListByProfileId(payload);
      store.dispatch.attachmentsModule.getAtchmtChkBoxSltdByProfileId(
        this.applicantProgramInfo.applicantProfileId
      );
    },
    getSubmittedDocs() {
      let payload = {
        tesApplicationId: this.applicantProgramInfo.applicationId
      };
      store.dispatch.attachmentsModule.getSubmittedDocList(payload);
    },
    hideDeleteBtn(document) {
      if (this.applicantProgramInfo.cycleNumber > 0) {
        let filteredDoc = this.submittedDocs.find(
          subDoc =>
            subDoc.attachmentId === document.attachmentId &&
            subDoc.cycleNumber === this.applicantProgramInfo.cycleNumber
        );
        return filteredDoc ? false : true;
      } else {
        return document.documentStatusCode === "SUBMITTED";
      }
    },
    displayAlertMessage() {
      //@ts-ignore
      this.showAlertMessage(
        //@ts-ignore
        this.uiFeature.ER_INLINE.largeText,
        "error"
      );
    }
  }
});
</script>

<style>
div.documentList > div > div {
  height: 135px;
}
div.documentList > div > div > a {
  height: 131px !important;
}
div.documentListWide > div > div {
  height: 75px;
}
div.documentListWide > div > div > a {
  height: 80px !important;
}
</style>
