
import Vue from "vue";
import axios from "axios";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import DraftProgramMixin from "@/mixins/DraftProgramMixin.vue";
import ProgressBar from "@/components/common/ProgressBar.vue";
import store from "@/store";
import {
  IApplicantProfile,
  IDashboardContent,
  IDraftProgramInfo,
  IProgramsList,
  IStepperHeaderList
} from "@/types";
import TESRadioControl from "@/components/common/TESRadioControl.vue";
import tesContainer from "@/components/common/TESContainer.vue";
import { TES_APP_ENDPOINT } from "@/constants/EndPoints";
import tesControlTemplate from "@/components/common/TESControlTemplate.vue";
import routerMixin from "@/mixins/RouterMixin.vue";
import StepperNavigationMixinVue from "@/mixins/StepperNavigationMixin.vue";
import { BUTTON_NAMES, CNST_TEXT } from "@/constants/CommonConstants";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import InfoSection from "@/components/common/InfoSection.vue";
import ScrollMixin from "@/mixins/ScrollMixin.vue";
import { GPC } from "@/constants/GPCCode";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";
import DynamicTextMixin from "@/mixins/DynamicTextMixin.vue";

export default Vue.extend({
  name: "ftp-program-info",
  mixins: [
    fieldEntryMixin,
    DraftProgramMixin,
    routerMixin,
    StepperNavigationMixinVue,
    UIFeatureMixinVue,
    AppPropsMixin,
    ScrollMixin,
    ApplicationMixin,
    DynamicTextMixin
  ],
  components: {
    tesContainer,
    TESRadioControl,
    ProgressBar,
    tesControlTemplate,
    InfoSection
  },
  props: {
    applicantAge: {
      type: Number
    },
    stepCount: {
      type: Number
    },
    step: { type: Number, default: 1 }
  },
  data() {
    return {
      showProgressBar: false,
      showDefaultButtons: true,
      steps: 3,
      agencyProviders: [] as Object[],
      isSeptaPresent: false,
      septaOptions: [] as Object[],
      showSeptaQues: false
    };
  },
  created() {
    this.showProgressBar = true;
    this.populateSteps();
    if (this.draftProgramInfo.ftpApplicationInd === "N") {
      this.showDefaultButtons = false;
    }
    let url = TES_APP_ENDPOINT.GET_TRANSIT_AGENCY_PROVIDERS;

    //@ts-ignore
    let applicantProfile =
      store.getters.applicantProfileModule.getApplicantProfile;
    let applicantCounty = applicantProfile.homeAddress.paCountyCode;
    axios
      .get(url, {
        params: { countyCode: applicantCounty, prgmCode: "FTP" }
      })
      .then(response => {
        //@ts-ignore
        if (applicantProfile.gisCallInd === "Y" && this.isSingleFlow()) {
          for (let agncyPrvdr of response.data) {
            if (
              applicantProfile.gisAgenciesList.includes(
                agncyPrvdr.transitAgencyCode
              )
            ) {
              this.agencyProviders.push(agncyPrvdr);
            }
          }
          this.showSeptaQuesInd();
        } else {
          this.agencyProviders = response.data;
        }
        if (this.agencyProviders && this.agencyProviders.length == 1) {
          //@ts-ignore
          this.draftProgramInfo.ftpAgencyCode = this.agencyProviders[0].transitAgencyCode;
        }
        this.showProgressBar = false;
      })
      .catch(error => {
        store.dispatch.alertModule.showAlertMessageById(error.response.data.id);
      });
  },
  methods: {
    saveContinue() {
      //@ts-ignore
      if (this.$refs.ftpProgramInfoTemplate.validate()) {
        this.saveFTPProgramInfo("SAVECONTINUE");
      } else {
        //@ts-ignore
        this.scrollToErrorField(this.$refs.ftpProgramInfoTemplate);
      }
    },
    save(save: string) {
      //@ts-ignore
      if (this.$refs.ftpProgramInfoTemplate.validate()) {
        this.saveFTPProgramInfo(save);
      } else {
        //@ts-ignore
        this.scrollToErrorField(this.$refs.ftpProgramInfoTemplate);
      }
    },
    cancel() {
      //@ts-ignore
      if (this.$refs.ftpProgramInfoTemplate.validate()) {
        this.saveFTPProgramInfo("CANCEL");
      } else {
        //@ts-ignore
        this.scrollToErrorField(this.$refs.ftpProgramInfoTemplate);
      }
    },
    saveFTPProgramInfo(buttonName: string) {
      //@ts-ignore
      if (this.$refs.ftpProgramInfoTemplate.validate()) {
        this.showProgressBar = true;
        let stepperIndex = 1;
        //@ts-ignore
        this.saveProgramInfo(
          this.draftProgramInfo,
          stepperIndex,
          this.stepCount,
          buttonName
        )
          //@ts-ignore
          .then(response => {
            this.draftProgramInfo = response.data;
            //@ts-ignore
            store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(
              this.draftProgramInfo
            );

            //@ts-ignore
            store.commit.applicantProgramModule.SET_APPLICANT_PROGRAM(
              this.draftProgramInfo.programInfo
            );
            for (let programEligModel of this.programEligModel
              .preEligiblePrograms) {
              if (
                programEligModel["programTypeCode"] === "FTP" ||
                programEligModel["transitAgencyCode"] === "AGY_PEND"
              ) {
                programEligModel[
                  "transitAgencyCode"
                ] = this.draftProgramInfo.programInfo.transitAgencyCode;
                programEligModel[
                  "transiAgencyName"
                ] = this.draftProgramInfo.programInfo.transiAgencyName;
                programEligModel[
                  "agencyEmailAddress"
                ] = this.draftProgramInfo.programInfo.agencyEmailAddress;
              }
            }
            this.updateStepperHeader();

            if (
              //@ts-ignore
              this.isSingleFlow() ||
              buttonName === BUTTON_NAMES.SAVE ||
              buttonName === BUTTON_NAMES.CANCEL
            ) {
              this.$emit("save-completed", buttonName);
            } else {
              //@ts-ignore
              this.forwardToDashboard();
            }
          })
          //@ts-ignore
          .catch(error => {
            store.dispatch.alertModule.showAlertMessageById(
              error.response.data.id
            );
          })
          .finally(() => {
            this.showProgressBar = false;
          });
      }
    },
    changeValues() {
      this.showDefaultButtons = true;
      this.populateSteps();

      if (this.draftProgramInfo.ftpApplicationInd === "N") {
        this.showDefaultButtons = false;
        this.steps = 1;
      }
      this.$emit("step-count", this.steps);
    },
    populateSteps() {
      if (
        //@ts-ignore
        this.isSingleFlow()
      ) {
        if (
          //@ts-ignore
          this.isProgramExist("SSR") &&
          //@ts-ignore
          (!this.isByPassPrgmChkd() ||
            (this.applicantProfile.homeAddress.paCountyCode === "02" &&
              this.draftProgramInfo.ftpAgencyCode == "PAAC" &&
              this.draftProgramInfo.ftpApplicationInd === "Y"))
        ) {
          this.steps = 1;
        } else {
          this.chkFTPApplInd();
        }
      } else {
        this.populateStepsElse();
      }
      this.$emit("step-count", this.steps);
    },
    populateStepsElse() {
      if (
        this.draftProgramInfo.ageVerificationMethodCode === "Y" &&
        this.draftProgramInfo.driverLicenseVerifyCode === "VRFD"
      ) {
        this.steps = 2;
      } else {
        this.steps = 3;
      }
    },
    chkFTPApplInd() {
      if (this.draftProgramInfo.ftpApplicationInd === "N") {
        this.steps = 1;
      } else {
        this.steps = 2;
      }
    },
    updateStepperHeader() {
      this.stepperHeaderMod.stepperHeaders.forEach(element => {
        if (
          element.programType === "FTP" ||
          element.agencyCode === "AGY_PEND"
        ) {
          element.agencyCode = this.draftProgramInfo.programInfo.transitAgencyCode;
          let providerName: string;

          providerName =
            this.draftProgramInfo.programInfo.transitAgencyCode !== "CPTA"
              ? this.draftProgramInfo.programInfo.transiAgencyName.toUpperCase()
              : this.draftProgramInfo.programInfo.transiAgencyName;
          element.description =
            "APPLICATION FOR " +
            this.draftProgramInfo.programInfo.programTypeCode +
            " WITH " +
            providerName;
          // ADD SEPTA agency to header if SEPTA question is selected as yes
          if (
            this.draftProgramInfo.transitIdCardApplyInd &&
            this.draftProgramInfo.transitIdCardApplyInd === "Y"
          ) {
            element.description = "and " + " SEPTA";
          }
        }
      });
    },
    selectedAgencyProvider(value: string) {
      if (this.checkForSepta() && value !== "SEPTA") {
        this.showSeptaQues = true;
      } else {
        this.draftProgramInfo.transitIdCardApplyInd = "";
        this.showSeptaQues = false;
      }
      this.populateSteps();
    },
    showSeptaQuesInd() {
      if (
        this.agencyProviders &&
        this.agencyProviders.length != 1 &&
        this.checkForSepta()
      ) {
        if (
          this.draftProgramInfo.ftpApplicationInd === "Y" &&
          this.draftProgramInfo.ftpAgencyCode != "SEPTA"
        ) {
          this.showSeptaQues = true;
        }
      }
    },
    checkForSepta() {
      let septaPresent = false;
      this.agencyProviders.forEach(obj => {
        //@ts-ignore
        if (obj.transitAgencyCode === "SEPTA") {
          septaPresent = true;
        }
      });
      return septaPresent;
    }
  },
  computed: {
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        //@ts-ignore
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      },
      set(newValue: IDraftProgramInfo) {
        //@ts-ignore
        store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(newValue);
      }
    },
    programEligModel: {
      get(): any {
        //@ts-ignore
        return store.getters.programEligModule.getProgramElig;
      }
    },
    infoMessageHeaderAge(): string {
      return `Based on the information you provided, you are ${this.applicantAge} years old`;
    },
    stepperHeaderMod: {
      get(): IStepperHeaderList {
        //@ts-ignore
        return store.getters.stepperHeaderModule.getStepperHeaderModel;
      }
    },
    applicantProfile: {
      get(): IApplicantProfile {
        //@ts-ignore
        return store.getters.applicantProfileModule.getApplicantProfile;
      }
    },
    ftpInfo(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.FTP_SEC1].largeText,
          CNST_TEXT.THE_APPLICANT,
          CNST_TEXT.THEY,
          CNST_TEXT.THEIR,
          CNST_TEXT.THEIR
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.FTP_SEC1].largeText,
          CNST_TEXT.YOU,
          CNST_TEXT.YOU,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOUR
        );
      }
      return text;
    },
    ftpApplyIndQues(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrgAgyCtznonBehalfOfUser()
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.FTP_INFO].largeText,
          CNST_TEXT.DOES_THE_APPLICANT,
          CNST_TEXT.THEIR,
          CNST_TEXT.THEY,
          CNST_TEXT.THEM
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.FTP_INFO].largeText,
          CNST_TEXT.DO_YOU,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOU,
          CNST_TEXT.YOU
        );
      }
      return text;
    },
    septaFixedRouteQues(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrgAgyCtznonBehalfOfUser()
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.FTP_SEPT_Q].largeText,
          CNST_TEXT.DOES_THE_APPLICANT
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.FTP_SEPT_Q].largeText,
          CNST_TEXT.DO_YOU
        );
      }
      return text;
    },
    rftpProviderQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.FtpRftpProviderQues();
      return text;
    },
    ftpDoNotQualifyText(): string {
      //@ts-ignore
      return this.ftpDoNotQualifyDynamicText(
        //@ts-ignore
        this.uiFeature[GPC.YNT_FTP1].largeText
      );
    },
    applicantProgramInfo(): IProgramsList {
      return store.getters.applicantProgramModule.getApplicantProgram;
    },
    isMultiFTPAndSepta(): boolean {
      let count: number = 0;
      let isSepta: boolean = false;
      let submittedPrograms;
      if (
        this.programEligModel.submittedPrograms !== null &&
        this.programEligModel.submittedPrograms.length > 0
      ) {
        submittedPrograms = this.programEligModel.submittedPrograms;
      } else {
        submittedPrograms = this.dashboardContent.programEligModel
          .submittedPrograms;
      }

      if (submittedPrograms && submittedPrograms.length > 1) {
        submittedPrograms.forEach((item: any) => {
          if (item.programTypeCode === "FTP") {
            count++;
          }
          if (item.transitAgencyCode === "SEPTA") {
            isSepta = true;
          }
        });
      }
      return count > 1 && isSepta;
    },
    isFTPReadOnly(): boolean {
      if (
        this.isMultiFTPAndSepta &&
        (this.applicantProgramInfo.statusCode === "RE_COR" ||
          this.applicantProgramInfo.statusCode === "IN_PRGS_C")
      ) {
        return true;
      } else return false;
    },
    dashboardContent(): IDashboardContent {
      return store.getters.dashboardModule.getDashboardContent;
    },
    showDLUploadMessage(): boolean {
      return (
        this.applicantProfile.homeAddress.paCountyCode === "02" &&
        this.draftProgramInfo.ftpAgencyCode == "PAAC" &&
        this.draftProgramInfo.ftpApplicationInd === "Y"
      );
    },
    finalStep() {
      return this.step === this.stepCount;
    }
  }
});
