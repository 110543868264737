
import Vue from "vue";
import store from "@/store";
import { IDraftProgramInfo } from "@/types";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import ProgramInfoSummaryMixin from "@/mixins/ProgramInfoSummaryMixin.vue";
import readonlyField from "@/components/common/ReadOnlyField.vue";
import { GPC } from "@/constants/GPCCode";
import DynamicTextMixin from "@/mixins/DynamicTextMixin.vue";
import { CNST_TEXT } from "@/constants/CommonConstants";

export default Vue.extend({
  name: "FTP-General-Information",
  mixins: [UIFeatureMixinVue, ProgramInfoSummaryMixin, DynamicTextMixin],
  components: { readonlyField },
  computed: {
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        //@ts-ignore
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      }
    },
    driverLicense(): string {
      let driverLicense = `Drivers License or PA ID #: ${this.draftProgramInfo.driverLicenseNumber}`;

      if (this.draftProgramInfo.ageVerificationMethodCode === "N") {
        //@ts-ignore
        driverLicense = this.uiFeature.DL_INVALID.largeText;
      }

      return driverLicense;
    },
    driverLicensePrevious(): string {
      //@ts-ignore
      let driverLicensePrevious = this.programInfoPrevious.driverLicenseNumber;

      //@ts-ignore
      if (this.programInfoPrevious.ageVerificationMethodCode === "N") {
        //@ts-ignore
        driverLicensePrevious = this.uiFeature.DL_INVALID.largeText;
      }

      return driverLicensePrevious;
    },
    verifyAgeDsplyNameQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithOnePlaceHolderVal(
        GPC.VRFY_AGE_Q,
        CNST_TEXT.THE_APPLICANTS,
        CNST_TEXT.YOUR
      );
      return text;
    }
  }
});
