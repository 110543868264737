
import Vue from "vue";
import ApplicantProfileMixin from "@/mixins/ApplicantProfileMixin.vue";
import readonlyField from "@/components/common/ReadOnlyField.vue";
import store from "@/store";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import {
  IApplicantAddress,
  IApplicationReviewModel,
  IISAMUserAttributes,
  IStgEcolaneCustProfileModel
} from "@/types";
import { TES_APP_ENDPOINT } from "@/constants/EndPoints";
import axios from "axios";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import dateHelperMixin from "@/mixins/dateHelperMixin.vue";
import AgencyReviewMixinVue from "@/mixins/AgencyReviewMixin.vue";
import EcolaneDiff from "@/components/common/EcolaneDiff.vue";
import { applicantAddress } from "@/data/AppData";
import { clone } from "@/util/clone";

export default Vue.extend({
  name: "review-recommendation-summary",
  components: { readonlyField, EcolaneDiff },
  mixins: [
    ApplicantProfileMixin,
    UIFeatureMixinVue,
    ApplicationMixin,
    AppPropsMixin,
    dateHelperMixin,
    AgencyReviewMixinVue
  ],
  data() {
    return {
      isamUserAttributes: {} as IISAMUserAttributes
    };
  },
  created() {
    //@ts-ignore
    this.getUserAttributesById(this.applicationReviewModel.reviewerUserId);
  },
  computed: {
    applicationReviewModel(): IApplicationReviewModel {
      return store.getters.applicationReviewModule.getApplicationReviewModel;
    },
    applicationReviewModelPrevious: {
      get(): IApplicationReviewModel {
        return store.getters.applicationReviewModule
          .getApplicationPreviousReviewModel;
      }
    },
    reviewedBy(): string {
      let reviewedBy = "";
      let isamUserAttributesJson = JSON.parse(
        JSON.stringify(this.isamUserAttributes)
      );
      if (isamUserAttributesJson && isamUserAttributesJson.attributeDetails) {
        reviewedBy =
          isamUserAttributesJson.attributeDetails["FIRST_NAME"] +
          " " +
          isamUserAttributesJson.attributeDetails["LAST_NAME"];
      }
      return reviewedBy;
    },
    stgEcolaneCustProfile: {
      get(): IStgEcolaneCustProfileModel {
        return store.getters.applicantEcolaneProfileModule
          .getStgEcolaneCustProfile;
      },
      set(newObject: IStgEcolaneCustProfileModel) {
        store.commit.applicantEcolaneProfileModule.SET_STG_ECOLANE_CUST_PROFILE(
          newObject
        );
      }
    }
  },
  methods: {
    getUserAttributesById(userId: number) {
      let url = TES_APP_ENDPOINT.ISAM_GET_USERATTRIBUTES_BYID;
      axios
        .get(url, {
          params: {
            userId: userId
          }
        })
        .then(response => {
          this.isamUserAttributes = response.data;
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        });
    },
    populateHomeAddressObj(
      newVal: IStgEcolaneCustProfileModel
    ): IApplicantAddress {
      let varHomeAddress: IApplicantAddress = clone(applicantAddress);
      varHomeAddress.street = newVal.homeAddressStreet;
      varHomeAddress.streetNumber = newVal.homeAddressStreetNum;
      varHomeAddress.apartmentNumber = newVal.homeAddressAptNum;
      varHomeAddress.postcode = newVal.homeAddressZip;
      varHomeAddress.city = newVal.homeAddressCity;
      varHomeAddress.state = newVal.homeAddressState;
      return varHomeAddress;
    },
    populateMailAddressObj(
      newVal: IStgEcolaneCustProfileModel
    ): IApplicantAddress {
      let varMailAddress: IApplicantAddress = clone(applicantAddress);
      varMailAddress.street = newVal.mailAddressStreet;
      varMailAddress.streetNumber = newVal.mailAddressStreetNum;
      varMailAddress.apartmentNumber = newVal.mailAddressAptNum;
      varMailAddress.postcode = newVal.mailAddressZip;
      varMailAddress.city = newVal.mailAddressCity;
      varMailAddress.state = newVal.mailAddressState;
      return varMailAddress;
    }
  },
  watch: {
    stgEcolaneCustProfile: {
      deep: true,
      handler(newVal: IStgEcolaneCustProfileModel) {
        if (newVal.stgEcolaneCustProfileId) {
          this.stgEcolaneCustProfile.homeAddress = this.populateHomeAddressObj(
            newVal
          );
          this.stgEcolaneCustProfile.mailAddress = this.populateMailAddressObj(
            newVal
          );
          //@ts-ignore
          this.showDiffSection = this.showDiffSectionMethod;
        }
      }
    }
  }
});
