
import Vue from "vue";

import SurrogateDialog from "@/components/dashboard/SurrogateDialog.vue";
import axios from "axios";
import { HTTP_STATUS } from "@/constants/HttpStatusCode";
import { DASHBOARD_ENDPOINT, TES_APP_ENDPOINT } from "@/constants/EndPoints";
import store from "@/store";
import {
  IOrgDashboardContent,
  ITESUser,
  IOrgDashboardReqModel,
  ITESApplicationModel,
  IApplicationNavigation,
  IAdvnacedSearchResultObj
} from "@/types";
import ProgressBar from "@/components/common/ProgressBar.vue";
import router from "@/router";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import DateHelperMixinVue from "@/mixins/dateHelperMixin.vue";
import { STATUS_CODES } from "@/constants/CommonConstants";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";

export default Vue.extend({
  name: "dashboard-org",
  mixins: [UIFeatureMixinVue, DateHelperMixinVue, AppPropsMixin],
  components: {
    SurrogateDialog,
    ProgressBar
  },
  data() {
    return {
      showSurrogateDialog: false,
      displaySave: false,

      items: [
        { text: "My Applicants", index: 0 },
        { text: "All Applicants", index: 1 }
      ],

      tab: 0 as number,
      orgDashboardContent: [] as IOrgDashboardContent[],
      orgDashboardReqModel: {} as IOrgDashboardReqModel,
      agencyStatusContent: [] as IAdvnacedSearchResultObj[],
      headers: [
        { text: "Applicant Name", value: "applicantLastName", width: "15%" },
        { text: "Profile Status", value: "profileStatus", width: "15%" },
        {
          text: "Application Status",
          value: "applicationStatus",
          width: "16%"
        },
        { text: "Application ID", value: "applicationId", width: "15%" },
        { text: "Program", value: "program", width: "13%" },

        { text: "Agency", value: "agency", width: "13%" },

        { text: "Status Date", value: "statusDate", width: "12%" }
      ],
      headersAllApplicants: [
        { text: "Applicant Name", value: "applicantLastName", width: "15%" },
        { text: "Profile Status", value: "profileStatus", width: "12.5%" },
        {
          text: "Application Status",
          value: "applicationStatus",
          width: "16%"
        },
        { text: "Application ID", value: "applicationId", width: "13.5%" },
        { text: "Program", value: "program", width: "10%" },

        { text: "Agency", value: "agency", width: "9%" },

        { text: "Status Date", value: "statusDate", width: "12.5%" },
        { text: "Created By", value: "createdBy", width: "11.5%" }
      ],

      showProgressBar: true,

      tesApplicationModel: {} as ITESApplicationModel,
      selectedExpansion: 100 as number
    };
  },

  computed: {
    userInfo: {
      get(): ITESUser {
        //@ts-ignore
        return store.getters.tesUserModule.getTesUser;
      }
    },
    applicationNavigation: {
      get(): IApplicationNavigation {
        //@ts-ignore
        return store.getters.applicationNavigationModule
          .getApplicationNavigation;
      },
      set(newValue: IApplicationNavigation) {
        //@ts-ignore
        store.commit.applicationNavigationModule.SET_APPLLICATION_NAVIGATION(
          newValue
        );
      }
    }
  },
  created() {
    this.getDashboardOrgContent(0);
  },
  methods: {
    getHeaders: function(index: number, headerType: string) {
      if (headerType === "create") {
        if (index === 0) {
          return this.headers;
        } else {
          return this.headersAllApplicants;
        }
      }
    },
    closeSurrogateDialog() {
      this.showSurrogateDialog = false;
    },
    addNewPerson() {
      this.showSurrogateDialog = true;
    },
    forwardToOrgUserDashboard(
      applicantProfileId: string,
      screeningResponseId: string
    ) {
      router.push({
        name: "dashboardOrgUser",
        query: {
          applicantProfileId: applicantProfileId,
          screeningResponseId: screeningResponseId
        }
      });
    },

    navigateToProgram(
      applicationStatus: string,
      applicationId: string,
      applicantProfileId: string,
      programTypeCode: string,
      transitAgencyCode: string
    ) {
      this.applicationNavigation.applProfileEdit = true;
      //@ts-ignore
      store.commit.applicationNavigationModule.SET_APPLLICATION_NAVIGATION(
        this.applicationNavigation
      );

      if (
        applicationStatus !== null &&
        (applicationStatus === STATUS_CODES.SBMTD ||
          applicationStatus === STATUS_CODES.RDY_SBMT)
      ) {
        if (
          applicationId !== null &&
          applicationId !== undefined &&
          applicationId.length !== 0
        ) {
          this.forwardSubmittedApplicationToSummary(applicationId);
        }
      } else {
        if (
          applicantProfileId !== null &&
          applicantProfileId !== undefined &&
          applicantProfileId.length !== 0
        ) {
          this.forwardBeforeSubmittedApplicationToSummary(
            applicantProfileId,
            programTypeCode,
            transitAgencyCode
          );
        }
      }
    },

    forwardSubmittedApplicationToSummary(applicationId: string) {
      let url = TES_APP_ENDPOINT.GET_COMPLETE_PROFILE_DETAILS_BY_APP_ID;
      axios
        .get(url, {
          params: { tesApplicationId: applicationId }
        })
        .then(response => {
          this.tesApplicationModel = response.data;
          if (response && response.data) {
            store.commit.appModule.SET_DOB_MATCH(response.data.nameDOBMatchInd);
          }
          this.forwardToApplicationSummary(this.tesApplicationModel);
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    },
    async forwardToApplicationSummary(tesAppModel: ITESApplicationModel) {
      //@ts-ignore
      store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(
        tesAppModel.draftProgramInfoModel
      );

      //@ts-ignore
      store.commit.applicantProgramModule.SET_APPLICANT_PROGRAM(
        tesAppModel.programEligibilityModel
      );

      //@ts-ignore
      store.commit.applicantProfileModule.SET_APPLICANT_PROFILE(
        tesAppModel.applicantProfileModel
      );
      //Get the Driver license details
      store.dispatch.applicantProfileModule.getDriverLicenseDetails(
        //@ts-ignore
        store.getters.draftProgramInfoModule.getDraftProgramInfo
      );
      await store.dispatch.applicationReviewModule.loadApplicationReviewModel({
        tesApplicationId: tesAppModel.applicantionId,
        cycleNumber: tesAppModel.cycleNumber
      });

      // route to Application review page
      router.push({
        name: "application-review",
        //@ts-ignore
        params: { program: tesAppModel.programTypeCode }
      });
    },
    forwardBeforeSubmittedApplicationToSummary(
      applicantProfileId: string,
      programTypeCode: string,
      transitAgencyCode: string
    ) {
      let url = TES_APP_ENDPOINT.GET_COMPLETE_PROFILE_DETAILS_BY_APP_PROFILE_ID;
      axios
        .get(url, {
          params: {
            applicantProfileId: applicantProfileId,
            programTypeCode: programTypeCode,
            transitAgencyCode: transitAgencyCode
          }
        })
        .then(response => {
          this.tesApplicationModel = response.data;

          this.tesApplicationModel.programTypeCode = programTypeCode;

          this.forwardToApplicationSummary(this.tesApplicationModel);
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    },

    forwardToProfileSummary(profileStatus: string) {
      router.push({
        name: "reviewProfileInfo"
      });
    },

    getDashboardOrgContent(index: number) {
      this.showProgressBar = true;

      this.orgDashboardReqModel.userId = this.userInfo.userId;
      this.orgDashboardReqModel.tabId = index;
      this.orgDashboardReqModel.feinHash = this.userInfo.fein;

      let url = DASHBOARD_ENDPOINT.GET_ORG_DASHBOARD_CONTENT;
      axios
        .post(url, this.orgDashboardReqModel)
        .then(response => {
          if (response.status === HTTP_STATUS.OK) {
            this.orgDashboardContent = response.data;
          }
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    }
  }
});
