
import Vue from "vue";
import { IScrAnswers, IScreeningQuestions, IApplicantDetails } from "@/types";
import store from "@/store";
import router from "@/router";
import ScreeningPrograms from "@/components/questions/ScreeningPrograms.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import FieldEnrtyMixin from "@/mixins/fieldEntryMixin.vue";
import DateHelperMixin from "@/mixins/dateHelperMixin.vue";
import { expansionPanelIconObj } from "@/constants/CommonConstants";

export default Vue.extend({
  name: "screen-questions-results",
  components: {
    ScreeningPrograms
  },
  mixins: [UIFeatureMixinVue, FieldEnrtyMixin, DateHelperMixin],
  data() {
    return {
      seen: false,
      showhideText: expansionPanelIconObj.showText,
      showHideIcon: "fa-expand-alt"
    };
  },
  computed: {
    scrAnswers: {
      get(): IScrAnswers {
        return store.getters.screenQuesModule.getScrAnswers;
      },
      set(scrAnswers: IScrAnswers) {
        store.commit.screenQuesModule.SET_SCRANSWERS(scrAnswers);
      }
    },
    questions: {
      get(): IScreeningQuestions[] {
        return store.getters.screenQuesModule.getQuestions;
      },
      set(newValue: IScreeningQuestions[]) {
        store.commit.screenQuesModule.SET_QUESTIONS(newValue);
      }
    },
    stepIndex: {
      get(): number {
        return store.getters.screenQuesModule.getStepInd;
      },
      set(newValue: number) {
        store.commit.screenQuesModule.SET_STEPIND(newValue);
      }
    },
    applicantDetails(): IApplicantDetails {
      return store.getters.applicantDetailsModule.getApplicantDetails;
    }
  },
  methods: {
    changeAnswerActionAriaLabel(lineItem: string): string {
      let result: string = "";
      switch (lineItem) {
        case "SQ1":
          result = "Change home address answer";
          break;
        case "SQ2":
          result = "Change CATA service answer";
          break;
        case "SQ3":
          result = "Change date of birth answer";
          break;
        case "SQ4":
          result = "Change Medical Assistance answer";
          break;
        case "SQ5":
          result = "Change disability answer";
          break;
        case "SQ7":
          result = "Change veteran answer";
          break;
        case "SQ8":
          result = "Change Medicare Card answer";
          break;
      }
      return result;
    },
    changeQuestion(stepIndex: number) {
      store.commit.screenQuesModule.SET_STEPIND(stepIndex);
      router.push({ name: "screeningQuestions" });
    },
    showHide() {
      if (!this.seen) {
        this.seen = true;
        this.showhideText = expansionPanelIconObj.hideText;
        this.showHideIcon = "fa-compress-alt";
      } else {
        this.seen = false;
        this.showhideText = expansionPanelIconObj.showText;
        this.showHideIcon = "fa-expand-alt";
      }
    }
  }
});
