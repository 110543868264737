
import Vue from "vue";
import axios from "axios";
import { GENERAl_INFO_ENDPOINT } from "@/constants/EndPoints";
import { HTTP_STATUS } from "@/constants/HttpStatusCode";
export default Vue.extend({
  el: "#app",
  data() {
    return {
      snackBarTextArr: [],
      snackBarText: "INFO",

      showSnackbar: false,
      snackBarColor: "info"
    };
  },

  mounted() {
    let url = GENERAl_INFO_ENDPOINT.GET_PRGMS_BY_USER;
    axios
      .get(url, { params: { userId: "1234" } })
      .then(response => {
        if (response.status === HTTP_STATUS.OK) {
          this.snackBarTextArr = response.data;
        } else {
          this.snackBarText = response.status + ": " + response.statusText;
          this.showSnackbar = true;
          this.snackBarColor = "info";
        }
      })

      .catch(error => {
        this.snackBarText =
          url +
          " : " +
          error.response.status +
          ": " +
          error.response.statusText;
        this.showSnackbar = true;
        this.snackBarColor = "error";
      })
      .finally(() => {
        //@ts-ignore
      });
  }
});
