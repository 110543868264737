
import Vue from "vue";
import router from "@/router";
import ClearStoreValuesMixin from "@/mixins/ClearStoreValuesMixin.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";

export default Vue.extend({
  name: "check-eligibility",
  mixins: [ClearStoreValuesMixin, UIFeatureMixinVue],
  methods: {
    getScreeningQuestions() {
      //@ts-ignore
      this.clearAllStoreValues();
      router.push({ name: "screeningQuestions" });
    }
  }
});
