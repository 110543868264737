
import Vue from "vue";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import tesProgramTemplate from "@/components/common/TESProgramTemplate.vue";
import {
  IApplicantProfile,
  IDraftProgramInfo,
  IProgramsList,
  IStepperHeaderList
} from "@/types";
import FTPProgramInfo from "@/components/application/program/ftp/FTPProgramInfo.vue";
import FTPDetailInfo from "@/components/application/program/ftp/FTPDetailInfo.vue";
import store from "@/store";
import Attachments from "@/components/application/program/Attachments.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import router from "@/router";
import StepperNavigationMixinVue from "@/mixins/StepperNavigationMixin.vue";
import AttachmentsMixinVue from "@/mixins/AttachmentsMixin.vue";
import { BUTTON_NAMES, CNST_TEXT } from "@/constants/CommonConstants";
import routerMixin from "@/mixins/RouterMixin.vue";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";
import { GPC } from "@/constants/GPCCode";

const SECTIONS = {
  FTP_INFO: "FTPINFO",
  FTP_DETAILS: "FTPDETAILS",
  ATTACHMENTS: "ATTACHMENTS"
};

export default Vue.extend({
  name: "program-ftp",
  mixins: [
    fieldEntryMixin,
    UIFeatureMixinVue,
    StepperNavigationMixinVue,
    routerMixin,
    AttachmentsMixinVue,
    ApplicationMixin
  ],
  components: {
    tesProgramTemplate,
    FTPProgramInfo,
    FTPDetailInfo,
    Attachments
  },
  data: function() {
    return {
      stepNum: 0,
      headerMsg: "",
      documentTypeCodes: [] as string[],
      displayModel: [] as string[],
      SECTIONS
    };
  },
  methods: {
    saveCompleted(buttonName: string) {
      if (buttonName === "CANCEL") {
        this.continueToDashboard();
      } else if (this.stepNum !== this.stepCount) {
        this.headerMsg =
          "Application for " +
          this.applicantProgramInfo.programName +
          " with " +
          this.applicantProgramInfo.transiAgencyName;
        //@ts-ignore
        this.$refs.programFTPTemplate.nextStep();
        //@ts-ignore
        this.scrollToSectionHeader(this.$refs.programFTPTemplate);
      } else if (
        //@ts-ignore
        this.isSingleFlow()
      ) {
        this.forwardTo(buttonName);
      } else {
        router.push({
          path:
            "/application-review/" + this.applicantProgramInfo.programTypeCode
        });
      }
    },
    changeStepCount(_steps: number) {
      this.buildScreenModel();
    },
    changeStep(step: number) {
      this.stepNum = step;
    },
    continueToDashboard() {
      //@ts-ignore
      this.forwardToDashboard();
    },
    createdContd() {
      if (this.applicantProgramInfo.transitAgencyCode === "AGY_PEND") {
        this.headerMsg =
          "Application for FTP " + this.applicantProgramInfo.transiAgencyName;
      } else {
        this.headerMsg =
          "Application for " +
          this.applicantProgramInfo.programName +
          " with " +
          this.applicantProgramInfo.transiAgencyName;
        // ADD SEPTA agency to message header if SEPTA question is selected as yes
        if (
          this.draftProgramInfo.transitIdCardApplyInd &&
          this.draftProgramInfo.transitIdCardApplyInd === "Y"
        ) {
          this.headerMsg = this.headerMsg + " and " + " SEPTA";
        }
      }
      //@ts-ignore
      if (!this.isSingleFlow()) {
        //@ts-ignore
        this.documentTypeCodes = this.getFTPDocumentTypes();
      }
    },
    forwardTo(buttonName: string) {
      if (
        buttonName === BUTTON_NAMES.SAVECONTINUE ||
        buttonName === BUTTON_NAMES.CONTINUE
      ) {
        //@ts-ignore
        this.headerMsg = this.buildHeaderForAgencyPending("FTP");
        let nextProgram = "";
        //@ts-ignore
        nextProgram = this.nextProgram("FTP", "");
        if (nextProgram === "DOCS" || nextProgram === "REVIEW") {
          router.push({
            path: "/application-program/" + nextProgram,
            query: {
              profileID: String(this.applicantProgramInfo.applicantProfileId),
              prgmType: nextProgram
            }
          });
        } else {
          router.push({
            path: "/application-program/" + nextProgram,
            query: {
              profileID: String(this.applicantProgramInfo.applicantProfileId),
              prgmType: nextProgram,
              agencyType: this.applicantProgramInfo.transitAgencyCode
            }
          });
        }
      } else {
        //@ts-ignore
        this.headerMsg = this.buildHeaderForAgencyPending("FTP");
      }
    },
    buildScreenModel() {
      this.displayModel = [] as string[];

      // Add the base section (always display)
      this.displayModel.push(SECTIONS.FTP_INFO);

      // Add the FTP Details section if needed
      if (
        this.draftProgramInfo.ftpApplicationInd === "Y" &&
        !this.isPRTBypass &&
        // @ts-ignore
        (!this.isSingleFlow() ||
          // @ts-ignore
          this.isByPassPrgmChkd() ||
          // @ts-ignore
          !this.isProgramExist("SSR"))
      ) {
        this.displayModel.push(SECTIONS.FTP_DETAILS);
      }

      // Add the Attachments Section for Age Verification if needed
      if (
        this.draftProgramInfo.ftpApplicationInd === "Y" &&
        // @ts-ignore
        !this.isSingleFlow() &&
        (this.isPRTBypass ||
          !this.dlVerified ||
          this.draftProgramInfo.ageVerificationMethodCode === "N")
      ) {
        this.displayModel.push(SECTIONS.ATTACHMENTS);
      }
    },
    getSectionHeader(index: number) {
      let hdr = "";
      if (index > 0 && index < this.displayModel.length + 1) {
        switch (this.displayModel[index - 1]) {
          case SECTIONS.FTP_DETAILS:
            hdr = "FTP Detail Information";
            break;

          case SECTIONS.ATTACHMENTS:
            hdr = "Attach Documents for Age Verification";
            break;
        }
      }
      return hdr;
    }
  },
  computed: {
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        //@ts-ignore
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      },
      set(newValue: IDraftProgramInfo) {
        //@ts-ignore
        store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(newValue);
      }
    },
    applicantProgramInfo: {
      get(): IProgramsList {
        //@ts-ignore
        return store.getters.applicantProgramModule.getApplicantProgram;
      }
    },
    applicantAge: {
      get(): number {
        let applicantDOB =
          //@ts-ignore
          store.getters.applicantProfileModule.getApplicantProfile.applicantDob;
        let currentDate = new Date();
        let birthDate = new Date(applicantDOB);
        //@ts-ignore
        let difference: any = currentDate - birthDate;
        return Math.floor(difference / 31557600000);
      }
    },
    docDescCodes(): any {
      //@ts-ignore
      return this.uiFeature.AGE_PROOF.children;
    },
    stepperHeaderMod: {
      get(): IStepperHeaderList {
        //@ts-ignore
        return store.getters.stepperHeaderModule.getStepperHeaderModel;
      },
      set(newValue: IStepperHeaderList) {
        //@ts-ignore
        store.commit.stepperHeaderModule.SET_STEPPER_HEADER_MODEL(newValue);
      }
    },
    applicantProfile: {
      get(): IApplicantProfile {
        return store.getters.applicantProfileModule.getApplicantProfile;
      }
    },
    ageVerificationInfo(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.AGE_PROOF].largeText,
          CNST_TEXT.THE_APPLICANTS
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.AGE_PROOF].largeText,
          CNST_TEXT.YOUR
        );
      }
      return text;
    },
    isAlleghenyCounty(): boolean {
      if (this.applicantProfile.homeAddress.paCountyCode === "02") {
        return true;
      } else return false;
    },
    replaceText(): string {
      let abc;
      if (this.isAlleghenyCounty) {
        //@ts-ignore
        abc = this.uiFeature.SSR_DE_ALG.largeText;
        return abc.replace("font-weight-bold", "");
      } else {
        //@ts-ignore
        return this.uiFeature.AGE_PROF1.largeText;
      }
    },
    prfDsbltyInfoTwo(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_2].largeText,
          CNST_TEXT.THE
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_2].largeText,
          CNST_TEXT.YOUR
        );
      }
      return text;
    },
    prfDsbltyInfoHeaderTwo(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_2].displayName,
          CNST_TEXT.THE
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_2].displayName,
          CNST_TEXT.MY
        );
      }
      return text;
    },
    prfDsbltyInfoThree(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_3].largeText,
          CNST_TEXT.THE,
          CNST_TEXT.THE
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_3].largeText,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOUR
        );
      }
      return text;
    },
    stepCount(): number {
      return this.displayModel.length;
    },
    isPRTBypass(): boolean {
      return (
        this.applicantProfile.homeAddress.paCountyCode === "02" &&
        this.draftProgramInfo.ftpAgencyCode == "PAAC" &&
        this.draftProgramInfo.ftpApplicationInd === "Y"
      );
    },
    dlVerified(): boolean {
      return (
        this.draftProgramInfo.ageVerificationMethodCode === "Y" &&
        this.draftProgramInfo.driverLicenseVerifyCode === "VRFD"
      );
    }
  },
  created() {
    this.buildScreenModel();
    this.createdContd();
  }
});
