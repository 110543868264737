
import Vue from "vue";
import DateInput from "@/components/common/DateInput.vue";
import { IApplicantProfile, IDraftProgramInfo, IProgramsList } from "@/types";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import DraftProgramMixin from "@/mixins/DraftProgramMixin.vue";
import ProgressBar from "@/components/common/ProgressBar.vue";
import store from "@/store";
import TESRadioControl from "@/components/common/TESRadioControl.vue";
import tesContainer from "@/components/common/TESContainer.vue";
import StepperNavigationMixinVue from "@/mixins/StepperNavigationMixin.vue";
import { DISABILITY_TYPE, CNST_TEXT } from "@/constants/CommonConstants";
import ScrollMixin from "@/mixins/ScrollMixin.vue";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";
import { GPC } from "@/constants/GPCCode";

export default Vue.extend({
  mixins: [
    fieldEntryMixin,
    DraftProgramMixin,
    StepperNavigationMixinVue,
    ScrollMixin,
    ApplicationMixin
  ],
  name: "pwd-detail-info",
  props: {
    stepCount: { type: Number, default: 0 },
    stepperIndex: { type: Number, default: 0 }
  },
  components: {
    DateInput,
    tesContainer,
    TESRadioControl,
    ProgressBar
  },
  data() {
    return {
      showProgressBar: false,
      seletedNeeds: [] as string[],
      otherText: "" as string
    };
  },
  computed: {
    specialNeeds(): any {
      //@ts-ignore
      return this.uiFeature.SPL_NEEDS.children;
    },
    isTemporaryDisability(): boolean {
      return this.draftProgramInfo.disabilityType === DISABILITY_TYPE.TEMP;
    },
    isPermanentDisability(): boolean {
      return this.draftProgramInfo.disabilityType === DISABILITY_TYPE.PERM;
    },
    applicantProgramInfo(): IProgramsList {
      return store.getters.applicantProgramModule.getApplicantProgram;
    },
    typeOfDisabilityOptions(): any {
      //@ts-ignore
      return this.uiFeature.DIS_TYPES.children;
    },
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      },
      set(newValue: IDraftProgramInfo) {
        store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(newValue);
      }
    },
    applicantProfile(): IApplicantProfile {
      return store.getters.applicantProfileModule.getApplicantProfile;
    },
    pwdDetailInfo(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PWD_SEC2].largeText,
          CNST_TEXT.THE_APPLICANT,
          CNST_TEXT.THEIR
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PWD_SEC2].largeText,
          CNST_TEXT.YOU,
          CNST_TEXT.YOUR
        );
      }
      return text;
    },
    singleFlowFinalStep(): boolean {
      return this.stepperIndex === this.stepCount;
    },
    disabilityConditionQuestion(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithOnePlaceHolderVal(
        GPC.DIS_CNDTNS,
        CNST_TEXT.IS_THE_APPLICANTS,
        CNST_TEXT.IS_YOUR
      );
      return text;
    },
    disabilityOtherTextRule() {
      let otherTextReq = this.otherText ? this.otherText.trim() : "";
      return () => !!otherTextReq || "Required when Other is selected";
    }
  },
  created() {
    if (this.draftProgramInfo.visualDisabilityInd === "Y") {
      this.seletedNeeds.push("DISABL_1");
    }
    if (this.draftProgramInfo.hearingDisabilityInd === "Y") {
      this.seletedNeeds.push("DISABL_2");
    }
    if (this.draftProgramInfo.mentalDisabilityInd === "Y") {
      this.seletedNeeds.push("DISABL_3");
    }
    if (this.draftProgramInfo.cognitiveDisabilityInd === "Y") {
      this.seletedNeeds.push("DISABL_4");
    }
    if (this.draftProgramInfo.mobilityDisabilityInd === "Y") {
      this.seletedNeeds.push("DISABL_5");
    }
    if (this.draftProgramInfo.otherDisabilityInd === "Y") {
      this.seletedNeeds.push("DISABL_OTH");
      this.otherText = this.draftProgramInfo.otherDisabilityDesc;
    }
  },
  methods: {
    saveContinue() {
      //@ts-ignore
      if (this.$refs.programDetailTemplate.validate()) {
        this.saveDraftProgramInfo("SAVECONTINUE");
      } else {
        //@ts-ignore
        this.scrollToErrorField(this.$refs.programDetailTemplate);
      }
    },
    save() {
      //@ts-ignore
      if (this.$refs.programDetailTemplate.validate()) {
        this.saveDraftProgramInfo("SAVE");
      } else {
        //@ts-ignore
        this.scrollToErrorField(this.$refs.programDetailTemplate);
      }
    },
    cancel() {
      //@ts-ignore
      if (this.$refs.programDetailTemplate.validate()) {
        this.saveDraftProgramInfo("CANCEL");
      } else {
        //@ts-ignore
        this.scrollToErrorField(this.$refs.programDetailTemplate);
      }
    },
    saveDraftProgramInfo(buttonName: string) {
      this.showProgressBar = true;
      this.populateSpecialNeeds();
      //@ts-ignore
      this.saveProgramInfo(
        this.draftProgramInfo,
        this.stepperIndex,
        this.stepCount,
        buttonName
      )
        //@ts-ignore
        .then(response => {
          //@ts-ignore
          this.draftProgramInfo = response.data;

          store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(
            this.draftProgramInfo
          );
          store.commit.applicantProgramModule.SET_APPLICANT_PROGRAM(
            this.draftProgramInfo.programInfo
          );

          this.$emit("save-completed", buttonName);
        })
        //@ts-ignore
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    },
    populateSpecialNeeds() {
      this.draftProgramInfo.visualDisabilityInd = this.seletedNeeds.includes(
        "DISABL_1"
      )
        ? "Y"
        : "N";
      this.draftProgramInfo.hearingDisabilityInd = this.seletedNeeds.includes(
        "DISABL_2"
      )
        ? "Y"
        : "N";
      this.draftProgramInfo.mentalDisabilityInd = this.seletedNeeds.includes(
        "DISABL_3"
      )
        ? "Y"
        : "N";
      this.draftProgramInfo.cognitiveDisabilityInd = this.seletedNeeds.includes(
        "DISABL_4"
      )
        ? "Y"
        : "N";
      this.draftProgramInfo.mobilityDisabilityInd = this.seletedNeeds.includes(
        "DISABL_5"
      )
        ? "Y"
        : "N";
      this.draftProgramInfo.otherDisabilityInd = this.seletedNeeds.includes(
        "DISABL_OTH"
      )
        ? "Y"
        : "N";
      this.draftProgramInfo.otherDisabilityDesc = this.otherText;
    }
  }
});
