
import Vue from "vue";
import store from "@/store";
import { IApplicantProfile, IProgramsList, IDraftProgramInfo } from "@/types";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import Attachments from "@/components/application/program/Attachments.vue";
import AttachmentsMixinVue from "@/mixins/AttachmentsMixin.vue";
import { CNST_TEXT } from "@/constants/CommonConstants";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";
import { GPC } from "@/constants/GPCCode";

export default Vue.extend({
  name: "matpIndividualflow-attachments",
  mixins: [UIFeatureMixinVue, AttachmentsMixinVue, ApplicationMixin],
  components: {
    Attachments
  },
  data: function() {
    return {
      showPCADoc: false,
      showPODDoc: false,
      showCTDCDoc: false,
      documentTypeCodes: [] as string[]
    };
  },
  computed: {
    prfDsbltyCodes(): any {
      //@ts-ignore
      return this.uiFeature.PRF_DSBLTY.children;
    },
    prfPrsnCareAttendantCodes(): any {
      //@ts-ignore
      return this.uiFeature.PRF_PRCARE.children;
    },
    matpFormCodes(): any {
      //@ts-ignore
      return this.uiFeature.MATP_FORM.children;
    },
    applicantProfile: {
      get(): IApplicantProfile {
        return store.getters.applicantProfileModule.getApplicantProfile;
      }
    },
    applicantProgramInfo: {
      get(): IProgramsList {
        return store.getters.applicantProgramModule.getApplicantProgram;
      }
    },
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      }
    },
    prfDsbltyInfoOne(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBLTY].largeText,
          CNST_TEXT.THE_APPLICANT_HAS,
          CNST_TEXT.THE_APPLICANT,
          CNST_TEXT.THE
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBLTY].largeText,
          CNST_TEXT.YOU_HAVE,
          CNST_TEXT.YOU,
          CNST_TEXT.YOUR
        );
      }
      return text;
    },
    prfDsbltyInfoHeaderTwo(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_2].displayName,
          CNST_TEXT.THE
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_2].displayName,
          CNST_TEXT.MY
        );
      }
      return text;
    },
    prfDsbltyInfoTwo(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_2].largeText,
          CNST_TEXT.THE
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_2].largeText,
          CNST_TEXT.YOUR
        );
      }
      return text;
    },
    prfDsbltyInfoThree(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_3].largeText,
          CNST_TEXT.THE,
          CNST_TEXT.THE
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_3].largeText,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOUR
        );
      }
      return text;
    },
    pcaDocInfoOne(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_PRCARE].largeText,
          CNST_TEXT.THE_APPLICANT_NEEDS,
          CNST_TEXT.THE_APPLICANT,
          CNST_TEXT.THEIR,
          CNST_TEXT.THE
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_PRCARE].largeText,
          CNST_TEXT.YOU_NEED,
          CNST_TEXT.YOU,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOUR
        );
      }
      return text;
    },
    matpCTDCDocText(): string {
      //@ts-ignore
      return this.getCodDocDynamicText(
        //@ts-ignore
        this.uiFeature[GPC.CTDC_MATP].largeText
      );
    },
    matpFormHelpText(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.MATP_FORM].largeText,
          CNST_TEXT.THE_APPLICANT_IS,
          CNST_TEXT.THE_APPLICANT,
          CNST_TEXT.THE
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.MATP_FORM].largeText,
          CNST_TEXT.YOU_ARE,
          CNST_TEXT.YOU,
          CNST_TEXT.YOUR
        );
      }
      return text;
    }
  },
  created() {
    if (
      this.applicantProfile.travelAttendantInd === "Y" &&
      (this.applicantProgramInfo.transitAgencyCode === "CPTA" ||
        this.applicantProgramInfo.transitAgencyCode === "CAT")
    ) {
      this.showPCADoc = true;
    }

    if (
      this.applicantProgramInfo.transitAgencyCode === "FREEDOM" &&
      (this.draftProgramInfo.massTransitInabilityInd === "Y" ||
        this.draftProgramInfo.transportationDisabilityInd === "Y" ||
        this.draftProgramInfo.rideAloneInd === "Y")
    ) {
      this.showPODDoc = true;
    }

    if (
      this.draftProgramInfo.medAssistanceEligibilityInd === "Y" &&
      this.applicantProgramInfo.transitAgencyCode === "CTDC"
    ) {
      this.showCTDCDoc = true;
    }

    //@ts-ignore
    if (!this.isSingleFlow()) {
      //@ts-ignore
      this.documentTypeCodes = this.getMATPDocumentTypes();
    }
  }
});
