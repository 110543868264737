
import Vue from "vue";
import store from "@/store";
import DocumentSectionList from "@/components/common/DocumentSectionList.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import DraftProgramMixin from "@/mixins/DraftProgramMixin.vue";
import ProgressBar from "@/components/common/ProgressBar.vue";
import { IDraftProgramInfo, IApplicantProfile } from "@/types";
export default Vue.extend({
  name: "Single-Flow-Attachments-Review",
  components: {
    ProgressBar,
    DocumentSectionList
  },
  mixins: [UIFeatureMixinVue, DraftProgramMixin],
  data() {
    return {
      uploadedFiles: [],
      showProgressBar: false
    };
  },
  props: {
    documentTypeCode: {
      type: String,
      default: null
    },
    documentType: {
      type: String,
      default: null
    }
  },
  computed: {
    docs: {
      get(): [] {
        return store.getters.attachmentsModule.getProfileDocs;
      },
      set(newValue: []) {
        store.commit.attachmentsModule.SET_DOC_LIST(newValue);
      }
    },
    isDocListLoadding(): boolean {
      return store.getters.attachmentsModule.getDocListLoadingStatus;
    },
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      },
      set(newValue: IDraftProgramInfo) {
        store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(newValue);
      }
    },
    applicantProfileInfo: {
      get(): IApplicantProfile {
        return store.getters.applicantProfileModule.getApplicantProfile;
      }
    },
    getAtchmtCheckboxSltdList() {
      return store.getters.attachmentsModule.getAtchmtCheckboxSltdList;
    },
    showDocsListByDocType: function() {
      //@ts-ignore
      return (first: string) => {
        //@ts-ignore
        return this.docs.filter(doc => doc.documentTypeCode === first);
      };
    },
    showChkBxSection(): boolean {
      //@ts-ignore
      return this.getAtchmtCheckboxSltdList.find(
        o =>
          //@ts-ignore
          o.noUploadInd === "Y"
      );
    },
    showChkBxByDocType: function() {
      //@ts-ignore
      return (first: string) => {
        //@ts-ignore
        return this.getAtchmtCheckboxSltdList.find(
          o =>
            //@ts-ignore
            o.documentTypeCode === first && o.noUploadInd === "Y"
        );
      };
    }
  },
  created() {
    this.getUploadedDocList();
  },
  methods: {
    getUploadedDocList() {
      let payload = {
        applicantProfileId: this.applicantProfileInfo.applicantProfileId
      };
      store.dispatch.attachmentsModule.getUploadedDocList(payload);
      store.dispatch.attachmentsModule.getAtchmtChkBoxSltdByProfileId(
        this.applicantProfileInfo.applicantProfileId
      );
    }
  }
});
