import { IAdvacnedSearch } from "@/types";

import { advancedSearchDefault } from "@/data/AppData";
import { clone } from "@/util/clone";

export interface AdvancedSearchState {
  advancedSearch: {} | IAdvacnedSearch;
}

export default {
  namespaced: true,
  state: {
    advancedSearch: clone(advancedSearchDefault)
  } as AdvancedSearchState,
  getters: {
    getAdvancedSearch: (state: AdvancedSearchState) => state.advancedSearch
  },
  mutations: {
    SET_ADVANCED_SEARCH(
      state: AdvancedSearchState,
      newAdvancedSearch: IAdvacnedSearch
    ) {
      state.advancedSearch = newAdvancedSearch;
    }
  }
};
