
import Vue from "vue";
import axios from "axios";
import store from "@/store";
import {
  ITESUser,
  IProgramEligModel,
  IApplicationReviewModel,
  IProgramsList
} from "@/types";
import ProgressBar from "@/components/common/ProgressBar.vue";
import { TES_APP_ENDPOINT } from "@/constants/EndPoints";
import { APPL_STATUS } from "@/constants/CommonConstants";
import ApplicantProfileMixin from "@/mixins/ApplicantProfileMixin.vue";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import routerMixin from "@/mixins/RouterMixin.vue";
import StepperNavigationMixinVue from "@/mixins/StepperNavigationMixin.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";

export default Vue.extend({
  name: "appeal-in-progress",
  mixins: [
    ApplicantProfileMixin,
    AppPropsMixin,
    routerMixin,
    StepperNavigationMixinVue,
    UIFeatureMixinVue,
    ApplicationMixin
  ],
  components: {
    ProgressBar
  },
  data() {
    return {
      showProgressBar: false,
      appealed: false,
      reviewModel: {} as IApplicationReviewModel
    };
  },
  computed: {
    userInfo(): ITESUser {
      return store.getters.tesUserModule.getTesUser;
    },
    applicationReviewModel: {
      get(): IApplicationReviewModel {
        //@ts-ignore
        return store.getters.applicationReviewModule.getApplicationReviewModel;
      }
    },
    applicantProgramInfo: {
      get(): IProgramsList {
        //@ts-ignore
        return store.getters.applicantProgramModule.getApplicantProgram;
      }
    },
    programEligModel(): IProgramEligModel {
      return store.getters.programEligModule.getProgramElig;
    }
  },
  methods: {
    GotoDashboard() {
      //@ts-ignore
      this.forwardToDashboard();
    },
    updateEligibility() {
      this.reviewModel.reviewerUserId = this.userInfo.userId;
      this.reviewModel.tesApplicationId = this.applicantProgramInfo.applicationId;
      this.reviewModel.cycleNumber = this.applicantProgramInfo.cycleNumber;
      let startReviewUrl = TES_APP_ENDPOINT.UPDATE_ELIGBLTY;
      this.showProgressBar = true;
      axios
        .post(startReviewUrl, this.reviewModel)
        .then(response => {
          //@ts-ignore
          store.commit.applicationReviewModule.SET_APPLICATION_REVIEW_MODEL(
            response.data
          );
          this.applicantProgramInfo.statusCode = APPL_STATUS.APEL_INPRG;
          // route to AgencyReview screen
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          store.dispatch.applicationReviewModule.loadApplicationPreviousReviewModel(
            { tesApplicationId: this.applicationReviewModel.tesApplicationId }
          );
          this.showProgressBar = false;
        });
    }
  }
});
