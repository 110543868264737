
import Vue from "vue";

export default Vue.extend({
  name: "expansion-panel-template",
  data() {
    return {
      isExpanded: false
    };
  },
  props: {
    header: { type: String, default: "" },
    addpadding: { type: String, default: "px-4 py-2" },
    isHeaderMobile: { type: Boolean, default: false },
    iconTextLabel: {
      type: Object,
      default: () => {
        return {
          showText: "View Details",
          hideText: "Hide Details"
        };
      }
    },
    iconDisplay: {
      type: Object,
      default: () => {
        return {
          expandIcon: "fa-expand-alt",
          compressIcon: "fa-compress-alt"
        };
      }
    },
    addColor: { type: String, default: "" }
  },
  computed: {
    icon(): string {
      return this.isExpanded
        ? this.iconDisplay.compressIcon
        : this.iconDisplay.expandIcon;
    },
    iconText(): string {
      return this.isExpanded
        ? this.iconTextLabel.hideText
        : this.iconTextLabel.showText;
    }
  }
});
