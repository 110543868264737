
import Vue from "vue";
import store from "@/store";
import { IDraftProgramInfo } from "@/types";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import ProgramInfoSummaryMixin from "@/mixins/ProgramInfoSummaryMixin.vue";
import readonlyField from "@/components/common/ReadOnlyField.vue";

export default Vue.extend({
  name: "transportation-appl-review",
  mixins: [UIFeatureMixinVue, ProgramInfoSummaryMixin],
  components: { readonlyField },
  data() {
    return {};
  },
  computed: {
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        //@ts-ignore
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      }
    }
  }
});
