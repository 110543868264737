
import Vue from "vue";
import ApplicantProfileMixin from "@/mixins/ApplicantProfileMixin.vue";
import readonlyField from "@/components/common/ReadOnlyField.vue";
import { GPC } from "@/constants/GPCCode";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";
import { CNST_TEXT } from "@/constants/CommonConstants";
import DynamicTextMixin from "@/mixins/DynamicTextMixin.vue";

export default Vue.extend({
  name: "review-transportation-info",
  components: { readonlyField },
  mixins: [ApplicantProfileMixin, ApplicationMixin, DynamicTextMixin],
  computed: {
    transAssistQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithOnePlaceHolder(
        GPC.TRANS_ASST,
        CNST_TEXT.DOES_THE_APPLICANT,
        CNST_TEXT.DO_YOU
      );
      return text;
    },
    personalCareAssistQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithOnePlaceHolderVal(
        GPC.PSNL_C_ADT,
        CNST_TEXT.DOES_THE_APPLICANT,
        CNST_TEXT.DO_YOU
      );
      return text;
    },
    currMeansTransQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithOnePlaceHolderVal(
        GPC.CUR_MN_TRP,
        CNST_TEXT.THE_APPLICANTS,
        CNST_TEXT.YOUR
      );
      return text;
    },
    printTranspWaysFlatString: function() {
      return (transpWaysList: string[]) => {
        let flatString: string = "";

        transpWaysList.forEach((n: string) => {
          if (!flatString && n !== "TRNSP_OTHR") {
            //@ts-ignore
            flatString = this.uiFeature[n].displayName;
          } else if (n !== "TRNSP_OTHR") {
            //@ts-ignore
            flatString = flatString + ", " + this.uiFeature[n].displayName;
          }
        });
        return flatString;
      };
    }
  }
});
