var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('v-row',[_c('v-col',{staticClass:"px-16 py-1 colAlignment"},[_c('h6',[_vm._v("Mobility Information")]),_c('v-card',{staticClass:"borderRadius pa-6",attrs:{"width":"100%"}},[_c('v-row',[_c('v-col',[_c('readonlyField',{attrs:{"label":_vm.leftEnabledVehicleQues,"value":_vm.largeText(_vm.applicantProfileInfo.liftEnabledVehicleInd),"previous":_vm.largeText(_vm.applicantProfileInfoPrevious.liftEnabledVehicleInd),"showPrevious":_vm.showPreviousValues}})],1)],1),_c('v-row',[_c('v-col',[_c('readonlyField',{attrs:{"label":_vm.haveAnyMobilityDevicesQues,"value":_vm.largeText(_vm.applicantProfileInfo.mobilityDeviceUseInd),"previous":_vm.largeText(_vm.applicantProfileInfoPrevious.mobilityDeviceUseInd),"showPrevious":_vm.showPreviousValues}})],1)],1),(_vm.applicantProfileInfo.mobilityDeviceUseInd === 'Y')?_c('v-row',[_c('v-col',[_c('readonlyField',{attrs:{"label":_vm.selectMobilityDevicesQues,"value":_vm.mobilityDevicesFlat,"previous":_vm.mobilityDevicesPreviousFlat,"showPrevious":_vm.showPreviousValues}},[_vm._l((_vm.applicantProfileInfo
                  .applicantMobilityDevices.mobilityDeviceCodes),function(mobilityDeviceCodes){return _c('span',{key:mobilityDeviceCodes.mobilityDeviceCodes,attrs:{"slot":"valueSlot"},slot:"valueSlot"},[_c('div',[_vm._v(" "+_vm._s(_vm.uiFeature[mobilityDeviceCodes].displayName)+" "),(mobilityDeviceCodes === 'OTH')?_c('span',[_vm._v(" : "+_vm._s(_vm.applicantProfileInfo.applicantMobilityDevices .otherMobilityDevice)+" ")]):_vm._e()])])}),_vm._l((_vm.applicantProfileInfoPrevious
                  .applicantMobilityDevices.mobilityDeviceCodes),function(mobilityDeviceCodes){return _c('span',{key:mobilityDeviceCodes.mobilityDeviceCodes,staticClass:"font-weight-bold word-wrap",attrs:{"slot":"previousSlot"},slot:"previousSlot"},[_c('div',[_vm._v(" "+_vm._s(_vm.uiFeature[mobilityDeviceCodes].displayName)+" "),(mobilityDeviceCodes === 'OTH')?_c('span',[_vm._v(" : "+_vm._s(_vm.applicantProfileInfoPrevious.applicantMobilityDevices .otherMobilityDevice)+" ")]):_vm._e()])])})],2)],1)],1):_vm._e(),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.applicantProfileInfo.mobilityDeviceUseInd === 'Y' &&
              (_vm.applicantProfileInfo.applicantMobilityDevices.mobilityDeviceCodes.includes(
                'MAN_WHLCHR'
              ) ||
                _vm.applicantProfileInfo.applicantMobilityDevices.mobilityDeviceCodes.includes(
                  'MOTO_SCO'
                ) ||
                _vm.applicantProfileInfo.applicantMobilityDevices.mobilityDeviceCodes.includes(
                  'MOT_WHLCHR'
                ))
          ),expression:"\n            applicantProfileInfo.mobilityDeviceUseInd === 'Y' &&\n              (applicantProfileInfo.applicantMobilityDevices.mobilityDeviceCodes.includes(\n                'MAN_WHLCHR'\n              ) ||\n                applicantProfileInfo.applicantMobilityDevices.mobilityDeviceCodes.includes(\n                  'MOTO_SCO'\n                ) ||\n                applicantProfileInfo.applicantMobilityDevices.mobilityDeviceCodes.includes(\n                  'MOT_WHLCHR'\n                ))\n          "}]},[_c('v-col',[_c('readonlyField',{attrs:{"label":_vm.withWithoutAssistQues,"value":_vm.largeText(_vm.applicantProfileInfo.wheelChairTransferInd),"previous":_vm.largeText(_vm.applicantProfileInfoPrevious.wheelChairTransferInd),"showPrevious":_vm.showPreviousValues}})],1)],1),(_vm.applicantProfileInfo.wheelChairSizeInd)?_c('v-row',[_c('v-col',[_c('readonlyField',{attrs:{"label":_vm.wheelChairWeightQues,"value":_vm.largeText(_vm.applicantProfileInfo.wheelChairSizeInd),"previous":_vm.largeText(_vm.applicantProfileInfoPrevious.wheelChairSizeInd),"showPrevious":_vm.showPreviousValues}})],1)],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }