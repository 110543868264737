
import Vue from "vue";
import axios from "axios";
import { TES_APP_ENDPOINT, ECS_ENDPOINT } from "@/constants/EndPoints";
import { ITESApplicationModel, IISAMUserAttributes } from "@/types";
import UIFeatureMixin from "@/mixins/UIFeatureMixin.vue";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";
import store from "@/store";

export default Vue.extend({
  name: "summary-report-generator",
  mixins: [UIFeatureMixin, ApplicationMixin],
  data() {
    return {
      isGeneratingAppPDF: false as boolean,
      hasError: false as boolean
    };
  },
  props: {
    applicationId: { default: 0, type: Number },
    color: { default: "primary", type: String }
  },
  computed: {
    linkClass(): string {
      return this.isGeneratingAppPDF ? "disabled-link" : "font-weight-bold";
    },
    isamUserAttributes: {
      get(): IISAMUserAttributes {
        return store.getters.isamUserAttributesModule.getISAMUserAttributes;
      }
    }
  },
  methods: {
    generatePDF(appId: number) {
      if (!this.isGeneratingAppPDF) {
        this.isGeneratingAppPDF = true;
        this.hasError = false;

        this.getApplication(appId)
          .then(response => {
            // Display DL Section only for Agency users
            if (
              //@ts-ignore
              this.isAgencyUser
            ) {
              this.getDriverLicenseDetails(response.data);
            }
            this.pdfCreation(response);
          })
          .catch(() => {
            this.hasError = true;
            this.isGeneratingAppPDF = false;
          });
      }
    },
    getApplication(appId: number): Promise<any> {
      return axios.get(
        TES_APP_ENDPOINT.GET_COMPLETE_PROFILE_DETAILS_BY_APP_ID,
        {
          params: { tesApplicationId: appId }
        }
      );
    },
    getDriverLicenseDetails(draftprogrmInfo: any): Promise<any> {
      return store.dispatch.applicantProfileModule.getDriverLicenseDetails(
        draftprogrmInfo.draftProgramInfoModel
      );
    },
    async pdfCreation(appResponse: any) {
      await this.getISAMAttributes(appResponse.data.createdByUserId);
      let app: ITESApplicationModel = appResponse.data;
      app.isamUserAttributes = this.isamUserAttributes;
      app.printForMe = this.setPrintForMeOption(app);
      //@ts-ignore
      app.isAgencyUser = this.isAgencyUser ? "Yes" : "No";
      //@ts-ignore
      app.isOrganizationUser = this.isOrganizationUser ? "Yes" : "No";
      //@ts-ignore
      app.isCOReadOnlyUser = this.isCOReadOnlyUser ? "Yes" : "No";
      // Display DL Section only for Agency users
      if (
        //@ts-ignore
        this.isAgencyUser
      ) {
        if (
          //@ts-ignore
          store.getters.applicantProfileModule.getApplicantProfile
            .drivingLicenseModel != null &&
          store.getters.applicantProfileModule.getApplicantProfile
            .drivingLicenseModel.firstName
        ) {
          app.applicantProfileModel.drivingLicenseModel =
            //@ts-ignore
            store.getters.applicantProfileModule.getApplicantProfile.drivingLicenseModel;
        }
      }
      let fileName: string =
        app.programEligibilityModel.applicationId +
        "_" +
        app.programEligibilityModel.programName;

      axios
        .post(ECS_ENDPOINT.GET_APP_SUMMARY_PDF, app, {
          responseType: "arraybuffer"
        })
        .then(response => {
          this.openPDF(new Blob([response.data]), fileName);
        })
        .catch(error => {
          this.hasError = true;
        })
        .finally(() => {
          this.isGeneratingAppPDF = false;
        });
    },
    setPrintForMeOption(app: ITESApplicationModel): string {
      let printForMe: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && app.applicantProfileModel.onBehalfOfInd === "Y")
      ) {
        printForMe = "N";
      } else {
        printForMe = "Y";
      }
      return printForMe;
    },
    openPDF(pdfBlob: Blob, fileName: string) {
      //@ts-ignore
      const pdfURL = window.URL.createObjectURL(pdfBlob);
      const link = document.createElement("a");
      link.href = pdfURL;
      link.setAttribute("download", fileName + ".pdf");
      document.body.appendChild(link);
      link.click();
    },
    async getISAMAttributes(createdByUserId: number) {
      await store.dispatch.isamUserAttributesModule.getISAMUserAttributesByUserId(
        createdByUserId
      );
    }
  }
});
