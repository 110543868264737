
import Vue from "vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import { IUIFeatureObjectModel } from "@/types";
export default Vue.extend({
  name: "tes-radio-control",
  mixins: [UIFeatureMixinVue],
  data() {
    return {
      selectedRadio: this.value
    };
  },
  props: {
    questionText: { type: String, default: "" },
    requiredQuestion: { type: Boolean, default: false },
    questionClass: { type: String, default: "" },
    labelMedium: { type: Boolean, default: false },
    rules: { type: Array, default: () => [] as object[] },
    value: { type: String, default: "" },
    options: {
      type: Array,
      required: false
    },
    choose: { type: Boolean, default: false },
    rowPosition: { type: Boolean, default: true },
    radioGroupId: { type: String, default: "" },
    disabled: { type: Boolean, default: false }
  },
  computed: {
    radioOptions(): any {
      let radioOptions: any;
      if (this.options) {
        radioOptions = this.options;
      } else {
        //@ts-ignore
        radioOptions = this.uiFeature.RADIO_YN.children;
      }
      return radioOptions;
    },
    labelClass(): string {
      return this.labelMedium ? "inputLabelMedium" : "inputLabelSmall";
    },
    questionTextLabelId(): string {
      // @ts-ignore
      if (this.$refs && this.$refs.questionText && this.$refs.questionText.id) {
        // @ts-ignore
        return this.$refs.questionText.id;
      } else {
        return "";
      }
    }
  },
  methods: {
    getText(radio: IUIFeatureObjectModel): string {
      if (this.choose) {
        if (radio.displayName) {
          return radio.displayName;
        } else {
          return "";
        }
      } else if (radio.largeText) {
        return radio.largeText;
      } else {
        return radio.displayName;
      }
    }
  },
  watch: {
    selectedRadio(newVal) {
      this.$emit("input", newVal);
    },
    value(newVal) {
      if (newVal) {
        this.selectedRadio = newVal;
      }
    }
  }
});
