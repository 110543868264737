
import Vue from "vue";
import DashboardInitial from "@/components/dashboard/DashboardInitial.vue";
import { DASHBOARD_ENDPOINT, TES_APP_ENDPOINT } from "@/constants/EndPoints";
import axios from "axios";
import { HTTP_STATUS } from "@/constants/HttpStatusCode";
import {
  DASHBOARD_TYPE,
  USER_GROUPS,
  CNST_TEXT
} from "@/constants/CommonConstants";
import DashboardResult from "@/components/dashboard/DashboardResult.vue";
import DashboardAgency from "@/components/dashboard/DashboardAgency.vue";
import DashboardOrganization from "@/components/dashboard/DashboardOrganization.vue";
import store from "@/store";
import {
  IDashboardContent,
  ITESUser,
  IProgramsList,
  IApplicationNavigation,
  IScreeningResponse,
  IDraftProgramInfo,
  IStepperHeaderList
} from "@/types";
import DashboardProfile from "@/components/dashboard/DashboardProfile.vue";
import ProgressBar from "@/components/common/ProgressBar.vue";
import ClearStoreValuesMixin from "@/mixins/ClearStoreValuesMixin.vue";
import SurrogateDialog from "@/components/dashboard/SurrogateDialog.vue";
import SummaryReportCreator from "@/components/common/SummaryReportCreator.vue";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";

export default Vue.extend({
  name: "dashboard",
  mixins: [
    ClearStoreValuesMixin,
    AppPropsMixin,
    UIFeatureMixinVue,
    ApplicationMixin
  ],
  components: {
    DashboardInitial,
    DashboardResult,
    DashboardProfile,
    ProgressBar,
    SurrogateDialog,
    DashboardAgency,
    DashboardOrganization,
    SummaryReportCreator
  },

  data() {
    return {
      showInitialDashboard: false as boolean,
      showProfileDashboard: false as boolean,
      showResultDashboard: false as boolean,
      showOrganizationDashboard: false as boolean,
      showOrgApplicantDashboard: false as boolean,
      showProgressBar: false,
      tabId: 0 as number,
      tabList: [] as IScreeningResponse[],
      exData: "" as string,
      showSurrogateDialog: false,
      screeningResponseId: 0 as number,
      displaySave: false as boolean,
      displayMyselfProp: true as boolean,
      reloadOnCancel: false as boolean,
      loadingCounter: -1 as number,
      isTabsLoading: false as boolean,
      isProfileLoading: false as boolean,
      submittedProgramList: [] as IProgramsList[],
      selectedTab: {} as IScreeningResponse,
      addNewTab: {} as IScreeningResponse,
      displayAddNew: false as boolean,
      scrnRespID: 0 as number,
      windowSize: 0 as number,
      showSubmittedApps: true,
      adaVoter: "" as string
    };
  },
  created() {
    this.scrnRespID = Number(this.$route.query.scrnRespID);
    if (this.applicationNavigation.submitAppl) {
      this.submittedProgramList = this.draftProgramInfo.programInfoList;
      this.adaVoter = this.draftProgramInfo.voterRegistrationSelectCode;
    }
    //@ts-ignore
    this.clearAllStoreValuesExceptUser();

    this.getUIFeaturesReqForThisPage();

    if (
      this.userInfo.userId === null ||
      this.userInfo.userId === undefined ||
      this.userInfo.userId === 0
    ) {
      this.getUserDetails();
    } else {
      this.getAllContent();
    }
  },
  computed: {
    dashboardType(): string {
      let dType = "";

      if (
        this.dashboardContent &&
        this.dashboardContent.screeningResponse &&
        this.dashboardContent.screeningResponse.screeningResponseId &&
        this.dashboardContent.screeningResponse.applicantProfileId
      ) {
        dType = DASHBOARD_TYPE.RESULT;
      } else {
        if (this.applicantProgramInfo) {
          dType = DASHBOARD_TYPE.APPLICATION;
        } else {
          dType = DASHBOARD_TYPE.PROFILE;
        }
      }

      return dType;
    },
    dashboardContent: {
      get(): IDashboardContent {
        //@ts-ignore
        return store.getters.dashboardModule.getDashboardContent;
      },
      set(newValue: IDashboardContent) {
        //@ts-ignore
        store.commit.dashboardModule.SET_DASHBOARD_CONTENT(newValue);
      }
    },
    userInfo: {
      get(): ITESUser {
        //@ts-ignore
        return store.getters.tesUserModule.getTesUser;
      },
      set(tesUser: ITESUser) {
        //@ts-ignore
        store.commit.tesUserModule.SET_TES_USER(tesUser);
      }
    },
    applicantProgramInfo: {
      get(): IProgramsList {
        //@ts-ignore
        return store.getters.applicantProgramModule.getApplicantProgram;
      },
      set(newValue: IProgramsList) {
        //@ts-ignore
        store.commit.applicantProgramModule.SET_APPLICANT_PROGRAM(newValue);
      }
    },
    applicationNavigation: {
      get(): IApplicationNavigation {
        //@ts-ignore
        return store.getters.applicationNavigationModule
          .getApplicationNavigation;
      }
    },
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        //@ts-ignore
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      },
      set(newValue: IDraftProgramInfo) {
        //@ts-ignore
        store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(newValue);
      }
    },
    stepperHeaderModel: {
      get(): IStepperHeaderList {
        //@ts-ignore
        return store.getters.stepperHeaderModule.getStepperHeaderModel;
      },
      set(newValue: IStepperHeaderList) {
        //@ts-ignore
        store.commit.stepperHeaderModule.SET_STEPPER_HEADER_MODEL(newValue);
      }
    },
    showSelectUserDropdown(): boolean {
      return this.tabList.length > 4 || this.windowSize < 1264;
    },
    screeningResponse: {
      get(): IScreeningResponse {
        //@ts-ignore
        return store.getters.screeningResponseModule.getScreeningResponse;
      }
    }
  },
  methods: {
    onResize() {
      this.windowSize = window.innerWidth;
    },
    getUserDetails() {
      axios
        .get(DASHBOARD_ENDPOINT.GET_USER_INFO)
        .then(response => {
          if (response.status === HTTP_STATUS.OK) {
            this.userInfo = response.data;
          }
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.getAllContent();
        });
    },
    getAllContent() {
      if (
        this.userInfo.fein !== null &&
        this.userInfo.fein !== undefined &&
        this.userInfo.fein.length > 0
      ) {
        this.resetScreen();
        this.showDashboard();
      } else {
        this.getTabs();
      }
    },
    getUIFeaturesReqForThisPage() {
      let uiFeatureArray =
        "NOT_START,IN_PRGS,RDY_SBMT,NOT_QLFYD,SBMTD,APPROVED,NOT_APPL,CTZ_PORTAL,BP_PORTAL,CWOPA_PT,RE_COR,IN_PRGS_C,RDY_SBMT_C,WITHDRAWN,PEND_CONF,PENDING,ER_INLINE,POD_AGNCYS,UN_RVW,INELIGIBLE,INCOMPLETE,Y_PWD_1,YNT_PWD1,Y_ADA_1,YNT_ADA1,Y_SSR_1,YNT_SSR1,Y_FTP_1,YNT_FTP1,Y_MATP_1,YNT_MATP1,CHGLGN_TXT,C_COD_AGNY,DOCS_ADA,DOCS_PWD,DOCS_FTP,DOCS_SSR,DOCS_MATP,QUICK_SRCH,ER_INLINE,COD_DOCTXT,PCA_DOCTXT,FRDMCODTXT,PARTCODTXT,AMTRAN_COD,FMR,ENVRONMNT,TMP_APRVD,DEFAULTDAY,RASGN_ACT,CPTA_COD,CLTS_COD,LT_COD,C_POD_PWD,C_COD_ADA,AG_ATT_TXT,C_COD_PWD,APEL_INPRG,Y_AGING_1,YNT_AGING1,Y_VTP_1,YNT_VTP1,Y_RFTP_1,YNT_RFTP1,DOCS_VTP,DOCS_RFTP,SUB_NOTE, REPORTS,EMBD_TOK,EMBD_URL,TOKEN_TYPE,R_TYPE,R_PAGE_V,C_COD_VIS,PCA_ADA_DC,ACCS_LNK,NO_ECLN_AG";

      //@ts-ignore
      store.dispatch.uiFeatureModule.getUIFeatures(uiFeatureArray);
    },
    resetScreen() {
      this.showInitialDashboard = false;
      this.showProfileDashboard = false;
      this.showResultDashboard = false;
      this.showOrganizationDashboard = false;
      this.showOrgApplicantDashboard = false;
    },
    closeSurrogateDialog(deleted: boolean) {
      this.showSurrogateDialog = false;
      if (deleted) {
        this.selectedTab = this.tabList[0]; //to default to 1st option in dropdown
      }
    },
    displaySurrogateDialog() {
      this.showSurrogateDialog = true;
    },
    displayMyself() {
      let displayMyself = true;
      if (this.tabList !== null) {
        //to make this one as active tab, not reloading the page
        //@ts-ignore
        if (
          this.tabList[0].onBehalfOfInd === null ||
          this.tabList[0].onBehalfOfInd === "N"
        ) {
          //First tab is related to Self applicant if present.
          displayMyself = false;
        }
      }
      this.displayMyselfProp = displayMyself;
    },
    setInitialScreen() {
      this.resetScreen();
      switch (this.dashboardType) {
        case DASHBOARD_TYPE.INITIAL:
          this.showInitialDashboard = true;
          break;
        case DASHBOARD_TYPE.RESULT:
          this.showResultDashboard = true;
          break;
        case DASHBOARD_TYPE.PROFILE:
          this.showProfileDashboard = true;
          break;
        case DASHBOARD_TYPE.APPLICATION:
          this.showProfileDashboard = true;
          break;
      }
      this.dashboardContent.dashboardType = this.dashboardType;
    },
    setDashboardType() {
      this.resetScreen();
      this.dashboardContent.dashboardType = "";
      if (this.checkProgramsLength()) {
        //To display continue to dashboard and steppers
        //After programs are submitted in single flow, preeligible programs would be empty
        if (
          this.dashboardContent.programEligModel.preEligiblePrograms &&
          this.dashboardContent.programEligModel.preEligiblePrograms.length != 0
        ) {
          this.dashboardContent.dashboardType = DASHBOARD_TYPE.CONTINUE;
        } else {
          this.dashboardContent.dashboardType = DASHBOARD_TYPE.APPLICATION;
        }
        this.showProfileDashboard = true;
      } else if (
        this.dashboardContent.applicantProfile &&
        this.dashboardContent.applicantProfile.applicantProfileId
      ) {
        this.showProfileDashboard = true;
        this.dashboardContent.dashboardType = DASHBOARD_TYPE.PROFILE;
      } else if (
        this.dashboardContent.screeningResponse &&
        this.dashboardContent.screeningResponse.screeningResponseId
      ) {
        this.showResultDashboard = true;
      }
    },
    checkProgramsLength() {
      return (
        this.dashboardContent.programEligModel &&
        ((this.dashboardContent.programEligModel.eligiblePrograms &&
          this.dashboardContent.programEligModel.eligiblePrograms.length !=
            0) ||
          (this.dashboardContent.programEligModel.preEligiblePrograms &&
            this.dashboardContent.programEligModel.preEligiblePrograms.length !=
              0) ||
          (this.dashboardContent.programEligModel.notEligiblePrograms &&
            this.dashboardContent.programEligModel.notEligiblePrograms.length !=
              0) ||
          (this.dashboardContent.programEligModel.submittedPrograms &&
            this.dashboardContent.programEligModel.submittedPrograms.length !=
              0))
      );
    },
    populateItemForAddNew() {
      this.addNewTab.screeningResponseId = -1;
      this.populateDashboardContent(this.addNewTab, "click");
    },
    populateDashboardContent(
      tabItem: IScreeningResponse,
      fromLocation: string
    ) {
      if ("click" === fromLocation) {
        //@ts-ignore
        this.clearApplicationNavigationModuleFromStore();
        //@ts-ignore
        this.clearAllStoreValuesExceptUser();
        //@ts-ignore
        this.clearProgramEligDataFromStore();
      }
      this.displaySave = false;

      //@ts-ignore
      store.dispatch.dashboardModule.resetData();
      this.dashboardContent.userInfo = this.userInfo;
      if (
        tabItem &&
        tabItem.screeningResponseId !== null &&
        tabItem.screeningResponseId === -1
      ) {
        this.displayMyself();

        this.reloadOnCancel = true;
        //on click of Add New Applicant
        this.showSurrogateDialog = true;
      } else {
        this.loadingCounter = 5;
        this.isProfileLoading = true;
        this.populateApplicantProfile(tabItem.applicantProfileId);

        this.populateScreenResponse(tabItem.screeningResponseId);

        this.populateProgramElig(tabItem.applicantProfileId);

        this.populateHeaderSteppers(tabItem.applicantProfileId);

        this.loadDraftProgramInfo(tabItem.applicantProfileId);

        if (
          (tabItem.applicantFirstName == null ||
            tabItem.applicantFirstName.length === 0) &&
          (tabItem.applicantLastName == null ||
            tabItem.applicantLastName.length === 0)
        ) {
          // on load of dashboard, when tab name is empty
          this.resetScreen();
          this.showResultDashboard = true;

          this.screeningResponseId = tabItem.screeningResponseId; //to update names in screening response

          // If only one tab is present display myself tab,
          // else check if first tab is related to self or not.
          if (this.tabList.length > 2) {
            this.displayMyself();
          } else {
            this.displayMyselfProp = true;
          }
          this.showSurrogateDialog = true;

          this.displaySave = true;
        }
      }
    },
    populateScreenResponse(screeningResponseId: number) {
      axios
        .get(DASHBOARD_ENDPOINT.GET_SCREENING_RESPONSE_BY_ID, {
          params: {
            screeningResponseId: screeningResponseId
          }
        })
        .then(response => {
          //@ts-ignore
          store.commit.dashboardModule.SET_DASHBOARD_SCREENING_RESPONSE(
            response.data
          );
          //@ts-ignore
          store.commit.screeningResponseModule.SET_SCREENING_RESPONSE(
            response.data
          );
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.loadingCounter--;
          this.loadingStatusCheck();
        });
    },
    populateApplicantProfile(applicantProfileId: number) {
      axios
        .get(DASHBOARD_ENDPOINT.GET_APPLICANT_PROFILE, {
          params: {
            applicantProfileId: applicantProfileId
          }
        })
        .then(response => {
          this.dashboardContent.applicantProfile = response.data;
          //@ts-ignore
          store.commit.dashboardModule.SET_DASHBOARD_APPLICANT_PROFILE(
            response.data
          );
          //@ts-ignore
          store.commit.applicantProfileModule.SET_APPLICANT_PROFILE(
            response.data
          );
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.loadingCounter--;
          this.loadingStatusCheck();
        });
    },
    populateProgramElig(applicantProfileId: number) {
      axios
        .get(DASHBOARD_ENDPOINT.GET_APPLICANT_PROFILE_PROGRAM, {
          params: {
            applicantProfileId: applicantProfileId
          }
        })
        .then(response => {
          //@ts-ignore
          store.commit.dashboardModule.SET_DASHBOARD_PROG_ELIG(response.data);
          //@ts-ignore
          store.commit.programEligModule.SET_PROG_ELIG(response.data);
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.loadingCounter--;
          this.loadingStatusCheck();
        });
    },
    //to populate the headers
    populateHeaderSteppers(applicantProfileId: number) {
      let url = DASHBOARD_ENDPOINT.GET_PREELIG_PRGMS;

      axios
        .get(url, {
          params: {
            applicantProfileId: applicantProfileId
          }
        })
        .then(response => {
          if (response.status === HTTP_STATUS.OK) {
            this.stepperHeaderModel.stepperHeaders = response.data;
            for (let stepHeaders of this.stepperHeaderModel.stepperHeaders) {
              if (stepHeaders["programType"] === "FTP") {
                // ADD SEPTA agency to header if SEPTA question is selected as yes
                if (
                  this.draftProgramInfo.transitIdCardApplyInd &&
                  this.draftProgramInfo.transitIdCardApplyInd === "Y"
                ) {
                  stepHeaders["description"] =
                    stepHeaders["description"] + " and " + " SEPTA";
                }
              }
            }
          }
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.loadingCounter--;
          this.loadingStatusCheck();
        });
    },

    loadDraftProgramInfo(applicantProfileId: number) {
      let url = TES_APP_ENDPOINT.GET_DRAFT_PROGRAM_INFO;
      axios
        .get(url, {
          params: {
            applicantProfileId: applicantProfileId
          }
        })
        .then(response => {
          let draftProgramInfoTemp = response.data;
          if (draftProgramInfoTemp) {
            //@ts-ignore
            this.draftProgramInfo = response.data;
          }
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        //@ts-ignore
        .finally(() => {
          this.loadingCounter--;
          this.loadingStatusCheck();
        });
    },

    loadingStatusCheck() {
      if (this.loadingCounter < 1) {
        this.isProfileLoading = false;
        this.setDashboardType();
        this.displayAddNew = true;
      }
    },
    showDashboard() {
      if (this.userInfo.userGroups) {
        //@ts-ignore
        if (this.isAgencyUser) {
          //Agency Dashboard
          this.showOrganizationDashboard = true;
          //@ts-ignore
        } else if (this.isOrganizationUser) {
          //Organization Dashboard
          this.showOrgApplicantDashboard = true;
        }
      }
    },
    getTabs() {
      this.isTabsLoading = true;

      axios
        .get(DASHBOARD_ENDPOINT.GET_TABS, {
          params: { userId: this.userInfo.userId }
        })
        .then(response => {
          if (response.status === HTTP_STATUS.OK) {
            this.tabList = response.data;

            if (this.tabList === null || this.tabList.length === 0) {
              //when there is no screening response for this user, display initial dashboard
              this.showSurrogateDialog = false;
              //To get user details and dashboard type to display on surrogate dialog in intial dashboard.
              //this.populateDashboardContent();
              this.showInitialDashboard = true;
            } else {
              //when there is/are some screening response for this user
              this.tabId = 0;

              if (this.userInfo.esecScreeningId) {
                this.setTabIdByesecScrnId();
              } else {
                this.setTabId();
              }

              this.populateDashboardContent(this.tabList[this.tabId], "");

              this.selectedTab = this.tabList[this.tabId]; //to default to 1st option in dropdown
            }
          }
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.isTabsLoading = false;
        });
    },
    setTabId() {
      for (let i in this.tabList) {
        if (this.tabList[i]["screeningResponseId"] === this.scrnRespID) {
          this.tabId = Number(i);
        }
      }
    },
    setTabIdByesecScrnId() {
      //when esecScreeningId is presennt in request headers
      let tabToSelect: IScreeningResponse;

      for (let i = 0; i < this.tabList.length; i++) {
        tabToSelect = this.tabList[i];
        if (tabToSelect.esecScreeningId === this.userInfo.esecScreeningId) {
          this.tabId = i;
        }
      }
      this.userInfo.esecScreeningId = "";
    },
    loadADADynamicGPC() {
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.screeningResponse.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        this.uiFeature["ADA_Y"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["ADA_Y"].largeText,
          CNST_TEXT.THE_APPLICANTS,
          CNST_TEXT.THE_APPLICANTS
        );
        //@ts-ignore
        this.uiFeature["Y_ADA_1"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["Y_ADA_1"].largeText,
          CNST_TEXT.THE_APPLICANT_LIVES,
          CNST_TEXT.THEY
        );
      } else {
        //@ts-ignore
        this.uiFeature["ADA_Y"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["ADA_Y"].largeText,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOUR
        );
        //@ts-ignore
        this.uiFeature[
          "Y_ADA_1"
          //@ts-ignore
        ].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["Y_ADA_1"].largeText,
          CNST_TEXT.YOU_LIVE,
          CNST_TEXT.YOU
        );
      }
    },
    loadFTPDynamicGPC() {
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.screeningResponse.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        this.uiFeature["FTP_Y"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["FTP_Y"].largeText,
          CNST_TEXT.THE_APPLICANTS,
          CNST_TEXT.THE_APPLICANTS
        );
        //@ts-ignore
        this.uiFeature["Y_FTP_1"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["Y_FTP_1"].largeText,
          CNST_TEXT.THE_APPLICANT_LIVES,
          CNST_TEXT.MEETS
        );
      } else {
        //@ts-ignore
        this.uiFeature["FTP_Y"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["FTP_Y"].largeText,
          "",
          CNST_TEXT.YOUR
        );
        //@ts-ignore
        this.uiFeature[
          "Y_FTP_1"
          //@ts-ignore
        ].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["Y_FTP_1"].largeText,
          CNST_TEXT.YOU_LIVE,
          CNST_TEXT.YOU_MEET
        );
      }
    },
    loadMATPDynamicGPC() {
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.screeningResponse.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        this.uiFeature["MATP_Y"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["MATP_Y"].largeText,
          CNST_TEXT.THE_APPLICANTS,
          CNST_TEXT.THE_APPLICANTS
        );
        //@ts-ignore
        this.uiFeature[
          "Y_MATP_1"
          //@ts-ignore
        ].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["Y_MATP_1"].largeText,
          CNST_TEXT.THE_APPLICANT_HAS,
          CNST_TEXT.NEEDS
        );
      } else {
        //@ts-ignore
        this.uiFeature["MATP_Y"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["MATP_Y"].largeText,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOUR
        );
        //@ts-ignore
        this.uiFeature[
          "Y_MATP_1"
          //@ts-ignore
        ].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["Y_MATP_1"].largeText,
          CNST_TEXT.YOU_HAVE,
          CNST_TEXT.NEED
        );
      }
    },
    loadPWDDynamicGPC() {
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.screeningResponse.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        this.uiFeature["PWD_Y"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["PWD_Y"].largeText,
          CNST_TEXT.THE_APPLICANTS,
          CNST_TEXT.THE_APPLICANTS
        );
        //@ts-ignore
        this.uiFeature[
          "Y_PWD_1"
          //@ts-ignore
        ].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["Y_PWD_1"].largeText,
          CNST_TEXT.THE_APPLICANT_HAS
        );
      } else {
        //@ts-ignore
        this.uiFeature["PWD_Y"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["PWD_Y"].largeText,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOUR
        );
        //@ts-ignore
        this.uiFeature[
          "Y_PWD_1"
          //@ts-ignore
        ].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["Y_PWD_1"].largeText,
          CNST_TEXT.YOU_HAVE
        );
      }
    },
    loadRFTPDynamicGPC() {
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.screeningResponse.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        this.uiFeature["RFTP_Y"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["RFTP_Y"].largeText,
          CNST_TEXT.THE_APPLICANT,
          CNST_TEXT.THEIR,
          CNST_TEXT.THE_APPLICANTS,
          CNST_TEXT.THE_APPLICANTS
        );
        //@ts-ignore
        this.uiFeature[
          "Y_RFTP_1"
          //@ts-ignore
        ].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["Y_RFTP_1"].largeText,
          CNST_TEXT.THE_APPLICANT_LIVES,
          ""
        );
      } else {
        //@ts-ignore
        this.uiFeature["RFTP_Y"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["RFTP_Y"].largeText,
          CNST_TEXT.YOU,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOUR
        );
        //@ts-ignore
        this.uiFeature[
          "Y_RFTP_1"
          //@ts-ignore
        ].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["Y_RFTP_1"].largeText,
          CNST_TEXT.YOU_LIVE,
          CNST_TEXT.YOU
        );
      }
    },
    loadAGINGDynamicGPC() {
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.screeningResponse.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        this.uiFeature["AGING"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["AGING"].largeText,
          CNST_TEXT.THE_APPLICANT,
          CNST_TEXT.THE_APPLICANTS
        );
        //@ts-ignore
        this.uiFeature[
          "Y_AGING_1"
          //@ts-ignore
        ].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["Y_AGING_1"].largeText,
          CNST_TEXT.THE_APPLICANT_DOES_NOT,
          CNST_TEXT.THEY
        );
      } else {
        //@ts-ignore
        this.uiFeature["AGING"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["AGING"].largeText,
          CNST_TEXT.YOU,
          CNST_TEXT.YOUR
        );
        //@ts-ignore
        this.uiFeature[
          "Y_AGING_1"
          //@ts-ignore
        ].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["Y_AGING_1"].largeText,
          CNST_TEXT.YOU_DONT,
          CNST_TEXT.YOU
        );
      }
    },
    loadSSRDynamicGPC() {
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.screeningResponse.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        this.uiFeature["SSR_Y"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["SSR_Y"].largeText,
          CNST_TEXT.THE_APPLICANT,
          CNST_TEXT.THE_APPLICANTS
        );
        //@ts-ignore
        this.uiFeature[
          "Y_SSR_1"
          //@ts-ignore
        ].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["Y_SSR_1"].largeText,
          CNST_TEXT.THE_APPLICANT_MEETS
        );
      } else {
        //@ts-ignore
        this.uiFeature["SSR_Y"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["SSR_Y"].largeText,
          CNST_TEXT.YOU,
          CNST_TEXT.YOUR
        );
        //@ts-ignore
        this.uiFeature[
          "Y_SSR_1"
          //@ts-ignore
        ].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["Y_SSR_1"].largeText,
          CNST_TEXT.YOU_MEET
        );
      }
    },
    loadVTPDynamicGPC() {
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.screeningResponse.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        this.uiFeature["VTP"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["VTP"].largeText,
          CNST_TEXT.THE_APPLICANTS,
          CNST_TEXT.THE_APPLICANTS
        );
        //@ts-ignore
        this.uiFeature[
          "Y_VTP_1"
          //@ts-ignore
        ].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["Y_VTP_1"].largeText,
          CNST_TEXT.THE_APPLICANT_IS
        );
      } else {
        //@ts-ignore
        this.uiFeature["VTP"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["VTP"].largeText,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOUR
        );
        //@ts-ignore
        this.uiFeature[
          "Y_VTP_1"
          //@ts-ignore
        ].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["Y_VTP_1"].largeText,
          CNST_TEXT.YOU_ARE
        );
      }
    }
  },

  beforeDestroy() {
    //@ts-ignore
    this.clearApplicationNavigationModuleFromStore();
  },
  mounted() {
    //@ts-ignore
    this.$vuetify.goTo(0, {
      duration: 500,
      offset: 0,
      easing: "easeInOutCubic"
    });
  },
  watch: {
    uiFeature: {
      deep: true,
      handler(obj: Object) {
        //@ts-ignore
        if (obj["Y_ADA_1"]) {
          this.loadADADynamicGPC();
        }
        //@ts-ignore
        if (obj["Y_FTP_1"]) {
          this.loadFTPDynamicGPC();
        }
        //@ts-ignore
        if (obj["Y_MATP_1"]) {
          this.loadMATPDynamicGPC();
        }
        //@ts-ignore
        if (obj["Y_PWD_1"]) {
          this.loadPWDDynamicGPC();
        }
        //@ts-ignore
        if (obj["Y_RFTP_1"]) {
          this.loadRFTPDynamicGPC();
        }
        //@ts-ignore
        if (obj["Y_AGING_1"]) {
          this.loadAGINGDynamicGPC();
        }
        //@ts-ignore
        if (obj["Y_SSR_1"]) {
          this.loadSSRDynamicGPC();
        }
        //@ts-ignore
        if (obj["Y_VTP_1"]) {
          this.loadVTPDynamicGPC();
        }
      }
    }
  }
});
