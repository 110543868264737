
import Vue from "vue";
import store from "@/store";
import { IAlert } from "@/types";

export default Vue.extend({
  name: "show-alert-message",
  computed: {
    alert(): IAlert {
      return store.getters.alertModule.getAlert;
    }
  },
  watch: {
    alert: {
      deep: true,
      handler(newVal: IAlert) {
        if (newVal) {
          //@ts-ignore
          this.$vuetify.goTo(0, {
            duration: 500,
            offset: 0,
            easing: "easeInOutCubic"
          });
        }
      }
    }
  }
});
