<template>
  <div>
    <v-divider
      v-if="index > 0"
      :key="'du' + index"
      aria-hidden="true"
    ></v-divider>
    <v-list-item :ripple="false" :key="index" @click.stop>
      <v-list-item-action v-show="!$vuetify.breakpoint.xsOnly">
        <v-icon color="red" v-if="doc.uploadStatus === 'failed'"
          >fa-exclamation-circle</v-icon
        >
        <v-icon color="green" v-if="doc.uploadStatus === 'uploaded'"
          >fa-check-circle</v-icon
        >
        <v-progress-circular
          indeterminate
          v-if="doc.uploadStatus === 'uploading'"
          color="primary"
        />
      </v-list-item-action>

      <v-list-item-content>
        <v-container pa-0 mt-4 fluid>
          <v-row no-wrap>
            <v-col>
              <v-row wrap v-if="!hideDocDescription">
                <v-col md="3" lg="3">
                  <v-text-field
                    label="File Name*"
                    :rules="[rules.required]"
                    maxlength="100"
                    :single-line="!showHeader"
                    outlined
                    v-model="doc.metaData.filename"
                    :class="
                      doc.uploadStatus === 'uploaded' ? '' : 'requiredTextField'
                    "
                    :disabled="doc.uploadStatus === 'uploaded'"
                    :autocomplete="this.getAutoCompleteOff()"
                  ></v-text-field>
                </v-col>
                <v-col md="3" lg="3">
                  <v-select
                    :items="docDescCodes"
                    :rules="[rules.required]"
                    item-value="resourceCode"
                    item-text="displayName"
                    label="Document Type*"
                    outlined
                    v-model="doc.metaData.documentTypeCode"
                    :class="
                      doc.uploadStatus === 'uploaded' ? '' : 'requiredTextField'
                    "
                    :disabled="doc.uploadStatus === 'uploaded'"
                  ></v-select>
                </v-col>
                <v-col md="3" lg="3">
                  <v-text-field
                    label="Description"
                    maxlength="100"
                    outlined
                    v-model="doc.metaData.description"
                    :disabled="doc.uploadStatus === 'uploaded'"
                    :rules="
                      doc.metaData.documentTypeCode === 'OTHER_DOC'
                        ? [rules.required]
                        : []
                    "
                  ></v-text-field>
                </v-col>
                <v-col md="3" lg="3" class="pt-6">
                  <v-btn
                    text
                    class="mt-2"
                    @click="removeDoc()"
                    :disabled="doc.uploadStatus === 'uploaded'"
                  >
                    <v-icon class="pl-2 pr-1">fa-minus</v-icon>
                    <span>Remove</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import FieldEnrtyMixin from "@/mixins/fieldEntryMixin.vue";

export default {
  name: "DocumentUploadRow",
  components: {},
  mixins: [FieldEnrtyMixin],
  data() {
    return {};
  },
  props: {
    index: { type: Number, default: 0 },
    doc: { type: Object, default: null },
    showHeaders: { type: Boolean, default: false },
    hideDocDescription: { type: Boolean, default: false },
    docDescCodes: {
      type: Array,
      default: null
    }
  },
  computed: {
    showHeader() {
      return this.showHeaders;
    }
  },
  methods: {
    removeDoc() {
      this.$emit("removeDoc");
    }
  }
};
</script>
