var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"removeGutters"},[_c('ProgressBar',{attrs:{"dialog":_vm.isDataLoading}}),(_vm.applicationReview)?_c('h1',{staticClass:"headerPadding"},[_vm._v(" Profile Summary "),(_vm.screeningResponse.onBehalfOfInd === 'Y')?_c('span',{staticClass:"h1StyleForNonHeadingTag"},[_vm._v(" for "+_vm._s(_vm.applicantProfile.applicantFirstName)+" "+_vm._s(_vm.applicantProfile.applicantLastName)+" ")]):_vm._e()]):_vm._e(),(_vm.applicationReview)?_c('p',{staticClass:"headerPadding"},[_vm._v(" This information will be used for all of your applications ")]):_vm._e(),(
      _vm.applicantProfile.profileEditMsg || _vm.applicantProfile.profileSuccessMsg
    )?_c('editProfileProgramChangesMsg'):_vm._e(),_c('v-card',{staticClass:"noGutters",attrs:{"flat":""}},[_c('PersonalInfoReview'),_c('HomeAddressReview',{attrs:{"applicantAddress":_vm.applicantProfile.homeAddress,"applicantAddressPrevious":_vm.homeAddressPrevious,"sameMailingInd":_vm.applicantProfile.sameMailingInd}}),(
        _vm.isAgencyUser &&
          null != _vm.draftProgramInfo &&
          _vm.draftProgramInfo.driverLicenseVerifyCode === 'VRFD'
      )?_c('DriverLicenseReview'):_vm._e(),(_vm.applicantProfile.sameMailingInd === 'N')?_c('HomeAddressReview',{attrs:{"applicantAddress":_vm.applicantProfile.mailingAddress,"applicantAddressPrevious":_vm.mailingAddressPrevious}}):_vm._e(),_c('EmergencyContactReview'),(!_vm.isAlleghenyCounty)?_c('DemographicInfoReview'):_vm._e(),(!_vm.isAlleghenyCounty)?_c('TransportationInfoReview'):_vm._e(),(!_vm.isAlleghenyCounty)?_c('MobilityInfoReview'):_vm._e(),(_vm.applicantProfile.onBehalfOfInd === 'Y')?_c('PreparerInfoReview'):_vm._e(),(!_vm.singleFlow)?_c('div',{staticClass:"btnControlsSection stickContentToBottom"},[_c('v-spacer',{staticClass:"btnEditPadding displayElement"}),(!_vm.applicantProgramInfo.applicationId > 0 && _vm.showApplRevCtrls)?_c('v-btn',{staticClass:"editBtnPadding primaryButton buttonResponsive",attrs:{"tile":""},on:{"click":function($event){return _vm.editProfile()}}},[_vm._v(" Edit Profile ")]):_vm._e(),_c('v-spacer',{staticClass:"btnEditPadding editBtnElement"}),(!_vm.applicantProgramInfo.applicationId > 0 && _vm.showApplRevCtrls)?_c('v-btn',{staticClass:"editBtnPadding secondaryButton buttonResponsive",attrs:{"tile":""},on:{"click":_vm.GotoDashboard}},[_vm._v(" Go to Dashboard ")]):_vm._e()],1):_vm._e(),(_vm.showDashboard)?_c('ApplicantProfileDashboard',{ref:"applDashboard"}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }