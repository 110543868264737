var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mt-4"},[_c('v-card',{staticClass:"pa-6",attrs:{"width":"100%"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.agencyStatusContent,"items-per-page":10,"sort-by":['tesApplicationId']},scopedSlots:_vm._u([{key:"item.tesApplicationId",fn:function({ item }){return [_vm._v(" "+_vm._s(item.tesApplicationId)+" ")]}},{key:"item.statusCode",fn:function({ item }){return [(
            !(
              item.statusCode === 'SBMTD' ||
              item.statusCode === 'RE_COR' ||
              item.statusCode === 'PEND_CONF' ||
              item.statusCode === 'APEL_INPRG'
            )
          )?_c('div',[_vm._v(" "+_vm._s(_vm.uiFeature[item.statusCode].largeText)+" ")]):_vm._e(),(
            item.statusCode === 'SBMTD' ||
              item.statusCode === 'RE_COR' ||
              item.statusCode === 'PEND_CONF' ||
              item.statusCode === 'APEL_INPRG'
          )?_c('div',[_vm._v(" "+_vm._s(_vm.uiFeature[item.statusCode].displayName)+" ")]):_vm._e()]}},{key:"item.programName",fn:function({ item }){return [_vm._v(" "+_vm._s(item.programName)+" ")]}},{key:"item.countyName",fn:function({ item }){return [_vm._v(" "+_vm._s(item.countyName)+" ")]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }