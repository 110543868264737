
import Vue from "vue";
import DocumentRow from "@/components/common/DocumentRow.vue";
import DraftProgramMixin from "@/mixins/DraftProgramMixin.vue";
export default Vue.extend({
  name: "DocumentsSection",
  components: {
    DocumentRow
  },
  mixins: [DraftProgramMixin],
  data() {
    return {
      showProgressBar: false
    };
  },
  props: {
    documentTypeCode: {
      type: String,
      default: null
    },
    docs: { type: Array, default: null }
  },
  methods: {}
});
