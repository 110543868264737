import { IStepperHeaderList } from "@/types";

import { stepperHeadersDefault } from "@/data/AppData";
import { clone } from "@/util/clone";
import { moduleActionContext } from "..";
import { defineModule } from "direct-vuex";
import { DASHBOARD_ENDPOINT } from "@/constants/EndPoints";
import axios from "axios";
import { HTTP_STATUS } from "@/constants/HttpStatusCode";

export interface StepperHeaderState {
  stepperHeaderModel: IStepperHeaderList;
}

const stepperHeaderModule = defineModule({
  namespaced: true,

  state: {
    stepperHeaderModel: clone(stepperHeadersDefault)
  } as StepperHeaderState,

  getters: {
    getStepperHeaderModel: (state: StepperHeaderState) =>
      state.stepperHeaderModel
  },
  mutations: {
    SET_STEPPER_HEADER_MODEL(
      state: StepperHeaderState,
      newStepperHeaderModel: IStepperHeaderList
    ) {
      state.stepperHeaderModel = newStepperHeaderModel;
    }
  },
  actions: {
    resetData(context: any) {
      let { commit } = stepperHeaderActionContext(context);
      //clearing the draftprograminfo data in store.
      let stepperHeaderModel = clone(stepperHeadersDefault);
      //@ts-ignore
      commit.SET_STEPPER_HEADER_MODEL(stepperHeaderModel);
    },
    async populateStepperHeaders(context: any, applProfileId: any) {
      let { commit, rootDispatch } = stepperHeaderActionContext(context);
      let url = DASHBOARD_ENDPOINT.GET_PREELIG_PRGMS;

      await axios
        .get(url, {
          params: {
            applicantProfileId: applProfileId
          }
        })
        .then(response => {
          if (response.status === HTTP_STATUS.OK) {
            commit.SET_STEPPER_HEADER_MODEL(response.data);
          }
        })
        .catch(() => {
          rootDispatch.alertModule.showAlertMessageByGPC("ER_INLINE");
        });
    }
  }
});

export default stepperHeaderModule;
const stepperHeaderActionContext = (context: any) =>
  moduleActionContext(context, stepperHeaderModule);
