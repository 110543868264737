import {
  IAppProperties,
  IAlert,
  IApplicantProfile,
  IApplicantMobilityDevice,
  IDrivingLicense,
  IDraftProgramInfo,
  IApplicantAddress,
  ITESUser,
  IDashboardContent,
  IScreeningResponse,
  IProgramEligModel,
  IProgramsList,
  IScrAnswers,
  IScreeningQuestions,
  IApplicationNavigation,
  ITESApplicationModel,
  IAdvacnedSearch,
  IAdvancedSearchCriteria,
  IAdvnacedSearchResultObj,
  IApplicantDetails,
  IDatabaseAuditFields,
  IApplicationStepperHeaderModel,
  IStepperHeaderList,
  IApplicantEcolaneProfileModel,
  ITESEcolaneModel,
  ITESEcolaneAddress,
  IISAMUserAttributes,
  IApplicationReviewModel,
  IReviewResultReasonList,
  IOtherReviewResultReasonList,
  ICreateEcolaneRequestModel,
  ICreateEcolaneRequestWrapperModel,
  ITESEcolaneRequestWrapperModel,
  IReviewResultReasonModel,
  IUIFeatureObjectModel,
  IAttachmentCheckBoxSelectModel,
  IStgEcolaneCustProfileModel,
  IEcolaneDataUpdate,
  IPostingStgModel,
  IUpdateEcolaneRequestModel,
  IUpdateEcolaneRequestWrapperModel,
  IDraftPhysicianInfo,
  IAssignUserModel,
  IGISDataModel
} from "@/types";
import { ALERT_TYPE } from "@/constants/CommonWebCodes";

export const stepperHeadersDefault: IStepperHeaderList = {
  stepperHeaders: []
};

export const appPropertiesDefault: IAppProperties = {
  loadingInvoice: false,
  loadingPayeezyForm: false,
  failedPayeezyFormCreation: false,
  validDrivingLicense: false,
  validInvoice: true,
  validParent: true,
  homeMainHTML: ""
};

export const databaseAuditFields: IDatabaseAuditFields = {
  createdBy: "",
  createdTs: "",
  updatedBy: "",
  updatedTs: ""
};

export const alertDefault: IAlert = {
  show: false,
  type: ALERT_TYPE.SUCCESS,
  dismissible: true,
  message: "Default Value",
  timeout: 6000,
  multiline: false,
  error: "",
  response: ""
};
export const tesUserDefault: ITESUser = {
  userId: 0,
  userName: "",
  userFirstName: "",
  userLastName: "",
  emailAddress: "",
  phoneNumber: "",
  createdBy: "",
  organizationName: "",
  loginId: "",
  isLoggedOut: false,
  organizationNameList: [],
  fein: "",
  userFullName: "",
  loginIdsinSameOrg: [],
  userGroups: "",
  transitAgencyCode: "",
  transitAgencyName: "",
  esecScreeningId: "",
  userDob: "",
  organizationType: "",
  userGroupName: ""
};

export const demographicInfo: IApplicantMobilityDevice = {
  mobilityDeviceCodes: [],
  otherMobilityDevice: "",
  applicantProfileId: 0
};

export const applicantAddress: IApplicantAddress = {
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "PA",
  zip: "",
  paCountyCode: "",
  sameMailingInd: "",
  stateCollegeInd: "",
  applicantProfileId: undefined,
  profileAddressId: undefined,
  addressTypeCode: "",
  zipExtension: "",
  maskedZip: "",
  applicationId: 0,
  auditFields: databaseAuditFields,
  countyName: "",
  streetNumber: "",
  street: "",
  apartmentNumber: "",
  postcode: ""
};

export const drivingLicenseInfoDefault: IDrivingLicense = {
  driverLicenseDataId: 0,
  applicantProfileId: 0,
  dlNumber: "",
  birthDate: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "",
  zipCode: "",
  countyCode: "",
  lastName: "",
  firstName: "",
  middleName: "",
  suffixName: "",
  expiryDate: "",
  auditFields: databaseAuditFields,
  createdBy: "",
  updatedBy: "",
  dlBirthDate: ""
};

export const applicantProfileDefault: IApplicantProfile = {
  applicantUserId: 0,
  createdByUserId: 0,
  applicantProfileId: 0,
  tesApplicationId: 0,
  applicantFirstName: "",
  applicantLastName: "",
  applicantMiddleInitial: "",
  applicantDob: "",
  applicantGender: "",
  applicantPrimaryPhone: "",
  applicantPrimaryPhoneExt: "",
  applicantSecondaryPhone: "",
  applicantSecondaryPhoneExt: "",
  applicantEmailAddress: "",
  emergencyFirstName: "",
  emergencyLastName: "",
  emergencyMiddleInitial: "",
  emergencyPrimaryPhone: "",
  emergencyPhoneExt: "",
  emergencyRelationship: "",
  primaryLanguageCode: "",
  interpreterInd: "",
  mobilityDeviceUseInd: "",
  applicantMobilityDevices: demographicInfo,
  homeAddress: {} as IApplicantAddress,
  mailingAddress: {} as IApplicantAddress,
  applicantResponseId: 0,
  updatedTs: "",
  applicantProfileChange: "N",
  sameMailingInd: "",
  applicationId: 0,
  cycleNumber: 0,
  isCountyChanged: false,
  isDOBChanged: false,
  profileEditMsg: "",
  profileSuccessMsg: "",
  selectedCounty: "",
  selectedDOB: "",
  selectedStateCollgInd: "",
  isStateCollgIndChanged: false,
  noChangePrograms: "",
  updatedPrograms: "",
  removedPrograms: "",
  addedPrograms: "",
  withdrawPrograms: "",
  onBehalfOfInd: "",
  screeningResponseId: 0,
  auditFields: databaseAuditFields,
  ethnicityCode: "",
  nursingHomeInd: "",
  nursingHomeDischargeInd: "",
  assistedLivingInd: "",
  assistedLivingTransportInd: "",
  transportationAssistanceInd: "",
  travelAttendantInd: "",
  currMeansOfTransportation: "",
  liftEnabledVehicleInd: "",
  wheelChairTransferInd: "",
  wheelChairSizeInd: "",
  drivingLicenseModel: drivingLicenseInfoDefault,
  duplicateApplications: false,
  continueWithDupAppl: false,
  organizationType: "",
  transitAgencyCode: "",
  transitAgencyName: "",
  organizationName: "",
  gisCallInd: "N",
  gisAgenciesList: [],
  gisResponseJSON: new Blob(),
  relationshipToApplicant: "",
  accessiblePrintMatInd: "",
  otherPrintMatFormat: "",
  printMatFormatsList: [],
  otherTranspWays: "",
  transpWaysList: []
};

export const applicantProgramDefault: IProgramsList = {
  applicantProfileId: 0,
  programTypeCode: "",
  transitAgencyCode: "",
  eligibilityInd: "",
  statusCode: "",
  statusCodeDesc: "",
  programName: "",
  transiAgencyName: "",
  applicantDob: "",
  workflowItemId: 0,
  applicationId: 0,
  submittedOn: "",
  transitAgencyLogo: "",
  cycleNumber: 0,
  agencyEmailAddress: "",
  createdByUserId: 0,
  createdBy: "",
  returnRestrictInd: "",
  emailOption: "",
  reviewAgency: "",
  ecolaneConfigAgncy: "",
  ecolaneDatabaseAgency: "",
  submitDate: "",
  byPassInd: ""
};

export const draftProgramInfoDefault: IDraftProgramInfo = {
  applicantProfileId: 0,
  applicantUserId: 0,
  //MATP
  medAssistanceEligibilityInd: "",
  medicaidCardInd: "",
  medicaidCardRecipientNum: "",
  medicaidCardIssueNum: "",
  hasSSN: "",
  enSSN: "",
  householdMatpUseInd: "",
  haveValidDL: "",
  dhsAuthInd: "",
  workingVehicleInd: "",
  dhsAuthDate: "",
  transportAvailabilityInd: "",
  liveNearBusInd: "",
  massTransitInabilityInd: "",
  transportationDisabilityInd: "",
  rideAloneInd: "",
  //FTP
  ftpApplicationInd: "",
  ftpAgencyCode: "",
  transitIdCardApplyInd: "",
  //SSR-FTP
  ageVerificationMethodCode: "",
  driverLicenseNumber: "",
  driverLicenseVerifyCode: "",
  //ADA
  voterRegistrationSelectCode: "",
  transitAgencyAreaCode: "",
  providerAreaTravelInd: "",
  fixedRouteDisabilityInd: "",
  fixedRouteDisabilityDesc: "",
  //PWD-ADA
  disabilityInd: "",
  visualDisabilityInd: "",
  hearingDisabilityInd: "",
  mentalDisabilityInd: "",
  cognitiveDisabilityInd: "",
  mobilityDisabilityInd: "",
  otherDisabilityInd: "",
  otherDisabilityDesc: "",
  disabilityType: "",
  temporaryDisabilityEndDate: "",
  disabilityCndtn: "",
  //current program info
  programInfo: applicantProgramDefault,
  programStatus: "",
  userInfo: tesUserDefault,
  // for post submitted applications
  programTypeCode: "",
  applicationId: 0,
  cycleNumber: 0,
  //audit fields
  auditFields: databaseAuditFields,
  //added for submitting programs
  programInfoList: [],
  draftFixedRouteUsageList: [],
  applicantName: "",
  screeningResponseId: 0,
  onBehalfOfInd: "",
  //RFTP
  rftpApplyInd: "",
  medicareCardInd: "",
  rftpProviderAgencyCode: "",
  veteranInd: "",
  upcomingAptInd: "",
  upcomingAptDate: "",
  draftPhysicianInfos: [],
  multiplePhysicianInd: "",
  matpEligCode: "",
  managedCarePrvdr: ""
};

export const screeningResponseDefault: IScreeningResponse = {
  screeningResponseId: 0,
  paCountyCode: "",
  stateCollegeInd: "",
  ageGroup: "",
  applicantDob: "",
  transportationDisabilityInd: "",
  medicaidInd: "",
  userId: 0,
  selectedPrograms: [],
  providersResponse: [],
  fixedRouteScreeningResults: [],
  updatedTs: "",
  applicantFirstName: "",
  applicantLastName: "",
  applicantNameDisp: "",
  index: 0,
  applicantProfileId: 0,
  onBehalfOfInd: "",
  esecScreeningId: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "PA",
  zip: "",
  zipExtension: "",
  veteranInd: "",
  medicareCardInd: "",
  rftpApplyInd: ""
};

export const programEligModelDefault: IProgramEligModel = {
  applicantAge: 0,
  eligiblePrograms: [],
  preEligiblePrograms: [],
  notEligiblePrograms: [],
  submittedPrograms: [],
  withdrawPrograms: [],
  approvedPrograms: [],
  inEligiblePrograms: [],
  accessOutsidePrograms: []
};

export const dashboardContentDefault: IDashboardContent = {
  dashboardType: "",
  userInfo: tesUserDefault,
  applicantProfile: applicantProfileDefault,
  screeningResponse: screeningResponseDefault,
  programEligModel: programEligModelDefault,
  tabList: []
};

export const screeningAnswersDefault: IScrAnswers = {
  answers: [],
  selCounty: [],
  selDisability: "",
  ageGroup: "",
  showVTPQues: false,
  showRFTPQues: false,
  homeAddress: {} as IApplicantAddress,
  applySEPTACounty: false
};

export const screeningQuestionsDefault: IScreeningQuestions = {
  qid: 0,
  qidSeq: 0,
  whyQues: "",
  whyQuesText: "",
  values: {},
  provider: false,
  qtext: "",
  qoption: "",
  disability: false,
  lineItemid: "",
  transitAgencyAreaCode: ""
};

export const applicationNavigationDefault: IApplicationNavigation = {
  submitAppl: false,
  applNumber: 0,
  applProfileEdit: false
};

export const isamUserAttributesDefault: IISAMUserAttributes = {
  orgId: "",
  orgTypeCode: "",
  attributeDetails: new Map()
};

export const tesApplicationDefault: ITESApplicationModel = {
  applicantProfileId: 0,
  applicantionId: 0,
  cycleNumber: 0,
  programTypeCode: "",
  transitAgencyCode: "",
  createdByUserId: 0,
  applicantUserId: 0,
  statusCode: "",
  statusDate: "",
  createdBy: "",
  printForMe: "",
  isAgencyUser: "",
  isOrganizationUser: "",
  isCOReadOnlyUser: "",
  applicantProfileModel: applicantProfileDefault,
  draftProgramInfoModel: draftProgramInfoDefault,
  programEligibilityModel: applicantProgramDefault,
  stepperHeaders: stepperHeadersDefault,
  isamUserAttributes: isamUserAttributesDefault,
  nameDOBMatchInd: "",
  createdByType: ""
};

export const advancedSearchCriteriaDefault: IAdvancedSearchCriteria = {
  lastName: "",
  programList: [],
  statusList: [],
  agencyList: [],
  countyList: [],
  dob: "",
  fromDate: "",
  toDate: "",
  createdByType: "",
  createdByName: ""
};
export const advancedSearchResultDefault: IAdvnacedSearchResultObj = {
  tesApplicationId: "",
  applicantFirstName: "",
  applicantLastName: "",
  applicantDOB: "",
  statusCode: "",
  statusDate: "",
  transitAgencyName: "",
  programName: "",
  countyName: "",
  applicantProfileId: "",
  transitAgencyCode: "",
  programTypeCode: "",
  daysRemain: "",
  createdByAgency: "",
  upcomingAptInd: "",
  createdByName: "",
  followUpDate: "",
  assignedTo: "",
  reviewerLoginId: "",
  reviewAgency: "",
  cycleNumber: "",
  workflowItemId: "",
  updatedBy: ""
};

export const advancedSearchDefault: IAdvacnedSearch = {
  userAgency: "",
  advancedSearchCriteria: advancedSearchCriteriaDefault,
  advancedSearchResults: [],
  orgUser: false,
  feinHash: ""
};
export const applicantDetailsDefault: IApplicantDetails = {
  applicantFirstName: "",
  applicantLastName: "",
  isSurrogate: false
};
export const applicationStepperHeaderModelDefault: IApplicationStepperHeaderModel = {
  programType: "",
  index: 0,
  isCurrentStep: false,
  description: "",
  agencyCode: "",
  fromPrgm: "",
  fromAgency: "",
  fromIndex: 0
};

export const applicantEcolaneProfileModelDefault: IApplicantEcolaneProfileModel = {
  applicantProfileId: 0,
  transitAgencyCode: "",
  ecolaneCustomerId: "",
  auditFields: databaseAuditFields,
  directLink: ""
};

export const tesEcolaneAddress: ITESEcolaneAddress = {
  street: "",
  streetNumber: "",
  apartmentNumber: "",
  city: "",
  county: "",
  state: "",
  postcode: ""
};

export const tesEcolaneModelDefault: ITESEcolaneModel = {
  yearOfBirth: 0,
  monthOfBirth: 0,
  dayOfBirth: 0,
  customerNumber: "",
  firstName: "",
  lastName: "",
  middleInitial: "",
  dateOfBirth: "", //LocalDate
  applicantGender: "",
  phoneLandline: "",
  phoneMobile: "",
  email: "",
  emergencyContactName: "",
  emergencyContactAddress: "",
  emergencyContactPhoneDay: "",
  emergencyContactPhoneNight: "",
  emergencyRelationship: "",

  disabilityMental: false,
  disabilityPhysical: false,
  disabilityVisual: false,
  defaultDevice: "",
  ethnicity: "",
  languageCode: "",
  gender: "",
  socialSecurityNumber: "",
  medicaidNumber: "",
  county: "",
  directLinkLegacy: "",
  directLink: "",
  homeAddress: tesEcolaneAddress,
  mailingAddress: tesEcolaneAddress
};

export const reviewResultReasonListDefault: IReviewResultReasonList = {
  reasons: []
};

export const otherReviewResultReasonModelDefault: IOtherReviewResultReasonList = {
  otherReasons: [
    {
      reviewResultReasonId: 0,
      resultReason: "",
      reviewResultCode: "OTHER",
      otherResultInd: "Y"
    }
  ]
};

export const applicationReviewModelDefault: IApplicationReviewModel = {
  applicationReviewId: 0,
  tesApplicationId: 0,
  cycleNumber: 0,
  ecolaneDeeplink: "",
  reviewResultCode: "",
  reviewComment: "",
  internalReviewNote: "",
  ecolaneConfiguredByCode: "",
  ecolaneConfiguredInd: "",
  ecolaneConfiguredBy: "",
  ecolaneConfiguredDate: "",
  ecolaneMatchInd: "",
  reviewerUserId: 0,
  statusCode: "",
  reasons: [] as IReviewResultReasonModel[],
  otherReasons: [],
  actReviewFlag: "",
  reviewDate: "",
  completeReviewInd: "",
  transitAgencyCode: "",
  ecolaneRecordCreatedFlag: false,
  ecolaneRecordPresentFlag: false,
  completeReviewFlag: false,
  notificationSentFlag: false,
  programInfo: applicantProgramDefault,
  applicantProfile: applicantProfileDefault,

  tempApprovalEndDate: "",
  followUpDate: "",
  updatedTs: ""
};

export const createEcolaneRequestModelDefault: ICreateEcolaneRequestModel = {
  customerNumber: "",
  firstName: "",
  lastName: "",
  middleInitial: "",
  dateOfBirth: "",
  phoneLandline: "",
  phoneMobile: "",
  email: "",
  emergencyContactName: "",
  emergencyContactAddress: "",
  emergencyContactPhoneDay: "",
  emergencyContactPhoneNight: "",
  disabilityMental: false,
  disabilityPhysical: false,
  disabilityVisual: false,
  mailingAddress: tesEcolaneAddress,
  homeAddress: tesEcolaneAddress,
  ethnicity: "",
  languageCode: "",
  gender: "",
  socialSecurityNumber: "",
  medicaidNumber: "",
  county: ""
};

export const updateEcolaneRequestModelDefault: IUpdateEcolaneRequestModel = {
  customerNumber: "",
  firstName: "",
  lastName: "",
  middleInitial: "",
  dateOfBirth: "",
  phoneLandline: "",
  phoneMobile: "",
  email: "",
  emergencyContactName: "",
  emergencyContactAddress: "",
  emergencyContactPhoneDay: "",
  emergencyContactPhoneNight: "",
  disabilityMental: false,
  disabilityPhysical: false,
  disabilityVisual: false,
  mailingAddress: tesEcolaneAddress,
  homeAddress: tesEcolaneAddress,
  ethnicity: "",
  languageCode: "",
  gender: "",
  socialSecurityNumber: "",
  medicaidNumber: "",
  county: ""
};

export const createEcolaneRequestWrapperDefault: ICreateEcolaneRequestWrapperModel = {
  tesEcolaneCreateReqModel: createEcolaneRequestModelDefault,
  agencyCode: "",
  countyCode: ""
};

export const updateEcolaneRequestWrapperDefault: IUpdateEcolaneRequestWrapperModel = {
  tesEcolaneUpdateReqModel: updateEcolaneRequestModelDefault,
  agencyCode: "",
  countyCode: ""
};

export const tesEcolaneRequestWrapperDefault: ITESEcolaneRequestWrapperModel = {
  tesEcolaneRequest: tesEcolaneModelDefault,
  agencyCode: "",
  countyCode: ""
};

export const uiFeatureDefaultModel: IUIFeatureObjectModel = {
  largeText: "",
  displayName: "",
  resourceCode: "",
  resourceDescription: "",
  childFeatures: [] as string[],
  children: [] as IUIFeatureObjectModel[]
};

export const attachmentCheckBoxSelectModel: IAttachmentCheckBoxSelectModel = {
  userId: 0,
  userName: "",
  applicantProfileId: 0,
  version: 0,
  documentTypeCode: "",
  description: "",
  status: "",
  filename: "",
  mimeType: "",
  parentAttachmentId: 0,
  applicationId: 0,
  cycleNumber: 0,
  noUploadInd: "",
  attachmentId: 0
};

export const stgEcolaneCustProfileDefault: IStgEcolaneCustProfileModel = {
  stgEcolaneCustProfileId: 0,
  applicationReviewId: 0,
  customerNumber: "",
  customerFirstName: "",
  customerLastName: "",
  customerMiddleInitial: "",
  customerDOB: "",
  landlinePhone: "",
  mobilePhone: "",
  emailAddress: "",
  emergencyContactName: "",
  emergencyContactAddress: "",
  emergencyContactPhoneDay: "",
  emergencyContactPhoneNight: "",
  disabilityMentalInd: "",
  disabilityPhysicalInd: "",
  disabilityVisualInd: "",
  defaultDevice: "",
  mailAddress: applicantAddress,
  mailAddressStreet: "",
  mailAddressStreetNum: "",
  mailAddressAptNum: "",
  mailAddressCity: "",
  mailAddressState: "",
  mailAddressZip: "",
  mailAddressCounty: "",
  homeAddress: applicantAddress,
  homeAddressStreet: "",
  homeAddressStreetNum: "",
  homeAddressAptNum: "",
  homeAddressCity: "",
  homeAddressState: "",
  homeAddressZip: "",
  homeAddressCounty: "",
  ethnicity: "",
  language: "",
  gender: "",
  socialSecurityNumber: "",
  medicaidNumber: "",
  county: "",
  directLinkLegacy: "",
  directLink: "",
  createdBy: "",
  createdTs: "",
  updatedBy: "",
  updatedTs: ""
};

export const ecolaneDataUpdateDefault: IEcolaneDataUpdate = {
  ecolaneDataUpdateId: 0,
  applicationReviewId: 0,
  ecolaneDataType: "",
  ecolaneDataValue: "",
  fmrDataValue: "",
  ecolaneUpdateInd: ""
};

export const postingStgModelDefault: IPostingStgModel = {
  ecolaneDataUpdateModels: [ecolaneDataUpdateDefault],
  prevEcolaneDataUpdateModels: [ecolaneDataUpdateDefault]
};

export const physicianDetailsDefault: IDraftPhysicianInfo = {
  draftPhysicianInfoId: 0,
  tesApplicationId: 0,
  cycleNumber: 0,
  applicantProfileId: 0,
  physicianFirstName: "",
  physicianLastName: "",
  professionalType: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "PA",
  zip: "",
  zipExtension: "",
  physicianEmailAddress: "",
  physicianPhone: "",
  physicianPhoneExt: "",
  physicianFax: ""
};

export const assignUserDefault: IAssignUserModel = {
  appSumObj: advancedSearchResultDefault,
  selectedUser: tesUserDefault,
  assigningUser: tesUserDefault
};

export const gisDataModelDefault: IGISDataModel = {
  gisAgencyNames: [],
  countyList: []
};
