import { IInvoiceData } from "@/types";

const invoiceDefault: IInvoiceData = {
  accountNumber: "",
  referenceNumber: "",
  documentNumber: "",
  baselinePaymentDate: "",
  assignment: "",
  documentType: "",
  businessArea: "",
  amountDue: 0,
  dunningBlock: "",
  dunningArea: ""
};

export default invoiceDefault;
