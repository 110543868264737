
import Vue from "vue";
import store from "@/store";
import ProgressBar from "@/components/common/ProgressBar.vue";
import routerMixin from "@/mixins/RouterMixin.vue";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";

import { IProgramsList } from "@/types";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
export default Vue.extend({
  name: "Withdraw-Application",
  mixins: [routerMixin, AppPropsMixin, ApplicationMixin, UIFeatureMixinVue],
  components: {
    ProgressBar
  },
  data() {
    return {
      showProgressBar: false,
      viewWithdrawDailog: false
    };
  },
  computed: {
    applicantProgramInfo: {
      get(): IProgramsList {
        //@ts-ignore
        return store.getters.applicantProgramModule.getApplicantProgram;
      }
    }
  },
  methods: {
    withdrawApplicationDailog() {
      this.viewWithdrawDailog = true;
    },
    closeViewWithdrawDailog() {
      this.viewWithdrawDailog = false;
    },
    withdrawApp() {
      this.showProgressBar = true;
      this.applicantProgramInfo.statusCode = "WITHDRAWN";
      //@ts-ignore
      this.withdrawApplication(this.applicantProgramInfo)
        //@ts-ignore
        .then(response => {
          //@ts-ignore
          store.commit.applicantProgramModule.SET_APPLICANT_PROGRAM(
            response.data
          );
        })
        //@ts-ignore
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
          //@ts-ignore
          this.forwardToDashboard();
        });
    }
  }
});
