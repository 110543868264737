
import Vue from "vue";
import {
  IScrAnswers,
  IScreeningQuestions,
  ITESUser,
  IScrnQuesRequest,
  IScreeningResponse,
  IGISDataModel
} from "@/types";
import axios from "axios";
import { TES_APP_ENDPOINT, GENERAl_INFO_ENDPOINT } from "@/constants/EndPoints";
import { HTTP_STATUS } from "@/constants/HttpStatusCode";
import store from "@/store";
import router from "@/router";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import ScreeningResponseMixin from "@/mixins/ScreeningResponseMixin.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import DateInput from "@/components/common/DateInput.vue";
import tesControlTemplate from "@/components/common/TESControlTemplate.vue";
import AccessibilityMixin from "@/mixins/AccessibilityMixin.vue";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";
import ExpansionPanelTemplate from "@/components/common/ExpansionPanelTemplate.vue";
import {
  PROGRAM_NAMES,
  CNST_TEXT,
  expansionPanelIconObj
} from "@/constants/CommonConstants";
import { GPC } from "@/constants/GPCCode";
import ProgressBar from "@/components/common/ProgressBar.vue";
import TESRadioControl from "@/components/common/TESRadioControl.vue";
import ScrollMixin from "@/mixins/ScrollMixin.vue";
import helper from "@/composables/helper";

export default Vue.extend({
  mixins: [
    fieldEntryMixin,
    AppPropsMixin,
    ScreeningResponseMixin,
    UIFeatureMixinVue,
    AccessibilityMixin,
    ApplicationMixin,
    ScrollMixin
  ],
  components: {
    DateInput,
    tesControlTemplate,
    ExpansionPanelTemplate,
    ProgressBar,
    TESRadioControl
  },
  data() {
    return {
      stateCollege: ["Centre"],
      quesIndex: 1,
      scrnQuesRequest: {} as IScrnQuesRequest,
      loadingQuestion: true,
      dialog: true,
      gisCounties: [] as string[],
      gisDataModel: {} as IGISDataModel,
      gisCounty: false,
      confirmedCounty: "",
      expPanelIconObj: expansionPanelIconObj,
      showProgressBar: false,
      disableNextBtn: false
    };
  },
  computed: {
    scrAnswers: {
      get(): IScrAnswers {
        return store.getters.screenQuesModule.getScrAnswers;
      },
      set(scrAnswers: IScrAnswers) {
        store.commit.screenQuesModule.SET_SCRANSWERS(this.scrAnswers);
      }
    },
    questions: {
      get(): IScreeningQuestions[] {
        return store.getters.screenQuesModule.getQuestions;
      },
      set(newValue: IScreeningQuestions[]) {
        store.commit.screenQuesModule.SET_QUESTIONS(newValue);
      }
    },
    stepIndex: {
      get(): number {
        return store.getters.screenQuesModule.getStepInd;
      },
      set(newValue: number) {
        store.commit.screenQuesModule.SET_STEPIND(newValue);
      }
    },
    prgmsList(): [] {
      return store.getters.screenQuesModule.getPrgmsList;
    },
    fixedRouteCounties(): [] {
      return store.getters.dataModule.getfixedRouteCounties;
    },
    applicantDetails(): any {
      return store.getters.applicantDetailsModule.getApplicantDetails;
    },
    userInfo: {
      get(): ITESUser {
        return store.getters.tesUserModule.getTesUser;
      }
    },
    screeningResponse: {
      get(): IScreeningResponse {
        return store.getters.screeningResponseModule.getScreeningResponse;
      }
    },
    gisCountyConfirmDialogTitle(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser &&
          (this.screeningResponse.onBehalfOfInd === "Y" ||
            this.applicantDetails.isSurrogate))
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.GIS_CNFM_1].largeText,
          CNST_TEXT.THE_APPLICANTS
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.GIS_CNFM_1].largeText,
          CNST_TEXT.YOUR
        );
      }
      return text;
    },
    gisCountyConfirmDialogText(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser &&
          (this.screeningResponse.onBehalfOfInd === "Y" ||
            this.applicantDetails.isSurrogate))
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.GIS_CNFM_2].largeText,
          CNST_TEXT.THE_APPLICANT_RESIDES
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.GIS_CNFM_2].largeText,
          CNST_TEXT.YOU_RESIDE
        );
      }
      return text;
    }
  },
  created() {
    if (!this.userInfo.userId) {
      store.dispatch.tesUserModule.getUserDetails();
    }
    this.scrAnswers.homeAddress.state = "PA";
    if (this.fixedRouteCounties.length === 0) {
      store.dispatch.dataModule.getFixedRouteCounties();
    }

    if (this.stepIndex === 0) {
      let url = TES_APP_ENDPOINT.GET_SCREENING_QUES;
      this.getQuesAndAnswers(url);
      store.commit.screenQuesModule.SET_STEPIND(1);
    } else {
      this.loadingQuestion = false;
      this.dialog = false;
    }
    if (
      !this.screeningResponse.applicantDob &&
      this.scrAnswers.answers.length > 0
    ) {
      this.scrAnswers.answers.length = 0;
      this.scrAnswers.selCounty.length = 0;
      this.scrAnswers.selDisability = "";
      this.scrAnswers.ageGroup = "";
      this.scrAnswers.homeAddress.addressLine1 = "";
      this.scrAnswers.homeAddress.addressLine2 = "";
      this.scrAnswers.homeAddress.city = "";
      this.scrAnswers.homeAddress.state = "PA";
      this.scrAnswers.homeAddress.zip = "";
      this.scrAnswers.showVTPQues = false;
      this.scrAnswers.showRFTPQues = false;
    }
    if (this.screeningResponse.applicantDob && !this.scrAnswers.ageGroup) {
      this.setscrAnswersAgeGroup();
    }
  },
  updated() {
    if (this.$refs.scrQuestionCountyRef) {
      //@ts-ignore
      this.setAriaAttributesOfAutoComplete(
        this.$refs.scrQuestionCountyRef,
        "scr_ques_select_county_id"
      );
    }
    if (this.$refs.formScrnQuestion) {
      //@ts-ignore
      this.setAriaAttributeForSelectInForm(
        this.$refs.formScrnQuestion,
        "State",
        "scr_ques_state_id"
      );
    }
  },
  methods: {
    async getQuesAndAnswers(url: string) {
      this.loadingQuestion = true;
      this.dialog = true;
      await axios
        .post(url, this.scrnQuesRequest)
        .then(response => {
          if (response.status === HTTP_STATUS.OK) {
            store.commit.screenQuesModule.SET_QUESTIONS(response.data);
          }
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.loadingQuestion = false;
          this.dialog = false;
        });
    },
    async getNextQuestion(questionNum: number) {
      let adrsValid = true;
      if (questionNum === 1) {
        adrsValid = this.validateAddress();
        if (!adrsValid)
          //@ts-ignore
          this.scrollToErrorField(this.$refs.formScrnQuestion);
        if (
          adrsValid &&
          this.scrAnswers.answers[1] &&
          (!this.confirmedCounty ||
            this.confirmedCounty != this.scrAnswers.answers[1])
        ) {
          this.disableNextBtn = true;
          this.showProgressBar = true;
          await this.getGISCounties();
        }
      }
      if (!this.gisCounty) {
        this.getNextQuestValidation(questionNum, adrsValid);
      }
    },
    getNextQuestValidation(questionNum: number, adrsValid: boolean) {
      let url = TES_APP_ENDPOINT.GET_SCREENING_QUES;
      if (adrsValid === false || !this.scrAnswers.answers[questionNum]) {
        this.validate();
      } else if (
        this.isSelectedCountyDiff() ||
        (this.questions[this.stepIndex - 1].lineItemid == "SQ3" &&
          this.isAgeGroupChanged())
      ) {
        this.generateQuesForSelect(url, questionNum);
        if (
          this.questions[this.stepIndex].lineItemid == "SQ3" &&
          !this.applicantDetails.isSurrogate &&
          this.userInfo &&
          this.userInfo.userDob &&
          !this.scrAnswers.answers[questionNum + 1]
        ) {
          this.scrAnswers.answers[questionNum + 1] = this.userInfo.userDob;
        }
      } else if (this.questions[Number(questionNum) - 1].lineItemid == "SQ5") {
        this.fixedRouteQuestion(url, questionNum);
      } else {
        this.resetValidation();
        this.incrementStepIndexByOne();
      }
    },
    confirmCounty() {
      this.scrAnswers.answers[1] = this.confirmedCounty;
      this.gisCounty = false;
    },
    getPreviousQuestion() {
      store.commit.screenQuesModule.SET_STEPIND(this.stepIndex - 1);
    },
    getScrQuestionsResults(questionNum: number) {
      if (
        this.isAgeGroupChanged() ||
        (this.hasRFTP() &&
          this.questions[Number(questionNum) - 1].lineItemid == "SQ5" &&
          this.scrAnswers.answers[Number(questionNum)] == "No" &&
          //@ts-ignore
          this.scrAnswers.ageGroup != this.uiFeature.ABOVE_65.displayName)
      ) {
        this.getNextQuestion(questionNum);
      } else if (!this.scrAnswers.answers[questionNum]) {
        this.validate();
      } else {
        this.processStartApplication(questionNum);
      }
    },
    async getGISCounties() {
      let url = GENERAl_INFO_ENDPOINT.GET_GIS_COUNTIES;
      await axios
        .post(url, this.scrAnswers.homeAddress)
        .then(response => {
          this.gisDataModel = response.data;
          this.gisCounties = this.gisDataModel.countyList;
          if (
            this.gisCounties &&
            this.gisCounties.length > 0 &&
            !this.gisCounties.includes(this.scrAnswers.answers[1])
          ) {
            this.confirmedCounty = "";
            this.gisCounty = true;
          }
          this.scrAnswers.applySEPTACounty =
            this.gisDataModel.gisAgencyNames.includes("SEPTA") ||
            this.scrAnswers.answers[1] === "Philadelphia";
        })
        .catch(error => {
          this.dialog = false;
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
          this.disableNextBtn = false;
        });
      return this.gisCounty;
    },
    processStartApplication(questionNum: number) {
      this.dialog = true;
      let scrResponseMixRef: any = this;

      if (this.questions[Number(questionNum - 1)].lineItemid == "SQ5") {
        this.scrAnswers.selDisability = this.scrAnswers.answers[
          Number(questionNum)
        ];
      }
      let scrResponses = scrResponseMixRef.buildScreeningResponseEntity(
        this.questions,
        this.scrAnswers.answers
      );
      scrResponses.addressLine1 = this.scrAnswers.homeAddress.addressLine1;
      scrResponses.addressLine2 = this.scrAnswers.homeAddress.addressLine2;
      scrResponses.city = this.scrAnswers.homeAddress.city;
      scrResponses.state = this.scrAnswers.homeAddress.state;
      scrResponses.zip = this.scrAnswers.homeAddress.zip;
      if (
        this.scrAnswers.ageGroup == "65" &&
        !this.scrAnswers.applySEPTACounty
      ) {
        scrResponses.transportationDisabilityInd = "No";
      }
      let userId = scrResponseMixRef.userInfo.userId;
      let url;
      if (userId && userId > 0) {
        url = TES_APP_ENDPOINT.START_APPLICATION;
      } else {
        url = TES_APP_ENDPOINT.START_APPL_BEFORE_LOGIN;
      }

      //@ts-ignore
      let screeningRespId =
        store.getters.screeningResponseModule.getScreeningResponse
          .screeningResponseId;
      if (screeningRespId) {
        scrResponses.screeningResponseId = screeningRespId;
        if (
          !this.applicantDetails ||
          !this.applicantDetails.applicantFirstName
        ) {
          scrResponses.applicantFirstName = this.screeningResponse.applicantFirstName;
          scrResponses.applicantLastName = this.screeningResponse.applicantLastName;
          scrResponses.onBehalfOfInd = this.screeningResponse.onBehalfOfInd;
        }
      }

      axios
        .post(url, scrResponses)
        .then(response => {
          store.commit.screeningResponseModule.SET_SCREENING_RESPONSE(
            response.data
          );
          router.push({
            name: "screeningQuestionsResults",
            query: {
              scrnRespID: String(response.data.screeningResponseId)
            }
          });
        })
        .catch(error => {
          this.dialog = false;
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        });
    },
    isStateCollegeCounty(countySelected: string) {
      return this.stateCollege.indexOf(countySelected) > -this.quesIndex;
    },
    isFixedRouteCounty(countySelected: string) {
      let fxdCntyValues = this.fixedRouteCounties;
      for (let i in fxdCntyValues) {
        if (fxdCntyValues[i]["countyName"] === countySelected) {
          return true;
        }
      }
    },
    incrementStepIndexByOne() {
      store.commit.screenQuesModule.SET_STEPIND(this.stepIndex + 1);
    },
    validate() {
      //@ts-ignore
      this.$refs.formScrnQuestion.validate();
    },
    validateAddress() {
      let adrsValid = true;
      const pattern = /^[a-zA-Z0-9]+\s[a-zA-Z0-9]+[\sa-zA-Z0-9.]+/;
      const addressEmpty =
        !this.scrAnswers.homeAddress.addressLine1 ||
        !this.scrAnswers.homeAddress.city ||
        !this.scrAnswers.homeAddress.state ||
        !this.scrAnswers.homeAddress.zip;
      const invalidStAddr = !pattern.test(
        this.scrAnswers.homeAddress.addressLine1
      );
      const invalidZip =
        this.scrAnswers.homeAddress.zip.length !== 10 &&
        this.scrAnswers.homeAddress.zip.length !== 5;
      if (addressEmpty || invalidStAddr || invalidZip) {
        this.validate();
        adrsValid = false;
      }
      return adrsValid;
    },
    resetValidation() {
      //@ts-ignore
      this.$refs.formScrnQuestion.resetValidation();
    },
    isSelectedCountyDiff() {
      if (
        this.scrAnswers.selCounty[this.quesIndex] !=
        this.scrAnswers.answers[this.quesIndex]
      ) {
        return true;
      }
    },
    isAgeGroupChanged() {
      let scrResponseMixRef: any = this;
      if (
        this.isStateCollegeCounty(this.scrAnswers.selCounty[this.quesIndex])
      ) {
        if (
          this.scrAnswers.ageGroup !=
          scrResponseMixRef.getAgeGroup(
            this.scrAnswers.answers[this.quesIndex + 2]
          )
        ) {
          return true;
        }
      } else if (
        this.scrAnswers.ageGroup !=
        scrResponseMixRef.getAgeGroup(
          this.scrAnswers.answers[this.quesIndex + 1]
        )
      ) {
        return true;
      }
    },
    setscrAnswersAgeGroup() {
      let scrResponseMixRef: any = this;
      if (
        this.isStateCollegeCounty(this.scrAnswers.selCounty[this.quesIndex])
      ) {
        this.scrAnswers.ageGroup = scrResponseMixRef.getAgeGroup(
          this.scrAnswers.answers[this.quesIndex + 2]
        );
      } else {
        this.scrAnswers.ageGroup = scrResponseMixRef.getAgeGroup(
          this.scrAnswers.answers[this.quesIndex + 1]
        );
      }
    },
    async removeAllSelections() {
      this.scrAnswers.selCounty[this.quesIndex] = this.scrAnswers.answers[
        this.quesIndex
      ];
      if (
        this.questions[this.stepIndex - 1].lineItemid == "SQ3" &&
        this.isAgeGroupChanged()
      ) {
        let scrResponseMixRef: any = this;
        if (
          this.isStateCollegeCounty(this.scrAnswers.selCounty[this.quesIndex])
        ) {
          this.scrAnswers.ageGroup = scrResponseMixRef.getAgeGroup(
            this.scrAnswers.answers[this.quesIndex + 2]
          );
        } else {
          this.scrAnswers.ageGroup = scrResponseMixRef.getAgeGroup(
            this.scrAnswers.answers[this.quesIndex + 1]
          );
        }
        this.scrnQuesRequest.ageGroup = this.scrAnswers.ageGroup;
        this.scrAnswers.selDisability = "";
        await this.getProgramsByCounty();
      } else {
        for (let question in this.questions) {
          this.scrAnswers.answers[Number(question) + this.quesIndex] = "";
        }
        this.scrAnswers.answers[this.quesIndex] = this.scrAnswers.selCounty[
          this.quesIndex
        ];
        this.scrAnswers.selDisability = "";
        this.scrAnswers.ageGroup = "";
        this.scrnQuesRequest.ageGroup = "";
        this.scrAnswers.showVTPQues = false;
        this.scrAnswers.showRFTPQues = false;
      }
    },
    isStateCollegeAndFxdRouteCounty() {
      return (
        this.isStateCollegeCounty(this.scrAnswers.selCounty[this.quesIndex]) &&
        this.isFixedRouteCounty(this.scrAnswers.selCounty[this.quesIndex])
      );
    },
    buildScrnQuesRequest(mostQuestionsInd: boolean) {
      let scrResponseMixRef: any = this;
      this.scrnQuesRequest.mostQuestions = mostQuestionsInd;
      this.scrnQuesRequest.applySEPTACounty = this.scrAnswers.applySEPTACounty;
      this.scrnQuesRequest.countyCode = scrResponseMixRef.getCodeForValue(
        this.scrAnswers.selCounty[this.quesIndex],
        this.questions,
        this.quesIndex
      );
    },
    isDisabilityAndFixedRouteCounty(quesNum: number) {
      return (
        this.questions[Number(quesNum) - 1].disability &&
        this.isFixedRouteCounty(this.scrAnswers.selCounty[this.quesIndex])
      );
    },
    isSelectedDisabilityDiff(quesNum: number) {
      return (
        this.scrAnswers.selDisability !=
        this.scrAnswers.answers[Number(quesNum)]
      );
    },
    removeAllProviderAnswerSelections() {
      for (let providerQues in this.questions) {
        if (this.questions[Number(providerQues)].provider) {
          this.scrAnswers.answers[Number(providerQues) + this.quesIndex] = "";
        }
      }
    },
    async generateQuesForSelect(url: string, questionNum: number) {
      await this.removeAllSelections();
      if (this.isStateCollegeAndFxdRouteCounty()) {
        this.buildScrnQuesRequest(true);
      } else if (
        this.isStateCollegeCounty(this.scrAnswers.selCounty[this.quesIndex])
      ) {
        this.scrnQuesRequest.stateCollege = true;
      } else if (
        this.isFixedRouteCounty(this.scrAnswers.selCounty[this.quesIndex])
      ) {
        this.buildScrnQuesRequest(false);
      } else {
        this.buildScrnQuesRequest(false);
      }
      await this.getQuesAndAnswers(url);
      const result = this.questions.find(
        ({ lineItemid }) => lineItemid === "SQ4"
      );
      if (
        this.scrAnswers.ageGroup == "65" &&
        !result &&
        !this.scrAnswers.applySEPTACounty
      ) {
        this.getScrQuestionsResults(questionNum);
      } else {
        this.incrementStepIndexByOne();
      }
    },
    fixedRouteQuestion(url: string, questionNum: number) {
      this.scrnQuesRequest.showVTPQues = this.scrAnswers.showVTPQues;
      this.scrnQuesRequest.showRFTPQues = this.scrAnswers.showRFTPQues;
      if (this.isDisabilityAndFixedRouteCounty(questionNum)) {
        if (this.isSelectedDisabilityDiff(questionNum)) {
          this.processDisabilityDiff(questionNum, url);
        }
      }
      this.resetValidation();
      this.incrementStepIndexByOne();
    },
    processDisabilityDiff(questionNum: number, url: string) {
      this.scrAnswers.selDisability = this.scrAnswers.answers[
        Number(questionNum)
      ];
      this.removeAllProviderAnswerSelections();
      if (
        this.isStateCollegeCounty(this.scrAnswers.selCounty[this.quesIndex]) &&
        this.isFixedRouteCounty(this.scrAnswers.selCounty[this.quesIndex])
      ) {
        this.scrnQuesRequest.mostQuestions = true;
      }
      if (this.scrAnswers.answers[Number(questionNum)] == "Yes") {
        this.scrnQuesRequest.disability = true;
        this.scrnQuesRequest.showRFTPQues = false;
        this.scrAnswers.showRFTPQues = false;
      } else {
        this.scrnQuesRequest.disability = false;
        this.setRFTPQuestParam();
      }
      let scrResponseMixRef: any = this;
      this.scrnQuesRequest.applySEPTACounty = this.scrAnswers.applySEPTACounty;
      this.scrnQuesRequest.countyCode = scrResponseMixRef.getCodeForValue(
        this.scrAnswers.selCounty[this.quesIndex],
        this.questions,
        this.quesIndex
      );
      this.processVTPQues(questionNum, url);
    },
    processVTPQues(questionNum: number, url: string) {
      if (
        this.hasRFTP() &&
        !this.hasVTP() &&
        this.scrAnswers.answers[Number(questionNum)] == "Yes" &&
        //@ts-ignore
        this.scrAnswers.ageGroup != this.uiFeature.ABOVE_65.displayName
      ) {
        let index = this.questions.indexOf(
          //@ts-ignore
          this.questions.find(obj => obj.lineItemid === "SQ8")
        );
        if (index != -1) {
          this.questions.splice(index, 1);
          this.scrAnswers.answers.splice(index + 1, 1);
        }
        this.getScrQuestionsResults(questionNum);
      } else {
        this.getQuesAndAnswers(url);
      }
    },
    async getProgramsByCounty() {
      let url = TES_APP_ENDPOINT.GET_SCREENING_QUES_PRGMS_BY_CNTY;
      //@ts-ignore
      this.scrnQuesRequest.countyCode = this.getCodeForValue(
        this.scrAnswers.answers[this.quesIndex],
        this.questions,
        this.quesIndex
      );
      await axios
        .post(url, this.scrnQuesRequest)
        .then(response => {
          if (response.status === HTTP_STATUS.OK) {
            store.commit.screenQuesModule.SET_PRGMS_LIST(response.data);
            this.setVTPQuestParam();
          }
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        });
    },
    setVTPQuestParam() {
      if (
        this.hasVTP() &&
        //@ts-ignore
        this.scrAnswers.ageGroup != this.uiFeature.UNDER_18.displayName
      ) {
        this.scrnQuesRequest.showVTPQues = true;
        this.scrAnswers.showVTPQues = true;
      } else {
        this.scrnQuesRequest.showVTPQues = false;
        this.scrAnswers.showVTPQues = false;
      }
    },
    hasVTP(): any {
      return this.prgmsList.find(o => o === PROGRAM_NAMES.VTP);
    },
    hasRFTP(): any {
      return this.prgmsList.find(o => o === PROGRAM_NAMES.RFTP);
    },
    setRFTPQuestParam(): boolean {
      if (
        this.hasRFTP() &&
        //@ts-ignore
        this.scrAnswers.ageGroup != this.uiFeature.ABOVE_65.displayName
      ) {
        this.scrnQuesRequest.showRFTPQues = true;
        this.scrAnswers.showRFTPQues = true;
      } else {
        this.scrnQuesRequest.showRFTPQues = false;
        this.scrAnswers.showRFTPQues = false;
      }
      return this.scrnQuesRequest.showRFTPQues;
    },
    matpSubQuesDisplTxt() {
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser &&
          (this.screeningResponse.onBehalfOfInd === "Y" ||
            this.applicantDetails.isSurrogate))
      ) {
        //@ts-ignore
        this.uiFeature["MATP_SCR_Q"].displayName = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["MATP_SCR_Q"].displayName,
          CNST_TEXT.THE_APPLICANT_HAS
        );
        //@ts-ignore
        this.uiFeature["MATP_SCR_Q"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["MATP_SCR_Q"].largeText,
          CNST_TEXT.THE_APPLICANT_NEEDS,
          CNST_TEXT.THEIR,
          CNST_TEXT.TTHE_APPLICANT,
          CNST_TEXT.THEIR,
          CNST_TEXT.THEY,
          CNST_TEXT.THEY
        );
        //@ts-ignore
      } else {
        //@ts-ignore
        this.uiFeature["MATP_SCR_Q"].displayName = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["MATP_SCR_Q"].displayName,
          CNST_TEXT.I_HAVE
        );
        //@ts-ignore
        this.uiFeature["MATP_SCR_Q"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["MATP_SCR_Q"].largeText,
          CNST_TEXT.YOU_HAVE,
          CNST_TEXT.YOUR,
          CNST_TEXT.YYOU,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOU,
          CNST_TEXT.YOU
        );
      }
    }
  },
  watch: {
    questions: {
      deep: true,
      handler(newArr: IScreeningQuestions[]) {
        if (newArr.length > 0) {
          //@ts-ignore
          this.questionsByContext();
        }
      }
    },
    uiFeature: {
      deep: true,
      handler(obj: Object) {
        //@ts-ignore
        if (obj["MATP_SCR_Q"]) {
          this.matpSubQuesDisplTxt();
        }
      }
    },
    gisCounty: {
      immediate: true,
      handler: function(newValue) {
        if (newValue) {
          this.$nextTick(() => {
            //@ts-ignore
            this.setAriaAttribute(this.$refs.scrGISDialogRef);
          });
        }
      }
    }
  },
  setup() {
    const { getAriaLabel } = helper();
    return { getAriaLabel };
  }
});
