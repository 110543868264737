
import Vue from "vue";
import screeningquestionsresults from "@/components/questions/ScreeningQuestionsResults.vue";
import ClearStoreValuesMixin from "@/mixins/ClearStoreValuesMixin.vue";
import store from "@/store";
import { IApplicationNavigation, IScreeningQuestions, ITESUser } from "@/types";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";

export default Vue.extend({
  name: "screening-questions-results",
  mixins: [ClearStoreValuesMixin, UIFeatureMixinVue],
  components: {
    screeningquestionsresults
  },
  data() {
    return {
      showComponent: false
    };
  },
  async created() {
    if (!this.userInfo.userId) {
      store.dispatch.tesUserModule.getUserDetails();
    }
    let scrnRespID = this.$route.query.scrnRespID;
    if (this.questions.length === 0) {
      await store.dispatch.screenQuesModule.populateScreeningResults(
        scrnRespID
      );
      await store.dispatch.screeningResponseModule.populateScreenResponse(
        scrnRespID
      );
    }
    this.showComponent = true;
    let uiFeatureArray =
      "ADA,FTP,MATP,PWD,SSR,WHY_PWD,WHY_ADA,WHY_ACADA,WHY_ACPWD,WHY_SSR,WHY_FTP,WHY_MATP,WNOT_SSR,WNOT_PWD,WNOT_ADA,WNOT_MATP,WNOT_FTP,UNDER_18,BTWN_1864,ABOVE_65,SMENC,SMLOCALE,TARGET,ESEC_URL,CTZ_FGTUSR,CTZ_FGTPWD,BP_FGTUSR,BP_FGTPWD,LOGIN_FAIL,ACC_LOCKED,ER_INLINE,CHGLGN_TXT,SHW_LGNSML,CTZURL_QA,BPURL_QA,CTZURL_ROL,NO_ELGPGM,NO_ELGPGM1,FMR,ENVRONMNT,DIS_LGN_BT,BTWN_6064,WHY_AGING,WNOT_AGING,WHY_VTP,WNOT_VTP,WHY_RFTP,WNOT_RFTP,AGING,VTP,RFTP,ABOVE_17,,ADAAC_Y,PWDAC_Y,ADA_N,FTP_Y,FTP_N,MATP_Y,MATP_N,PWD_Y,PWD_N,RFTP_Y,RFTP_N,SSR_Y,SSR_N,AGING_Y,AGING_N,VTP_Y,VTP_N";

    store.dispatch.uiFeatureModule.getUIFeatures(uiFeatureArray);
  },
  computed: {
    applicationNavigation: {
      get(): IApplicationNavigation {
        return store.getters.applicationNavigationModule
          .getApplicationNavigation;
      }
    },
    userInfo: {
      get(): ITESUser {
        return store.getters.tesUserModule.getTesUser;
      }
    },
    questions: {
      get(): IScreeningQuestions[] {
        return store.getters.screenQuesModule.getQuestions;
      }
    }
  },
  beforeDestroy() {
    //@ts-ignore
    this.clearApplicationNavigationModuleFromStore();
  }
});
