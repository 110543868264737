
import Vue from "vue";
import { DASHBOARD_ENDPOINT, TES_APP_ENDPOINT } from "@/constants/EndPoints";
import axios from "axios";
import { HTTP_STATUS } from "@/constants/HttpStatusCode";
import { DASHBOARD_TYPE } from "@/constants/CommonConstants";
import DashboardResult from "@/components/dashboard/DashboardResult.vue";
import store from "@/store";
import {
  IDashboardContent,
  ITESUser,
  IProgramsList,
  IApplicationNavigation,
  IStepperHeaderList
} from "@/types";
import DashboardProfile from "@/components/dashboard/DashboardProfile.vue";
import ProgressBar from "@/components/common/ProgressBar.vue";
import ClearStoreValuesMixin from "@/mixins/ClearStoreValuesMixin.vue";
import routerMixin from "@/mixins/RouterMixin.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";

export default Vue.extend({
  name: "dashboard-template",
  mixins: [
    ClearStoreValuesMixin,
    routerMixin,
    UIFeatureMixinVue,
    AppPropsMixin,
    ApplicationMixin
  ],
  components: {
    DashboardResult,
    DashboardProfile,
    ProgressBar
  },

  data() {
    return {
      dashboardType: "" as string,
      showProfileDashboard: false as boolean,
      showResultDashboard: false as boolean,
      showProgressBar: true,

      tabId: 0 as number,
      tabList: [] as ITESUser[],
      exData: "" as string,

      screeningResponseId: 0 as number,
      tabItem: {} as ITESUser,
      loadingCounter: 0 as number,
      isProfileLoading: false as boolean
    };
  },
  created() {
    this.showProgressBar = true;
    let paramApplicantProfileId = Number(this.$route.query.applicantProfileId);

    let paramScreeningResponseId = Number(
      this.$route.query.screeningResponseId
    );

    //@ts-ignore
    this.clearAllStoreValuesExceptUser();

    this.getUIFeaturesReqForThisPage();

    this.getDashboardContent(
      this.userInfo.userId.toString(),
      paramApplicantProfileId.toString(),
      paramScreeningResponseId.toString()
    );

    this.showProgressBar = false;
  },
  computed: {
    dashboardContent: {
      get(): IDashboardContent {
        //@ts-ignore
        return store.getters.dashboardModule.getDashboardContent;
      },
      set(newValue: IDashboardContent) {
        //@ts-ignore
        store.commit.dashboardModule.SET_DASHBOARD_CONTENT(newValue);
      }
    },
    stepperHeaderModel: {
      get(): IStepperHeaderList {
        //@ts-ignore
        return store.getters.stepperHeaderModule.getStepperHeaderModel;
      },
      set(newValue: IStepperHeaderList) {
        //@ts-ignore
        store.commit.stepperHeaderModule.SET_STEPPER_HEADER_MODEL(newValue);
      }
    },
    userInfo: {
      get(): ITESUser {
        return store.getters.tesUserModule.getTesUser;
      }
    },
    applicantProgramInfo: {
      get(): IProgramsList {
        //@ts-ignore
        return store.getters.applicantProgramModule.getApplicantProgram;
      },
      set(newValue: IProgramsList) {
        //@ts-ignore
        store.commit.applicantProgramModule.SET_APPLICANT_PROGRAM(newValue);
      }
    },
    applicationNavigation: {
      get(): IApplicationNavigation {
        //@ts-ignore
        return store.getters.applicationNavigationModule
          .getApplicationNavigation;
      }
    }
  },
  methods: {
    getUIFeaturesReqForThisPage() {
      let uiFeatureArray =
        "NOT_START,IN_PRGS,RDY_SBMT,NOT_QLFYD,SBMTD,APPROVED,NOT_APPL,RE_COR,IN_PRGS_C,RDY_SBMT_C,WITHDRAWN,PEND_CONF,POD_AGNCYS,C_COD_AGNY,CHGLGN_TXT,DOCS_ADA,DOCS_PWD,DOCS_FTP,DOCS_SSR,DOCS_MATP,INELIGIBLE,INCOMPLETE,QUICK_SRCH,ER_INLINE,UN_RVW,FMR,ENVRONMNT,TMP_APRVD,APEL_INPRG,DOCS_VTP,DOCS_RFTP,PENDING";

      //@ts-ignore
      store.dispatch.uiFeatureModule.getUIFeatures(uiFeatureArray);
    },
    resetScreen() {
      this.showProfileDashboard = false;
      this.showResultDashboard = false;
    },

    setInitialScreen(dashboardContent: IDashboardContent) {
      this.resetScreen();
      switch (this.dashboardContent.dashboardType) {
        case DASHBOARD_TYPE.RESULT:
          this.showResultDashboard = true;
          break;
        case DASHBOARD_TYPE.PROFILE:
          this.showProfileDashboard = true;
          break;
        case DASHBOARD_TYPE.APPLICATION:
          this.showProfileDashboard = true;
          break;
      }
    },

    getDashboardContent(
      paramUserId: string,
      applicantProfileId: string,
      screeningResponseId: string
    ) {
      this.showProgressBar = true;

      //@ts-ignore
      this.clearAllStoreValuesExceptUser();

      //@ts-ignore
      store.dispatch.dashboardModule.resetData();

      this.loadingCounter = 5;
      this.isProfileLoading = true;
      this.populateApplicantProfile(applicantProfileId);

      this.populateScreenResponse(screeningResponseId);

      this.populateProgramElig(applicantProfileId);

      this.populateHeaderSteppers(applicantProfileId);

      this.loadDraftProgramInfo(applicantProfileId);
    },

    populateTabList() {
      if (
        this.dashboardContent.applicantProfile &&
        this.dashboardContent.applicantProfile.applicantProfileId
      ) {
        //@ts-ignore
        this.tabItem.userFirstName = this.dashboardContent.applicantProfile.applicantFirstName;
        //@ts-ignore
        this.tabItem.userLastName = this.dashboardContent.applicantProfile.applicantLastName;
      } else if (
        this.dashboardContent.screeningResponse &&
        this.dashboardContent.screeningResponse.screeningResponseId
      ) {
        //@ts-ignore
        this.tabItem.userFirstName = this.dashboardContent.screeningResponse.applicantFirstName;
        //@ts-ignore
        this.tabItem.userLastName = this.dashboardContent.screeningResponse.applicantLastName;
      }

      this.tabList.push(this.tabItem);
    },

    populateApplicantProfile(applicantProfileId: string) {
      axios
        .get(DASHBOARD_ENDPOINT.GET_APPLICANT_PROFILE, {
          params: {
            applicantProfileId: applicantProfileId
          }
        })
        .then(response => {
          this.dashboardContent.applicantProfile = response.data;
          //@ts-ignore
          store.commit.dashboardModule.SET_DASHBOARD_APPLICANT_PROFILE(
            response.data
          );
          //@ts-ignore
          store.commit.applicantProfileModule.SET_APPLICANT_PROFILE(
            response.data
          );
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.loadingCounter--;
          this.loadingStatusCheck();
        });
    },
    populateProgramElig(applicantProfileId: string) {
      axios
        .get(DASHBOARD_ENDPOINT.GET_APPLICANT_PROFILE_PROGRAM, {
          params: {
            applicantProfileId: applicantProfileId
          }
        })
        .then(response => {
          //@ts-ignore
          store.commit.dashboardModule.SET_DASHBOARD_PROG_ELIG(response.data);
          //@ts-ignore
          store.commit.programEligModule.SET_PROG_ELIG(response.data);
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.loadingCounter--;
          this.loadingStatusCheck();
        });
    },

    populateHeaderSteppers(applicantProfileId: string) {
      let url = DASHBOARD_ENDPOINT.GET_PREELIG_PRGMS;

      axios
        .get(url, {
          params: {
            applicantProfileId: applicantProfileId
          }
        })
        .then(response => {
          if (response.status === HTTP_STATUS.OK) {
            this.stepperHeaderModel.stepperHeaders = response.data;
          }
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.loadingCounter--;
          this.loadingStatusCheck();
        });
    },

    loadDraftProgramInfo(applicantProfileId: string) {
      let url = TES_APP_ENDPOINT.GET_DRAFT_PROGRAM_INFO;
      axios
        .get(url, {
          params: {
            applicantProfileId: applicantProfileId
          }
        })
        .then(response => {
          let draftProgramInfoTemp = response.data;
          if (draftProgramInfoTemp) {
            //@ts-ignore
            this.draftProgramInfo = response.data;
          }
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        //@ts-ignore
        .finally(() => {
          this.loadingCounter--;
          this.loadingStatusCheck();
        });
    },
    populateScreenResponse(screeningResponseId: string) {
      axios
        .get(DASHBOARD_ENDPOINT.GET_SCREENING_RESPONSE_BY_ID, {
          params: {
            screeningResponseId: screeningResponseId
          }
        })
        .then(response => {
          //@ts-ignore
          store.commit.dashboardModule.SET_DASHBOARD_SCREENING_RESPONSE(
            response.data
          );
          //@ts-ignore
          store.commit.screeningResponseModule.SET_SCREENING_RESPONSE(
            response.data
          );
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.loadingCounter--;
          this.loadingStatusCheck();
        });
    },

    loadingStatusCheck() {
      if (this.loadingCounter < 1) {
        this.populateTabList();
        this.isProfileLoading = false;
        this.setDashboardType();
      }
    },

    setDashboardType() {
      this.resetScreen();
      this.dashboardContent.dashboardType = "";
      if (this.checkProgramsLength()) {
        //To display continue to dashboard and steppers
        //After programs are submitted in single flow, preeligible programs would be empty
        if (
          this.dashboardContent.programEligModel.preEligiblePrograms &&
          this.dashboardContent.programEligModel.preEligiblePrograms.length != 0
        ) {
          this.dashboardContent.dashboardType = DASHBOARD_TYPE.CONTINUE;
        } else {
          this.dashboardContent.dashboardType = DASHBOARD_TYPE.APPLICATION;
        }
        this.showProfileDashboard = true;
      } else if (
        this.dashboardContent.applicantProfile &&
        this.dashboardContent.applicantProfile.applicantProfileId
      ) {
        this.showProfileDashboard = true;
        this.dashboardContent.dashboardType = DASHBOARD_TYPE.PROFILE;
      } else if (
        this.dashboardContent.screeningResponse &&
        this.dashboardContent.screeningResponse.screeningResponseId
      ) {
        this.showResultDashboard = true;
      }
    },
    checkProgramsLength() {
      return (
        this.dashboardContent.programEligModel &&
        ((this.dashboardContent.programEligModel.eligiblePrograms &&
          this.dashboardContent.programEligModel.eligiblePrograms.length !=
            0) ||
          (this.dashboardContent.programEligModel.preEligiblePrograms &&
            this.dashboardContent.programEligModel.preEligiblePrograms.length !=
              0) ||
          (this.dashboardContent.programEligModel.notEligiblePrograms &&
            this.dashboardContent.programEligModel.notEligiblePrograms.length !=
              0) ||
          (this.dashboardContent.programEligModel.submittedPrograms &&
            this.dashboardContent.programEligModel.submittedPrograms.length !=
              0))
      );
    }
  },
  beforeDestroy() {
    //@ts-ignore
    this.clearApplicationNavigationModuleFromStore();
  }
});
