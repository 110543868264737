
import Vue from "vue";
import { ITESUser, IScreeningResponse, IApplicantDetails } from "@/types";
import store from "@/store";
import router from "@/router";
import tesControlTemplate from "@/components/common/TESControlTemplate.vue";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import routerMixin from "@/mixins/RouterMixin.vue";
import AccessibilityMixin from "@/mixins/AccessibilityMixin.vue";
import { DASHBOARD_ENDPOINT, TES_APP_ENDPOINT } from "@/constants/EndPoints";
import { clone } from "@/util/clone";
import { screeningResponseDefault } from "@/data/AppData";
import axios from "axios";
import { HTTP_STATUS } from "@/constants/HttpStatusCode";
import QuestionnaireDialog from "@/components/dashboard/OrgQuestionnaireDialog.vue";

export default Vue.extend({
  name: "surrogate-dialog",
  mixins: [fieldEntryMixin, routerMixin, AccessibilityMixin],
  components: {
    tesControlTemplate,
    QuestionnaireDialog
  },
  data: function() {
    return {
      tab: null,
      tabSomeOne: null,
      items: [
        { text: "For Myself", index: 0 },
        { text: "For Someone Else", index: 1 }
      ],
      itemsSomeOne: [{ text: "For Someone Else", index: 0 }],
      displayQuestDialog: false,
      firstNameAriaLabel: "First name is required",
      lastNameAriaLabel: "Last name is required"
    };
  },
  props: {
    showSurrogateDialog: { type: Boolean, default: true },
    displaySave: { type: Boolean, default: false },
    screeningResponseId: { type: Number, default: null },
    tabListToUpdate: {
      type: Array as () => IScreeningResponse[],
      default: () => [] as IScreeningResponse[]
    },
    displayMyselfProp: { type: Boolean, default: true },
    reloadOnCancel: { type: Boolean, default: false },
    uniqueId: { type: String, default: "" }
  },
  created() {
    store.dispatch.applicantDetailsModule.resetData();
  },
  mounted() {
    this.firstNameAriaLabel = "First name is required";
    this.lastNameAriaLabel = "Last name is required";
  },
  computed: {
    userInfo(): ITESUser {
      return store.getters.tesUserModule.getTesUser;
    },
    applicantDetails(): IApplicantDetails {
      return store.getters.applicantDetailsModule.getApplicantDetails;
    },
    displayMyselfTab(): boolean {
      //don't display  tabs for organizations
      return (
        this.displayMyselfProp &&
        (this.userInfo.fein === null ||
          this.userInfo.fein === undefined ||
          this.userInfo.fein.length === 0)
      );
    },
    dontDisplayMyselfTab(): boolean {
      //don't display tabs for organizations
      return (
        !this.displayMyselfProp &&
        (this.userInfo.fein === null ||
          this.userInfo.fein === undefined ||
          this.userInfo.fein.length === 0)
      );
    },
    displayOrganizationNoTabs(): boolean {
      return (
        this.userInfo.fein !== null &&
        this.userInfo.fein !== undefined &&
        this.userInfo.fein.length > 0
      );
    }
  },
  methods: {
    closeQuestDialog() {
      this.displayQuestDialog = false;
    },
    getScreeningQuestions() {
      if (
        //@ts-ignore
        this.$refs.surrogateDialogTemp.validate() ||
        (this.tab == 0 && this.displayMyselfTab)
      ) {
        if (this.tab == 0 && this.displayMyselfTab) {
          this.applicantDetails.applicantFirstName = this.userInfo.userFirstName;

          this.applicantDetails.applicantLastName = this.userInfo.userLastName;
        } else {
          this.applicantDetails.isSurrogate = true;
        }

        if (this.userInfo.fein != null && this.userInfo.fein.length !== 0) {
          this.applicantDetails.isSurrogate = true;
        }
        router.push({ name: "screeningQuestions" });
      } else {
        //@ts-ignore
        const isFormValid = this.$refs.surrogateDialogTemp.validate();
        if (!isFormValid) {
          this.moveFocusToError();
        }
      }
    },
    updateScreeningResponseName() {
      let onBehalfInd = "";

      if (this.tab === 0 && this.displayMyselfTab) {
        this.applicantDetails.applicantFirstName = this.userInfo.userFirstName;

        this.applicantDetails.applicantLastName = this.userInfo.userLastName;
      } else {
        this.applicantDetails.isSurrogate = true;
        onBehalfInd = "Y";
      }

      axios
        .get(DASHBOARD_ENDPOINT.UPDATE_SCREENING_RSPS, {
          params: {
            applicantFirstName: this.applicantDetails.applicantFirstName,
            applicantLastName: this.applicantDetails.applicantLastName,
            onBehalfOfInd: onBehalfInd,
            screeningResponseId: this.screeningResponseId
          }
        })
        .then(response => {
          if (response.status === HTTP_STATUS.OK) {
            for (let i in this.tabListToUpdate) {
              //to make this one as active tab, not reloading the page
              if (
                this.tabListToUpdate[i].screeningResponseId ===
                this.screeningResponseId
              ) {
                this.tabListToUpdate[
                  i
                ].applicantFirstName = this.applicantDetails.applicantFirstName;

                this.tabListToUpdate[
                  i
                ].applicantLastName = this.applicantDetails.applicantLastName;

                this.tabListToUpdate[
                  i
                ].applicantNameDisp = `${this.applicantDetails.applicantLastName}, ${this.applicantDetails.applicantFirstName}`;
              }
            }
            store.dispatch.screeningResponseModule.populateScreenResponse(
              this.screeningResponseId
            );
            this.$emit("close-dialog");
          }
        });
    },
    closeDialog() {
      if (!(this.tab == 0 && this.displayMyselfTab)) {
        //@ts-ignore
        this.$refs.surrogateDialogTemp.reset();
      }
      if (this.screeningResponseId) {
        let screeningResponse = clone(screeningResponseDefault);
        screeningResponse.screeningResponseId = this.screeningResponseId;

        let url = TES_APP_ENDPOINT.DELETE_SCRNG_RSP;
        axios.post(url, screeningResponse).then(response => {
          for (let i in this.tabListToUpdate) {
            //to make this one as active tab, not reloading the page
            if (
              this.tabListToUpdate[i].screeningResponseId ===
              this.screeningResponseId
            ) {
              this.tabListToUpdate.splice(parseInt(i), 1);
              break;
            }
          }
          this.$emit("close-dialog", true);
        });
      } else {
        this.$emit("close-dialog", false);
      }
      this.firstNameAriaLabel = "First name is required";
      this.lastNameAriaLabel = "Last name is required";
    },
    setDefaultAriaLabels() {
      if (
        this.applicantDetails.applicantFirstName &&
        //@ts-ignore
        typeof this.rules.nameValidation(
          this.applicantDetails.applicantFirstName
        ) === "boolean"
      ) {
        this.firstNameAriaLabel = "First name is required";
      }
      if (
        this.applicantDetails.applicantLastName &&
        //@ts-ignore
        typeof this.rules.nameValidation(
          this.applicantDetails.applicantLastName
        ) === "boolean"
      ) {
        this.lastNameAriaLabel = "Last name is required";
      }
    },
    elementToFocus(id1: string, id2: string): HTMLElement | null {
      let el: HTMLElement | null = null;
      if (
        this.applicantDetails.applicantFirstName &&
        //@ts-ignore
        typeof this.rules.nameValidation(
          this.applicantDetails.applicantFirstName
        ) === "string"
      ) {
        el = document.getElementById(id1);
        this.firstNameAriaLabel = "First name has invalid characters";
      } else if (!this.applicantDetails.applicantFirstName) {
        el = document.getElementById(id1);
        this.firstNameAriaLabel = "First name is required";
      } else if (
        this.applicantDetails.applicantLastName &&
        //@ts-ignore
        typeof this.rules.nameValidation(
          this.applicantDetails.applicantLastName
        ) === "string"
      ) {
        el = document.getElementById(id2);
        this.lastNameAriaLabel = "Last name has invalid characters";
      } else if (!this.applicantDetails.applicantLastName) {
        el = document.getElementById(id2);
        this.lastNameAriaLabel = "Last name is required";
      }
      return el;
    },
    moveFocusToError() {
      let elemToFocus: HTMLElement | null = null;
      this.setDefaultAriaLabels();
      if (this.dontDisplayMyselfTab) {
        elemToFocus = this.elementToFocus(
          "onBehalfOfFirstName",
          "onBehalfOfLastName"
        );
      } else if (this.displayOrganizationNoTabs) {
        elemToFocus = this.elementToFocus(
          "agency_on_behalf_first_name",
          "agency_on_behalf_last_name"
        );
      } else if (this.displayMyselfTab) {
        elemToFocus = this.elementToFocus(
          "citizen_on_behalf_first_name",
          "citizen_on_behalf_last_name"
        );
      }
      if (elemToFocus) {
        elemToFocus.focus();
      }
    }
  },
  watch: {
    showSurrogateDialog: {
      immediate: true,
      handler: function(newValue) {
        if (newValue) {
          this.$nextTick(() => {
            //@ts-ignore
            this.setAriaAttribute(this.$refs.surrogateDialog);
          });
        }
      }
    }
  }
});
