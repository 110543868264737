
import Vue from "vue";
import store from "@/store";
import DateHelperMixin from "@/mixins/dateHelperMixin.vue";
import FieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import UIFeatureMixin from "@/mixins/UIFeatureMixin.vue";
import CloseButton from "@/components/common/CloseButton.vue";
import ExpansionPanelTemplate from "@/components/common/ExpansionPanelTemplate.vue";
import {
  IProgramsList,
  IApplicationNavigation,
  IApplicantProfile,
  IStepperHeaderList,
  IDashboardContent
} from "@/types";
import { APPL_STATUS } from "@/constants/CommonConstants";
import router from "@/router";
import axios from "axios";
import { TES_APP_ENDPOINT } from "@/constants/EndPoints";
import { clone } from "@/util/clone";
import { tesApplicationDefault } from "@/data/AppData";
import ProgressBar from "@/components/common/ProgressBar.vue";
import AccessibilityMixin from "@/mixins/AccessibilityMixin.vue";
import DynamicTextMixin from "@/mixins/DynamicTextMixin.vue";

export default Vue.extend({
  name: "dashboard-card",
  mixins: [
    DateHelperMixin,
    FieldEntryMixin,
    UIFeatureMixin,
    AccessibilityMixin,
    DynamicTextMixin
  ],
  components: { ExpansionPanelTemplate, ProgressBar, CloseButton },
  data() {
    return {
      bgColor: "base_light_grey",
      contactDialog: false,
      selectedTransitAgencyData: {} as Object,
      showProgressBar: false,
      tesApplicationModel: clone(tesApplicationDefault)
    };
  },
  props: {
    program: {
      type: Object as () => IProgramsList
    },
    programStatus: { type: String, default: "" },
    uniqueKey: { type: Number, default: null }
  },
  computed: {
    allTransitAgencyData: {
      get(): Object {
        return store.getters.allTransitAgencyDataModule.getAllTransitAgencyData;
      }
    },
    agencyContact(): object {
      //@ts-ignore
      return this.allTransitAgencyData[this.program.transitAgencyCode];
    },
    getGpcCode(): string {
      let gpc: string = "";
      if (this.program.eligibilityInd === "Y") {
        gpc = "Y_" + this.program.programTypeCode + "_1";
      } else if (this.program.eligibilityInd === "N") {
        gpc = "YNT_" + this.program.programTypeCode + "1";
      }
      return gpc;
    },
    applicantProfile(): IApplicantProfile {
      return store.getters.applicantProfileModule.getApplicantProfile;
    },
    applicationNavigation: {
      get(): IApplicationNavigation {
        return store.getters.applicationNavigationModule
          .getApplicationNavigation;
      },
      set(newValue: IApplicationNavigation) {
        store.commit.applicationNavigationModule.SET_APPLLICATION_NAVIGATION(
          newValue
        );
      }
    },
    stepperHeaderMod: {
      get(): IStepperHeaderList {
        return store.getters.stepperHeaderModule.getStepperHeaderModel;
      },
      set(newValue: IStepperHeaderList) {
        store.commit.stepperHeaderModule.SET_STEPPER_HEADER_MODEL(newValue);
      }
    },
    dashboardContent(): IDashboardContent {
      return store.getters.dashboardModule.getDashboardContent;
    },
    applicantAge: {
      get(): number {
        let applicantDOB =
          store.getters.applicantProfileModule.getApplicantProfile.applicantDob;
        let currentDate = new Date();
        let birthDate = new Date(applicantDOB);
        //@ts-ignore
        let difference: any = currentDate - birthDate;
        return Math.floor(difference / 31557600000);
      }
    },
    fontColor(): string {
      return this.bgColor === ("alert_green" || "brand_red")
        ? "white--text"
        : "black--text";
    },
    ftpDoNotQualifyText(): string {
      let gpc: string = "";
      if (this.program.eligibilityInd === "Y") {
        gpc = "Y_" + this.program.programTypeCode + "_1";
      } else if (this.program.eligibilityInd === "N") {
        gpc = "YNT_" + this.program.programTypeCode + "1";
      }
      //@ts-ignore
      return this.ftpDoNotQualifyDynamicText(
        //@ts-ignore
        this.uiFeature[gpc].largeText
      );
    },
    matpDoNotQualifyText(): string {
      //@ts-ignore
      return this.matpDoNotQualifyDynamicText(
        //@ts-ignore
        this.uiFeature["YNT_MATP1"].largeText
      );
    },
    vtpDoNotQualifyText(): string {
      //@ts-ignore
      return this.vtpDoNotQualifyDynamicText(
        //@ts-ignore
        this.uiFeature["YNT_VTP1"].largeText
      );
    },
    rftpDoNotQualifyText(): string {
      //@ts-ignore
      return this.rftpDoNotQualifyDynamicText(
        //@ts-ignore
        this.uiFeature["YNT_RFTP1"].largeText
      );
    }
  },
  methods: {
    bgClass(): string {
      switch (this.program.statusCode) {
        case "SBMTD":
        case "UN_RVW":
        case "PEND_CONF":
          this.bgColor = "info";
          break;
        case "RDY_SBMT":
        case "RE_COR":
          this.bgColor = "alert_gold";
          break;
        case "APPROVED":
        case "TMP_APRVD":
        case "PENDING":
          this.bgColor = "alert_green";
          break;
        case "INELIGIBLE":
        case "INCOMPLETE":
          this.bgColor = "brand_red";
          break;
      }
      return this.bgColor;
    },
    getAgencyLogo(): string {
      return this.program && this.program.transitAgencyLogo
        ? this.program.transitAgencyLogo
        : "";
    },
    getButtonText(): string {
      let returnVal = "Start";
      let status = this.program.statusCode;
      if (
        status === APPL_STATUS.IN_PROGRESS ||
        status === APPL_STATUS.IN_PROGRESS_C
      ) {
        returnVal = "Continue";
      } else if (
        status === APPL_STATUS.READY_TO_SUMBMIT ||
        status === APPL_STATUS.SUBMITTED ||
        status === APPL_STATUS.APPROVED ||
        status === APPL_STATUS.UNDER_REVIEW ||
        status === APPL_STATUS.PENDING_CONFIGURATION ||
        status === APPL_STATUS.RETURN_FOR_CORRECTION ||
        status === APPL_STATUS.READY_TO_SUMBMIT_C ||
        status === APPL_STATUS.INELIGIBLE ||
        status === APPL_STATUS.INCOMPLETE ||
        status === APPL_STATUS.TMP_APRVD ||
        status === APPL_STATUS.PENDING ||
        status === APPL_STATUS.APEL_INPRG
      ) {
        returnVal = "Review";
      } else if (
        status === APPL_STATUS.NOT_APPLICABLE ||
        status === APPL_STATUS.NOT_QUALIFIED
      ) {
        returnVal = "Edit";
      }
      return returnVal;
    },
    checkNonEligibility(): boolean {
      if (
        (this.applicantAge < 65 &&
          (this.program.programTypeCode === "FTP" ||
            this.program.programTypeCode === "SSR")) ||
        ((this.applicantAge < 60 || this.applicantAge > 64) &&
          this.program.programTypeCode === "AGING") ||
        (this.applicantAge > 64 && this.program.programTypeCode === "RFTP") ||
        (this.applicantAge < 17 && this.program.programTypeCode === "VTP") ||
        (this.applicantAge > 65 && this.program.programTypeCode === "PWD")
      ) {
        return true;
      } else {
        return false;
      }
    },
    navigateToProgram() {
      let statusCodeDesc = this.getButtonText();
      store.commit.applicantProgramModule.SET_APPLICANT_PROGRAM(this.program);
      this.applicationNavigation.applProfileEdit = true;
      store.commit.applicationNavigationModule.SET_APPLLICATION_NAVIGATION(
        this.applicationNavigation
      );
      store.dispatch.stepperHeaderModule.resetData();
      if (statusCodeDesc === "Review") {
        router.push({
          path: "/application-review/" + this.program.programTypeCode,
          query: {
            profileID: String(this.applicantProfile.applicantProfileId),
            prgmType: this.program.programTypeCode,
            agencyType: this.program.transitAgencyCode
          }
        });
      } else {
        router.push({
          path: "/application-program/" + this.program.programTypeCode,
          query: {
            profileID: String(this.applicantProfile.applicantProfileId),
            prgmType: this.program.programTypeCode,
            agencyType: this.program.transitAgencyCode
          }
        });
      }
    },
    navigateToApply() {
      //@ts-ignore
      let link = this.uiFeature["ACCS_LNK"].displayName;
      window.open(link, "_blank");
    },
    navigateToSubmittedPrgm() {
      store.commit.applicantProgramModule.SET_APPLICANT_PROGRAM(this.program);
      this.applicationNavigation.applProfileEdit = true;
      store.commit.applicationNavigationModule.SET_APPLLICATION_NAVIGATION(
        this.applicationNavigation
      );
      this.showProgressBar = true;
      let url = TES_APP_ENDPOINT.GET_COMPLETE_PROFILE_DETAILS_BY_APP_ID;
      axios
        .get(url, {
          params: { tesApplicationId: this.program.applicationId }
        })
        .then(response => {
          this.tesApplicationModel = response.data;
          if (response && response.data) {
            store.commit.appModule.SET_DOB_MATCH(response.data.nameDOBMatchInd);
          }
          store.dispatch.applicationReviewModule.loadApplicationReviewModel({
            tesApplicationId: this.tesApplicationModel.applicantionId,
            cycleNumber: this.tesApplicationModel.cycleNumber
          });

          store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(
            this.tesApplicationModel.draftProgramInfoModel
          );

          store.commit.applicantProgramModule.SET_APPLICANT_PROGRAM(
            this.tesApplicationModel.programEligibilityModel
          );

          store.commit.applicantProfileModule.SET_APPLICANT_PROFILE(
            this.tesApplicationModel.applicantProfileModel
          );
          //Get the Driver license details
          store.dispatch.applicantProfileModule.getDriverLicenseDetails(
            //@ts-ignore
            store.getters.draftProgramInfoModule.getDraftProgramInfo
          );
          store.commit.programEligModule.SET_PROG_ELIG(
            response.data.programEligModel
          );

          store.commit.stepperHeaderModule.SET_STEPPER_HEADER_MODEL(
            this.tesApplicationModel.stepperHeaders
          );
          this.stepperHeaderMod.stepperHeaders = this.tesApplicationModel.stepperHeaders;
          if (
            this.stepperHeaderMod.stepperHeaders &&
            this.stepperHeaderMod.stepperHeaders.length > 0
          ) {
            if (this.tesApplicationModel.statusCode === "IN_PRGS_C") {
              router.push({
                name: "editapp",
                query: {
                  profileID: String(
                    this.dashboardContent.applicantProfile.applicantProfileId
                  ),
                  scrnRespID: String(
                    this.dashboardContent.screeningResponse.screeningResponseId
                  ),
                  applID: String(this.program.applicationId)
                }
              });
            } else if (this.tesApplicationModel.statusCode === "RDY_SBMT_C") {
              router.push({
                path: "/application-program/" + "REVIEW",
                query: {
                  profileID: String(
                    this.dashboardContent.applicantProfile.applicantProfileId
                  ),
                  scrnRespID: String(
                    this.dashboardContent.screeningResponse.screeningResponseId
                  ),
                  applID: String(this.program.applicationId)
                }
              });
            } else {
              router.push({
                path:
                  "/application-program/" +
                  this.tesApplicationModel.programTypeCode,
                query: {
                  profileID: String(
                    this.dashboardContent.applicantProfile.applicantProfileId
                  ),
                  scrnRespID: String(
                    this.dashboardContent.screeningResponse.screeningResponseId
                  ),
                  applID: String(this.program.applicationId)
                }
              });
            }
          } else {
            // route to Application review page
            router.push({
              name: "application-review",
              //@ts-ignore
              params: { program: this.tesApplicationModel.programTypeCode },
              query: {
                profileID: String(
                  this.dashboardContent.applicantProfile.applicantProfileId
                ),
                scrnRespID: String(
                  this.dashboardContent.screeningResponse.screeningResponseId
                ),
                applID: String(this.program.applicationId)
              }
            });
          }
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    }
  },
  watch: {
    contactDialog: {
      immediate: true,
      handler: function(newValue) {
        if (newValue) {
          this.$nextTick(() => {
            //@ts-ignore
            this.setAriaAttribute(this.$refs.providerContactDialog);
          });
        }
      }
    }
  }
});
