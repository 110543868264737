<template>
  <div>
    <v-divider
      v-if="index > 0"
      :key="'du' + index"
      aria-hidden="true"
    ></v-divider>
    <v-list-item class="px-0" :ripple="false" :key="index" @click.stop>
      <v-list-item-content>
        <v-container pa-0 mt-4 fluid>
          <v-row wrap v-if="!hideDocDescription">
            <v-col cols="12" sm="12" md="9" lg="9" xl="9">
              <span>
                {{ doc.metaData.filename }}
              </span>
            </v-col>
            <v-col cols="12" sm="12" md="3" lg="3" xl="3">
              <v-btn
                text
                @click="removeDoc()"
                v-if="doc.uploadStatus !== 'uploading'"
                :disabled="doc.uploadStatus === 'uploaded'"
                aria-label="Click To Remove Document from Uploader"
                class="px-0"
              >
                <v-icon color="primary">fa-minus-circle</v-icon>
                <span style="text-transform: capitalize;" class="ml-1">
                  Remove
                </span>
              </v-btn>
              <v-icon color="red" v-if="doc.uploadStatus === 'failed'"
                >fa-exclamation-circle</v-icon
              >
              <v-icon color="green" v-if="doc.uploadStatus === 'uploaded'"
                >fa-check-circle</v-icon
              >
              <v-progress-circular
                indeterminate
                v-if="doc.uploadStatus === 'uploading'"
                color="primary"
                size="16"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import FieldEnrtyMixin from "@/mixins/fieldEntryMixin.vue";

export default {
  name: "DocumentUploadRow",
  components: {},
  mixins: [FieldEnrtyMixin],
  data() {
    return {};
  },
  props: {
    index: { type: Number, default: 0 },
    doc: { type: Object, default: null },
    showHeaders: { type: Boolean, default: false },
    hideDocDescription: { type: Boolean, default: false },
    docDescCodes: {
      type: Array,
      default: null
    }
  },
  mounted() {
    if (this.docDescription) {
      this.doc.metaData.description = this.docDescription.resourceCode;
    }
  },
  computed: {
    showHeader() {
      return this.showHeaders;
    },
    docDescription: {
      get() {
        return this.docDescCodes[0];
      }
    }
  },
  methods: {
    removeDoc() {
      this.$emit("removeDoc");
    }
  }
};
</script>
