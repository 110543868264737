
import Vue from "vue";
import store from "@/store";
import { TES_APP_ENDPOINT } from "@/constants/EndPoints";
import axios from "axios";
import {
  IApplicantProfile,
  IDraftProgramInfo,
  ITESUser,
  IProgramsList
} from "@/types";
import UIFeatureMixinVue from "./UIFeatureMixin.vue";
import StepperNavigationMixinVue from "@/mixins/StepperNavigationMixin.vue";

export default Vue.extend({
  mixins: [UIFeatureMixinVue, StepperNavigationMixinVue],
  methods: {
    saveProgramInfo: function(
      draftProgramInfo: IDraftProgramInfo,
      stepperIndex: number, // current step number
      stepCount: number, // total # of steps
      saveOrContinue: string
    ) {
      draftProgramInfo.applicantProfileId = this.applicantProfile.applicantProfileId;
      draftProgramInfo.programInfo = this.applicantProgramInfo;
      draftProgramInfo.userInfo = this.userInfo;

      if (
        "SAVE" === saveOrContinue ||
        "SAVECONTINUE" === saveOrContinue ||
        "CANCEL" === saveOrContinue
      ) {
        this.setProgramStatus(stepperIndex, stepCount, draftProgramInfo);
      } else if ("CONTINUE" === saveOrContinue) {
        if (draftProgramInfo.programInfo.programTypeCode === "FTP") {
          //@ts-ignore
          draftProgramInfo.programStatus = this.uiFeature.NOT_APPL.displayName;
        } else {
          //@ts-ignore
          draftProgramInfo.programStatus = this.uiFeature.NOT_QLFYD.displayName;
        }
      } else if ("SAVECONTBYPASS" === saveOrContinue) {
        //@ts-ignore
        draftProgramInfo.programStatus = this.uiFeature.NOT_START.displayName;
      } else {
        draftProgramInfo.programStatus = "";
      }

      let url = TES_APP_ENDPOINT.SAVE_DRAFT_PRGM_INFO;

      return axios.post(url, draftProgramInfo);
    },
    setProgramStatus(
      stepperIndex: number,
      stepCount: number,
      draftProgramInfo: IDraftProgramInfo
    ) {
      if (stepperIndex === stepCount) {
        if (
          //@ts-ignore
          this.isApplicantCorrectionsStatus()
        ) {
          if (
            //@ts-ignore
            !this.isDocsRequired()
          ) {
            //@ts-ignore
            draftProgramInfo.programStatus = this.uiFeature.RDY_SBMT_C.displayName;
          } else {
            //@ts-ignore
            draftProgramInfo.programStatus = this.uiFeature.IN_PRGS_C.displayName;
          }
        } else {
          //@ts-ignore
          draftProgramInfo.programStatus = this.uiFeature.RDY_SBMT.displayName;
        }
      } else if (stepperIndex < stepCount) {
        if (
          //@ts-ignore
          this.isApplicantCorrectionsStatus()
        ) {
          //@ts-ignore
          draftProgramInfo.programStatus = this.uiFeature.IN_PRGS_C.displayName;
        } else {
          //@ts-ignore
          draftProgramInfo.programStatus = this.uiFeature.IN_PRGS.displayName;
        }
      } else {
        draftProgramInfo.programStatus = "";
      }
    }
  },
  computed: {
    userInfo: {
      get(): ITESUser {
        return store.getters.tesUserModule.getTesUser;
      }
    },
    applicantProfile: {
      get(): IApplicantProfile {
        // get applicantProfile from it's own module instead of dashboard once applicantProfile is set.

        return store.getters.applicantProfileModule.getApplicantProfile;
      }
    },
    applicantProgramInfo: {
      get(): IProgramsList {
        return store.getters.applicantProgramModule.getApplicantProgram;
      }
    }
  }
});
