import Vue from "vue";
import Vuex from "vuex";
// https://github.com/paleo/direct-vuex
// https://itnext.io/use-a-vuex-store-with-typing-in-typescript-without-decorators-or-boilerplate-57732d175ff3
import { createDirectStore } from "direct-vuex";

import appModule from "@/store/modules/appModule";
import screenQuesModule from "@/store/modules/screenQuesModule";
import dataModule from "@/store/modules/dataModule";
import alertModule from "@/store/modules/alertModule";
import applicantProfileModule from "@/store/modules/applicantProfileModule";
import drivingLicenseInfoModule from "@/store/modules/drivingLicenseInfoModule";
import screeningResponseModule from "@/store/modules/screeningResponseModule";
import draftProgramInfoModule from "@/store/modules/draftProgramInfoModule";
import dashboardModule from "@/store/modules/dashboardModule";
import attachmentsModule from "@/store/modules/attachmentsModule";
import tesUserModule from "@/store/modules/tesUserModule";
import programEligModule from "@/store/modules/programEligModule";
import applicantProgramModule from "@/store/modules/applicantProgramModule";
import applicationNavigationModule from "@/store/modules/applicationNavigationModule";
import uiFeatureModule from "@/store/modules/uiFeatureModule";
import advancedSearchModule from "@/store/modules/advancedSearchModule";
import applicantDetailsModule from "@/store/modules/applicantDetailsModule";
import stepperHeaderModule from "@/store/modules/stepperHeaderModule";
import isamUserAttributesModule from "@/store/modules/isamUserAttributesModule";
import applicationReviewModule from "@/store/modules/applicationReviewModule";
import allTransitAgencyDataModule from "@/store/modules/allTransitAgencyDataModule";
import applicantEcolaneProfileModule from "@/store/modules/applicantEcolaneProfileModule";

Vue.use(Vuex);

const {
  store,
  rootActionContext,
  moduleActionContext,
  rootGetterContext,
  moduleGetterContext
} = createDirectStore({
  modules: {
    appModule,
    screenQuesModule,
    dataModule,
    alertModule,
    applicantProfileModule,
    drivingLicenseInfoModule,
    draftProgramInfoModule,
    screeningResponseModule,
    dashboardModule,
    tesUserModule,
    programEligModule,
    applicantProgramModule,
    attachmentsModule,
    applicationNavigationModule,
    advancedSearchModule,
    uiFeatureModule,
    applicantDetailsModule,
    stepperHeaderModule,
    isamUserAttributesModule,
    applicationReviewModule,
    allTransitAgencyDataModule,
    applicantEcolaneProfileModule
  }
});

// Export the direct-store instead of the classic Vuex store.
export default store;

// The following exports will be used to enable types in the
// implementation of actions.
export {
  rootActionContext,
  moduleActionContext,
  rootGetterContext,
  moduleGetterContext
};

// The following lines enable types in the injected store '$store'.
export type AppStore = typeof store;
declare module "vuex" {
  interface Store<S> {
    direct: AppStore;
  }
}
