var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[(_vm.isEcolaneCustomerLoading)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"pl-16 pr-16"},[_c('h6',[_vm._v(" Ecolane Link ")]),_c('v-card',{staticClass:"borderRadius pa-6",attrs:{"width":"100%"}},[_c('v-row',[_c('v-col',[_c('p',{staticClass:"mb-0"},[_vm._v("Customer No:")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.selectedEcolaneCustomer.ecolaneCustomerId)+" ")])])],1),_c('v-row',[_c('v-col',[_c('span',[_c('v-icon',{staticClass:"pr-1",attrs:{"color":"primary"}},[_vm._v("fa-arrow-alt-circle-right")]),_c('a',{staticClass:"textDecorationNone",attrs:{"href":_vm.selectedEcolaneCustomer.directLink,"target":"_blank"}},[_vm._v(" Ecolane Configuration ")])],1)])],1),_c('v-row',[_c('v-col',[_c('tes-control-template',{attrs:{"required":"","label":"Has configuration been completed?"}},[_c('v-checkbox',{staticClass:"text-h6",attrs:{"label":"Configuration was completed in Ecolane","value":"Y","readonly":_vm.applicationReviewModel.ecolaneConfiguredDate !== null},model:{value:(_vm.applicationReviewModel.ecolaneConfiguredInd),callback:function ($$v) {_vm.$set(_vm.applicationReviewModel, "ecolaneConfiguredInd", $$v)},expression:"applicationReviewModel.ecolaneConfiguredInd"}})],1)],1)],1)],1)],1)],1),_c('v-row',[(
        _vm.applicationReviewModel.reviewResultCode === 'TM_APR_PCF' ||
          _vm.applicationReviewModel.reviewResultCode === 'TMP_APROVE'
      )?_c('v-col',{staticClass:"pl-16 pr-16"},[_vm._v(" Approve application until the Temporary Approval End Date: "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.formatDateMixin(_vm.applicationReviewModel.tempApprovalEndDate))+" ")])]):_vm._e()],1),(_vm.applicationReviewModel.ecolaneConfiguredDate === null)?_c('v-card-actions',{staticClass:"pl-13"},[(
        _vm.applicationReviewModel.reviewResultCode !== 'TM_APR_PCF' &&
          _vm.applicationReviewModel.reviewResultCode !== 'TMP_APROVE'
      )?_c('v-btn',{staticClass:"primaryButton",attrs:{"tile":"","align":"left","disabled":!_vm.applicationReviewModel.ecolaneConfiguredInd},on:{"click":function($event){return _vm.saveReview()}}},[_vm._v("Approve")]):_vm._e(),(
        _vm.applicationReviewModel.reviewResultCode === 'TM_APR_PCF' ||
          _vm.applicationReviewModel.reviewResultCode === 'TMP_APROVE'
      )?_c('v-btn',{staticClass:"primaryButton",attrs:{"tile":"","align":"left","disabled":!_vm.applicationReviewModel.ecolaneConfiguredInd},on:{"click":function($event){return _vm.saveReview()}}},[_vm._v("Temporarily Approve")]):_vm._e(),_c('v-btn',{staticClass:"secondaryButton",attrs:{"tile":"","align":"left"},on:{"click":function($event){return _vm.returnToDashboard()}}},[_vm._v("Cancel")])],1):_vm._e(),(_vm.applicationReviewModel.ecolaneConfiguredDate !== null)?_c('v-card-actions',{staticClass:"pl-13"},[_c('v-btn',{staticClass:"secondaryButton",attrs:{"tile":"","align":"left"},on:{"click":function($event){return _vm.returnToDashboard()}}},[_vm._v("Return to Dashboard")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }