
import Vue from "vue";
import ApplicantDashboard from "@/components/dashboard/DashboardApplications.vue";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";

export default Vue.extend({
  name: "ProfileReview-Dashboard",
  mixins: [fieldEntryMixin, AppPropsMixin],
  components: { ApplicantDashboard }
});
