
import Vue from "vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import CloseButton from "@/components/common/CloseButton.vue";

export default Vue.extend({
  name: "system-info",
  mixins: [UIFeatureMixinVue],
  components: { CloseButton },
  props: {
    idleLogOut: { type: Boolean, default: false }
  },
  data() {
    return {
      showSafariLoginMsg: true as boolean,
      showMatpMsg: true as boolean
    };
  }
});
