
import Vue from "vue";
import store from "@/store";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import AgencyReviewMixin from "@/mixins/AgencyReviewMixin.vue";
import {
  IApplicantProfile,
  IAdvnacedSearchResultObj,
  IDraftProgramInfo
} from "@/types";

export default Vue.extend({
  name: "related-application-information",
  mixins: [UIFeatureMixinVue, AgencyReviewMixin],
  data: function() {
    return {
      stepCount: 4 as number,
      headers: [
        { text: "Application ID", value: "tesApplicationId" },
        { text: "Status", value: "statusCode" },
        { text: "Program", value: "programName" },
        { text: "Agency", value: "transitAgencyName" }
      ]
    };
  },
  props: {
    transitAgncy: { type: String, default: "" }
  },
  async mounted() {
    store.dispatch.draftProgramInfoModule.getRelatedApplicationInfo({
      profileId: this.draftProgramInfo.applicantProfileId,
      transitAgencyCode: this.transitAgncy
    });
  },
  computed: {
    draftProgramInfo(): IDraftProgramInfo {
      return store.getters.draftProgramInfoModule.getDraftProgramInfo
    },
    applicantProfileInfo(): IApplicantProfile {
      return store.getters.applicantProfileModule.getApplicantProfile;
    },
    agencyStatusContent(): IAdvnacedSearchResultObj[] {
      return store.getters.draftProgramInfoModule.getRelatedApplications.filter(
        r =>
          Number(r.tesApplicationId) !== this.applicantProfileInfo.applicationId
      );
    }
  },
  watch: {
    draftProgramInfo: {
      deep: true,
      async handler(newVal: IDraftProgramInfo) {
        store.dispatch.draftProgramInfoModule.getRelatedApplicationInfo({
          profileId: newVal.applicantProfileId,
          transitAgencyCode: this.transitAgncy
        });
      }
    }
  }
});
