var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"mt-5",staticStyle:{"padding":"0px"}},[_c('ProgressBar',{attrs:{"dialog":_vm.showProgressBar}}),(_vm.displayResults)?_c('v-card',{staticClass:"pt-4",attrs:{"flat":""}},[_c('h3',{staticClass:"pb-5 ml-5"},[_vm._v(" Results: "+_vm._s(_vm.advancedSearch.advancedSearchResults.length)+" Applications ")]),_c('v-card-text',{staticClass:"pa-5"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.computedHeaders,"items":_vm.advancedSearch.advancedSearchResults,"items-per-page":10,"sort-by":['statusDate']},scopedSlots:_vm._u([{key:"item",fn:function({item, headers}){return [_c('tr',{on:{"click":function($event){return _vm.searchApplctn(
                item.tesApplicationId,
                item.applicantProfileId,
                item.programTypeCode,
                item.transitAgencyCode,
                item.transitAgencyName,
                item.createdByName
              )}}},_vm._l((headers),function(header,ind){return _c('td',{key:ind},[(header.value === 'statusCode')?_c('span',[_vm._v(" "+_vm._s(item[header.value] === "PEND_CONF" || item[header.value] === "TMP_APRVD" ? _vm.uiFeature[item[header.value]].displayName : _vm.uiFeature[item[header.value]].largeText)+" ")]):_vm._e(),(
                  header.value === 'applicantFullName' &&
                    item['transitAgencyName'] !== item['createdByName']
                )?_c('span',[_vm._v(" "+_vm._s(item["applicantFullName"])+" ")]):_vm._e(),(
                  header.value === 'applicantFullName' &&
                    item['transitAgencyName'] === item['createdByName']
                )?_c('span',[(_vm.isAgencyUser)?_c('span',[_c('a',{on:{"click":function($event){return _vm.forwardToOrgUserDashboard(item.applicantProfileId)}}},[_vm._v(" "+_vm._s(item["applicantFullName"])+" ")])]):_c('span',[_vm._v(" "+_vm._s(item["applicantFullName"])+" ")])]):_vm._e(),(header.value === 'tesApplicationId')?_c('span',[_c('a',{on:{"click":function($event){return _vm.search(
                      item.tesApplicationId,
                      item.applicantProfileId,
                      item.programTypeCode,
                      item.transitAgencyCode
                    )}}},[_vm._v(" "+_vm._s(item[header.value])+" ")])]):_vm._e(),(
                  header.value === 'applicantDOB' ||
                    header.value === 'statusDate'
                )?_c('span',[_vm._v(" "+_vm._s(_vm.formatDateMixin(item[header.value]))+" ")]):_vm._e(),(
                  header.value !== 'statusCode' &&
                    header.value !== 'applicantDOB' &&
                    header.value !== 'statusDate' &&
                    header.value !== 'applicantFullName' &&
                    header.value !== 'tesApplicationId'
                )?_c('span',[_vm._v(" "+_vm._s(item[header.value])+" ")]):_vm._e()])}),0)]}}],null,false,2444227071)})],1)],1):_vm._e(),(_vm.noResults)?_c('v-card',{staticClass:"pr-10 pl-4 pt-4 pb-10",attrs:{"flat":""}},[_c('h3',[_vm._v("No applications match your search criteria")])]):_vm._e(),(_vm.tooManyResults)?_c('v-card',{staticClass:"pr-10 pl-4 pt-4 pb-10",attrs:{"flat":""}},[_c('h3',[_vm._v("Search results exceed 1,000 record limit")]),_c('v-card-text',{staticClass:"pt-0 pl-0"},[_vm._v(" Refine your search criteria to reduce the number of records. ")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }