
import Vue from "vue";
import axios from "axios";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import DraftProgramMixin from "@/mixins/DraftProgramMixin.vue";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import ProgressBar from "@/components/common/ProgressBar.vue";
import store from "@/store";
import { TES_APP_ENDPOINT } from "@/constants/EndPoints";
import {
  IDraftProgramInfo,
  IProgramsList,
  IDraftFixedRouteUsage,
  IApplicantProfile
} from "@/types";
import TESRadioControl from "@/components/common/TESRadioControl.vue";
import tesContainer from "@/components/common/TESContainer.vue";
import router from "@/router";
import routerMixin from "@/mixins/RouterMixin.vue";
import tesControlTemplate from "@/components/common/TESControlTemplate.vue";
import StepperNavigationMixinVue from "@/mixins/StepperNavigationMixin.vue";
import { BUTTON_NAMES, CNST_TEXT } from "@/constants/CommonConstants";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import InfoSection from "@/components/common/InfoSection.vue";
import ScrollMixin from "@/mixins/ScrollMixin.vue";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";
import { GPC } from "@/constants/GPCCode";
import DynamicTextMixin from "@/mixins/DynamicTextMixin.vue";

export default Vue.extend({
  name: "ada-program-info",
  mixins: [
    fieldEntryMixin,
    DraftProgramMixin,
    AppPropsMixin,
    routerMixin,
    StepperNavigationMixinVue,
    UIFeatureMixinVue,
    ScrollMixin,
    ApplicationMixin,
    DynamicTextMixin
  ],
  components: {
    tesContainer,
    TESRadioControl,
    ProgressBar,
    tesControlTemplate,
    InfoSection
  },
  props: {
    stepCount: { type: Number, default: 1 },
    step: { type: Number, default: 1 },
    draftFixedRoute: {
      type: Object as () => IDraftFixedRouteUsage
    },
    displayDisabilityInd: { type: Boolean, default: false }
  },
  data() {
    return {
      showProgressBar: false,
      showDefaultButtons: true,
      serviceArea: "",
      transitAgencyAreaCode: "",
      showDisabilityInd: true,
      draftFixedRouteUsage: {} as IDraftFixedRouteUsage,
      draftFixedRouteUsageList: {} as IDraftFixedRouteUsage[],
      showFixedRouteDisabilityDesc: false,
      showFixedRouteDisabilityInd: false
    };
  },
  computed: {
    applicantProgramInfo(): IProgramsList {
      return store.getters.applicantProgramModule.getApplicantProgram;
    },
    isSingleFlowFinalStep(): boolean {
      // @ts-ignore
      if (this.isSingleFlow()) {
        return this.step == this.stepCount;
      } else {
        return false;
      }
    },
    disabilityQuestionText(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrgAgyCtznonBehalfOfUser()
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.DS_AGY_FRS].largeText,
          CNST_TEXT.DOES_THE_APPLICANT,
          CNST_TEXT.THEM,
          this.applicantProgramInfo.transiAgencyName
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.DS_AGY_FRS].largeText,
          CNST_TEXT.DO_YOU,
          CNST_TEXT.YOU,
          this.applicantProgramInfo.transiAgencyName
        );
      }
      return text;
    },
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      },
      set(newValue: IDraftProgramInfo) {
        store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(newValue);
      }
    },
    applicantProfile(): IApplicantProfile {
      return store.getters.applicantProfileModule.getApplicantProfile;
    },
    adaProgInfo(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrgAgyCtznonBehalfOfUser()
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.ADA_SEC1].largeText,
          CNST_TEXT.THE_APPLICANT_QUALIFIES,
          CNST_TEXT.THEY,
          CNST_TEXT.THEM
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.ADA_SEC1].largeText,
          CNST_TEXT.YOU_QUALIFY,
          CNST_TEXT.YOU,
          CNST_TEXT.YOU
        );
      }
      return text;
    },
    adaProgSecondSectionInfo(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrgAgyCtznonBehalfOfUser()
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.ADA_SEC2].largeText,
          CNST_TEXT.THE_APPLICANTS,
          CNST_TEXT.THE_APPLICANTS
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.ADA_SEC2].largeText,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOUR
        );
      }
      return text;
    },
    disabilityConfirmQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithOnePlaceHolder(
        GPC.DIS_CNFM,
        CNST_TEXT.THE_APPLICANT_HAS,
        CNST_TEXT.YOU_HAVE
      );
      return text;
    },
    dsbltyFixedRouteSrvQues(): string {
      let text: string = "";
      //@ts-ignore
      text = this.buildTxtWithTwoPlaceHolder(
        GPC.DS_PRV_FRS,
        CNST_TEXT.THE_APPLICANTS,
        CNST_TEXT.THEM,
        CNST_TEXT.YOUR,
        CNST_TEXT.YOU
      );
      return text;
    },
    travelQuestionText(): string {
      return this.getTravelQuesTxt(this.serviceArea);
    }
  },
  created() {
    this.draftFixedRouteUsage = this.draftFixedRoute;

    this.showFixedRouteDisabilityInd =
      this.draftProgramInfo.disabilityInd === "Y" &&
      (this.draftFixedRouteUsage.providerAreaTravelInd === "Y" ||
        this.applicantProfile.gisCallInd === "Y");

    this.showFixedRouteDisabilityDesc =
      this.draftProgramInfo.disabilityInd === "Y" &&
      this.draftFixedRouteUsage.fixedRouteDisabilityInd === "Y";

    if (
      this.draftProgramInfo.disabilityInd &&
      //@ts-ignore
      this.isSingleFlow() &&
      //@ts-ignore
      (this.isProgramExist("PWD") || !this.displayDisabilityInd)
    ) {
      this.showDisabilityInd = false;
    }

    this.populateSteps();

    let applicantCounty =
      store.getters.applicantProfileModule.getApplicantProfile.homeAddress
        .paCountyCode;

    let prgmTransitAgencyCode =
      store.getters.applicantProgramModule.getApplicantProgram
        .transitAgencyCode;

    axios
      .get(TES_APP_ENDPOINT.GET_TRANSIT_AGENCY_SRV_NAME, {
        params: {
          countyCode: applicantCounty,
          transitAgencyCode: prgmTransitAgencyCode
        }
      })
      .then(response => {
        this.serviceArea = response.data.areaName;
        this.transitAgencyAreaCode = response.data.transiAgencyAreaCode;
      })
      .catch(error => {
        store.dispatch.alertModule.showAlertMessageById(error.response.data.id);
      });
  },
  methods: {
    saveContinue() {
      //@ts-ignore
      if (this.$refs.adaProgramInfoTemplate.validate()) {
        this.saveADAProgramInfo("SAVECONTINUE");
      } else {
        //@ts-ignore
        this.scrollToErrorField(this.$refs.adaProgramInfoTemplate);
      }
    },
    save(save: string) {
      //@ts-ignore
      if (this.$refs.adaProgramInfoTemplate.validate()) {
        this.saveADAProgramInfo(save);
      } else {
        //@ts-ignore
        this.scrollToErrorField(this.$refs.adaProgramInfoTemplate);
      }
    },
    cancel() {
      //@ts-ignore
      if (this.$refs.adaProgramInfoTemplate.validate()) {
        this.saveADAProgramInfo("CANCEL");
      } else {
        //@ts-ignore
        this.scrollToErrorField(this.$refs.adaProgramInfoTemplate);
      }
    },
    saveADAProgramInfo(buttonName: string) {
      this.showProgressBar = true;
      let stepperIndex = 1;
      if (this.draftProgramInfo.draftFixedRouteUsageList === null) {
        this.draftProgramInfo.draftFixedRouteUsageList = [];
      }
      this.draftFixedRouteUsage.applicantProfileId = this.draftProgramInfo.applicantProfileId;
      this.draftFixedRouteUsage.transitAgencyAreaCode = this.transitAgencyAreaCode;
      this.draftFixedRouteUsage.transitAgencyCode = this.applicantProgramInfo.transitAgencyCode;
      this.draftProgramInfo.draftFixedRouteUsageList.push(
        this.draftFixedRouteUsage
      );
      //@ts-ignore
      this.saveProgramInfo(
        this.draftProgramInfo,
        stepperIndex,
        this.stepCount,
        buttonName
      )
        //@ts-ignore
        .then(response => {
          this.draftProgramInfo = response.data;
          //@ts-ignore
          store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(
            this.draftProgramInfo
          );
          //@ts-ignore
          store.commit.applicantProgramModule.SET_APPLICANT_PROGRAM(
            this.draftProgramInfo.programInfo
          );

          if (
            //@ts-ignore
            this.isSingleFlow() ||
            buttonName === BUTTON_NAMES.SAVE ||
            buttonName === BUTTON_NAMES.CANCEL
          ) {
            this.$emit("save-completed", buttonName);
          } else {
            //@ts-ignore
            this.forwardToDashboard();
          }
        })
        //@ts-ignore
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    },
    changeValues() {
      if (this.draftProgramInfo.disabilityInd === "N") {
        this.draftFixedRouteUsage.providerAreaTravelInd = "";
        this.draftFixedRouteUsage.fixedRouteDisabilityInd = "";
      }

      if (this.draftFixedRouteUsage.providerAreaTravelInd === "N") {
        this.draftFixedRouteUsage.fixedRouteDisabilityInd = "";
      }

      this.showFixedRouteDisabilityInd =
        this.draftProgramInfo.disabilityInd === "Y" &&
        (this.draftFixedRouteUsage.providerAreaTravelInd === "Y" ||
          this.applicantProfile.gisCallInd === "Y");

      this.showFixedRouteDisabilityDesc =
        this.draftProgramInfo.disabilityInd === "Y" &&
        this.draftFixedRouteUsage.fixedRouteDisabilityInd === "Y";

      this.showDefaultButtons = true;
      this.populateSteps();
    },
    getTravelQuesTxt(serviceAreaName: string) {
      let travelQuestion: string = "";
      let applicantCounty =
        store.getters.applicantProfileModule.getApplicantProfile.homeAddress
          .paCountyCode;
      //@ts-ignore
      let noSrvArea = this.uiFeature.NO_SRVAREA.largeText;
      let noSrvAreaArray = noSrvArea.split(",");
      if (noSrvAreaArray.includes(applicantCounty)) {
        //@ts-ignore
        travelQuestion = this.buildTxtWithTwoPlaceHolder(
          GPC.TVL_ARA_Q1,
          CNST_TEXT.DOES_THE_APPLICANT,
          this.applicantProgramInfo.transiAgencyName,
          CNST_TEXT.DO_YOU,
          this.applicantProgramInfo.transiAgencyName
        );
      } else if (
        //@ts-ignore
        this.isOrgAgyCtznonBehalfOfUser()
      ) {
        //@ts-ignore
        travelQuestion = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.TVL_ARA_Q2].largeText,
          CNST_TEXT.DOES_THE_APPLICANT,
          serviceAreaName,
          this.applicantProgramInfo.transiAgencyName
        );
      } else {
        //@ts-ignore
        travelQuestion = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.TVL_ARA_Q2].largeText,
          CNST_TEXT.DO_YOU,
          serviceAreaName,
          this.applicantProgramInfo.transiAgencyName
        );
      }
      return travelQuestion;
    },
    populateSteps() {
      if (
        this.draftProgramInfo.disabilityInd === "N" ||
        this.draftFixedRouteUsage.providerAreaTravelInd === "N" ||
        this.draftFixedRouteUsage.fixedRouteDisabilityInd === "N"
      ) {
        this.showDefaultButtons = false;
      }

      this.$emit("step-count");
    },
    goToPWDProgram() {
      router.push({
        path: "/application-program/" + "PWD"
      });
    }
  }
});
