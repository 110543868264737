export const AGE_DOC_CODES = [
  { value: "BRTH_CERT", text: "Birth certificate" },
  {
    value: "BAPT_CERT",
    text: "Baptismal certificate, if it lists a birth date"
  },
  { value: "DL", text: "Driver’s license" },
  { value: "ARMF_DSCH", text: "Armed forces discharge papers" },
  {
    value: "US_SSA",
    text: "Statement of age from the US Social Security Administration"
  },
  { value: "PASSPT", text: "Passport or naturalization papers" },
  { value: "PACE_ID", text: "PACE ID card" },
  {
    value: "VET_ID",
    text: "Veteran’s ID card, if it lists a birth date"
  },
  { value: "RES_ALN", text: "Resident alien card" }
];

export const DSBLTY_DOC_CODES = [
  { value: "CERT_DBLTY", text: "Certificate of Disability" }
];
