import Vue from "vue";
import VueRouter from "vue-router";
import Moved from "@/views/Moved.vue";
import Home from "@/views/Home.vue";
import Help from "@/views/Help.vue";
import Contact from "@/views/Contact.vue";
import Program from "@/views/Program.vue";
import ProgramDashboard from "@/views/ProgramDashboard.vue";
import Dashboard from "@/views/Dashboard.vue";
import UserInfo from "@/views/UserInfo.vue";
import ProgramReview from "@/views/ProgramReview.vue";
import AdvancedSearch from "@/views/AdvancedSearch.vue";
import PromiseInterface from "@/views/PromiseInterface.vue";
import DashboardOrgUser from "@/views/DashboardOrgUser.vue";
import About from "@/views/About.vue";
import ScreeningQues from "@/views/ScreeningQues.vue";
import ScreeningQuesResults from "@/views/ScreeningQuesResults.vue";
import CreateApplication from "@/views/CreateApplication.vue";
import EditApplication from "@/views/EditApplication.vue";
import ReviewProfileInformation from "@/views/ReviewProfileInformation.vue";
import RegisterVue from "@/views/Register.vue";
import Report from "@/views/Report.vue";
import ReportPBI from "@/views/ReportPBI.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/dashboard"
  },
  {
    path: "/public/moved",
    name: "moved",
    component: Moved,
    props: true
  },
  {
    path: "/public/home",
    name: "home",
    component: Home,
    props: true,
    meta: {
      title: "Home"
    }
  },
  {
    path: "/public/about",
    name: "about",
    component: About,
    meta: {
      title: "About Us"
    }
  },
  {
    path: "/report",
    name: "report",
    component: Report,
    meta: {
      title: "Reports"
    }
  },
  {
    path: "/reportPBI",
    name: "reportPBI",
    component: ReportPBI
  },
  {
    path: "/public/help",
    name: "help",
    component: Help,
    meta: {
      title: "Help"
    }
  },
  {
    path: "/public/contact",
    name: "contact",
    component: Contact,
    meta: {
      title: "Contact Us"
    }
  },
  {
    path: "/screeningQuestions",
    name: "screeningQuestions",
    component: ScreeningQues,
    meta: {
      title: "Screening Questions"
    }
  },
  {
    path: "/screeningQuestionsResults",
    name: "screeningQuestionsResults",
    component: ScreeningQuesResults,
    meta: {
      title: "Screening Questions Results"
    }
  },
  {
    path: "/createapp",
    name: "createapp",
    component: CreateApplication,
    meta: {
      title: "Create Application"
    }
  },
  {
    path: "/editapp",
    name: "editapp",
    component: EditApplication,
    meta: {
      title: "Edit Application"
    }
  },

  {
    path: "/application-program/:program",
    name: "application-program",
    component: Program,
    props: true,
    meta: {
      title: "Program Application"
    }
  },
  {
    path: "/program-dashboard",
    name: "program-dashboard",
    component: ProgramDashboard,
    meta: {
      title: "Program Dashboard"
    }
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: {
      title: "Dashboard"
    }
  },
  {
    path: "/dashboardOrgUser",
    name: "dashboardOrgUser",
    component: DashboardOrgUser
  },
  {
    path: "/reviewProfileInfo",
    name: "reviewProfileInfo",
    component: ReviewProfileInformation,
    meta: {
      title: "Review Profile"
    }
  },
  {
    path: "/userInfo",
    name: "userInfo",
    component: UserInfo
  },
  {
    path: "/application-review/:program",
    name: "application-review",
    component: ProgramReview,
    props: true,
    meta: {
      title: "Application Review"
    }
  },
  {
    path: "/advancedSearch",
    name: "advancedSearch",
    component: AdvancedSearch,
    meta: {
      title: "Advance Search"
    }
  },
  {
    path: "/promiseInterface",
    name: "promiseInterface",
    component: PromiseInterface,
    meta: {
      title: "Promise Interface"
    }
  },
  {
    path: "/register",
    name: "register",
    component: RegisterVue,
    meta: {
      title: "Register"
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, _from, next) => {
  if (to.meta && to.meta.title)
    document.title = `PennDOT Find My Ride Apply - ${to.meta.title}`;
  next();
});

export default router;
