
import Vue from "vue";
import SteppersNav from "@/components/application/CreateAppSteppersNav.vue";
import { IScreeningResponse } from "@/types";
import store from "@/store";

export default Vue.extend({
  name: "create-app",
  components: {
    SteppersNav
  },
  computed: {
    screeningResponse: {
      get(): IScreeningResponse {
        //@ts-ignore
        return store.getters.screeningResponseModule.getScreeningResponse;
      }
    },
    applicantProfile(): any {
      //@ts-ignore
      return store.getters.applicantProfileModule.getApplicantProfile;
    },
    applicantName(): string {
      let applName =
        this.screeningResponse.applicantFirstName +
        " " +
        this.screeningResponse.applicantLastName;
      if (this.applicantProfile.applicantFirstName) {
        applName =
          this.applicantProfile.applicantFirstName +
          " " +
          this.applicantProfile.applicantLastName;
      }
      return applName;
    }
  }
});
