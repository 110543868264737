var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-form',{ref:"programDetailTemplate",attrs:{"lazy-validation":"","autocomplete":"off"}},[_c('tes-container',{attrs:{"questionHint":_vm.pwdDetailInfo,"isSingleFlowFinalStep":_vm.singleFlowFinalStep,"showRequiredLegend":""},on:{"save-clicked":function($event){return _vm.save()},"save-continue-clicked":function($event){return _vm.saveContinue()},"cancel-clicked":function($event){return _vm.cancel()}}},[_c('v-row',{staticClass:"py-3 no-gutters"},[_c('v-col',[_c('label',{staticClass:"inputLabelSmall",attrs:{"id":"spneedslbl"}},[_vm._v(" Special Needs/Nature of Condition (Select all that apply) ")]),_c('span',{staticClass:"requiredSymbol"},[_c('strong',[_vm._v("*")])]),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"d-flex":"","xs12":"","sm6":"","md6":""}},[_c('ul',{staticClass:"listStyleNone",attrs:{"aria-labelledby":"spneedslbl"}},_vm._l((_vm.specialNeeds),function(m,index){return _c('li',{directives:[{name:"show",rawName:"v-show",value:(index % 2 === 0),expression:"index % 2 === 0"}],key:index},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-checkbox',{key:index,attrs:{"value":m.resourceCode,"label":m.displayName,"rules":[_vm.rules.specialNeedsRule]},model:{value:(_vm.seletedNeeds),callback:function ($$v) {_vm.seletedNeeds=$$v},expression:"seletedNeeds"}})],1)],1)],1)}),0)]),_c('v-flex',{attrs:{"d-flex":"","xs12":"","sm6":"","md6":""}},[_c('ul',{staticClass:"listStyleNone",attrs:{"aria-labelledby":"spneedslbl"}},_vm._l((_vm.specialNeeds),function(m,index){return _c('li',{directives:[{name:"show",rawName:"v-show",value:(index % 2 !== 0),expression:"index % 2 !== 0"}],key:index},[_c('v-checkbox',{key:index,attrs:{"value":m.resourceCode,"label":m.displayName,"rules":[_vm.rules.specialNeedsRule]},model:{value:(_vm.seletedNeeds),callback:function ($$v) {_vm.seletedNeeds=$$v},expression:"seletedNeeds"}}),(
                    m.resourceCode === 'DISABL_OTH' &&
                      _vm.seletedNeeds.includes(m.resourceCode)
                  )?_c('v-col',[_c('v-textarea',{attrs:{"rows":"1","auto-grow":"","cols":"100","solo":"","maxlength":"250","aria-label":"Other Text","placeholder":"Enter Other Here","rules":[_vm.disabilityOtherTextRule]},model:{value:(_vm.otherText),callback:function ($$v) {_vm.otherText=$$v},expression:"otherText"}})],1):_vm._e()],1)}),0)])],1)],1)],1),_c('v-row',{staticClass:"py-3 no-gutters"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('TESRadioControl',{attrs:{"questionText":"Type of Disability","requiredQuestion":"","questionClass":"no-gutters","options":_vm.typeOfDisabilityOptions,"rules":[_vm.rules.required],"radioGroupId":"radio_pwd_disabilityType"},model:{value:(_vm.draftProgramInfo.disabilityType),callback:function ($$v) {_vm.$set(_vm.draftProgramInfo, "disabilityType", $$v)},expression:"draftProgramInfo.disabilityType"}})],1),(
          _vm.isPermanentDisability &&
            _vm.applicantProgramInfo.transitAgencyCode === 'SEPTA' &&
            (_vm.applicantProgramInfo.programTypeCode === 'ADA' ||
              _vm.applicantProgramInfo.programTypeCode === 'RFTP')
        )?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('TESRadioControl',{attrs:{"questionText":_vm.disabilityConditionQuestion,"requiredQuestion":"","questionClass":"no-gutters","options":_vm.uiFeature.DIS_CNDTNS.children,"rules":[_vm.rules.required],"radioGroupId":"radio_pwd_conditionType"},model:{value:(_vm.draftProgramInfo.disabilityCndtn),callback:function ($$v) {_vm.$set(_vm.draftProgramInfo, "disabilityCndtn", $$v)},expression:"draftProgramInfo.disabilityCndtn"}})],1):_vm._e(),(_vm.isTemporaryDisability)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('label',{staticClass:"inputLabelSmall"},[_vm._v(" Disability End Date ")]),_c('span',{staticClass:"requiredSymbol"},[_c('strong',[_vm._v("*")])]),_c('DateInput',{attrs:{"id":"dedDatePicker","dateRules":[_vm.rules.required, _vm.rules.isDateCurrentOrFuture],"aria-label":"Enter Disability End Date in mm/dd/yyyy format"},model:{value:(_vm.draftProgramInfo.temporaryDisabilityEndDate),callback:function ($$v) {_vm.$set(_vm.draftProgramInfo, "temporaryDisabilityEndDate", $$v)},expression:"draftProgramInfo.temporaryDisabilityEndDate"}})],1):_vm._e()],1)],1),_c('ProgressBar',{attrs:{"dialog":_vm.showProgressBar}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }