
export default {
  data() {
    return {};
  },
  methods: {
    setAriaAttribute(ref: any) {
      const content = ref.$refs.content;
      const chidDiv = content.getElementsByTagName("div")[0];
      if (chidDiv && chidDiv.hasAttribute("tabindex"))
        chidDiv.removeAttribute("tabindex");
      if (content) {
        content.setAttribute("tabindex", 0);
        // Copy aria-labelledby from v-dialog to new rendered element.
        content.setAttribute(
          "aria-labelledby",
          //@ts-ignore
          ref.$attrs["aria-labelledby"]
        );
      }
    },
    setAriaLabelledByForSelect(ref: any, id: string) {
      if (ref && ref.$refs && ref.$refs.input && ref.$refs.input.offsetParent) {
        ref.$refs.input.offsetParent.setAttribute("aria-labelledby", id);
      }
    },
    setAriaAttributeForSelectInForm(ref: any, label: string, id: string) {
      let element: any;
      ref.inputs.find((input: any) => {
        if (
          input.$refs.input &&
          input.$refs.input.ariaLabel &&
          input.$refs.input.ariaLabel === label
        ) {
          element = input;
        }
        return element;
      });
      if (element && element.$refs.input.offsetParent) {
        element.$refs.input.offsetParent.setAttribute("aria-labelledby", id);
      }
    },
    setAriaAttributesOfAutoComplete(ref: any, id: string) {
      let content: any;
      if (ref.length) {
        if (ref[0].$el.firstChild) {
          content = ref[0].$el.firstChild.firstChild;
        }
      } else {
        if (ref.$el && ref.$el.firstChild) {
          content = ref.$el.firstChild.firstChild;
        }
      }
      if (content) {
        content.setAttribute("aria-labelledby", id);
      }
    }
  }
};
