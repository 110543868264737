
import Vue from "vue";

import SurrogateDialog from "@/components/dashboard/SurrogateDialog.vue";
import axios from "axios";
import { HTTP_STATUS } from "@/constants/HttpStatusCode";
import { DASHBOARD_ENDPOINT, TES_APP_ENDPOINT } from "@/constants/EndPoints";
import store from "@/store";
import {
  IOrgDashboardContent,
  ITESUser,
  IOrgDashboardReqModel,
  ITESApplicationModel,
  IApplicationNavigation,
  IAdvnacedSearchResultObj,
  IAgencyDashboardTabModel,
  IAssignUserModel
} from "@/types";
import { assignUserDefault, tesUserDefault } from "@/data/AppData";
import ProgressBar from "@/components/common/ProgressBar.vue";
import DashboardInitial from "@/components/dashboard/DashboardInitial.vue";
import router from "@/router";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import DateHelperMixinVue from "@/mixins/dateHelperMixin.vue";
import { STATUS_CODES } from "@/constants/CommonConstants";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";
import AgencyReviewMixin from "@/mixins/AgencyReviewMixin.vue";
import { clone } from "@/util/clone";
import { GPC } from "@/constants/GPCCode";

export default Vue.extend({
  name: "dashboard-agency",
  mixins: [
    UIFeatureMixinVue,
    DateHelperMixinVue,
    AppPropsMixin,
    ApplicationMixin,
    AgencyReviewMixin
  ],
  components: {
    SurrogateDialog,
    ProgressBar,
    DashboardInitial
  },
  data() {
    return {
      showSurrogateDialog: false,
      displaySave: false,
      agencyHeaders: [
        { text: "Review Applications", index: 0 },
        { text: "Create Applications", index: 1 }
      ],
      items: [
        { text: "My Applicants", index: 0 },
        { text: "All Applicants", index: 1 }
      ],
      agencyReviewHeaders: [
        { text: "View By Aging", index: 0 },
        { text: "Unassigned Applications", index: 1 },
        { text: "My Applications", index: 2 },
        { text: "All Applications", index: 3 }
      ],
      reviewStatusTabs: [] as IAgencyDashboardTabModel[],
      tab: 0 as number,
      agencyHeaderTab: 0 as number,
      reviewHeaderTab: 0 as number,
      statusTab: 0 as number,
      orgDashboardContent: [] as IOrgDashboardContent[],
      orgDashboardReqModel: {} as IOrgDashboardReqModel,
      agencyStatusContent: [] as IAdvnacedSearchResultObj[],
      agingDashboardContent: [] as IAdvnacedSearchResultObj[],
      headers: [
        { text: "Applicant Name", value: "applicantFullName" },
        { text: "Profile Status", value: "profileStatus" },
        { text: "Application Status", value: "applicationStatus" },
        { text: "Application #", value: "applicationId" },
        { text: "Program", value: "program" },
        { text: "Agency", value: "agency" },
        { text: "Status Date", value: "statusDate" }
      ],
      headersAllApplicants: [
        { text: "Applicant Name", value: "applicantFullName" },
        { text: "Profile Status", value: "profileStatus" },
        { text: "Application Status", value: "applicationStatus" },
        { text: "Application #", value: "applicationId" },
        { text: "Program", value: "program" },
        { text: "Agency", value: "agency" },
        { text: "Status Date", value: "statusDate" },
        { text: "Created By", value: "createdBy" }
      ],
      headersAgencyDashboard: [
        { text: "Application #", value: "tesApplicationId" },
        { text: "Applicant Name", value: "applicantFullName" },
        { text: "Program", value: "programName" },
        { text: "County", value: "countyName" },
        { text: "Status Date", value: "statusDate" },
        { text: "Created By", value: "createdByName" }
      ],
      headersAllAppAgencyDashboard: [
        { text: "Application #", value: "tesApplicationId" },
        { text: "Applicant Name", value: "applicantFullName" },
        { text: "Program", value: "programName" },
        { text: "County", value: "countyName" },
        { text: "Status Date", value: "statusDate" },
        { text: "Created By", value: "createdByName" },
        { text: "Assigned To", value: "assignedTo" }
      ],
      headersAgencySpvsrUnassignedDashboard: [
        { text: "Application #", value: "tesApplicationId" },
        { text: "Applicant Name", value: "applicantFullName" },
        { text: "Program", value: "programName" },
        { text: "County", value: "countyName" },
        { text: "Appt.?", value: "upcomingAptInd" },
        { text: "Status Date", value: "statusDate" },
        { text: "Created By", value: "createdByName" },
        { text: "Assigned To", value: "assignedTo" }
      ],
      headersAgencyUnassignedDashboard: [
        { text: "Application #", value: "tesApplicationId" },
        { text: "Applicant Name", value: "applicantFullName" },
        { text: "Program", value: "programName" },
        { text: "County", value: "countyName" },
        { text: "Appt.?", value: "upcomingAptInd" },
        { text: "Status Date", value: "statusDate" },
        { text: "Created By", value: "createdByName" }
      ],
      headersAgingyDashboard: [
        { text: "Days Remaining", value: "daysRemain" },
        { text: "Application #", value: "tesApplicationId" },
        { text: "Applicant Name", value: "applicantFullName" },
        { text: "Program", value: "programName" },
        { text: "Application Status", value: "statusCode" },
        { text: "Appt.?", value: "upcomingAptInd" },
        { text: "Status Date", value: "statusDate" },
        { text: "Assigned To", value: "assignedTo" }
      ],
      showProgressBar: true,
      showInitialDashboard: false as boolean,
      tesApplicationModel: {} as ITESApplicationModel,
      selectedExpansion: 100 as number,
      daysRemainIcon: 0 as number,
      asgnApp: {},
      asignees: {} as ITESUser,
      selectedUser: {} as ITESUser,
      actRev: false,
      userNotFound: false
    };
  },
  computed: {
    userInfo: {
      get(): ITESUser {
        return store.getters.tesUserModule.getTesUser;
      }
    },
    applicationNavigation: {
      get(): IApplicationNavigation {
        //@ts-ignore
        return store.getters.applicationNavigationModule
          .getApplicationNavigation;
      },
      set(newValue: IApplicationNavigation) {
        //@ts-ignore
        store.commit.applicationNavigationModule.SET_APPLLICATION_NAVIGATION(
          newValue
        );
      }
    }
  },
  created() {
    this.getDashBoardAgingContent();
    store.dispatch.applicationReviewModule.resetData();
    this.loadAgencyTabs();
  },
  methods: {
    isRdOnlyStatus(status: string): boolean {
      let ASSGN_STATUS = [
        "APPROVED",
        "RE_COR",
        "INELIGIBLE",
        "WITHDRAWN",
        "TMP_APRVD"
      ];
      return ASSGN_STATUS.includes(status);
    },
    forwardToSummaryPage(applId: string) {
      this.forwardSubmittedApplicationToSummary(applId);
    },
    loadAgencyTabs() {
      //@ts-ignore
      if (this.isReviewerAssigned) {
        this.agencyReviewHeaders.splice(1, 1);
      }
    },
    getHeaders: function(index: number, headerType: string, tabInd: number) {
      if (headerType === "create") {
        if (index === 0) {
          return this.headers;
        } else {
          return this.headersAllApplicants;
        }
      } else {
        if (tabInd === 1) {
          //@ts-ignore
          if (!this.isAgencySupervisor) {
            return this.headersAgencyUnassignedDashboard;
          } else {
            return this.headersAgencySpvsrUnassignedDashboard;
          }
          //@ts-ignore
        } else if (tabInd === 3 || (tabInd === 2 && this.isReviewerAssigned)) {
          return this.headersAllAppAgencyDashboard;
        } else {
          return this.headersAgencyDashboard;
        }
      }
    },
    closeSurrogateDialog() {
      this.showSurrogateDialog = false;
    },
    addNewPerson() {
      this.showSurrogateDialog = true;
    },
    forwardToOrgUserDashboard(
      applicantProfileId: string,
      screeningResponseId: string
    ) {
      router.push({
        name: "dashboardOrgUser",
        query: {
          applicantProfileId: applicantProfileId,
          screeningResponseId: screeningResponseId
        }
      });
    },

    navigateToProgram(
      applicationStatus: string,
      applicationId: string,
      applicantProfileId: string,
      programTypeCode: string,
      transitAgencyCode: string
    ) {
      this.applicationNavigation.applProfileEdit = true;
      //@ts-ignore
      store.commit.applicationNavigationModule.SET_APPLLICATION_NAVIGATION(
        this.applicationNavigation
      );

      if (
        applicationStatus !== null &&
        (applicationStatus === STATUS_CODES.SBMTD ||
          applicationStatus === STATUS_CODES.RDY_SBMT)
      ) {
        if (
          applicationId !== null &&
          applicationId !== undefined &&
          applicationId.length !== 0
        ) {
          this.forwardSubmittedApplicationToSummary(applicationId);
        }
      } else {
        if (
          applicantProfileId !== null &&
          applicantProfileId !== undefined &&
          applicantProfileId.length !== 0
        ) {
          this.forwardBeforeSubmittedApplicationToSummary(
            applicantProfileId,
            programTypeCode,
            transitAgencyCode
          );
        }
      }
    },

    forwardSubmittedApplicationToSummary(applicationId: string) {
      let url = TES_APP_ENDPOINT.GET_COMPLETE_PROFILE_DETAILS_BY_APP_ID;
      axios
        .get(url, {
          params: { tesApplicationId: applicationId }
        })
        .then(response => {
          this.tesApplicationModel = response.data;
          if (response && response.data) {
            store.commit.appModule.SET_DOB_MATCH(response.data.nameDOBMatchInd);
          }
          this.forwardToApplicationSummary(this.tesApplicationModel);
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    },
    async forwardToApplicationSummary(tesAppModel: ITESApplicationModel) {
      //@ts-ignore
      store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(
        tesAppModel.draftProgramInfoModel
      );

      //@ts-ignore
      store.commit.applicantProgramModule.SET_APPLICANT_PROGRAM(
        tesAppModel.programEligibilityModel
      );

      //@ts-ignore
      store.commit.applicantProfileModule.SET_APPLICANT_PROFILE(
        tesAppModel.applicantProfileModel
      );
      //Get the Driver license details
      store.dispatch.applicantProfileModule.getDriverLicenseDetails(
        //@ts-ignore
        store.getters.draftProgramInfoModule.getDraftProgramInfo
      );
      //@ts-ignore
      await store.dispatch.applicationReviewModule.loadApplicationReviewModel({
        tesApplicationId: this.tesApplicationModel.applicantionId,
        cycleNumber: this.tesApplicationModel.cycleNumber
      });
      //@ts-ignore
      await store.dispatch.applicationReviewModule.loadApplicationPreviousReviewModel(
        {
          tesApplicationId: this.tesApplicationModel.applicantionId
        }
      );

      //@ts-ignore
      store.commit.stepperHeaderModule.SET_STEPPER_HEADER_MODEL(
        this.tesApplicationModel.stepperHeaders
      );

      store.dispatch.screeningResponseModule.populateScrnRespByApplProfileID(
        tesAppModel.applicantProfileId
      );

      // route to Application review page
      router.push({
        name: "application-review",
        //@ts-ignore
        params: {
          program: this.tesApplicationModel.programEligibilityModel
            .programTypeCode
        },
        query: {
          profileID: String(
            this.tesApplicationModel.draftProgramInfoModel.applicantProfileId
          ), // To DO need to add screening response ID for browser refresh
          applID: String(this.tesApplicationModel.applicantionId)
        }
      });
    },
    forwardBeforeSubmittedApplicationToSummary(
      applicantProfileId: string,
      programTypeCode: string,
      transitAgencyCode: string
    ) {
      let url = TES_APP_ENDPOINT.GET_COMPLETE_PROFILE_DETAILS_BY_APP_PROFILE_ID;
      axios
        .get(url, {
          params: {
            applicantProfileId: applicantProfileId,
            programTypeCode: programTypeCode,
            transitAgencyCode: transitAgencyCode
          }
        })
        .then(response => {
          this.tesApplicationModel = response.data;

          this.tesApplicationModel.programTypeCode = programTypeCode;

          this.forwardToApplicationSummary(this.tesApplicationModel);
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    },

    forwardToProfileSummary(profileStatus: string) {
      router.push({
        name: "reviewProfileInfo"
      });
    },

    getHeaderTabContent(index: number) {
      if (index === 1) {
        //When Create Applications is clicked
        this.tab = 0;
        this.getDashboardOrgContent(0);
      } else {
        //when Review Applications is clicked
        this.reviewHeaderTab = 0;
        this.getCountOfAgencyRecords(0);
      }
    },
    getReviewHeaderContent(index: number) {
      this.getCountOfAgencyRecords(index);
    },
    getRecordsForStatus(index: number, statusTxt: string) {
      this.showProgressBar = true;

      let tabName = "Unassigned";
      if (index === 2) {
        tabName = "Assigned";
      } else if (index === 3) {
        tabName = "AllApps";
      }
      let url = DASHBOARD_ENDPOINT.GET_UNASSIGNED_AGENCY_APPS;
      axios
        .get(url, {
          params: {
            transitAgencyCode: this.userInfo.transitAgencyCode,
            statusCode: statusTxt,
            tabName: tabName,
            userId: this.userInfo.userId
          }
        })
        .then(response => {
          if (response.status === HTTP_STATUS.OK) {
            this.agencyStatusContent = response.data;
          }
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    },

    getCountOfAgencyRecords(index: number) {
      if (index === 0) {
        this.getDashBoardAgingContent();
      } else {
        this.showProgressBar = true;

        this.selectedExpansion = 100;

        let tabName = "Unassigned";

        if (index === 2) {
          tabName = "Assigned";
        } else if (index === 3) {
          tabName = "AllApps";
        }

        let url = DASHBOARD_ENDPOINT.GET_UNASSIGNED_AGENCY_APP_COUNT;
        axios
          .get(url, {
            params: {
              transitAgencyCode: this.userInfo.transitAgencyCode,
              tabName: tabName,
              userId: this.userInfo.userId
            }
          })
          .then(response => {
            if (response.status === HTTP_STATUS.OK) {
              const revStatTabTemp = response.data as IAgencyDashboardTabModel[];
              this.reviewStatusTabs = this.isNoEcolaneAgencyDashBrd
                ? revStatTabTemp.filter(t => t.code !== GPC.PEND_CONF)
                : revStatTabTemp;
            }
          })
          .catch(error => {
            store.dispatch.alertModule.showAlertMessageById(
              error.response.data.id
            );
          })
          .finally(() => {
            this.showProgressBar = false;
          });
      }
    },

    getDashboardOrgContent(index: number) {
      this.showProgressBar = true;

      this.orgDashboardReqModel.userId = this.userInfo.userId;
      this.orgDashboardReqModel.tabId = index;
      this.orgDashboardReqModel.feinHash = this.userInfo.fein;

      let url = DASHBOARD_ENDPOINT.GET_ORG_DASHBOARD_CONTENT;
      axios
        .post(url, this.orgDashboardReqModel)
        .then(response => {
          if (response.status === HTTP_STATUS.OK) {
            this.orgDashboardContent = response.data;
          }
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    },
    getDashBoardAgingContent() {
      this.showProgressBar = true;

      let url = DASHBOARD_ENDPOINT.GET_ALL_AGING_APPS;
      axios
        .post(url, this.userInfo)
        .then(response => {
          if (response.status === HTTP_STATUS.OK) {
            this.agingDashboardContent = response.data;
          }
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    },
    asignApp(appSumObj: IAdvnacedSearchResultObj) {
      this.getAsgneeList(appSumObj);
    },
    getAsgneeList(appSumObj: IAdvnacedSearchResultObj) {
      let url = DASHBOARD_ENDPOINT.GET_ASSIGNEE_LIST;
      axios
        .get(url, {
          params: {
            reviewAgency: appSumObj.reviewAgency
          }
        })
        .then(response => {
          if (response.status === HTTP_STATUS.OK) {
            this.asignees = response.data;
            this.selectedUser = clone(tesUserDefault);
            Vue.set(this.asgnApp, appSumObj.tesApplicationId, true);
          }
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    },
    assignRevToApp(item: IAdvnacedSearchResultObj) {
      this.showProgressBar = true;

      let url = DASHBOARD_ENDPOINT.ASSIGN_APP_USER;
      let asgnUserMdl: IAssignUserModel = clone(assignUserDefault);
      asgnUserMdl.appSumObj = item;
      asgnUserMdl.assigningUser = this.userInfo;
      asgnUserMdl.selectedUser = this.selectedUser;
      axios
        .post(url, asgnUserMdl)
        .then(response => {
          if (response.status === HTTP_STATUS.OK) {
            Vue.set(this.asgnApp, item.tesApplicationId, false);
            if ("updated" === response.data) {
              item.reviewerLoginId = this.selectedUser.loginId;
              item.assignedTo = this.selectedUser.userFullName;
            } else if ("Active Review" === response.data) {
              this.actRev = true;
            } else if ("User not found in TES" === response.data) {
              this.userNotFound = true;
            }
          }
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    }
  }
});
