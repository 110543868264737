
import Vue from "vue";
import store from "@/store";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import tesControlTemplate from "@/components/common/TESControlTemplate.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";
import ExpansionPanelTemplate from "@/components/common/ExpansionPanelTemplate.vue";
import { expansionPanelIconObj } from "@/constants/CommonConstants";
import { GPC } from "@/constants/GPCCode";
import {
  IApplicationNavigation,
  IProgramsList,
  IScreeningResponse
} from "@/types";
import { TES_APP_ENDPOINT } from "@/constants/EndPoints";
import axios from "axios";
import router from "@/router";
import ProgressBar from "@/components/common/ProgressBar.vue";
import routerMixin from "@/mixins/RouterMixin.vue";
import helper from "@/composables/helper";

export default Vue.extend({
  name: "emergency-contact",
  mixins: [fieldEntryMixin, UIFeatureMixinVue, ApplicationMixin, routerMixin],
  components: {
    tesControlTemplate,
    ExpansionPanelTemplate,
    ProgressBar
  },
  data: function() {
    return {
      maskedPrimaryPhone: "" as string,
      expPanelIconObj: expansionPanelIconObj,
      showProgressBar: false
    };
  },
  setup() {
    const { getAriaLabel } = helper();
    return { getAriaLabel };
  },
  computed: {
    applicantProfile(): any {
      //@ts-ignore
      return store.getters.applicantProfileModule.getApplicantProfile;
    },
    screeningResponse(): IScreeningResponse {
      return store.getters.screeningResponseModule.getScreeningResponse;
    },
    emergencyInfoText(): string {
      let emergencyText: string = "";
      //@ts-ignore
      if (this.isOrganizationUser || this.isAgencyUser) {
        //@ts-ignore
        emergencyText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.EMERG_INFO].largeText,
          "the applicant's"
        );
        //@ts-ignore
      } else if (this.isCitizenUser) {
        if (this.screeningResponse.onBehalfOfInd === "Y") {
          //@ts-ignore
          emergencyText = this.buildDynamicText(
            //@ts-ignore
            this.uiFeature[GPC.EMERG_INFO].largeText,
            "the applicant's"
          );
        } else {
          //@ts-ignore
          emergencyText = this.buildDynamicText(
            //@ts-ignore
            this.uiFeature[GPC.EMERG_INFO].largeText,
            "your"
          );
        }
      }
      return emergencyText;
    },
    applicationNavigation: {
      get(): IApplicationNavigation {
        //@ts-ignore
        return store.getters.applicationNavigationModule
          .getApplicationNavigation;
      }
    },
    applicantProgramInfo: {
      get(): IProgramsList {
        //@ts-ignore
        return store.getters.applicantProgramModule.getApplicantProgram;
      }
    }
  },
  methods: {
    saveToStore() {
      //@ts-ignore
      this.applicantProfile.emergencyPrimaryPhone = this.unmaskedPhone(
        this.maskedPrimaryPhone
      );

      //@ts-ignore
      store.commit.applicantProfileModule.SET_APPLICANT_PROFILE(
        this.applicantProfile
      );
    },
    saveEmergencyCnctInfo(navigateToDashboard: boolean) {
      this.showProgressBar = true;
      let url = TES_APP_ENDPOINT.SAVE_MOBILITY_INFO;
      //@ts-ignore
      //this.$refs.refEmergencyContact.saveToStore();
      axios
        .post(url, this.applicantProfile)
        .then(response => {
          //@ts-ignore
          store.commit.applicantProfileModule.SET_APPLICANT_PROFILE(
            response.data.applicantProfile
          );
          //@ts-ignore
          store.commit.programEligModule.SET_PROG_ELIG(
            response.data.programEligModel
          );
          if (navigateToDashboard) {
            //@ts-ignore
            this.forwardToDashboard();
          } else if (
            response.data.programEligModel.preEligiblePrograms.length > 0
          ) {
            //@ts-ignore
            store.commit.applicantProgramModule.SET_APPLICANT_PROGRAM(
              response.data.programEligModel.preEligiblePrograms[0]
            );
            //@ts-ignore
            this.getStepperheaders();
          } else if (this.applicationNavigation.applProfileEdit) {
            router.push({
              path:
                "/application-review/" +
                this.applicantProgramInfo.programTypeCode
            });
          } else {
            store.dispatch.applicantProgramModule.resetData();
            router.push({ name: "reviewProfileInfo" });
          }
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    }
  },
  created() {
    this.maskedPrimaryPhone = this.applicantProfile.emergencyPrimaryPhone;
  }
});
