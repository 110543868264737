
import axios from "axios";
import Vue from "vue";
import { TES_APP_ENDPOINT } from "@/constants/EndPoints";
import router from "@/router";
import store from "@/store";
import { ITESUser, IApplicationReviewModel } from "@/types";
import ProgressBarVue from "@/components/common/ProgressBar.vue";
import ClearStoreValuesMixinVue from "@/mixins/ClearStoreValuesMixin.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";

export default Vue.extend({
  name: "user-account",
  mixins: [
    ClearStoreValuesMixinVue,
    ProgressBarVue,
    UIFeatureMixinVue,
    AppPropsMixin,
    ApplicationMixin
  ],
  data() {
    return {
      assignableRoles: null,
      showProgressBar: false,
      logoutDialog: false
    };
  },
  computed: {
    userInfo: {
      get(): ITESUser {
        //@ts-ignore
        return store.getters.tesUserModule.getTesUser;
      }
    },
    isBPUser(): boolean {
      let isBPUser = false;
      if (
        this.userInfo &&
        this.userInfo.loginId &&
        this.userInfo.loginId.startsWith("b-")
      ) {
        isBPUser = true;
      }
      return isBPUser;
    },
    applicationReviewModel: {
      get(): IApplicationReviewModel {
        return store.getters.applicationReviewModule.getApplicationReviewModel;
      }
    }
  },
  methods: {
    userLogout() {
      this.changeReviewFlag("N");
      this.logoutDialog = false;
      let logoutUrl = TES_APP_ENDPOINT.USER_LOGOUT;
      this.showProgressBar = true;
      axios
        .post(logoutUrl, this.userInfo)
        .then(response => {
          //@ts-ignore
          this.clearAllStoreValues();
          //@ts-ignore
          this.userInfo.isLoggedOut = true;
          router.push({ name: "home" });
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    },
    changeReviewFlag(flag: string) {
      if (
        this.applicationReviewModel &&
        this.applicationReviewModel.reviewerUserId === this.userInfo.userId
      ) {
        this.applicationReviewModel.actReviewFlag = flag;
        let updateReviewFlagUrl = TES_APP_ENDPOINT.UPDATE_REVIEW_FLAG;
        this.showProgressBar = true;
        axios
          .post(updateReviewFlagUrl, this.applicationReviewModel)
          .then(() => {
            //After logout
          })
          .finally(() => {
            this.showProgressBar = false;
          });
      }
    }
  }
});
