
import Vue from "vue";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import MainSection from "@/components/home/MainSection.vue";
import CheckEligibility from "@/components/home/CheckEligibility.vue";
import Welcome from "@/components/home/Welcome.vue";
import ProgressBar from "@/components/common/ProgressBar.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import store from "@/store";
import SystemInfoMessage from "@/components/common/SystemInfoMessage.vue";

export default Vue.extend({
  name: "home",
  mixins: [AppPropsMixin, UIFeatureMixinVue],
  data() {
    return {
      idleLogOut: false as boolean
    };
  },
  components: {
    CheckEligibility,
    MainSection,
    Welcome,
    ProgressBar,
    SystemInfoMessage
  },
  created() {
    let uiFeatureArray =
      "HOME_WELC,HOME_STRT,HOME_RIDE,ABOUT_FUN,ADA,FTP,MATP,PWD,SSR,HOME_INFO,HOME_ELIG,SM_QRYDATA,SM_AUTHRSN,SM_AGNT_NM,PST_PSVDAT,SMENC,SMLOCALE,TARGET,BUTTON1,ESEC_URL,HME_THIS,NCOUNTY_3,CTZ_FGTUSR,CTZ_FGTPWD,BP_FGTUSR,BP_FGTPWD,HOME_DTELG,LOGIN_FAIL,ACC_LOCKED,QUICK_SRCH,ER_INLINE,SHW_LGNSML,CTZURL_QA,BPURL_QA,CTZURL_ROL,CHGLGN_TXT,CRSL_INTVL,FMR,ENVRONMNT,HOME_IMG,SHOW_UNAVA,ALERT_COLR,DIS_LGN_BT,AGING,VTP,RFTP,EDU_URL,SF_LOG_MSG,BRWSR_ERR,ADA_Y,PWDAC_Y,ADAAC_Y,ADA_N,FTP_Y,FTP_N,MATP_Y,MATP_N,PWD_Y,PWD_N,RFTP_Y,RFTP_N,SSR_Y,SSR_N,AGING_Y,AGING_N,VTP_Y,VTP_N,MATP_MSG";

    store.commit.uiFeatureModule.SET_DATA_LDNG_STS(true);
    store.dispatch.uiFeatureModule.getUIFeatures(uiFeatureArray);
    store.dispatch.applicantDetailsModule.resetData();
    store.dispatch.tesUserModule.resetData();
    this.idleLogOut = this.$route.params.idleLogout === "true";
  },
  computed: {
    isDataLoading(): boolean {
      return store.getters.uiFeatureModule.getLoadingStatus;
    },
    showLoginSimulation(): boolean {
      //@ts-ignore
      return this.uiFeature.SHW_LGNSML.largeText === "Y";
    }
  }
});
