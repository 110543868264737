var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"px-0 px-md-auto"},[_c('div',[_c('v-card',{attrs:{"flat":""}},[_c('RFTPProgramInfoReview'),(_vm.draftProgramInfoObj.disabilityInd == 'Y')?_c('PWDGeneralInfoReview',{attrs:{"programName":"RFTP"}}):_vm._e(),(
          _vm.draftProgramInfoObj.disabilityInd == 'Y' &&
            (_vm.applicantProgramInfoObj.transitAgencyCode == 'SEPTA' ||
              _vm.draftProgramInfoObj.rftpProviderAgencyCode == 'SEPTA') &&
            _vm.draftProgramInfoObj.draftPhysicianInfos &&
            _vm.draftProgramInfoObj.draftPhysicianInfos.length > 0
        )?_c('PhysicianInfoReview'):_vm._e(),(!_vm.singleFlow)?_c('AttachmentsReview',{attrs:{"documentTypeCodes":_vm.documentTypeCodes}}):_vm._e(),(!_vm.singleFlow && _vm.showApplRevCtrls)?_c('ApplicationReviewControls',{attrs:{"applicantProgramInfoObj":_vm.applicantProgramInfoObj,"draftProgramInfoObj":_vm.draftProgramInfoObj}}):_vm._e()],1)],1),_c('v-spacer',{staticClass:"pb-4"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }