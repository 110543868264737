
import Vue from "vue";
import { IApplicantProfile, IDraftProgramInfo, IProgramsList } from "@/types";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import DraftProgramMixin from "@/mixins/DraftProgramMixin.vue";
import ProgressBar from "@/components/common/ProgressBar.vue";
import store from "@/store";
import tesContainer from "@/components/common/TESContainer.vue";
import PADrivingLicense from "@/components/application/program/PADrivingLicense.vue";
import StepperNavigationMixinVue from "@/mixins/StepperNavigationMixin.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import InfoSection from "@/components/common/InfoSection.vue";
import ScrollMixin from "@/mixins/ScrollMixin.vue";
import { GPC } from "@/constants/GPCCode";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";
import { CNST_TEXT } from "@/constants/CommonConstants";

export default Vue.extend({
  mixins: [
    fieldEntryMixin,
    DraftProgramMixin,
    StepperNavigationMixinVue,
    UIFeatureMixinVue,
    AppPropsMixin,
    ScrollMixin,
    ApplicationMixin
  ],
  name: "ssr-detail-info",
  props: {
    stepCount: {
      type: Number
    },
    byPassPrgmChk: { type: Boolean, default: false }
  },
  components: {
    tesContainer,
    PADrivingLicense,
    ProgressBar,
    InfoSection
  },
  data() {
    return {
      showProgressBar: false,
      steps: this.stepCount,
      singleFlowFinalStep: false,
      disableSave: true,
      byPassTxtHeader: { type: String, default: "" },
      byPassTxtBody: { type: String, default: "" }
    };
  },
  methods: {
    saveContinueByPass() {
      //@ts-ignore
      if (this.$refs.ssrDetailInfoTemp.validate()) {
        this.saveDraftProgramInfo("SAVECONTBYPASS");
      }
    },
    saveContinue() {
      //@ts-ignore
      if (this.$refs.ssrDetailInfoTemp.validate()) {
        this.saveDraftProgramInfo("SAVECONTINUE");
      }
    },
    save() {
      //@ts-ignore
      if (this.$refs.ssrDetailInfoTemp.validate()) {
        this.saveDraftProgramInfo("SAVE");
      }
    },
    cancel() {
      //@ts-ignore
      if (this.$refs.ssrDetailInfoTemp.validate()) {
        this.saveDraftProgramInfo("CANCEL");
      } else {
        //@ts-ignore
        this.scrollToErrorField(this.$refs.ssrDetailInfoTemp);
      }
    },
    saveDraftProgramInfo(buttonName: string) {
      this.showProgressBar = true;
      let stepperIndex = 1;

      this.draftProgramInfo =
        //@ts-ignore
        store.getters.draftProgramInfoModule.getDraftProgramInfo;

      if (this.draftProgramInfo.ageVerificationMethodCode === "N") {
        this.draftProgramInfo.driverLicenseNumber = "";
        this.draftProgramInfo.driverLicenseVerifyCode = "";
      }
      //@ts-ignore
      this.saveProgramInfo(
        this.draftProgramInfo,
        stepperIndex,
        this.steps,
        buttonName
      )
        //@ts-ignore
        .then(response => {
          this.draftProgramInfo = response.data;
          //@ts-ignore
          store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(
            this.draftProgramInfo
          );
          store.commit.applicantProgramModule.SET_APPLICANT_PROGRAM(
            this.draftProgramInfo.programInfo
          );
          this.$emit("save-completed", buttonName);
        })
        //@ts-ignore
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    },
    changeStepCount(steps: number) {
      this.steps = steps;
      this.$emit("step-count", this.steps);
    },
    changeDisableSave() {
      this.disableSave = true;
      if (
        (this.draftProgramInfo &&
          ((this.draftProgramInfo.ageVerificationMethodCode === "Y" &&
            this.draftProgramInfo.driverLicenseVerifyCode === "VRFD") ||
            this.draftProgramInfo.ageVerificationMethodCode === "N")) ||
        this.applicantProfile.homeAddress.paCountyCode === "02"
      ) {
        this.disableSave = false;
      }
    }
  },
  computed: {
    applicantProgramInfo: {
      get(): IProgramsList {
        //@ts-ignore
        return store.getters.applicantProgramModule.getApplicantProgram;
      }
    },
    applicantProfile: {
      get(): IApplicantProfile {
        //@ts-ignore
        return store.getters.applicantProfileModule.getApplicantProfile;
      }
    },
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        //@ts-ignore
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      },
      set(newValue: IDraftProgramInfo) {
        //@ts-ignore
        store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(newValue);
      }
    },
    ssrInfo(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.SSR_SEC2].largeText,
          CNST_TEXT.THE_APPLICANTS,
          CNST_TEXT.THEY,
          CNST_TEXT.THEIR,
          CNST_TEXT.THEY
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.SSR_SEC2].largeText,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOU,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOU
        );
      }
      return text;
    }
  },
  created() {
    if (
      //@ts-ignore
      this.isSingleFlow()
    ) {
      this.singleFlowFinalStep = true;
      //@ts-ignore
      this.byPassTxtHeader = this.uiFeature.BY_PAS_TXT.displayName.replace(
        "{prgmName}",
        this.applicantProgramInfo.programName
      );
      //@ts-ignore
      this.byPassTxtBody = this.uiFeature.BY_PAS_TXT.largeText.replace(
        "{prgmName}",
        this.applicantProgramInfo.programName
      );
    }
    this.changeDisableSave();
  }
});
