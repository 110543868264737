
import Vue from "vue";
import axios from "axios";
import { TES_APP_ENDPOINT, ECOLANE_ENDPOINT } from "@/constants/EndPoints";
import store from "@/store";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import { GPC } from "@/constants/GPCCode";
import {
  IApplicationReviewModel,
  IProgramsList,
  IApplicantProfile,
  IDraftProgramInfo,
  IISAMUserAttributes,
  IScreeningResponse,
  IApplicantEcolaneProfileModel,
  IReviewResultReasonModel,
  IUIFeatureObjectModel,
  IStgEcolaneCustProfileModel,
  IEcolaneDataUpdate,
  IPostingStgModel,
  IApplReviewResultReasonModel
} from "@/types";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import RouterMixinVue from "@/mixins/RouterMixin.vue";
import ProgressBar from "@/components/common/ProgressBar.vue";
import {
  applicantEcolaneProfileModelDefault,
  createEcolaneRequestModelDefault,
  createEcolaneRequestWrapperDefault,
  postingStgModelDefault,
  updateEcolaneRequestModelDefault,
  updateEcolaneRequestWrapperDefault
} from "@/data/AppData";
import AgencyReviewMixin from "@/mixins/AgencyReviewMixin.vue";
import { clone } from "@/util/clone";
import { AGENCY_REVIEW } from "@/constants/CommonConstants";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import tesControlTemplate from "@/components/common/TESControlTemplate.vue";
import TESRadioControl from "@/components/common/TESRadioControl.vue";
import InfoSection from "@/components/common/InfoSection.vue";
import dateHelperMixin from "@/mixins/dateHelperMixin.vue";
import DateInput from "@/components/common/DateInput.vue";
import CloseButton from "@/components/common/CloseButton.vue";
import ScrollMixin from "@/mixins/ScrollMixin.vue";
import EcolaneDiff from "@/components/common/EcolaneDiff.vue";

export default Vue.extend({
  name: "review-recommendation",
  mixins: [
    UIFeatureMixinVue,
    fieldEntryMixin,
    RouterMixinVue,
    AgencyReviewMixin,
    AppPropsMixin,
    dateHelperMixin,
    ScrollMixin
  ],
  components: {
    ProgressBar,
    tesControlTemplate,
    TESRadioControl,
    InfoSection,
    DateInput,
    CloseButton,
    EcolaneDiff
  },
  data: function() {
    return {
      canCommentsDialog: false,
      showProgressBar: false as boolean,
      reviewReasons: [
        {
          resultReason: "",
          reviewResultCode: "",
          reviewResultReasonId: ""
        }
      ],
      comments: "" as string,
      internalNotes: "" as string,
      otherReasons: [
        {
          resultReason: "",
          reviewResultCode: "OTHER"
        }
      ],
      completeReviewDialog: false,
      completeReviewInd: "" as string,
      createEcolaneRequestModelLocal: clone(createEcolaneRequestModelDefault),
      updateEcolaneRequestModelLocal: clone(updateEcolaneRequestModelDefault),
      applicantEcolaneProfileModelLocal: clone(
        applicantEcolaneProfileModelDefault
      ),
      isEcolaneError: false,
      isEcolaneMailAddrMissingCounty: false,
      createEcolaneRequestWrapper: clone(createEcolaneRequestWrapperDefault),
      updateEcolaneRequestWrapper: clone(updateEcolaneRequestWrapperDefault),
      displayOtherReasons: false as boolean,
      cannedCommentsFilteredList: [],
      noCannedComments: true as boolean,
      tempABCD: "" as string,
      firstNameUpd: false as boolean,
      middleInitUpd: false as boolean,
      emerContNameUpd: false as boolean,
      emerContPhNoUpd: false as boolean,
      ethnicityUpd: false as boolean,
      languageUpd: false as boolean,
      genderUpd: false as boolean,
      socialSecurityNumUpd: false as boolean,
      medicaidNumUpd: false as boolean,
      countyUpd: false as boolean,
      emaiAddrUpd: false as boolean,
      primaryPhUpd: false as boolean,
      secondaryPhUpd: false as boolean,
      disMentalUpd: false as boolean,
      disPhysicalUpd: false as boolean,
      disVisualUpd: false as boolean,
      homeAddrUpd: false as boolean,
      mailAddrUpd: false as boolean,
      ecolaneDataObj: {} as IEcolaneDataUpdate
    };
  },
  created() {
    this.getReasons(this.applicationReviewModel.reviewResultCode);
    ///@ts-ignore
    if (this.selectedRecommendation === "ELIGIB" && this.isLANTA) {
      //@ts-ignore
      this.loadLANTADocs();
    }
    if (
      (this.applicationReviewModel.otherReasons.length == 1 &&
        this.applicationReviewModel.otherReasons[0].applReviewResultReasonId) ||
      this.applicationReviewModel.otherReasons.length > 1
    ) {
      this.displayOtherReasons = true;
      this.applicationReviewModel.otherReasons.forEach(otherReason => {
        this.otherReasons = [];
        let tempObj = { resultReason: "", reviewResultCode: "OTHER" };
        tempObj.resultReason = otherReason.resultReasonOther;
        this.otherReasons.push(tempObj);
      });
    }

    this.loadCheckBoxOptions();
    if (this.applicationReviewModel.reviewResultCode) {
      this.populateCannedComments(this.applicationReviewModel.reviewResultCode);
    }
  },
  methods: {
    shouldDisplayOtherReason() {
      this.displayOtherReasons =
        this.selectedReasons.length > 0 &&
        this.selectedReasons.findIndex(i => i.resultReason === "Other") > -1;
      if (this.displayOtherReasons) {
        this.otherReasons = [
          {
            resultReason: "",
            reviewResultCode: "OTHER"
          }
        ];
      }
    },
    changedRecmndValue(value: string) {
      if (value !== "TM_APR_PCF" && value !== "TMP_APROVE") {
        this.applicationReviewModel.tempApprovalEndDate = "";
      }
      if (value !== "PNDNG") {
        this.applicationReviewModel.followUpDate = "";
      }
      //@ts-ignore
      this.selectedReasons = [];
      this.resetFormValidation();
      if (
        value === "IN_ELIGB" ||
        value === "RETFORCORR" ||
        value === "INCMPLT"
      ) {
        this.getReasons(value);
        //@ts-ignore
      } else if (value === "ELIGIB" && this.isLANTA) {
        //@ts-ignore
        this.loadLANTADocs();
      }
      this.populateCannedComments(value);
    },
    populateCannedComments(value: string) {
      this.cannedCommentsFilteredList = [];
      let abc = this.populateFilteredCannedComments(value);
      for (let i in abc) {
        //@ts-ignore
        this.cannedCommentsFilteredList.push({
          //@ts-ignore
          resourceCode: abc[i].cannedCommentText,
          //@ts-ignore
          displayName: abc[i].transitAgencyCode,
          //@ts-ignore
          largeText: abc[i].cannedCommentText
        });
      }
      this.noCannedComments =
        !this.cannedCommentsFilteredList ||
        this.cannedCommentsFilteredList.length < 1;
      return this.noCannedComments;
    },
    populateFilteredCannedComments(value: string) {
      return this.getCannedCommentsList.filter(
        (o: { commentType: string }) => o.commentType === value
      );
    },
    commentsSelected() {
      this.applicationReviewModel.reviewComment = this.tempABCD;
      this.canCommentsDialog = false;
    },
    displaySubTitle(): boolean {
      return this.applicationReviewModel.reviewComment ? true : false;
    },
    getReasons(reviewResultCode: string) {
      let url = TES_APP_ENDPOINT.GET_REVIEW_REASONS_BY_RESULTCODE;
      axios
        .get(url, {
          params: { reviewResultCode: reviewResultCode }
        })
        .then(response => {
          this.reviewReasons = response.data;
          this.sortReviewReasons();
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        });
    },
    sortReviewReasons() {
      this.reviewReasons.sort(function(a, b) {
        if (a.resultReason > b.resultReason) {
          return 1;
        } else if (a.resultReason < b.resultReason) {
          return -1;
        } else return 0;
      });
      let len = this.reviewReasons.filter(r => r.resultReason === "Other")
        .length;
      if (len > 0) {
        let otherObjTemp = this.reviewReasons.filter(
          r => r.resultReason === "Other"
        )[0];
        let index = this.reviewReasons.findIndex(
          r => r.resultReason === "Other"
        );
        this.reviewReasons.splice(index, 1);
        this.reviewReasons.push(otherObjTemp);
      }
    },
    async saveReview(saveOrComplete: string) {
      //@ts-ignore
      if (this.$refs.reviewRecommendationForm.validate()) {
        //need to make axios call to save application review
        if (saveOrComplete === "COMPLETE_REV_ECO") {
          this.completeReviewDialog = true;
        } else {
          let url = "";
          if (saveOrComplete === "SAVE_REV") {
            url = TES_APP_ENDPOINT.SAVE_REVIEW_RECOMMENDATION;
          } else if (saveOrComplete === "COMPLETE_REV") {
            url = TES_APP_ENDPOINT.COMPLETE_REVIEW;
            await this.completeReview();
          }
          this.saveEcolaneDataUpdate();
          this.showProgressBar = true;
          let hasOtherReasons = this.otherReasons.find(
            (o: { resultReason: string }) => o.resultReason !== ""
          );
          if (hasOtherReasons) {
            let abc = this.reviewReasons.find(
              (o: { resultReason: string }) => o.resultReason === "Other"
            );

            this.applicationReviewModel.otherReasons = [];
            this.otherReasons.forEach(otherReason => {
              let tempObj: IApplReviewResultReasonModel;
              tempObj = {
                applReviewResultReasonId: 0,
                applicationReviewId: this.applicationReviewModel
                  .applicationReviewId,
                //@ts-ignore
                reviewResultReasonId: abc.reviewResultReasonId,
                resultReasonOther: otherReason.resultReason
              };
              this.applicationReviewModel.otherReasons.push(tempObj);
            });
          }
          axios
            .post(url, this.applicationReviewModel)
            .then(response => {
              store.commit.applicationReviewModule.SET_APPLICATION_REVIEW_MODEL(
                response.data
              );
              this.navigateTo(saveOrComplete);
            })
            .catch(error => {
              store.dispatch.alertModule.showAlertMessageById(
                error.response.data.id
              );
            })
            .finally(() => {
              this.showProgressBar = false;
            });
        }
      } else {
        /* TO DO: Need to remove this condition if we need to 
        scroll to error field on complte review as well */
        if (saveOrComplete === "SAVE_REV") {
          //@ts-ignore
          this.scrollToErrorField(this.$refs.reviewRecommendationForm);
        }
      }
    },
    async createEcolaneCustomer() {
      this.showProgressBar = true;
      let url = ECOLANE_ENDPOINT.ECOLANE_CREATE_CUSTOMER;
      this.createEcolaneRequestWrapper.tesEcolaneCreateReqModel = this.createEcolaneRequestModelLocal;
      this.createEcolaneRequestWrapper.agencyCode = this.applicantProgramInfo.ecolaneDatabaseAgency;
      this.createEcolaneRequestWrapper.countyCode = this.applicantProfileInfo.homeAddress.paCountyCode;
      await axios
        .post(url, this.createEcolaneRequestWrapper)
        .then(response => {
          if (
            response.data &&
            response.data.customerNumber &&
            response.data.customerNumber !== ""
          ) {
            if (response.data.customerMailAddrMissingCounty) {
              this.isEcolaneMailAddrMissingCounty = true;
            }
            //call ECOLANE_ENDPOINT.SAVE_ECOLANE_INFO
            this.applicantEcolaneProfileModelLocal.ecolaneCustomerId =
              response.data.customerNumber;
            this.applicantEcolaneProfileModelLocal.directLink =
              response.data.directLink;
            this.applicantEcolaneProfileModelLocal.applicantProfileId = this.applicantProgramInfo.applicantProfileId;
            this.applicantEcolaneProfileModelLocal.transitAgencyCode = this.applicantProgramInfo.ecolaneDatabaseAgency;
            this.saveEcolaneInfo(this.applicantEcolaneProfileModelLocal);
          }
        })
        .catch(error => {
          this.isEcolaneError = true;
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    },
    async updateEcolaneCustomer() {
      this.showProgressBar = true;
      let url = ECOLANE_ENDPOINT.ECOLANE_UPDATE_CUSTOMER;
      this.updateEcolaneRequestWrapper.tesEcolaneUpdateReqModel = this.updateEcolaneRequestModelLocal;
      this.updateEcolaneRequestWrapper.agencyCode = this.applicantProgramInfo.ecolaneDatabaseAgency;
      this.updateEcolaneRequestWrapper.countyCode = this.applicantProfileInfo.homeAddress.paCountyCode;
      this.checkHomeAddress();
      await axios
        .post(url, this.updateEcolaneRequestWrapper)
        .then(response => {
          if (response.data) {
            if (response.data.customerMailAddrMissingCounty) {
              this.isEcolaneMailAddrMissingCounty = true;
            }
          }
        })
        .catch(error => {
          this.isEcolaneError = true;
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    },
    checkHomeAddress() {
      if (
        this.updateEcolaneRequestWrapper.tesEcolaneUpdateReqModel.homeAddress &&
        this.updateEcolaneRequestWrapper.tesEcolaneUpdateReqModel.homeAddress
          .city === "" &&
        this.updateEcolaneRequestWrapper.tesEcolaneUpdateReqModel.homeAddress
          .street === "" &&
        this.updateEcolaneRequestWrapper.tesEcolaneUpdateReqModel.homeAddress
          .streetNumber === ""
      ) {
        this.updateEcolaneRequestWrapper.tesEcolaneUpdateReqModel.homeAddress = null;
      }
    },
    async saveEcolaneInfo(applicantEcolaneProfileModel: any) {
      this.showProgressBar = true;
      let url = ECOLANE_ENDPOINT.SAVE_ECOLANE_INFO;
      await axios
        .post(url, applicantEcolaneProfileModel)
        .then(response => {
          //@ts-ignore
          store.commit.applicantEcolaneProfileModule.SET_APPLICANT_ECOLANE_PROFILE(
            response.data
          );
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.showProgressBar = false;
        });
    },
    returnToDashboard() {
      //@ts-ignore
      this.forwardToDashboard();
    },
    addOtherReason(index: number) {
      this.otherReasons.push({ resultReason: "", reviewResultCode: "OTHER" });
    },
    removeOtherReason(index: number) {
      this.otherReasons.splice(index, 1);
    },
    getText(radio: any): any {
      if (radio.largeText) {
        return radio.largeText;
      } else {
        return radio.displayName;
      }
    },
    cancelEclnConfig() {
      this.resetFormValidation();
      this.completeReviewInd = "";
      this.completeReviewDialog = false;
    },
    resetFormValidation() {
      const reviewRecommendationForm: any = this.$refs.reviewRecommendationForm;
      reviewRecommendationForm.resetValidation();
    },
    getEthnicityDesc(abc: string): string {
      //@ts-ignore
      return this.displayName(abc);
    },
    getLanguageDesc(abc: string): string {
      //@ts-ignore
      return this.displayName(abc);
    },
    populateEcolaneRequestModel() {
      this.createEcolaneRequestModelLocal.firstName = this.applicantProfileInfo.applicantFirstName;
      this.createEcolaneRequestModelLocal.lastName = this.applicantProfileInfo.applicantLastName;
      this.createEcolaneRequestModelLocal.middleInitial = this.applicantProfileInfo.applicantMiddleInitial;
      this.createEcolaneRequestModelLocal.dateOfBirth = this.applicantProfileInfo.applicantDob;
      this.createEcolaneRequestModelLocal.ethnicity = this.getEthnicityDesc(
        this.applicantProfileInfo.ethnicityCode
      );
      this.createEcolaneRequestModelLocal.gender = this.getEcolaneGenderCode(
        this.applicantProfileInfo.applicantGender
      );
      this.createEcolaneRequestModelLocal.languageCode = this.getEcolaneLanguageCode(
        this.applicantProfileInfo.primaryLanguageCode
      );
      if (this.draftProgramInfo.enSSN) {
        this.createEcolaneRequestModelLocal.socialSecurityNumber = this.draftProgramInfo.enSSN;
      }
      if (this.draftProgramInfo.medicaidCardRecipientNum) {
        this.createEcolaneRequestModelLocal.medicaidNumber = this.draftProgramInfo.medicaidCardRecipientNum;
      }
      if (this.applicantProfileInfo.applicantPrimaryPhone) {
        this.createEcolaneRequestModelLocal.phoneLandline = this.applicantProfileInfo.applicantPrimaryPhone; //primary?
      }
      if (this.applicantProfileInfo.applicantSecondaryPhone) {
        this.createEcolaneRequestModelLocal.phoneMobile = this.applicantProfileInfo.applicantSecondaryPhone; //secondary?
      }
      if (this.applicantProfileInfo.applicantEmailAddress) {
        this.createEcolaneRequestModelLocal.email = this.applicantProfileInfo.applicantEmailAddress;
      }
      if (
        this.applicantProfileInfo.emergencyFirstName &&
        this.applicantProfileInfo.emergencyLastName &&
        this.applicantProfileInfo.emergencyRelationship
      ) {
        this.createEcolaneRequestModelLocal.emergencyContactName =
          this.applicantProfileInfo.emergencyFirstName +
          " " +
          this.applicantProfileInfo.emergencyLastName +
          ", " +
          this.applicantProfileInfo.emergencyRelationship;
      }
      if (this.applicantProfileInfo.emergencyPrimaryPhone) {
        this.createEcolaneRequestModelLocal.emergencyContactPhoneDay = this.applicantProfileInfo.emergencyPrimaryPhone;
      }
      if (this.draftProgramInfo.mentalDisabilityInd === "Y") {
        this.createEcolaneRequestModelLocal.disabilityMental = true;
      }
      if (this.draftProgramInfo.mobilityDisabilityInd === "Y") {
        this.createEcolaneRequestModelLocal.disabilityPhysical = true;
      }
      if (this.draftProgramInfo.visualDisabilityInd === "Y") {
        this.createEcolaneRequestModelLocal.disabilityVisual = true;
      }
      // populating address fields.
      this.populateAddress();
    },
    populateAddress() {
      //homeAddress
      const expr1: string = "^d*[a-zA-Z0-9]+";
      let tempAddress = this.applicantProfileInfo.homeAddress.addressLine1.trim();
      let streetNum = new RegExp(expr1).exec(tempAddress);
      if (streetNum) {
        //@ts-ignore
        this.createEcolaneRequestModelLocal.homeAddress.streetNumber =
          streetNum[0];
        let streetAddrText = tempAddress.substring(
          tempAddress.indexOf(" ") + 1
        );
        this.createEcolaneRequestModelLocal.homeAddress.street = streetAddrText;
      }

      if (this.applicantProfileInfo.homeAddress.addressLine2) {
        this.createEcolaneRequestModelLocal.homeAddress.apartmentNumber = this.applicantProfileInfo.homeAddress.addressLine2.trim();
      }
      this.createEcolaneRequestModelLocal.homeAddress.city = this.applicantProfileInfo.homeAddress.city;
      this.createEcolaneRequestModelLocal.homeAddress.county = this.getCountyName(
        this.applicantProfileInfo.homeAddress.paCountyCode
      );
      this.createEcolaneRequestModelLocal.homeAddress.state = this.applicantProfileInfo.homeAddress.state;
      this.createEcolaneRequestModelLocal.homeAddress.postcode = this.applicantProfileInfo.homeAddress.zip;
      //mailingAddress
      if (this.applicantProfileInfo.sameMailingInd === "Y") {
        //@ts-ignore
        this.createEcolaneRequestModelLocal.mailingAddress.streetNumber = this.createEcolaneRequestModelLocal.homeAddress.streetNumber;
        this.createEcolaneRequestModelLocal.mailingAddress.street = this.createEcolaneRequestModelLocal.homeAddress.street;
        this.createEcolaneRequestModelLocal.mailingAddress.city = this.applicantProfileInfo.homeAddress.city;
        this.createEcolaneRequestModelLocal.mailingAddress.county = this.getCountyName(
          this.applicantProfileInfo.homeAddress.paCountyCode
        );
        this.createEcolaneRequestModelLocal.mailingAddress.state = this.applicantProfileInfo.homeAddress.state;
        this.createEcolaneRequestModelLocal.mailingAddress.postcode = this.applicantProfileInfo.homeAddress.zip;
        if (this.applicantProfileInfo.homeAddress.addressLine2) {
          this.createEcolaneRequestModelLocal.mailingAddress.apartmentNumber = this.applicantProfileInfo.homeAddress.addressLine2.trim();
        }
      } else if (
        this.applicantProfileInfo.sameMailingInd === "N" &&
        this.applicantProfileInfo.mailingAddress
      ) {
        tempAddress = this.applicantProfileInfo.mailingAddress.addressLine1.trim();
        streetNum = new RegExp(expr1).exec(tempAddress);
        if (streetNum) {
          //@ts-ignore
          this.createEcolaneRequestModelLocal.mailingAddress.streetNumber =
            streetNum[0];
          let streetAddrText = tempAddress.substring(
            tempAddress.indexOf(" ") + 1
          );
          this.createEcolaneRequestModelLocal.mailingAddress.street = streetAddrText;
        }

        this.createEcolaneRequestModelLocal.mailingAddress.city = this.applicantProfileInfo.mailingAddress.city;
        this.createEcolaneRequestModelLocal.mailingAddress.county = this.getCountyName(
          this.applicantProfileInfo.mailingAddress.paCountyCode
        );
        this.createEcolaneRequestModelLocal.mailingAddress.state = this.applicantProfileInfo.mailingAddress.state;
        this.createEcolaneRequestModelLocal.mailingAddress.postcode = this.applicantProfileInfo.mailingAddress.zip;
        if (this.applicantProfileInfo.mailingAddress.addressLine2) {
          this.createEcolaneRequestModelLocal.mailingAddress.apartmentNumber = this.applicantProfileInfo.mailingAddress.addressLine2.trim();
        }
      }
      //countyName
      this.createEcolaneRequestModelLocal.county = this.getCountyName(
        this.applicantProfileInfo.homeAddress.paCountyCode
      );
    },
    getEcolaneLanguageCode(fmrLanguageCode: string): string {
      switch (fmrLanguageCode) {
        case GPC.ENG:
          return "en";
        case GPC.ARA:
          return "ar";
        case GPC.ZHO:
          return "zh";
        case GPC.FRE:
          return "fr";
        case GPC.DEU:
          return "de";
        case GPC.GUJ:
          return "gu";
        case GPC.HAT:
          return "ht";
        case GPC.ITA:
          return "it";
        case GPC.KOR:
          return "ko";
        case GPC.MKH:
          return "km";
        case GPC.NEP:
          return "ne";
        case GPC.POL:
          return "pl";
        case GPC.POR:
          return "pt";
        case GPC.RUS:
          return "ru";
        case GPC.SPA:
          return "es";
        case GPC.VIE:
          return "vi";
        default:
          return "";
      }
    },
    getEcolaneGenderCode(fmrGenderCode: string): string {
      switch (fmrGenderCode) {
        case GPC.M:
          return GPC.M;
        case GPC.F:
          return GPC.F;
        default:
          return "";
      }
    },
    populateUpdateEcolaneRequestModel() {
      this.updateEcolaneRequestModelLocal.firstName = this.firstNameUpd
        ? this.applicantProfileInfo.applicantFirstName
        : this.stgEcolaneCustProfile.customerFirstName;

      this.updateEcolaneRequestModelLocal.lastName = this.applicantProfileInfo.applicantLastName;
      this.updateEcolaneRequestModelLocal.customerNumber = this.applicantEcolaneProfile.ecolaneCustomerId;
      this.updateEcolaneRequestModelLocal.middleInitial = this.middleInitUpd
        ? this.applicantProfileInfo.applicantMiddleInitial
        : this.stgEcolaneCustProfile.customerMiddleInitial;
      this.updateEcolaneRequestModelLocal.dateOfBirth = this.applicantProfileInfo.applicantDob;

      this.updateEcolaneRequestModelLocal.ethnicity = this.ethnicityUpd
        ? this.getEthnicityDesc(this.applicantProfileInfo.ethnicityCode)
        : this.stgEcolaneCustProfile.ethnicity;

      this.updateEcolaneRequestModelLocal.languageCode = this.languageUpd
        ? this.getEcolaneLanguageCode(
            this.applicantProfileInfo.primaryLanguageCode
          )
        : this.getEcolaneLanguageCode(this.stgEcolaneCustProfile.language);

      this.updateEcolaneRequestModelLocal.gender = this.genderUpd
        ? this.getEcolaneGenderCode(this.applicantProfileInfo.applicantGender)
        : this.getEcolaneGenderCode(this.stgEcolaneCustProfile.gender);

      this.updateEcolaneRequestModelLocal.socialSecurityNumber = this
        .socialSecurityNumUpd
        ? this.draftProgramInfo.enSSN
        : this.stgEcolaneCustProfile.socialSecurityNumber;

      this.updateEcolaneRequestModelLocal.medicaidNumber = this.medicaidNumUpd
        ? this.draftProgramInfo.medicaidCardRecipientNum
        : this.stgEcolaneCustProfile.medicaidNumber;

      this.updateEcolaneRequestModelLocal.phoneLandline = this.primaryPhUpd
        ? this.applicantProfileInfo.applicantPrimaryPhone
        : this.stgEcolaneCustProfile.landlinePhone;

      this.updateEcolaneRequestModelLocal.phoneMobile = this.secondaryPhUpd
        ? this.applicantProfileInfo.applicantSecondaryPhone
        : this.stgEcolaneCustProfile.mobilePhone;

      this.updateEcolaneRequestModelLocal.email = this.emaiAddrUpd
        ? this.applicantProfileInfo.applicantEmailAddress
        : this.stgEcolaneCustProfile.emailAddress;
      if (this.emerContNameUpd) {
        this.updateEcolaneRequestModelLocal.emergencyContactName =
          this.applicantProfileInfo.emergencyFirstName +
          " " +
          this.applicantProfileInfo.emergencyLastName +
          ", " +
          this.applicantProfileInfo.emergencyRelationship;
      } else {
        this.updateEcolaneRequestModelLocal.emergencyContactName = this.stgEcolaneCustProfile.emergencyContactName;
      }

      this.updateEcolaneRequestModelLocal.emergencyContactPhoneDay = this
        .emerContPhNoUpd
        ? this.applicantProfileInfo.emergencyPrimaryPhone
        : this.stgEcolaneCustProfile.emergencyContactPhoneDay;

      this.updateEcolaneRequestModelLocal.county = this.countyUpd
        ? this.getCountyName(this.applicantProfileInfo.homeAddress.paCountyCode)
        : this.stgEcolaneCustProfile.county;

      this.populateMentalDisability();
      this.populatePhysicalDisability();
      this.populateVisualDisability();
      // populating address fields.
      this.populateUpdateEcolaneHomeAddress();
      this.populateUpdateEcolaneMailAddress();
    },
    populateMentalDisability() {
      if (this.disMentalUpd) {
        this.updateEcolaneRequestModelLocal.disabilityMental =
          this.draftProgramInfo.mentalDisabilityInd === "Y";
      } else {
        this.updateEcolaneRequestModelLocal.disabilityMental =
          this.stgEcolaneCustProfile.disabilityMentalInd === "Y";
      }
    },
    populatePhysicalDisability() {
      if (this.disPhysicalUpd) {
        this.updateEcolaneRequestModelLocal.disabilityPhysical =
          this.draftProgramInfo.mobilityDisabilityInd === "Y";
      } else {
        this.updateEcolaneRequestModelLocal.disabilityPhysical =
          this.stgEcolaneCustProfile.disabilityPhysicalInd === "Y";
      }
    },
    populateVisualDisability() {
      if (this.disVisualUpd) {
        this.updateEcolaneRequestModelLocal.disabilityVisual =
          this.draftProgramInfo.visualDisabilityInd === "Y";
      } else {
        this.updateEcolaneRequestModelLocal.disabilityVisual =
          this.stgEcolaneCustProfile.disabilityVisualInd === "Y";
      }
    },
    populateUpdateEcolaneHomeAddress() {
      //homeAddress
      const expr1: string = "^d*[a-zA-Z0-9]+";
      let tempAddress = this.applicantProfileInfo.homeAddress.addressLine1.trim();
      let streetNum = new RegExp(expr1).exec(tempAddress);
      if (this.homeAddrUpd) {
        if (streetNum) {
          //@ts-ignore
          this.updateEcolaneRequestModelLocal.homeAddress.streetNumber =
            streetNum[0];
          let streetAddrText = tempAddress.substring(
            tempAddress.indexOf(" ") + 1
          );
          this.updateEcolaneRequestModelLocal.homeAddress.street = streetAddrText;
        }

        if (this.applicantProfileInfo.homeAddress.addressLine2) {
          this.updateEcolaneRequestModelLocal.homeAddress.apartmentNumber = this.applicantProfileInfo.homeAddress.addressLine2.trim();
        }
        this.updateEcolaneRequestModelLocal.homeAddress.city = this.applicantProfileInfo.homeAddress.city;
        this.updateEcolaneRequestModelLocal.homeAddress.county = this.getCountyName(
          this.applicantProfileInfo.homeAddress.paCountyCode
        );
        this.updateEcolaneRequestModelLocal.homeAddress.state = this.applicantProfileInfo.homeAddress.state;
        this.updateEcolaneRequestModelLocal.homeAddress.postcode = this.applicantProfileInfo.homeAddress.zip;
      } else {
        this.updateEcolaneRequestModelLocal.homeAddress.streetNumber = this.stgEcolaneCustProfile.homeAddressStreetNum;
        this.updateEcolaneRequestModelLocal.homeAddress.street = this.stgEcolaneCustProfile.homeAddressStreet;
        this.updateEcolaneRequestModelLocal.homeAddress.apartmentNumber = this.stgEcolaneCustProfile.homeAddressAptNum;
        this.updateEcolaneRequestModelLocal.homeAddress.city = this.stgEcolaneCustProfile.homeAddressCity;
        this.updateEcolaneRequestModelLocal.homeAddress.county = this.stgEcolaneCustProfile.homeAddressCounty;
        this.updateEcolaneRequestModelLocal.homeAddress.state = this.stgEcolaneCustProfile.homeAddressState;
        this.updateEcolaneRequestModelLocal.homeAddress.postcode = this.stgEcolaneCustProfile.homeAddressZip;
      }
    },
    populateUpdateEcolaneMailAddress() {
      if (this.mailAddrUpd) {
        this.setUpdateEcolaneMailAddress();
      } else {
        this.updateEcolaneRequestModelLocal.mailingAddress.streetNumber = this.stgEcolaneCustProfile.mailAddressStreetNum;
        this.updateEcolaneRequestModelLocal.mailingAddress.street = this.stgEcolaneCustProfile.mailAddressStreet;
        this.updateEcolaneRequestModelLocal.mailingAddress.apartmentNumber = this.stgEcolaneCustProfile.mailAddressAptNum;
        this.updateEcolaneRequestModelLocal.mailingAddress.city = this.stgEcolaneCustProfile.mailAddressCity;
        this.updateEcolaneRequestModelLocal.mailingAddress.county = this.stgEcolaneCustProfile.mailAddressCounty;
        this.updateEcolaneRequestModelLocal.mailingAddress.state = this.stgEcolaneCustProfile.mailAddressState;
        this.updateEcolaneRequestModelLocal.mailingAddress.postcode = this.stgEcolaneCustProfile.mailAddressZip;
      }
    },
    setUpdateEcolaneMailAddress() {
      const expr1: string = "^d*[a-zA-Z0-9]+";
      let tempAddress;
      let streetNum;
      if (this.applicantProfileInfo.sameMailingInd === "Y") {
        tempAddress = this.applicantProfileInfo.homeAddress.addressLine1.trim();
        streetNum = new RegExp(expr1).exec(tempAddress);
        if (streetNum) {
          //@ts-ignore
          this.updateEcolaneRequestModelLocal.mailingAddress.streetNumber =
            streetNum[0];
          let streetAddrText = tempAddress.substring(
            tempAddress.indexOf(" ") + 1
          );
          this.updateEcolaneRequestModelLocal.mailingAddress.street = streetAddrText;
        }
        this.updateEcolaneRequestModelLocal.mailingAddress.city = this.applicantProfileInfo.homeAddress.city;
        this.updateEcolaneRequestModelLocal.mailingAddress.county = this.getCountyName(
          this.applicantProfileInfo.homeAddress.paCountyCode
        );
        this.updateEcolaneRequestModelLocal.mailingAddress.state = this.applicantProfileInfo.homeAddress.state;
        this.updateEcolaneRequestModelLocal.mailingAddress.postcode = this.applicantProfileInfo.homeAddress.zip;
        if (this.applicantProfileInfo.homeAddress.addressLine2) {
          this.updateEcolaneRequestModelLocal.mailingAddress.apartmentNumber = this.applicantProfileInfo.homeAddress.addressLine2.trim();
        }
      } else if (
        this.applicantProfileInfo.sameMailingInd === "N" &&
        this.applicantProfileInfo.mailingAddress
      ) {
        tempAddress = this.applicantProfileInfo.mailingAddress.addressLine1.trim();
        streetNum = new RegExp(expr1).exec(tempAddress);
        if (streetNum) {
          //@ts-ignore
          this.updateEcolaneRequestModelLocal.mailingAddress.streetNumber =
            streetNum[0];
          let streetAddrText = tempAddress.substring(
            tempAddress.indexOf(" ") + 1
          );
          this.updateEcolaneRequestModelLocal.mailingAddress.street = streetAddrText;
        }

        this.updateEcolaneRequestModelLocal.mailingAddress.city = this.applicantProfileInfo.mailingAddress.city;
        this.updateEcolaneRequestModelLocal.mailingAddress.county = this.getCountyName(
          this.applicantProfileInfo.mailingAddress.paCountyCode
        );
        this.updateEcolaneRequestModelLocal.mailingAddress.state = this.applicantProfileInfo.mailingAddress.state;
        this.updateEcolaneRequestModelLocal.mailingAddress.postcode = this.applicantProfileInfo.mailingAddress.zip;
        if (this.applicantProfileInfo.mailingAddress.addressLine2) {
          this.updateEcolaneRequestModelLocal.mailingAddress.apartmentNumber = this.applicantProfileInfo.mailingAddress.addressLine2.trim();
        }
      }
    },
    getCountyName(paCountyCode: string) {
      let countyName = this.counties.find(
        obj =>
          //@ts-ignore
          obj.id === paCountyCode
      );
      //@ts-ignore
      return countyName ? countyName.name : "";
    },
    async completeReview() {
      this.completeReviewDialog = false;
      this.applicationReviewModel.completeReviewFlag = true;
      this.applicationReviewModel.completeReviewInd = this.completeReviewInd;
      this.applicationReviewModel.transitAgencyCode = this.applicantProgramInfo.transitAgencyCode;
      this.applicationReviewModel.actReviewFlag = "N";
      if (
        this.selectedRecommendation === "ELIGIB" ||
        this.selectedRecommendation === "TM_APR_PCF"
      ) {
        await this.processEcolaneBusiness();
      }
      this.applicationReviewModel.programInfo = this.applicantProgramInfo;
      this.applicationReviewModel.applicantProfile = this.applicantProfileInfo;
      if (
        this.screeningResponse &&
        this.screeningResponse.screeningResponseId
      ) {
        this.applicationReviewModel.applicantProfile.screeningResponseId = this.screeningResponse.screeningResponseId;
      }
      let isamUserAttributesJson = JSON.parse(
        JSON.stringify(this.isamUserAttributes)
      );
      if (
        (this.screeningResponse.onBehalfOfInd === "Y" ||
          this.applicantProfileInfo.onBehalfOfInd === "Y") &&
        isamUserAttributesJson &&
        isamUserAttributesJson.attributeDetails
      ) {
        this.applicationReviewModel.applicantProfile.applicantEmailAddress =
          isamUserAttributesJson.attributeDetails["EMAIL"];
      }
    },
    async processEcolaneBusiness() {
      if (
        !this.applicantEcolaneProfile ||
        (this.applicantEcolaneProfile &&
          !this.applicantEcolaneProfile.ecolaneCustomerId)
      ) {
        //populate ecolane request object
        this.populateEcolaneRequestModel();
        //call Ecolane createCustomer
        await this.createEcolaneCustomer();
        if (!this.isEcolaneError) {
          this.applicationReviewModel.ecolaneRecordCreatedFlag = true;
        }
      } else if (
        //@ts-ignore
        this.showDiffSectionMethod &&
        (this.firstNameUpd ||
          this.middleInitUpd ||
          this.emerContNameUpd ||
          this.emerContPhNoUpd ||
          this.ethnicityUpd ||
          this.languageUpd ||
          this.genderUpd ||
          this.socialSecurityNumUpd ||
          this.medicaidNumUpd ||
          this.countyUpd ||
          this.emaiAddrUpd ||
          this.primaryPhUpd ||
          this.secondaryPhUpd ||
          this.disMentalUpd ||
          this.disPhysicalUpd ||
          this.disVisualUpd ||
          this.homeAddrUpd ||
          this.mailAddrUpd)
      ) {
        this.populateUpdateEcolaneRequestModel();
        await this.updateEcolaneCustomer();
      } else {
        this.applicationReviewModel.ecolaneRecordPresentFlag = true;
      }
    },
    navigateTo(saveOrComplete: string) {
      //route to dashboard if ineligibleOrReturn
      if (
        saveOrComplete === "COMPLETE_REV" &&
        (this.ineligibleOrReturn ||
          this.isIncmplt ||
          this.isApprove ||
          this.isPending)
      ) {
        this.returnToDashboard();
      } else if (saveOrComplete === "COMPLETE_REV" && !this.isEcolaneError) {
        //Status changed to PEND_CONF update the status in store to display Review Summary
        this.pendingConfigBusiness();
      }
    },
    pendingConfigBusiness() {
      this.applicantProgramInfo.statusCode = "PEND_CONF";
      //if "Someone else will perform configuration" selected route to dashboard
      if (
        this.completeReviewInd === AGENCY_REVIEW.CMP_RV_OTH ||
        //@ts-ignore
        (!this.completeReviewInd && !this.isAgncyElgbl)
      ) {
        this.returnToDashboard();
        //if ecolane customer record created display success message.
      } else if (this.completeReviewInd === AGENCY_REVIEW.CMP_RV_SLF) {
        if (this.applicationReviewModel.ecolaneRecordCreatedFlag) {
          this.$emit("ecolane-success");
        }
        if (!this.applicationReviewModel.notificationSentFlag) {
          this.$emit("notification-error");
        }
        if (this.isEcolaneMailAddrMissingCounty) {
          this.$emit("ecolane-warn-mail-addr-missing-county");
        }
      }
    },
    checkHelpAtchmtHelpUploaded(): boolean {
      let abc: boolean = false;

      let helpNeededDocs = this.getAtchmtCheckboxSltdByDocTypeList.filter(
        (o: { documentTypeCode: string }) =>
          this.docs.find(
            (obj: { documentTypeCode: string; noUploadInd: string }) =>
              //@ts-ignore
              obj.documentTypeCode === o.documentTypeCode ||
              (o.documentTypeCode === "CST_DSBLTY" &&
                obj.documentTypeCode === "PRF_DSBLTY")
          )
      );

      if (
        helpNeededDocs.length === this.getAtchmtCheckboxSltdByDocTypeList.length
      ) {
        abc = true;
      }
      return abc;
    },
    reqAtchmtsArray() {
      let codes = [];
      for (let checkBoxAtchmt of this.getAtchmtCheckboxSltdByDocTypeList) {
        let objFound = false;
        for (let doc of this.docs) {
          if (
            doc["documentTypeCode"] === checkBoxAtchmt["documentTypeCode"] ||
            (checkBoxAtchmt["documentTypeCode"] === "CST_DSBLTY" &&
              doc["documentTypeCode"] === "PRF_DSBLTY")
          ) {
            objFound = true;
          }
        }
        if (!objFound) {
          //@ts-ignore
          codes.push(this.displayName(checkBoxAtchmt["documentTypeCode"]));
        }
      }
      return codes;
    },
    getCheckBoxValue(abc: string): boolean {
      return this.getEcolaneDataUpdateList.find(
        o => o.ecolaneDataType === abc && o.ecolaneUpdateInd === "Y"
      )
        ? true
        : false;
    },
    saveEcolaneDataUpdate() {
      this.populateFirstNameUpdate();
      this.populateMiddleInitUpdate();
      this.populateEmerContNameUpdate();
      this.populateEmerContPhNoUpdate();
      this.populateEthnicityUpdate();
      this.populateLanguageUpdate();
      this.populateGenderUpdate();
      this.populateSocialSecurityNumUpdate();
      this.populateMedicaidNumUpdate();
      this.populateCountyUpdate();
      this.populateEmaiAddrUpdate();
      this.populatePrimaryPhUpdate();
      this.populateSecondaryPhUpdate();
      this.populateDisMentalUpdate();
      this.populateDisPhysicalUpdate();
      this.populateDisVisualUpdate();
      this.populateHomeAddrLine1();
      this.populateHomeAddrLine2();
      this.populateHomeCity();
      this.populateHomeState();
      this.populateHomeZip();
      this.populateMailAddrLine1();
      this.populateMailAddrLine2();
      this.populateMailCity();
      this.populateMailState();
      this.populateMailZip();

      let abc: IPostingStgModel = clone(postingStgModelDefault);
      if (
        this.getEcolaneDataUpdateList.length == 1 &&
        !this.getEcolaneDataUpdateList[0].ecolaneDataUpdateId
      ) {
        let x: IEcolaneDataUpdate = this.getEcolaneDataUpdateList[0];
        if (!x.ecolaneDataUpdateId) {
          let updateList: IEcolaneDataUpdate[];
          updateList = [];
          //@ts-ignore
          abc.ecolaneDataUpdateModels = updateList;
        }
      } else {
        //@ts-ignore
        abc.ecolaneDataUpdateModels = this.getEcolaneDataUpdateList;
      }
      let url = ECOLANE_ENDPOINT.SAVE_ECOLANE_DATA_UPDATE;
      axios
        .post(url, abc)
        .then(response => {
          //@ts-ignore
          store.commit.applicantEcolaneProfileModule.SET_ECOLANE_DATA_UPDATE_LIST(
            response.data
          );
          store.commit.applicantEcolaneProfileModule.SET_ECOLANE_DATA_PREV_UPDATE_LIST(
            response.data
          );
        })
        .catch(error => {
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        });
    },
    populateFirstNameUpdate() {
      let ecolaneDataObj: IEcolaneDataUpdate;
      //@ts-ignore
      ecolaneDataObj = this.firstNameObj;
      if (ecolaneDataObj && ecolaneDataObj.ecolaneDataUpdateId) {
        if (this.firstNameUpd) {
          ecolaneDataObj.ecolaneUpdateInd = "Y";
        } else {
          ecolaneDataObj.ecolaneUpdateInd = "N";
        }
      }
    },
    populateMiddleInitUpdate() {
      let ecolaneDataObj: IEcolaneDataUpdate;
      //@ts-ignore
      ecolaneDataObj = this.middleInitObj;
      if (ecolaneDataObj && ecolaneDataObj.ecolaneDataUpdateId) {
        if (this.middleInitUpd) {
          ecolaneDataObj.ecolaneUpdateInd = "Y";
        } else {
          ecolaneDataObj.ecolaneUpdateInd = "N";
        }
      }
    },
    populateEmerContNameUpdate() {
      let ecolaneDataObj: IEcolaneDataUpdate;
      //@ts-ignore
      ecolaneDataObj = this.emerContNameObj;
      if (ecolaneDataObj && ecolaneDataObj.ecolaneDataUpdateId) {
        if (this.emerContNameUpd) {
          ecolaneDataObj.ecolaneUpdateInd = "Y";
        } else {
          ecolaneDataObj.ecolaneUpdateInd = "N";
        }
      }
    },
    populateEmerContPhNoUpdate() {
      let ecolaneDataObj: IEcolaneDataUpdate;
      //@ts-ignore
      ecolaneDataObj = this.emerContPhNoObj;
      if (ecolaneDataObj && ecolaneDataObj.ecolaneDataUpdateId) {
        if (this.emerContPhNoUpd) {
          ecolaneDataObj.ecolaneUpdateInd = "Y";
        } else {
          ecolaneDataObj.ecolaneUpdateInd = "N";
        }
      }
    },
    populateEthnicityUpdate() {
      let ecolaneDataObj: IEcolaneDataUpdate;
      //@ts-ignore
      ecolaneDataObj = this.ethnicityObj;
      if (ecolaneDataObj && ecolaneDataObj.ecolaneDataUpdateId) {
        if (this.ethnicityUpd) {
          ecolaneDataObj.ecolaneUpdateInd = "Y";
        } else {
          ecolaneDataObj.ecolaneUpdateInd = "N";
        }
      }
    },
    populateLanguageUpdate() {
      let ecolaneDataObj: IEcolaneDataUpdate;
      //@ts-ignore
      ecolaneDataObj = this.languageObj;
      if (ecolaneDataObj && ecolaneDataObj.ecolaneDataUpdateId) {
        if (this.languageUpd) {
          ecolaneDataObj.ecolaneUpdateInd = "Y";
        } else {
          ecolaneDataObj.ecolaneUpdateInd = "N";
        }
      }
    },
    populateGenderUpdate() {
      let ecolaneDataObj: IEcolaneDataUpdate;
      //@ts-ignore
      ecolaneDataObj = this.genderObj;
      if (ecolaneDataObj && ecolaneDataObj.ecolaneDataUpdateId) {
        if (this.genderUpd) {
          ecolaneDataObj.ecolaneUpdateInd = "Y";
        } else {
          ecolaneDataObj.ecolaneUpdateInd = "N";
        }
      }
    },
    populateSocialSecurityNumUpdate() {
      let ecolaneDataObj: IEcolaneDataUpdate;
      //@ts-ignore
      ecolaneDataObj = this.socialSecurityObj;
      if (ecolaneDataObj && ecolaneDataObj.ecolaneDataUpdateId) {
        if (this.socialSecurityNumUpd) {
          ecolaneDataObj.ecolaneUpdateInd = "Y";
        } else {
          ecolaneDataObj.ecolaneUpdateInd = "N";
        }
      }
    },
    populateMedicaidNumUpdate() {
      let ecolaneDataObj: IEcolaneDataUpdate;
      //@ts-ignore
      ecolaneDataObj = this.medicaidObj;
      if (ecolaneDataObj && ecolaneDataObj.ecolaneDataUpdateId) {
        if (this.medicaidNumUpd) {
          ecolaneDataObj.ecolaneUpdateInd = "Y";
        } else {
          ecolaneDataObj.ecolaneUpdateInd = "N";
        }
      }
    },
    populateCountyUpdate() {
      let ecolaneDataObj: IEcolaneDataUpdate;
      //@ts-ignore
      ecolaneDataObj = this.countyObj;
      if (ecolaneDataObj && ecolaneDataObj.ecolaneDataUpdateId) {
        if (this.countyUpd) {
          ecolaneDataObj.ecolaneUpdateInd = "Y";
        } else {
          ecolaneDataObj.ecolaneUpdateInd = "N";
        }
      }
    },
    populateEmaiAddrUpdate() {
      let ecolaneDataObj: IEcolaneDataUpdate;
      //@ts-ignore
      ecolaneDataObj = this.emaiAddrObj;
      if (ecolaneDataObj && ecolaneDataObj.ecolaneDataUpdateId) {
        if (this.emaiAddrUpd) {
          ecolaneDataObj.ecolaneUpdateInd = "Y";
        } else {
          ecolaneDataObj.ecolaneUpdateInd = "N";
        }
      }
    },
    populatePrimaryPhUpdate() {
      let ecolaneDataObj: IEcolaneDataUpdate;
      //@ts-ignore
      ecolaneDataObj = this.primaryPhObj;
      if (ecolaneDataObj && ecolaneDataObj.ecolaneDataUpdateId) {
        if (this.primaryPhUpd) {
          ecolaneDataObj.ecolaneUpdateInd = "Y";
        } else {
          ecolaneDataObj.ecolaneUpdateInd = "N";
        }
      }
    },
    populateSecondaryPhUpdate() {
      let ecolaneDataObj: IEcolaneDataUpdate;
      //@ts-ignore
      ecolaneDataObj = this.secondaryPhObj;
      if (ecolaneDataObj && ecolaneDataObj.ecolaneDataUpdateId) {
        if (this.secondaryPhUpd) {
          ecolaneDataObj.ecolaneUpdateInd = "Y";
        } else {
          ecolaneDataObj.ecolaneUpdateInd = "N";
        }
      }
    },
    populateDisMentalUpdate() {
      let ecolaneDataObj: IEcolaneDataUpdate;
      //@ts-ignore
      ecolaneDataObj = this.disMentalObj;
      if (ecolaneDataObj && ecolaneDataObj.ecolaneDataUpdateId) {
        if (this.disMentalUpd) {
          ecolaneDataObj.ecolaneUpdateInd = "Y";
        } else {
          ecolaneDataObj.ecolaneUpdateInd = "N";
        }
      }
    },
    populateDisPhysicalUpdate() {
      let ecolaneDataObj: IEcolaneDataUpdate;
      //@ts-ignore
      ecolaneDataObj = this.disPhysicalObj;
      if (ecolaneDataObj && ecolaneDataObj.ecolaneDataUpdateId) {
        if (this.disPhysicalUpd) {
          ecolaneDataObj.ecolaneUpdateInd = "Y";
        } else {
          ecolaneDataObj.ecolaneUpdateInd = "N";
        }
      }
    },
    populateDisVisualUpdate() {
      let ecolaneDataObj: IEcolaneDataUpdate;
      //@ts-ignore
      ecolaneDataObj = this.disVisualObj;
      if (ecolaneDataObj && ecolaneDataObj.ecolaneDataUpdateId) {
        if (this.disVisualUpd) {
          ecolaneDataObj.ecolaneUpdateInd = "Y";
        } else {
          ecolaneDataObj.ecolaneUpdateInd = "N";
        }
      }
    },
    populateHomeAddrLine1() {
      let ecolaneDataObj: IEcolaneDataUpdate;
      //@ts-ignore
      ecolaneDataObj = this.homeAddrLine1Obj;
      if (ecolaneDataObj && ecolaneDataObj.ecolaneDataUpdateId) {
        if (this.homeAddrUpd) {
          ecolaneDataObj.ecolaneUpdateInd = "Y";
        } else {
          ecolaneDataObj.ecolaneUpdateInd = "N";
        }
      }
    },
    populateHomeAddrLine2() {
      let ecolaneDataObj: IEcolaneDataUpdate;
      //@ts-ignore
      ecolaneDataObj = this.homeAddrLine2Obj;
      if (ecolaneDataObj && ecolaneDataObj.ecolaneDataUpdateId) {
        if (this.homeAddrUpd) {
          ecolaneDataObj.ecolaneUpdateInd = "Y";
        } else {
          ecolaneDataObj.ecolaneUpdateInd = "N";
        }
      }
    },
    populateHomeCity() {
      let ecolaneDataObj: IEcolaneDataUpdate;
      //@ts-ignore
      ecolaneDataObj = this.homeCityObj;
      if (ecolaneDataObj && ecolaneDataObj.ecolaneDataUpdateId) {
        if (this.homeAddrUpd) {
          ecolaneDataObj.ecolaneUpdateInd = "Y";
        } else {
          ecolaneDataObj.ecolaneUpdateInd = "N";
        }
      }
    },
    populateHomeState() {
      let ecolaneDataObj: IEcolaneDataUpdate;
      //@ts-ignore
      ecolaneDataObj = this.homeStateObj;
      if (ecolaneDataObj && ecolaneDataObj.ecolaneDataUpdateId) {
        if (this.homeAddrUpd) {
          ecolaneDataObj.ecolaneUpdateInd = "Y";
        } else {
          ecolaneDataObj.ecolaneUpdateInd = "N";
        }
      }
    },
    populateHomeZip() {
      let ecolaneDataObj: IEcolaneDataUpdate;
      //@ts-ignore
      ecolaneDataObj = this.homeZipObj;
      if (ecolaneDataObj && ecolaneDataObj.ecolaneDataUpdateId) {
        if (this.homeAddrUpd) {
          ecolaneDataObj.ecolaneUpdateInd = "Y";
        } else {
          ecolaneDataObj.ecolaneUpdateInd = "N";
        }
      }
    },
    populateMailAddrLine1() {
      let ecolaneDataObj: IEcolaneDataUpdate;
      //@ts-ignore
      ecolaneDataObj = this.mailAddrLine1Obj;
      if (ecolaneDataObj && ecolaneDataObj.ecolaneDataUpdateId) {
        if (this.mailAddrUpd) {
          ecolaneDataObj.ecolaneUpdateInd = "Y";
        } else {
          ecolaneDataObj.ecolaneUpdateInd = "N";
        }
      }
    },
    populateMailAddrLine2() {
      let ecolaneDataObj: IEcolaneDataUpdate;
      //@ts-ignore
      ecolaneDataObj = this.mailAddrLine2Obj;
      if (ecolaneDataObj && ecolaneDataObj.ecolaneDataUpdateId) {
        if (this.mailAddrUpd) {
          ecolaneDataObj.ecolaneUpdateInd = "Y";
        } else {
          ecolaneDataObj.ecolaneUpdateInd = "N";
        }
      }
    },
    populateMailCity() {
      let ecolaneDataObj: IEcolaneDataUpdate;
      //@ts-ignore
      ecolaneDataObj = this.mailCityObj;
      if (ecolaneDataObj && ecolaneDataObj.ecolaneDataUpdateId) {
        if (this.mailAddrUpd) {
          ecolaneDataObj.ecolaneUpdateInd = "Y";
        } else {
          ecolaneDataObj.ecolaneUpdateInd = "N";
        }
      }
    },
    populateMailState() {
      let ecolaneDataObj: IEcolaneDataUpdate;
      //@ts-ignore
      ecolaneDataObj = this.mailStateObj;
      if (ecolaneDataObj && ecolaneDataObj.ecolaneDataUpdateId) {
        if (this.mailAddrUpd) {
          ecolaneDataObj.ecolaneUpdateInd = "Y";
        } else {
          ecolaneDataObj.ecolaneUpdateInd = "N";
        }
      }
    },
    populateMailZip() {
      let ecolaneDataObj: IEcolaneDataUpdate;
      //@ts-ignore
      ecolaneDataObj = this.mailZipObj;
      if (ecolaneDataObj && ecolaneDataObj.ecolaneDataUpdateId) {
        if (this.mailAddrUpd) {
          ecolaneDataObj.ecolaneUpdateInd = "Y";
        } else {
          ecolaneDataObj.ecolaneUpdateInd = "N";
        }
      }
    },
    loadCheckBoxOptions() {
      this.firstNameUpd = this.getCheckBoxValue("FIRST_NAME");
      this.middleInitUpd = this.getCheckBoxValue("MIDDLE_INIT");
      this.emerContNameUpd = this.getCheckBoxValue("EMERG_CONT_NAME");
      this.emerContPhNoUpd = this.getCheckBoxValue("EMERG_CONT_PHNE");
      this.ethnicityUpd = this.getCheckBoxValue("ETHNICITY");
      this.languageUpd = this.getCheckBoxValue("LANGUAGE");
      this.genderUpd = this.getCheckBoxValue("GENDER");
      this.socialSecurityNumUpd = this.getCheckBoxValue(
        "SOCIAL_SECURITY_NUMBER"
      );
      this.medicaidNumUpd = this.getCheckBoxValue("MEDICAID_NUMBER");
      this.countyUpd = this.getCheckBoxValue("COUNTY");
      this.emaiAddrUpd = this.getCheckBoxValue("EMAIL_ADDR");
      this.primaryPhUpd = this.getCheckBoxValue("PRIM_PHNE");
      this.secondaryPhUpd = this.getCheckBoxValue("SEC_PHNE");
      this.disMentalUpd = this.getCheckBoxValue("DIS_MENTAL");
      this.disPhysicalUpd = this.getCheckBoxValue("DIS_PHYSCL");
      this.disVisualUpd = this.getCheckBoxValue("DIS_VISUAL");
      this.homeAddrUpd = this.getCheckBoxValue("HOME_ADDRL1");
      this.mailAddrUpd = this.getCheckBoxValue("MAIL_ADDRL1");
    }
  },
  computed: {
    applicantProfileInfo(): IApplicantProfile {
      return store.getters.applicantProfileModule.getApplicantProfile;
    },
    recommendations(): any {
      //@ts-ignore
      return this.uiFeature.RVW_RCMNDS.children;
    },
    noEcolaneRecommendations(): IUIFeatureObjectModel[] {
      let tempRecommendations = this.recommendations.filter(
        (rcmd: IUIFeatureObjectModel) => rcmd.largeText !== "PEND_CONF"
      );
      let filteredRecommendations: IUIFeatureObjectModel[] = [];
      if (this.applicantProgramInfo.returnRestrictInd === "Y") {
        filteredRecommendations = tempRecommendations.filter(
          (item: IUIFeatureObjectModel) => item.largeText !== "RE_COR"
        );
      }
      return filteredRecommendations.length > 0
        ? filteredRecommendations
        : tempRecommendations;
    },
    ecolaneRecommendations(): IUIFeatureObjectModel[] {
      let tempRcmds = this.recommendations.filter(
        (rcmd: IUIFeatureObjectModel) =>
          rcmd.largeText !== "APPROVED" && rcmd.largeText !== "TMP_APRVD"
      );
      let filteredRcmds: IUIFeatureObjectModel[] = [];
      if (this.recommendations.length > 0) {
        if (this.applicantProgramInfo.returnRestrictInd === "Y") {
          filteredRcmds = tempRcmds.filter(
            (item: IUIFeatureObjectModel) => item.largeText !== "RE_COR"
          );
        }
      }
      return filteredRcmds.length > 0 ? filteredRcmds : tempRcmds;
    },
    applicationReviewModel: {
      get(): IApplicationReviewModel {
        return store.getters.applicationReviewModule.getApplicationReviewModel;
      },
      set(newObject: IApplicationReviewModel) {
        store.commit.applicationReviewModule.SET_APPLICATION_REVIEW_MODEL(
          newObject
        );
      }
    },
    applicationReviewModelPrevious: {
      get(): IApplicationReviewModel {
        return store.getters.applicationReviewModule
          .getApplicationPreviousReviewModel;
      }
    },
    selectedReasons: {
      get(): IReviewResultReasonModel[] {
        return this.applicationReviewModel.reasons;
      },
      set(newReasons: IReviewResultReasonModel[]) {
        store.commit.applicationReviewModule.SET_APPLICATION_REVIEW_REASONS(
          newReasons
        );
      }
    },
    selectedRecommendation: {
      get(): string {
        return this.applicationReviewModel.reviewResultCode;
      },
      set(newRecmnd: string) {
        store.commit.applicationReviewModule.SET_APPLICATION_REVIEW_RECOMMENDATION(
          newRecmnd
        );
      }
    },
    ineligibleOrReturn(): boolean {
      let isInelgOrRtn = false;
      if (
        this.selectedRecommendation &&
        (this.selectedRecommendation === "IN_ELIGB" ||
          this.selectedRecommendation === "INCMPLT" ||
          this.selectedRecommendation === "RETFORCORR")
      ) {
        isInelgOrRtn = true;
      }
      return isInelgOrRtn;
    },
    isTempAprvdOrTempAprvdpendConfig(): boolean {
      return (
        (this.selectedRecommendation &&
          this.selectedRecommendation === "TM_APR_PCF") ||
        this.selectedRecommendation === "TMP_APROVE"
      );
    },
    isPending(): boolean {
      return (
        this.selectedRecommendation !== null &&
        this.selectedRecommendation === "PNDNG"
      );
    },
    isIncmplt(): boolean {
      return (
        this.selectedRecommendation !== null &&
        this.selectedRecommendation === "INCMPLT"
      );
    },
    isApprove(): boolean {
      let isApprove = false;
      if (
        this.selectedRecommendation &&
        (this.selectedRecommendation === "APPROVE" ||
          this.selectedRecommendation === "TMP_APROVE")
      ) {
        isApprove = true;
      }
      return isApprove;
    },
    radioOptions(): any {
      let radioOptions: any;
      //@ts-ignore
      radioOptions = this.uiFeature.ECL_CFG_RD.children;
      return radioOptions;
    },
    applicantProgramInfo: {
      get(): IProgramsList {
        //@ts-ignore
        return store.getters.applicantProgramModule.getApplicantProgram;
      },
      set(newObject: IProgramsList) {
        store.commit.applicantProgramModule.SET_APPLICANT_PROGRAM(newObject);
      }
    },
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        //@ts-ignore
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      }
    },
    applicantEcolaneProfile: {
      get(): IApplicantEcolaneProfileModel {
        return store.getters.applicantEcolaneProfileModule
          .getApplicantEcolaneProfile;
      },
      set(newObject: IApplicantEcolaneProfileModel) {
        store.commit.applicantEcolaneProfileModule.SET_APPLICANT_ECOLANE_PROFILE(
          newObject
        );
      }
    },
    counties(): [] {
      //@ts-ignore
      return store.getters.dataModule.getCounties;
    },
    isLANTADocsLoading(): boolean {
      return store.getters.attachmentsModule.getDocListLoadingStatus;
    },
    lantaDocs: {
      get(): any {
        //@ts-ignore
        return store.getters.attachmentsModule
          .getReviewrDocsByReviewIdAgencyAndDocType;
      },
      set(newValue: any) {
        //@ts-ignore
        store.commit.attachmentsModule.SET_REVR_DOCS_BY_AGENCY_AND_DOCTYPE(
          newValue
        );
      }
    },
    isLANTAValidationFailed(): boolean {
      return (
        (this.selectedRecommendation === "TM_APR_PCF" ||
          this.selectedRecommendation === "ELIGIB") &&
        //@ts-ignore
        (this.isADA || this.isPWD) &&
        //@ts-ignore
        !this.isNoEcolaneAgency &&
        //@ts-ignore
        this.isLANTA &&
        !this.lantaDocs.length
      );
    },
    isAtchmtValidationFailed(): boolean {
      return (
        (this.selectedRecommendation === "APPROVE" ||
          this.selectedRecommendation === "ELIGIB" ||
          this.selectedRecommendation === "TMP_APROVE" ||
          this.selectedRecommendation === "TM_APR_PCF") &&
        !this.checkHelpAtchmtHelpUploaded() &&
        this.getAtchmtCheckboxSltdByDocTypeList.length > 0
      );
    },
    errorMessage() {
      let msg = "";
      let codes = [];
      if (
        this.isAtchmtValidationFailed &&
        JSON.stringify(this.getAtchmtCheckboxSltdByDocTypeList) !=
          JSON.stringify(this.docs)
      ) {
        codes = this.reqAtchmtsArray();
        msg = "Application cannot be approved without uploading the required";
        msg = `${msg} ${codes.join(" and ")} documents`;
      }
      return msg;
    },
    docs() {
      return store.getters.attachmentsModule.getReviewerDocs;
    },
    getAtchmtCheckboxSltdByDocTypeList(): [] {
      return store.getters.attachmentsModule.getAtchmtCheckboxSltdByDocTypeList;
    },
    isamUserAttributes: {
      get(): IISAMUserAttributes {
        //@ts-ignore
        return store.getters.isamUserAttributesModule.getISAMUserAttributes;
      }
    },
    screeningResponse: {
      get(): IScreeningResponse {
        return store.getters.screeningResponseModule.getScreeningResponse;
      }
    },
    getCannedCommentsList() {
      return store.getters.allTransitAgencyDataModule.getCannedCommentsList;
    },
    stgEcolaneCustProfile: {
      get(): IStgEcolaneCustProfileModel {
        return store.getters.applicantEcolaneProfileModule
          .getStgEcolaneCustProfile;
      }
    },
    getEcolaneDataUpdateList: {
      get(): IEcolaneDataUpdate[] {
        return store.getters.applicantEcolaneProfileModule
          .getEcolaneDataUpdateList;
      }
    },
    isEmptyComments(): boolean {
      return this.populateCannedComments(
        this.applicationReviewModel.reviewResultCode
      );
    }
  },
  watch: {
    getEcolaneDataUpdateList: {
      deep: true,
      handler(newArr: IEcolaneDataUpdate[]) {
        if (newArr.length > 0) {
          this.loadCheckBoxOptions();
        }
      }
    }
  }
});
