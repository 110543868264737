
import Vue from "vue";
import axios from "axios";
import store from "@/store";
import {
  IApplicantProfile,
  IDraftProgramInfo,
  IProgramsList,
  ITESUser,
  IApplicationNavigation,
  IProgramEligModel,
  IStepperHeaderList,
  IApplicationStepperHeaderModel,
  IScreeningResponse,
  IsubmitReqModel,
  IPromiseSearchModel
} from "@/types";
import ProgressBar from "@/components/common/ProgressBar.vue";
import { TES_APP_ENDPOINT } from "@/constants/EndPoints";
import ApplicantProfileMixin from "@/mixins/ApplicantProfileMixin.vue";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import routerMixin from "@/mixins/RouterMixin.vue";
import StepperNavigationMixinVue from "@/mixins/StepperNavigationMixin.vue";
import {
  USER_GROUPS,
  PROGRAM_NAMES,
  APPL_STATUS,
  CNST_TEXT
} from "@/constants/CommonConstants";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import CloseButton from "@/components/common/CloseButton.vue";
import AccessibilityMixin from "@/mixins/AccessibilityMixin.vue";
import DateInput from "@/components/common/DateInput.vue";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import DynamicTextMixin from "@/mixins/DynamicTextMixin.vue";
import TESRadioControl from "@/components/common/TESRadioControl.vue";
import { GPC } from "@/constants/GPCCode";
import { clone } from "@/util/clone";
import router from "@/router";

export default Vue.extend({
  name: "Submit-Application",
  mixins: [
    ApplicantProfileMixin,
    AppPropsMixin,
    routerMixin,
    StepperNavigationMixinVue,
    UIFeatureMixinVue,
    AccessibilityMixin,
    fieldEntryMixin,
    DynamicTextMixin
  ],
  components: {
    ProgressBar,
    CloseButton,
    DateInput,
    TESRadioControl
  },
  data() {
    return {
      submitApplDialog: false,
      checkBoxClick: false,
      authCheck: false,
      showProgressBar: false,
      sbmtButtonClicked: false,
      appointAns: "" as string,
      appointmentDate: "" as string,
      matpDialog: false as boolean,
      matpDialogMessage: "Due to changes in the MATP application, you will be directed back to the application to complete the additional information prior to being able to submit the application(s)." as string,
      hasMATP: [] as IProgramsList[],
      submitReqModel: {} as IsubmitReqModel,
      promiseSrchModel: {} as IPromiseSearchModel
    };
  },
  props: {
    applicationReview: { type: Boolean, default: true }
  },
  computed: {
    userInfo(): ITESUser {
      return store.getters.tesUserModule.getTesUser;
    },
    applicantProfile(): IApplicantProfile {
      return store.getters.applicantProfileModule.getApplicantProfile;
    },
    stepperHeaderModel(): IStepperHeaderList {
      return store.getters.stepperHeaderModule.getStepperHeaderModel;
    },
    subMitConfirmMsg(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrgAgyCtznonBehalfOfUser()
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.SUB_MSG].largeText,
          CNST_TEXT.THIS,
          " for the applicant and I certify that the applicant has authorized me to release this information"
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.SUB_MSG].largeText,
          CNST_TEXT.MY,
          ""
        );
      }
      return text;
    },
    upcomingApptQuestion(): string {
      let question: string = "";
      if (
        //@ts-ignore
        this.isOrgAgyCtznonBehalfOfUser()
      ) {
        //@ts-ignore
        question = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.UPCMGAPTS].largeText,
          CNST_TEXT.DOES_THE_APPLICANT
        );
      } else {
        //@ts-ignore
        question = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.UPCMGAPTS].largeText,
          CNST_TEXT.DO_YOU
        );
      }
      return question;
    },
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      },
      set(newValue: IDraftProgramInfo) {
        store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(newValue);
      }
    },
    applicantProgramInfo(): IProgramsList {
      return store.getters.applicantProgramModule.getApplicantProgram;
    },
    applicationNavigation(): IApplicationNavigation {
      return store.getters.applicationNavigationModule.getApplicationNavigation;
    },
    programEligModel(): IProgramEligModel {
      return store.getters.programEligModule.getProgramElig;
    },
    preEligiblePrograms(): IProgramsList[] {
      return store.getters.programEligModule.getProgramElig.preEligiblePrograms;
    },
    eligiblePrograms(): IProgramsList[] {
      return store.getters.programEligModule.getProgramElig.eligiblePrograms;
    },
    addAppointmentQues(): boolean {
      let isEligibleProgramType;
      //@ts-ignore
      if (this.isSingleFlow()) {
        isEligibleProgramType = this.preEligiblePrograms.find(
          (o: { programTypeCode: string; statusCode: string }) =>
            o.programTypeCode !== PROGRAM_NAMES.FTP &&
            o.programTypeCode !== PROGRAM_NAMES.RFTP &&
            (o.statusCode === APPL_STATUS.READY_TO_SUMBMIT ||
              o.statusCode === APPL_STATUS.READY_TO_SUMBMIT_C)
        );
      } else {
        isEligibleProgramType =
          this.applicantProgramInfo.programTypeCode !== PROGRAM_NAMES.FTP &&
          this.applicantProgramInfo.programTypeCode !== PROGRAM_NAMES.RFTP &&
          (this.applicantProgramInfo.statusCode ===
            APPL_STATUS.READY_TO_SUMBMIT ||
            this.applicantProgramInfo.statusCode ===
              APPL_STATUS.READY_TO_SUMBMIT_C);
      }
      return isEligibleProgramType ? true : false;
    },
    renderCndSbmt(): boolean {
      //@ts-ignore
      return (
        this.applicantProfile.createdByUserId === this.userInfo.userId ||
        (this.applicantProgramInfo.transiAgencyName &&
          this.userInfo.userGroups &&
          this.userInfo.userGroups === USER_GROUPS.PD_TES_AGREVIEWER &&
          this.applicantProgramInfo.transiAgencyName ===
            this.userInfo.transitAgencyCode)
      );
    },
    radioOptions(): any {
      //@ts-ignore
      return this.uiFeature.RADIO_YN.children;
    },
    screeningResponse: {
      get(): IScreeningResponse {
        //@ts-ignore
        return store.getters.screeningResponseModule.getScreeningResponse;
      }
    }
  },
  methods: {
    GotoDashboard() {
      //@ts-ignore
      this.forwardToDashboard();
    },
    submitApplicationDialog() {
      this.showExistUnsubmittedDialog();
    },
    showExistUnsubmittedDialog(): void {
      //@ts-ignore
      let isMATPExist = this.isSingleFlow()
        ? this.checkMatp()
        : this.applicantProgramInfo.programTypeCode === "MATP";
      if (isMATPExist && !this.draftProgramInfo.dhsAuthInd) {
        this.matpDialog = true;
      } else {
        this.submitApplDialog = true;
      }
    },
    checkMatp(): boolean {
      this.hasMATP = this.programEligModel.preEligiblePrograms.filter(
        (o: IProgramsList) => o.programTypeCode === PROGRAM_NAMES.MATP
      );
      return this.hasMATP.length > 0;
    },
    modifyStepper() {
      this.stepperHeaderModel.stepperHeaders[
        this.stepperHeaderModel.stepperHeaders.length - 1
      ].isCurrentStep = false;
      this.stepperHeaderModel.stepperHeaders.forEach(
        (step: IApplicationStepperHeaderModel) => {
          if (step.programType === "MATP") {
            step.isCurrentStep = true;
          }
        }
      );
    },
    okayClick() {
      //@ts-ignore
      if (this.isSingleFlow()) {
        this.modifyStepper();
      }
      router.push({
        path: "/application-program/MATP",
        query: {
          profileID: String(this.applicantProfile.applicantProfileId),
          prgmType: "MATP",
          //@ts-ignore
          agencyType: this.isSingleFlow()
            ? this.hasMATP[0].transitAgencyCode
            : this.applicantProgramInfo.transitAgencyCode
        }
      });
    },
    closeSubmitApplicationDialog() {
      this.submitApplDialog = false;
    },
    validateSubmit() {
      if (
        this.checkBoxClick &&
        ((this.addAppointmentQues &&
          (this.appointAns === "N" ||
            (this.appointAns === "Y" &&
              this.appointmentDate &&
              //@ts-ignore
              this.$refs.formDateVal.validate()))) ||
          !this.addAppointmentQues)
      ) {
        this.authCheck = true;
      } else {
        this.authCheck = false;
      }
    },
    resetAppointmentDate() {
      if (this.appointAns === "N") {
        this.appointmentDate = "";
      }
    },
    resetAppointmentFields() {
      this.appointmentDate = "";
      this.appointAns = "";
      this.checkBoxClick = false;
    },
    getFormattedDOBForPrms(dob: string) {
      //@ts-ignore
      return this.formatDateMixinForPromise(dob);
    },
    populateRequestObject() {
      this.promiseSrchModel.profileID = this.applicantProfile.applicantProfileId;
      this.promiseSrchModel.firstName = this.applicantProfile.applicantFirstName;
      this.promiseSrchModel.lastName = this.applicantProfile.applicantLastName;
      this.promiseSrchModel.dob = this.getFormattedDOBForPrms(
        this.applicantProfile.applicantDob
      );
    },
    submitApplication() {
      this.showProgressBar = true;
      this.sbmtButtonClicked = true;

      this.draftProgramInfo.programInfoList = this.programEligModel.preEligiblePrograms;
      this.draftProgramInfo.applicantName = [
        this.applicantProfile.applicantFirstName,
        this.applicantProfile.applicantMiddleInitial,
        this.applicantProfile.applicantLastName
      ]
        .filter(Boolean)
        .join(" ");

      //@ts-ignore
      if (!this.isSingleFlow() || !this.draftProgramInfo.programInfoList) {
        this.draftProgramInfo.programInfoList = [];
        this.draftProgramInfo.programInfoList.push(this.applicantProgramInfo);
        if (
          this.draftProgramInfo.transitIdCardApplyInd &&
          this.draftProgramInfo.transitIdCardApplyInd === "Y"
        ) {
          let temp: IProgramsList;
          temp = clone(this.applicantProgramInfo);
          temp.transitAgencyCode = "SEPTA";
          temp.ecolaneConfigAgncy = "SEPTA";
          temp.ecolaneDatabaseAgency = "SEPTA";
          temp.transiAgencyName =
            "Southeastern Pennsylvania Transportation Authority";
          temp.reviewAgency = "SEPTA";
          this.draftProgramInfo.programInfoList.push(temp);
        }
      }

      this.draftProgramInfo.programStatus = "SBMTD";
      this.draftProgramInfo.applicantUserId = this.applicantProfile.applicantUserId;
      this.draftProgramInfo.userInfo = this.userInfo;
      this.draftProgramInfo.onBehalfOfInd = this.applicantProfile.onBehalfOfInd;
      if (this.applicantProfile.screeningResponseId) {
        this.draftProgramInfo.screeningResponseId = this.applicantProfile.screeningResponseId;
      }
      this.draftProgramInfo.upcomingAptInd = this.appointAns;
      this.draftProgramInfo.upcomingAptDate = this.appointmentDate;
      this.submitReqModel.draftProgramInfoModel = this.draftProgramInfo;
      if (
        this.screeningResponse.medicaidInd &&
        this.screeningResponse.medicaidInd === "No"
      ) {
        this.populateRequestObject();
        this.submitReqModel.promiseSearchModel = this.promiseSrchModel;
      }
      axios
        .post(TES_APP_ENDPOINT.SUBMIT_APPLICATION, this.submitReqModel)
        .then(response => {
          store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(
            response.data
          );
          this.applicationNavigation.submitAppl = true;
          store.commit.applicationNavigationModule.SET_APPLLICATION_NAVIGATION(
            this.applicationNavigation
          );
          this.GotoDashboard();
        })
        .catch(error => {
          this.authCheck = false;
          this.closeSubmitApplicationDialog();
          store.dispatch.alertModule.showAlertMessageById(
            error.response.data.id
          );
        })
        .finally(() => {
          this.sbmtButtonClicked = false;
          this.showProgressBar = false;
        });
    }
  },
  watch: {
    submitApplDialog: {
      immediate: true,
      handler: function(newValue) {
        if (newValue) {
          this.$nextTick(() => {
            //@ts-ignore
            this.setAriaAttribute(this.$refs.submitApplicationDialog);
          });
        } else {
          this.resetAppointmentFields();
        }
      }
    },
    matpDialog: {
      immediate: true,
      handler: function(newValue) {
        if (newValue) {
          this.$nextTick(() => {
            //@ts-ignore
            this.setAriaAttribute(this.$refs.matpDialogRef);
          });
        }
      }
    },
    appointAns: {
      immediate: true,
      handler(newValue: string) {
        if (newValue) {
          this.validateSubmit();
          this.resetAppointmentDate();
        }
      }
    },
    appointmentDate: {
      immediate: true,
      handler(newValue: string) {
        if (newValue) {
          this.validateSubmit();
        }
      }
    },
    uiFeature: {
      deep: true,
      handler(obj: Object) {
        //@ts-ignore
        if (obj["MEDAST_RGS"]) {
          //@ts-ignore
          this.loadMATPDynamicGPCText();
          //@ts-ignore
          this.loadTranspDynamicGPCText();
        }
      }
    }
  }
});
