
import Vue from "vue";
import store from "@/store";
import PWDDetailInfo from "@/components/application/program/pwd/PWDDetailInfo.vue";
import PWDProgramInfo from "@/components/application/program/pwd/PWDProgramInfo.vue";
import { IApplicantProfile, IDraftProgramInfo, IProgramsList } from "@/types";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import tesProgramTemplate from "@/components/common/TESProgramTemplate.vue";
import Attachments from "@/components/application/program/Attachments.vue";
import router from "@/router";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import StepperNavigationMixinVue from "@/mixins/StepperNavigationMixin.vue";
import { BUTTON_NAMES, CNST_TEXT } from "@/constants/CommonConstants";
import routerMixin from "@/mixins/RouterMixin.vue";
import AttachmentsMixinVue from "@/mixins/AttachmentsMixin.vue";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";
import { GPC } from "@/constants/GPCCode";

export default Vue.extend({
  name: "program-pwd",
  mixins: [
    fieldEntryMixin,
    UIFeatureMixinVue,
    StepperNavigationMixinVue,
    routerMixin,
    AttachmentsMixinVue,
    ApplicationMixin
  ],
  components: {
    tesProgramTemplate,
    PWDProgramInfo,
    PWDDetailInfo,
    Attachments
  },
  data: function() {
    return {
      stepCount: 3 as number,
      documentTypeCodes: [] as string[],
      customDisability: false,
      docText: "" as string,
      step: 1
    };
  },
  props: {
    agencyName: { type: String, default: "" }
  },
  methods: {
    moveFocusToNextStep() {
      let elementTofocus;
      if (this.step === 2) {
        elementTofocus = document.getElementById("pwdStep2Id");
      } else if (this.step === 3) {
        elementTofocus = document.getElementById("pwdStep3Id");
      }
      if (elementTofocus) {
        elementTofocus.focus();
      }
    },
    saveCompleted(buttonName: string) {
      if (buttonName === "CANCEL") {
        this.continueToDashboard();
      } else if (this.step !== this.stepCount) {
        //@ts-ignore
        this.$refs.programPWDTemplate.nextStep();
        this.moveFocusToNextStep();
        //@ts-ignore
        this.scrollToSectionHeader(this.$refs.programPWDTemplate);
      } else if (
        //@ts-ignore
        this.isSingleFlow()
      ) {
        if (
          buttonName === BUTTON_NAMES.SAVECONTINUE ||
          buttonName === BUTTON_NAMES.CONTINUE
        ) {
          let nextProgram = "";
          //@ts-ignore
          nextProgram = this.nextProgram(
            "PWD",
            this.applicantProgramInfo.transitAgencyCode
          );
          if (nextProgram === "DOCS" || nextProgram === "REVIEW") {
            router.push({
              path: "/application-program/" + nextProgram,
              query: {
                profileID: String(this.applicantProgramInfo.applicantProfileId),
                prgmType: nextProgram
              }
            });
          } else {
            router.push({
              path: "/application-program/" + nextProgram,
              query: {
                profileID: String(this.applicantProgramInfo.applicantProfileId),
                prgmType: nextProgram,
                agencyType: this.applicantProgramInfo.transitAgencyCode
              }
            });
          }
        }
      } else {
        router.push({
          path:
            "/application-review/" + this.applicantProgramInfo.programTypeCode
        });
      }
    },
    changeStepCount(steps: number) {
      this.stepCount = steps;
    },
    changeStep(step: number) {
      this.step = step;
    },
    continueToDashboard() {
      //@ts-ignore
      this.forwardToDashboard();
    },
    isCustomDisabilityReq() {
      if (
        //@ts-ignore
        this.isCustomDisabilityAgency() ||
        //@ts-ignore
        this.isCustomDisabilityAgencyPWD()
      ) {
        this.customDisability = true;
        this.populateDocTextForCustomDisability();
      }
    },
    populateDocTextForCustomDisability() {
      let abcObj = this.docGPCCodes.find(
        obj =>
          //@ts-ignore
          obj.resourceCode ===
          this.applicantProgramInfo.transitAgencyCode + "_COD"
      );
      //@ts-ignore
      this.docText = this.getCodDocDynamicText(abcObj.largeText);
    }
  },
  computed: {
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        //@ts-ignore
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      },
      set(newValue: IDraftProgramInfo) {
        //@ts-ignore
        store.commit.draftProgramInfoModule.SET_DRAFT_PROGRAM_INFO(newValue);
      }
    },
    applicantProgramInfo: {
      get(): IProgramsList {
        //@ts-ignore
        return store.getters.applicantProgramModule.getApplicantProgram;
      }
    },
    applicantAge: {
      get(): number {
        let applicantDOB =
          //@ts-ignore
          store.getters.applicantProfileModule.getApplicantProfile.applicantDob;
        let currentDate = new Date();
        let birthDate = new Date(applicantDOB);
        //@ts-ignore
        let difference: any = currentDate - birthDate;
        return Math.floor(difference / 31557600000);
      }
    },
    docDescCodes(): any {
      //@ts-ignore
      return this.uiFeature.PRF_DSBLTY.children;
    },
    docGPCCodes(): [] {
      //@ts-ignore
      return this.uiFeature.AG_ATT_TXT.children;
    },
    applicantProfile(): IApplicantProfile {
      return store.getters.applicantProfileModule.getApplicantProfile;
    },
    prfDsbltyInfoOne(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBLTY].largeText,
          CNST_TEXT.THE_APPLICANT_HAS,
          CNST_TEXT.THE_APPLICANT,
          CNST_TEXT.THE
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBLTY].largeText,
          CNST_TEXT.YOU_HAVE,
          CNST_TEXT.YOU,
          CNST_TEXT.YOUR
        );
      }
      return text;
    },
    prfDsbltyInfoHeaderTwo(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_2].displayName,
          CNST_TEXT.THE
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_2].displayName,
          CNST_TEXT.MY
        );
      }
      return text;
    },
    prfDsbltyInfoTwo(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_2].largeText,
          CNST_TEXT.THE
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_2].largeText,
          CNST_TEXT.YOUR
        );
      }
      return text;
    },
    prfDsbltyInfoThree(): string {
      let text: string = "";
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfile.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_3].largeText,
          CNST_TEXT.THE,
          CNST_TEXT.THE
        );
      } else {
        //@ts-ignore
        text = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.PRF_DSBY_3].largeText,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOUR
        );
      }
      return text;
    },
    codDocText(): string {
      //@ts-ignore
      return this.getCodDocDynamicText(
        //@ts-ignore
        this.uiFeature[GPC.COD_DOCTXT].largeText
      );
    }
  },
  created() {
    if (this.draftProgramInfo.disabilityInd === "N") {
      this.stepCount = 1;
    } else if (
      //@ts-ignore
      this.isSingleFlow()
    ) {
      this.stepCount = 2;
    } else {
      this.stepCount = 3;
    }
    //@ts-ignore
    if (!this.isSingleFlow()) {
      this.isCustomDisabilityReq();
      //@ts-ignore
      this.documentTypeCodes = this.getPWDDocumentTypes();
    }
  }
});
