import { IAdvnacedSearchResultObj, IDraftProgramInfo } from "@/types";

import { draftProgramInfoDefault } from "@/data/AppData";
import { clone } from "@/util/clone";
import { moduleActionContext } from "..";
import { defineModule } from "direct-vuex";
import { TES_APP_ENDPOINT, APP_REVIEW } from "@/constants/EndPoints";
import axios from "axios";
import { ActionContext } from "vuex";
import { HTTP_STATUS } from "@/constants/HttpStatusCode";

export interface DraftProgramInfoState {
  draftProgramInfo: IDraftProgramInfo;
  programInfoPrevious: IDraftProgramInfo;
  showPreviousValues: boolean;
  relatedApplicationInfo: IAdvnacedSearchResultObj[];
}

const draftProgramInfoModule = defineModule({
  namespaced: true,
  state: {
    draftProgramInfo: clone(draftProgramInfoDefault),
    programInfoPrevious: clone(draftProgramInfoDefault),
    showPreviousValues: false,
    relatedApplicationInfo: []
  } as DraftProgramInfoState,
  getters: {
    getDraftProgramInfo: (state: DraftProgramInfoState) =>
      state.draftProgramInfo,
    getProgramInfoPrevious: (state: DraftProgramInfoState) =>
      state.programInfoPrevious,
    getShowPreviousValues: (state: DraftProgramInfoState) =>
      state.showPreviousValues,
    getRelatedApplications: (state: DraftProgramInfoState) =>
      state.relatedApplicationInfo
  },
  mutations: {
    SET_DRAFT_PROGRAM_INFO(
      state: DraftProgramInfoState,
      newDraftProgramInfo: IDraftProgramInfo
    ) {
      state.draftProgramInfo = newDraftProgramInfo;
    },
    SET_PROGRAM_INFO_PREVIOUS(
      state: DraftProgramInfoState,
      newDraftProgramInfo: IDraftProgramInfo
    ) {
      state.showPreviousValues = newDraftProgramInfo.cycleNumber !== 0;
      state.programInfoPrevious = newDraftProgramInfo;
    },
    SET_RELATED_APPLICATION_INFO(
      state: DraftProgramInfoState,
      newRelatedApplicationInfo: IAdvnacedSearchResultObj[]
    ) {
      state.relatedApplicationInfo = newRelatedApplicationInfo;
    }
  },
  actions: {
    resetData(context: any) {
      let { commit } = draftProgramModuleActionContext(context);
      //clearing the draftprograminfo data in store.
      let draftProgramInfo = clone(draftProgramInfoDefault);

      commit.SET_DRAFT_PROGRAM_INFO(draftProgramInfo);
      commit.SET_PROGRAM_INFO_PREVIOUS(draftProgramInfo);
    },
    async loadDraftProgramInfo(context: any, applProfileID: any) {
      let { commit, rootDispatch } = draftProgramModuleActionContext(context);

      await axios
        .get(TES_APP_ENDPOINT.GET_DRAFT_PROGRAM_INFO, {
          params: {
            applicantProfileId: applProfileID
          }
        })
        .then(response => {
          commit.SET_DRAFT_PROGRAM_INFO(response.data);
        })
        .catch(() => {
          rootDispatch.alertModule.showAlertMessageByGPC("ER_INLINE");
        });
    },
    loadDraftProgramInfoPrevious(context: any) {
      let { commit, state, rootDispatch } = draftProgramModuleActionContext(
        context
      );
      // Reset previous values
      commit.SET_PROGRAM_INFO_PREVIOUS(clone(draftProgramInfoDefault));

      axios
        .get(APP_REVIEW.GET_APP_PROGRAM_INFO, {
          params: {
            tesAppId: state.draftProgramInfo.applicationId,
            cycleNumber: state.draftProgramInfo.cycleNumber - 1
          }
        })
        .then(response => {
          commit.SET_PROGRAM_INFO_PREVIOUS(response.data);
        })
        .catch(() => {
          rootDispatch.alertModule.showAlertMessageByGPC("ER_INLINE");
        });
    },
    async getApplicatProfileAndProgramInfo(context: any, profileDetails: any) {
      let {
        commit,
        rootCommit,
        rootDispatch
      } = draftProgramModuleActionContext(context);
      let url = TES_APP_ENDPOINT.GET_COMPLETE_PROFILE_DETAILS_BY_APP_PROFILE_ID;
      await axios
        .get(url, {
          params: {
            applicantProfileId: profileDetails.applicantProfileId,
            programTypeCode: profileDetails.programTypeCode,
            transitAgencyCode: profileDetails.transitAgencyCode
          }
        })
        .then(response => {
          commit.SET_DRAFT_PROGRAM_INFO(response.data.draftProgramInfoModel);

          rootCommit.applicantProgramModule.SET_APPLICANT_PROGRAM(
            response.data.programEligibilityModel
          );

          rootCommit.applicantProfileModule.SET_APPLICANT_PROFILE(
            response.data.applicantProfileModel
          );
        })
        .catch(() => {
          rootDispatch.alertModule.showAlertMessageByGPC("ER_INLINE");
        });
    },
    async getProgramSummaryDetailsByApplID(context: any, applID: any) {
      let {
        commit,
        rootCommit,
        rootDispatch
      } = draftProgramModuleActionContext(context);
      let url = TES_APP_ENDPOINT.GET_COMPLETE_PROFILE_DETAILS_BY_APP_ID;
      await axios
        .get(url, {
          params: { tesApplicationId: applID }
        })
        .then(response => {
          commit.SET_DRAFT_PROGRAM_INFO(response.data.draftProgramInfoModel);
          if (response && response.data) {
            rootCommit.appModule.SET_DOB_MATCH(response.data.nameDOBMatchInd);
          }
          rootCommit.applicantProgramModule.SET_APPLICANT_PROGRAM(
            response.data.programEligibilityModel
          );

          rootCommit.applicantProfileModule.SET_APPLICANT_PROFILE(
            response.data.applicantProfileModel
          );

          rootDispatch.applicationReviewModule.loadApplicationReviewModel({
            tesApplicationId: applID,
            cycleNumber: response.data.cycleNumber
          });
        })
        .catch(() => {
          rootDispatch.alertModule.showAlertMessageByGPC("ER_INLINE");
        });
    },
    saveDraftProgramInfo: async (
      context: ActionContext<DraftProgramInfoState, any>,
      draftProgramInfo: IDraftProgramInfo
    ) => {
      let status = "";
      try {
        const response = await axios.post(
          TES_APP_ENDPOINT.SAVE_DRAFT_PRGM_INFO,
          draftProgramInfo
        );
        if (response.status == HTTP_STATUS.OK) {
          const { commit } = draftProgramModuleActionContext(context);
          commit.SET_DRAFT_PROGRAM_INFO(draftProgramInfo);
          status = "SUCCESS";
        } else {
          status = `Error: ${response.data}`;
        }
      } catch (e) {
        if (axios.isAxiosError(e)) {
          status = `Error:  ${e.message}`;
        } else {
          status = "An unknown error has occurred.";
        }
      }
      return status;
    },
    getRelatedApplicationInfo: async (
      context: ActionContext<DraftProgramInfoState, any>,
      payload: { profileId: number; transitAgencyCode: string }
    ) => {
      const url = TES_APP_ENDPOINT.GET_RELATED_APPS;
      const { commit, rootDispatch } = draftProgramModuleActionContext(context);
      try {
        const response = await axios.get<IAdvnacedSearchResultObj[]>(url, {
          params: {
            transitAgencyCode: payload.transitAgencyCode,
            profileId: payload.profileId
          }
        });
        if (response.status === HTTP_STATUS.OK) {
          commit.SET_RELATED_APPLICATION_INFO(response.data);
        } else {
          throw Error(`Connection failed: ${response.status}`);
        }
      } catch (error) {
        //@ts-ignore
        rootDispatch.alertModule.showAlertMessageById(error.response.data.id);
      }
    }
  }
});

export default draftProgramInfoModule;
const draftProgramModuleActionContext = (context: any) =>
  moduleActionContext(context, draftProgramInfoModule);
