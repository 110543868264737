import { IPrograms } from "@/types";

export const PROGRAMS_CONSTANTS = {
  PROGRAM_CODES: ["ADA", "FTP", "MATP", "PWD", "SSR", "AGING", "RFTP", "VTP"]
};

export const PROGRAM_CODEVALUE: IPrograms[] = [
  { name: "AGING" },
  { name: "ADA" },
  { name: "FTP" },
  { name: "MATP" },
  { name: "PWD" },
  { name: "RFTP" },
  { name: "SSR" },
  { name: "VTP" }
];

export const ACSPROGRAM_CODEVALUE: IPrograms[] = [
  { name: "ADA" },
  { name: "PWD" }
];

export const PROGRAMS_NAME = {
  ADA: "ADA",
  ADA1: "ADA1",
  FTP: "FTP",
  MATP: "MATP",
  PWD: "PWD",
  SSR: "SSR",
  RFTP: "RFTP",
  VTP: "VTP",
  AGING: "AGING"
};
