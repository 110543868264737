
import Vue from "vue";
import store from "@/store";
import { TES_APP_ENDPOINT } from "@/constants/EndPoints";
import { USER_GROUPS } from "@/constants/CommonConstants";
import axios from "axios";
import { IProgramsList } from "@/types";
import UIFeatureMixinVue from "./UIFeatureMixin.vue";
import StepperNavigationMixinVue from "@/mixins/StepperNavigationMixin.vue";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";

export default Vue.extend({
  mixins: [UIFeatureMixinVue, StepperNavigationMixinVue, AppPropsMixin],
  methods: {
    withdrawApplication(applicantProgramInfo: IProgramsList) {
      let url = TES_APP_ENDPOINT.WITHDRAW_APPLICATION;
      return axios.post(url, applicantProgramInfo);
    },
    scrollToSectionHeader(ref: any) {
      setTimeout(
        () =>
          this.$vuetify.goTo(ref, {
            duration: 500,
            offset: 0,
            easing: "easeInOutCubic"
          }),
        500
      );
    }
  },
  computed: {
    isOrganizationUser() {
      return (
        store.getters.tesUserModule.getTesUser.userGroups ===
        USER_GROUPS.PD_TES_APPLICANT
      );
    },
    isAgencyUser() {
      return [
        USER_GROUPS.PD_TES_AGREVIEWER,
        USER_GROUPS.PD_FMR_AGREVIEWERASSIGNED,
        USER_GROUPS.PD_FMR_AGSUPERVISOR
      ].includes(store.getters.tesUserModule.getTesUser.userGroups);
    },
    isCOReadOnlyUser() {
      return (
        store.getters.tesUserModule.getTesUser.userGroups ===
        USER_GROUPS.PD_TES_COREADONLY
      );
    },
    isCitizenUser() {
      return (
        store.getters.tesUserModule.getTesUser.userGroups ===
        USER_GROUPS.PD_TES_CITIZEN
      );
    },
    isReviewerAssigned() {
      return (
        store.getters.tesUserModule.getTesUser.userGroups ===
        USER_GROUPS.PD_FMR_AGREVIEWERASSIGNED
      );
    },
    isAgencySupervisor() {
      return (
        store.getters.tesUserModule.getTesUser.userGroups ===
        USER_GROUPS.PD_FMR_AGSUPERVISOR
      );
    },
    isReviewerSelfAsgndUser() {
      return (
        store.getters.tesUserModule.getTesUser.userGroups ===
        USER_GROUPS.PD_TES_AGREVIEWER
      );
    }
  }
});
