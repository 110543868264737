import {
  IDashboardContent,
  IScreeningResponse,
  IProgramEligModel,
  IApplicantProfile
} from "@/types";

import { dashboardContentDefault } from "@/data/AppData";
import { clone } from "@/util/clone";
import { defineModule } from "direct-vuex";
import { moduleActionContext } from "..";

export interface DashboardInfoState {
  dashboardContent: IDashboardContent;
}

const dashboardModule = defineModule({
  namespaced: true,
  state: {
    dashboardContent: clone(dashboardContentDefault)
  } as DashboardInfoState,
  getters: {
    getDashboardContent: (state: DashboardInfoState) => state.dashboardContent
  },
  mutations: {
    SET_DASHBOARD_CONTENT(
      state: DashboardInfoState,
      newDashboardContent: IDashboardContent
    ) {
      state.dashboardContent = newDashboardContent;
    },
    SET_DASHBOARD_SCREENING_RESPONSE(
      state: DashboardInfoState,
      newScreeningResponse: IScreeningResponse
    ) {
      state.dashboardContent.screeningResponse = newScreeningResponse;
    },
    SET_DASHBOARD_PROG_ELIG(
      state: DashboardInfoState,
      newProgramElig: IProgramEligModel
    ) {
      state.dashboardContent.programEligModel = newProgramElig;
    },
    SET_DASHBOARD_APPLICANT_PROFILE(
      state: DashboardInfoState,
      newApplicantProfile: IApplicantProfile
    ) {
      state.dashboardContent.applicantProfile = newApplicantProfile;
    }
  },
  actions: {
    resetData(context: any) {
      let { commit } = dashboardModuleContext(context);
      //clearing the dashboardContent data in store.
      let dashboardContent = clone(dashboardContentDefault);
      //@ts-ignore
      commit.SET_DASHBOARD_CONTENT(dashboardContent);
    }
  }
});

export default dashboardModule;
const dashboardModuleContext = (context: any) =>
  moduleActionContext(context, dashboardModule);
