
import Vue from "vue";
import store from "@/store";
import { IApplicantEcolaneProfileModel, IProgramsList } from "@/types";

export default Vue.extend({
  name: "ReviewEcolaneInfoSummary",
  components: {},
  mixins: [],
  data() {
    return {
      showProgressBar: false
    };
  },
  computed: {
    applicantProgramInfo: {
      get(): IProgramsList {
        //@ts-ignore
        return store.getters.applicantProgramModule.getApplicantProgram;
      }
    },
    isEcolaneCustomerLoading(): boolean {
      return store.getters.applicantEcolaneProfileModule
        .getIsEcolaneCustomerLoaded;
    },
    isEcolaneCustomerDataLoaded(): boolean {
      return store.getters.applicantEcolaneProfileModule
        .getIsEcolaneCustomerDataLoaded;
    },
    selectedEcolaneCustomer(): IApplicantEcolaneProfileModel {
      return store.getters.applicantEcolaneProfileModule
        .getApplicantEcolaneProfile;
    }
  },
  methods: {}
});
