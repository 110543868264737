
import Vue from "vue";
import AppPropsMixin from "@/mixins/AppPropsMixin.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import store from "@/store";
import ClearStoreValuesMixinVue from "@/mixins/ClearStoreValuesMixin.vue";
import TESRadioControl from "@/components/common/TESRadioControl.vue";
import { IScreeningResponse } from "@/types";

export default Vue.extend({
  name: "register",
  mixins: [AppPropsMixin, UIFeatureMixinVue, ClearStoreValuesMixinVue],
  components: { TESRadioControl },
  data() {
    return {
      registerType: "" as string
    };
  },
  created() {
    let uiFeatureArray =
      "RGSTR_RADS,CTZ_DESC1,CTZ_DESC2,ORG_DESC1,ORG_DESC2,ORG_RGLINK,CTZ_RGLINK,ER_INLINE,CHGLGN_TXT,ADMN_USR,MAIN,MAIN_TITLE,FMR,ENVRONMNT";
    //@ts-ignore
    store.commit.uiFeatureModule.SET_DATA_LDNG_STS(true);
    //@ts-ignore
    store.dispatch.uiFeatureModule.getUIFeatures(uiFeatureArray);
  },
  computed: {
    isDataLoading: {
      get(): boolean {
        //@ts-ignore
        return store.getters.uiFeatureModule.getLoadingStatus;
      }
    },
    screeningResponse: {
      get(): IScreeningResponse {
        //@ts-ignore
        return store.getters.screeningResponseModule.getScreeningResponse;
      }
    },
    desc1(): string {
      let xyz = "";
      if (this.registerType) {
        //@ts-ignore
        xyz = this.uiFeature[this.registerType + "_DESC1"].largeText;
      }
      return xyz;
    },
    desc2(): string {
      let xyz = "";
      if (this.registerType) {
        //@ts-ignore
        xyz = this.uiFeature[this.registerType + "_DESC2"].largeText;
      }
      return xyz;
    },
    rgLink(): string {
      let xyz = "";
      if (this.registerType) {
        //@ts-ignore
        xyz = this.uiFeature[this.registerType + "_RGLINK"].largeText;
      }
      return xyz;
    }
  },
  methods: {
    routeToRegistrationPage(): string {
      let registrationLink: string;
      //@ts-ignore
      registrationLink = this.rgLink;
      if (
        "CTZ" === this.registerType &&
        this.screeningResponse &&
        this.screeningResponse.esecScreeningId
      ) {
        registrationLink =
          registrationLink +
          "&TESScreeningID=" +
          this.screeningResponse.esecScreeningId;
      }
      return registrationLink;
    }
  }
});
