
import Vue from "vue";
import store from "@/store";
import ProgramInfoSummaryMixin from "@/mixins/ProgramInfoSummaryMixin.vue";
import readonlyField from "@/components/common/ReadOnlyField.vue";
import { IDraftProgramInfo } from "@/types";
import { CNST_TEXT } from "@/constants/CommonConstants";
import DynamicTextMixin from "@/mixins/DynamicTextMixin.vue";
import UIFeatureMixin from "@/mixins/UIFeatureMixin.vue";
import { GPC } from "@/constants/GPCCode";

export default Vue.extend({
  name: "UpcomingApptReview",
  mixins: [ProgramInfoSummaryMixin, DynamicTextMixin, UIFeatureMixin],
  components: { readonlyField },
  computed: {
    draftProgramInfo(): IDraftProgramInfo {
      return store.getters.draftProgramInfoModule.getDraftProgramInfo;
    },
    upcomingApptQuestion(): string {
      let question: string = "";
      if (
        //@ts-ignore
        this.isOrgAgyCtznonBehalfOfUser()
      ) {
        //@ts-ignore
        question = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.UPCMGAPTS].largeText,
          CNST_TEXT.DOES_THE_APPLICANT
        );
      } else {
        //@ts-ignore
        question = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature[GPC.UPCMGAPTS].largeText,
          CNST_TEXT.DO_YOU
        );
      }
      return question;
    }
  }
});
