import { IProgramEligModel } from "@/types";

import { programEligModelDefault } from "@/data/AppData";
import { clone } from "@/util/clone";
import { moduleActionContext } from "..";
import { defineModule } from "direct-vuex";
import { DASHBOARD_ENDPOINT } from "@/constants/EndPoints";
import axios from "axios";

export interface ProgramEligState {
  programEligModel: IProgramEligModel;
  isDataLoading: boolean;
}

const programEligibleModule = defineModule({
  namespaced: true,

  state: {
    programEligModel: clone(programEligModelDefault),
    isDataLoading: false
  } as ProgramEligState,

  getters: {
    getProgramElig: (state: ProgramEligState) => state.programEligModel,
    getDataLoading: (state: ProgramEligState) => state.isDataLoading
  },
  mutations: {
    SET_PROG_ELIG(
      state: ProgramEligState,
      newProgramEligModel: IProgramEligModel
    ) {
      state.programEligModel = newProgramEligModel;
    },
    SET_DATA_LOADING(state: ProgramEligState, flag: boolean) {
      state.isDataLoading = flag;
    }
  },
  actions: {
    resetData(context: any) {
      let { commit } = programEligibleModuleContext(context);
      //clearing the programEligible data in store.
      let programEligible = clone(programEligModelDefault);
      //@ts-ignore
      commit.SET_PROG_ELIG(programEligible);
    },
    async populateProgramElig(context: any, applProfileId: any) {
      let { commit, rootDispatch } = programEligibleModuleContext(context);
      let url = DASHBOARD_ENDPOINT.GET_APPLICANT_PROFILE_PROGRAM;
      commit.SET_DATA_LOADING(true);
      await axios
        .get(url, {
          params: {
            applicantProfileId: applProfileId
          }
        })
        .then(response => {
          commit.SET_PROG_ELIG(response.data);
        })
        .catch(() => {
          rootDispatch.alertModule.showAlertMessageByGPC("ER_INLINE");
        })
        .finally(() => {
          commit.SET_DATA_LOADING(false);
        });
    }
  }
});

export default programEligibleModule;
const programEligibleModuleContext = (context: any) =>
  moduleActionContext(context, programEligibleModule);
