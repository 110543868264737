
import Vue from "vue";
import MedicalAssistanceReview from "@/components/application/program/matp/review/MedicalAssistanceReview.vue";
import TransportationReview from "@/components/application/program/matp/review/TransportationReview.vue";
import ApplicationReviewControls from "@/components/application/program/ApplicationReviewControls.vue";
import { IDraftProgramInfo, IProgramsList, IApplicantProfile } from "@/types";
import StepperNavigationMixinVue from "@/mixins/StepperNavigationMixin.vue";
import AttachmentsMixinVue from "@/mixins/AttachmentsMixin.vue";
import AttachmentsReview from "@/components/application/program/AttachmentsReview.vue";
import UpcomingApptReview from "@/components/application/program/UpcomingApptReview.vue";
import store from "@/store";

export default Vue.extend({
  name: "Program-Review-MATP",
  mixins: [StepperNavigationMixinVue, AttachmentsMixinVue],
  components: {
    MedicalAssistanceReview,
    TransportationReview,
    ApplicationReviewControls,
    AttachmentsReview,
    UpcomingApptReview
  },
  data() {
    return {
      singleFlow: false,
      documentTypeCodes: [] as string[]
    };
  },
  props: {
    applicantProgramInfoObj: {
      type: Object as () => IProgramsList
    },
    draftProgramInfoObj: {
      type: Object as () => IDraftProgramInfo
    },
    showApplRevCtrls: { type: Boolean, default: false }
  },
  computed: {
    applicantProgramInfo: {
      get(): IProgramsList {
        return store.getters.applicantProgramModule.getApplicantProgram;
      }
    },
    draftProgramInfo: {
      get(): IDraftProgramInfo {
        return store.getters.draftProgramInfoModule.getDraftProgramInfo;
      }
    },
    applicantProfile: {
      get(): IApplicantProfile {
        return store.getters.applicantProfileModule.getApplicantProfile;
      }
    }
  },
  created() {
    if (
      //@ts-ignore
      this.isSingleFlow()
    ) {
      this.singleFlow = true;
    } else {
      //@ts-ignore
      this.documentTypeCodes = this.getMATPDocumentTypes();
    }
  }
});
