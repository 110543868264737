
import Vue from "vue";
import ProgressBar from "@/components/common/ProgressBar.vue";
import Reports from "@/components/reports/Reports.vue";
import store from "@/store";

export default Vue.extend({
  name: "report",
  components: {
    Reports,
    ProgressBar
  },
  created() {
    let uiFeatureArray = "REPORTS,EMBD_TOK,EMBD_URL,R_TYPE,R_PAGE_V";

    //@ts-ignore
    store.commit.uiFeatureModule.SET_DATA_LDNG_STS(true);
    //@ts-ignore
    store.dispatch.uiFeatureModule.getUIFeatures(uiFeatureArray);
  },
  computed: {
    isDataLoading: {
      get(): boolean {
        //@ts-ignore
        return store.getters.uiFeatureModule.getLoadingStatus;
      }
    }
  }
});
