import axios from "axios";
import {
  IApplicationReviewModel,
  IReviewResultReasonModel,
  IReviewModelPayload,
  IPriorReviewModelPayload,
  IApplReviewResultReasonModel
} from "@/types";
import { applicationReviewModelDefault } from "@/data/AppData";
import { clone } from "@/util/clone";
import { moduleActionContext } from "..";
import { defineModule } from "direct-vuex";
import { TES_APP_ENDPOINT } from "@/constants/EndPoints";

export interface IApplicationReviewState {
  applicationReview: IApplicationReviewModel;
  applicationReviewPrevious: IApplicationReviewModel;
  isApplicationRvwModelLoading: boolean;
}

const applicationReviewModule = defineModule({
  namespaced: true,
  state: {
    applicationReview: clone(applicationReviewModelDefault),
    applicationReviewPrevious: clone(applicationReviewModelDefault),
    isApplicationRvwModelLoading: false
  } as IApplicationReviewState,
  getters: {
    getApplicationReviewModel: (state: IApplicationReviewState) =>
      state.applicationReview,
    getApplicationPreviousReviewModel: (state: IApplicationReviewState) =>
      state.applicationReviewPrevious,
    getIsAppRvwModelLoaded: (state: IApplicationReviewState) =>
      state.isApplicationRvwModelLoading
  },
  mutations: {
    SET_APPLICATION_REVIEW_MODEL(
      state: IApplicationReviewState,
      applicationReviewObject: IApplicationReviewModel
    ) {
      state.applicationReview = applicationReviewObject;
    },
    SET_APPLICATION_PREVIOUS_REVIEW_MODEL(
      state: IApplicationReviewState,
      applicationReviewObject: IApplicationReviewModel
    ) {
      state.applicationReviewPrevious = applicationReviewObject;
    },
    SET_APPLICATION_REVIEW_REASONS(
      state: IApplicationReviewState,
      newReasons: IReviewResultReasonModel[]
    ) {
      state.applicationReview.reasons = newReasons;
    },
    SET_OTHER_REASONS(
      state: IApplicationReviewState,
      newReasons: IApplReviewResultReasonModel[]
    ) {
      state.applicationReview.otherReasons = newReasons;
    },
    SET_APPLICATION_REVIEW_RECOMMENDATION(
      state: IApplicationReviewState,
      newRecmnd: string
    ) {
      state.applicationReview.reviewResultCode = newRecmnd;
    },
    SET_APPRVW_MODEL_LOADING(state: IApplicationReviewState, flag: boolean) {
      state.isApplicationRvwModelLoading = flag;
    }
  },
  actions: {
    async loadApplicationReviewModel(
      context: any,
      payload: IReviewModelPayload
    ) {
      const { commit, rootDispatch } = applicationReviewContext(context);
      commit.SET_APPRVW_MODEL_LOADING(true);
      let url = TES_APP_ENDPOINT.GET_APPLREVIEW_MODEL_BY_APPLID;
      await axios
        .get(url, {
          params: {
            tesApplicationId: payload.tesApplicationId,
            cycleNumber: payload.cycleNumber
          }
        })
        .then(response => {
          commit.SET_APPLICATION_REVIEW_MODEL(response.data);
        })
        .catch(() => {
          rootDispatch.alertModule.showAlertMessageByGPC("ER_INLINE");
        })
        .finally(() => {
          commit.SET_APPRVW_MODEL_LOADING(false);
        });
    },
    async loadApplicationPreviousReviewModel(
      context: any,
      payload: IPriorReviewModelPayload
    ) {
      const { commit, rootDispatch } = applicationReviewContext(context);
      commit.SET_APPRVW_MODEL_LOADING(true);
      let url = TES_APP_ENDPOINT.GET_PRIOR_APPLREVIEW_MODEL_BY_APPLID;
      await axios
        .get(url, {
          params: {
            tesApplicationId: payload.tesApplicationId
          }
        })
        .then(response => {
          commit.SET_APPLICATION_PREVIOUS_REVIEW_MODEL(response.data);
        })
        .catch(() => {
          rootDispatch.alertModule.showAlertMessageByGPC("ER_INLINE");
        })
        .finally(() => {
          commit.SET_APPRVW_MODEL_LOADING(false);
        });
    },
    resetData(context: any) {
      let { commit } = applicationReviewContext(context);
      //clearing the applicationReview data in store.
      commit.SET_APPLICATION_REVIEW_MODEL(clone(applicationReviewModelDefault));
      commit.SET_APPLICATION_PREVIOUS_REVIEW_MODEL(
        clone(applicationReviewModelDefault)
      );
    }
  }
});

export default applicationReviewModule;
const applicationReviewContext = (context: any) =>
  moduleActionContext(context, applicationReviewModule);
