
import Vue from "vue";
import { IApplicantProfile } from "@/types";
import dateHelperMixin from "@/mixins/dateHelperMixin.vue";
import fieldEntryMixin from "@/mixins/fieldEntryMixin.vue";
import UIFeatureMixinVue from "@/mixins/UIFeatureMixin.vue";
import ApplicationMixin from "@/mixins/ApplicationMixin.vue";
import { CNST_TEXT } from "@/constants/CommonConstants";
import store from "@/store";

export default Vue.extend({
  mixins: [
    dateHelperMixin,
    fieldEntryMixin,
    UIFeatureMixinVue,
    ApplicationMixin
  ],
  computed: {
    applicantProfileInfo(): IApplicantProfile {
      return store.getters.applicantProfileModule.getApplicantProfile;
    },
    applicantProfileInfoPrevious(): IApplicantProfile {
      return store.getters.applicantProfileModule.getApplicantProfilePrevious;
    },
    showPreviousValues(): boolean {
      return store.getters.applicantProfileModule.getShowPreviousValues;
    }
  },
  methods: {
    loadMATPDynamicGPCText() {
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfileInfo.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        this.uiFeature["MEDAST_RGS"].displayName = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["MEDAST_RGS"].displayName,
          CNST_TEXT.IS_THE_APPLICANT
        );
        //@ts-ignore
        this.uiFeature["DIG10_RN"].displayName = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["DIG10_RN"].displayName,
          CNST_TEXT.DOES_THE_APPLICANT,
          CNST_TEXT.THEIR
        );
        //@ts-ignore
        this.uiFeature["DIG10_RN"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["DIG10_RN"].largeText,
          CNST_TEXT.THE_APPLICANT
        );
        //@ts-ignore
        this.uiFeature["HAS_SSN"].displayName = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["HAS_SSN"].displayName,
          CNST_TEXT.DOES_THE_APPLICANT
        );
        //@ts-ignore
        this.uiFeature[
          "RADIO_YN"
          //@ts-ignore
        ].children[1].displayName = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["RADIO_YN"].children[1].displayName,
          CNST_TEXT.THE_APPLICANT_DOES_NOT,
          CNST_TEXT.DOESNT
        );
        //@ts-ignore
        this.uiFeature["HSHD_MATP"].displayName = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["HSHD_MATP"].displayName,
          CNST_TEXT.THE_APPLICANTS
        );
        //@ts-ignore
        this.uiFeature["MAID_SSN"].displayName = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["MAID_SSN"].displayName,
          CNST_TEXT.THE_APPLICANTS
        );
        //@ts-ignore
        this.uiFeature["NMTCH_SSN"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["NMTCH_SSN"].largeText,
          CNST_TEXT.THE_APPLICANTS
        );
        //@ts-ignore
        this.uiFeature["INELG_MATP"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["INELG_MATP"].largeText,
          CNST_TEXT.TTHE_APPLICANT_IS
        );
        //@ts-ignore
        this.uiFeature["NMTCH_MAID"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["NMTCH_MAID"].largeText,
          CNST_TEXT.THE_APPLICANTS
        );
        //@ts-ignore
        this.uiFeature["NT_QLFY"].displayName = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["NT_QLFY"].displayName,
          "The applicant does"
        );
        //@ts-ignore
        this.uiFeature["YNT_MATP1"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["YNT_MATP1"].largeText,
          CNST_TEXT.TTHE_APPLICANT,
          CNST_TEXT.THEY,
          CNST_TEXT.THEY,
          CNST_TEXT.THE_APPLICANT_HAS,
          CNST_TEXT.THEIR,
          CNST_TEXT.THEIR
        );
      } else {
        //@ts-ignore
        this.uiFeature["MEDAST_RGS"].displayName = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["MEDAST_RGS"].displayName,
          CNST_TEXT.ARE_YOU
        );
        //@ts-ignore
        this.uiFeature["DIG10_RN"].displayName = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["DIG10_RN"].displayName,
          CNST_TEXT.DO_YOU,
          CNST_TEXT.YOUR
        );
        //@ts-ignore
        this.uiFeature["DIG10_RN"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["DIG10_RN"].largeText,
          CNST_TEXT.I
        );
        //@ts-ignore
        this.uiFeature["HAS_SSN"].displayName = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["HAS_SSN"].displayName,
          CNST_TEXT.DO_YOU
        );
        //@ts-ignore
        this.uiFeature[
          "RADIO_YN"
          //@ts-ignore
        ].children[1].displayName = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["RADIO_YN"].children[1].displayName,
          CNST_TEXT.I_DONT,
          CNST_TEXT.I_DONT
        );
        //@ts-ignore
        this.uiFeature["HSHD_MATP"].displayName = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["HSHD_MATP"].displayName,
          CNST_TEXT.YOUR
        );
        //@ts-ignore
        this.uiFeature["MAID_SSN"].displayName = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["MAID_SSN"].displayName,
          CNST_TEXT.YOUR
        );
        //@ts-ignore
        this.uiFeature["NMTCH_SSN"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["NMTCH_SSN"].largeText,
          CNST_TEXT.YOUR
        );
        //@ts-ignore
        this.uiFeature["INELG_MATP"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["INELG_MATP"].largeText,
          CNST_TEXT.YOU_ARE
        );
        //@ts-ignore
        this.uiFeature["NMTCH_MAID"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["NMTCH_MAID"].largeText,
          CNST_TEXT.YOUR
        );
        //@ts-ignore
        this.uiFeature["NT_QLFY"].displayName = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["NT_QLFY"].displayName,
          CNST_TEXT.YOU_DO
        );
        //@ts-ignore
        this.uiFeature["YNT_MATP1"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["YNT_MATP1"].largeText,
          CNST_TEXT.YYOU,
          CNST_TEXT.YOU,
          CNST_TEXT.YOU,
          CNST_TEXT.YOU_HAVE,
          CNST_TEXT.YOUR,
          CNST_TEXT.YOUR
        );
      }
    },
    loadTranspDynamicGPCText() {
      if (
        //@ts-ignore
        this.isOrganizationUser ||
        //@ts-ignore
        this.isAgencyUser ||
        //@ts-ignore
        (this.isCitizenUser && this.applicantProfileInfo.onBehalfOfInd === "Y")
      ) {
        //@ts-ignore
        this.uiFeature["VALD_DL"].displayName = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["VALD_DL"].displayName,
          CNST_TEXT.DOES_THE_APPLICANT,
          CNST_TEXT.THEIR
        );
        //@ts-ignore
        this.uiFeature["ACCS_VEH"].displayName = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["ACCS_VEH"].displayName,
          CNST_TEXT.DOES_THE_APPLICANT,
          CNST_TEXT.THEIR
        );
        //@ts-ignore
        this.uiFeature["TNSPT_APT"].displayName = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["TNSPT_APT"].displayName,
          CNST_TEXT.DOES_THE_APPLICANT,
          CNST_TEXT.THEM
        );
        //@ts-ignore
        this.uiFeature["BS_TRN_STP"].displayName = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["BS_TRN_STP"].displayName,
          CNST_TEXT.DOES_THE_APPLICANT
        );
        //@ts-ignore
        this.uiFeature["DIF_TNSPTS"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["DIF_TNSPTS"].largeText,
          CNST_TEXT.DOES_THE_APPLICANT,
          CNST_TEXT.THEIR
        );
        //@ts-ignore
        this.uiFeature["RDE_WT_OTH"].displayName = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["RDE_WT_OTH"].displayName,
          CNST_TEXT.THE_APPLICANT
        );
        //@ts-ignore
        this.uiFeature["MED_COG_RS"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["MED_COG_RS"].largeText,
          CNST_TEXT.THE_APPLICANT
        );
      } else {
        //@ts-ignore
        this.uiFeature["VALD_DL"].displayName = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["VALD_DL"].displayName,
          CNST_TEXT.DO_YOU,
          CNST_TEXT.YOUR
        );
        //@ts-ignore
        this.uiFeature["ACCS_VEH"].displayName = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["ACCS_VEH"].displayName,
          CNST_TEXT.DO_YOU,
          CNST_TEXT.YOUR
        );
        //@ts-ignore
        this.uiFeature["TNSPT_APT"].displayName = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["TNSPT_APT"].displayName,
          CNST_TEXT.DO_YOU,
          CNST_TEXT.YOU
        );
        //@ts-ignore
        this.uiFeature["BS_TRN_STP"].displayName = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["BS_TRN_STP"].displayName,
          CNST_TEXT.DO_YOU
        );
        //@ts-ignore
        this.uiFeature["DIF_TNSPTS"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["DIF_TNSPTS"].largeText,
          CNST_TEXT.DO_YOU,
          CNST_TEXT.YOUR
        );
        //@ts-ignore
        this.uiFeature["RDE_WT_OTH"].displayName = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["RDE_WT_OTH"].displayName,
          CNST_TEXT.YOU
        );
        //@ts-ignore
        this.uiFeature["MED_COG_RS"].largeText = this.buildDynamicText(
          //@ts-ignore
          this.uiFeature["MED_COG_RS"].largeText,
          CNST_TEXT.YOU
        );
      }
    }
  }
});
